'use strict';

import _                         from 'underscore';
import $                         from 'jquery';
import ViewScroll                from 'components/core/mixins/Backbone.ViewScroll';
import app                       from 'components/core/application';
import template                  from 'components/betting/quick-bet-multi-dialog.tpl.hbs';
import helpers                   from 'components/helpers/helpers';
import moment                    from 'moment-timezone';
import user                      from 'components/user/user';
import BackboneCloseView         from 'components/core/mixins/Backbone.CloseView';
import RaceModel                 from 'components/model/race.model';
import BetPlacedDialogView       from 'components/betting/bet_placed';
import BetErrorDialogView        from 'components/betting/bet_placing_error';
import maybe                     from 'components/helpers/maybe';
import betslipHelpers            from 'components/betting/betslip.helpers';

var QuickBetMulti = {

        template: template,

        events: {
            'click [data-wrap]': 'stopPropagation',
            'click [data-close]': 'closeDialog',
            'click [data-bet]': 'quickbet',
            'keyup [data-amount]': 'amountInput',//used for b2b but Betsafe. To be removed when all platforms inherit home page, race card, quick-(multi-)bet facelift from Betsafe
            'click [data-stake-btn]': 'stakeBtn',//used for Betsafe only
            'click [data-bonus-warning]': 'toggleBonusWarningMessage'
        },

        className: 'l-scrollV',

        /**
         * @method initialize
         * @description
         *      Initialize view with options. Setup listeners on model
         *
         * @param opt
         */
        initialize: function(opt) {
            this.options = {};
            _.extend(this.options, opt);

            let attrs = this.options.attributes;
            let race = new RaceModel(attrs.race.data.race);

            this.DOM = {};
            this.data = {};
            this.data.cols = [];
            this.data.race = race.attributes;

            this.setUpBetModel(attrs);
            let normalBetTypes = this.betModel.attributes.collectionData.betTypes.normal;
            this.setUpOdds(normalBetTypes);

            this.toteCurrency = (helpers.isPickBetType(this.betModel.attributes.betType)) ? this.betModel.attributes.currency : maybe.of(this.options).mapDotProp('attributes.race.data.event.toteCurrency').join();
            this.betModel.set({currency: this.betModel.attributes.betCategory === 'TOT' ? this.toteCurrency : user.data.currency}, {silent: true});
            this.convert_to_user_currency = this.betModel.attributes.betCategory === 'TOT' && this.betModel.attributes.currency !== user.data.currency;

            if(this.betModel.get('betslipType') === 'ACC') {
                var marksAcc = this.betModel.get('marksAcc');
                if(marksAcc) marksAcc = marksAcc.map(function(race, index) {
                    race.n = index + 1;
                    return race;
                });
                this.data.runners_info = marksAcc;
            } else {
                _.each(attrs.betModel.attributes.programNumberViewColumns, (col, key) => {
                    if (!_.isEmpty(col)) {
                        this.data.cols.push({
                            key: key === 'C' ? app.polyglot.t('label_betslip_c') : app.polyglot.t('label_betslip_col' + key),
                            col
                        });
                    }
                });
            }

            if(this.afterInitialize && typeof this.afterInitialize == 'function') this.afterInitialize.apply(this, arguments);
        },

        setUpBetModel: function(attrs) {
            this.betModel = attrs.betModel;
            this.betModel.set('unitStake', '', {silent: true});
            this.betModel.set('country', attrs.race.data.event.country, {silent: true});
            this.listenTo(this.betModel, 'error', this.promptUserActionOnError);
            this.listenTo(this.betModel, 'invalid', this.showValidationErrors);
            this.listenTo(this.betModel, 'sync', this.processSuccessResponse);
            this.listenTo(this.betModel, 'change:unitStake', this.updateUnitStake);
        },

        setUpOdds: function(normalBetTypes) {
            this.data.betCategoryOptions = [];

            if (normalBetTypes.BOK && normalBetTypes.BOK[this.betModel.attributes.betType]) {
                this.data.betCategoryOptions.push({key: 'BOK', title: app.polyglot.t('label_bet_category_BOK')});
            }

            if (normalBetTypes.TOT && normalBetTypes.TOT[this.betModel.attributes.betType]) {
                this.data.betCategoryOptions.push({key: 'TOT', title: app.polyglot.t('label_bet_category_TOT')});
            }

            // enabled
            var cat_opt_length = this.data.betCategoryOptions.length;

            //if there is at least one option, set the first one to active and assign it as betCategory value
            if (cat_opt_length > 0) {
                this.data.betCategoryOptions[0].active = true;
                this.betModel.set({betCategory: this.data.betCategoryOptions[0].key}, {silent: true});
            }
        },

        getSelectedRaces: function() {
            var races = [];
            _.each(this.data.runners_info, (race, index) => {
                var info = {
                    time: moment.unix(race.time).tz(user.timeZone()).format('LT'),
                    race: `${app.polyglot.t('label_race')} ${index + 1}`,
                    runners: race.runners.length === 1 ? race.runners[0].name : this.concatRunners(race.runners)
                };

                races.push(info);
            });

            return races;
        },

        concatRunners: function(runners) {
            var arr = [];
            _.each(runners, (runner) => {
                arr.push(runner.programNumber);
            });

            return arr.join(', ');
        },

        render: function () {
            if(this.beforeRender && typeof this.beforeRender == 'function') this.beforeRender.apply(this, arguments);

            this.$el.html(this.template(this.getViewData()));

            if(this.onTemplateRendered && typeof this.onTemplateRendered == 'function') this.onTemplateRendered.apply(this, arguments);

            this.DOM.submitBtn = this.$('[data-bet]');
            this.DOM.amountInput = this.$('[data-amount]');
            this.DOM.converted_amount = this.$('[data-converted-amount]');
            this.DOM.tax_value = this.$('[data-tax-value]');
            this.DOM.total_stake_value = this.$('[data-total-stake-value]');
            this.DOM.bonusWarning = this.$('[data-bonus-warning]');
            this.DOM.bonusWarningMessage = this.$('[data-bonus-warning-message]');
            this.DOM.diffCurrencyWarning = this.$('[data-diff-currency-warning]');
            this.DOM.diffCurrencyMessage = this.$('[data-diff-currency-message]');

            if(user.isGerman()) this.updateTax();
            this.updateDiffCurrencyInfo();

            app.overlay.showComponent(this);

            window.setTimeout(() => this.handleScrollToInputs(), 0);

            if(this.onRender && typeof this.onRender == 'function') this.onRender.apply(this, arguments);
            return this;
        },

        getViewData: function() {
            var unit_stake = this.betModel.get('unitStake') > 0 ? this.betModel.get('unitStake') : 0;
            var user_currency = user.data.currency.toUpperCase();
            var currency = this.betModel.get('betCategory') === 'TOT' ? this.toteCurrency : user_currency;

            let data = {
                race_title: maybe.of(this).mapDotProp('options.attributes.race.data.event.title').orElse(null).join(),
                race_type: this.betModel.get('betslipType') === 'ACC' ? app.interpolate(helpers.isPickPlace(this.betModel.get('betType')) ? 'label_bet_type_ppx' : 'label_bet_type_PXX', {numRaces: this.betModel.get('marksAcc').length}) : null,
                cols: this.data.cols,
                selected_races: this.getSelectedRaces(),
                stake: this.betModel.get('unitStake'),
                betType: helpers.betTypeName(this.betModel.get('betType'), this.betModel.get('betCategory'), this.betModel.get('wps'), this.betModel.get('country')),
                total_stake: helpers.formatMoney(unit_stake, unit_stake % 1 > 0 ? 2 : 0, currency, true, false),
                total_stake_converted: helpers.formatMoney(unit_stake, unit_stake % 1 > 0 ? 2 : 0, currency, true, true),
                betCategoryOptions: this.data.betCategoryOptions,
                buttonsLength: this.data.betCategoryOptions.length,
                isGermanCustomer: user.isGerman(),
                isUkCustomer: user.isUkCustomer(),
                user_currency: user.data.currency.toUpperCase(),
                showDiffCurancyInfo: (this.betModel.get('currency') !== user.data.currency && app.brand === 'suaposta'),
                currency_symbol: helpers.currencySymbols[this.betModel.get('betCategory') === 'TOT' ? this.toteCurrency : user.data.currency.toUpperCase()],
                convert_to_user_currency: this.convert_to_user_currency,
                hasOnlyBonus: user.data.balance === user.data.bonusBalance
            }

            return _.extend(this.viewData || {}, data);
        },

        updateDiffCurrencyInfo: function() {
            if (this.convert_to_user_currency && ['suaposta', 'europebet'].indexOf(app.brand) > -1) {
                var parsedAmount =  parseFloat(this.betModel.get('unitStake'));
                if(parsedAmount === 0 || isNaN(parsedAmount)) {
                    this.DOM.diffCurrencyWarning.hide();
                } else {
                    this.DOM.diffCurrencyWarning.show();
                    this.DOM.diffCurrencyMessage.html(
                        app.interpolate('msg_diff_currency_info', {
                            originalCurrency: this.betModel.get('currency'),
                            chargedAmount: (Math.round(helpers.exchange(this.betModel.get('unitStake'), this.betModel.attributes.currency, user.data.currency) * 100) / 100) + ' ' + user.data.currency
                        })
                    );
                }
            }
        },

        stopPropagation: function (e) {
            e.stopPropagation();
        },

        getTax: function() {
            var attributes = this.betModel.attributes;
            var taxRate = attributes.taxFees && attributes.taxFees[attributes.betCategory] ? attributes.taxFees[attributes.betCategory] : 0;
            var unitStake = attributes.unitStake;

            unitStake = unitStake * attributes.numBets;

            var result = betslipHelpers.calculateTax(unitStake, attributes, taxRate);
            return result.taxAmount ? result.taxAmount : result;
        },

        updateTax: function() {
            var tax = this.getTax();
            //tax is 0 for TOT bets but not Pick bets
            if(this.betModel.get('betCategory') === 'TOT' && !helpers.isPickBetType(this.betModel.get('betType'))) tax = 0;
            this.DOM.tax_value.html(helpers.formatMoney(tax, tax % 1 > 0 ? 2 : 0, user.data.currency, true, true));
        },

        amountInput: function (e) {
            var value = this.DOM.amountInput.val();
            var currency = this.betModel.get('betCategory') === 'TOT' ? this.toteCurrency : user.data.currency;

            this.value = parseFloat(value);
            this.betModel.set('unitStake', value);
            this.updateTax();
            this.manageInputErrors(e);

            if(!value) value = '0';

            value = value.replace(',', '.') * this.betModel.attributes.numBets;
            this.DOM.total_stake_value.html(helpers.formatMoney(value, value % 1 > 0 ? 2 : 0, currency, true, false));

            if(this.convert_to_user_currency) this.DOM.converted_amount.text(helpers.formatMoney(value, value % 1 > 0 ? 2 : 0, currency, true, true));
            this.updateDiffCurrencyInfo();

        },

        // called when model triggers a change on the unitStake attribute
        updateUnitStake: function (betModel) {
            this.DOM.amountInput.val(betModel.get('unitStake'));
            this.toggleBonusWarning();
        },

        manageInputErrors: function(e) {
            var id = $(e.target).data('id');

            if(this.DOM[id]) this.DOM[id].removeClass('c-form__input--invalid');
            if(this.DOM.vmc && this.DOM.vmc[id]) this.DOM.vmc[id].hide();
        },

        closeDialog: function () {
            app.overlay.closeDialog();
        },

        onOddsSelected: function(val) {
            console.log('Odds selected: ', val);
        },

        quickbet: function () {
            this.DOM.submitBtn.attr('disabled', true);
            this.options.closeOnOutsideClick = false;
            this.betModel.save();
        },

        /**
         * @method processSuccessResponse
         * @description
         *      Success callback function for model.save()
         *
         * @param model
         * @param response
         */
        processSuccessResponse: function(model, response) {
            this.options.closeOnOutsideClick = true;
            this.DOM.submitBtn.removeAttr('disabled');
            if(response.type === 'success') {
                let contentView = new BetPlacedDialogView({publicId: response.publicId});

                app.overlaySecondary.dialog({
                    contentView: contentView,
                    size: 'sliding',
                    secondary: true, //tell dialog to use app.layOutSecondary
                    closeOnOutsideClick: true
                });

                app.overlaySecondary.component.addOverlayClassName('c-overlay--showHeader');
                app.overlaySecondary.component.render();
                app.trigger('user:balance:update');
                app.trigger('bet:placed:successful');

                if(this.betModel.get('isFree')) app.trigger('bet:freeBet:used');

            } else {
                this.promptUserActionOnError();
            }
        },

        /**
         * @method promptUserActionOnError
         * @description
         *      Prompt for user action in case of an error response
         * @param model
         * @param response
         */
        promptUserActionOnError: function() {
            this.DOM.submitBtn.removeAttr('disabled');
            this.errorDialogView = new BetErrorDialogView(betslipHelpers.getErrorMessage(this.betModel.attributes));

            app.overlaySecondary.dialog({
                contentView: this.errorDialogView,
                secondary: true, //tell dialog to use app.layOutSecondary
                closeOnOutsideClick: true
            });

            app.overlaySecondary.component.render();
            app.overlaySecondary.component.delegateEvents();

            this.errorDialogView.on('cancel', function () {
                app.overlaySecondary.closeDialog();
            }, this);
            this.errorDialogView.on('resubmit', function () {
                app.overlaySecondary.closeDialog();
                if(this.betModel.get('newUnitStake')) {
                    this.betModel.set({unitStake: this.betModel.get('newUnitStake')}, {silent: true})
                }
                this.betModel.stripExtraAttributes();
                this.quickbet();
            }, this);

            this.options.closeOnOutsideClick = true;
        },

        /**
         * @method showValidationErrors
         * @description
         *      Shows validations errors when fired on model 'invalid' event
         *
         * @param model
         * @param response
         */
        showValidationErrors: function(model, errors) {
            this.DOM.submitBtn.removeAttr('disabled');
            this.options.closeOnOutsideClick = true;
            //validation messages containers - vmc
            if(!this.DOM.vmc) {
                this.DOM.vmc = {};
            }

            for(var i = 0, l = errors.length; i < l; i++) {
                var field = errors[i];
                //cache validation message container object if has not been cached yet
                if(!this.DOM.vmc[field.name]) {
                    this.DOM.vmc[field.name] = this.$('[data-validation_' + field.name + ']');
                }

                this.DOM.vmc[field.name].html(field.message).show();
            }
        },

        toggleBonusWarningMessage: function(e) {
            e.preventDefault();
            betslipHelpers.toggleBonusWarningMessage($(e.target), $(this.DOM.bonusWarningMessage));
        },

        toggleBonusWarning: function() {
            const bet = +this.betModel.attributes.unitStake;
            betslipHelpers.toggleBonusWarning({ balance: user.data.balance, bonusBalance: user.data.bonusBalance }, bet, this.DOM.bonusWarning);
        },

        onClose: function() {
            if(this.oddsSelect) this.oddsSelect.close();
        }
    }

export default _.extend({}, QuickBetMulti, ViewScroll, BackboneCloseView);
