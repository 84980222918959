'use strict';
import Backbone     from 'backbone';
import app          from 'components/core/application';

/**
 * Active sessions model
 * @module Auth
 *
 * @description
 *      Save user sessions management selections
 *
 * @returns {Function} - Active sessions model constructor function
 */
export default Backbone.Model.extend({
    url: '/rest/v1/users/me/logout/sessions',

    data: {},

    defaults: {
        sessions: [],
        hide_sessions: false
    },

    save: function(attrs, options) {
        var self = this;
        options = options || {};
        options.success = function () {
            app.trigger('navigate', self.data.url, false);
            if(self.data.url === '/') {
                location.reload(true);
            }
        };

        Backbone.Model.prototype.save.call(this, attrs, options);
    }
});
