var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
    var stack1;

  return "    <ul class=\"m-h2h c-accordion c-countryList l-scrollTarget--noTitle\">\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.data : depth0),{"name":"each","hash":{},"fn":this.program(2, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "    </ul>\n";
},"2":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "            <a href=\"/race/details/id/"
    + alias2(alias1((depth0 != null ? depth0.idRaceParent : depth0), depth0))
    + "\" id=\""
    + alias2(alias1((depth0 != null ? depth0.idRace : depth0), depth0))
    + "\" data-h2h-"
    + alias2(alias1((depth0 != null ? depth0.idEvent : depth0), depth0))
    + " class=\"c-accordion__head c-countryList__head m-h2h__head\">\n                <h3>\n                    <i class=\"c-flag c-flag isCountry"
    + alias2(alias1((depth0 != null ? depth0.country : depth0), depth0))
    + "\"></i>\n                    "
    + alias2(alias1((depth0 != null ? depth0.title : depth0), depth0))
    + " | R"
    + alias2(alias1((depth0 != null ? depth0.raceNumber : depth0), depth0))
    + "\n                </h3>\n                <div class=\"m-h2h__time\">\n                    "
    + alias2(alias1((depth0 != null ? depth0.raceTime : depth0), depth0))
    + "\n                </div>\n            </a>\n\n            <div class=\"c-grid c-countryList__content m-h2h__content\">\n                <div class=\"c-grid__col is-1-2 text-center p-rel\">\n                    <span class=\"c-silk c-silk--large inline-block mt2\" "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.topRunners : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.silkUrl : stack1),{"name":"if","hash":{},"fn":this.program(3, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "></span>\n                    <div class=\"m-h2h__runner\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.topRunners : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.name : stack1), depth0))
    + "</div>\n                    <div class=\"m-h2h__details\">"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.topRunners : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.ageFormatted : stack1),{"name":"if","hash":{},"fn":this.program(5, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.topRunners : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.genderFormatted : stack1), depth0))
    + " "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.topRunners : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.pastPerformance : stack1),{"name":"if","hash":{},"fn":this.program(7, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n                    <div class=\"m-h2h__details\">"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.topRunners : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.postPosition : stack1),{"name":"if","hash":{},"fn":this.program(9, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n                    <div class=\"m-h2h__btn\">\n                        <a data-idRunner=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.topRunners : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.idRunner : stack1), depth0))
    + "\" data-idRace=\""
    + alias2(alias1((depth0 != null ? depth0.idRace : depth0), depth0))
    + "\" data-betType=\"WIN\" role=\"button\" class=\"c-btn c-btn--block c-btn--odds c-btn--narrow\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.topRunners : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.fixedOddsWin : stack1), depth0))
    + "</a>\n                    </div>\n                    <div class=\"m-h2h__vs "
    + ((stack1 = __default(require("../../components/helpers/handlebar-helpers/compare.js")).call(depth0,((stack1 = (depth0 != null ? depth0.topRunners : depth0)) != null ? stack1.length : stack1),">",2,{"name":"compare","hash":{},"fn":this.program(11, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " \"><span>vs</span></div>\n                </div>\n\n                <div class=\"c-grid__col is-1-2 text-center\">\n                    <span class=\"c-silk c-silk--large inline-block mt2\" "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.topRunners : depth0)) != null ? stack1["1"] : stack1)) != null ? stack1.silkUrl : stack1),{"name":"if","hash":{},"fn":this.program(13, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "></span>\n                    <div class=\"m-h2h__runner\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.topRunners : depth0)) != null ? stack1["1"] : stack1)) != null ? stack1.name : stack1), depth0))
    + "</div>\n                    <div class=\"m-h2h__details\">"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.topRunners : depth0)) != null ? stack1["1"] : stack1)) != null ? stack1.ageFormatted : stack1),{"name":"if","hash":{},"fn":this.program(15, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.topRunners : depth0)) != null ? stack1["1"] : stack1)) != null ? stack1.genderFormatted : stack1), depth0))
    + " "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.topRunners : depth0)) != null ? stack1["1"] : stack1)) != null ? stack1.pastPerformance : stack1),{"name":"if","hash":{},"fn":this.program(17, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n                    <div class=\"m-h2h__details\">"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.topRunners : depth0)) != null ? stack1["1"] : stack1)) != null ? stack1.postPosition : stack1),{"name":"if","hash":{},"fn":this.program(19, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n                    <div class=\"m-h2h__btn\">\n                        <a data-idRunner=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.topRunners : depth0)) != null ? stack1["1"] : stack1)) != null ? stack1.idRunner : stack1), depth0))
    + "\" data-idRace=\""
    + alias2(alias1((depth0 != null ? depth0.idRace : depth0), depth0))
    + "\" data-betType=\"WIN\" role=\"button\" class=\"c-btn c-btn--block c-btn--odds c-btn--narrow\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.topRunners : depth0)) != null ? stack1["1"] : stack1)) != null ? stack1.fixedOddsWin : stack1), depth0))
    + "</a>\n                    </div>\n                </div>\n            </div>\n\n"
    + ((stack1 = __default(require("../../components/helpers/handlebar-helpers/compare.js")).call(depth0,((stack1 = (depth0 != null ? depth0.topRunners : depth0)) != null ? stack1.length : stack1),">",2,{"name":"compare","hash":{},"fn":this.program(21, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n";
},"3":function(depth0,helpers,partials,data) {
    var stack1;

  return "style=\"background-image:url('"
    + this.escapeExpression(this.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.topRunners : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.silkUrl : stack1), depth0))
    + "')\"";
},"5":function(depth0,helpers,partials,data) {
    var stack1;

  return this.escapeExpression(this.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.topRunners : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.ageFormatted : stack1), depth0));
},"7":function(depth0,helpers,partials,data) {
    var stack1;

  return "("
    + this.escapeExpression(this.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.topRunners : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.pastPerformance : stack1), depth0))
    + ")";
},"9":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.escapeExpression;

  return alias1(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_abbr_box",{"name":"translate","hash":{},"data":data}))
    + ": "
    + alias1(this.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.topRunners : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.postPosition : stack1), depth0));
},"11":function(depth0,helpers,partials,data) {
    return "m-h2h__vs--h3h";
},"13":function(depth0,helpers,partials,data) {
    var stack1;

  return "style=\"background-image:url('"
    + this.escapeExpression(this.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.topRunners : depth0)) != null ? stack1["1"] : stack1)) != null ? stack1.silkUrl : stack1), depth0))
    + "')\"";
},"15":function(depth0,helpers,partials,data) {
    var stack1;

  return this.escapeExpression(this.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.topRunners : depth0)) != null ? stack1["1"] : stack1)) != null ? stack1.ageFormatted : stack1), depth0));
},"17":function(depth0,helpers,partials,data) {
    var stack1;

  return "("
    + this.escapeExpression(this.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.topRunners : depth0)) != null ? stack1["1"] : stack1)) != null ? stack1.pastPerformance : stack1), depth0))
    + ")";
},"19":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.escapeExpression;

  return alias1(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_abbr_box",{"name":"translate","hash":{},"data":data}))
    + ": "
    + alias1(this.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.topRunners : depth0)) != null ? stack1["1"] : stack1)) != null ? stack1.postPosition : stack1), depth0));
},"21":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "                <div class=\"c-grid c-countryList__content m-h2h__content\">\n                    <div class=\"c-grid__col is-1-4 text-center\"></div>\n\n                    <div class=\"c-grid__col is-1-2 text-center\">\n                        <span class=\"c-silk c-silk--large inline-block mt2\" "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.topRunners : depth0)) != null ? stack1["2"] : stack1)) != null ? stack1.silkUrl : stack1),{"name":"if","hash":{},"fn":this.program(22, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "></span>\n                        <div class=\"m-h2h__runner\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.topRunners : depth0)) != null ? stack1["2"] : stack1)) != null ? stack1.name : stack1), depth0))
    + "</div>\n                        <div class=\"m-h2h__details\">"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.topRunners : depth0)) != null ? stack1["2"] : stack1)) != null ? stack1.ageFormatted : stack1),{"name":"if","hash":{},"fn":this.program(24, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.topRunners : depth0)) != null ? stack1["2"] : stack1)) != null ? stack1.genderFormatted : stack1), depth0))
    + " "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.topRunners : depth0)) != null ? stack1["2"] : stack1)) != null ? stack1.pastPerformance : stack1),{"name":"if","hash":{},"fn":this.program(26, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n                        <div class=\"m-h2h__details\">"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.topRunners : depth0)) != null ? stack1["2"] : stack1)) != null ? stack1.postPosition : stack1),{"name":"if","hash":{},"fn":this.program(28, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n                        <div class=\"m-h2h__btn\">\n                            <a data-idRunner=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.topRunners : depth0)) != null ? stack1["2"] : stack1)) != null ? stack1.idRunner : stack1), depth0))
    + "\" data-idRace=\""
    + alias2(alias1((depth0 != null ? depth0.idRace : depth0), depth0))
    + "\" data-betType=\"WIN\" role=\"button\" class=\"c-btn c-btn--block c-btn--odds c-btn--narrow\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.topRunners : depth0)) != null ? stack1["2"] : stack1)) != null ? stack1.fixedOddsWin : stack1), depth0))
    + "</a>\n                        </div>\n                    </div>\n\n                    <div class=\"c-grid__col is-1-4 text-center\"></div>\n                </div>\n";
},"22":function(depth0,helpers,partials,data) {
    var stack1;

  return "style=\"background-image:url('"
    + this.escapeExpression(this.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.topRunners : depth0)) != null ? stack1["2"] : stack1)) != null ? stack1.silkUrl : stack1), depth0))
    + "')\"";
},"24":function(depth0,helpers,partials,data) {
    var stack1;

  return this.escapeExpression(this.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.topRunners : depth0)) != null ? stack1["2"] : stack1)) != null ? stack1.ageFormatted : stack1), depth0));
},"26":function(depth0,helpers,partials,data) {
    var stack1;

  return "("
    + this.escapeExpression(this.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.topRunners : depth0)) != null ? stack1["2"] : stack1)) != null ? stack1.pastPerformance : stack1), depth0))
    + ")";
},"28":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.escapeExpression;

  return alias1(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_abbr_box",{"name":"translate","hash":{},"data":data}))
    + ": "
    + alias1(this.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.topRunners : depth0)) != null ? stack1["2"] : stack1)) != null ? stack1.postPosition : stack1), depth0));
},"30":function(depth0,helpers,partials,data) {
    return "    <div data-empty-state></div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.data : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(30, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true});