'use strict';

import $       from 'jquery';
import cookies from 'components/helpers/docCookies';

/**
 * ajaxSetup
 * @constructor
 *
 * @requires $
 * @requires cookies
 *
 * @description
 *  Overrides Ajax settings
 */
export const ajaxSetup = function () {
    let token = cookies.getItem('XSRF-TOKEN');

    if (token) {
        $.ajaxSetup({
            headers: {'X-XSRF-TOKEN': token}
        });
    }
};
