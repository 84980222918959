import $    from 'jquery';

var LoadingManager = {

    DOM: {
        container: $('[data-loader-container]'),
        indicator: $('[data-loader-indicator]')
    },

    //shows loader on top of all other elements to block any interjections
    onTop: false,

    /**
     * Transform loader element from splash to module loader or vice-versa
     * @param splash
     */
    transform: function(splash) {
        splash = splash || false;
        this.DOM.container.toggleClass('c-loading--splash', splash);
        this.DOM.indicator.toggleClass('c-loadingIndicator--light', splash);
        return this;
    },

    show: function() {
        this.DOM.container.toggleClass(`isVisible ${this.onTop ? 'c-loading--onTop' : ''}`, true);
    },

    hide: function() {
        this.DOM.container.toggleClass('isVisible c-loading--onTop', false);
    }
};

export default LoadingManager;
