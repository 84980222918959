'use strict';

import _                from 'underscore';
import Backbone         from 'backbone';
import template         from 'components/betting/bet_placing_error.tpl.hbs';
import templatePrompt   from 'components/betting/bet_placing_error_prompt_action.tpl.hbs';
import app              from 'components/core/application';

/**
 * BetPlacingError view Controller
 * @name BetPlacingErrorView
 * @constructor
 *
 * @requires Backbone
 * @requires template
 *
 * @description
 *     BetPlacingError View Controller
 *
 * @returns Function - Constructor function for BetPlacingError view
 */
var BetPlacingErrorView = Backbone.View.extend(
    _.extend({}, {

        className: 'l-scrollV',

        template: template,

        events: {
            'click a[data-dialog-closeBtn]': 'closeBtnFn',
            'click a[data-dialog-resubmit]:not(".c-btn--disabled")':'resubmit',
            'click a[data-dialog-deposit]:not(".c-btn--disabled")': 'depositDialog',
            'click a[data-dialog-cancel]': 'cancel'
        },

        /**
         * @method initialize
         * @description
         *      Initialize view with options/data
         *
         * @param data
         */
        initialize: function(data) {
            this.data = {};
            this.DOM = {};

            if(!data) {
                throw new Error('Error data is required');
            }
            this.data = data;

            //todo: cover with a test
            if (data.callback || data.hasOwnProperty('triggerEvent')) {
                this.data.primaryBtnClass = data.primaryBtnClass || 'primary';
                this.setPromptTemplate();
                this.data.cancelBtn = true;
            }
        },

        /**
         * todo: cover with a test
         * @method setPromptTemplate
         * @description
         *      Changes default template to a template with prompt buttons
         */
        setPromptTemplate: function() {
            this.template = templatePrompt;
        },

        /**
         * @method render
         * @description
         *      Render view template and populate with data (if passed)
         *
         * @returns {BetPlacingErrorView}
         */
        render: function() {
            this.$el.html(this.template(this.data));
            this.DOM.resubmitBtn = this.$('[data-dialog-resubmit]');
            return this;
        },

        /**
         * @method closeBtnFn
         * @description
         *      Callback function for a click event handler on close btn
         *
         * @param e
         */
        closeBtnFn: function() {
            this.trigger('dialog:close:btn');
            app.trigger('dialog:secondary:close');
        },

        /**
         * todo: cover with a test
         * @method cancel
         * @description
         *      Callback function for a click event handler on the cancel btn
         *
         * @param e
         */
        cancel: function(e) {
            this.trigger('cancel');
        },

        /**
         * todo: cover with a test
         * @method resubmit
         * @description
         *      Callback function for a click event handler on the ok/resubmit btn
         *
         * @param e
         */
        resubmit: function(e) {
            this.DOM.resubmitBtn.removeClass('c-btn--primary').addClass('c-btn--disabled');
            this.trigger('resubmit');
        },

        depositDialog: function (e) {
            this.DOM.resubmitBtn.removeClass('c-btn--primary').addClass('c-btn--disabled');

            if (this.data.hasOwnProperty('triggerEvent')) {
                app.trigger(this.data.triggerEvent);
                this.trigger('cancel');
            }
        },
        /**
         * @method close
         * @description
         *      Calls remove and undelegateEvents methods on a view to clean up html and events;
         */
        close: function() {
            this.remove();
            this.undelegateEvents();
        }
    })
);

export default BetPlacingErrorView;
