'use strict';

import _                       from 'underscore';
import $                       from 'jquery';
import Backbone                from 'backbone';
import betslipMethods          from 'components/betting/betslip.methods';

/**
 * Betslip view Controller
 * @name Betslip
 * @constructor
 *
 * @requires Underscore
 * @requires jQuery
 * @requires Backbone
 * @requires app
 * @requires template
 * @requires helpers
 * @requires user
 * @requires maybe
 *
 * @description
 *     Betslip View Controller
 *
 * @returns Function - Constructor function for Betslip view
 */
var Betslip = Backbone.View.extend(
    _.extend({}, betslipMethods, {

    }));

export default Betslip;
