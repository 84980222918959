import _                   from 'underscore';
import app                 from 'components/core/application';
import Backbone            from 'backbone';
import template            from 'components/betting/button.tpl.hbs';
import BettingService      from 'components/betting/betting.service';
import BackboneCloseView   from 'components/core/mixins/Backbone.CloseView';

const ButtonView = Backbone.View.extend(
    _.extend({}, BackboneCloseView, {

        events: {
            'click': 'fire'
        },

        template: template,

        initialize: function (opt) {
            this.DOM = {};
            this.options = {};
            _.extend(this.options, opt || {});

            this.addListeners();
        },

        render: function () {
            this.$el.html(this.template(this.options));
            this.DOM.button = this.$('button');
            this.DOM.extra = this.$('[data-extra]');

            this.onRender();
            return this;
        },

        onRender: function () {
            if (this.options.extraCssClass) {
                this.addExtraClass(this.options.extraCssClass)
            }
        },

        addListeners: function () {
            this.listenTo(this, 'increase', () => {
                this.DOM.button.addClass('isWorse');
                this.DOM.button.on('webkitanimation', () => {
                    this.DOM.button.removeClass('isWorse');
                })
            });

            this.listenTo(this, 'decrease', () => {
                this.DOM.button.addClass('isBetter');
                this.DOM.button.on('webkitanimation', () => {
                    this.DOM.button.removeClass('isBetter');
                })
            });

            this.listenTo(this, 'button:deselect', function() {
                this.DOM.button.prop('disabled', false).removeClass('isSelected');
            });

            this.listenTo(this.options.model, 'change:isSelected', this.manageSelection);
        },

        animate: function(direction) {
            this.render().trigger(direction);
        },

        updateOptions: function(opt={}) {
            _.extend(this.options, opt);
            return this;
        },

        manageSelection: function() {
            if(this.options.model.attributes.isSelected && this.options.model.attributes.selectedCategory === this.options.betCategory) {
                if(this.DOM.button) this.DOM.button.prop('disabled', false);
                //set enabled to 'true' to allow clicks on buttons since on page reload this attribute is set to false.
                this.options.enabled = true;

                if (!this.DOM.button.hasClass('isSelected')) this.DOM.button.addClass('isSelected');

            } else if(this.options.model.attributes.selectedCategory === 'n/a') {//means that betslip is empty and we need to remove selected state
                if(this.DOM.button) this.DOM.button.prop('disabled', false).removeClass('isSelected');
                this.options.enabled = true;
            } else {
                if(this.DOM.button) this.DOM.button.prop('disabled', true).removeClass('isSelected');
                this.options.enabled = false;
            }
        },

        fire: function (e) {
            if (!this.options.enabled) return;

            const idRunner = this.options.model.get('idRunner');

            if (app.betslip.hasBet(idRunner)) {
                app.betslip.removeBet(idRunner);
                return;
            }

            const toBetSlip = !app.betslip.isEmpty() && !['WS', 'PS', 'WPS'].includes(this.options.betType);

            this.trigger('button:fire', {idRunner: idRunner, toBetSlip: toBetSlip});

            // Show as selected immediately for any consecutive bets
            // First bet triggers dialog and closing/betting does not
            // trigger model change, hence we don't class or `disabled`
            if (this.DOM.button.hasClass('isSelected')) {
                this.DOM.button.removeClass('isSelected');
            } else if (!app.betslip.isEmpty()) {
                this.DOM.button.prop('disabled', true);
                this.DOM.button.addClass('isSelected');
            }

            BettingService.showQuickbet({
                buttonView: this,
                attributes: {
                    idRunner: idRunner,
                    betType: this.options.betType,
                    betCategory: this.options.betCategory,
                    toteCurrency: this.options.toteCurrency,
                    raceCollection: this.options.model.collection,
                    isSpecialBet: false,
                    isHeadToHead: false,
                },
                runnerModel: this.options.model
            });
        },

        addExtraClass: function (className) {
            this.DOM.extra.addClass(className)
        }
    })
);

export default ButtonView;
