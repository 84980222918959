import _                    from 'underscore';
import $                    from 'jquery';
import app                  from 'components/core/application';
import Backbone             from 'backbone';
import BackboneCloseView    from 'components/core/mixins/Backbone.CloseView';

/**
 * Backbone select component view Controller
 * @name cSelect
 * @constructor
 *
 * @requires Backbone
 *
 * @description
 *     Responsive Backbone select component that allows images as options values
 *
 * @returns Function - Constructor function for Backbone select component view
 */

var cSelect = Backbone.View.extend(
    _.extend({}, BackboneCloseView, {

        defaultText: 'Please, select',

        /**
         * @param settings Object
         * settings.label will be used instead of template hardcoded label if the template supports it
         */
        initialize: function(settings) {
            if(!settings.template) {
                throw new Error('Template is required');
            }

            //todo: if each option is an object check that properties are values/title
            //Alternatively it may sense to allow passing prop mask {value: 'customValueProp', title: 'customTitleProp'}, however it seem to be more complex and not sure if there will be a case where it woul dbe useful
            if(settings.options && !_.isArray(settings.options)) {
                throw new Error('Options should be an array');
            }

            this.val = null;
            this.id = settings.id || null;
            this.template = settings.template;
            this.options = settings.options || null;
            this.disabled = settings.disabled || null;//allows to init select without options
            this.selected = settings.selected || null;
            this.label = settings.label || null;
            this.autocomplete = settings.autocomplete || 'off';
            this.event = settings.event || null;
            this.defaultText = settings.defaultText || app.polyglot.t('label_please_select');
            this.dataAttributes = (settings.dataAttributes) ? settings.dataAttributes.join(' ') : null;
            this.rerenderOnSelected = settings.rerenderOnSelected || false;
            this.rerenderElAttribute = settings.rerenderElAttribute || 'data-selected'
        },

        events: {
            'change [data-select]' : 'onSelected'
        },

        render: function() {
            let defaultSelected = false;
            //check if select options have been provided
            if(!this.disabled && !this.options) {
                throw new Error('Select options are required');
            }

            if(!this.selected) {
                this.options.unshift(this.defaultText);
                this.selected = this.options[0];
                defaultSelected = true;
            }

            this.$el.html(this.template({
                options: this.options,
                selected: this.selected,
                id: this.id,
                label: this.label,
                dataAttributes: this.dataAttributes,
                defaultSelected: defaultSelected,
                disabled: this.disabled,
                autocomplete: this.autocomplete,
            }));

            this.selectedEl = this.$el.find('[data-selected]');
            this.rerenderEl = this.$el.find('[' + this.rerenderElAttribute + ']');
            this.selectedElParent = this.selectedEl.parent();
            return this;
        },

        onSelected: function(e) {
            this.val = $(e.target).val();
            var selectedOption = this.options[e.target.selectedIndex];
            let isObject = selectedOption === Object(selectedOption);

            //if data options are objects, use objects title property as selected
            if(isObject) {
                //if rerendering will happen, just pass an object as it is.
                if(this.rerenderOnSelected) {
                    this.rerenderEl.html(selectedOption);
                    //pass value only
                } else {
                    this.rerenderEl.html(selectedOption.title);
                }
            } else {
                this.rerenderEl.html(this.val);
            }

            this.selectedEl.toggleClass('c-select--isSelected', e.target.selectedIndex !== 0);
            this.selected = (isObject)? selectedOption : this.val;

            //todo: move html for 'selected' part to a separate template in order to reduce rerendering to a few lines of html only. No need to rerender the whole select template
            //rerender template - thi sis needed when template is complex, e.g. country select where flags depend on selected value.
            if(this.rerenderOnSelected) {
                this.render();
            }

            if (this.selectedElParent) {
                this.selectedElParent.toggleClass('c-select--isSelectedParent', e.target.selectedIndex !== 0);
            }

            if(this.event) {
                this.trigger(this.event, (isObject)? selectedOption : this.val);
            }
        },

        getVal: function () {
            return this.val;
        },

        setVal: function (val) {
            if(val === this.val) return;
            this.selected = val;
            this.val = val;
            this.render();
        }

    }), Backbone.Events
);

export default cSelect;
