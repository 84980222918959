import $                    from 'jquery';
import Backbone             from 'backbone';
import app                  from 'components/core/application';
import template             from 'components/core/app-footer.tpl.hbs';
import MyBetsBar            from 'components/mybets-bar/mybets-bar';

/**
 * Footer Constructor
 * @module appFooter
 * @constructor
 *
 * @requires Backbone
 * @requires app
 * @requires jQuery
 * @requires template
 *
 * @description
 *     Handling Footer interactions.
 *
 * @returns {Function} - Constructor function for Footer
 */
const Footer = Backbone.View.extend({

    el: app.DOM.appFooter,

    template: template,

    events: {
    },

    initialize: function () {
        this.data = {};

        this.myBetsBar = new MyBetsBar();
    },

    render: function () {
        this.$el.html(this.template(this.data));

        this.DOM = {
            mybets_bar: this.$('[data-mybets-bar]')
        };

        this.DOM.mybets_bar = this.myBetsBar.render()

        return this;
    },
});

export default Footer;
