var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "        <div class=\"c-promotionsTerms__item\" data-terms=\""
    + alias2(alias1((depth0 != null ? depth0.idPromotion : depth0), depth0))
    + "\">\n            <i class=\"rb-icon rb-icon--info\"></i>\n            <b>"
    + alias2(alias1((depth0 != null ? depth0.labelTranslation : depth0), depth0))
    + ".</b>\n            "
    + alias2(alias1((depth0 != null ? depth0.terms : depth0), depth0))
    + "\n"
    + ((stack1 = __default(require("../../components/helpers/handlebar-helpers/compare.js")).call(depth0,(depth0 != null ? depth0.promotionType : depth0),"!==","custom",{"name":"compare","hash":{},"fn":this.program(2, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../components/helpers/handlebar-helpers/compare.js")).call(depth0,(depth0 != null ? depth0.promotionType : depth0),"===","custom",{"name":"compare","hash":{},"fn":this.program(4, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n";
},"2":function(depth0,helpers,partials,data) {
    var alias1=this.escapeExpression;

  return "                <span data-dialog=\""
    + alias1(this.lambda((depth0 != null ? depth0.label : depth0), depth0))
    + "\" class=\"c-form__link c-form__link--primary\">"
    + alias1(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_full_conditions",{"name":"translate","hash":{},"data":data}))
    + "</span>\n";
},"4":function(depth0,helpers,partials,data) {
    var alias1=this.escapeExpression;

  return "                <a href=\""
    + alias1(this.lambda((depth0 != null ? depth0.termsUrl : depth0), depth0))
    + "\" class=\"c-form__link c-form__link--primary\" data-link>"
    + alias1(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_full_conditions",{"name":"translate","hash":{},"data":data}))
    + "</a>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"c-promotionsTerms\">\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.racePromotions : depth0),{"name":"each","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});