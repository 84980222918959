'use strict';

import _                           from 'underscore';
import $                           from 'jquery';
import app                         from 'components/core/application';
import Backbone                    from 'backbone';
import template                    from 'modules/race/race-promotions.tpl.hbs';
import raceHelpers                 from 'modules/race/race.helpers';
import ViewScroll                  from 'components/core/mixins/Backbone.ViewScroll';
import helpers                     from 'components/helpers/helpers';
import BackboneCloseView           from 'components/core/mixins/Backbone.CloseView';

/**
 * Jackpot and bonus view
 *
 * @name PromotionsView
 * @constructor
 *
 * @requires Underscore
 * @requires jQuery
 * @requires Backbone
 * @requires template
 * @requires raceHelpers
 * @requires helpers
 * @requires BackboneCloseView
 *
 * @description
 * 		PromotionsView view
 *
 * @returns {Function} - Constructor function for PromotionsView
 */

const PromotionsView = Backbone.View.extend(
    _.extend({}, BackboneCloseView, ViewScroll, {

        template: template,

        events: {
            'click [data-promo]': 'scrollToTerms'
        },

        /**
         * @method initialize
         *
         * @param options
         */
        initialize: function (options) {
            this.data = options.racePromotions;
            this.data.forEach((item)=>{
                if (item.promotionType !== 'custom') {
                    item.labelTranslation = app.polyglot.t('label_promotion_'+item.label);
                } else {
                    item.labelTranslation = item.label;
                }
            });
        },

        render: function () {
            this.$el.html(this.template({racePromotions: this.data}));
            return this;
        },

        scrollToTerms: function(e) {
            var idPromotion = $(e.target).data('promo'),
                termsDom = document.querySelectorAll(`[data-terms='${idPromotion}']`)[0],
                header = $('#app-header');

                termsDom.scrollIntoView();

                if (!this.isInViewport(header[0])) {
                    header[0].scrollIntoView();
                }


        }

    })
);

export default PromotionsView;
