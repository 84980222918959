'use strict';

import _                       from 'underscore';
import app                     from 'components/core/application';
import user                    from 'components/user/user';

var auth = {
    manageLogin: function() {
        app.trigger('user:login:required');
    },

    loginRequired: function () {
        app.trigger('user:login:required');
    },

    isUserLoggedIn: function () {
        return user.isLoggedIn;
    },

    // Proxy
    ifLoggedIn: function (fn, context) {
        var self = this;

        if (!_.isFunction(fn)) {
            return undefined;
        }

        var args =  [].slice.call(arguments, 2);

        var proxy = function () {
            if (!user.isLoggedIn) {
                return self.manageLogin();
            }
            return fn.apply(context, args.concat([].slice.call(arguments)));
        };

        return proxy;
    },

    forceLogin: function() {
        if (!user.isLoggedIn) {
            this.manageLogin();
        }
    }
};

export default auth;
