import _                    from 'underscore';
import $                    from 'jquery';
import app                  from 'components/core/application';
import Backbone             from 'backbone';
import template             from 'modules/sessions/session-manager.tpl.hbs';
import helpers              from 'components/helpers/helpers';
import moment               from 'moment-timezone';
import BackboneCloseView    from 'components/core/mixins/Backbone.CloseView';


const SessionManager = Backbone.View.extend(

    _.extend({}, {

        className: 'm-session',

        template: template,

        events: {
            'click': 'stopPropagation',
            'submit form': 'submit',
            'change form input': 'buildData',
            'click [data-info]': 'labelFn'
        },

        initialize: function (opt) {
            this.DOM = {};
            this.data = {};
            this.options = {
                closeOnOutsideClick: false
            };

            this.buttonFlag = false;
            // urls will be used once the dialog is closed to redirect the user
            this.model = opt.model;
            this.data.sessions = opt.list;
            this.data.sessions = _.map(opt.list, function (session) {
                return {
                    channel: app.polyglot.t('label_channel_' + (session.channel === 'WEB' ? 'default' : 'IPH')),
                    os: helpers.getOS(session.user_agent),
                    browser: helpers.getBrowser(session.user_agent),
                    time: moment.unix(session.start_time).format('L, LT'),
                    idSession: session.id_session
                }
            });

            this.overlay = app.overlay;
        },

        render: function () {
            this.$el.html(this.template(this.data));

            this.overlay.showComponent(this);

            this.DOM.form = this.$('form');
            this.DOM.list = this.$('[data-list]');
            this.DOM.scroll = this.$('[data-scroll]');
            this.DOM.title = this.$('[data-title]');
            this.DOM.toggle = this.$('[data-toggle]');
            this.DOM.controls = this.$('[data-controls]');
            this.DOM.submit = this.$('[data-submit]');

            return this;
        },

        // fired when user click on session text, act as checkbox label
        labelFn: function (e) {
            var $input = $(e.currentTarget).next().find('input')
            $input.prop('checked', !$input.prop('checked'));
            $input.trigger('change');
        },

        toggleButton: function () {
            if ((_.isEmpty(this.sessions) && this.hideDialog === false) && this.buttonFlag) {
                this.DOM.submit.html(app.polyglot.t('label_close'));
                this.buttonFlag = false;
            }
            
            if (!_.isEmpty(this.sessions) || this.hideDialog && !this.buttonFlag) {
                if (!_.isEmpty(this.sessions)) {
                    this.DOM.submit.html(app.polyglot.t('label_button_logout_session'))
                } else {
                    this.DOM.submit.html( app.polyglot.t('label_ok'));
                }
                this.buttonFlag = true;
            }
        },

        submit: function (e) {
            e.preventDefault();
            if (this.buttonFlag) {
                this.sessions = this.sessions === null ? [] : this.sessions;
                this.model.set('sessions', this.sessions);
                this.model.set('hide_sessions', this.hideDialog);
                this.model.save();
            } else {
                this.closeSessionManager();
            }
        },

        /**
         * serialize the form, extract the data from the generated string
         */
        buildData: function () {
            var serialize = this.DOM.form.serialize(),
                hideExp = /hideSessionDialog/g;
            this.sessions = serialize.match(/(\d+)/g);
            this.hideDialog = hideExp.test(serialize);
            this.toggleButton();
        },

        closeSessionManager: function () {
            app.overlay.closeDialog();
            app.trigger('navigate', this.model.data.url);
            location.reload();
        },

        /**
         * Prevents the overlay from closing
         * @param e
         */
        stopPropagation: function (e) {
            e.stopPropagation();
        }
    }, BackboneCloseView)
);

export default SessionManager;
