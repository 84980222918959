'use strict';

import _                           from 'underscore';
import $                           from 'jquery';
import app                         from 'components/core/application';
import Backbone                    from 'backbone';
import maybe                       from 'components/helpers/maybe';
import H2HButtonView               from 'components/betting/button_h2h';
import H2HModel                    from 'modules/h2h/h2h.model';
import template                    from 'modules/race/race-h2h-item.tpl.hbs';
import BackboneCloseView           from 'components/core/mixins/Backbone.CloseView';
import bettingService              from 'components/betting/betting.service';

/**
 * Race h2h bet view
 *
 * @name RaceH2HView
 * @constructor
 *
 * @requires Underscore
 * @requires jQuery
 * @requires Backbone
 * @requires template
 * @requires BackboneCloseView
 *
 * @description
 * 		RaceNumbers view
 *
 * @returns {Function} - Constructor function for RaceH2HView
 */

const RaceH2HView = Backbone.View.extend(
    _.extend({}, BackboneCloseView, {

        tagName: 'li',

        template: template,

        events: {
            'click [data-quickbet]': 'quickbet'
        },

        initialize: function () {
            this.listenTo(app.betslip, 'model:isSelected betslip:change', this.updateSelectedRunners);
            this.initBetButtons();
        },

        initBetButtons: function() {
            this.closeButtonViews();
            this.buttonViews = [];
            this.runnersCollection = this.model.get('runners_collection');
            this.runnersCollection.forEach(runner => {
                runner.collection = this.model.collection;
            });

            _.each(this.runnersCollection.models, runner => {
                runner.set({betButtonId: `bet_btn_${runner.get('idRunner')}`}, {silent: true});
                this.buttonViews.push(new H2HButtonView({model: runner}));
            });
        },

        render: function () {
            this.$el.html(this.template({
                data: this.prepareRunners()
            }));

            this.attachButtons();
            //update selected buttons
            this.updateSelectedRunners();

            return this;
        },

        prepareRunners: function() {
            var runners = this.runnersCollection.toJSON();
            if(runners.length < 1) return [];

            //group runners by 2 in subarrays
            return _.chain(runners)
            .groupBy(function(element, index) {
                return Math.floor(index/2);
            })
            .toArray()
            .value();
        },

        attachButtons: function() {
            _.each(this.buttonViews, button => {
                this.$(`[data-id="bet_btn_${button.model.get('idRunner')}"]`).html(button.render().$el);
            });
        },

        updateSelectedRunners: function() {
            // Get ids for runners who have been added to betslip
            var selected_runners_ids = app.betslip.getBetIds();
            var bets = app.betslip.getBets(true);
            var race_is_open = this.model.collection.data.race.raceStatus === 'OPN';

            this.runnersCollection.forEach(model => {
                let contains = _.contains(selected_runners_ids, model.get('idRunner'));
                model.set({
                    isSelected: contains
                });
            });
        },

        closeButtonViews: function() {
            _.each(this.buttonViews, button => {
                button.close();
            });
        },

        onClose: function() {
            this.closeButtonViews();
        }
    })
);

export default RaceH2HView;
