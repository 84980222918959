var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
    var stack1;

  return "        <a class=\"c-link c-banner__terms\" href=\""
    + this.escapeExpression(this.lambda((depth0 != null ? depth0.terms_url : depth0), depth0))
    + "\" data-term>"
    + ((stack1 = __default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_t_and_c",{"name":"translate","hash":{},"data":data})) != null ? stack1 : "")
    + "</a>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "<div class=\"c-banner\" data-banner>\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.displayTermsLink : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "    <a class=\"c-banner__link\" target=\""
    + alias2(alias1((depth0 != null ? depth0.target : depth0), depth0))
    + "\" href=\""
    + alias2(alias1((depth0 != null ? depth0.url : depth0), depth0))
    + "\"  data-link>\n        <picture>\n            <source srcset=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.image_path : depth0)) != null ? stack1.l : stack1), depth0))
    + "\" media=\"(min-width: "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.breakpoints : depth0)) != null ? stack1.big : stack1), depth0))
    + "px)\">\n            <source srcset=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.image_path : depth0)) != null ? stack1.m : stack1), depth0))
    + " 2x, "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.image_path : depth0)) != null ? stack1.l : stack1), depth0))
    + " 3x\" media=\"(min-width: "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.breakpoints : depth0)) != null ? stack1.medium : stack1), depth0))
    + "px)\">\n            <img class=\"c-banner__image\" style=\"width:100%\" src=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.image_path : depth0)) != null ? stack1.s : stack1), depth0))
    + "\" srcset=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.image_path : depth0)) != null ? stack1.m : stack1), depth0))
    + " 2x, "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.image_path : depth0)) != null ? stack1.l : stack1), depth0))
    + " 3x\">\n        </picture>\n    </a>\n</div>\n\n";
},"useData":true});