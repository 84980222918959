/**
 * Context menu logic for the bonus section
 */

'use strict';

import Router               from 'router';
import app                  from 'components/core/application';

const BonusContext = function () {

    let afterRouteChange = (params) => {
        if (params.current.indexOf('bonus') > -1) {
            var showContextMenu = function () {
                var actions = [
                    {
                        event: 'click',
                        selector: '#claim',
                        domSelector: 'id=claim',
                        title: app.polyglot.t('h_claim_bonus'),
                        icon: 'rb-icon--trophy',
                        cb: function () {
                            app.router.navigateTo('bonus/claim');
                        }
                    },
                    {
                        event: 'click',
                        selector: '#pending',
                        domSelector: 'id=pending',
                        title: app.polyglot.t('h_pending_bonuses'),
                        icon: 'rb-icon--bonus-overview',
                        cb: function () {
                            app.router.navigateTo('bonus/overview');
                        }
                    },
                    {
                        event: 'click',
                        selector: '#progress',
                        domSelector: 'id=progress',
                        title: app.polyglot.t('h_bonus_progress'),
                        icon: 'rb-icon--bonus-progress',
                        cb: function () {
                            app.router.navigateTo('bonus/progress/internal');
                        }
                    },
                    {
                        event: 'click',
                        selector: '#cancel',
                        domSelector: 'id=cancel',
                        title: app.polyglot.t('label_cancel'),
                        icon: 'rb-icon--cancel',
                        cb: function () {
                            app.overlay.closeComponent();
                        }
                    }
                ];
                app.overlay.actionSheet({actions: actions}).render();
            };

            app.subHeader.rightMenu(true);
            app.subHeader.on('bonusContextMenu:show', showContextMenu, this);

            //hide loader on navigation
            app.loadingManager.hide();
        }
    };

    var router = Router.getInstance();
    router.on('after:route:change', afterRouteChange);
};

export { BonusContext };
