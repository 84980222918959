'use strict';

import $              from 'jquery';
import _              from 'underscore';
import Backbone       from 'backbone';
import app            from 'components/core/application';
import auth           from 'components/auth/auth';

/**
 * Error handler
 * @constructor
 *
 * @requires app
 *
 * @description
 *  Sets up listeners to different error messages. Ex:
 *  app.trigger('error:critical', 'Error message') would trigger
 *  errorCritical function via 'error:critical' listener
 */
export default function () {
    /**
     * Array of codes not to be processed globally
     * 132 - WRONG_CREDENTIALS
     * 159 - NEW_TERMS
     * 136 - TIME_OUT
     * 135 - SELF_RESTRICTED
     * @type {number[]}
     */
    var white_list = [132, 134, 135, 136, 159, 186];

    /**
     * Array of api urls that don't need the global loading animation
     * @type {string[]}
     */
    var api_white_list = [
        '/rest/v2/cards/runner_past_performance/',
        '/rest/v2/cards/runner_statistics/',
        '/rest/v1/users/me'
    ];

    /**
     * Intercept ajax response globally to check against response codes
     * todo: check here status 'error' or 'fail' and in case of 'error' process response globally
     * todo: move beforeSend and complete to a relevant place
     */
    $.ajaxSetup({
        beforeSend: function (jqXHR, settings) {
            this.hideGlobalLoader = _.find(api_white_list, (apiUrl) => {
                return (this.url.indexOf(apiUrl) > -1)
            });

            //if it is a betslip url and type is 'PUT' or 'DELETE' - do not show loader
            if (this.url.indexOf('/rest/v2/betting/betslip') > -1 && ['PUT', 'DELETE'].indexOf(settings.type) > -1) this.hideGlobalLoader = true;

            //show loader on top of all elements to block interrections when bet is placed
            app.loadingManager.onTop = ['/ajax/mbetslip/send/', '/rest/v2/betting/betslip'].indexOf(this.url) > -1 && settings.type === 'POST';

            //also hide if this is fixed_bet and freeBet get request made before adding bet to betslip bypassing the quickbet dialog
            if(settings.betslip) {
                this.hideGlobalLoader = _.find(['/ajax/freebets/freeBetsForRace', '/ajax/races/bettingDialog'], (apiUrl) => {
                    return (this.url.indexOf(apiUrl) > -1)
                });
            }

            if (!this.hideGlobalLoader) {
                app.trigger('app:loading:show');
            }
        },
        complete: function (jqXHR, String) {
            if (!this.hideGlobalLoader) {
                //hide it when stack is empty
                setTimeout(function() {
                    app.trigger('app:loading:hide');
                }, 300);
            }
        },
        statusCode: {
            401: function (jqXHR) {
                var responseText = $.parseJSON(jqXHR.responseText);

                if (responseText.data && responseText.data.code) {
                    if (white_list.indexOf(responseText.data.code) > -1) {
                        return;
                    }

                    //for jurisdiction errors there should be no redirect to login page
                    if ([192, 193].indexOf(responseText.data.code) > -1) {
                        return;
                    }
                }

                //no need to redirect to /login if user is already there
                if (Backbone.history.getFragment() === 'login') {
                    return;
                }

                auth.loginRequired();
            },
            403: function () {
                // 403 -- Access denied
                auth.loginRequired();
            }
        }
    });

    var errorCritical = function (message) {
        throw new Error(message);
    };

    app.on('error:critical', errorCritical);
};
