"use strict";

import _ from 'underscore';
import cookies from "components/helpers/docCookies";
import user from "components/user/user";
import app from "components/core/application";
import moment from "moment-timezone";
import helpers from "components/helpers/helpers";
import appSettings, {
    RB_CONSENT,
    MARKETING_COOKIE_DEFAULT_VALUE,
    TARGETING_COOKIE_DEFAULT_VALUE,
    FUNCTIONAL_COOKIE_DEFAULT_VALUE,
    PERFORMANCE_COOKIE_DEFAULT_VALUE,
    TARGETING_COOKIE_NAME_MAP,
    FUNCTIONAL_COOKIE_NAME_MAP,
    PERFORMANCE_COOKIE_NAME_MAP

} from "components/app.settings";

let useThirdPartyConsent = !RB_CONSENT;

let thirdPartyCookieNames = {
    targeting: TARGETING_COOKIE_NAME_MAP,
    functional: FUNCTIONAL_COOKIE_NAME_MAP,
    performance: PERFORMANCE_COOKIE_NAME_MAP,
}

function getCookieObjectDefaultValues() {
    return { 'functional':FUNCTIONAL_COOKIE_DEFAULT_VALUE,
             'marketing':MARKETING_COOKIE_DEFAULT_VALUE,
             'performance':PERFORMANCE_COOKIE_DEFAULT_VALUE,
             'targeting':TARGETING_COOKIE_DEFAULT_VALUE };
}

function overridePerformanceCookieValue(consents) {
    // TODO: use PERFORMANCE_COOKIE_DEFAULT_VALUE_OVERRIDE
    let countryMatch = user.data.ipCountry.toUpperCase() === 'DE'
    let newValue = 0;

    return countryMatch ? _.extend(consents, { 'performance': newValue } ) : consents;
}

function prepareCookieObject(consents, existingCookie) {
    return _.extend(overridePerformanceCookieValue(getCookieObjectDefaultValues()), existingCookie, consents);
}

function isAllRejected(consents) {
    let allRejected = false;
    // TODO: use PERFORMANCE_COOKIE_DEFAULT_VALUE_OVERRIDE
    let countryMatch = user.data.ipCountry.toUpperCase() === 'DE'
    let result = Object.keys(consents).filter(key => consents[key] === 1);

    // For countries specified in performanceCookieDefaultValueOverride, e.g. Germany, performance cookie can be rejected. It means that all values can be set to "0"
    if(countryMatch) {
        allRejected = result.length === 0;
    } else {
        // For the rest EU countries performance cookie is alway "1"
        allRejected = result.length === 1;
    }

    return allRejected;
}

function consentDiff(existingCookie, consents) {
    var accepted = {};
    Object.keys(consents).forEach(key => {
        if(existingCookie[key] !== consents[key] && consents[key] === 1) {
            accepted[key] = 1
        }
    });

    return accepted;
}

export function getCookieDefaultState() {
    return overridePerformanceCookieValue(getCookieObjectDefaultValues());
}

export function setCookieFromThirdParty(props) {
    let updatedConsent = {};
    Object.keys(thirdPartyCookieNames).forEach(function(key) {
        updatedConsent[key] = props.indexOf(thirdPartyCookieNames[key]) > -1 ? 1 : 0;
    });
    setCookie(updatedConsent);
}

export function setCookie(consents={}) {
    let existingCookie = getCookie();
    consents = prepareCookieObject(consents, existingCookie);

    let sendEvent = !_.isEqual(existingCookie, consents);

    // If the previous selection is different from th new one, we need to se what changes
    let accepted = {};
    if(sendEvent) accepted = consentDiff(existingCookie, consents);

    // set marketing property to be same as targeting only when using OneTrust
    if (useThirdPartyConsent) {
        consents.marketing = consents.targeting;
    } else {
        consents.functional = consents.marketing;
        consents.targeting = consents.marketing;
        consents.performance = consents.marketing;
    }

    const cookieExpiryDate = user.isLoggedIn
        ? appSettings.cookieExpiryDateInYearsLoggedIn
        : appSettings.cookieExpiryDateInYearsVisitor;
    cookies.setItem(
        appSettings.consentCookie,
        JSON.stringify(consents),
        moment().add(cookieExpiryDate, "years").toDate(),
        "/",
        helpers.getTLDCookieDomain(),
        true
    );

    let allRejected = isAllRejected(consents);

    if(useThirdPartyConsent && sendEvent) {
        if(allRejected) {
            app.trigger("marketing:gdpr:consent:rejected", consents);
        } else {
            app.trigger("marketing:gdpr:consent", consents, accepted);
        }
    }
}

export function getCookie() {
    var marketingCookie = cookies.getItem(appSettings.consentCookie);
    if (_.isUndefined(marketingCookie) || _.isNull(marketingCookie)) {
        return {}
    } else {
        return JSON.parse(decodeURIComponent(marketingCookie))
    }
}

export function triggerCookieAcceptedEvent(value) {
    if (+value) app.trigger("marketing:cookie:accepted");
}
