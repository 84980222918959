import _                from 'underscore';
import docCookies       from 'components/helpers/docCookies';

//raceType filter
var rtFilter = {

    // default state
    filterState: {
        type: {
            G: true,
            J: true,
            T: true,
            D: false
        },
        antepost: {
            special: false
        }
    },

    filterCookieName: 'rbftr',

    filterCookeExpiryDate: new Date(2020, 1, 1),

    getFromCookie: function() {
        var filtersCookie = docCookies.getItem(this.filterCookieName);

        if(filtersCookie) {
            let parsedCookie = {};

            // if the cookie value isn't valid JSON it will throw error and stop the application from running
            try {
                parsedCookie = JSON.parse(filtersCookie);
            } catch (e) {
                console.log('Cookie parsing error: ' + e);
            }

            // checks if the parsed cookie as the expected keys
            if (!_.isUndefined(parsedCookie.type) || !_.isUndefined(parsedCookie.antepost)) {
                this.filterState = JSON.parse(filtersCookie);
            }
        }

        return this;
    },

    /**
     * @method createFilterCookie
     *
     * @description
     *      Create filter cookie if it does not exist and save initial filter state
     *
     * @returns {this}
     */
    createFilterCookie: function() {
        var filtersCookie = docCookies.getItem(self.filterCookieName);

        if(!filtersCookie) {
            var filters = {};
            for (var key in this.filterState) {
                if (this.filterState.hasOwnProperty(key)) {
                    filters[key] = this.filterState[key];
                }
            }

            filters = JSON.stringify(filters);
            docCookies.setItem(this.filterCookieName, filters, this.filterCookeExpiryDate, '/');
        }

        return this;
    },

    saveToCookie: function () {
        var filters = JSON.stringify(this.filterState);
        docCookies.setItem(this.filterCookieName, filters, this.filterCookeExpiryDate, '/');
        return this;
    }
};

export default rtFilter;
