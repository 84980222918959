'use strict';

import _                           from 'underscore';
import Backbone                    from 'backbone';
import RunnersCollection           from 'modules/race/runners.collection';
import transformRunners            from 'modules/race/transform.runners';
import raceHelpers                 from 'modules/race/race.helpers';

var PickRaceCollection = Backbone.Collection.extend({

    url: '/ajax/maccumulation/betslip',

    initialize: function (models, opts) {
        this.options = opts;
        this.setUrl();
    },

    setUrl: function(opts={}) {
        _.extend(this.options, opts);
        this.url = `${this.url}/idRace/${this.options.race_id}/betType/${this.options.bet_type}/betCategory/${this.options.bet_category}`;
        return this;
    },

    parse: function(response) {
        this.data = {
            currency: response.currency,
            minUnitStake: response.minUnitStake,
            maxUnitStake: response.maxUnitStake,
            details: response.details
        };

        this.data.races = [];

        _.each(this.data.details, (race) => {
            let models = [];

            // _.each(race.runners.data, (runner) => {
            //     models.push(new raceitemmodel(runner))
            // });

            let collection = new RunnersCollection(null, {
                race_id: race.idRace,
                join_channel: false,
                bet_type: this.options.bet_type,
                data: transformRunners.mapRunners(race),
                timestamp: race.timestamp
            });

            collection.data.availableOdds = raceHelpers.availableOdds(race.betTypes, race.race.fixedOddsStatus);
            collection.add(_.toArray(race.runners.data));
            this.data.races.push(collection)
        });

        return this.data.races;
    }

});

export default PickRaceCollection;
