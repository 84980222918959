'use strict';
import _                            from 'underscore';
import $                            from 'jquery';
import Backbone                     from 'backbone';
import template                     from 'modules/settings/modify_settings.tpl.hbs';
import user                         from 'components/user/user';
import app                          from 'components/core/application';
import BackboneCloseView            from 'components/core/mixins/Backbone.CloseView';
import SettingsModel                from 'modules/settings/settings.model';
import userSettingsManager          from 'components/user/user.settings-manager';

/**
 * User settings view for modifying settings
 *
 * @name ModifySettingsView
 * @constructor
 *
 * @requires Underscore
 * @requires jQuery
 * @requires Backbone
 * @requires template;
 * @requires app
 * @requires user
 * @requires BackboneCloseView;
 * @requires SettingsModel;
 *
 * @description
 *     User settings view Controller.
 *
 * @returns {Function} - Constructor function for ModifySettingsView
 */

var ModifySettingsView = Backbone.View.extend(
    _.extend({}, BackboneCloseView, {

        className: 'c-itemList',

        template: template,

        events: {
            'click [data-value]': 'submit'
        },

        initialize: function (opts) {
            this.options = _.extend({}, this.options, opts);

            this.model = new SettingsModel();
            this.model.attributes = {
                dialog: {
                    general: userSettingsManager.getGeneral()
                }
            };

            if(this.options.type === 'odds_format') {
                this.selected = user.data.oddsFormat;
                this.menuOptions = [
                    {
                        key: 'base1',
                        value: '2.50'
                    },
                    {
                        key: 'base10',
                        value: '25',
                        hide: user.data.language.toLowerCase() !== 'de'
                    },
                    {
                        key: 'fractional',
                        value: '6/4'
                    }
                ];
            } else if(this.options.type === 'unit_system') {
                this.selected = user.getMetricSystem();
                this.menuOptions = [
                    {
                        key: 'metric',
                        value: app.polyglot.t('label_metric')
                    },
                    {
                        key: 'imperial',
                        value: app.polyglot.t('label_imperial')
                    }
                ];
            }
        },

        render: function() {
            this.$el.html(this.template({menuOptions: this.menuOptions, selected: this.selected}));
            return this;
        },

        submit: function(e) {
            var $el = $(e.currentTarget);
            var value = $el.attr('data-value');

            if(value) {
                if(this.options.type === 'odds_format') {
                    this.model.attributes.dialog.general.oddsFormat = value;
                } else if(this.options.type === 'unit_system') {
                    this.model.attributes.dialog.general.unitSystem = value;
                }

                this.model.save();
            }

        }
    })
);
export default ModifySettingsView;
