import _                            from 'underscore';
import $                            from 'jquery';
import app                          from 'components/core/application';
import ButtonView                   from 'components/betting/button';
import RaceItemTooltip              from 'modules/race/race-item-tooltip';
import maybe                        from 'components/helpers/maybe';

let timeout;
export const tagName = 'li';
export const className = 'c-dataList__row m-race__listItem';

export const events = {
    'click [data-expand]': 'toggleTooltip',
    'click [data-tooltip]': 'toggleTooltip',
    'click [data-tooltip-shoes]': 'manageTooltipShoes',
    'click': 'closeTooltip',
};

export function initialize(opt) {
    
    this.DOM = {};
    this.data = opt.data;
    this.model = opt.model ? opt.model : undefined;
    this.th = opt.th ? opt.th : undefined;
    this.options = opt.options;
    this.betType = this.options.betType;

    // add collection data to the model attributes
    _.extend(this.model.attributes, opt.data);

    //check extra css classes
    this.cssClasses = this.getCssClassList();

    this.manageToolTip(RaceItemTooltip);
    this.manageButtons(true);

    this.initListeners();
}

export function initListeners() {
    // socket update
    this.listenTo(this.model, 'updated', (...args) => {
        this.model.setupBettingData(this.betType);
        this.manageButtons();
    });
    //after render event to trigger dom events in tooltip
    this.listenTo(app, 'race:race:rendered', () => {
        if (this.options.race_update && this.model.attributes.tooltip_open) {
            this.tooltipView.trigger('visible', this.options.race_update);
        }
    });

    //call custom listeners functions in local app folders
    if (typeof this.viewListeners === 'function') {
        this.viewListeners();
    }
}

export function manageToolTip(TooltipView) {
    this.tooltipView = new TooltipView({model: this.model});
    this.listenTo(this.tooltipView, 'tab:change', this.adjustHeight);
}

export function getCssClassList() {
    let classes = [];

    //if the is the minimal version, without the runner information add an extra css class
    if (this.th.data.minimal) classes.push('isMinimal');
    //add extra class if event is antepost
    if (this.model.attributes.event.isAntePost) classes.push('isAntePost');
    //add extra class if tooltip was open
    if (this.model.attributes.tooltip_open) classes.push('isTooltipOpen');

    return classes.length > 0 ? classes.join(' ') : null;
}

export function render() {
    this.$el.html(this.template({
        model: this.model.toJSON(),
        buttons: this.buttonViews,
        th: this.th
    }));

    if(this.cssClasses) this.$el.addClass(this.cssClasses);

    this.DOM.itemOverlay = this.$('[data-item-overlay]');
    this.DOM.itemOverlayText = this.$('[data-item-overlay-text]');
    this.DOM.shoesContainer = this.$('[data-tooltip-shoes]');

    this.attachButtons();
    this.attachTooltip();

    return this;
}

export function addNewButton(button, isSelected, enabled, adjustedCategory, showOddsHistory=false) {
    //Add button if `show_in_raceCard` is `true` AND one of the following conditions:
    //- enbaled,
    //- not enabled but one of the buttons in set of buttons for this item has been selected (isSelected),
    //- fixed odds buttons (button model is not set up if fixed odds are not available, in other cases button should be visible)
    //- runner is scratched
    //- race is not open anymore
    if (button.show_in_raceCard && (
        enabled ||
        (!enabled && this.model.attributes.isSelected) ||
        adjustedCategory === 'FXD' ||
        this.model.get('scratched') === true ||
        _.contains(['STR', 'FNL', 'TMP', 'CNC'], this.data.race.raceStatus))) {
            let btn = new ButtonView(this.generateOptions(button, isSelected, enabled, adjustedCategory, showOddsHistory));
            this.buttonViews.push(btn);
    }
}

export function updateButton(button, isSelected, enabled, adjustedCategory, showOddsHistory=false) {
    var value_change = maybe.of(button).mapDotProp('value_change').join();
    if(value_change.direction || button.scratched_changed) {
        var buttonView = _.find(this.buttonViews, function(btn) {
            return maybe.of(btn).mapDotProp('options.betCategory').join() === adjustedCategory;
        });

        if(buttonView) {
            buttonView.updateOptions(this.generateOptions(button, isSelected, enabled, adjustedCategory, showOddsHistory))

            if(value_change.direction) {
                buttonView.animate(value_change.direction);
            } else {
                buttonView.render();
            }
        }
    }
}

export function generateOptions(button, isSelected, enabled, adjustedCategory, showOddsHistory) {
    return {
        enabled: enabled,
        customCssClass: [button.class, isSelected && this.model.attributes.selectedCategory === adjustedCategory ? 'isSelected' : ''].join(' '),
        extraCssClass: adjustedCategory === 'BOK' && this.betType === 'WIN' ? 'hasAsterisk' : '',
        showOddsHistory: showOddsHistory,
        oddsHistory: button.odds_history,
        label: button.value,
        toteCurrency: this.data.event.toteCurrency,
        betCategory: adjustedCategory,
        betType: this.betType,
        model: this.model
    }
}

export function manageButtons(newButton=false) {
    var isSelected = this.model.attributes.isSelected && maybe.of(this).mapDotProp('model.attributes.race.raceStatus').join() === 'OPN';

    _.each(maybe.of(this).mapDotProp('model.attributes.betting.buttons').orElse([]).join(), (button) => {
        var adjustedCategory = (button.category === 'FXW') ? 'FXD' : button.category; //maps 'FXW' to 'FXD'

        if(!this.data.race.bookiePreset && adjustedCategory === 'BOK' && maybe.of(this.model.attributes).mapDotProp(`betTypes.normal.TOT.${button.betType}`).join()) {
            adjustedCategory = 'TOT';
        }

        if (maybe.of(this.model.attributes).mapDotProp(`betTypes.normal.${adjustedCategory}.${button.betType}`).join()) {
            var enabled = button.enabled && (!isSelected || this.model.attributes.selectedCategory === adjustedCategory);

            if(newButton) {
                this.buttonViews = this.buttonViews || [];
                this.addNewButton(button, isSelected, enabled, adjustedCategory);
            } else {
                this.updateButton(button, isSelected, enabled, adjustedCategory);
            }

        }
    })
}

export function attachButtons() {
    _.each(this.buttonViews, (button, index) => {
        this.DOM[`buttons_${index}`] = this.DOM[`buttons_${index}`] || this.$(`[data-button-${index}]`);
        this.DOM[`buttons_${index}`].html(button.render().$el);
    });
}

export function attachTooltip() {
    this.DOM.tooltip = this.$('[data-tooltip]');
    this.DOM.tooltip.html(this.tooltipView.render().$el);
    this.tooltipView.delegateEvents();

    if (this.model.attributes.tooltip_open) {
        this.$el.outerHeight(this.model.attributes.opened_height);
        //trigger tab change
        this.tooltipView.trigger(`tab:active:${this.model.attributes.tooltip_active_tab}`, this.options.race_update);
    }
}

export function toggleTooltip() {
    if(this.model.attributes.event.isVirtual) {
        return
    }

    // stores original height
    if (_.isUndefined(this.model.attributes.original_height)) {
        this.model.attributes.original_height = this.$el.outerHeight();
    }

    if (this.model.attributes.tooltip_open) {
        this.tooltipView.trigger('hidden');
        this.$el.outerHeight(this.model.attributes.original_height).removeClass('isTooltipOpen');
        this.model.attributes.tooltip_open = false;
        this.model.attributes.opened_height = this.model.attributes.original_height;

    } else {
        this.tooltipView.trigger('visible');
        this.$el.outerHeight(this.model.attributes.original_height + this.$el.find('[data-tooltip]').outerHeight()).addClass('isTooltipOpen');
        this.model.attributes.tooltip_open = true;
        this.model.attributes.opened_height = this.model.attributes.original_height + this.$el.find('[data-tooltip]').outerHeight();
    }
}

export function manageTooltipShoes(e) {
    e.stopPropagation();
    clearTimeout(timeout);

    // hide all tooltips first
    $('.c-tooltip__shoes').hide();
    
    const shoesIcons = $(e.target);
    const tooltip = shoesIcons.parent().find('.c-tooltip__shoes');
    
    tooltip.css({ display: 'block' });
    tooltip.css({
        top: shoesIcons.height(),
        left: shoesIcons.position().left - tooltip.width() / 2
    })
    timeout = setTimeout(() => tooltip.css({ display: 'none' }), 3000)
}

export function adjustHeight() {
    this.model.attributes.opened_height = this.model.attributes.original_height + this.$el.find('[data-tooltip]').outerHeight();
    this.$el.outerHeight(this.model.attributes.opened_height);
}

export function onClose() {
    this.tooltipView.close();

    _.each(this.buttonViews, button => {
        button.close();
    });
}
