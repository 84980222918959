import app  from 'components/core/application';

/**
 * Register handlebar translation method
 *
 * @Example
 * data: {label_current: 'Label title'}
 * syntax in Handlebars template: {{ translate 'label_current' }}
 *
 * @url https://github.com/airbnb/polyglot.js
 * @url http://airbnb.io/polyglot.js/polyglot.html
 *
 */
export default function (key) {
    return app.polyglot.t(key);
};
