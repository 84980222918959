var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
    return "show-visually-fade-in";
},"3":function(depth0,helpers,partials,data) {
    return "hide-visually";
},"5":function(depth0,helpers,partials,data) {
    return "isHidden";
},"7":function(depth0,helpers,partials,data) {
    var stack1;

  return "    <a href=\"/bonus/overview\" class=\"c-myActions c-myActions__bonus "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.hasBonus : stack1),{"name":"if","hash":{},"fn":this.program(8, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\" data-bonus>\n        <div class=\"c-myActions__icon\">&nbsp;</div>\n        <div class=\"c-myActions__label\">\n            "
    + this.escapeExpression(__default(require("../../../brands/racebets/js/components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_bonus_p",{"name":"translate","hash":{},"data":data}))
    + "\n        </div>\n    </a>\n";
},"8":function(depth0,helpers,partials,data) {
    return "hasBonus";
},"10":function(depth0,helpers,partials,data) {
    return "isEmpty";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "<div class=\"c-bar\">\n    <span id=\"back_btn\" class=\"c-subheader__arrow "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.backArrow : stack1),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers.unless.call(depth0,((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.backArrow : stack1),{"name":"unless","hash":{},"fn":this.program(3, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\"><i class=\"c-arrow c-arrow--light pointLeft\"></i></span>\n    <h2 data-title>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.title : stack1), depth0))
    + "</h2>\n    <span class=\"c-subheader__button "
    + ((stack1 = __default(require("../../../brands/racebets/js/components/helpers/handlebar-helpers/compare.js")).call(depth0,((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.rightMenu : stack1),"===",false,{"name":"compare","hash":{},"fn":this.program(5, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\" data-r-menu>\n        <i class=\"rb-icon rb-icon--dots-three-v\"></i>\n    </span>\n\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.isLoggedIn : depth0),{"name":"if","hash":{},"fn":this.program(7, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n    <div class=\"c-myActions c-myActions__mybets\" data-open-bets>\n        <div class=\"c-myActions__icon rb-icon rb-icon--list\">\n            <div class=\"c-mybetsBar__count\" style=\"display: none\"></div>\n        </div>\n        <div class=\"c-myActions__label\">\n            "
    + alias2(__default(require("../../../brands/racebets/js/components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_open_bets",{"name":"translate","hash":{},"data":data}))
    + "\n        </div>\n    </div>\n\n    <div class=\"c-myActions c-myActions__betslip "
    + ((stack1 = helpers.unless.call(depth0,((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.betCount : stack1),{"name":"unless","hash":{},"fn":this.program(10, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\" data-betslip-button>\n        <div class=\"c-myActions__icon\">\n            <div class=\"c-myActions__count\" data-betslip-count>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.betCount : stack1), depth0))
    + "</div>\n        </div>\n        <div class=\"c-myActions__label\">\n            "
    + alias2(__default(require("../../../brands/racebets/js/components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_betslip",{"name":"translate","hash":{},"data":data}))
    + "\n        </div>\n    </div>\n</div>\n";
},"useData":true});