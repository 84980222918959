var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
    return "c-raceNumber--virtual";
},"3":function(depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "        <div class=\"c-raceNumber__item is"
    + alias3(((helper = (helper = helpers.raceStatus || (depth0 != null ? depth0.raceStatus : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"raceStatus","hash":{},"data":data}) : helper)))
    + " "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.active : depth0),{"name":"if","hash":{},"fn":this.program(4, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\" data-id=\""
    + alias3(((helper = (helper = helpers.idRace || (depth0 != null ? depth0.idRace : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"idRace","hash":{},"data":data}) : helper)))
    + "\">\n            <div class=\"c-raceNumber__digit\">"
    + alias3(((helper = (helper = helpers.raceNumber || (depth0 != null ? depth0.raceNumber : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"raceNumber","hash":{},"data":data}) : helper)))
    + "</div>\n            <div class=\"c-raceNumber__time\">"
    + alias3(((helper = (helper = helpers.postTimeLabel || (depth0 != null ? depth0.postTimeLabel : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"postTimeLabel","hash":{},"data":data}) : helper)))
    + "</div>\n        </div>\n";
},"4":function(depth0,helpers,partials,data) {
    return "c-raceNumber__item--active";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"c-raceNumber l-scrollH "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.isVirtual : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.races : depth0),{"name":"each","hash":{},"fn":this.program(3, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});