/**
 * Application object
 * @name application
 * @singleton
 *
 * @listens initialize
 * @listens onDataLoaded
 *
 * @description
 *     Main application object.
 */

//Apply Polyfills
import 'core-js/fn/object/assign' 
import 'core-js/fn/object/create' 

import app                      from 'components/core/application';
import { initialize }           from 'components/core/application.init';
import { onDataLoaded }         from 'components/core/application.init.loaded';
import '../scss/styles.scss';

app.on('initialize', initialize);

app.on('initial:data:loaded', onDataLoaded);

app.start();