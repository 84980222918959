import _ from 'underscore';
import app                      from 'components/core/application';
import helpers                  from 'components/helpers/helpers';
import raceHelpers              from 'modules/race/race.helpers';
import user                     from 'components/user/user';
import maybe                    from 'components/helpers/maybe';
import RaceNumbersView          from 'modules/race/race-numbers';
import RaceBottomBet            from 'modules/race/race-bottom-bet';
import RaceTitleView            from 'modules/race/race-title';
import RacePromotionsView       from 'modules/race/race-promotions';
import RacePromotionsTermsView  from 'modules/race/race-promotions-terms';
import BetTypeView              from 'modules/race/bet-type';
import SortableView             from 'components/sortable-view/sortable-view';
import raceViews                from 'modules/race/race.views';
import PickViewManager          from 'modules/race/pick-view-manager';
import webSocketManager         from 'components/helpers/webSocketManager';
import BettingService           from 'components/betting/betting.service';
import BetModel                 from 'components/betting/bet.model';
import RaceH2HView              from 'modules/race/race-h2h';
import subHeaderTemplate        from 'modules/race/race-subheader.tpl.hbs';
import multiplesValidator       from 'components/betting/multiples.validator';
import BannerView               from 'components/banner/banner';
import AudioPreviewView         from 'components/media/audio-preview';


export var className = 'm-race';
export const viewEvents = {};

/**
 * @method initialize
 *
 * @param options {race_id: race_id, collection: collection}. race_id stands for selected race, collection contains Event data
 */
export function initialize (options) {
    this.options = options;
    this.active_socket = null;

    //holds 'race.taxFees' value from /ajax/races/details/id/:eventId Could also be passed around in a service but seems like it is needed only here
    var taxFees = maybe.of(this.options).mapDotProp('collection.data.race.taxFees').join();
    if(taxFees) app.betslip.taxFees = taxFees;

    this.components = {};
    this.default_type = 'WIN';

    this.items = this.setupItems();

    // results
    this.results = [];
    // if there are no results, the api returns an empty array otherwise results data are returned in an object ?!?
    if (!_.isArray(this.collection.data.result)) {
        // store easytouse® arrays to display race results in handlebars
        this.results = this.raceResults();
    }

    this.listenTo(this.collection, 'sync reset', this.onRaceLoaded);
    this.listenTo(this.collection, 'race:betTypes:updated', () => {
        this.updateBetTypes(this.collection.data);
    });

    this.listenTo(this.collection, 'race:siblings:updated', () => {
        this.components.race_numbers.update({races: maybe.of(this.options.collection).mapDotProp('data.siblingRaces.races').join()}).render();
    });

    this.listenTo(app.betslip, 'model:isSelected betslip:change', this.updateSelectedRunners);

    // prepare bet model for exotic bets
    if (['OPN','STR'].indexOf(this.collection.data.race.raceStatus) > -1) {
        this.setupBetModel();
    }

    // handle checkboxes click
    this.listenTo(app, 'betting:checkbox:clicked', this.handleCheckboxClick);

    this.listenTo(app, 'bet:placed:clean:selections', () => {
        if (this.betModel) {
            this.betModel.resetViewColumns();
            this.betModel.validateBet();
            this.cleanSelections();
        }
    });

    //rerender runners list when betstatus changes, etc.
    this.listenTo(this.collection, 'race:race:updated', this.updateRace);

    this.listenTo(this.collection, 'updated', this.updateRunner);

    // handle exotic bets
    this.listenTo(app, 'betting:exotic:bet', this.quickbetMulti);

    // switch subheader
    if(!this.options.race_preview) app.subHeader.switchTemplate(subHeaderTemplate, {race: this.options.collection.data.race, event: this.options.collection.data.event});

    //call custom init functions in local app folders
    if (typeof this.viewInitialize === 'function') {
        this.viewInitialize();
    }
}

export function updateRace() {
    this.collection.invoke('setupData', this.options.bet_type);
    this.options.race_update = true;
    this.updateList(this.data);
    //update number color depending on race st
    if(this.components.race_numbers) this.components.race_numbers.updateStatus(this.data.data.race).render();
}

export function updateRunner() {
    if (this.collection.hasOddsAsInfo) {
        this.updateList(this.data);
    }
}

export function setupItems() {
    return raceViews();
}

export function getActiveType () {
    return this.options.bet_type ? this.options.bet_type.toUpperCase() : this.default_type;
}

export function getItemData () {
    return this.items[this.getActiveType()] || this.items[this.default_type];
}

export function render () {
    this.$el.html(this.template({
        race: {
            results: this.results,
            preview: this.collection.data.race.preview,
            previewHeading: this.collection.data.race.previewHeading,
            previewProvider: this.collection.data.race.previewProvider,
            isVirtual: this.collection.data.event.isVirtual,
        }
    }));
    this.DOM = {
        banner: this.$('[data-banner]'),
        race_numbers: this.$('[data-race-numbers]'),
        promotions: this.$('[data-promotions]'),
        promotions_terms: this.$('[data-promotions-terms]'),
        title: this.$('[data-title]'),
        bet_types: this.$('[data-bet-types]'),
        sortable: this.$('[data-runners]'),
        bottom_bet: this.$('[data-bottom-bet]'),
        togglePayouts: this.$('[data-show-payouts]'),
        extraPayouts: this.$('[data-payouts]'),
        preview: this.$('[data-preview]'),
        sub_header: this.$('[data-subHeader]'),
        audio_preview: this.$('[data-audio-preview]')
    };

    //call custom render functions in local app folders
    if (typeof this.viewRender === 'function') {
        this.viewRender();
    }

    this.onRendered();
    return this;
}

export function onRendered () {
    this.initBanner();
    this.managePreview();
    this.initRaceNumbers();
    this.initRacePromotions();
    this.initAudioPreview();

    if (this.collection.data.event.isVirtual && user.isLoggedIn) {
        setTimeout(()=>{
            app.subHeader.trigger('livestream:show');
        }, 100)
    }


    //investigate is this call is needed because there is already on from onRaceLoaded.
    // this.updateComponents(this.options.collection);
}

export function managePreview() {
    if(this.DOM.preview.length > 0) this.DOM.preview.toggle(this.options.bet_type !== 'H2H');
}

export function betTypeChanged (data) {
    this.options.bet_type = data && data.active_bet_type ? data.active_bet_type.toUpperCase() : '';
    this.options.race_update = false;
    this.managePreview();
    //todo; check if needed
    // this.updateSortable(this.data);

    this.updateBottomBet();

    //todo; check if needed - couses 'updateList' running twice. It is called from this.updateSortable(this.data) in 'betTypeChanged' function
    this.updateList(this.data);
    if (this.options.bet_type === 'H2H') {
        this.betModel.set('betType', 'WIN');
    } else {
        if (helpers.isPickBetType(this.options.bet_type)) {
            this.betModel.set('betslipType', 'ACC');
            this.betModel.set('betCategory', helpers.getPickCategory(this.collection.data.betTypes.pick, this.options.bet_type));
        }
        this.betModel.set('betType', this.options.bet_type);
    }

    app.trigger('betType:changed');
}

export function onRaceLoaded (collection, response, options) {
    let idEvent = (response && response.event) ? response.event.idEvent : this.collection.data.event.idEvent
    this.manageSockets(idEvent);
    this.updateComponents(collection || this.collection);
}

/**
 * @method initBanner
 * @description
 *  Initiates and attaches to the main template the banner
 */
export function initBanner() {
    if (this.options.noBanner ) {
        return
    }
    this.components.banner = new BannerView({ idRace: this.collection.data.race.idRace });
    this.DOM.banner.html(this.components.banner.render().el);
    this.components.banner.delegateEvents();
}

/**
 * @method initRaceNumbers
 * @description
 *  Initiates and attaches to the main template the race number view/component
 */
export function initRaceNumbers () {
    if (maybe.of(this.collection).mapDotProp('data.event.isAntePost') && !this.collection.data.event.isAntePost) {
        this.components.race_numbers = new RaceNumbersView({
            races: maybe.of(this.options.collection).mapDotProp('data.siblingRaces.races').join(),
            active_race_id: this.options.race_id,
            isVirtual: maybe.of(this.options.collection).mapDotProp('data.event.isVirtual').join()
        });
        this.DOM.race_numbers.html(this.components.race_numbers.render().$el);
    }
}

/**
 * @method initRacePromotions
 * @description
 *  Initiates and attaches to to the main template the race promotions view/component
 */
export function initRacePromotions () {
    if (maybe.of(this.collection).mapDotProp('data.race.promotions') && this.collection.data.race.promotions.length) {

        let promotionsArray = this.collection.data.race.promotions.filter((item) => {
            if(
                _.contains(item.countries, user.data.country || user.data.ipCountry)
                && (item.promotionType === "enhanced-place" || !user.data.noBonus)
            ) {
                if (item.promotionType !== 'custom') {
                    item.label = item.promotionType.replace('-','_');
                }
                return true;
            }
            return false;
        });

        if (!promotionsArray.length){
            return;
        }

        this.components.promotions = new RacePromotionsView({
            racePromotions: promotionsArray
        });

        this.components.promotions_terms = new RacePromotionsTermsView({
            racePromotions: promotionsArray
        });

        this.DOM.promotions.html(this.components.promotions.render().$el);
        this.DOM.promotions_terms.html(this.components.promotions_terms.render().$el);
    }
}


/**
 * @method initAudioPreview
 * @description
 *  Initiates and attaches to to the main template the audio preview player
 */
export function initAudioPreview () {
    if (this.collection.data.race.previewHasAudio) {
        this.components.audioPreview = new AudioPreviewView({idRace: this.collection.data.race.idRace, el: this.DOM.audio_preview});
    }
}

export function updateComponents (collection) {
    this.data = collection;

    this.updateTitle(collection.data);
    this.updateBetTypes(collection.data);
    if (this.options.bet_type === 'H2H') {
        this.updateH2H(collection);
    } else {
        //check if needed
        // this.updateSortable(collection);
    }

    //call custom update functions in local app folders
    if (typeof this.viewComponentUpdate === 'function') {
      this.viewComponentUpdate(collection);
    }
    this.updateList(collection);

    const runData = collection.data;
    if (runData.race.raceStatus === 'CNC') {
        const runners = _.values(runData.runners);

        if (runners.length === 1 && runners[0].idSubject === 0) {
            this.DOM.sortable.remove();
            this.DOM.bet_types.remove();
            this.DOM.sub_header.remove();
        }
    }
}

/**
 * @method initTitle
 * @description
 *  Initiates and attaches to the main template the title view/component
 */
export function initTitle (data) {
    this.components.title = new RaceTitleView({hide_siblings: (this.options.race_preview)});
    this.DOM.title.html(this.components.title.update(data).render().$el);
}

/**
 * @method initBetType
 * @description
 *  Initiates and attaches to to the main template the betType view/component
 */
export function initBetType (data) {
    this.components.bet_types = new BetTypeView({
        race_id: this.options.race_id,
        bet_type: this.options.bet_type,
        h2h: data.race.head2head,
        race_status: data.race.raceStatus,
        country: data.event.country,
        tote_currency: data.event.toteCurrency,
        hide: (this.options.race_preview || data.race.raceStatus === 'STR')
    });
    this.listenTo(this.components.bet_types, 'betType:changed', this.betTypeChanged);
    this.DOM.bet_types.html(this.components.bet_types.update(data).render().$el);
}

export function initPickManager (collection) {
    this.components.pick_manager_view = new PickViewManager({
        race_id: this.options.race_id,
        bet_type: this.options.bet_type,
        bet_category: helpers.getPickCategory(collection.data.betTypes.pick, this.options.bet_type),
        tot_currency: maybe.of(collection).mapDotProp('data.event.toteCurrency').orElse(user.data.currency).join()
    });
    this.DOM.sortable.html(this.components.pick_manager_view.update(this.options.bet_type).render().$el);
}

export function manageSockets(idEvent) {
    //do not connect more than once.
    if(this.active_socket === this.collection.data.socketChannel) return;

    if(this.active_socket) webSocketManager.leaveChannel(this.active_socket);
    this.active_socket = this.collection.data.socketChannel;
    this.connectSocket(this.active_socket, idEvent);
}

export function connectSocket(channel, idEvent) {
    webSocketManager
        .connect()
        .joinChannel({
            channel: channel,
            timestamp: this.collection.data.timestamp,
            idEvent: idEvent
        });
}

export function initSortable (collection) {
    let itemData = this.checkButtons(this.getItemData(), collection);
    let options = _.extend({}, this.setSortableOptions(itemData, collection, this.options), {models: collection.models});

    this.components.sortable = new SortableView(options);
    this.DOM.sortable.html(this.components.sortable.render().$el);
}

export function updateSelectedRunners() {
    //get ids for runners who have been added to betslip
    var selected_runners_ids = app.betslip.getBetIds();
    var bets = app.betslip.getBets(true);
    var race_is_open = this.options.collection.data.race.raceStatus === 'OPN';

    _.each(this.options.collection.models, (model) => {
        let contains = _.contains(selected_runners_ids, model.attributes.idRunner);
        let category = null;
        let sameMarket = false;

        if(contains) {
            //find all bets with give id - same runner may be included in betslim under different markets
            let same_bets = _.filter(bets, function(bet) {
                return parseInt(bet.idRunner, 10) == model.attributes.idRunner
            });
            //look through bets with same id
            _.each(same_bets, (bet) => {
                category = bet.category;
                //set 'sameMarket' to 'true' if market matches
                if(_.findWhere(bet.parts, {market: this.options.bet_type})) sameMarket = true;
            });
        }

        var isScratched = !!+model.attributes.scratched;

        model.set({
            selectedCategory: (category && contains && sameMarket && race_is_open && !isScratched) ? category : (isScratched || !race_is_open) ? 'n/r' : 'n/a', //set category to 'n/a' if isSelected will be set to false and runner is not scratched
            isSelected: contains && sameMarket
        });
    });
}

export function updateList (collection) {
    if (helpers.isPickBetType(this.options.bet_type)) {
        if (this.components.sortable) {
            this.components.sortable.close();
            this.components.sortable = null;
        }
        if (this.components.h2h) {
            this.components.h2h.close();
            this.components.h2h = null;
        }
        this.updatePickManager(collection);
    } else if (this.options.bet_type === 'H2H') {
        if (this.components.sortable) {
            this.components.sortable.close();
            this.components.sortable = null;
        }
        if (this.components.pick_manager_view) {
            this.components.pick_manager_view.close();
            this.components.pick_manager_view = null;
        }
        this.updateH2H(collection);
    } else {
        if (this.components.pick_manager_view) {
            this.components.pick_manager_view.close();
            this.components.pick_manager_view = null;
        }
        if (this.components.h2h) {
            this.components.h2h.close();
            this.components.h2h = null;
        }
        this.updateSortable(collection);
    }
}

export function updatePickManager (collection) {
    if (this.components.pick_manager_view) {
        this.DOM.sortable.html(this.components.pick_manager_view.update(this.options.bet_type).render().$el);
    } else {
        this.initPickManager(collection);
        this.listenTo(this.components.pick_manager_view, 'pick:collection:sync', this.updatePickBetModel);
    }
}

/**
 * Pick bet min unit stake and currency comes from API
 * @param {object} attr New model attributes
 */
export function updatePickBetModel(attr) {
    if (attr.minUnitStake) this.betModel.set('minUnitStake', attr.minUnitStake, {silent: true});
    if (attr.maxUnitStake) this.betModel.set('maxUnitStake', attr.maxUnitStake, { silent: true });
    if (attr.currency) this.betModel.set('currency', attr.currency, {silent: true});
}

export function checkButtons(data, collection) {
    // On navigating back the bet_type is in lowercase. We need to uppercase it to access properties on the object.
    this.options.bet_type = this.options.bet_type ? this.options.bet_type.toUpperCase() : '';
    this.items[this.options.bet_type].th = this.manageDynamicHeader(this.items[this.options.bet_type].th, collection, this.options.collection.data, this.options.bet_type);
    return data;
}

export function manageDynamicHeader(th, collection, data, betType) {
    //call setupBettingData on each model to ensure the latest state of bettng object - single source of truth for betitng buttons, odds, labels, etc
    collection.invoke('setupBettingData', betType);

    //get a non-scratched horse because scratched horse may have no odds and we need them for 'showOddsAsInfo'
    var model = collection.findWhere({scratched: false});
    if(model) {
        // model.setupBettingData(betType);
        var betting = maybe.of(model).mapDotProp('attributes.betting').join();

        //iterate buttons to adjust table header
        if(betType === 'WIN') {
            _.each(betting.buttons, (button, index) => {
                //BOK or TOT
                if (_.contains(data.availableOdds, 'PRC') && _.contains(['BOK', 'TOT'], button.category)) {
                    th[`BTN${index + 1}`] = {
                        title: app.polyglot.t('label_starting_price_short'),
                        width: '18%',
                        alignment: 'center',
                        sortable: _.isNumber(parseInt(button.value, 10)),
                        sort_attribute: 'odds_for_sorting_prc',
                        active: (th[`BTN${index + 1}`]) ? th[`BTN${index + 1}`].active : false
                    };
                }

                //FXD
                if(_.contains(data.availableOdds, 'FXW') && button.category === 'FXW') {
                    th[`BTN${index + 1}`] = {
                        title: app.polyglot.t('label_price_quote_short'),
                        width: '18%',
                        alignment: 'center',
                        sortable: _.isNumber(parseInt(button.value, 10)),
                        sort_attribute: 'odds_for_sorting_fxd',
                        active: (th[`BTN${index + 1}`]) ? th[`BTN${index + 1}`].active : false
                    };
                }
            });
        }

        //if odds available and button's label/value is a string (e.g. Bet) and betType is PLC', 'SHW', 'ITA', 'TRT'] - show available odds as an information
        if(betting.buttons.length > 0 && raceHelpers.showOddsAsInfo(betting.buttons[0].betType, betting.oddsAsInfo, betting.buttons[0].value)) {
            if(!th.odds) {
                th.odds = {
                    title: app.polyglot.t('label_win'),
                    width: '15%',
                    alignment: 'center',
                    sortable: true,
                    sort_attribute: 'odds_for_sorting'
                };
                th.data.width = `${parseInt(th.data.width, 10) - 18}%`;
            }
        } else {
            if(th.odds) delete th.odds;
        }
    }

    //for antepost race remove sortable state on number and set data to be active.
    //reset to default value
    th.background = false;
    if(data.event.isAntePost) {
        //for antepost races show table header with background
        th.background = true;
        if(th.number) {
            th.number.title = '';
            th.number.sortable = false;
            th.number.active = false;
        }
        if(th.data) {
            th.data.sortable = true;
            th.data.active = false;
        }
        if(th.BTN1) {
            th.BTN1.sortable = true;
            th.BTN1.active = true;
        }
    }

    return th;
}


export function updateSortable (collection) {
    //mark horses from betslip as selected
    this.updateSelectedRunners();

    if (this.components.sortable) {
        let itemData = this.checkButtons(this.getItemData(), collection);
        let options = this.setSortableOptions(itemData, collection, this.options);
        this.components.sortable.update(collection.models, options);
    } else {
        this.initSortable(collection);
    }
}

export function setSortableOptions (itemData, collection, options) {
    //export function to be able to overwrite in b2b
    return {
        view: itemData.view,
        viewOptions: itemData.viewOptions,
        th: itemData.th,
        columns: itemData.columns,
        betType: options.bet_type,
        data: collection.data,
        raceUpdate: options.race_update
    };
}

export function initH2H (collection) {
    this.components.h2h = new RaceH2HView({
        collection: collection
    });
    this.DOM.sortable.html(this.components.h2h.render().$el);
}

export function updateH2H (collection) {
    if (this.components.h2h) {
        this.components.h2h.update({
            collection: collection
        });
    } else {
        this.initH2H(collection)
    }
}

/**
 * @method updateTitle
 * @description
 *  Initiates and attaches to to the main template the title view/component if not yet done
 *  or simply rerenders the view
 *
 * @param data
 */
export function updateTitle (data) {
    if (this.components.title) {
        this.components.title.update(data).render();
    } else {
        this.initTitle(data);
    }
}

/**
 * @method updateBetTypes
 * @description
 *  Initiates and attaches to to the main template the betType view/component if not yet done
 *  or simply rerenders the view
 *
 * @param data
 */
export function updateBetTypes (data) {
    if (!this.collection.data.event.isAntePost && ['FNL', 'CNC'].indexOf(this.collection.data.race.raceStatus) < 0) {
        if (this.components.bet_types) {
            this.components.bet_types.update(data).render();
            //if current betType is not in the updated list (the betTYpe where user was currently was removed)
            if(!_.findWhere(this.components.bet_types.types, {id: this.options.bet_type})) {
                if(this.components.bet_types.types.length > 0) {
                    this.components.bet_types.redirect(this.components.bet_types.types[0].id);
                }
            }
        } else {
            this.initBetType(data);
        }
    }
}

/**
 * Called when the bottom bet button is clicked, it calls the dialog for exotic bets
 * @param data
 */
export function quickbetMulti (data) {

    if (maybe.of(this.collection.data).mapDotProp('betTypes').join()) {
        this.betModel.attributes.betTypes = this.collection.data.betTypes;
    }

    BettingService.showQuickbetMulti({
        attributes: {
            betType: this.options.bet_type,
            betModel: this.betModel,
            race: this.options.collection
        }
    });
}

export function setupBetModel () {
    let betCategory = null;
    if (maybe.of(this.collection.data).mapDotProp('betTypes.normal.FXD.' + this.options.bet_type).join()) {
        betCategory = 'FXD';
    } else if (maybe.of(this.collection.data).mapDotProp('betTypes.normal.BOK.' + this.options.bet_type).join()) {
        betCategory = 'BOK';
    } else if (maybe.of(this.collection.data).mapDotProp('betTypes.normal.TOT.' + this.options.bet_type).join()) {
        betCategory = 'TOT';
    }

    var attributes = {
        name: '',
        idRace: this.collection.data.race.idRace,
        betCategory: betCategory,
        betType: this.options.bet_type ? this.options.bet_type : null,
        fixedOddsWin: null,
        betslipType: 'STD',
        unitStake: null,
        isFree: this.collection.data.race.isFree || false,
        taxFees: this.collection.data.race.taxFees,
        viewColumns: {
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            C: []
        },
        programNumberViewColumns: { //a property to store program numbers. Used to generate marks string
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            C: []
        },
        collectionData: this.collection.data
    };

    // set antepost betType to WIN
    if (this.collection.data.event.isAntePost) {
        attributes.betCategory = 'FXD';
        attributes.betType = 'WIN';
    }

    //set model for pickbets
    if (helpers.isPickBetType(this.options.bet_type)) {
        attributes.betCategory = helpers.getPickCategory(maybe.of(this.collection.data).mapDotProp('betTypes.pick').join(), this.options.bet_type);
        attributes.betslipType = 'ACC';
    }

    this.betModel = new BetModel(attributes);
    //set betType because for some reason it is not set on model init
    this.betModel.attributes.betType = attributes.betType;
    this.betModel.calculateUnitStake();
    this.betModel.set({currency: (this.betModel.get('betCategory') === 'TOT') ? this.collection.data.event.toteCurrency : user.data.currency}, {silent: true});
    this.betModel.set({country: (this.betModel.get('betCategory') === 'TOT') ? this.collection.data.event.country : user.data.country}, {silent: true});

    this.listenTo(this.betModel, 'change:betType', () => {
        this.betModel.resetViewColumns();
    });

    this.listenTo(this.betModel, 'bet:invalid', (data) => {
        this.listenTo(this.betModel, 'bet:invalid', (data) => {
            this.updateBottomBet(data);
        });
    });
}

export function handleCheckboxClick (value) {
    var idRunner = parseInt(value.data.idrunner, 10);
    var bet_status = [];
    var bet_status_select_runners = {
        name: 'betStatus',
        message: app.polyglot.t('error_bet_select_runner_per_race'),
        type: 'message'
    };

    //Assign current race. This is needed because pick races contain more than one race. On initialize the first race is passed in to race.methods init function.
    //Then if any other race is chosen in the list of pick races, we need to update current race which is passed in to this function.
    this.collection.data.race = value.race || this.collection.data.race;

    if (!_.isUndefined(this.collection.data.race) && !_.isUndefined(this.collection.data.race.stables) && !value.data.stableChecked) {
        this.checkStableRunners(idRunner, value.data.col, value.data.isChecked);
    }
    this.checkSiblings(value.data);

    //validate pick bet type
    if(helpers.isPickBetType(this.betModel.get('betType'))) {
        var isChecked = maybe.of(value.model).mapDotProp('attributes.checkbox_data.isChecked').join();
        var idRace = maybe.of(value.model).mapDotProp('attributes.race.idRace').join();

        var raceObject = _.findWhere(value.race_ids, {idRace: parseInt(idRace, 10)});
        var stable_runners = this.getStableRunners(idRunner);

        if(isChecked) {
            //for stable runners increment only once
            if(_.intersection(raceObject.runners, stable_runners).length < 1) {
                raceObject.runners.push(multiplesValidator.composeRunner(value.model.toJSON(), this.betModel.toJSON()));
                raceObject.count ++;
                if (stable_runners.length && value.data.stableChecked) {
                    raceObject.skipCount = raceObject.skipCount ? (raceObject.skipCount + 1) : 1;
                }
            }
        } else {
            raceObject.runners = _.reject(raceObject.runners, function(runner) {return runner.idRunner === idRunner});
            if (stable_runners.length && value.data.stableChecked) {
                raceObject.skipCount --;
            }
            raceObject.count --;
        }

        this.betModel.attributes.marksAcc = value.race_ids;
        this.betModel.attributes.numBets = multiplesValidator.getPickBetTypeCount(value.race_ids);
        if(this.betModel.attributes.numBets === 0) bet_status.push(bet_status_select_runners);
    } else {
        this.betModel.attributes.marksAcc = [];
        this.betModel.set('betslipType', 'STD', {silent: true});
        //validate bet
        this.betModel.validateBet();
        bet_status = this.betModel.attributes.errors;
    }
    this.updateBottomBet(bet_status);

    app.trigger('checkbox:click');
}

export function cleanSelections() {
    this.betModel.attributes.marksAcc = '';
    this.betModel.attributes.numBets = 0;
    this.updateBottomBet();
}

export function initBottomBet() {
    let raceData = this.data;
    if (raceData.data.race.raceStatus === 'OPN') {
        this.components.bottomBet = new RaceBottomBet({
            show: [...helpers.BET_TYPES_EXOTIC, ...helpers.getBetTypesPick()].includes(this.options.bet_type),
            bets: this.betModel.get('numBets'),
            betType: this.options.bet_type
        });
        this.listenTo(this.components.bottomBet, 'button:fire', this.quickbetMulti);
        this.DOM.bottom_bet.html(this.components.bottomBet.render().$el);
    }
}

export function updateBottomBet(errors) {
    if (this.components.bottomBet) {
        this.components.bottomBet.update({
            show: [...helpers.BET_TYPES_EXOTIC, ...helpers.getBetTypesPick()].includes(this.options.bet_type),
            bets: this.betModel.get('numBets'),
            betType: this.options.bet_type,
            errors: errors
        }).render();
    } else {
        this.initBottomBet();
    }
}

export function checkStableRunners (idRunner, col, checked) {
    _.each(this.getStableRunners(idRunner), (runner, i, list) => {
        let checkbox = this.$('input[data-idrunner="' + runner + '"][data-col="' + col + '"]');
        // might be scratched
        if (checkbox.length > 0) {
            checkbox.prop('checked', checked);
            window.setTimeout(() => {
                checkbox.trigger('stable:checked');
            }, 200);
        }
    });
}

export function getStableRunners (idRunner) {
    const findProgramNumberForId = (idRunner) => {
        return this.collection.data.runners.reduce((accumulator, item) => {
            if (item.idRunner === idRunner) {
                accumulator = item.programNumber;
            }
            return accumulator;
        }, null);
    }
    const result = [];
    const stableObj = this.collection.data.race.stables;
    const runners = this.collection.data.runners;
    const runnerProgramNumber = findProgramNumberForId(idRunner);

     [idRunner].programNumber;

    for (let key in stableObj) {
        const stable = stableObj[key];

        for (let i = 0; i < stable.length; i++) {
            const runner = stable[i];
            if(idRunner !== stable[i] && (findProgramNumberForId(stable[i]) === runnerProgramNumber || this.options.bet_type === 'WIN') ) {
                result.push(stable[i]);
            }
        }
    }

    return result;
}

export function checkSiblings (data) {
    var idRunner = parseInt(data.idrunner, 10);
    var viewColumns = this.betModel.get('viewColumns');
    var programNumberViewColumns = this.betModel.get('programNumberViewColumns');
    if(data.isChecked) {
        var property = 'cardRow' + data.programnumber;

        // add it if is not in there already
        if (_.indexOf(viewColumns[data.col], idRunner) < 0) {
            viewColumns[data.col].push(idRunner);
            programNumberViewColumns[data.col].push(data.programnumber);
        }
        //if is it s "C" checkbox - uncheck all other checkboxes in this row and remove them form 'viewColumns' and 'programNumberViewColumns' arrays
        if(data.col === 'C') {
            //remove idRunner and program number from all other columns in this row(row=programNumber) when they are pragmatically unchecked
            for(var i = 1; i < 5; i++) {
                viewColumns[i] = _.without(viewColumns[i], idRunner);
                programNumberViewColumns[i] = _.without(programNumberViewColumns[i], data.programnumber);
            }
        } else {
            //remove idRunner and program number from 'C' column when it is pragmatically unchecked
            viewColumns['C'] = _.without(viewColumns['C'], idRunner);
            programNumberViewColumns['C'] = _.without(programNumberViewColumns['C'], data.programnumber);
        }
    } else {
        viewColumns[data.col] = _.without(viewColumns[data.col], idRunner);
        programNumberViewColumns[data.col] = _.without(programNumberViewColumns[data.col], data.programnumber);
    }
}

export function raceResults() {
    var results = {
            race: [],
            other: [],
            header: {
                win: this.collection.data.result.odds !== undefined && this.collection.data.result.odds.wps !== undefined && this.collection.data.result.odds.wps.WIN !== undefined,
                place: this.collection.data.result.odds !== undefined && this.collection.data.result.odds.wps !== undefined && this.collection.data.result.odds.wps.PLC !== undefined,
                show: this.collection.data.result.odds !== undefined && this.collection.data.result.odds.wps !== undefined && this.collection.data.result.odds.wps.SHW !== undefined
            }
        };

    _.each(this.collection.data.result.positions, (element) => {
        const pn = parseInt(element.programNumber, 10);

        results.race.push({
            name: element.name,
            position: element.position,
            programNumber: element.programNumber,
            win: element.position === 1 && this.collection.data.result.odds !== undefined && this.collection.data.result.odds.wps.WIN !== undefined ? helpers.formatOdds(this.collection.data.result.odds.wps.WIN[pn]) : null,
            place: this.collection.data.result.odds !== undefined && this.collection.data.result.odds.wps.PLC !== undefined && this.collection.data.result.odds.wps.PLC[pn] !== undefined ? helpers.formatOdds(this.collection.data.result.odds.wps.PLC[pn]) : null,
            show: this.collection.data.result.odds !== undefined && this.collection.data.result.odds.wps.SHW !== undefined && this.collection.data.result.odds.wps.SHW[pn] !== undefined ? helpers.formatOdds(this.collection.data.result.odds.wps.SHW[pn]) : null
        })
    });

    if (this.collection.data.result.odds !== undefined) {
        _.each(this.collection.data.result.odds.other, (element) => {
            var name = _.keys(element)[0];
            var win = _.keys(element[name])[0];

            _.each(element[name], (position, win) => {
                results.other.push({
                    name: helpers.betTypeName(name, 'BOK', this.collection.data.event.toteCurrency === 'USD', this.collection.data.event.country),
                    win: helpers.formatOdds(parseFloat(win)),
                    position: position
                });
            });
        });
    }

    return results;
}

export function onClose () {
    app.subHeader.resetTemplate();
    if (this.contextMenu) {
        this.contextMenu.destroy();
    }
    webSocketManager.leaveChannel(this.active_socket);
    _.each(this.components, function (component) {
        if(component) component.close();
    });
    this.components = {};
    //call custom close functions in local app folders
    if (typeof this.viewOnClose === 'function') {
        this.viewOnClose();
    }
}
