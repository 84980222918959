'use strict';

import _               from 'underscore';
import Backbone        from 'backbone';
import BetItemModel    from 'components/betting/bet-item.model';
import helpers         from 'components/helpers/helpers';
import maybe           from 'components/helpers/maybe';

const BetItemInnerCollection = Backbone.Collection.extend({

    model: BetItemModel,

    initialize: function() {
        this.on('change:parts', this.triggerPartsChanged, this);//for bets
        this.on('change:stake', this.triggerStakeChanged, this);//for multiples
        this.on('change:status', () => {
            this.trigger('model:changed');
            this.triggerCollectionUpdate();
        });
        this.on('change:isEachWay', this.manageEachWay, this);
        this.on('change:inMultiples', this.addMultiples, this);
        this.on('add', this.modelAdded, this);
        this.listenTo(this, 'model:update', this.triggerCollectionUpdate);
        this.on('destroy remove', this.triggerDestroyEvent, this);
    },

    triggerPartsChanged: function(...args) {
        if(args && args[0] && args[0].attributes && args[0].attributes.previous_unit) {
            this.trigger('model:changed', {property: 'stake', previous_unit: args[0].attributes.previous_unit});
        } else {
            this.trigger('model:changed', {property: 'parts', value: args[1]});
        }
    },

    triggerStakeChanged: function(...args) {
        this.trigger('model:changed', {property: 'stake', value: args[1]});
    },

    triggerDestroyEvent: function(model) {
        this.trigger('model:destroy', {idRunner: model.attributes.idRunner});
    },

    triggerCollectionUpdate: function () {
        this.trigger('model:changed');
    },

    addMultiples: function(model, value) {
        //Set newly added bet's isEachWay options to be same as it is for the first item from bets which are added to multiples (in multiples all bets are either each way or non of them is each way);
        //var inMultiples = this.each(function(bet) {
        //    return bet.attributes.inMultiples && bet.attributes.showEachWay;
        //});
        //
        //if(inMultiples.length > 0) model.set({isEachWay: inMultiples[0].attributes.isEachWay});

        //check eachWay value if model is added to multiples
        // if(model.attributes.inMultiples) model.set({isEachWay: betslipHelpers.isEachWay(this.models, model)});

        this.trigger('multiples:add');
        this.trigger('model:changed');
    },

    manageEachWay: function(model, value, options) {
        var changed = false;

        //proceed only if event was triggered by a bet included in multiples
        if(model.attributes.inMultiples) {
            this.each(function (bet) {
                if (bet.attributes.inMultiples && bet.attributes.showEachWay) {
                    changed = true;
                    // Note: uncomment this if all bets need to be either e\w or none of them e\w
                    // bet.attributes.isEachWay = value;

                    var _parts = [];
                    var _part_2;
                    var parts = maybe.of(bet).mapDotProp('attributes.parts').join();
                    _parts.push(bet.attributes.parts[0]);
                    if (bet.attributes.isEachWay) {
                        //remove second part if exists and create a new one
                        if(parts && parts.length === 2) parts.pop();
                        _part_2 = helpers.deepCopy(bet.attributes.parts[0]);
                        _part_2.market = _part_2.market === 'WIN' ? 'PLC' : 'WIN';
                        _part_2.odds = bet.attributes.oddsPlc || null;
                        _parts.push(_part_2);

                        bet.unset('parts', {silent: true});
                        bet.set({parts: _parts}, {silent: true});
                    } else {
                        //clear stake for second part if available
                        if(parts && parts.length === 2 && parts[1].stake) delete parts[1].stake; //bet.attributes.category !== 'FXD'
                    }
                }
            });

            if (changed) this.trigger('update', this.models);
        } else {
            this.trigger('update', model);
        }
    },

    modelAdded: function(model) {
        if(model.attributes.isSelected) this.trigger('model:isSelected', model);
    },

    fixedBetsOnly: function() {
        return _.filter(this.models, function(model) {return model.attributes.category === 'FXD'});
    },

    acceptAll: function() {
        const toBeRemoved = [];
        const toBeAccepted = [];

        this.each(function(bet) {
            if (bet.attributes.status) {
                //remove only bets that have no 'accept' option
                if (bet.attributes.status.remove === true && bet.attributes.status.accept !== true) {
                    toBeRemoved.push(bet);
                }
                //accept bets that can be accepted
                if (bet.attributes.status.accept === true) {
                    toBeAccepted.push(bet);
                }
            }
        });

        this.remove(toBeRemoved);

        _.each(toBeAccepted, (model) => {
            model.attributes.status.update = false;
            model.attributes.status.remove = false;
            model.collection.trigger('model:update');
        });
    },

    getTotalUnits: function() {
        var total = 0;
        this.each(function(model) {
            _.each(model.attributes.parts, function(part) {
                if(part.stake && part.stake.unit) {
                    total += parseFloat(part.stake.unit);
                }
            });
        });

        return total;
    }

});

//with this there is no need to set url which is mandatory for a collection
BetItemInnerCollection.prototype.sync = function() {return null;};
BetItemInnerCollection.prototype.fetch = function() {return null;};
BetItemInnerCollection.prototype.save = function() {return null;};

export default BetItemInnerCollection;
