var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "    <label for=\"filter_"
    + alias2(alias1((data && data.key), depth0))
    + "\" class=\"c-filter__switch isType"
    + alias2(alias1((data && data.key), depth0))
    + " "
    + ((stack1 = helpers["if"].call(depth0,depth0,{"name":"if","hash":{},"fn":this.program(2, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\" data-type=\""
    + alias2(alias1((data && data.key), depth0))
    + "\">\n        <input type=\"checkbox\" id=\"filter_"
    + alias2(alias1((data && data.key), depth0))
    + "\" name=\"filter_"
    + alias2(alias1((data && data.key), depth0))
    + "\" class=\"c-filter__checkbox\">\n        <small class=\"c-filter__switchName c-rType-filter__switchName\">"
    + alias2(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,__default(require("../../components/helpers/handlebar-helpers/concat.js")).call(depth0,"label_race_type_",__default(require("../../components/helpers/handlebar-helpers/toLowerCase.js")).call(depth0,(data && data.key),{"name":"toLowerCase","hash":{},"data":data}),{"name":"concat","hash":{},"data":data}),{"name":"translate","hash":{},"data":data}))
    + "</small>\n    </label>\n";
},"2":function(depth0,helpers,partials,data) {
    return "isActive";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"c-filter__toggle\"></div>\n"
    + ((stack1 = helpers.each.call(depth0,((stack1 = (depth0 != null ? depth0.filterState : depth0)) != null ? stack1.type : stack1),{"name":"each","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"useData":true});