import  _                            from 'underscore';
import  $                            from 'jquery';
import  Backbone                     from 'backbone';
import { captureMessage, withScope } from '@sentry/browser';
import  app                          from 'components/core/application';
import  user                         from 'components/user/user';
import  helpers                      from 'components/helpers/helpers';
import  betslipHelpers               from 'components/betting/betslip.helpers'
import  Storage                      from 'components/helpers/serviceFactory';

/**
 * BetModel Controller
 * @name BetModel
 * @constructor
 *
 * @requires Underscore
 * @requires Backbone
 * @requires app
 * @requires user
 * @requires helpers
 *
 * @description
 *     BetModel Controller
 *
 * @returns Function - Constructor function for BetModel
 */
var BetModel = Backbone.Model.extend({

    /**
     * Ajax request url
     * @type {String}
     */
    url: '/ajax/mbetslip/send/',

    /**
     * Possible bet types
     * @type {Array}
     */
    allowedBetTypes: helpers.allowedBetTypes(),

    /**
     * Possible bet types per bet category
     */
    betTypes: {
        'BOK': ['WIN', 'PLC', 'WP', 'EXA', 'TRI', 'SFC', 'QNL', 'SWG', 'TRO', 'ITA', 'TRT', 'SHW', 'WS', 'PS'],
        'US': ['WIN', 'PLC', 'WP', 'EXA', 'TRI', 'SFC', 'QNL', 'SHW', 'WS', 'PS', 'WPS']
    },

    /**
     * Possible freebet types per bet category
     */
    freeBetTypes: {
        'BOK' : ['WIN', 'WP'],
        'US': ['WIN', 'WP']
    },

    /**
     * Default model values
     *
     * @type {Object}
     */
    defaults: {
        isFree: false,
        showEachWay: false,
        betCategory: null,
        activeBetTypes: {
            options: [],
            active: {},
            arr: []
        }
    },

    /**
     * Store data for analytics
     */
    analytics: {
        betType: 'n/a',
        country: 'n/a'
    },

    /**
     * todo: cover with tests
     * value: column number
     * minColsToSelect: min number of columns to have (at least) one runner selected
     * multi: bet number multiplier. Applied to bet types where combinations are not possible.
     *      Default value is 1 or null for bets with more than one column (combination bets),
     *      for betTypes: 'WP', 'WS', 'PS' value is 2, for betType 'WPS' value is 3
     */
    rules: {
        WIN: {value: [1], cols: 1, minColsToSelect: 1, multi: 1},
        PLC: {value: [1], cols: 1, minColsToSelect: 1, multi: 1},
        SHW: {value: [1], cols: 1, minColsToSelect: 1, multi: 1},
        WP: {value: [1], cols: 1, minColsToSelect: 1, multi: 2},
        WS: {value: [1], cols: 1, minColsToSelect: 1, multi: 2},
        PS: {value: [1], cols: 1, minColsToSelect: 1, multi: 2},
        WPS: {value: [1], cols: 1, minColsToSelect: 1, multi: 3},
        ITA: {value: [2], cols: 1, minColsToSelect: 1, multi: 1},
        TRT: {value: [3], cols: 1, minColsToSelect: 1, multi: 1},
        QNL: {value: [1, 'C'], cols: 1, minColsToSelect: 1, multi: null},
        SWG: {value: [1, 'C'], cols: 1, minColsToSelect: 1, multi: null},
        EXA: {value: [1, 2, 'C'], cols: 2, minColsToSelect: 2, multi: null},
        TRO: {value: [1, 2, 'C'], cols: 2, minColsToSelect: 2, multi: null},
        TRI: {value: [1, 2, 3, 'C'], cols: 3, minColsToSelect: 3, multi: null},
        SFC: {value: [1, 2, 3, 4, 'C'], cols: 4, minColsToSelect: 4, multi: null},
        TOF: {value: [1, 'C'], cols: 1, minColsToSelect: 1, multi: null},
        QRP: {value: [1, 2, 3, 4, 'C'], cols: 4, minColsToSelect: 1, multi: null}, //'Quarte+',
        QNP: {value: [1, 2, 3, 4, 5, 'C'], cols: 5, minColsToSelect: 1, multi: null}, // 'Quinte+',
        SF4: {value: [1, 2, 3, 4, 'C'], cols: 4, minColsToSelect: 1, multi: null}, //'Super4',
        TRC: {value: [1, 2, 3, 'C'], cols: 3, minColsToSelect: 1, multi: null},// Tierce
        M4:  {value: [1, 'C'], cols: 1, minColsToSelect: 1, multi: null},  //'Multi4',
        M5:  {value: [1, 'C'], cols: 1, minColsToSelect: 1, multi: null}, //Multi5
        M6:  {value: [1, 'C'], cols: 1, minColsToSelect: 1, multi: null}, //'Multi6',
        M7:  {value: [1, 'C'], cols: 1, minColsToSelect: 1, multi: null}, //'Multi7',
        PK5: {value: [1, 'C'], cols: 1, minColsToSelect: 1, multi: null}// 'Pick5'
    },

    betslipSettings: {
        confirmation: false,
        preselection: true,
        stakes: {
            SEK: [
                {id: '5.00', title: '5.00'},
                {id: '10.00', title: '10.00'},
                {id: '15.00', title: '15.00'},
                {id: '20.00', title: '20.00'},
                {id: '30.00', title: '30.00'},
                {id: '50.00', title: '50.00'},
                {id: '100.00', title: '100.00'},
                {id: '200.00', title: '200.00'},
                {id: '300.00', title: '300.00'},
                {id: '500.00', title: '500.00'},
                {id: '1000.00', title: '1000.00'},
                {id: '2000.00', title: '2000.00'}
            ],
            NOK: [
                {id: '5.00', title: '5.00'},
                {id: '10.00', title: '10.00'},
                {id: '15.00', title: '15.00'},
                {id: '20.00', title: '20.00'},
                {id: '30.00', title: '30.00'},
                {id: '50.00', title: '50.00'},
                {id: '100.00', title: '100.00'},
                {id: '200.00', title: '200.00'},
                {id: '300.00', title: '300.00'},
                {id: '500.00', title: '500.00'},
                {id: '1000.00', title: '1000.00'},
                {id: '2000.00', title: '2000.00'}
            ],
            EUR: [
                {id: '0.50', title: '0.50'},
                {id: '1.00', title: '1.00'},
                {id: '1.50', title: '1.50'},
                {id: '2.00', title: '2.00'},
                {id: '3.00', title: '3.00'},
                {id: '5.00', title: '5.00'},
                {id: '10.00', title: '10.00'},
                {id: '20.00', title: '20.00'},
                {id: '30.00', title: '30.00'},
                {id: '50.00', title: '50.00'},
                {id: '100.00', title: '100.00'},
                {id: '200.00', title: '200.00'}
            ],
            GBP: [
                {id: '0.50', title: '0.50'},
                {id: '1.00', title: '1.00'},
                {id: '1.50', title: '1.50'},
                {id: '2.00', title: '2.00'},
                {id: '3.00', title: '3.00'},
                {id: '5.00', title: '5.00'},
                {id: '10.00', title: '10.00'},
                {id: '20.00', title: '20.00'},
                {id: '30.00', title: '30.00'},
                {id: '50.00', title: '50.00'},
                {id: '100.00', title: '100.00'},
                {id: '200.00', title: '200.00'}
            ],
            USD: [
                {id: '0.50', title: '0.50'},
                {id: '1.00', title: '1.00'},
                {id: '1.50', title: '1.50'},
                {id: '2.00', title: '2.00'},
                {id: '3.00', title: '3.00'},
                {id: '5.00', title: '5.00'},
                {id: '10.00', title: '10.00'},
                {id: '20.00', title: '20.00'},
                {id: '30.00', title: '30.00'},
                {id: '50.00', title: '50.00'},
                {id: '100.00', title: '100.00'},
                {id: '200.00', title: '200.00'}
            ]
        }
    },

    freeBetCategories: ['BOK'],

    countries: ['DK', 'FI', 'NO', 'SE'],

    /**
     * @method initialize
     * @description
     *      Initialize model with options. Check for required attributes and setup listeners
     */
    initialize: function () {
        this.data = {};

        if (_.isUndefined(this.attributes.name)) {
            throw new Error('Runner name attribute is required');
        }

        if (_.isUndefined(this.attributes.fixedOddsWin)) {
            throw new Error('fixedOddsWin attribute is required');
        }

        //store event country for analytics
        if (this.attributes.country) {
            this.analytics.country = this.attributes.country;
        }

        //store bet type for analytics
        if (this.attributes.betType) {
            this.analytics.betType = this.attributes.betType;
        }

        this.on('sync', () => {
            this.analyticsTagBetConfirmed();
        }, this);

        this.on('change:unitStake', this.formatUnitStake, this);

        //listen on change event to validate specific value range for betType
        this.on('change:betType', this.validateBetType, this);

        //generate mark string right after bet number is set. It is assumed that bet is valid in that stage
        this.on('change:betIsValid', this.setMarkString, this);

        //this.on('change:betCategory', this.getActiveBetTypes(), this);

        //check betCategory and set one of does not exists
        this.checkBetCategory();

        //currency may be returned form server (assumption)
        this.set({currency: this.attributes.currency || user.data.currency}, {silent: true});

        this.set({country: this.attributes.country || user.data.country}, {silent: true});

        //create a list of active betTypes
        this.getActiveBetTypes();

        //check if default betType 'WIN' is present in the active BetType list
        this.checkBetType();

        //set numBets depending on a bet type
        //todo: check if this is needed. This set default value
        var betNumber = (this.rules[this.attributes.betType] && this.rules[this.attributes.betType].multi) ? this.rules[this.attributes.betType].multi : 0;
        this.set({numBets: betNumber}, {silent: true});

        if (this.attributes.freeBets && this.attributes.freeBets.length > 0) {
            this.trigger('change:freeBets');
        }
    },

    save: function (attributes, options) {
        this.analyticsTagBetClick();
        return Backbone.Model.prototype.save.call(this, attributes, options);
    },

    /**
     * @method sync
     * @description
     *      Override for a native Backbone.sync method to post data as FormData
     *
     * @param method
     * @param model
     * @param options
     * @returns {*}
     */
    sync: function (method, model, options) {
        this.prepareData();
        options.attrs = model.attributes;
        //disable validation on after sync
        options.validate = false;

        //store bet data
        Storage.getByNameOrCreate('Bet').set({data: options.attrs});

        ///remove for api v2
        // Post data as FormData object on create to allow file upload
        if (method === 'create') {
            var formData = new FormData();

            // Loop over model attributes and append to formData
            _.each(options.attrs, function (value, key) {
                formData.append(key, value);
            });

            // Set processData and contentType to false so data is sent as FormData
            _.defaults(options || (options = {}), {
                data: formData,
                processData: false,
                contentType: false
            });
        }
        ///

        return Backbone.sync.call(this, method, model, options);
    },

    /**
     * @method prepareData
     * @description
     *      Check attributes and leave only white listed
     *
     * @returns {*}
     */
    prepareData: function () {
        var white_list = ['idRace', 'betType', 'betslipType', 'betCategory', 'unitStake',
            'currency', 'fixedOddsWin', 'fixedOddsPlace', 'marks', 'taxFee', 'numBets', 'idFreebet', 'isFree', 'idRms'];

        if (this.attributes.errorCode) {
            //if ([302, 140].indexOf(this.attributes.errorCode) > -1) {
            //    this.set({unitStake: this.attributes.newUnitStake});
            //}

            if (this.attributes.errorCode === 309) {
                this.resetAttributesOnNextSync('betCategory');
                this.set({betCategory: 'BOK'}, {silent: true});
                white_list = _.without(white_list, 'fixedOddsWin', 'fixedOddsPlace');
            }

            if (this.attributes.errorCode === 306) {
                this.resetAttributesOnNextSync('betType');
                this.set({betType: 'WIN'});
            }
        }

        this.attributes = _.pick(this.attributes, white_list);
    },

    /**
     * @method stripExtraAttributes
     * @description
     *      Removes extra attributes that are not needed after the next request
     *
     * @returns {*}
     */
    stripExtraAttributes: function () {
        this.listenToOnce(this, 'sync', function () {
            if (this.attributes.idRms) {
                this.unset('idRms', {silent: true});
            }
            if (this.attributes.errorCode) {
                this.unset('errorCode', {silent: true});
            }
        });
    },

    resetAttributesOnNextSync: function (attribute) {
        let attributeOldValue = this.get(attribute);
        this.listenToOnce(this, 'sync', function () {
            this.set(attribute, attributeOldValue);
        })
    },

    resetViewColumns: function () {
        //good place to set a default selection in an appropriate column if required
        var viewColumns = this.get('viewColumns');
        var programNumberViewColumns = this.get('programNumberViewColumns');
        for (var key in viewColumns) {
            viewColumns[key] = [];
            programNumberViewColumns[key] = [];
        }
    },

    /**
     * @method validateBetType
     * @description
     *      Ensures two possible values
     *
     * @returns {*}
     */
    validateBetType: function () {
        let type = this.attributes.betType;

        //store bettype for analytics
        this.analytics.betType = type;

        if (type && this.allowedBetTypes.indexOf(type) === -1) {
            throw new Error('This "betType" value is not allowed');
        }

        if (this.get('viewColumns')) {
            //it as a betslip
            this.validateBet();
        } else {
            //it is a fixed bet
            this.set({numBets: this.rules[type].multi});
        }
    },

    verify: function () {
        const self = this;
        const attrs = this.attributes;
        const viewColumns = this.get('viewColumns');
        const betType = this.get('betType');
        const maxOneBankPerColumnMsg = app.polyglot.t('error_max_one_bank_per_col');
        const maxOneBankMsg = app.polyglot.t('error_max_one_bank');
        const bitPos = [0, 1, 2, 4, 8, 16, 32, 64, 128, 256, 512, 1024, 2048];

        // removes extra stable horses from the columns and adds just one for verification purposes
        const stables = attrs.collectionData.race.stables;

        if (stables.length > 0) {
            _.each(viewColumns, function (col, key) {

                if (col.length > 0) {
                    let runners = col;
                    _.each(stables, function (stable) {

                        let intersection = _.intersection(stable, runners);
                        if (intersection.length > 0) {
                            viewColumns[key] = _.difference(col, stable);
                            viewColumns[key].push(stable[0]);
                        }
                    });
                }
            });
        }

        const _rules = this.rules;

        const _isCombinationOnly = function () {
            return (viewColumns['1'].length === 0 && viewColumns['2'].length === 0 && viewColumns['3'].length === 0 && viewColumns['4'].length === 0 && viewColumns['5'].length === 0 && viewColumns['C'].length > 0);
        };

        const _isBankersOnly = function () {
            return viewColumns[1].length > 0 && viewColumns['C'].length === 0
        }

        const _hasCombination = function () {
            return attrs.viewColumns['C'].length > 0;
        };

        const _binomial = function (n, k) {
            if ((typeof n !== 'number') || (typeof k !== 'number')) {
                return false;
            }
            var coeff = 1;
            for (var x = n-k+1; x <= n; x++) {
                coeff *= x;
            }
            for (x = 1; x <= k; x++) {
                coeff /= x;
            }
            return coeff;
        };


        function _factorialize(num) {
            if (num < 0)
                  return -1;
            else if (num == 0)
                return 1;
            else {
                return (num * _factorialize(num - 1));
            }
        }


        function _getCombinationsWithoutRepetition(combination, set) {
            return _factorialize(set) / (_factorialize(combination) * _factorialize(set - combination));
        }

        function _getVariationsWithoutRepetition(combination, set) {
            return _factorialize(set) / _factorialize(set-combination)
        }

        /**
         * todo: cover with tests
         * @method validateOneColumnBet
         * @description
         *      Validate bets that have only one column (no combinations)
         *      betTypes: 'WIN', 'PLC', 'SHW', 'WP', 'WS', 'PS', 'WPS'
         *      Formula to calculate number of bets: (totalBets = n * multi), where
         *          'n' = number of selected runners (column #1),
         *          'multi' = bet number multiplier (Defaults to 1 or null for bets with more than one column (combination bets),
         *              for betTypes: 'WP', 'WS', 'PS' value is 2, for betType 'WPS' value is 3)
         *
         *      Error object example: {name: 'property name', message:'message'}
         *
         * @returns {Array} - return empty array if no validation required.
         */
        const _oneColumnBet = function () {
            self.set({numBets: attrs.viewColumns[1].length * _rules[attrs.betType].multi});
            return [];
        };

        /**
         * todo: cover with tests
         * @method validateITA
         * @description
         *      Validate bets that have only one column (no combinations)
         *      betTypes: 'ITA'
         *      Formula to calculate number of bets: (totalBets = n), where 'n' = number of selected runners (column #2)
         *
         *      Error object example: {name: 'property name', message:'message'}
         *
         * @returns {Array} - return empty array if no validation required
         */
        const _ITA = function () {
            self.set({numBets: attrs.viewColumns[2].length});
            return [];
        };

        /**
         * todo: cover with tests
         * @method validateTRT
         * @description
         *      Validate bets that have only one column (no combinations)
         *      betTypes: 'TRT'
         *      Formula to calculate number of bets: (totalBets = n), where 'n' = number of selected runners (column #3)
         *
         *      Error object example: {name: 'property name', message:'message'}
         *
         * @returns {Array} - return empty array if no validation required
         */
        const _TRT = function () {
            self.set({numBets: attrs.viewColumns[3].length});
            return [];
        };

        /**
         * todo: cover with tests
         * @method validateEXA
         * @description
         *      Validate bets that have more than one column (combinations possible)
         *      betTypes: 'EXA'
         *      Formula to calculate number of bets: calculate permutations. If same runner selected in first and second column +
         *      combinations is selected, then multiply permutations by 2 (2 = number of columns = _rules['EXA'].cols)
         *
         *      Error object example: {name: 'property name', message:'message'}
         *
         * @returns {Array} - return empty array if no validation required
         */
        const _EXA = function () {
            var errors = [];
            var columns = [];
            var combinationMultiplier = 1;
            var numBets;

            if (_isCombinationOnly()) {
                columns.push(viewColumns['C'], viewColumns['C']);
            } else if (_hasCombination()) {
                if (viewColumns[1].length > 1 || viewColumns[2].length > 1) {
                    errors.push({name: 'betStatus', message: maxOneBankPerColumnMsg});
                } else if (viewColumns[1].length > 0 && viewColumns[2].length > 0 && viewColumns[1][0] !== viewColumns[2][0]) {
                    errors.push({name: 'betStatus', message: maxOneBankMsg});
                } else {
                    //add runner either from first or second column
                    if (viewColumns[1].length > 0) {
                        columns.push(viewColumns[1]);
                    } else if (viewColumns[2].length > 0) {
                        columns.push(viewColumns[2]);
                    }

                    //if same runner selected in first and second column, multiply combinations.
                    if (viewColumns[1][0] === viewColumns[2][0]) {
                        combinationMultiplier = _rules['EXA'].cols;
                    }

                    columns.push(viewColumns['C']);
                }
            } else {
                columns.push(viewColumns[1], viewColumns[2]);
            }

            numBets = self.calculatePermutations(columns) * combinationMultiplier;
            self.set({numBets: numBets});

            return errors;
        };

        const _QNLandSWG = function () {
            var errors = [];

            var runner = viewColumns[1].length;
            var combinations = viewColumns['C'].length;
            if (runner > 1) {
                errors.push({name: 'betStatus', message: maxOneBankPerColumnMsg});
            } else if (runner === 1) {
                // with bank horse
                self.set({numBets: combinations});
            } else {
                // just combination column
                self.set({numBets: ((combinations - 1) * combinations) / 2});
            }
            return errors;
        };

        // The same as _QNLandSWG but if only 1st column is marked it works as second column when its only marked
        const _TOF = function () {
            var errors = [];
            var runner = viewColumns[1].length;
            var combinations = viewColumns['C'].length;
            if (runner > 1) {
                if (combinations === 0) {
                    self.set({numBets: ((runner - 1) * runner) / 2});
                } else {
                    errors.push({name: 'betStatus', message: maxOneBankPerColumnMsg});
                }
            } else if (runner === 1) {
                // with bank horse
                self.set({numBets: combinations});
            } else {
                // just combination column
                self.set({numBets: ((combinations - 1) * combinations) / 2});
            }
            return errors;
        };


        const _TRI = function () {
            var errors = [];
            var numBets = 0;
            var bankCount = 0;
            var combinations = viewColumns['C'].length;
            var bank1 = 0;
            var bank2 = 0;
            var bankH1 = 0;
            var bankH2 = 0;
            var programNumber;

            if (_isCombinationOnly()) {
                let columns = [viewColumns['C'], viewColumns['C'], viewColumns['C']];
                numBets = self.calculatePermutations(columns);
            } else if (_hasCombination()) {
                bankCount = _.uniq(_.flatten([viewColumns[1], viewColumns[2], viewColumns[3]])).length;
                if (bankCount > 2) {
                    errors.push({name: 'betStatus', message: app.interpolate('error_max_n_bank', {num: 2})});
                } else {
                    for (var i = 1; i <= 3; i++) {
                        for (var j = 0, lj = viewColumns[i].length; j < lj; j++) {
                            programNumber = self.attributes.programNumberViewColumns[i][j];

                            if (!bank1 || bank1 === programNumber) {
                                bank1 = programNumber;
                                bankH1 |= programNumber | bitPos[8 + i];
                            } else if (!bank2 || bank2 == programNumber) {
                                bank2 = programNumber;
                                bankH2 |= programNumber | bitPos[8 + i];
                            } else {
                                throw new Error('Error occurred!')
                            }
                        }
                    }

                    if (bankCount === 1) {
                        for (var k = 1; k <= 3; k++) {
                            if ((bankH1 & bitPos[8 + k]) == 0) {
                                continue;
                            }

                            for (var j = 0; j < combinations; j++) {
                                if (viewColumns['C'][j] === bank1) {
                                    continue;//return;
                                }

                                for (var n = 0; n < combinations; n++) {
                                    if (viewColumns['C'][n] !== viewColumns['C'][j] && viewColumns['C'][n] !== bank1) {
                                        numBets = numBets + 1;
                                    }
                                }
                            }
                        }
                    }

                    if (bankCount === 2) {
                        for (var j = 1; j <= 3; j++) {
                            if ((bankH1 & bitPos[8 + j]) === 0) {
                                continue;
                            }

                            for (var k = 1; k <= 3; k++) {
                                if (k === j || (bankH2 & bitPos[8 + k]) === 0) {
                                    continue;
                                }

                                for (var n = 0; n < combinations; n++) {
                                    if (viewColumns['C'][n] !== bank1 && viewColumns['C'][n] !== bank2) {
                                        numBets = numBets + 1;
                                    }
                                }
                            }
                        }
                    }
                }
            } else {
                for (var i = 0, l = viewColumns[1].length; i < l; i++) {
                    for (var j = 0, lj = viewColumns[2].length; j < lj; j++) {
                        if (viewColumns[2][j] === viewColumns[1][i]) {
                            continue;//return;
                        }
                        for (var n = 0, ln = viewColumns[3].length; n < ln; n++) {
                            if (viewColumns[3][n] === viewColumns[1][i] || viewColumns[3][n] === viewColumns[2][j]) {
                                continue;//return;
                            }

                            numBets = numBets + 1;
                        }
                    }
                }
            }

            self.set({numBets: numBets});

            return errors;
        };

        const _TRO = function () {
            var errors = [];
            var numBets = 0;
            var bankCount = 0;
            var combinations = viewColumns['C'].length;

            if (viewColumns[1].length > 1 || viewColumns[2].length > 1) {
                errors.push({name: 'betStatus', message: maxOneBankPerColumnMsg});
            } else {
                bankCount = _.uniq(_.flatten([viewColumns[1], viewColumns[2], viewColumns[3]])).length;

                if (bankCount === 2 && viewColumns[1][0] === viewColumns[2][0]) {
                    this.error = app.polyglot.t('error_same_bank');
                } else {
                    if (bankCount === 2) {
                        // two bank horses
                        numBets = combinations;
                    } else if (bankCount > 0) {
                        // one bank horse
                        // with 5 runners in the combi column it is for example 1 + 2 + 3 + 4 = 10
                        numBets = ((combinations - 1) * combinations) / 2;
                    } else if (combinations >= 3) {
                        // just combination column (binomial coefficient)
                        numBets = _binomial(combinations, 3);
                    }
                }
            }

            self.set({numBets: numBets});

            return errors;
        };

        const _SFC = function (isSFC) {
            var errors = [];
            var numBets = 0;
            var bankCount = 0;
            var combinations = viewColumns['C'].length;
            var bank1 = 0;
            var bank2 = 0;
            var bank3 = 0;
            var bankH1 = 0;
            var bankH2 = 0;
            var bankH3 = 0;
            var programNumber;

            if (_isCombinationOnly()) {
                numBets = self.calculatePermutations([viewColumns['C'], viewColumns['C'], viewColumns['C'], viewColumns['C']]);
            } else if (_hasCombination()) {
                bankCount = _.uniq(_.flatten([viewColumns[1], viewColumns[2], viewColumns[3], viewColumns[4]])).length;
                if (bankCount > 3) {
                    errors.push({name: 'betStatus', message: app.interpolate('error_max_n_bank', {num: 3})});
                } else {
                    for (let i = 1; i <= 4; i++) {
                        for (let j = 0, lj = viewColumns[i].length; j < lj; j++) {
                            programNumber = self.attributes.programNumberViewColumns[i][j];

                            if (!bank1 || bank1 === programNumber) {
                                bank1 = programNumber;
                                bankH1 |= programNumber | bitPos[8 + i];
                            } else if (!bank2 || bank2 == programNumber) {
                                bank2 = programNumber;
                                bankH2 |= programNumber | bitPos[8 + i];
                            } else if (!bank3 || bank3 == programNumber) {
                                bank3 = programNumber;
                                bankH3 |= programNumber | bitPos[8 + i];
                            } else {
                                throw new Error('Error occurred!')
                            }
                        }
                    }

                    if (bankCount === 1) {
                        for (let k = 1; k <= 4; k++) {
                            if ((bankH1 & bitPos[8 + k]) == 0) {
                                continue;
                            }

                            for (let j = 0; j < combinations; j++) {
                                if (viewColumns['C'][j] === bank1) {
                                    continue;//return;
                                }

                                for (let k = 0; k < combinations; k++) {
                                    if (viewColumns['C'][k] === bank1 || viewColumns['C'][k] === viewColumns['C'][j]) {
                                        continue;
                                    }

                                    for (let n = 0; n < combinations; n++) {
                                        if (viewColumns['C'][n] !== bank1 && viewColumns['C'][n] !== viewColumns['C'][j] && viewColumns['C'][n] !== viewColumns['C'][k]) {
                                            numBets = numBets + 1;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    if (bankCount === 2) {
                         // SFC ONLY - first horse place 1-2 second horse place 3-4 + combi === invalid

                        if(isSFC &&
                            _.flatten([viewColumns[1], viewColumns[2], viewColumns[3], viewColumns[4]]).length === 4 &&
                            viewColumns[1][0] === viewColumns[2][0] && viewColumns[3][0] === viewColumns[4][0]
                        ) {
                            self.set({numBets: 0});
                            return errors;
                        }


                        for (let j = 1; j <= 4; j++) {
                            if ((bankH1 & bitPos[8 + j]) === 0) {
                                continue;
                            }

                            for (let k = 1; k <= 4; k++) {
                                if (k === j || (bankH2 & bitPos[8 + k]) === 0) {
                                    continue;
                                }

                                for (let k = 0; k < combinations; k++) {
                                    if (viewColumns['C'][k] === bank1 || viewColumns['C'][k] === bank2) {
                                        continue;
                                    }

                                    for (let n = 0; n < combinations; n++) {
                                        if (viewColumns['C'][n] !== bank1 && viewColumns['C'][n] !== bank2 && viewColumns['C'][n] != viewColumns['C'][k]) {
                                            numBets = numBets + 1;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    if (bankCount === 3) {
                        // three bank horses

                        for (let j = 1; j <= 4; j++) {
                            if ((bankH1 & bitPos[8 + j]) === 0) {
                                continue;
                            }

                            for (let k = 1; k <= 4; k++) {
                                if (k === j || (bankH2 & bitPos[8 + k]) === 0) {
                                    continue;
                                }

                                for (let i = 1; i<= 4; i++) {
                                    if (i === j || i === k || (bankH3 & bitPos[8 + i]) == 0) {
                                        continue;
                                    }

                                    for (let n = 0; n < combinations; n++) {
                                        if (viewColumns['C'][n] !== bank1 && viewColumns['C'][n] !== bank3) {
                                            numBets = numBets + 1;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            } else {
                for (let i = 0, l = viewColumns[1].length; i < l; i++) {
                    for (let j = 0, lj = viewColumns[2].length; j < lj; j++) {
                        if (viewColumns[2][j] === viewColumns[1][i]) {
                            continue;//return;
                        }
                        for (let n = 0, ln = viewColumns[3].length; n < ln; n++) {
                            if (viewColumns[3][n] === viewColumns[1][i] || viewColumns[3][n] === viewColumns[2][j]) {
                                continue;//return;
                            }

                            for (let k = 0, lk = viewColumns[4].length; k < lk; k++) {
                                if (viewColumns[4][k] === viewColumns[1][i] || viewColumns[4][k] === viewColumns[2][j] || viewColumns[4][k] === viewColumns[3][n]) {
                                    continue
                                }
                                numBets = numBets + 1;
                            }
                        }
                    }
                }
            }

            self.set({numBets: numBets});

            return errors;
        };

        const _QNP = function() {

            const numOfColumns = 5
            const errors = [];
            const bankCount = _.uniq(_.flatten([viewColumns[1], viewColumns[2], viewColumns[3], viewColumns[4], viewColumns[5]])).length;
            const combinationsNumber = viewColumns['C'].length;

            let numBets = 0;

            if (bankCount + combinationsNumber < numOfColumns) {
                 numBets = 0;
            } else if (_hasCombination() || _isCombinationOnly()) {
                if (bankCount > 4) {
                    errors.push({name: 'betStatus', message: app.interpolate('error_max_n_bank', {num: numOfColumns - 1})});
                } else {
                    numBets = _getVariationsWithoutRepetition(numOfColumns - bankCount ,combinationsNumber);
                }
            } else {
                numBets = 1;
            }
            self.set({numBets: numBets});
            return errors;


        };

        const _OutOfFirstNth = function (minimumMarks) {
            const errors = [];
            const bankersNumber = viewColumns[1].length;
            const combinationsNumber = viewColumns['C'].length;

            let numBets = 0;

            if(bankersNumber + combinationsNumber < minimumMarks) {
                numBets = 0;
            } else if (_isCombinationOnly()) {
                numBets = _getCombinationsWithoutRepetition(minimumMarks, combinationsNumber);
            } else if (_isBankersOnly()) {
                numBets = _getCombinationsWithoutRepetition(minimumMarks, bankersNumber);
            } else if(bankersNumber >= minimumMarks) {
                errors.push({name: 'betStatus', message: app.interpolate('error_max_n_bank', {num: bankersNumber -1 })});
            } else {
                numBets = _getCombinationsWithoutRepetition(minimumMarks - bankersNumber, combinationsNumber);
            }

            self.set({numBets: numBets});
            return errors;
        }

        return {
            oneColumnBet: _oneColumnBet,
            ITA: _ITA,
            TRT: _TRT,
            EXA: _EXA,
            TRI: _TRI,
            TRO: _TRO,
            SFC: _SFC,
            QNLandSWG: _QNLandSWG,
            TOF: _TOF,
            QRP: _SFC,
            QNP: _QNP,
            SF4: _SFC,
            TRC: _TRI,
            M4:  () => _OutOfFirstNth(4),
            M5:  () => _OutOfFirstNth(5),
            M6:  () => _OutOfFirstNth(6),
            M7:  () => _OutOfFirstNth(7),
            PK5: () => _OutOfFirstNth(5)
        }
    },

    checkBetCategory: function () {
        if (!this.attributes.collectionData) {
            return;
        }

        var normalBetTypes = this.attributes.collectionData.betTypes.normal;
        if (this.get('betCategory') === null) {
            this.set({betCategory: (normalBetTypes.TOT && (!this.attributes.collectionData.race.bookiePreset || !normalBetTypes.BOK)) ? 'TOT' : 'BOK'}, {silent: true});
        }
    },

    getActiveBetTypes: function () {
        if (!this.attributes.collectionData) {
            return;
        }
        var attrs = this.attributes;
        var normalBetTypes = attrs.collectionData.betTypes.normal;
        var betCategory = (attrs.betCategory === 'TOT' && attrs.collectionData.event.toteCurrency === 'USD') ? 'US': 'BOK';
        var betTypesToUse = this.betTypes;

        //todo: temp solution
        this.attributes.activeBetTypes.options = [];
        this.attributes.activeBetTypes.arr = [];

        //use different betType ofr a freeBte
        if (this.get('isFree')) {
            betTypesToUse = this.freeBetTypes;
        }

        for (var i = 0, l = betTypesToUse[betCategory].length; i < l; i++) {
            var type = betTypesToUse[betCategory][i];
            if (normalBetTypes[attrs.betCategory] && normalBetTypes[attrs.betCategory][type] && normalBetTypes[attrs.betCategory][type].length > 0) {
                var value = {id: type, title: helpers.betTypeName(type.toUpperCase(), attrs.betCategory, attrs.collectionData.event.toteCurrency == 'USD', attrs.collectionData.event.country)};
                this.attributes.activeBetTypes.options.push(value);
                this.attributes.activeBetTypes.arr.push(type);
            }
        }

        this.checkBetType();

        // if the active option isn't available set the first from the array
        if (!_.isEmpty(this.attributes.activeBetTypes.active)) {
            if (!_.contains(this.attributes.activeBetTypes.arr, this.attributes.activeBetTypes.active.id)) {
                this.attributes.activeBetTypes.active = this.attributes.activeBetTypes.options[0];
                this.set('betType', this.attributes.activeBetTypes.arr[0]);
            }
        }
    },

    checkBetType: function () {
        var attr = this.attributes;
        var activeBetTypes = this.get('activeBetTypes');
        var betType = attr.betType;
        if (activeBetTypes.arr && activeBetTypes.arr.length > 0 && activeBetTypes.arr.indexOf(betType) === -1) {
            var type = activeBetTypes.arr[0];
            this.set({betType: type}, {silent: true});
        }

        if (attr.betType) {
            this.attributes.activeBetTypes.active = {id: attr.betType, title: app.polyglot.t('label_bet_type_' + attr.betType.toUpperCase())};
        }
    },

    /**
     * @method validateBet
     * @description
     *      Bet slip/card validation
     *
     */
    validateBet: function () {
        var errors = [];
        var betType = this.get('betType');


        if (!this.get('viewColumns')) {
            return;
        }

        // Set to false before validation
        this.set({'betIsValid': false});

        // Win, Place, Show, Win/Place, Win/Show, Place/Show, Win/Place/Show
        if (_.contains(['WIN', 'PLC', 'SHW', 'WP', 'WS', 'PS', 'WPS'], betType)) {
            errors = this.verify().oneColumnBet();
        } // Ita
        else if (betType === 'ITA') {
            errors = this.verify().ITA();
        } // Trita
        else if (betType === 'TRT') {
            errors = this.verify().TRT();
        } // Exacta
        else if (betType === 'EXA') {
            errors = this.verify().EXA();
        } // Quinella, Swinger
        else if (_.contains(['QNL', 'SWG'], betType)) {
            errors = this.verify().QNLandSWG();
        } // Trifecta
        else if (betType === 'TRI') {
            errors = this.verify().TRI();
        } // Trio
        else if (betType === 'TRO') {
            errors = this.verify().TRO();
        } // Superfecta
        else if (betType === 'SFC') {
            errors = this.verify().SFC(true);
        }
        else if (betType === 'TOF') {
            errors = this.verify().TOF();
        }
        else if (betType === 'QNP') {
            errors = this.verify().QNP();
        }
        else if (betType === 'QRP') {
            errors = this.verify().QRP();
        }
        else if (betType === 'SF4') {
            errors = this.verify().SF4();
        }
        else if (betType === 'TRC') {
            errors = this.verify().TRC();
        }
        else if (betType === 'M4') {
            errors = this.verify().M4();
        }
        else if (betType === 'M5') {
            errors = this.verify().M5();
        }
        else if (betType === 'M6') {
            errors = this.verify().M6();
        }
        else if (betType === 'M7') {
            errors = this.verify().M7();
        }
        else if (betType === 'PK5') {
            errors = this.verify().PK5();
        }

        if (this.get('numBets') < 1) {
            errors.push({
                name: 'betStatus',
                message: helpers.isPickBetType(this.get('betType')) ? app.polyglot.t('error_bet_select_runner_per_race') : app.polyglot.t('error_bet_select_runners'),
                type: 'message'
            });
        }

        //check if unitStake is valid
        //disabled because on that stage we do not need to validate unit stake.
        //errors.push(this.checkUnitStake());
        errors = _.flatten(errors);

        this.set({'betIsValid': errors.length === 0});
        this.attributes.errors = errors;

        if (!this.get('betIsValid')) {
            this.trigger('bet:invalid', errors);
        }
    },

    /**
     * @method validate
     * @description
     *      Validate model attributes
     *
     * @returns {Array}
     */
    validate: function (attrs) {
        var errors = [];

        if (!attrs.unitStake || attrs.unitStake === '' || attrs.unitStake === 0) {
            errors.push({name: 'unitStake', message: app.polyglot.t('msg_error_stake_required')});
        } else {
            //replace coma with dot
            this.attributes.unitStake = attrs.unitStake = attrs.unitStake.toString().replace(',', '.');
        }

        const unitStake = parseFloat(attrs.unitStake);

        //validate min and max stake for Pick type bets (can be either TOT or BOK)
        if (helpers.isPickBetType(attrs.betType)) {
            const min = parseFloat(attrs.minUnitStake);
            const max = attrs.maxUnitStake ? parseFloat(attrs.maxUnitStake) : 0;

            if (min > unitStake) {
                errors.push({
                    name: 'unitStake',
                    message: app.interpolate('error_min_stake', {amount: helpers.formatMoney(min, 2, attrs.currency, true)})
                });
            }
            if (max > min && unitStake > max) {
                errors.push({
                    name: 'unitStake',
                    message: app.interpolate('error_max_stake', {amount: helpers.formatMoney(max, 2, attrs.currency, true)})
                });
            }

        } else {
            //validate min and max stake for BOK and FXD bets
            if (['BOK', 'FXD'].indexOf(attrs.betCategory) > -1 && user && user.data && user.data.currencySettings) {
                const min = helpers.getMinStakeBOKFXD(user.data.currencySettings, attrs.betCategory, attrs.betType);
                const max = helpers.getMaxStakeBOKFXD(user.data.currencySettings, attrs.betCategory, attrs.betType);

                if (unitStake < min) {
                    errors.push({
                        name: 'unitStake',
                        message: app.interpolate('error_min_stake', {amount: helpers.formatMoney(min, 2, attrs.currency, true)})
                    });
                } else if (unitStake > max) {
                    errors.push({
                        name: 'unitStake',
                        message: app.interpolate('error_max_stake', {amount: helpers.formatMoney(max, 2, attrs.currency, true)})
                    });
                }

            //validate min stake for TOT bets
            } else if (attrs.betCategory === 'TOT' && attrs.betTypes && !helpers.isPickBetType(this.get('betType'))) {
                const min = attrs.betTypes.normal.TOT[attrs.betType][0];
                const minTOT = attrs.betTypes.normal.TOT[attrs.betType][1];
                const totalStake = unitStake * attrs.numBets;

                if (minTOT && totalStake < minTOT) {
                    errors.push({
                        name: 'unitStake',
                        message: app.interpolate('error_min_total_stake', {amount: helpers.formatMoney(minTOT, 2, attrs.currency, true)})
                    });
                }

                if (min && unitStake < min) {
                    errors.push({
                        name: 'unitStake',
                        message: app.interpolate('error_min_stake', {amount: helpers.formatMoney(min, 2, attrs.currency, true)})
                    });
                }

                if (helpers.isPMUBetType(this.get('betType'))) {
                    if (unitStake % min !== 0 && !(unitStake >= minTOT && unitStake % 1 === 0)) {
                        if (min <= 1 && 1 % min === 0) {
                            //use simpler error message if minUnitStake for this betType is already divisible by 1 (eg. 0.5/0.25)
                            errors.push({
                                name: 'unitStake',
                                message: app.interpolate('step_size_invalid', {amount: helpers.formatMoney(min, 2, attrs.currency, true)})
                            });
                        } else {
                            errors.push({
                                name: 'unitStake',
                                message: app.interpolate('step_size_invalid_PMU', {
                                    amount: helpers.formatMoney(min, 2, attrs.currency, true),
                                    minTotalStake: helpers.formatMoney(minTOT, 2, attrs.currency, true)
                                })
                            });
                        }
                    }

                }

            }
        }

        //TODO: betslipHelpers.checkUnitStake could be used to validate unitStake for all type of Bets
        //if US TOT race
        //betTypes contain two values. First one is minimum unit stake and second one is minimum total stake which is the minimum total value of the bet before it can be placed
        //e.g. For instance minimum unit stake 0.5 and minimum total stake 2 USD, so customer need to make at least 4 combinations before the bet is valid
        if((this.get('country') === 'US' && this.get('betCategory').toUpperCase() === 'TOT' && helpers.isPickBetType(this.get('betType')))) {
            var unit_stake_error = betslipHelpers.checkUnitStake(this.get('betCategory'), this.get('betType'), this.get('betTypes') || this.attributes.collectionData.betTypes, this.get('currency'), this.get('country'), parseFloat(this.get('unitStake')));
            if (unit_stake_error.length > 0) errors = _.union(errors, unit_stake_error);
        }

        if (isNaN(attrs.unitStake)) {
            errors.push({name: 'unitStake', message: app.polyglot.t('msg_error_digits_only')});
        }

        if (attrs.marks === '////') {
            errors.push({name: 'marks', message: 'Wrong marks selection'});
        }

        return (errors.length > 0) ? errors : false;
    },

    /**
     * todo: cover with tests
     * @method calculatePermutations
     * @description
     *      Calculates number of possible bets (permutations) depending on number of columns (groups)
     */
    calculatePermutations: function (columns) {
        var permutations = [];

        if (columns.length === 0) {
            return;
        }

        var _getPermutations = function (column, permSoFar, nextColumnIndex) {
            for (var i = 0, l = column.length; i < l; i++) {
                var nextPermSoFar = $.extend(true, [], permSoFar); // Make a copy of the permSoFar list
                //Only proceed if num isn't a repeat in nextPermSoFar
                if (nextPermSoFar.indexOf(column[i]) === -1) {
                    nextPermSoFar.push(column[i]);

                    if (nextColumnIndex < columns.length) {
                        _getPermutations(columns[nextColumnIndex], nextPermSoFar, nextColumnIndex + 1);
                    } else {
                        permutations.push(nextPermSoFar);
                    }
                }
            }
        };

        _getPermutations(columns[0], [], 1);

        return permutations.length;
    },

    /**
     * @method formatUnitStake
     * @description
     */

    formatUnitStake: function () {
        var unitStake = this.get('unitStake');
        this.set({unitStake: unitStake ? unitStake : ''}, {silent: true});

        //validate bet (betslip only)
        this.validateBet();
    },

    /**
     * @method checkUnitStake
     * @description
     *      Check if unitStake is valid for a give betType
     */
    checkUnitStake: function () {
        var errors = [];
        var betCategory = this.get('betCategory');
        var betType = this.get('betType');
        var currency = this.get('currency');
        var unitStake = parseFloat(this.get('unitStake'));

        // do nothing with pick bets, pick min unit stake comes from API
        if (helpers.isPickBetType(betType)) return errors;

        // get min stakes
        var minUnitStake = (betCategory === 'TOT')
            ? this.attributes.collectionData.betTypes.normal[betCategory][betType][0]
            : helpers.getMinStakeBOKFXD(user.data.currencySettings, betCategory, betType)
        ;

        var minTotalStake = this.attributes.collectionData.betTypes.normal[betCategory][betType][1];

        // adjust different min stake for scandinavia
        if (betCategory === 'BOK' && betType === 'TRI' && _.contains(['DK', 'FI', 'NO', 'SE'], this.attributes.collectionData.event.country)) {
            minUnitStake = user.data.currencySettings.minStakeBOKTRISE;
        }

        var minToteStepSize = this.getToteStepSize(this.attributes.collectionData.event.country);

        // min unit stake
        if (!unitStake || unitStake < minUnitStake) {
            errors.push({name: 'betUnitStake', message: app.interpolate('error_min_stake', {amount: helpers.formatMoney(minUnitStake, 2, currency, true)})});
        }

        // min total stake for some tote bets
        if (unitStake * this.get('numBets') < minTotalStake) {
            errors.push({name: 'betUnitStake', message: app.interpolate('error_min_total_stake', {amount: helpers.formatMoney(minTotalStake, 2, currency, true)})});
        }

        // max stake for bookie bets
        if (betCategory !== 'TOT') {
            var maxUnitStake = helpers.getMaxStakeBOKFXD(user.data.currencySettings, betCategory, betType)
            if (unitStake > maxUnitStake) {
                errors.push({name: 'betUnitStake', message: app.interpolate('error_max_stake', {amount: helpers.formatMoney(maxUnitStake, 2, currency, true)})});
            }
        }

        // step size for US tote bets; unit stake under 1 USD has to be a multiplication of min stake
        if (betCategory === 'TOT' && this.attributes.collectionData.event.country === 'US' && unitStake < 1 && (unitStake * 100) % (minUnitStake * 100) !== 0) {
            errors.push({name: 'betUnitStake', message: app.interpolate('error_step_size_unit_stake_invalid', {amount: helpers.formatMoney(minUnitStake, 2, currency, true)})});
        }

        // step size for tote bets
        if (betCategory === 'TOT' && (unitStake * 100) % (minToteStepSize * 100) !== 0) {
            errors.push({name: 'betUnitStake', message: app.interpolate('msg_step_size_invalid', {amount: helpers.formatMoney(minToteStepSize, 2, currency, true)})});
        }

        return errors;
    },

    calculateUnitStake: function() {
        //in case of a freeBet
        if(this.get('isFree') === true) {
            this.set({unitStake: this.get('freeBet').amount});
        } else {
            //Pick bet type is handeled in multiples.validator
            if(!this.get('betType') || helpers.isPickBetType(this.get('betType'))) return;

            var collectionData = this.attributes.collectionData;
            var modelBetCategory = this.get('betCategory');
            var modelBetType = this.get('betType');
            var modelUnitStake = this.get('unitStake');

            var minUnitStake = (modelBetCategory === 'TOT')
                ? collectionData.betTypes.normal[modelBetCategory][modelBetType][0]
                : helpers.getMinStakeBOKFXD(user.data.currencySettings, modelBetCategory, modelBetType)
            ;
            var minStakeBOKTRISE = user.data.currencySettings.minStakeBOKTRISE;

            if (modelBetCategory === 'BOK' && _.contains(this.countries, collectionData.event.country)) {
                if (modelBetType === 'TRI' && modelUnitStake < minStakeBOKTRISE) {
                    minUnitStake = minStakeBOKTRISE;
                }
                this.setUnitStake(minUnitStake);
            }

            //set unitStake value on the model in case if there no previous value and preselection === true
            if ((!modelUnitStake || modelUnitStake === '' || parseFloat(modelUnitStake) === 0) && this.betslipSettings.preselection) {
                this.setUnitStake(minUnitStake);
            }
        }
    },

    setUnitStake: function(newUnitStake) {
        this.set({unitStake: newUnitStake});
    },

    /**
     * todo: cover with a test
     * @method calculateTax
     * @description
     *      Calculate final total stake amount including tax rate (if applicable)
     *
     * @param totalStake
     * @param betCategory
     * @param noFee
     * @returns {{taxDue: number, taxFeeRate: number, taxFee: number, taxSubvention: number, taxSubventionRate: number, totalCost: *}}
     */
    calculateTax: function (totalStake) {
        var attrs = this.attributes;
        var taxFee;

        var categoryTaxRate;
        if (attrs.betCategory === "BOK" || attrs.betCategory === "TOT") {
            categoryTaxRate = parseFloat(this.data.raceModel.taxFees[attrs.betCategory]);
        } else {
            categoryTaxRate = parseFloat(attrs.taxRateV2[attrs.betCategory]);
        }

        // freebets
        if (attrs.isFree) {
            categoryTaxRate = 0;
        }

        var res = {
            taxAmount: 0, // tax amount which is due to the authorities (right now just 5% German tax is considered)
            taxRate: 0, // tax fee set for this race/bet category
            taxSubventionAmount: 0, // subvention of RaceBets for the tax amount
            taxSubventionRate: 0, // subvention rate
            totalCost: totalStake, // total cost of the betslip
            totalStake: totalStake
        };

        if (user.data.taxFees && user.data.taxFees.taxable) {
            // tax amount
            res.taxRate = 5;
            res.taxAmount = Math.floor(totalStake * res.taxRate) / 100;

            var userDeduction = user.data.taxFees.deductions[attrs.betCategory] ? user.data.taxFees.deductions[attrs.betCategory] : 0;
            taxFee = Math.floor(totalStake * categoryTaxRate / 100 * (100 - userDeduction)) / 100;

            //res.taxSubventionAmount = Math.ceil(totalStake * res.taxSubventionRate) / 100;
            res.taxSubventionAmount = res.taxAmount - taxFee;
            if (res.taxSubventionAmount > 0) {
                res.taxSubventionRate = (res.taxRate - (categoryTaxRate * (100 - userDeduction) / 100));
            }

            res.totalCost = totalStake + taxFee;
        }

        return res;
    },

    /**
     * todo: cover with a test
     * @method calculateTotalStake
     * @description
     *      Calculate total stake and compose formatted amount html (with proper currency sign)
     *
     * @returns {{totalCost: *, html: string, taxFee: (number|*)}}
     */
    calculateTotalStake: function () {
        var attrs = this.attributes;
        var totalStake = Math.round((attrs.numBets || 1) * attrs.unitStake * 100) / 100,
            totalCost,
            totalStakeHtml ='';

        totalCost = this.calculateTax(totalStake);

        this.set({taxFee: totalCost.taxAmount - totalCost.taxSubventionRate}); // required for confirmation dialog

        return totalCost;
    },

    // used only for fixed bets
    calculateWinnings: function () {
        var attrs = this.attributes;
        var fixedOddsWin = parseFloat(attrs.fixedOddsWin);
        var fixedOddsPlace = parseFloat(attrs.fixedOddsPlace);
        var placeOddsFactor = parseFloat(attrs.placeOddsFactor);
        if (attrs.betType === 'WIN') {
            return this.get('unitStake') * fixedOddsWin
        }
        if (attrs.betType === 'PLC') {
            return this.get('unitStake') * fixedOddsPlace
        }
        if (attrs.betType === 'WP') {
            let ret = this.get('unitStake') * fixedOddsWin;
            if (attrs.spEvent) {
                ret += this.get('unitStake') * ((fixedOddsWin - 1) / placeOddsFactor + 1);
            } else {
                ret += this.get('unitStake') * fixedOddsPlace;
            }

            return ret;
        }
    },

    //todo: cover with test
    //todo: REFACTOR and move to Bet model
    //todo 1: get column nom (1, 2, 3, 4, C)
    //todo 2: integrate through column runners
    //todo 3:
    generateMarksString: function () {
        var attrs = this.attributes;
        if (attrs.betslipType !== 'ACC') {
            var marks = [];

            if(!this.rules[attrs.betType]) return '';

            // It will be generated only taking in account the columns for the selected betType
            // convert to an array of strings
            var betTypeViewColumns = _.map(this.rules[attrs.betType].value, function (col) {
                return col.toString();
            });

            var _marksCb = function (col, runners) {
                var runnersPn = [];

                var _markRunners = function (key, runner) {

                    if (typeof runner === "string") {
                        // stable horse
                        // strip non-digits from runner
                        runner = runner.replace(/\D/g, '');
                    }

                    if ($.inArray(parseInt(runner), runnersPn) == -1) {
                        runnersPn.push(parseInt(runner));
                    }
                };

                for (var iRunner = 0, iRunnerL = runners.length; iRunner < iRunnerL; iRunner++) {
                    if (runners[iRunner]) {
                        _markRunners(iRunner, runners[iRunner]);
                    }
                }

                if (!_.contains(betTypeViewColumns, col)) {
                    runnersPn = []
                }

                marks.push(runnersPn.join(' '));
            };

            // Send 5 slashes only for QNP bet type
            if (this.get('betType') !== 'QNP') {
                delete attrs.programNumberViewColumns[5];
            }

            for (var mark in attrs.programNumberViewColumns) {
                _marksCb(mark, attrs.programNumberViewColumns[mark]);
            }

            return marks.join('/');
        } else {
            // filter stables - add only first stable horse
            var allMarks = this.get('marksAcc');

            if (!allMarks) {
                return '';
            }

            allMarks.forEach(function(race) {
                if(race.runners.length > 1) {
                    var progNumbers = [];
                    race.runners = race.runners.filter(function(mark) {
                        if(progNumbers.indexOf(mark.programNumber) > -1) {
                            return false;
                        }
                        progNumbers.push(mark.programNumber);
                        return true;
                    })
                }
            });

            return JSON.stringify(allMarks);
        }
    },

    setMarkString: function () {
        if (this.get('betIsValid') === true) {
            this.set({marks: this.generateMarksString()}, {silent: true});
        }
    },

    //todo: cover with tests
    getToteStepSize: function (country) {
        var toteStepSizes = { 'DE': 0.50, 'MT': 1.00 };
        return (toteStepSizes[country]) ? toteStepSizes[country] : 0.01;

    },

    getLimitIdNotification: function() {
        return this.attributes.idNotification;
    },

    analyticsTagBetConfirmed: function () {
        app.trigger('bet:placed:confirmed', {
            'BetCategory': this.attributes.betCategory,
            'BetType': this.attributes.betType,
            'BetMarket': this.attributes.country,
            'CouponID': this.attributes.publicId,
            'TotalStake': this.attributes.unitStake,
        });
    },

    analyticsTagBetClick: function () {
        app.trigger('bet:placed:click', {
            'BetCategory': this.attributes.betCategory,
            'BetType': this.attributes.betType
        })
    },

    analyticsTagBetFailed: function (code) {
        app.trigger('bet:placed:failed', code);
    }
});

export default BetModel;
