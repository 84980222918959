import moment                   from 'moment-timezone';

const i18n = {

    requireLanguage: (lang, country) => {
        // here are all the language options specified in order to let webpack prepare the bundles needed
        // we could also make a generic fn but that would led webpack to import all the languages, including those we don't need
        switch (lang) {

            case 'en':
                if (country === 'GB') {
                    return import(
                        /* webpackChunkName: "moment-locale-en-gb" */
                        'moment/locale/en-gb'
                    )
                    break;
                }
                if (country === 'AU') {
                    return import(
                        /* webpackChunkName: "moment-locale-en-au" */
                        'moment/locale/en-au'
                    )
                    break;
                }
                if (country === 'CA') {
                    return import(
                        /* webpackChunkName: "moment-locale-en-ca" */
                        'moment/locale/en-ca'
                    )
                    break;
                }
                if (country === 'IE') {
                    return import(
                        /* webpackChunkName: "moment-locale-en-ie" */
                        'moment/locale/en-ie'
                    )
                    break;
                }

                if (country === 'NZ') {
                    return import(
                        /* webpackChunkName: "moment-locale-en-nz" */
                        'moment/locale/en-nz'
                    )
                    break;
                }
                return import(
                     /* webpackChunkName: "moment-locale-en-gb" */
                     'moment/locale/en-gb'
                )
                break;

            case 'de':
                return import(
                    /* webpackChunkName: "moment-locale-de" */
                    'moment/locale/de'
                )
                break;
            case 'fr':
                return import(
                    /* webpackChunkName: "moment-locale-fr" */
                    'moment/locale/fr'
                )
                break;
            case 'it':
                return import(
                    /* webpackChunkName: "moment-locale-it" */
                    'moment/locale/it'
                )
                break;
            case 'es':
            case 'ag':
            case 'cl':
            case 'co':
            case 'mx':
            case 'pe':
                return import(
                    /* webpackChunkName: "moment-locale-es" */
                    'moment/locale/es'
                )
                break;
            case 'tr':
                return import(
                    /* webpackChunkName: "moment-locale-tr" */
                    'moment/locale/tr'
                )
                break;
            case 'pl':
                return import(
                    /* webpackChunkName: "moment-locale-pl" */
                    'moment/locale/pl'
                )
                break;
            case 'cs':
                return import(
                    /* webpackChunkName: "moment-locale-cs" */
                    'moment/locale/cs'
                )
                break;
            case 'zh':
                return import(
                    /* webpackChunkName: "moment-locale-zh-cn" */
                    'moment/locale/zh-cn'
                )
                break;
            case 'hu':
                return import(
                    /* webpackChunkName: "moment-locale-hu" */
                    'moment/locale/hu'
                )
                break;
            case 'fi':
                return import(
                    /* webpackChunkName: "moment-locale-fi" */
                    'moment/locale/fi'
                )
                break;
            case 'sv':
                return import(
                    /* webpackChunkName: "moment-locale-sv" */
                    'moment/locale/sv'
                )
                break;
            case 'no':
                return import(
                    /* webpackChunkName: "moment-locale-no" */
                    'moment/locale/nn' // norwegian nynorsk
                )
                break;
            case 'sk':
                return import(
                    /* webpackChunkName: "moment-locale-sk" */
                    'moment/locale/sk'
                )
                break;
            case 'ru':
                return import(
                    /* webpackChunkName: "moment-locale-ru" */
                    'moment/locale/ru'
                )
                break;
            case 'pt':
                return import(
                    /* webpackChunkName: "moment-locale-pt" */
                    'moment/locale/pt'
                )
                break;
            case 'br':
                return import(
                    /* webpackChunkName: "moment-locale-pt-br" */
                    'moment/locale/pt-br'
                )
                break;
            case 'mt':
                return import(
                    /* webpackChunkName: "moment-locale-mt" */
                    'moment/locale/mt'
                )
                break;
            case 'ja':
                return import(
                    /* webpackChunkName: "moment-locale-ja" */
                    'moment/locale/ja'
                )
                break;
            case 'el':
                return import(
                    /* webpackChunkName: "moment-locale-el" */
                    'moment/locale/el'
                )
                break;
            default:
                return import(
                    /* webpackChunkName: "moment-locale-en-gb" */
                    'moment/locale/en-gb'
                )

        }
    },

    setLanguage: function (language, country) {

        if (language === 'en') {

            switch (country) {

                case 'GB':
                    moment.updateLocale('en-gb');
                    break;

                case 'AU':
                    moment.updateLocale('en-au');
                    break;

                case 'CA':
                    moment.updateLocale('en-ca');
                    break;

                case 'IE':
                    moment.updateLocale('en-ie');
                    break;

                case 'NZ':
                    moment.updateLocale('en-nz');
                    break;

                case 'US':
                    moment.updateLocale('en');
                    break;

                default:
                    moment.updateLocale('en-gb'); // en-gb
                    break
            }

            return;
        }

        moment.updateLocale(language);
    },

    getLanguageFlag: function (language) {
        let flagMap = {
            'cs': 'CZ',
            'en': 'GB',
            'zh': 'CN',
            'el': 'GR',
            'sv': 'SE',
            'ja': 'JP',
            'ag': 'AR'
        };

        return flagMap[language] ? flagMap[language] : language.toUpperCase();
    }
};

export default i18n;
