import _                            from 'underscore';
import app                          from 'components/core/application';
import RaceItemView                 from 'modules/race/race-item';
import RaceItemViewMultiple         from 'modules/race/race-item-multiple';
import betOptions                   from 'modules/race/bet-options';

export default function() {
    return {
        WIN: {
            view: RaceItemView,
            viewOptions: {betType: 'WIN'},
            th: {
                number: {
                    title: '#',
                    width: '13%',
                    sortable: true,
                    active: true,
                    sort_attribute: 'programNumber'
                },
                data: {
                    title: app.polyglot.t('label_runner_p'),
                    width: '55%',
                    sortable: true,
                    sort_attribute: 'name',
                    alignment: 'left'
                }
            },
            columns: betOptions.columns.WIN
        },
        WP: {
            view: RaceItemView,
            viewOptions: {betType: 'WP'},
            th: {
                number: {
                    title: '#',
                    width: '13%',
                    sortable: true,
                    active: true,
                    sort_attribute: 'programNumber'
                },
                data: {
                    title: app.polyglot.t('label_runner_p'),
                    width: '55%',
                    sortable: true,
                    sort_attribute: 'name',
                    alignment: 'left'
                },
                BTN1: {title: app.polyglot.t('label_bet_type_WPUS'), width: '18%', alignment: 'center'}
            },
            columns: betOptions.columns.WP
        },
        PLC: {
            view: RaceItemView,
            viewOptions: {betType: 'PLC'},
            th: {
                number: {
                    title: '#',
                    width: '13%',
                    sortable: true,
                    active: true,
                    sort_attribute: 'programNumber'
                },
                data: {
                    title: app.polyglot.t('label_runner_p'),
                    width: '55%',
                    sortable: true,
                    sort_attribute: 'name',
                    alignment: 'left'
                },
                BTN1: {title: app.polyglot.t('label_bet_type_PLC'), width: '18%', alignment: 'center'}
            },
            columns: betOptions.columns.PLC
        },
        ITA: {
            view: RaceItemView,
            viewOptions: {betType: 'ITA'},
            th: {
                number: {
                    title: '#',
                    width: '13%',
                    sortable: true,
                    active: true,
                    sort_attribute: 'programNumber'
                },
                data: {
                    title: app.polyglot.t('label_runner_p'),
                    width: '55%',
                    sortable: true,
                    sort_attribute: 'name',
                    alignment: 'left'
                },
                BTN1: {title: app.polyglot.t('label_bet_type_ITA'), width: '18%', alignment: 'center'}
            },
            columns: betOptions.columns.ITA
        },
        TRT: {
            view: RaceItemView,
            viewOptions: {betType: 'TRT'},
            th: {
                number: {
                    title: '#',
                    width: '13%',
                    sortable: true,
                    active: true,
                    sort_attribute: 'programNumber'
                },
                data: {
                    title: app.polyglot.t('label_runner_p'),
                    width: '55%',
                    sortable: true,
                    sort_attribute: 'name',
                    alignment: 'left'
                },
                BTN1: {title: app.polyglot.t('label_bet_type_TRT'), width: '18%', alignment: 'center'}
            },
            columns: betOptions.columns.TRT
        },
        SHW: {
            view: RaceItemView,
            viewOptions: {betType: 'SHW'},
            th: {
                number: {
                    title: '#',
                    width: '13%',
                    sortable: true,
                    active: true,
                    sort_attribute: 'programNumber'
                },
                data: {
                    title: app.polyglot.t('label_runner_p'),
                    width: '52%',
                    sortable: true,
                    sort_attribute: 'name',
                    alignment: 'left'
                },
                BTN1: {title: app.polyglot.t('label_bet_type_SHW'), width: '25%', alignment: 'center'}
            },
            columns: betOptions.columns.SHW
        },
        PS: {
            view: RaceItemView,
            viewOptions: {betType: 'PS'},
            th: {
                number: {
                    title: '#',
                    width: '13%',
                    sortable: true,
                    active: true,
                    sort_attribute: 'programNumber'
                },
                data: {
                    title: app.polyglot.t('label_runner_p'),
                    width: '62%',
                    sortable: true,
                    sort_attribute: 'name',
                    alignment: 'left'
                },
                BTN1: {title: app.polyglot.t('label_bet_type_PS'), width: '25%', alignment: 'center'}
            },
            columns: betOptions.columns.PS
        },
        WPS: {
            view: RaceItemView,
            viewOptions: {betType: 'WPS'},
            th: {
                number: {
                    title: '#',
                    width: '13%',
                    sortable: true,
                    active: true,
                    sort_attribute: 'programNumber'
                },
                data: {
                    title: app.polyglot.t('label_runner_p'),
                    width: '50%',
                    sortable: true,
                    sort_attribute: 'name',
                    alignment: 'left'
                },
                BTN1: {title: app.polyglot.t('label_bet_type_WPS'), width: '27%', alignment: 'center'}
            },
            columns: betOptions.columns.WPS
        },
        WS: {
            view: RaceItemView,
            viewOptions: {betType: 'SHW'},
            th: {
                number: {
                    title: '#',
                    width: '13%',
                    sortable: true,
                    active: true,
                    sort_attribute: 'programNumber'
                },
                data: {
                    title: app.polyglot.t('label_runner_p'),
                    width: '52%',
                    sortable: true,
                    sort_attribute: 'name',
                    alignment: 'left'
                },
                BTN1: {title: app.polyglot.t('label_bet_type_WS'), width: '25%', alignment: 'center'}
            },
            columns: betOptions.columns.WS
        },
        EXA: {
            view: RaceItemViewMultiple,
            viewOptions: {betType: 'EXA'},
            th: {
                number: {
                    title: '#',
                    width: '13%',
                    sortable: true,
                    active: true,
                    sort_attribute: 'programNumber'
                },
                data: {
                    title: app.polyglot.t('label_runner_p'),
                    width: '47%',
                    sortable: true,
                    sort_attribute: 'name',
                    alignment: 'left'
                },
                CHK1: {title: app.polyglot.t('label_betslip_col1'), width: '10%', alignment: 'center'},
                CHK2: {title: app.polyglot.t('label_betslip_col2'), width: '10%', alignment: 'center'},
                COMBO: {title: app.polyglot.t('label_betslip_c'), width: '10%', alignment: 'center', combo: true}
            },
            columns: betOptions.columns.EXA
        },
        TRI: {
            view: RaceItemViewMultiple,
            viewOptions: {betType: 'TRI'},
            th: {
                data: {
                    title: app.polyglot.t('label_runner_p'),
                    width: '50%',
                    alignment: 'left',
                    sortable: true,
                    active: true,
                    sort_attribute: 'programNumber',
                    minimal: true
                },
                CHK1: {title: app.polyglot.t('label_betslip_col1'), width: '10%', alignment: 'center', class: 'pl0'},
                CHK2: {title: app.polyglot.t('label_betslip_col2'), width: '10%', alignment: 'center'},
                CHK3: {title: app.polyglot.t('label_betslip_col3'), width: '10%', alignment: 'center'},
                COMBO: {title: app.polyglot.t('label_betslip_c'), width: '10%', alignment: 'center', combo: true}
            },
            columns: betOptions.columns.TRI
        },
        SFC: {
            view: RaceItemViewMultiple,
            viewOptions: {betType: 'SFC'},
            th: {
                data: {
                    title: app.polyglot.t('label_runner_p'),
                    width: '40%',
                    alignment: 'left',
                    sortable: true,
                    active: true,
                    sort_attribute: 'programNumber',
                    minimal: true
                },
                CHK1: {title: app.polyglot.t('label_betslip_col1'), width: '10%', alignment: 'center' , class: 'pl0'},
                CHK2: {title: app.polyglot.t('label_betslip_col2'), width: '10%', alignment: 'center'},
                CHK3: {title: app.polyglot.t('label_betslip_col3'), width: '10%', alignment: 'center'},
                CHK4: {title: app.polyglot.t('label_betslip_col4'), width: '10%', alignment: 'center'},
                COMBO: {title: app.polyglot.t('label_betslip_c'), width: '10%', alignment: 'center', combo: true}
            },
            columns: betOptions.columns.SFC
        },
        QNL: {
            view: RaceItemViewMultiple,
            viewOptions: {betType: 'QNL'},
            th: {
                number: {
                    title: '#',
                    width: '13%',
                    sortable: true,
                    active: true,
                    sort_attribute: 'programNumber'
                },
                data: {
                    title: app.polyglot.t('label_runner_p'),
                    width: '57%',
                    alignment: 'left',
                    sortable: true,
                    active: true,
                    sort_attribute: 'programNumber'
                },
                CHK1: {title: app.polyglot.t('label_betslip_col1'), width: '10%', alignment: 'center'},
                COMBO: {title: app.polyglot.t('label_betslip_c'), width: '10%', alignment: 'center'}
            },
            columns: betOptions.columns.QNL
        },
        SWG: {
            view: RaceItemViewMultiple,
            viewOptions: {betType: 'SWG'},
            th: {
                number: {
                    title: '#',
                    width: '13%',
                    sortable: true,
                    active: true,
                    sort_attribute: 'programNumber'
                },
                data: {
                    title: app.polyglot.t('label_runner_p'),
                    width: '67%',
                    alignment: 'left',
                    sortable: true,
                    active: true,
                    sort_attribute: 'programNumber'
                },
                CHK1: {title: app.polyglot.t('label_betslip_col1'), width: '10%', alignment: 'center'},
                COMBO: {title: app.polyglot.t('label_betslip_c'), width: '10%', alignment: 'center', combo: true}
            },
            columns: betOptions.columns.SWG
        },
        TRO: {
            view: RaceItemViewMultiple,
            viewOptions: {betType: 'TRO'},
            th: {
                number: {
                    title: '#',
                    width: '13%',
                    sortable: true,
                    active: true,
                    sort_attribute: 'programNumber'
                },
                data: {
                    title: app.polyglot.t('label_runner_p'),
                    width: '47%',
                    alignment: 'left',
                    sortable: true,
                    active: true,
                    sort_attribute: 'programNumber'
                },
                CHK1: {title: app.polyglot.t('label_betslip_col1'), width: '10%', alignment: 'center'},
                CHK2: {title: app.polyglot.t('label_betslip_col2'), width: '10%', alignment: 'center'},
                COMBO: {title: app.polyglot.t('label_betslip_c'), width: '10%', alignment: 'center', combo: true}
            },
            columns: betOptions.columns.TRO
        },
        TOF: {
            view: RaceItemViewMultiple,
            viewOptions: {betType: 'TOF'},
            th: {
                number: {
                    title: '#',
                    width: '13%',
                    sortable: true,
                    active: true,
                    sort_attribute: 'programNumber'
                },
                data: {
                    title: app.polyglot.t('label_runner_p'),
                    width: '67%',
                    alignment: 'left',
                    sortable: true,
                    active: true,
                    sort_attribute: 'programNumber'
                },
                CHK1: {title: app.polyglot.t('label_betslip_col1'), width: '10%', alignment: 'center'},
                COMBO: {title: app.polyglot.t('label_betslip_c'), width: '10%', alignment: 'center', combo: true}
            },
            columns: betOptions.columns.TOF
        },

        QRP: { //Quarte+'
            view: RaceItemViewMultiple,
            viewOptions: {betType: 'QRP'},
            th: {
                data: {
                    title: app.polyglot.t('label_runner_p'),
                    width: '40%',
                    alignment: 'left',
                    sortable: true,
                    active: true,
                    sort_attribute: 'programNumber',
                    minimal: true
                },
                CHK1: {title: app.polyglot.t('label_betslip_col1'), width: '10%', alignment: 'center' , class: 'pl0'},
                CHK2: {title: app.polyglot.t('label_betslip_col2'), width: '10%', alignment: 'center'},
                CHK3: {title: app.polyglot.t('label_betslip_col3'), width: '10%', alignment: 'center'},
                CHK4: {title: app.polyglot.t('label_betslip_col4'), width: '10%', alignment: 'center'},
                COMBO: {title: app.polyglot.t('label_betslip_c'), width: '10%', alignment: 'center', combo: true}
            },
            columns: betOptions.columns.QRP
        },
        QNP: { //Quinte+'
            view: RaceItemViewMultiple,
            viewOptions: {betType: 'QNP'},
            th: {
                data: {
                    title: app.polyglot.t('label_runner_p'),
                    width: '30%',
                    alignment: 'left',
                    sortable: true,
                    active: true,
                    sort_attribute: 'programNumber',
                    minimal: true
                },
                CHK1: {title: app.polyglot.t('label_betslip_col1'), width: '10%', alignment: 'center' , class: 'pl0'},
                CHK2: {title: app.polyglot.t('label_betslip_col2'), width: '10%', alignment: 'center'},
                CHK3: {title: app.polyglot.t('label_betslip_col3'), width: '10%', alignment: 'center'},
                CHK4: {title: app.polyglot.t('label_betslip_col4'), width: '10%', alignment: 'center'},
                CHK5: {title: app.polyglot.t('label_betslip_col5'), width: '10%', alignment: 'center'},
                COMBO: {title: app.polyglot.t('label_betslip_c'), width: '10%', alignment: 'center', combo: true}
            },
            columns: betOptions.columns.QNP
        },
        SF4: { //Super4
            view: RaceItemViewMultiple,
            viewOptions: {betType: 'SF4'},
            th: {
                data: {
                    title: app.polyglot.t('label_runner_p'),
                    width: '40%',
                    alignment: 'left',
                    sortable: true,
                    active: true,
                    sort_attribute: 'programNumber',
                    minimal: true
                },
                CHK1: {title: app.polyglot.t('label_betslip_col1'), width: '10%', alignment: 'center' , class: 'pl0'},
                CHK2: {title: app.polyglot.t('label_betslip_col2'), width: '10%', alignment: 'center'},
                CHK3: {title: app.polyglot.t('label_betslip_col3'), width: '10%', alignment: 'center'},
                CHK4: {title: app.polyglot.t('label_betslip_col4'), width: '10%', alignment: 'center'},
                COMBO: {title: app.polyglot.t('label_betslip_c'), width: '10%', alignment: 'center', combo: true}
            },
            columns: betOptions.columns.SF4
        },
        TRC: { //Tierce+'
            view: RaceItemViewMultiple,
            viewOptions: {betType: 'TRC'},
            th: {
                data: {
                    title: app.polyglot.t('label_runner_p'),
                    width: '50%',
                    alignment: 'left',
                    sortable: true,
                    active: true,
                    sort_attribute: 'programNumber',
                    minimal: true
                },
                CHK1: {title: app.polyglot.t('label_betslip_col1'), width: '10%', alignment: 'center', class: 'pl0'},
                CHK2: {title: app.polyglot.t('label_betslip_col2'), width: '10%', alignment: 'center'},
                CHK3: {title: app.polyglot.t('label_betslip_col3'), width: '10%', alignment: 'center'},
                COMBO: {title: app.polyglot.t('label_betslip_c'), width: '10%', alignment: 'center', combo: true}
            },
            columns: betOptions.columns.TRC
        },
        M4: { //Multi4
            view: RaceItemViewMultiple,
            viewOptions: {betType: 'M4'},
            th: {
                number: {
                    title: '#',
                    width: '13%',
                    sortable: true,
                    active: true,
                    sort_attribute: 'programNumber'
                },
                data: {
                    title: app.polyglot.t('label_runner_p'),
                    width: '67%',
                    alignment: 'left',
                    sortable: true,
                    active: true,
                    sort_attribute: 'programNumber'
                },
                CHK1: {title: app.polyglot.t('label_betslip_col1'), width: '10%', alignment: 'center'},
                COMBO: {title: app.polyglot.t('label_betslip_c'), width: '10%', alignment: 'center', combo: true}
            },
            columns: betOptions.columns.M4
        },
        M5: { //Multi5
            view: RaceItemViewMultiple,
            viewOptions: {betType: 'M5'},
            th: {
                number: {
                    title: '#',
                    width: '13%',
                    sortable: true,
                    active: true,
                    sort_attribute: 'programNumber'
                },
                data: {
                    title: app.polyglot.t('label_runner_p'),
                    width: '67%',
                    alignment: 'left',
                    sortable: true,
                    active: true,
                    sort_attribute: 'programNumber'
                },
                CHK1: {title: app.polyglot.t('label_betslip_col1'), width: '10%', alignment: 'center'},
                COMBO: {title: app.polyglot.t('label_betslip_c'), width: '10%', alignment: 'center', combo: true}
            },
            columns: betOptions.columns.M5
        },
        M6: { //Multi6
            view: RaceItemViewMultiple,
            viewOptions: {betType: 'M6'},
            th: {
                number: {
                    title: '#',
                    width: '13%',
                    sortable: true,
                    active: true,
                    sort_attribute: 'programNumber'
                },
                data: {
                    title: app.polyglot.t('label_runner_p'),
                    width: '67%',
                    alignment: 'left',
                    sortable: true,
                    active: true,
                    sort_attribute: 'programNumber'
                },
                CHK1: {title: app.polyglot.t('label_betslip_col1'), width: '10%', alignment: 'center'},
                COMBO: {title: app.polyglot.t('label_betslip_c'), width: '10%', alignment: 'center', combo: true}
            },
            columns: betOptions.columns.M6
        },
        M7: { //Multi7
            view: RaceItemViewMultiple,
            viewOptions: {betType: 'M7'},
            th: {
                number: {
                    title: '#',
                    width: '13%',
                    sortable: true,
                    active: true,
                    sort_attribute: 'programNumber'
                },
                data: {
                    title: app.polyglot.t('label_runner_p'),
                    width: '67%',
                    alignment: 'left',
                    sortable: true,
                    active: true,
                    sort_attribute: 'programNumber'
                },
                CHK1: {title: app.polyglot.t('label_betslip_col1'), width: '10%', alignment: 'center'},
                COMBO: {title: app.polyglot.t('label_betslip_c'), width: '10%', alignment: 'center', combo: true}
            },
            columns: betOptions.columns.M7
        },
        PK5: {
            view: RaceItemViewMultiple,
            viewOptions: {betType: 'PK5'},
            th: {
                number: {
                    title: '#',
                    width: '13%',
                    sortable: true,
                    active: true,
                    sort_attribute: 'programNumber'
                },
                data: {
                    title: app.polyglot.t('label_runner_p'),
                    width: '67%',
                    alignment: 'left',
                    sortable: true,
                    active: true,
                    sort_attribute: 'programNumber'
                },
                CHK1: {title: app.polyglot.t('label_betslip_col1'), width: '10%', alignment: 'center'},
                COMBO: {title: app.polyglot.t('label_betslip_c'), width: '10%', alignment: 'center', combo: true}
            },
            columns: betOptions.columns.PK5

        }
    }
}
