'use strict';
import $                            from 'jquery';
import _                            from 'underscore';
import app                          from 'components/core/application';
import Backbone                     from 'backbone';
import BackboneCloseView            from 'components/core/mixins/Backbone.CloseView';
import template                     from 'modules/next-races/next-races.tpl.hbs';
import countDown                    from 'components/helpers/countDown';
import appSettings                  from 'components/app.settings';
import NextRacesCollection          from 'modules/next-races/next-races.collection';

/**
 * Next Races View
 *
 * @name NextRacesView
 * @constructor
 *
 * @requires jQuery
 * @requires Underscore
 * @requires template
 *
 * @description
 *  List upcoming races
 *
 * @returns {Function} - Constructor function for NextRacesView
 */

var NextRacesView = Backbone.View.extend(
    _.extend({}, BackboneCloseView, {

        className: 'c-dataList rowsBordered',

        template: template,

        events: {
            'click [data-href]': 'goToRace'
        },

        initialize: function () {
            this.data = {};
            this.DOM = {};
            this.collection = new NextRacesCollection();
            this.listenTo(this.collection, 'sync', this.render);
            this.collection.fetch();
            this.initCountDowns();
        },

        initCountDowns: function () {
            var callback = () => {
                _.each(this.DOM.countDownEls, (el) => {
                    let $el = $(el);
                    let idRace = $el.attr('data-countdown');
                    let model = this.collection.findWhere({'idRace': idRace});
                    let countDown = model.getCountDownTime();
                    $el.html(countDown.textValue);
                });
            };

            this.countDown = new countDown();
            this.countDown.init(appSettings.eventsCountDownStartTime, 'nextraces:timer');
            this.listenTo(app, 'nextraces:timer', callback);
        },

        render: function () {
            this.$el.html(this.template({data: this.collection.toJSON()}));
            this.DOM.countDownEls = this.$('[data-countdown]');
            return this;
        },

        onClose: function () {
            this.countDown.destroy();
        },

        goToRace: function (e) {
            app.trigger('navigate', '/race/details/id/' + $(e.currentTarget).attr('data-href'));
        }
    })
);

export default NextRacesView;
