var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var alias1=this.escapeExpression;

  return "<div class=\"c-dialog__message\">\n    <button data-btn=\"0\" class=\"c-btn c-btn--primary mb1\">"
    + alias1(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_livestream_low_quality",{"name":"translate","hash":{},"data":data}))
    + " </button>\n    <button data-btn=\"1\" class=\"c-btn c-btn--primary\">"
    + alias1(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_livestream_high_quality",{"name":"translate","hash":{},"data":data}))
    + " </button>\n</div>\n";
},"useData":true});