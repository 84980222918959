'use strict';

/**
 * Backbone.ParentTabbedView view
 *
 * @description
 *      Parent view with tabs.
 *
 * @type Backbone View
 */

var ParentTabbedView = {
    initializeTabbedView: function () {
        /**
         * @property {Array} tabLinkParentList - Parent elements for tab links
         *
         * @description
         *     Stores parent elements for tabs links
         *     based on current css structure (isActive is applied to <li> element)
         */
        this.tabLinkParentList = [];

        /**
         * @property {Object} cachedUrls - Tabs' links
         *
         * @description
         *     Stores tabs' links
         *     {key: $element}
         */
        this.cachedUrls = {};

        /**
         * @property {String} subViewTag - SubView html tag
         *
         * @description
         *     Html tag to wrap the subview
         *
         * @default [null]
         */
        this.subViewTag = null;

        /**
         * @property {String} activeTab - Active tab name
         *
         * @description
         *     Active tab name (same as route. E.g. 'current', 'antepost', 'calendar')
         *
         * @default [null]
         */
        this.activeTab = null;

        /**
         * @property {Boolean} isTabbedView - Is a parent view with tabs
         *
         * @description
         *     Marks view as parent with tabs
         *
         * @default [true]
         */
        this.isTabbedView = true;

        this.tabViews = [];
    },

    /**
     * @method manageSubView
     *
     * @description
     *     Closes previous view if any and creates and renders a new subview.
     */
    manageSubView: function () {
        //Close previous subview
        if (this.activSubView) {
            this.activSubView.close();
        }

        this.activSubView = this.getSubView();
        this.subViewTag.html(this.activSubView.render().$el);
        this.subViewTag.scrollTop(0);
        this.setActiveTab();
    },

    /**
     * @method getSubView
     *
     * @description
     *     Returns new subView instance
     *
     * @returns {Object}  - subView instance
     */
    getSubView: function () {
        this.activeTab = this.tabViews[this.activeTab] ? this.activeTab : this.defaultActiveTab;
        // this.options is set on the parent view initialize, is used to pass down route parameters
        return new this.tabViews[this.activeTab](this.options);
    },

    /**
     * @method setActiveTab
     *
     * @description
     *     Marks active tab as selected or sets a default one
     */
    setActiveTab: function () {
        //todo: investigate where this.cachedUrls gets its initial value
        this.cachedUrls[this.activeTab] = this.$el.find('a[href*="' + this.activeTab + '"]');

        this.tabLinkParentList.removeClass('isActive');
        this.cachedUrls[this.activeTab].parent().addClass('isActive');
        this.trigger('tab:changedActive', this.activeTab);
    },

    /**
     * @method closeSubViews
     *
     * @description
     *     Call close() on an active subView to clean it up.
     *     subView's onClose() is also invoked to clean references to models if any
     */
    closeTabsViews: function () {
        if (this.activSubView) {
            this.activSubView.close();
        }
        this.activSubView = null;
    }
};

export default ParentTabbedView;
