'use strict';

import _             from 'underscore';
import app           from 'components/core/application';
import maybe         from 'components/helpers/maybe';
import moment        from 'moment-timezone';
import auth          from 'components/auth/auth';
import helpers       from 'components/helpers/helpers';
import MediaView     from 'components/media/media';
import user          from 'components/user/user';

/**
 * Media service
 *
 * @name mediaService
 *
 * @requires app
 * @requires maybe
 * @requires auth
 * @requires moment
 * @requires helpers
 * @requires MediaView
 *
 * @description
 * 		Manages media
 *
 * @returns {object}
 */
const mediaService = {
    setUpMedia: function(data, idResource = null) {

        var media = maybe.of(data).mapDotProp('race.media').join();
        var idRace = maybe.of(data).mapDotProp('race.idRace').join();

        if(!media || !idRace) return;

        var mediaTime = moment.unix(media.startTime);

        // Add idRace to the media data, used to retrieve archive video
        media['idRace'] = idRace;
        media['idResource'] = idResource;

        if (mediaTime.isBefore(moment().tz(user.timeZone())) || media.provider === 'PFG') { // we skip this check for pfg as it will be done after the bet check
            app.mediaView = null;
            app.mediaView = new MediaView(media, idResource);
        } else {
            app.trigger('dialog:warning', app.interpolate('msg_media_start_time', {time: mediaTime.format('LT')}));
        }
    },

    startMedia: function(data, idResource = null) {
        auth.ifLoggedIn(() => {
            this.setUpMedia(data, idResource);
        }, this)()
    },

    mediaAvailable: function(data) {
        var media = maybe.of(data).mapDotProp('race.media').join();
        var event = maybe.of(data).mapProp('event').join();
        return media && event.country !== 'AU' && helpers.isCountryAllowed(user.data.mediaCountry || user.data.ipCountry, media.filterCountries, media.filterType);
    },

    getContextMenuActions: function (data) {
        let contextMenuActions = [];

        if(this.mediaAvailable(data)) {
            let media = maybe.of(data).mapDotProp('race.media').join();
            if (media.provider === 'PFG') {
                contextMenuActions.push({
                    event: 'click',
                    selector: '#livestream-PFG',
                    domSelector: 'id=livestream-PFG',
                    title: app.polyglot.t('label_livestream'),
                    icon: 'rb-icon--cam',
                    cb: (e) => {
                        app.overlay.closeComponent();
                        this.startMedia(data);
                    }
                });

                return contextMenuActions;
            }

            _.each(media.resources, (resource) => {
                var title;
                if (media.streamType === 'LIV') {
                    title = 'label_livestream_';
                    title += parseInt(resource.bitrate, 10) < 400 ? 'low_quality' : 'high_quality';

                    if (resource.technology === 'MP3') {
                        title = 'label_audio_link';
                    }
                } else {
                    title = 'label_archive_';
                    title += parseInt(resource.bitrate, 10) < 400 ? 'low_quality' : 'high_quality';
                }
                var idResource = resource.idResource;
                contextMenuActions.push({
                    event: 'click',
                    selector: '#livestream-' + resource.idResource,
                    domSelector: 'id=livestream-' + resource.idResource,
                    title: app.polyglot.t(title),
                    icon: 'rb-icon--cam',
                    cb: (e) => {
                        app.overlay.closeComponent();
                        this.startMedia(data, idResource);
                    }
                });
            });

            // us race streaming have no resources, no idChannel
            if (_.isUndefined(media.resources) && _.isNull(media.idChannel)) {
                contextMenuActions.push({
                    event: 'click',
                    selector: '#livestream',
                    domSelector: 'id=livestream',
                    title: app.polyglot.t('label_livestream'),
                    icon: 'rb-icon--cam',
                    cb: (e) => {
                        app.overlay.closeComponent();
                        this.startMedia(data);
                    }
                });
            }

        }
        return contextMenuActions;
    }
};

export default mediaService;
