var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.data : depth0),{"name":"each","hash":{},"fn":this.program(2, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"2":function(depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression, alias4=this.lambda;

  return "        <div id=\""
    + alias3(((helper = (helper = helpers.idRace || (depth0 != null ? depth0.idRace : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"idRace","hash":{},"data":data}) : helper)))
    + "\" data-h2h-"
    + alias3(((helper = (helper = helpers.idEvent || (depth0 != null ? depth0.idEvent : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"idEvent","hash":{},"data":data}) : helper)))
    + " class=\"c-accordion__head c-countryList__head\">\n            <h3>\n                <i class=\"c-flag c-flag isCountry"
    + alias3(((helper = (helper = helpers.country || (depth0 != null ? depth0.country : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"country","hash":{},"data":data}) : helper)))
    + "\"></i>\n                "
    + alias3(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"title","hash":{},"data":data}) : helper)))
    + " | R"
    + alias3(((helper = (helper = helpers.raceNumber || (depth0 != null ? depth0.raceNumber : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"raceNumber","hash":{},"data":data}) : helper)))
    + "\n            </h3>\n            <div class=\"m-h2h__time\">\n                "
    + alias3(((helper = (helper = helpers.raceTime || (depth0 != null ? depth0.raceTime : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"raceTime","hash":{},"data":data}) : helper)))
    + "\n            </div>\n        </div>\n\n        <div class=\"c-grid\">\n            <div class=\"c-grid__col is-1-2 text-center p-rel\">\n                <span class=\"c-silk c-silk--large inline-block mt2\" "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.runners : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.silkUrl : stack1),{"name":"if","hash":{},"fn":this.program(3, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "></span>\n                <div class=\"m-h2h__runner\">"
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? depth0.runners : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.programNumber : stack1), depth0))
    + " "
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? depth0.runners : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.name : stack1), depth0))
    + "</div>\n                <div class=\"m-h2h__btn\">\n                    <a data-idRunner="
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? depth0.runners : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.idRunner : stack1), depth0))
    + " data-betType=\"WIN\" role=\"button\" class=\"c-btn c-btn--block c-btn--odds c-btn--narrow\">"
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? depth0.topRunners : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.fixedOddsWin : stack1), depth0))
    + "</a>\n                </div>\n                <div class=\"m-h2h__vs\"><span>vs</span></div>\n            </div>\n            <div class=\"c-grid__col is-1-2 text-center\">\n                <span class=\"c-silk c-silk--large inline-block mt2\" "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.runners : depth0)) != null ? stack1["1"] : stack1)) != null ? stack1.silkUrl : stack1),{"name":"if","hash":{},"fn":this.program(5, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "></span>\n                <div class=\"m-h2h__runner\">"
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? depth0.runners : depth0)) != null ? stack1["1"] : stack1)) != null ? stack1.programNumber : stack1), depth0))
    + " "
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? depth0.runners : depth0)) != null ? stack1["1"] : stack1)) != null ? stack1.name : stack1), depth0))
    + "</div>\n                <div class=\"m-h2h__btn\">\n                    <a data-idRunner="
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? depth0.runners : depth0)) != null ? stack1["1"] : stack1)) != null ? stack1.idRunner : stack1), depth0))
    + " data-betType=\"WIN\" role=\"button\" class=\"c-btn c-btn--block c-btn--odds c-btn--narrow\">"
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? depth0.topRunners : depth0)) != null ? stack1["1"] : stack1)) != null ? stack1.fixedOddsWin : stack1), depth0))
    + "</a>\n                </div>\n            </div>\n        </div>\n";
},"3":function(depth0,helpers,partials,data) {
    var stack1;

  return "style=\"background-image:url('"
    + this.escapeExpression(this.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.topRunners : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.silkUrl : stack1), depth0))
    + "')\"";
},"5":function(depth0,helpers,partials,data) {
    var stack1;

  return "style=\"background-image:url('"
    + this.escapeExpression(this.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.topRunners : depth0)) != null ? stack1["1"] : stack1)) != null ? stack1.silkUrl : stack1), depth0))
    + "')\"";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.data : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"useData":true});