import _                    from 'underscore';
import $                    from 'jquery';
import Backbone             from 'backbone';
import BackboneCloseView    from 'components/core/mixins/Backbone.CloseView';
import app                  from 'components/core/application';
import user                 from 'components/user/user';
import template             from 'components/auth/new-terms.tpl.hbs';
import LoginModel           from 'components/auth/login.model';
import serviceFactory       from 'components/helpers/serviceFactory';
import { TermsContentView } from 'components/auth/new-terms-content';

export const NewTermsView = Backbone.View.extend(
    _.extend({}, BackboneCloseView, {

        className: 'c-newTerms l-scrollV',

        template: template,

        events: {
            'click [data-id-accept]': 'resend',
            'click [data-id-toggle-terms]': 'toggleTerms'
        },

        initialize: function(options={}) {
            this.DOM = {};
            this.options = _.extend({}, this.options, options);
            this.data = {
                summary: {content: '', url: `/rest/v2/terms/${user.data.language}/summary`},
                terms: {content: '', url: `/rest/v2/terms/${user.data.language}`}
            };
            this.data.isSummary = true;

            this.listenTo(app.overlay, 'overlay:render', () => {
                this.contentView = new TermsContentView();
                this.onViewRendered();
            });
            this.overlay = app.overlay;
        },

        loadContent: function(type='summary') {
            this.data.isSummary = type === 'summary';

            if (this.data[type].content) {
                this.contentView.data.content = this.data[type].content;
                this.renderContent();
                return;
            }

            this.getContent(type).then(
                (resp) => {
                    if (resp.data.trim() !== '') {
                        this.contentView.data.content = this.data[type].content = resp.data;
                        this.renderContent();
                    } else {
                        this.loadContent('terms');
                    }
                },
                (resp) => {
                    this.loadContent('terms');
                }
            );
        },

        render: function () {
            this.$el.html(this.template(this.data));
            this.DOM.accept = this.$el.find('[data-id-accept]');
            this.DOM.toggle = this.$el.find('[data-id-toggle-terms]');

            this.overlay.showComponent(this);
            return this;
        },

        renderContent: function() {
            this.contentView.render();
            this.DOM.toggle.html(app.polyglot.t(this.data.isSummary ? 'label_view_full_terms_conditions' : 'label_view_terms_changes'));
        },

        onViewRendered: function() {
            this.loadContent();
        },

        getContent: function(type) {
            return $.get(this.data[type].url);
        },

        resend: function(e) {
            e.preventDefault();
            app.loadingManager.show();
            this.DOM.accept.attr('disabled', true);

            const loginModel = new LoginModel();
            const data = serviceFactory.login.get('data');

            data.accept_terms = true;
            loginModel.save(data);
        },

        toggleTerms: function(e) {
            e.preventDefault();
            this.contentView.clearData();
            this.loadContent(this.data.isSummary ? 'terms' : 'summary');
        }
    })
);
