var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression, alias4=this.lambda;

  return "<div class=\"c-raceTitle__header\" data-toggle-subHeader-extra>"
    + ((stack1 = ((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"title","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n    <div class=\"c-raceTitle__subHeader\" data-subHeader>"
    + alias3(((helper = (helper = helpers.info || (depth0 != null ? depth0.info : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"info","hash":{},"data":data}) : helper)))
    + "</div>\n</div>\n<div class=\"c-raceTitle__subHeaderExtra\" data-subHeader-extra>\n    <div>"
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.extra : depth0)) != null ? stack1.top : stack1), depth0))
    + "</div>\n    <div>"
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.extra : depth0)) != null ? stack1.bottom : stack1), depth0))
    + "</div>\n    <div>"
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.extra : depth0)) != null ? stack1.purseDetails : stack1), depth0))
    + "</div>\n    <div>"
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.extra : depth0)) != null ? stack1.placeTermsInfo : stack1), depth0))
    + "</div>\n    <div>"
    + ((stack1 = alias4(((stack1 = (depth0 != null ? depth0.extra : depth0)) != null ? stack1.rule4 : stack1), depth0)) != null ? stack1 : "")
    + "</div>\n</div>\n";
},"useData":true});