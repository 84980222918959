'use strict';
import _                    from 'underscore';
import $                    from 'jquery';
import app                  from 'components/core/application';
import Backbone             from 'backbone';
import template             from 'components/seo-text/seo-text.tpl.hbs';
import BackboneSubView      from 'components/core/mixins/Backbone.SubView';
import BackboneCloseView    from 'components/core/mixins/Backbone.CloseView';

/**
 * Seo Text view Controller
 * @name SeoTextView
 * @constructor
 *
 * @requires Underscore
 * @requires jQuery
 * @requires app
 * @requires BackboneSubView
 * @requires HomeCollection
 *
 * @description
 *     Seo Text Controller
 *
 * @returns Function - Constructor function for SeoText view
 */
export default Backbone.View.extend(
    _.extend({}, BackboneSubView, BackboneCloseView, {

        template: template,

        events: {
            'click [data-show]': 'showMore'
        },

        initialize: function (opt) {
            this.DOM = {};

            this.data = {
                text: app.interpolate('txt_seo_home_page', { brandName: app.interpolate('seo_brand') })
            }

        },

        
        render: function () {
            if (this.DOM.seo) return this;
            
            this.$el.html(this.template(this.data));
            
            this.DOM.seo = this.$('[data-seo-text]');

            return this;
        },
        
        showMore: function () {
            this.DOM.seo.toggleClass('c-seo--isOpen')
        },

    })
);
