var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.escapeExpression;

  return "<div class=\"pb0\">\n    <div class=\"c-dialog__circle-wrap\">\n        <div class=\"c-dialog__circle\">\n            <div class=\"rb-icon rb-icon--checkmark c-dialog__circle-icon\"></div>\n        </div>\n    </div>\n    <div class=\"text-center\">\n        <h2 class=\"c-dialog__heading\">"
    + alias1(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_bet_successful",{"name":"translate","hash":{},"data":data}))
    + "</h2>\n        <p class=\"c-dialog__text\">"
    + alias1(__default(require("../helpers/handlebar-helpers/translateInterpolate.js")).call(depth0,"label_bet_placed",{"name":"translateInterpolate","hash":{"idBetslip":(depth0 != null ? depth0.publicId : depth0)},"data":data}))
    + "</p>\n    </div>\n\n    <a class=\"c-btn c-btn--block c-btn--primary mt2 mb1\" data-dialog-closeBtn role=\"button\">"
    + alias1(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_close",{"name":"translate","hash":{},"data":data}))
    + "</a>\n    <a class=\"c-btn c-btn--block c-btn--light mb2\" data-dialog-retain role=\"button\">"
    + alias1(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_retain_selections",{"name":"translate","hash":{},"data":data}))
    + "</a>\n    <div class=\"c-dialog__footer text-center\">"
    + ((stack1 = __default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"msg_bet_placed_footer",{"name":"translate","hash":{},"data":data})) != null ? stack1 : "")
    + "</div>\n</div>\n\n";
},"useData":true});