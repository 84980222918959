'use strict';

import _                           from 'underscore';
import $                           from 'jquery';
import app                         from 'components/core/application';
import Backbone                    from 'backbone';
import template                    from 'modules/race/race-promotions-terms.tpl.hbs';
import raceHelpers                 from 'modules/race/race.helpers';
import helpers                     from 'components/helpers/helpers';
import BackboneCloseView           from 'components/core/mixins/Backbone.CloseView';

/**
 * Jackpot and bonus view
 *
 * @name PromotionsTermsView
 * @constructor
 *
 * @requires Underscore
 * @requires jQuery
 * @requires Backbone
 * @requires template
 * @requires raceHelpers
 * @requires helpers
 * @requires BackboneCloseView
 *
 * @description
 * 		PromotionsTermsView view
 *
 * @returns {Function} - Constructor function for PromotionsTermsView
 */

const PromotionsTermsView = Backbone.View.extend(
    _.extend({}, BackboneCloseView, {

        template: template,

        events: {
            'click [data-dialog]': 'openDialog',
            'click [data-link]': 'goToPage'
        },

        initialize: function (options) {
            this.data = options.racePromotions;
        },

        openDialog: function(e) {
            var promotionTypeLabel = $(e.currentTarget).attr('data-dialog');
            app.trigger('dialog:terms', {key:'terms_promotion_'+promotionTypeLabel});
        },

        goToPage: function (e) {
            e.preventDefault();
            e.stopPropagation();

            if (e.target.href) {
                window.open(e.target.href);
            } else {
                app.router.navigate('/info/terms-conditions', {trigger: true});
            }
        },

        render: function () {
            this.$el.html(this.template({racePromotions: this.data}));
            return this;
        }

    })
);

export default PromotionsTermsView;
