import Backbone                 from 'backbone';

/**
 * @method globalLinkHandler
 *
 * @description
 *      Listen to global events and check for clicks on links
 *      to stop full page reload when {pushState: true}
 *
 *      Link tags with role="button" are omitted.
 *      Link tags with role="button" are treated as buttons.
 *
 * @param {Object} e
 */
export function globalLinkHandler(e) {

    var url = e.currentTarget.host;

    if (!url) {
        var eventPath = e.originalEvent.path;
        e.preventDefault();

        for (var i = 0; i < eventPath.length; i++) {
            if (eventPath[i].tagName === 'A') {
                url = eventPath[i].pathname;
                break;
            }
        }
    } else if (url === window.location.host) {
        e.preventDefault();
        url = e.currentTarget.pathname;
    }


    Backbone.history.navigate(url, {trigger: true});
}