import _                            from 'underscore';
import Backbone                     from 'backbone';
import BackboneCloseView            from 'components/core/mixins/Backbone.CloseView';
import * as RaceItemMethods         from 'modules/race/race-item.methods';
import template                     from 'modules/race/race-item.tpl.hbs';

/**
 * @name RaceItemExView
 * @constructor
 *
 * @requires Underscore
 * @requires Backbone
 * @requires BackboneCloseView
 * @requires RaceItemMethods
 * @requires template
 *
 * @description
 * 		Extended Race item view
 *
 * @returns {Function} - Constructor function for RaceItemExView
 */

var RaceItemExView = Backbone.View.extend(
    _.extend({}, BackboneCloseView, RaceItemMethods, {

        template: template,

        viewListeners: function() {
            _.each(this.buttonViews, (button, index) => {
                this.listenTo(button, 'button:fire', this.toggleRunnerOverlay);
            });
        },

        toggleRunnerOverlay: function({idRunner, toBetSlip}) {
            if (toBetSlip) {
                // making sure all buttons are disabled for this runner
                _.each(this.buttonViews, (button, i) => {
                    button.DOM.button.prop('disabled', true);
                });
                this.DOM.itemOverlay.one('animationend webkitAnimationEnd', () => {
                    this.DOM.itemOverlay.removeClass('fadeOut');
                });
                this.DOM.itemOverlay.addClass('fadeOut');
            }
        }
    })
);

export default RaceItemExView;
