export default function (lvalue, operator, rvalue, options) {
    var operators, result;

    if (lvalue && typeof lvalue === 'string' || lvalue instanceof String) {
        lvalue = lvalue.toLowerCase();
    }

    if (rvalue && typeof rvalue === 'string' || rvalue instanceof String) {
        rvalue = rvalue.toLowerCase();
    }

    if (arguments.length < 3) {
        throw new Error('Handlebars Helper \'compare\' needs 2 parameters');
    }

    if (options === undefined) {
        options = rvalue;
        rvalue = operator;
        operator = '===';
    }

    operators = {
        '==': function (l, r) {
            return l == r; },
        '===': function (l, r) {
            return l === r; },
        '!=': function (l, r) {
            return l != r; },
        '!==': function (l, r) {
            return l !== r; },
        '<': function (l, r) {
            return l < r; },
        '>': function (l, r) {
            return l > r; },
        '<=': function (l, r) {
            return l <= r; },
        '>=': function (l, r) {
            return l >= r; },
        'typeof': function (l, r) {
            return typeof l == r; }
    };

    if (!operators[operator]) {
        throw new Error('Handlebars Helper \'compare\' doesn\'t know the operator' + operator);
    }

    result = operators[operator](lvalue, rvalue);

    if (result) {
        return options.fn(this);
    } else {
        return options.inverse(this);
    }
};
