var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.lambda;

  return "        <li class=\"c-actionSheet__listItem\"><a role=\"button\" class=\"c-actionSheet__listItemLink\" "
    + this.escapeExpression(alias1((depth0 != null ? depth0.domSelector : depth0), depth0))
    + "><i class=\""
    + ((stack1 = helpers["if"].call(depth0,__default(require("../helpers/handlebar-helpers/exists.js")).call(depth0,(depth0 != null ? depth0.icon : depth0),{"name":"exists","hash":{},"data":data}),{"name":"if","hash":{},"fn":this.program(2, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\"></i>"
    + ((stack1 = alias1((depth0 != null ? depth0.title : depth0), depth0)) != null ? stack1 : "")
    + "</a></li>\n";
},"2":function(depth0,helpers,partials,data) {
    return "rb-icon "
    + this.escapeExpression(this.lambda((depth0 != null ? depth0.icon : depth0), depth0))
    + " ";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<ul class=\"c-actionSheet__list\">\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.actions : depth0),{"name":"each","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</ul>\n";
},"useData":true});