'use strict';

import _                   from 'underscore';
import app                 from 'components/core/application';
import Backbone            from 'backbone';
import helpers             from 'components/helpers/helpers';
import betslipHelpers      from 'components/betting/betslip.helpers';
import user                from 'components/user/user';
import webSocketManager    from 'components/helpers/webSocketManager';
import maybe               from 'components/helpers/maybe';

var BetItemModel = Backbone.Model.extend({

    initialize: function() {
        this.on('change:parts', this.onPartsChange, this);
        this.on('change:stake', this.onStakeChange, this);
        this.on('change:isEachWay', this.manageEachWay, this);
        this.on('change:status', this.statusChanged, this);
        this.listenTo(webSocketManager, 'update:channel:node_runner_' + this.attributes.idRunner, this.updateFromSocket);
    },

    onPartsChange: function() {
        //format odds and stake for bets
        _.each(this.attributes.parts, (part) => {
            if(part.odds) part.odds_f = helpers.formatOdds(part.odds, user.data.oddsFormat);
            if (part.stake && part.stake.unit) {
                part.stake.unit_f = helpers.formatMoney(part.stake.unit, part.stake.unit % 1 > 0 ? 2 : 0);
            }
        });

        //calculate tax for Germans
        this.attributes.tax_data = betslipHelpers.getTax(this.attributes);
    },

    //todo: potential duplication of what onPartsChange does
    onStakeChange: function() {
        //format odds and stake
        if(this.attributes.stake && this.attributes.stake.unit) {
            this.attributes.stake.unit_f = helpers.formatMoney(this.attributes.stake.unit, this.attributes.stake.unit % 1 > 0 ? 2 : 0);
        } else {
            this.attributes.stake.unit_f = '';
        }
    },

    manageEachWay: function() {
        //calculate tax for Germans
        this.attributes.tax_data = betslipHelpers.getTax(this.attributes);
    },

    updateFromSocket: function (update) {
        let runner = {};
        let event = update.data.event;
        let race = update.data.race;

        //we need to know that 'change:parts' event was not triggered by user
        this.attributes.user_entered_unit = false;

        if (update.data.runners) {
            runner = update.data.runners[0];

            this.set('scratched', runner.scratched, {silent: true});

            // update odds
            let category = this.attributes.category;
            // newOdds flag to update the runner in the betslip
            runner.newOdds = false;
            _.each(this.attributes.parts, (part) => {
                if (category === 'BOK' && !runner.scratched) {
                    if (part.odds_f && part.odds_f !== helpers.formatOdds(runner.odds.PRC, user.data.oddsFormat)) {
                        runner.newOdds = true;
                        part.odds = runner.odds.PRC;
                        part.odds_f = helpers.formatOdds(runner.odds.PRC, user.data.oddsFormat);
                        runner.new_odds_value = part.odds_f;
                    }
                }
                if (category === 'FXD' && !runner.scratched) {
                    if (part.odds_f && part.odds_f !== helpers.formatOdds(runner.odds.FXW, user.data.oddsFormat)) {
                        runner.newOdds = true;
                        part.odds = runner.odds.FXW;
                        part.odds_f = helpers.formatOdds(runner.odds.FXW, user.data.oddsFormat);
                        runner.new_odds_value = part.odds_f;
                    }
                }
            });

            // update odds select only if NOT scratched
            if(!runner.scratched) {
                this.attributes.odds_select.options = _.map(maybe.of(this).mapDotProp('attributes.odds_select.options').orElse([]).join(), (option) => {
                    if (option.category === 'BOK') {
                        option.value = runner.odds.PRC !== 0 ? helpers.formatOdds(runner.odds.PRC, user.data.oddsFormat) : null
                    }
                    if (option.category === 'FXD') {
                        option.value = runner.odds.FXW !== 0 ? helpers.formatOdds(runner.odds.FXW, user.data.oddsFormat) : null
                    }

                    return option
                });
            }

            if (runner.newOdds) {
                this.trigger('change:parts', {newOdds: runner.newOdds});
            }
        }

        this.set('status', betslipHelpers.getUpdateStatus({}, event, race, runner));
    },

    resetUnit: function() {
        _.each(this.attributes.parts, function (part) {
            if (part.stake && part.stake.unit) delete part.stake;
        });

        //multiples
        if(this.attributes.stake) {
            if(this.attributes.stake.unit) delete this.attributes.stake.unit;
            if(this.attributes.stake.unit_f) delete this.attributes.stake.unit_f;
        }
    },

    statusChanged: function(model, value) {
        this.set({'disableUnitStakeInput': value.remove && !value.accept});
    },

    oddsChanged: function(error) {
        // fixedOddsPlace
        this.updateOdds(error.fixedOddsWin, error.fixedOddsPlace, error.errorCode === 111);
        this.trigger('invalid', {}, [{name: 'odds', message: ''}]);
        this.set('status', betslipHelpers.getUpdateStatus({}, {}, {}, {newOdds: true, new_odds_value: error.errorCode === 111 ? error.fixedOddsWin : error.fixedOddsPlace}));
    },

    updateOdds: function(fixedOddsWin, fixedOddsPlace, windOddsChanged) {
        let options = maybe.of(this).mapDotProp('attributes.odds_select.options').orElse([]).join();
        let select_option = _.find(options, function(opt) {
            return opt.oddsWin !== undefined || opt.oddsPlc !== undefined;
        });

        if (select_option) {
            select_option.oddsWin = fixedOddsWin;
            select_option.value = fixedOddsWin;
        }

        this.attributes.msg_new_win_fixed_odds = windOddsChanged ? fixedOddsWin : fixedOddsPlace;
        _.each(this.attributes.parts, (part) => {
            let new_odds = part.market === 'WIN' ? fixedOddsWin : fixedOddsPlace;
            part.odds = new_odds;
            part.odds_f = helpers.formatOdds(new_odds, user.data.oddsFormat);
        });
    },

    validate: function (attrs) {
        var errors = [];
        //validating bets
        _.each(attrs.parts, function(part) {
            if (part.stake && part.stake.unit) {
                //is not a number
                if (isNaN(part.stake.unit)) errors.push({name: 'unit', message: app.polyglot.t('msg_error_digits_only')});
                //unit stake limit
                if (user && user.data && user.data.currencySettings && ['BOK', 'FXD'].indexOf(attrs.category) > -1) {
                    const min = helpers.getMinStakeBOKFXD(user.data.currencySettings, attrs.category, part.market);
                    const max = helpers.getMaxStakeBOKFXD(user.data.currencySettings, attrs.category, part.market);

                    if (part.stake.unit < min) {
                        errors.push({
                            name: 'unit',
                            message: app.interpolate('error_min_stake', {amount: min})
                        });
                    } else if (part.stake.unit > max) {
                        errors.push({
                            name: 'unit',
                            message: app.interpolate('error_max_stake', {amount: max})
                        });
                    }
                }
            }
        });

        //validating multiples
        if (attrs.stake && attrs.stake.unit) {
            //is not a number
            if (isNaN(attrs.stake.unit)) errors.push({name: 'unit', message: app.polyglot.t('msg_error_digits_only')});

            //unit stake limit. multiples can only be BOK or FXD
            if (attrs.stake.unit < attrs.stake.minStake) {
                errors.push({
                    name: 'unit',
                    message: app.interpolate('error_min_stake', {amount: attrs.stake.minStake})
                });
            } else if(attrs.stake.unit > attrs.stake.maxStake) {
                errors.push({
                    name: 'unit',
                    message: app.interpolate('error_max_stake', {amount: attrs.stake.maxStake})
                });
            }
        }

        return (errors.length > 0) ? errors : false;
    }
});

export default BetItemModel;
