'use strict';

import _    from 'underscore';

var pagination = {
    /**
     * Use to create the pagination view data
     * it returns 3 page numbers to display, with the current page in the middle
     * plus 2 booleans for prev and next page
     * e.g. pagination.create(1,3) // {prev: false, pages: [1,2,3], next: true}
     * e.g. pagination.create(3,6) // {prev: true, pages: [2,3,4], next: true}
     * e.g. pagination.create(6,6) // {prev: true, pages: [4,5,6], next: false}
     * @param current
     * @param totalPages
     * @returns A pagination object
     */
    create: function (current, totalPages) {
        if (_.isUndefined(current) || _.isUndefined(totalPages)) {
            return {};
        }

        var range = _.range(1, totalPages + 1);
        var _current = parseInt(current, 10);
        var result = [];
        var hasPrev = false;
        var hasNext = false;

        if (totalPages === 1) {
            result.push(totalPages);
            return {
                current: _current,
                prev: hasPrev,
                pages: _.map(result, function (n) {
                    return {
                        number: n,
                        active: n === _current
                    }
                }),
                next: hasNext
            };
        }

        // First page
        if (_current === 1) {
            var i = _current - 1;

            for (; i < 3; i++) {
                if (range[i]) {
                    result.push(range[i]);
                }
            }

            if (_.contains(result, _current + 1)) {
                hasNext = true;
            }

            return {
                current: _current,
                prev: hasPrev,
                pages: _.map(result, function (n) {
                    return {
                        number: n,
                        active: n === _current
                    }
                }),
                next: hasNext
            };
        }

        // Last page
        if (_current === totalPages) {
            var i = _current - 1;
            for (; i >= totalPages - 3; i--) {
                if (range[i]) {
                    result.push(range[i]);
                }
            }

            hasPrev = true;

            return {
                current: _current,
                prev: hasPrev,
                pages: _.sortBy(_.map(result, function (n) {
                    return {
                        number: n,
                        active: n === _current
                    }
                }), 'number'),
                next: hasNext
            }
        }

        var i = _current - 2;
        for (; i <= _current; i++) {
            if (range[i]) {
                result.push(range[i]);
            }
        }

        hasPrev = true;
        hasNext = true;

        return {
            current: _current,
            prev: hasPrev,
            pages: _.map(result, function (n) {
                return {
                    number: n,
                    active: n === _current
                }
            }),
            next: hasNext
        };
    }
};

export default pagination;
