import app                                  from 'components/core/application';
import viewManager                          from 'components/core/ViewManager';
import ContentView                          from 'components/view/content-view';

const extrasRouter = {
    routes: {
        'extras': 'extras',
        'extras/today-highlights': 'todayHighlights',
        'extras/top-ratio': 'topRatio',
        'extras/top-winners': 'topWinners',
        'extras/top-winners/betslip/id/:id': 'betslip',
        'extras/top-ratio/betslip/id/:id': 'betslip',
        'extras/most-betted': 'bestBacked',
        'extras/market-movers': 'marketMovers',
        'extras/horses-abroad': 'horsesAbroad',
        'extras/horses-abroad/type/:type/country/:country/date/:date': 'horsesAbroad',
    },
    controllers: {
        extras: function () {
            import(
                /* webpackChunkName: "extras" */
                'modules/extras/extras.tpl.hbs'
            ).then(function({default: template}) {
                app.subHeader.title(app.polyglot.t('label_menu_extras'));
                viewManager.removePrevView().show(new ContentView({className: 'm-extras', template}));
            });
        },

        todayStars: function () {
            import(
                /* webpackChunkName: "extras.runnersbycountry" */
                'components/view/featured-runners-by-country'
            ).then(function({default: FeaturedRunnersByCountryView}) {
                app.subHeader.title(app.polyglot.t('label_races_stars'));
                viewManager.removePrevView().show(new FeaturedRunnersByCountryView('/ajax/content/stars'));
            });
        },

        todayHighlights: function () {
            import(
                /* webpackChunkName: "extras.highlights" */
                'modules/extras/today-highlights'
            ).then(function({default: TodayHighlightsView}) {
                app.subHeader.title(app.polyglot.t('label_todays_highlights'));
                viewManager.removePrevView().show(new TodayHighlightsView());
            });
        },

        topRatio: function () {
            import(
                /* webpackChunkName: "extras.topratio" */
                'modules/extras/top-ratio'
            ).then(function({default: TopRatioView}) {
                app.subHeader.title(app.polyglot.t('label_top_ratio'));
                viewManager.removePrevView().show(new TopRatioView());
            });
        },

        topWinners: function () {
            import(
                /* webpackChunkName: "extras.topwinners" */
                'modules/extras/top-winners'
            ).then(function({default: TopWinnersView}) {
                app.subHeader.title(app.polyglot.t('label_top_winners'));
                viewManager.removePrevView().show(new TopWinnersView());
            });
        },

        bestBacked: function () {
            import(
                /* webpackChunkName: "extras.runnersbycountry" */
                'components/view/featured-runners-by-country'
            ).then(function({default: FeaturedRunnersByCountryView}) {
                app.subHeader.title(app.polyglot.t('label_most_betted_horses'));
                viewManager.removePrevView().show(new FeaturedRunnersByCountryView('/ajax/content/mostbetted'));
            });
        },

        marketMovers: function () {
            import(
                /* webpackChunkName: "extras.marketmovers" */
                'modules/extras/market-movers'
            ).then(function({default: MarketMoversView}) {
                app.subHeader.title(app.polyglot.t('label_market_movers'));
                viewManager.removePrevView().show(new MarketMoversView());
            });
        },

        horsesAbroad: function (type, country, date) {
            import(
                /* webpackChunkName: "extras.horsesabroad" */
                'modules/extras/horses-abroad'
            ).then(function({default: HorsesAbroadView}) {
                app.subHeader.title(app.polyglot.t('label_horses_abroad'));
                viewManager.removePrevView().show(new HorsesAbroadView({type: type, country: country, date: date}));
            });
        },

        betslip: function (id) {
            import(
                /* webpackChunkName: "betslip" */
                'modules/betslip/betslip'
            ).then(function({default: BetslipView}) {
                app.subHeader.title(app.polyglot.t('label_betslip'));
                viewManager.removePrevView().show(new BetslipView(id));
            });
        },
    }
}

export default extrasRouter;
