'use strict';

import _        from 'underscore';
import Backbone from 'backbone';
import maybe    from 'components/helpers/maybe';

/**
 * Sortable collection
 *
 * @name SortableCollection
 * @constructor
 *
 * @requires Backbone
 *
 * @description
 *  Contains sorting logic and is used only to perform sorting on models
 *
 * @returns {Function} - Constructor function for Sortable collection
 */
const SortableCollection = Backbone.Collection.extend({

    initialize: function(models, options) {
        this.asc = options.sort_order === 'asc';
        this.sort_attribute = options.sort_attribute;
    },

    comparator: function(a, b) {
        var sampleDataA = a.get(this.sort_attribute),
            sampleDataB = b.get(this.sort_attribute);

        if(_.isUndefined(sampleDataA)) sampleDataA = 1000;
        if(_.isUndefined(sampleDataB)) sampleDataB = 1000;

        //convert fractions to decimals
        if(sampleDataA && sampleDataA.toString().indexOf('/') > -1) sampleDataA = eval(sampleDataA);
        if(sampleDataB && sampleDataB.toString().indexOf('/') > -1) sampleDataB = eval(sampleDataB);

        if (this.asc) {
            if (sampleDataA > sampleDataB) { return 1; }
            if (sampleDataB > sampleDataA) { return -1; }
            return 0;
        } else {
            if (sampleDataA < sampleDataB) { return 1; }
            if (sampleDataB < sampleDataA) { return -1; }
            return 0;
        }
    },

    sortByAttribute: function(attribute, order) {
        this.sort_attribute = attribute;
        this.asc = order === 'asc';
        this.sort();
    }
});

//with this there is no need to set url which is mandatory for a collection
SortableCollection.prototype.sync = function() {return null;};
SortableCollection.prototype.fetch = function() {return null;};
SortableCollection.prototype.save = function() {return null;};

export default SortableCollection;
