'use strict';

import _                           from 'underscore';
import $                           from 'jquery';
import app                         from 'components/core/application';
import maybe                       from 'components/helpers/maybe';
import user                        from 'components/user/user';
import helpers                     from 'components/helpers/helpers';
import raceHelpers                 from 'modules/race/race.helpers';
import Backbone                    from 'backbone';
import template                    from 'modules/race/bet-type.tpl.hbs';
import betOptions                  from 'modules/race/bet-options';
import betCategoryService          from 'components/helpers/serviceFactory';
import BackboneCloseView           from 'components/core/mixins/Backbone.CloseView';

/**
 * BetType view
 *
 * @name BetTypeView
 * @constructor
 *
 * @requires Underscore
 * @requires jQuery
 * @requires Backbone
 * @requires app
 * @requires user
 * @requires maybe
 * @requires helpers
 * @requires template
 * @requires BackboneCloseView
 *
 * @description
 * 		BetType view
 *
 * @returns {Function} - Constructor function for BetType View
 */

const BetTypeView = Backbone.View.extend(
    _.extend({}, BackboneCloseView, {

        template: template,

        events: {
            'click [data-type-id]': 'changeBetType'
        },

        initialize: function(opts) {
            this.setRaceId(opts.race_id);
            this.country = opts.country;
            this.tote_currency = opts.tote_currency;
            this.h2h = opts.h2h;
            this.hidden = opts.hide;

            /**
             * Store initial viewport position for clicked bettype menu element
             */
            this.initial_position = null;

            this.race_is_started = opts.race_status === 'STR';
            this.race_is_finished = opts.race_status === 'FNL';
            this.active_bet_type = this.setActiveBetType(opts.bet_type);
            // this.bet_categories_service = betCategoryService.getByNameOrCreate('bet_category_map');
            this.type_order = helpers.marketsDisplayOrder(opts.country);
        },

        setRaceId: function(race_id) {
            this.active_race_id = race_id;
            return this;
        },

        setActiveBetType: function(val) {
            return val ? val.toUpperCase() : val;
        },

        render: function () {
            this.$el.html(this.template({types: this.types, hidden: this.hidden}));
            this.checkBetTypeVisibility();
            return this;
        },

        checkBetTypeVisibility: function() {
            if (this.types.length > 0) {
                setTimeout(() => {
                    raceHelpers.keepInViewPort(this.$(`[data-type-id="${this.active_bet_type}"]`), this.initial_position);
                }, 0);
            }
        },

        getPossibleBetTypes: function(category=null) {
            // var possible_bet_types = {
            //     'BOK': ['WIN', 'PLC', 'EXA', 'TRI', 'SFC', 'QNL', 'SWG', 'TRO', 'ITA', 'TRT'],
            //     'US': ['WIN', 'PLC', 'SHW', 'WS', 'PS', 'WPS', 'EXA', 'QNL', 'TRI', 'SFC', 'P02', 'P03', 'P04', 'P05', 'P06', 'P07', 'P08', 'P09']
            // };

            //return this.race_is_finished ? ['WIN'] : category ? possible_bet_types[category] : _.union(possible_bet_types.BOK, possible_bet_types.US);
            return (this.race_is_finished || this.race_is_started) ? ['WIN'] : this.type_order;
        },

        update: function(data) {
            this.h2h = data.race.head2head;
            this.types = this.formatTypes(maybe.of(data).mapProp('betTypes').join());
            return this;
        },

        formatTypes: function(all_types) {
            if(!all_types) throw new Error('Bet types required');
            var types = [];
            var h2hAvailable;
            _.each(betOptions.betTopCategories, (topCategory) => {
                _.each(betOptions.betCategories, (category) => {
                    let possible_bet_types = this.getPossibleBetTypes();
                    let data = maybe.of(all_types).mapDotProp(`${topCategory}.${category}`).orElse([]).join();
                    _.each(possible_bet_types, (key) => {
                        if (data[key]) {
                            types.push({
                                id: key,
                                b_class: key === 'WPS' ? 'font-size-10': '',
                                title: helpers.betTypeName(key, category, this.tote_currency == 'USD', this.country),
                                value: data[key].value,
                                active: this.active_bet_type ? this.active_bet_type === key : types.length === 0,
                            });
                        }
                    });
                });
            });
            if (this.h2h && this.h2h.length > 0 && !this.race_is_finished) h2hAvailable = true;

            var unique = _.uniq(types, 'title');
            if(h2hAvailable) unique.push({id: 'H2H', title: app.polyglot.t('label_menu_head_to_head'), active: this.active_bet_type === 'H2H'});
            return unique;
        },

        changeBetType: function(e) {
            e.preventDefault();

            const betType = $(e.currentTarget).attr('data-type-id');

            if (this.active_bet_type === betType) return;

            this.active_bet_type = betType;
            this.initial_position = raceHelpers.getRect(this.$(`[data-type-id="${this.active_bet_type}"]`));
            app.router.navigate(`/race/details/id/${this.active_race_id}/${this.active_bet_type}`, false);
            this.manageActiveType();
            this.trigger('betType:changed', {active_bet_type: this.active_bet_type});
        },

        redirect: function(betType) {
            this.active_bet_type = betType;
            app.router.navigate(`/race/details/id/${this.active_race_id}/${this.active_bet_type}`, false);
            this.manageActiveType();
            this.trigger('betType:changed', {active_bet_type: this.active_bet_type});
        },

        manageActiveType: function() {
            this.types.map((type)=> {
                type.active = type.id.toUpperCase() === this.active_bet_type;
                return type;
            });
            this.render();
        }
    })
);

export default BetTypeView;
