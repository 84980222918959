var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"c-snackBar__content\">\n    "
    + ((stack1 = this.lambda((depth0 != null ? depth0.content : depth0), depth0)) != null ? stack1 : "")
    + "\n</div>\n\n<div class=\"c-snackBar__actionButtons\">\n    <a class=\"c-btn c-btn--action\" data-accept role=\"button\">"
    + this.escapeExpression(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_accept",{"name":"translate","hash":{},"data":data}))
    + "</a>\n</div>\n";
},"useData":true});