'use strict';
import _                                    from 'underscore';
import app                                  from 'components/core/application';
import BackboneParentTabbedView             from 'components/core/mixins/Backbone.ParentTabbedView';
import BackboneParentTabbedViewInstance     from 'components/core/mixins/Backbone.ParentTabbedViewInstance';
import Backbone                             from 'backbone';
import BackboneCloseView                    from 'components/core/mixins/Backbone.CloseView';
import CurrentView                          from 'modules/home/current';
import AntepostView                         from 'modules/home/antepost';
import CalendarView                         from 'modules/home/calendar';
import VirtualsView                         from 'modules/home/virtuals';
import FiltersView                          from 'modules/home/filters';
import HomeCollection                       from 'modules/home/home.collection';
import pushService                          from 'modules/home/homePushService';
import BannerView                           from 'components/banner/banner';
import template                             from 'modules/home/home.tpl.hbs';
import user                                 from 'components/user/user';
import helpers                              from 'components/helpers/helpers';
import SeoTextView                          from 'components/seo-text/seo-text';

/**
 * Home parent view Controller
 *
 * @name HomeParentView
 * @constructor
 *
 * @requires jQuery
 * @requires Underscore
 * @requires app
 * @requires BackboneParentTabbedView
 * @requires BackboneParentTabbedViewInstance
 * @requires BaseView;
 * @requires CurrentView;
 * @requires AntepostView;
 * @requires CalendarView;
 *
 * @requires template;
 *
 * @description
 *     Home View Controller.
 *     Inherits from parentView mixin
 *
 * @returns {Function} - Constructor function for HomeParentView
 */

var HomeParentView = Backbone.View.extend(
    _.extend({}, BackboneCloseView, BackboneParentTabbedView, {

        className: 'l-tabbedView',

        template: template,

        initialize: function () {
            app.trigger('home:initialize');
            this.initializeTabbedView();
            this.defaultActiveTab = 'current';
            this.subViews = {};
            this.DOM = {};
            this.loaded = false;

            //init filters
            this.subViews.filtersView = new FiltersView();
            this.subViews.bannerView = new BannerView();

            this.collection = new HomeCollection();
            this.listenTo(this.collection, 'sync', () => {
                this.loaded = true;
                this.render();
                this.renderFilters();
            });

            // this object is passed to the subviews
            this.options = {
                parentView: this
            };

            //Set subviews
            this.tabViews = {
                'current': CurrentView,
                'antepost': AntepostView,
                'calendar': CalendarView,
                'virtuals': VirtualsView
            };

            this.collection.fetch();
        },

        render: function () {
            //Insert html
            this.$el.html(this.template({hideVirtuals: !helpers.isVirtualAvailable()}));
            this.seoView = new SeoTextView();

            //Cache parent <li> elements for tabs links
            this.tabLinkParentList = this.$('[data-tab]');

            //Cache subViewTag tag
            this.subViewTag = this.$(app.SELECTORS.appSubView);

            //Cache filters tag
            this.filtersTag = this.$('[data-filter]');

            if (this.loaded) {
                //connect to push server if noy yet connected
                if(!pushService.socket) {
                    pushService.init({activeTab: this.activeTab, collection: this.collection});
                }
                this.$el.prepend(this.subViews.bannerView.render().el);
                this.subViews.bannerView.delegateEvents();
                this.manageSubView();
            }
            
            this.$el.append(this.seoView.render().el);

            return this;
        },

        renderFilters: function () {
            this.filtersTag.html(this.subViews.filtersView.render().el);
            //rebind events after the filter view was rerendered
            this.subViews.filtersView.delegateEvents();
        },

        onClose: function () {
            if (!user.isLoggedIn) {
                app.subHeader.resetTemplate();
            }
        }
    }), BackboneParentTabbedViewInstance
);

export default HomeParentView;
