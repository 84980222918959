'use strict';

import _                   from 'underscore';
import $                   from 'jquery';
import app                 from 'components/core/application';
import Backbone            from 'backbone';
import template            from 'components/betting/button_h2h.tpl.hbs';
import BettingService      from 'components/betting/betting.service';
import BackboneCloseView   from 'components/core/mixins/Backbone.CloseView';

const H2HButtonView = Backbone.View.extend(
    _.extend({}, BackboneCloseView, {

        events: {
            'click [data-bet]': 'fire'
        },

        template: template,

        initialize: function (opt={}) {
            this.options = _.extend({enabled: true}, opt);

            this.addListeners();
        },

        render: function () {
            this.$el.html(this.template(this.model.toJSON()));

            this.DOM = {
                button: this.$('button'),
                extra: this.$('[data-extra]')
            }

            this.onRender();
            return this;
        },

        onRender: function () {
            if (this.options.extraCssClass) {
                this.addExtraClass(this.options.extraCssClass)
            }
        },

        addListeners: function () {
            this.listenTo(this, 'increase', () => {
                this.DOM.button.addClass('isWorse');
                this.DOM.button.on('webkitanimation', () => {
                    this.DOM.button.removeClass('isWorse');
                })
            });

            this.listenTo(this, 'decrease', () => {
                this.DOM.button.addClass('isBetter');
                this.DOM.button.on('webkitanimation', () => {
                    this.DOM.button.removeClass('isBetter');
                })
            });

            this.listenTo(this, 'button:deselect', function() {
                this.DOM.button.removeClass('isSelected');
            });

            this.listenTo(this.model, 'change:isSelected', this.manageSelection);
        },

        animate: function(direction) {
            this.render().trigger(direction);
        },

        updateOptions: function(opt={}) {
            _.extend(this.options, opt);
            return this;
        },

        manageSelection: function() {
            this.DOM.button.toggleClass('isSelected', this.model.attributes.isSelected);
        },

        fire: function (e) {
            if (!this.options.enabled) return;

            const btn = $(e.target);
            const idRunner = parseInt(btn.attr('data-idRunner'), 10);

            if (app.betslip.hasBet(idRunner)) {
                app.betslip.removeBet(idRunner);
                return;
            }

            this.trigger('button:fire', {idRunner: idRunner, toBetSlip: !app.betslip.isEmpty()});

            if(idRunner.length < 1) return;

            // Show as selected immediately for any consecutive bets
            if (this.DOM.button.hasClass('isSelected')) {
                this.DOM.button.removeClass('isSelected');
            } else if (!app.betslip.isEmpty()) {
                this.DOM.button.addClass('isSelected');
            }

            // update model to match runnerModel of H2H events
            this.model.attributes.specialBetType = 'H2H';

            BettingService.showQuickbet({
                buttonView: this,
                attributes: {
                    idRunner: idRunner,
                    betType: btn.attr('data-betType'),
                    betCategory: 'FXD',
                    isSpecialBet: true,
                    isHeadToHead: true,
                },
                runnerModel: this.model
            });
        },

        addExtraClass: function (className) {
            this.DOM.extra.addClass(className)
        }
    })
);

export default H2HButtonView;
