import _ from 'underscore';
import Backbone from 'backbone';
import app from 'components/core/application';
import helpers from 'components/helpers/helpers';
import user from 'components/user/user';
import BackboneSingleton from 'components/core/mixins/Backbone.Singleton';
import RaceModel from 'components/model/race.model';
import specialEventModel from 'modules/event/special-event-item.model';
import webSocketManager from 'components/helpers/webSocketManager';

var EventRacesCollection = Backbone.Collection.extend({

    url: function () {
        return '/ajax/events/details/id/' + this.data.eventId
    },

    data: {
        eventId: null
    },

    model: RaceModel,

    initialize: function () {
        this.once('error', this.fallBack, this);
    },

    /**
     * @method fallBack
     * @description
     *  If ajaxHost is not available, use fallback host
     */
    fallBack: function (collection, response, options) {
        this.fetch({ajaxHostFailure: true});
    },

    parse: function (response) {
        this.data.event = response.event;
        this.data.media = response.race;
        this.data.timestamp = response.timestamp;

        if (this.data.event) {
            if (this.data.event.isSpecialBets) {
                this.model = specialEventModel;
            }

            if (this.data.event.isAntePost) {
                _.each(response.races, function (race) {
                    race.isAntePost = true;
                })
            }
        } else {
            app.router.navigate('404', {trigger: true});
            return;
        }

        if (response.races) {
            response.races.forEach((race) => {
                race.country = this.data.event.country;

                if(race.promotions) {
                    race.promotions = race.promotions.filter((item) => {
                        if (
                            _.contains(item.countries, user.data.country || user.data.ipCountry)
                            && (item.promotionType === "enhanced-place" || !user.data.noBonus)
                        ) {
                            if (item.promotionType !== 'custom') {
                                item.label = `label_promotion_${item.promotionType.replace('-','_')}`;
                            }
                            return true;
                        }
                        return false;
                    });

                }

                if (race.results && race.results[0]) {
                    let winner_id = race.results[0] ? parseInt(race.results[0], 10) : null;
                    _.each(race.runners, (runner) => {
                        if (winner_id) {
                            runner.isWinner = this.checkWinner(runner, winner_id);
                        }

                        if (runner.winOdds) {
                            runner.winOdds = helpers.formatOdds(runner.winOdds, user.getOddsFormat(), true);
                        }
                    });
                }

                //sort if 'runnersOrder' === 'ODD'
                if(race.runnersOrder === 'ODD') race.runners = _.sortBy(race.runners, 'winOdds');
            });
        }
        // returns a collection of races
        return response.races;
    },

    checkWinner: function (runner, winner_id) {
        return (parseInt(runner.idRunner, 10) === winner_id) ? true: false;
    },

    joinChannel: function () {
        this.socketChannel = 'node_eventCard_' + this.data.eventId;
        this.listenTo(webSocketManager, 'update:channel:' + this.socketChannel, this.onUpdate);
    },

    onUpdate: function (response) {
        let data = response.data;
        if (data.races) {
            _.each(data.races, (race) => {
                let raceToUpdate = this.get(race.idRace);
                if(!raceToUpdate) {
                    return;
                }
                raceToUpdate.attributes.raceStatus = race.raceStatus;
                raceToUpdate.attributes.postTime = race.postTime;
                raceToUpdate.attributes.postTimeLabel = raceToUpdate.getTime();

                let winner_id = race.results && race.results[0] ? parseInt(race.results[0], 10) : null;
                _.each(race.runners, (runner) => {
                    // Find and update current runner from attributes
                    _.each(raceToUpdate.attributes.runners, (attrRunner) => {
                        if (attrRunner.idRunner === runner.idRunner) {
                            if (runner.winOdds) {
                                attrRunner.winOdds = helpers.formatOdds(runner.winOdds, user.getOddsFormat(), true);
                            }

                            if (winner_id) {
                                attrRunner.isWinner = this.checkWinner(runner, winner_id);
                            }
                        }
                    });
                });

                if (race.favourites) {
                    raceToUpdate.attributes.favourites = race.favourites;
                    raceToUpdate.attributes.favourites = raceToUpdate.getFavourites();
                }
                if (race.results) {
                    raceToUpdate.attributes.results = race.results;
                }

                raceToUpdate.trigger('updated');
            });
        }
    }
}, BackboneSingleton);

export default EventRacesCollection;
