import _                        from 'underscore';
import user                     from 'components/user/user';
import app                      from 'components/core/application';
import cookies                  from 'components/helpers/docCookies';
import helpers                  from 'components/helpers/helpers';
import appSettings, {
    SETTINGS_GDPR_ENABLED,
    ROUTE_SNACK_BAR_EXCLUSIONS,
    XTREMEPUSH_OPT_IN_DELAY,
    RB_CONSENT
}                               from 'components/app.settings';
import { setCookie }            from 'modules/cookie-settings/marketing-cookie-settings.service';

export const beforeRouteChange = (params) => {
    //check for cookie consent
    const { current } = params;

    //all this needs to be checked if GDPR is enabled
    if(SETTINGS_GDPR_ENABLED) {
        //no need to show the snack bar for logged in customer as he has either given consent already or is being forced to do so on the consent page.
        if (user.isLoggedIn && !user.consentRequired()) {
            //if consent is not required (e.g. user gave consent already), check if 'consentCookie' exists and set 'CONSENT' cookie (true/false) to avoid showing the snack bar.
            //this is possible when user is using a new device - why annoy him ...?
            if(user.hasMarketingCookieConsent()) setCookie(user.getMarketingCookie());
        } else {
            //3. user is not logged in.
            let rbConsent = cookies.getItem(appSettings.consentCookie);
            //if 'CONSENT' cookie set or if the current url is in exclusions array - hide snack bar
            if(rbConsent || _.contains(ROUTE_SNACK_BAR_EXCLUSIONS, current)) {
                app.overlaySecondary.closeConsentSnackBar();
            } else {
                //'CONSENT' cookie is not available and the current url is not in exclusions array. Can be a new customer or a new device. Show the snack bar.
                RB_CONSENT && app.overlaySecondary.showConsentSnackBar();
            }
        }
    }

    //hide right menu on every navigation
    app.subHeader.rightMenu(false);
    app.loadingManager.hide();
}


export const afterRouteChange = (params) => {
    if (window.navigator.standalone == true) {
        try {
            if (app.firstroute) {
                app.firstroute = false;
            } else {
                let currentUrl = {
                    expireDate: (moment().unix() + 1800),
                    url: params.current
                };
                window.localStorage.setItem('currentUrl', JSON.stringify(currentUrl));
            }
        } catch (e) {
            console.log('Cannot save current url in local storage: ' + e);
        }
    }

    var consentCookie = cookies.getItem(appSettings.consentCookie);
    var parsedCookie = consentCookie ? JSON.parse(consentCookie) : {};
    var consentGiven = parsedCookie && parseInt(parsedCookie.marketing) === 1;

    // send user ID to XtremePush
    if(user.isLoggedIn && app.brand === 'racebets' && consentGiven && helpers.getXtremePushEnabledState()) {
        // Wait 3 sec because on full page reload, the "window.xtremepush" object is undefined
        setTimeout(function() {
            if(window.xtremepush) {
                window.xtremepush('set', 'user_id', user.data.idAccount);
            }
        }, 3000)

        // Show XtremePush-opt in dialog
        // let xtremePushOptInDialogShown = cookies.hasItem('xtrpush3')
        // if(!xtremePushOptInDialogShown && helpers.showXtremePushOptIn()) {
        //     setTimeout(() => {
        //         app.trigger('dialog:info:xtremePushOptIn');
        //     }, XTREMEPUSH_OPT_IN_DELAY);
        // }
    }
};
