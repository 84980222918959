'use strict';

import webSocketManager     from 'components/helpers/webSocketManager';

export default {

    channel: 'node_sidebarEvents',

    channelConnected: false,

    /**
     * @method init
     *
     * @description
     *  Connect to h2h channel
     *
     */
    init: function () {
        //if there is already a connection to 'node_sidebarEvents' channel, there is no need to connect again
        if (this.channelConnected) {
            return;
        }

        webSocketManager
            .connect()
            .joinChannel({
                channel: this.channel
            });

        this.channelConnected = true;
    }
};
