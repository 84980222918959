var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "    <div class=\"m-betslip__section\">\n        <div class=\"table-row\">\n            <div class=\"table-cell\">\n                <div class=\"truncate-text\">\n                    <div class=\"m-betslip__runnerName\">\n                        "
    + alias2(alias1((depths[1] != null ? depths[1].name : depths[1]), depth0))
    + "<span class=\"m-betslip__market\">("
    + alias2(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,__default(require("../helpers/handlebar-helpers/concat.js")).call(depth0,"label_bet_type_",((stack1 = ((stack1 = (depths[1] != null ? depths[1].parts : depths[1])) != null ? stack1["0"] : stack1)) != null ? stack1.market : stack1),{"name":"concat","hash":{},"data":data}),{"name":"translate","hash":{},"data":data}))
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.showEachWay : depth0),{"name":"if","hash":{},"fn":this.program(2, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ")</span>\n                    </div>\n                </div>\n"
    + ((stack1 = helpers["if"].call(depth0,(depths[1] != null ? depths[1].h2hOpponentsNames : depths[1]),{"name":"if","hash":{},"fn":this.program(5, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                <div class=\"m-betslip__secondaryInfo\">"
    + alias2(alias1((depths[1] != null ? depths[1].postTime : depths[1]), depth0))
    + " "
    + alias2(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_at",{"name":"translate","hash":{},"data":data}))
    + " "
    + alias2(alias1((depths[1] != null ? depths[1].title : depths[1]), depth0))
    + "</div>\n            </div>\n            <div class=\"table-cell m-betslip__trash pl1 pb1\">\n                <span class=\"rb-icon rb-icon--trash\"></span>\n                <div class=\"m-betslip__iconBackground m-betslip__iconBackground--betItem\" data-delete></div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"m-betslip__section\">\n        <div class=\"c-grid c-grid--noGutter m-betslip__inputWrapper\">\n"
    + ((stack1 = helpers.unless.call(depth0,((stack1 = (depths[1] != null ? depths[1].odds_select : depths[1])) != null ? stack1.show_options : stack1),{"name":"unless","hash":{},"fn":this.program(7, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depths[1] != null ? depths[1].odds_select : depths[1])) != null ? stack1.show_options : stack1),{"name":"if","hash":{},"fn":this.program(15, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                <div class=\"c-grid__col is-1-2 right-el\">\n                    <label for=\"unit\" class=\"m-betslip__inputLabel\">"
    + alias2(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_amount",{"name":"translate","hash":{},"data":data}))
    + "</label>\n                    <input id=\"unit\" type=\"text\" value=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.parts : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.stake : stack1)) != null ? stack1.unit_f : stack1), depth0))
    + "\" class=\"m-betslip__input\" data-id=\"unit\" "
    + ((stack1 = helpers["if"].call(depth0,(depths[1] != null ? depths[1].disableUnitStakeInput : depths[1]),{"name":"if","hash":{},"fn":this.program(17, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " data-unit>\n                </div>\n            </div>\n            <div data-validation_unit class=\"c-form__validationMessage\"></div>\n        </div>\n\n"
    + ((stack1 = helpers.unless.call(depth0,((stack1 = (depths[1] != null ? depths[1].status : depths[1])) != null ? stack1.remove : stack1),{"name":"unless","hash":{},"fn":this.program(19, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n        <div class=\"m-betslip__actionSection\">\n            <div class=\"m-betslip__actionMessage text-center\">"
    + ((stack1 = alias1(((stack1 = (depths[1] != null ? depths[1].status : depths[1])) != null ? stack1.message : stack1), depth0)) != null ? stack1 : "")
    + "</div>\n"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depths[1] != null ? depths[1].status : depths[1])) != null ? stack1.accept : stack1),{"name":"if","hash":{},"fn":this.program(27, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depths[1] != null ? depths[1].status : depths[1])) != null ? stack1.remove : stack1),{"name":"if","hash":{},"fn":this.program(29, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n    </div>\n";
},"2":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.isEachWay : depth0),{"name":"if","hash":{},"fn":this.program(3, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"3":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "/"
    + this.escapeExpression(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,__default(require("../helpers/handlebar-helpers/concat.js")).call(depth0,"label_bet_type_",((stack1 = ((stack1 = (depths[1] != null ? depths[1].parts : depths[1])) != null ? stack1["1"] : stack1)) != null ? stack1.market : stack1),{"name":"concat","hash":{},"data":data}),{"name":"translate","hash":{},"data":data}));
},"5":function(depth0,helpers,partials,data,blockParams,depths) {
    var alias1=this.escapeExpression;

  return "                    <div class=\"m-betslip__secondaryInfo\">"
    + alias1(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_head_to_head_vs",{"name":"translate","hash":{},"data":data}))
    + " "
    + alias1(this.lambda((depths[1] != null ? depths[1].h2hOpponentsNames : depths[1]), depth0))
    + "</div>\n";
},"7":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0,((stack1 = ((stack1 = (depths[1] != null ? depths[1].parts : depths[1])) != null ? stack1["0"] : stack1)) != null ? stack1.odds : stack1),{"name":"unless","hash":{},"fn":this.program(8, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = ((stack1 = (depths[1] != null ? depths[1].parts : depths[1])) != null ? stack1["0"] : stack1)) != null ? stack1.odds : stack1),{"name":"if","hash":{},"fn":this.program(11, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(depth0,(depths[1] != null ? depths[1].selectedOdds : depths[1]),{"name":"if","hash":{},"fn":this.program(13, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"8":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0,(depths[2] != null ? depths[2].selectedOdds : depths[2]),{"name":"unless","hash":{},"fn":this.program(9, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"9":function(depth0,helpers,partials,data) {
    var alias1=this.escapeExpression;

  return "                            <div class=\"c-grid__col is-1-2 left-el\">\n                                <label for=\"odds\" class=\"m-betslip__inputLabel\">"
    + alias1(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_odds",{"name":"translate","hash":{},"data":data}))
    + "</label>\n                                <input id=\"odds\" type=\"text\" class=\"m-betslip__input m-betslip__input--NA\" value=\""
    + alias1(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_sp",{"name":"translate","hash":{},"data":data}))
    + "\" disabled>\n                            </div>\n";
},"11":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.escapeExpression;

  return "                        <div class=\"c-grid__col is-1-2 left-el\">\n                            <label for=\"odds\" class=\"m-betslip__inputLabel\">"
    + alias1(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_odds",{"name":"translate","hash":{},"data":data}))
    + "</label>\n                            <input id=\"odds\" type=\"text\" class=\"m-betslip__input m-betslip__input--NA\" value=\""
    + alias1(this.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.parts : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.odds_f : stack1), depth0))
    + "\" disabled>\n                        </div>\n";
},"13":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=this.escapeExpression;

  return "                        <div class=\"c-grid__col is-1-2 left-el\">\n                            <label for=\"odds\" class=\"m-betslip__inputLabel\">"
    + alias1(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_odds",{"name":"translate","hash":{},"data":data}))
    + "</label>\n                            <input id=\"odds\" type=\"text\" class=\"m-betslip__input m-betslip__input--NA\" value=\""
    + alias1(this.lambda(((stack1 = (depths[1] != null ? depths[1].selectedOdds : depths[1])) != null ? stack1.odds_f : stack1), depth0))
    + "\" disabled>\n                        </div>\n";
},"15":function(depth0,helpers,partials,data) {
    return "                    <div class=\"c-grid__col is-1-2 left-el\">\n                        <label for=\"odds\" class=\"m-betslip__inputLabel\">"
    + this.escapeExpression(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_odds",{"name":"translate","hash":{},"data":data}))
    + "</label>\n                        <div data-odds-select></div>\n                    </div>\n";
},"17":function(depth0,helpers,partials,data) {
    return "disabled";
},"19":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "            <div class=\"m-betslip__section\">\n                <div class=\"table-row\">\n                    <div class=\"table-cell\">\n                        <input type=\"checkbox\" data-checkbox-eachWay id=\"each_way_"
    + alias2(alias1(((stack1 = ((stack1 = (depths[2] != null ? depths[2].parts : depths[2])) != null ? stack1["0"] : stack1)) != null ? stack1.market : stack1), depth0))
    + "_"
    + alias2(alias1((depths[1] != null ? depths[1].idRunner : depths[1]), depth0))
    + "\" name=\"each_way_"
    + alias2(alias1(((stack1 = ((stack1 = (depths[2] != null ? depths[2].parts : depths[2])) != null ? stack1["0"] : stack1)) != null ? stack1.market : stack1), depth0))
    + "_"
    + alias2(alias1((depths[1] != null ? depths[1].idRunner : depths[1]), depth0))
    + "\" data-idRunner=\""
    + alias2(alias1((depths[1] != null ? depths[1].idRunner : depths[1]), depth0))
    + "\" class=\"c-checkbox\" "
    + ((stack1 = helpers["if"].call(depth0,(depths[1] != null ? depths[1].isEachWay : depths[1]),{"name":"if","hash":{},"fn":this.program(20, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers.unless.call(depth0,(depths[1] != null ? depths[1].showEachWay : depths[1]),{"name":"unless","hash":{},"fn":this.program(17, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">\n                        <label for=\"each_way_"
    + alias2(alias1(((stack1 = ((stack1 = (depths[2] != null ? depths[2].parts : depths[2])) != null ? stack1["0"] : stack1)) != null ? stack1.market : stack1), depth0))
    + "_"
    + alias2(alias1((depths[1] != null ? depths[1].idRunner : depths[1]), depth0))
    + "\" class=\"c-checkbox__label c-checkbox__label--race\">"
    + alias2(alias1((depths[2] != null ? depths[2].eachWayCountryBasedLabel : depths[2]), depth0))
    + "</label>\n                    </div>\n                    <div class=\"table-cell\">\n"
    + ((stack1 = __default(require("../helpers/handlebar-helpers/compare.js")).call(depth0,(depths[1] != null ? depths[1].category : depths[1]),"===","FXD",{"name":"compare","hash":{},"fn":this.program(22, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n"
    + ((stack1 = __default(require("../helpers/handlebar-helpers/ifIn.js")).call(depth0,(depths[2] != null ? depths[2].category : depths[2]),(depths[2] != null ? depths[2].allowedMultiples : depths[2]),{"name":"ifIn","hash":{},"fn":this.program(25, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            </div>\n";
},"20":function(depth0,helpers,partials,data) {
    return "checked";
},"22":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "                            <div class=\"m-betslip__secondaryInfo m-betslip__secondaryInfo--adjustedToCheckBox text-right\" data-winnings>\n"
    + ((stack1 = helpers["if"].call(depth0,(depths[1] != null ? depths[1].winnings : depths[1]),{"name":"if","hash":{},"fn":this.program(23, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                            </div>\n";
},"23":function(depth0,helpers,partials,data,blockParams,depths) {
    return "                                    "
    + this.escapeExpression(__default(require("../helpers/handlebar-helpers/translateInterpolate.js")).call(depth0,"potentialWinnings",{"name":"translateInterpolate","hash":{"amount":(depths[1] != null ? depths[1].winnings : depths[1])},"data":data}))
    + "\n";
},"25":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "                <div class=\"table-row mt1\">\n                    <div class=\"table-cell\">\n                        <input type=\"checkbox\" data-checkbox-multiples id=\"multiples_"
    + alias2(alias1(((stack1 = ((stack1 = (depths[2] != null ? depths[2].parts : depths[2])) != null ? stack1["0"] : stack1)) != null ? stack1.market : stack1), depth0))
    + "_"
    + alias2(alias1((depths[2] != null ? depths[2].idRunner : depths[2]), depth0))
    + "\" name=\"multiples_"
    + alias2(alias1(((stack1 = ((stack1 = (depths[2] != null ? depths[2].parts : depths[2])) != null ? stack1["0"] : stack1)) != null ? stack1.market : stack1), depth0))
    + "_"
    + alias2(alias1((depths[2] != null ? depths[2].idRunner : depths[2]), depth0))
    + "\" data-idRunner=\""
    + alias2(alias1((depths[2] != null ? depths[2].idRunner : depths[2]), depth0))
    + "\" class=\"c-checkbox\" "
    + ((stack1 = helpers["if"].call(depth0,(depths[2] != null ? depths[2].inMultiples : depths[2]),{"name":"if","hash":{},"fn":this.program(20, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers.unless.call(depth0,(depths[2] != null ? depths[2].activeMultiplesChBx : depths[2]),{"name":"unless","hash":{},"fn":this.program(17, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">\n                        <label for=\"multiples_"
    + alias2(alias1(((stack1 = ((stack1 = (depths[2] != null ? depths[2].parts : depths[2])) != null ? stack1["0"] : stack1)) != null ? stack1.market : stack1), depth0))
    + "_"
    + alias2(alias1((depths[2] != null ? depths[2].idRunner : depths[2]), depth0))
    + "\" class=\"c-checkbox__label c-checkbox__label--race\">"
    + alias2(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_include_in_multiples",{"name":"translate","hash":{},"data":data}))
    + "</label>\n                    </div>\n                </div>\n";
},"27":function(depth0,helpers,partials,data) {
    return "                <input data-accept type=\"button\" class=\"c-btn c-btn--betslipAction mt1\" value=\""
    + this.escapeExpression(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_accept",{"name":"translate","hash":{},"data":data}))
    + "\">\n";
},"29":function(depth0,helpers,partials,data) {
    return "                <input data-delete type=\"button\" class=\"c-btn c-btn--betslipAction mt1\" value=\""
    + this.escapeExpression(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_remove_from_betslip",{"name":"translate","hash":{},"data":data}))
    + "\">\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.parts : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"useData":true,"useDepths":true});