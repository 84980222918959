import _                     from 'underscore';
import Backbone              from 'backbone';
import maybe                 from 'components/helpers/maybe';
import template              from 'modules/race/race-title.tpl.hbs';
import raceHelpers           from 'modules/race/race.helpers';
import BackboneCloseView     from 'components/core/mixins/Backbone.CloseView';

/**
 * Title view
 *
 * @name TitleView
 * @constructor
 *
 * @requires Underscore
 * @requires jQuery
 * @requires Backbone
 * @requires app
 * @requires maybe
 * @requires user
 * @requires helpers
 * @requires template
 * @requires raceHelpers
 * @requires BackboneCloseView
 *
 * @description
 * 		Title view
 *
 * @returns {Function} - Constructor function for TitleView
 */

const TitleView = Backbone.View.extend(
    _.extend({}, BackboneCloseView, {

        className: 'c-raceTitle',

        template: template,

        events: {
            'click [data-toggle-subHeader-extra]': 'toggleSubHeader'
        },

        render: function () {
            this.$el.html(this.template(_.extend({}, this.getTitleData(), this.composeExtraInfo())));

            this.DOM = {
                title_subHeader_extra: this.$('[data-subHeader-extra]')
            };

            return this;
        },

        update: function(data) {
            this.data = {
                event: maybe.of(data).mapDotProp('event').join(),
                race: maybe.of(data).mapProp('race').join()
            };

            return this;
        },

        composeInfo: function(race, event) {
            const raceTypeDetail = raceHelpers.getRaceTypeDetails(race);
            const category = raceHelpers.getCategory(race);
            const raceClass = raceHelpers.getClass(this.data.race.categoryLetter, this.data.event.country);
            const categoryLabel = category !== null ? `${category} ${raceClass}` : null;
            const distance =  raceHelpers.getDistance(race);
            const purse = raceHelpers.getPurse(race);
            const placeTerms = raceHelpers.getPlaceTerms(race, event);
            const rule4 = raceHelpers.getRule4(race, event);

            return [raceTypeDetail, categoryLabel, distance, purse, placeTerms, rule4].filter(raceHelpers.getDefined).join(' | ');
        },

        getTitleData: function() {
            return {title: raceHelpers.getTitle(this.data.race), info: this.composeInfo(this.data.race, this.data.event)}
        },

        composeExtraInfo: function() {
            const ageCondition = raceHelpers.getAgeCondition(this.data.race);
            const purseDetails = raceHelpers.getPurseDetails(this.data.race)
            const trackConditions = raceHelpers.getTrackConditionsFormatted(this.data.race, this.data.event.country);
            const trackSurface = raceHelpers.getTrackSurfaceFormatted(this.data.race);
            const placeTermsInfo = raceHelpers.getPlaceTermsInfo(this.data.event);
            const rule4Info = raceHelpers.getRule4Info(this.data.race, this.data.event);

            return {
                extra: {
                    top: [ageCondition].filter(raceHelpers.getDefined).join(' | '),
                    bottom: [trackSurface, trackConditions].filter(raceHelpers.getDefined).join(' | '),
                    purseDetails: purseDetails,
                    placeTermsInfo: placeTermsInfo,
                    rule4: rule4Info
                }
            };
        },

        toggleSubHeader: function (e) {
            e.preventDefault();

            if(!this.data.event.isVirtual) {
                this.DOM.title_subHeader_extra.slideToggle(200);
            }
        }
    })
);

export default TitleView;
