'use strict';

import Backbone  from 'backbone';

/**
 * H2HModel Model
 * @name BetModel
 * @constructor
 *
 * @requires Backbone
 *
 * @description
 *     H2HModel Model
 *
 * @returns Function - Constructor function for H2HModel
 */
var RaceH2HModel = Backbone.Model.extend({

    /**
     * Map from 'idRace' to 'id'
     * @type {String}
     */
    idAttribute: 'idRunner',

    defaults: {
        enabled: true
    }
});

export default RaceH2HModel;
