'use strict';
import $                            from 'jquery';
import _                            from 'underscore';
import app                          from 'components/core/application';
import Backbone                     from 'backbone';
import BackboneCloseView            from 'components/core/mixins/Backbone.CloseView';
import template                     from 'modules/home/event-list-item.tpl.hbs';

var EventItem = Backbone.View.extend(
    _.extend({}, BackboneCloseView, {
        template: template,

        events: {
            'click [data-id]': 'goToEvent',
            'click [data-h2h]': 'goToH2H',
            'click [data-special]': 'goToSpecial',
            'click [data-next]': 'goToNextRace'
        },

        initialize: function (opt) {
            this.DOM = {};
            this.isVirtual = opt.isVirtual;
            this.data = opt.event;
            this.data.calendarTime = opt.timeFormat === 'calendar';
            this.parentView = opt.parentView;
            this.model = this.parentView.parentView.collection.get(this.data.eventId);
            this.listenTo(this.model, 'updated', this.render);
            this.listenTo(this.model, 'change', this.render);

            if (this.model.attributes.countDown) {
                this.listenTo(app, 'current:timer', function() {
                    this.model.updateEventTime();
                });
            }
        },

        render: function () {
            this.$el.html(this.template({model: this.model.toJSON(), data: this.data}));
            return this;
        },

        goToEvent: function (e) {
            if(this.isVirtual) {
                this.goToNextRace(e);
                return;
            }
            app.trigger('navigate', '/event/details/id/' + this.data.eventId);
        },

        goToH2H: function (e) {
            e.stopPropagation();
            app.trigger('navigate', '/event/details/id/' + this.data.id_event_h2h);
        },

        goToSpecial: function (e) {
            e.stopPropagation();
            app.trigger('navigate', '/event/details/id/' + this.data.id_event_specials);
        },

        goToNextRace: function (e) {
            e.stopPropagation();
            app.trigger('navigate', '/race/details/id/' + this.data.id_next_race);
        }
    })
);

export default EventItem;
