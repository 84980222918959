'use strict';

import _                       from 'underscore';
import Backbone                from 'backbone';
import template                from 'components/betting/update_messages.tpl.hbs';
import BackboneCloseView       from 'components/core/mixins/Backbone.CloseView';

/**
 * Update message view Controller
 * @name UpdateMessage
 * @constructor
 *
 * @requires Underscore
 * @requires jQuery
 * @requires Backbone
 * @requires app
 * @requires template
 * @requires BackboneCloseView
 *
 * @description
 *     UpdateMessage View Controller
 *
 * @returns Function - Constructor function for UpdateMessage view
 */
var UpdateMessage = Backbone.View.extend(
    _.extend({}, {

        template: template,

        render: function (data) {
            this.$el.html(this.template({data: data}));
            return this;
        }

    }, BackboneCloseView));

export default UpdateMessage;
