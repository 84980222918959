import _                    from 'underscore';
import $                    from 'jquery';
import app                  from 'components/core/application';

import Backbone             from 'backbone';
import BackboneCloseView    from 'components/core/mixins/Backbone.CloseView';
import BackboneSubView      from 'components/core/mixins/Backbone.SubView';
import BannerCollection     from 'components/banner/banner.collection';
import template             from 'components/banner/banner.tpl.hbs';

const Banner = Backbone.View.extend(
    _.extend({}, BackboneSubView, BackboneCloseView, {

        template: template,

        events: {
            'click [data-link]': 'goToPage',
            'click [data-term]': 'goToPage'
        },

        initialize: function (opt) {
            this.DOM = {};
            this.options = {};
            this.data = {};

            _.extend(this.options, opt || {});

            this.collection = new BannerCollection([], { idRace: this.options.idRace });
            this.listenTo(this.collection, 'sync', this.render);
            this.collection.fetch();

        },

        render: function () {
            let banners = this.collection.toJSON();

            if (banners.length) {
                this.data = banners[0];

                this.data.breakpoints = {
                    big: (this.data.width / 3 * 2),
                    medium: (this.data.width / 3)
                };

                this.data.displayTermsLink = !!this.data.terms_url;

                this.$el.html(this.template(this.data));
            }

            return this;
        },

        isExternalUrl: function (url) {
            return /sta\/(landing|promotion)/.test(url) || url.indexOf('http') === 0;
        },

        goToPage: function (e) {

            e.preventDefault();
            e.stopPropagation();
            const url = $(e.currentTarget).attr('href');
            // always open t&c link in new window
            const target =  e.currentTarget.hasAttribute('data-term') ? '_blank' : this.data.target;
            const isExternal = this.isExternalUrl(url);

            if (url) {
                if (isExternal) {
                    window.open(url, target);
                } else {
                    app.router.navigate(url, { trigger: true });
                }
            }
        }

    })
);

export default Banner;

