import app  from 'components/core/application';

/**
 * Register handlebar translationInterpolate method
 *
 * Use it when string interpolation is expected
 * data: {hello_name: 'Hello {{name}}.'}
 * syntax in Handlebars template: {{ translateInterpolate 'hello_name' name=country}}
 * 'name=country' is a key-value pair
 *
 * syntax in Handlebars template: {{ translateInterpolate 'label_current' }}
 *
 */

export default function (key, data) {
    //this is how we collect key-value pairs and create and object of them to pass to the t() as a second optional param
    var values = {};
    for (var prop in data.hash) {
        if (data.hash.hasOwnProperty(prop)) {
            values[prop] = data.hash[prop];
        }
    }

    return app.interpolate(key, values);
};
