'use strict';

import _                     from 'underscore';
import Backbone              from 'backbone';
import betItemMethods        from 'components/betting/bet-item.methods';

/**
 * BetItem item view Controller
 * @name BetItem
 * @constructor
 *
 * @requires Underscore
 * @requires Backbone
 * @requires betItemMethods
 *
 * @description
 *     BetItem View Controller
 *     for quick betting and adding bets to BetItem
 *
 * @returns Function - Constructor function for BetItem view
 */
var BetItem = Backbone.View.extend(
    _.extend({}, betItemMethods, { }));

export default BetItem;
