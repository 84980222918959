'use strict';

import _                           from 'underscore';
import app                         from 'components/core/application';
import maybe                       from 'components/helpers/maybe';
import helpers                     from 'components/helpers/helpers';
import Backbone                    from 'backbone';
import template                    from 'modules/race/pick-view-manager.tpl.hbs';
import betOptions                  from 'modules/race/bet-options';
import SortableView                from 'components/sortable-view/sortable-view';
import RaceItemViewMultiple        from 'modules/race/race-item-multiple';
import RaceTitleView               from 'modules/race/race-title';
import PickRacesCollection         from 'modules/race/pick-races.collection';
import BackboneCloseView           from 'components/core/mixins/Backbone.CloseView';

/**
 *
 * @name PickViewsManager
 * @constructor
 *
 * @requires Underscore
 * @requires jQuery
 * @requires Backbone
 * @requires app
 * @requires maybe
 * @requires helpers
 * @requires template
 * @requires BackboneCloseView
 *
 * @description
 * 		Pick view manager view
 *
 * @returns {Function} - Constructor function for PickViewsManager View
 */

const PickViewsManager = Backbone.View.extend(
    _.extend({}, BackboneCloseView, {

        template: template,

        initialize: function(opts={}) {
            this.options = opts;
            const colTitle = helpers.isPickPlace(this.options.bet_type) ? 'label_bet_type_PLC' : 'label_betslip_col1';
            this.pickView = {
                view: RaceItemViewMultiple,
                th: {
                    number: {
                        title: '#',
                        width: '13%',
                        sortable: true,
                        active: true,
                        sort_attribute: 'programNumber',
                        minimal: true
                    },
                    data: {
                        title: app.polyglot.t('label_runner_p'),
                        width: '60%',
                        sortable: true,
                        sort_attribute: 'name',
                        alignment: 'left',
                        minimal: true
                    },
                    odds: {
                        title: app.polyglot.t('label_win'),
                        width: '10%',
                        sortable: true,
                        sort_attribute: 'odds_for_sorting',
                        alignment: 'center'
                    },
                    CHK1: {title: app.polyglot.t(colTitle), width: '20%', alignment: 'center'}
                },
                columns: betOptions.columns.PICK
            };

            this.collection = new PickRacesCollection({}, {
                race_id: opts.race_id,
                bet_type: opts.bet_type,
                bet_category: opts.bet_category
            });
            this.listenTo(this.collection, 'sync', this.onSuccess);
            this.listenTo(this.collection, 'error', this.onError);
            //this.collection.fetch();
        },

        render: function () {
            this.$el.html(this.template());

            if(!maybe.of(this).mapDotProp('DOM.data_sortables').join()) this.DOM = {data_sortables: this.$('[data-sortables]')};
            return this;
        },

        update: function(bet_type) {
            this.options.bet_type = bet_type;
            setTimeout(() => {
                this.collection.setUrl(this.options).fetch();
            }, 0);

            return this;
        },

        onSuccess: function(collection, response) {
            this.renderSortable(collection);
            this.trigger('pick:collection:sync', { currency: response.currency, minUnitStake: response.minUnitStake, maxUnitStake: response.maxUnitStake});
        },

        onError: function(collection, response) {
            console.log('error: ', response);
        },

        composeList: function (collection) {
            //clean up all child views because later on the childViews array is reset
            this.closeSortables();
            var listFragment = document.createDocumentFragment();
            this.sortables = [];

            if(collection.length === 0) return listFragment;

            //collect race_ids to be used for calculating pick bet count
            var race_ids = [];
            _.each(collection.data.races, (collection) => {
                race_ids.push({idRace: collection.data.race.idRace, time: maybe.of(collection).mapDotProp('data.race.postTime').join() || maybe.of(collection).mapDotProp('data.race.raceTime').join(), runners: [], count: 0});
            });

            _.each(collection.data.races, (collection, index) => {

                if(index > 0) {
                    var title = new RaceTitleView();
                    listFragment.appendChild(title.update({event: collection.data.event, race: collection.data.race}).render().el);
                    this.sortables.push(title);
                }

                collection.data.race_ids = race_ids;

                var options = {
                    th: this.pickView.th,
                    view: this.pickView.view,
                    columns: this.pickView.columns,
                    models: collection.models,
                    betType: this.options.bet_type,
                    data: collection.data
                };

                var sortable = new SortableView(options);
                listFragment.appendChild(sortable.render().el);

                this.sortables.push(sortable);
            });

            return listFragment;
        },

        renderSortable: function (collection) {
            this.$('[data-sortables]').append(this.composeList(collection));
        },

        closeSortables: function() {
            _.each(this.sortables, function(sortable) {
                sortable.close();
            })
        },

        onClose: function() {
            this.closeSortables();
        }
    })
);

export default PickViewsManager;
