'use strict';
import Backbone                 from 'backbone';
import $                        from 'jquery';
import _                        from 'underscore';
import { ajaxSetup }            from 'components/core/AjaxSetup';
import { 
    ENV_BRAND,
    ENV_RB 
}                               from 'components/app.settings';

/**
 * Application object
 * @module Application
 *
 * @type {Object}
 *
 * @requires Backbone
 * @requires jQuery
 * @requires Underscore
 *
 * @description
 *      Also serves as a mediator - Event(Message) Bus
 *      to allow all components communicate via messaging pattern.
 *      Warning!!! Callback functions are executed one after another
 *      without waiting for promises to be resolved!
 *
 * @params {Object} options
 *
 * @returns {Function} - Object
 */

Backbone.$ = $;

var application = {
    /**
     * @property {Object} options - default options.
     *
     * @description
     *      To override the default options or set additional options,
     *      pass an object to app.start({language: 'en', anotherOptions: 'some option'})
     */
    options: {
        language: 'de'
    },

    /**
     * @property {Object} DOM
     *
     * @description
     *      Store cached global DOM elements
     */
    DOM: {
        appHtml: $('#app-html'),
        appCanvas: $('#app-canvas'),
        appViews: $('#app-view'),
        appScroll: $('#app-scroll'),
        appMain: $('#app-main'),
        appStream: $('#app-stream'),
        appMainMenu: $('#app-viewport-mainMenu'),
        appSideMenu: $('#app-viewport-sideMenu'),
        appHeader: $('#app-header'),
        appFooter: $('#app-footer'),
        appTopHeader: $('#app-top-header'),
        appSubHeader: $('#app-sub-header'),
        snackBarAttachedAfter: $('#overlay-backdrop')
    },

    /**
     * @property {Object} SELECTORS
     *
     * @description
     *      List of preset selectors, which do not exist on app initialization.
     *      Cached in parent view on demand.
     */
    SELECTORS: {
        appSubView: '#app-subview'
    },

    /**
     * @method start
     *
     * @description
     *     Initiate app
     *
     * @param {Object} opts
     */
    start: function (opts) {
        _.extend(this.options, opts);
        ajaxSetup();
        this.trigger('initialize', {});
    },
    
    /**
     * @method brandChild
     *
     * @description
     *      Returns child brand or if application is serve on local brand name
     *      
     */
    brandChild: (function() {
        if(ENV_RB === 'local-develop') {
            return ENV_BRAND;
        }
        
        return $('html').data('brand-child')
    })()
};

/**
 * Inherit backbone events so that the application can serve as a mediator - Event(Message) Bus
 */
_.extend(application, Backbone.Events);

export default application;
