'use strict';

import _          from 'underscore';
import Backbone   from 'backbone';

/**
 * Settings model (general)
 * @module Settings
 *
 * @description
 *      Save general settings to db. For guest or logged in user.
 *
 * @returns {Function} - Constructor function settings model
 */
export default Backbone.Model.extend({

    url: '/ajax/account/updatesettings/',

    initialize: function (attr, opt) {
        this.reload = true;
        if (opt && !_.isUndefined(opt.reload)) {
            this.reload = opt.reload;
        }
    },

    sync: function (method, model, options) {
        options.attrs = model.attributes;

        if (method === 'create') {
            options.emulateJSON = true;
            options.data = {settings: JSON.stringify(options.attrs)};
        }

        //disable validation on after sync
        options.validate = false;

        options.success = () => {
            if (this.reload) {
                location.reload();
            }
        };

        return Backbone.sync.call(this, method, model, options);
    }
});
