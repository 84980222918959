import _                    from 'underscore';
import app                  from 'components/core/application';
import crossFrame           from 'lib/browser/cross_frame';
import auth                 from 'components/auth/auth';
import onBodyResize         from 'utils/body_resize';


const PostMessages = {
    iframe: null,
    init: function () {

        const filterList = [app.domain, '.wildfire.com'];

        const skipRoutes = ['horse-racing','greyhounds', 'next-races', 'meetings', 'antepost', 'specials'];

        crossFrame.init(filterList);

        crossFrame.addMessageListener('navigationHash', function (data) {
            let lastVisitedRoute = _.last(app.router.visitedRoutes);
            if (('/' + lastVisitedRoute !== data.payload) && (lastVisitedRoute.slice(0, lastVisitedRoute.indexOf('?')) === data.payload !== true)) {
                app.router.navigate(data.payload, {trigger: true});
            }
        });

        crossFrame.addMessageListener('requestSportsbook', function (data) {
            app.router.navigateTo('/', {trigger: true});
        });

        crossFrame.addMessageListener('height', function (data) {
            if (PostMessages.iframe) {
                PostMessages.iframe.css({height: data.payload + 'px'});
            } else {
                PostMessages.disableScroll();
                app.iframeHeight = parseInt(data.payload);
                app.trigger('app:height:change', app.iframeHeight);
            }
        });

        crossFrame.addMessageListener('scroll', function (data) {
            app.iframeScrollPosition = parseInt(data.payload);
            app.trigger('app:scroll', app.iframeScrollPosition);
        });

        crossFrame.addMessageListener('loginsuccess', function (data) {
            if ((/^http/).test(data.payload)) {
                window.location.href = data.payload;
            }
        });

        crossFrame.addMessageListener('logoutsuccess', function (data) {
            if ((/^http/).test(data.payload)) {
                window.location.href = data.payload;
            }
        });

        crossFrame.addMessageListener('requestLogin', function (data) {
            auth.manageLogin()
        });

        crossFrame.addMessageListener('navigation', function (data) {
            app.DOM.appScroll.scrollTop(0);
        });

        crossFrame.addMessageListener('requestRegistration', function (data) {
            app.router.navigate('/register', {trigger: true});
        });

        app.on('user:login:required', function () {
            crossFrame.send('requestLogin');
        });

        app.router.on('after:route:change', function(route) {
            // TODO: remove after bonus routes are implemented in OBG
            if(route.current.match(/^bonus\//g)) return;

            if (skipRoutes.indexOf(route.current) > -1) return;
            crossFrame.send('navigationHash', '/' + route.current);
        });

        app.on('bet:placed:successful', function () {
            crossFrame.send('betPlacementSuccess');
        });

        app.on('dialog:showdeposit', function () {
            crossFrame.send('showDeposit');
        });

        app.on('app:bootstrapped', function () {
            crossFrame.send('ready');
        });

        app.on('app:pagenotfound', () => {
            crossFrame.send('pageNotFound');
        });

        app.on('app:loading:show', () => {
            crossFrame.send('overlayDisplayed', true);
        });

        app.on('app:loading:hide', () => {
            crossFrame.send('overlayDisplayed', false);
        });

        app.on('million:navigation', (path) => {
            crossFrame.send('navigation', path)
        });

        onBodyResize((height) => {
            crossFrame.send('height', height);
            app.innerHeight = height;
            app.trigger('body:resize');
        });
    },
    addIframe: function(iframe) {
        this.iframe = iframe;
        crossFrame.addIframe(iframe)
    },
    removeIframe: function() {
        this.iframe = null;
        crossFrame.removeIframe()
    },
    isScrollDisabled: false,
    disableScroll: () => {
        if (PostMessages.isScrollDisabled) return;
        app.DOM.appHtml.addClass('noScroll');
        PostMessages.isScrollDisabled = true;
    }

};

export default PostMessages;
