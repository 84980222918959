import _                            from 'underscore';
import Backbone                     from 'backbone';
import app                          from 'components/core/application';
import helpers                      from 'components/helpers/helpers';
import raceHelpers                  from 'modules/race/race.helpers';
import user                         from 'components/user/user';
import moment                       from 'moment-timezone';
import maybe                        from 'components/helpers/maybe';

var RaceRunnerModel = Backbone.Model.extend({

    idAttribute: 'idRunner',

    initialize: function () {
        this.setupData(maybe.of(this).mapDotProp('collection.options.bet_type').orElse(null).join());
    },

    setupData: function (betType) {
        // formats jockey weight
        if (maybe.of(this.attributes).mapDotProp('jockey.weight').join()) {
            this.attributes.jockey.weight.weightFormatted = helpers.weight(this.attributes.jockey.weight.weight, user.getMetricSystem());
        }

        this.attributes.photos = this.getPhotosUrl();
        this.attributes.rating = this.attributes.rating !== "0" && this.attributes.rating !== "0.0" && !_.isEmpty(this.attributes.rating) ? this.attributes.rating : null;
        this.attributes.pinSticker = this.attributes.pinSticker !== "0" && !_.isEmpty(this.attributes.pinSticker) ? this.attributes.pinSticker : null;
        this.attributes.silkUrl = helpers.generateSilkUrl(this.attributes);
        this.attributes.postTimeLabel = this.getTime();
        this.formatJockeyData();
        this.formatTrainerData();

        if(maybe.of(this.collection).mapDotProp('data.event.raceType').join()) this.attributes.raceType = this.collection.data.event.raceType;

        if (this.collection) {
            let isScratched = !!+this.attributes.scratched;
            this.attributes.isActive = this.collection.data.race.raceStatus === 'OPN' && !isScratched;
            this.attributes.isAntePost = this.collection.data.event.isAntePost;
            this.attributes.isGreyhound = raceHelpers.isGreyhound(this.collection.data.race);
        }
    },

    setupBettingData: function (betType) {
        let jockeyStats = maybe.of(this.attributes).mapDotProp('jockey.stats').join();
        let trainerStats = maybe.of(this.attributes).mapDotProp('trainer.stats').join();
        let hasPastPerformance = maybe.of(this.attributes).mapProp('hasPastPerformance').join();
        this.attributes.tabHeader = (jockeyStats || trainerStats || hasPastPerformance) ? true : false;

        this.attributes.ageFormatted = this.getAge();
        this.attributes.purse = this.getPurse();

        if (maybe.of(this.attributes).mapDotProp('pp.purse').join() && this.attributes.pp.purse === 0) {
            this.attributes.purse = false;
        }

        // we could use another model if differences with antepost runner differs more than this
        if (this.collection.data.event && !this.collection.data.event.isAntePost) {
            this.attributes.jockey.firstNameAbbr = this.attributes.jockey.firstName ? this.attributes.jockey.firstName.charAt(0) + '.' : '';
            this.attributes.trainer.firstNameAbbr = this.attributes.trainer.firstName ? this.attributes.trainer.firstName.charAt(0) + '.' : '';
        }

        // checks which buttons we want to show
        this.attributes.betting = this.setupBettingOptions(betType);
        this.attributes.genderFormatted = this.getGender();
    },

    setButtonOdds: function (odds, category, betType) {
        var isScratched = !!+this.attributes.scratched;
        if (isScratched) return app.polyglot.t('label_btn_scratched');

        //for German customers, when there are no odds available, always show Bet/Wetten, for all other countries show SP for WIN and Bet for all other markets
        if(betType === 'WIN' && odds < 1 && !user.isGerman() && user.data.language.toUpperCase() !== 'DE') return app.polyglot.t('label_sp');

        // for fractional customers we convert the odds format on buttons to base1
        var oddsFormat = user.getOddsFormat();
        oddsFormat = (oddsFormat === 'fractional' && category === 'FXP') ? 'base1' : oddsFormat;

        if (_.contains(['PLC', 'SHW', 'WS', 'PS', 'WP', 'WPS', 'ITA', 'TRT'], betType)) {
            return app.polyglot.t('label_bet');
        } else if (odds > 0) {
            return helpers.formatOdds(odds, oddsFormat, true);
        } else {
            return app.polyglot.t('label_bet');
        }

        return odds;
    },

    getPurse: function () {
        return (this.attributes.pp) ? helpers.formatMoney(this.attributes.pp.purse, false, this.attributes.pp.purseCurrency, false) : null
    },

    setupBettingOptions: function (betType) {
        var betting = {
            betType: betType,
            buttons: []
        },
        collectionData = this.collection.data,
        raceEnabled = collectionData.race && collectionData.race.raceStatus === 'OPN',
        oddsFormat = user.getOddsFormat();

        if (maybe.of(collectionData).mapDotProp('betTypes.normal.BOK.WIN').join()) {
            let scratched_changed = this.scratchedChanged(this.attributes.scratched, 'BOK');
            let value = this.setButtonOdds(this.attributes.odds.PRC || 0, 'PRC', betting.betType);
            let value_change = this.getPreviousValue(value, 'BOK', scratched_changed);
            betting.oddsAsInfo = raceHelpers.showOddsAsInfo(betting.betType, this.attributes.odds.PRC, value) ? this.attributes.odds.PRC : null;

            //is used by comparator to perfom sorting.
            if(betting.oddsAsInfo) this.attributes.odds_for_sorting = betting.oddsAsInfo;
            if(raceHelpers.labelIsNumber(value)) this.attributes.odds_for_sorting_prc = value;

            let isScratched = !!+this.attributes.scratched;
            betting.buttons.push({
                category: 'BOK',
                betType: betting.betType,
                enabled: raceEnabled && !isScratched,
                scratched_changed: scratched_changed,
                value_change: value_change,
                class: value.toString().length > 3 ? value.toString().length > 5 ? 'x-small' : 'small' : '',
                value: value,
                show_in_raceCard: true
            });
        }

        if (maybe.of(collectionData).mapDotProp('betTypes.normal.FXD.WIN').join() && maybe.of(collectionData).mapDotProp('race.fixedOddsStatus').join() !== 'NAV') {
            let scratched_changed = this.scratchedChanged(this.attributes.scratched, 'FXW');
            let value = this.setButtonOdds(this.attributes.odds.FXW || 0, 'FXD', betting.betType);
            let value_change = this.getPreviousValue(value, 'FXW', scratched_changed);
            let odds_history = this.attributes.fixedOddsHistory ? [...this.attributes.fixedOddsHistory] : [];

            odds_history.splice(0, 1); //for fixed odds remove first item as it is the same as current value
            betting.oddsAsInfo = raceHelpers.showOddsAsInfo(betting.betType, this.attributes.odds.FXW, value) ? this.attributes.odds.FXW : null;

            //is used by comparator to perfom sorting.
            if(betting.oddsAsInfo) this.attributes.odds_for_sorting = betting.oddsAsInfo;
            if(raceHelpers.labelIsNumber(value)) this.attributes.odds_for_sorting_fxd = value;

            let isScratched = !!+this.attributes.scratched;
            betting.buttons.push({
                category: 'FXW',
                betType: betting.betType,
                enabled: (raceEnabled && collectionData.race.fixedOddsStatus === 'ON' && !isScratched),
                scratched_changed: scratched_changed,
                value_change: value_change,
                class: value.toString().length > 3 ? value.toString().length > 5 ? 'x-small' : 'small' : '',
                value: value,
                show_in_raceCard: true,
                odds_history: this.getOddsHistoryFormatted(odds_history, oddsFormat)
            });
        }
       
        //show TOT btn only if BOK btn does not exist or else set show_in_raceCard = 'false' (for German customers) and do not show as button in race card
        //TOT button is same as SP so we show only one button - SP or TOT (if SP is not available). But for German customers we show TOT button as option in the quick bet dialog
        if (maybe.of(collectionData).mapDotProp('betTypes.normal.TOT.WIN').join()) {
            let scratched_changed = this.scratchedChanged(this.attributes.scratched, 'TOT');
            let value = this.setButtonOdds(this.attributes.odds.PRC || 0, 'PRC', betting.betType);
            let value_change = this.getPreviousValue(value, 'TOT', scratched_changed);
            betting.oddsAsInfo =  raceHelpers.showOddsAsInfo(betting.betType, this.attributes.odds.PRC, value) ? this.attributes.odds.PRC : null;

            //is used by comparator to perfom sorting.
            if(betting.oddsAsInfo) this.attributes.odds_for_sorting = betting.oddsAsInfo;
            if(raceHelpers.labelIsNumber(value)) this.attributes.odds_for_sorting_prc = value;

            let isScratched = !!+this.attributes.scratched;
            betting.buttons.push({
                category: 'TOT',
                betType: betting.betType,
                enabled: raceEnabled && !isScratched,
                scratched_changed: scratched_changed,
                value_change: value_change,
                class: value.toString().length > 3 ? value.toString().length > 5 ? 'x-small' : 'small' : '',
                value: value,
                show_in_raceCard: !maybe.of(collectionData).mapDotProp('betTypes.normal.BOK.WIN').join() //show in racecard if BOK not available
            });
        }


        this.collection.hasOddsAsInfo = !!betting.oddsAsInfo;

        return betting;
    },

    getOddsHistoryFormatted: function (odds_arr=[], oddsFormat) {
        return odds_arr.filter((odds, i) => odds > 0 && i < 2).map((odds) => {return helpers.formatOdds(odds, oddsFormat, true)}).join(', ');
    },

    getPreviousValue: function(new_value, category, scratched_changed) {
        var buttons = maybe.of(this.attributes).mapDotProp('betting.buttons').orElse([]).join();
        var button = _.findWhere(buttons, {category: category});
        var prev_value = 0;
        var direction = null;

        if(button && button.value) {
            prev_value = button.value;
        } else if(_.isNumber(new_value)) {
            prev_value = new_value;
        }

        if(button && _.isNumber(prev_value) && _.isNumber(new_value)) {
            direction = new_value > prev_value ? 'increase' : 'decrease';
        }

        return {
            prev_value: prev_value,
            direction: direction
        }
    },

    scratchedChanged: function(new_value, category) {
        var buttons = maybe.of(this.attributes).mapDotProp('betting.buttons').orElse([]).join();
        var button = _.findWhere(buttons, {category: category});
        new_value = !!+new_value; //convert string to boolean
        return button ? button.scratched_changed !== new_value ? true : false : false;
    },

    getAge: function () {
        return this.attributes.age > 0 ? app.interpolate('label_year_old_abbr', {'age': this.attributes.age}) : false;
    },

    getGender: function () {
        let message = 'label_gender_short_';

        // exception for dog races
        if (maybe.of(this.collection).mapDotProp('data.event.raceType').join() && this.collection.data.event.raceType === 'D') {
            message = 'label_gender_short_greyhounds_';
        }

        return this.attributes.gender ? app.polyglot.t(message + this.attributes.gender) : false;
    },

    getPhotosUrl: function () {
        var photosUrl = {
            runner: null,
            jockey: null,
            trainer: null
        };

        if (!_.isEmpty(this.attributes.photo)) {
            photosUrl.runner = '//www.racebets.com/cache/img/photos/subjects/' + this.attributes.idSubject + '_' + this.attributes.photo + '_' + 'w150' + '.jpg';
        }

        if (maybe.of(this.attributes).mapDotProp('jockey.photo').join()) {
            photosUrl.jockey = '//www.racebets.com/cache/img/photos/persons/' + this.attributes.jockey.idPerson + '_' + this.attributes.jockey.photo + '_' + 'w75' + '.jpg';
        }

        if (maybe.of(this.attributes).mapDotProp('trainer.photo').join()) {
            photosUrl.trainer = '//www.racebets.com/cache/img/photos/persons/' + this.attributes.trainer.idPerson + '_' + this.attributes.trainer.photo + '_' + 'w75' + '.jpg';
        }

        return photosUrl;
    },

    formatJockeyData: function () {
        if (maybe.of(this.attributes).mapDotProp('jockey.stats').join()) {
            this.attributes.jockey.stats.label = app.interpolate('label_statistics_for_season_year', {year: moment().format('YYYY')});
            this.attributes.jockey.stats.country = this.collection.data.event.country;
            // calculate percentage winning and places
            if (this.attributes.jockey.stats.numWin && this.attributes.jockey.stats.numRaces) {
                this.attributes.jockey.stats.numWinPercentage = helpers.number(this.attributes.jockey.stats.numWin / this.attributes.jockey.stats.numRaces * 100, 0) + '%';
            }
            if (this.attributes.jockey.stats.numPlace && this.attributes.jockey.stats.numRaces) {
                this.attributes.jockey.stats.numPlacePercentage = helpers.number(this.attributes.jockey.stats.numPlace / this.attributes.jockey.stats.numRaces * 100, 0) + '%';
            }
            if (this.attributes.jockey.stats.earnings) {
                // always EUR
                this.attributes.jockey.stats.earnings = helpers.formatMoney(this.attributes.jockey.stats.earnings, 2, 'EUR', true);
            }

        }
    },

    formatTrainerData: function () {
        if (maybe.of(this.attributes).mapDotProp('trainer.stats').join()) {
            this.attributes.trainer.stats.label = app.interpolate('label_statistics_for_season_year', {year: moment().format('YYYY')});
            this.attributes.trainer.stats.country = this.collection.data.event.country;
            // calculate percentage winning and places
            if (this.attributes.trainer.stats.numWin && this.attributes.trainer.stats.numRaces) {
                this.attributes.trainer.stats.numWinPercentage = helpers.number(this.attributes.trainer.stats.numWin / this.attributes.trainer.stats.numRaces * 100, 0) + '%';
            }
            if (this.attributes.trainer.stats.numPlace && this.attributes.trainer.stats.numRaces) {
                this.attributes.trainer.stats.numPlacePercentage = helpers.number(this.attributes.trainer.stats.numPlace / this.attributes.trainer.stats.numRaces * 100, 0) + '%';
            }
            if (this.attributes.trainer.stats.earnings) {
                // always EUR
                this.attributes.trainer.stats.earnings = helpers.formatMoney(this.attributes.trainer.stats.earnings, 2, 'EUR', true);
            }

        }
    },

    getTime: function () {
        if (typeof this.collection === 'undefined') {
            return null;
        }

        let time = this.collection.data.race.postTime ? this.collection.data.race.postTime : this.collection.data.race.raceTime;

        if (this.collection.data.race.isAntePost) {
            var date = moment.unix(time).tz(user.timeZone()).format('L');
            // regexp extract month and day from dd/mm/yyyy or dd.mm.yyyy
            // if there are no matches falls back to full date
            date = date.match(/(\d{2}.\d{2})/) === null ? date : date.match(/(\d{2}.\d{2})/)[0];
            return date;
        }
        return moment.unix(time).tz(user.timeZone()).format('LT');
    }
});

export default RaceRunnerModel;
