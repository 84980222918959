'use strict';

import _                    from 'underscore';
import Backbone             from 'backbone';
import app                  from 'components/core/application';
import moment               from 'moment-timezone';
import user                 from 'components/user/user';
import helpers              from 'components/helpers/helpers';

var StreamsCollection = Backbone.Collection.extend({

    url: '/ajax/media/streams',

    initialize: function () {
        this.once('error', this.fallBack, this);
        this.data = {
            showEmpty: false
        };
    },

    /**
     * @method fallBack
     * @description
     *  If ajaxHost is not available, use fallback host
     */
    fallBack: function (collection, response, options) {
        this.fetch({ajaxHostFailure: true});
    },

    parse: function (response) {
        var allowed_streams = [];
        _.each(response.channels, function (stream) {
            // hide dogs-only channels if filtered out
            if (stream.raceTypes && stream.raceTypes.length === 1 && stream.raceTypes[0] === 'D'
                && (user.data.websiteTLD.toLowerCase() === 'de' || app.brand === 'suaposta')) {
                return;
            }

            // check if the stream is allowed based on user country
            if (!helpers.isCountryAllowed(user.data.mediaCountry || user.data.ipCountry, stream.filterCountries, stream.filterType)) {
                return;
            }

            // if stream is limited to one odds format, hide it if it doesn't match user odds format (only support fractional and base1 and default to base1)
            if (stream.oddsFormat && (['fractional', 'base1'].indexOf(stream.oddsFormat) > -1)) {
                var userFormat = user.data.oddsFormat == 'fractional' ? 'fractional' : 'base1';
                if (userFormat !== stream.oddsFormat) {
                    return;
                }
            }

            stream.startTimeFormatted = moment.unix(stream.startTime).tz(user.timeZone()).format('LT');
            allowed_streams.push(stream);
        });
        this.data.showEmpty = allowed_streams.length ? false : true;

        return allowed_streams;
    }
});

export default StreamsCollection;
