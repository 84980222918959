'use strict';
import $                            from 'jquery';
import _                            from 'underscore';
import app                          from 'components/core/application';
import Backbone                     from 'backbone';
import BackboneCloseView            from 'components/core/mixins/Backbone.CloseView';
import template                     from 'modules/event/special-event-item.tpl.hbs';
import bettingService               from 'components/betting/betting.service';

/**
 * Special Event view
 *
 * @name SpecialEventView
 * @constructor
 *
 * @requires jQuery
 * @requires Underscore
 * @requires BackboneCloseView
 * @requires template
 *
 * @description
 * 		Special Event item view.
 *
 * @returns {Function} - Constructor function for SpecialEventView
 */

var SpecialEventView = Backbone.View.extend(
    _.extend({}, BackboneCloseView, {
        tagName: 'li',

        className: 'c-dataList__row',

        template: template,

        events: {
            'click [data-idRunner]': 'showDialog'
        },

        initialize: function () {
            this.listenTo(this.model, 'updated', this.render);
        },

        render: function () {
            this.$el.html(this.template(this.model.toJSON()));
            return this;
        },

        showDialog: function(e) {
            var link = $(e.target);
            var betType = link.attr('data-betType');
            var odds = link.attr('data-odds');
            const idRunner = parseInt(link.attr('data-idRunner'), 10);
            const horseName = link.attr('data-horseName');

            if (app.betslip.hasBet(idRunner)) {
                app.betslip.removeBet(idRunner);
                return;
            }

            if(idRunner.length < 1) return;

            // to have runner name showing on quickbet
            this.model.attributes.name = horseName;
            // to have the title showing on quickbet
            this.model.attributes.raceTitleLabel = this.model.attributes.raceTitle;

            bettingService.showQuickbet({
                buttonView: this,
                attributes: {
                    idRunner: idRunner,
                    betType: betType,
                    odds: odds,
                    betCategory: 'FXD',
                    isSpecialBet: true,
                    isHeadToHead: this.model.attributes.specialBetType === 'H2H',
                    disableBetslip: this.model.attributes.specialBetType !== 'H2H',
                },
                runnerModel: this.model
            });
        }
    })
);

export default SpecialEventView;
