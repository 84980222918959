/**
 * Register handlebar if in Array method
 *
 * @Example
 * syntax in Handlebars template: {{#ifIn 'value' array}}
 *
 */
export default function (elem, list, options) {
    if (list === undefined) {
        return options.inverse(this);
    }
    if (list.indexOf(elem) > -1) {
        return options.fn(this);
    }
    return options.inverse(this);
};
