'use strict';

import Backbone             from 'backbone';
import H2HModel             from 'modules/h2h/race-h2h.model';

/**
 * EventH2h Collection
 * @name RaceH2hCollection
 * @constructor
 *
 * @requires Backbone
 * @requires H2HModel
 *
 * @description
 *     RaceH2h Collection
 *
 * @returns Function - Constructor function for RaceH2h Collection
 */
var RaceH2hCollection = Backbone.Collection.extend({
    model: H2HModel
});

//with this there is no need to set url which is mandatory for a collection
RaceH2hCollection.prototype.sync = function() {return null;};
RaceH2hCollection.prototype.fetch = function() {return null;};
RaceH2hCollection.prototype.save = function() {return null;};

export default RaceH2hCollection;
