'use strict';

import app               from 'components/core/application';

/**
 * Context menu object
 *
 * @name contextMenu
 *
 * @requires app
 *
 * @description
 * 		Manages context menu options based on available data
 *
 * @returns {object}
 */
const contextMenu = {

    init: function(data) {
        var actions = [
            {
                event: 'click',
                selector: '#cancel',
                domSelector: 'id=cancel',
                title: app.polyglot.t('label_cancel'),
                icon: 'rb-icon--cancel',
                cb: function() {
                    app.overlay.closeComponent();
                }
            }
        ];

        // checks if streaming media is available and video is available for the user
        let streamingActions = this.mediaService.getContextMenuActions(data);
        actions = streamingActions.concat(actions);

        if (actions.length > 1) {
            var showContextMenu = function () {
                // if there is only one option, fire the callback without showing the menu
                if (actions.length < 3) {
                    actions[0].cb();
                    return;
                }
                app.overlay.actionSheet({actions: actions}).render();
            };

            app.subHeader.rightMenu(true);
            app.subHeader.on('livestream:show', showContextMenu, this);
        }
    },

    destroy: function() {
        app.subHeader.off('livestream:show', null,  this);
    }
};

export default contextMenu;
