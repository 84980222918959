/**
 * Register handlebar exists method
 *
 * @Example
 * syntax in Handlebars template: {{ {{exists value}} }}
 *
 */
export default function (val) {
    return (val !== undefined && val !== false && val !== null);
};
