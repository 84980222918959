/**
 * Register handlebar string toLowerCase method
 *
 * @Example
 * syntax in Handlebars template: {{ {{toLowerCase value}} }}
 *
 */

export default function (str) {
    return (str) ? str.toLowerCase() : str;
};
