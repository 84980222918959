'use strict';

import _                           from 'underscore';
import $                           from 'jquery';
import app                         from 'components/core/application';
import Backbone                    from 'backbone';
import template                    from 'modules/race/race-numbers.tpl.hbs';
import raceHelpers                 from 'modules/race/race.helpers';
import BackboneCloseView           from 'components/core/mixins/Backbone.CloseView';

/**
 * Race numbers view
 *
 * @name RaceNumbersView
 * @constructor
 *
 * @requires Underscore
 * @requires jQuery
 * @requires Backbone
 * @requires template
 * @requires BackboneCloseView
 *
 * @description
 * 		RaceNumbers view
 *
 * @returns {Function} - Constructor function for RaceNumbersView
 */

const RaceNumbersView = Backbone.View.extend(
    _.extend({}, BackboneCloseView, {

        className: 'race-numbers',

        template: template,

        events: {
            'click [data-id]': 'goToRace'
        },

        /**
         * @method initialize
         *
         * @param options {races: [], active_race_id: int} represent Races of an Event and an active race id
         */
        initialize: function (options) {
            this.data = {
                races: options.races,
                active_race_id: options.active_race_id,
                isVirtual: options.isVirtual
            };
        },

        render: function () {
            this.$el.html(this.template({races: this.data.races, isVirtual: this.data.isVirtual}));
            this.checkRaceNumbersVisibility();
            return this;
        },

        updateStatus: function(race_to_update) {
            _.map(this.data.races, function(race) {
                if(race.idRace === race_to_update.idRace) race.raceStatus = race_to_update.raceStatus;
            });

            return this;
        },

        checkRaceNumbersVisibility: function() {
            setTimeout(() => {
                raceHelpers.keepInViewPort(this.$(`[data-id="${this.data.active_race_id}"]`));
            }, 0);
        },

        update: function (data) {
            this.data.races = data.races;
            return this;
        },

        goToRace: function(e) {
            e.preventDefault();
            let active_race_id = $(e.currentTarget).attr('data-id');
            app.router.navigateTo(`/race/details/id/${active_race_id}`);
        }
    })
);

export default RaceNumbersView;
