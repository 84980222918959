import Backbone             from 'backbone';
import user                 from 'components/user/user';

const BannersCollection = Backbone.Collection.extend({

    url: '/rest/v2/banners',

    initialize: function (models, options) {
        if (options.idRace) {
            this.url += `/mobile_racecard/${options.idRace}`;
        }
    },
    parse: function (response) {
        const now = Math.floor(Date.now() / 1000);
        const isRaceCard = /mobile_racecard/.test(this.url);

        return response.filter(function(banner) {
            const isPromo = !(user.data.noBonus === '1' && banner.is_bonus_promotion);
            let isActual = true;

            // check date only for race card. Do not check for home page
            if (isRaceCard) {
                isActual = (((banner.start_date && now > banner.start_date) || !banner.start_date) && ((banner.end_date && now < banner.end_date) || !banner.end_date));
            }

            return isPromo && isActual;
        });
    }
});

export default BannersCollection;
