import $                     from 'jquery';
import Backbone              from 'backbone';
import template              from 'components/betting/unit-stakes.tpl.hbs'

/**
 * BetItem item view Controller
 * @name UnitStakes
 * @constructor
 *
 * @requires Backbone
 * @requires template
 *
 * @description
 *     UnitStakes View Controller
 *     Select predefined unit stake or a custom amount
 *
 * @returns Function - Constructor function for UnitStakes view
 */
const UnitStakes = Backbone.View.extend({
    template: template,

    events: {
        'click [data-stake-btn]': 'stakeBtn',
    },

    initialize: function(data={}) {
        this.DOM = {};
        this.data = data;
    },

    render: function() {
        this.$el.html(this.template(this.data));
        return this;
    },

    update: function({ personalUnitStakes }) {
        this.data.personalUnitStakes = personalUnitStakes;
        this.render();
    },

    stakeBtn: function (e) {
        var $el = $(e.currentTarget);
        this.$('[data-stake-btn].isSelected').removeClass('isSelected');
        $el.addClass('isSelected');
        this.trigger('select:unitStake', $el.attr('data-stake-btn'));
    },

    close: function () {
        this.remove();
        this.undelegateEvents();
    }
});

export default UnitStakes;
