/**
 * Set default routes to manage back arrow behaviour
 */
export const defaultRoutesList = {
    defaultRoutes: {
        'settings/language': {
            to: 'settings',
            routes: []
        },
        'settings/odds_format': {
            to: 'settings',
            routes: []
        },
        'settings/unit_system': {
            to: 'settings',
            routes: []
        }
    }
};
