var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
    return "isUnderlined";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.escapeExpression;

  return "\n<div class=\"c-list\" data-list></div>\n\n<div class=\"c-tabs--antepost c-grid\">\n    <div class=\"c-grid__col is-1-2\">\n        <a class=\"c-btn c-btn--block c-btn--flat "
    + ((stack1 = helpers.unless.call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.filterState : depth0)) != null ? stack1.antepost : stack1)) != null ? stack1.special : stack1),{"name":"unless","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\" role=\"button\" data-type=\"general\">"
    + alias1(__default(require("../../../brands/racebets/js/components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_event_filter_general",{"name":"translate","hash":{},"data":data}))
    + "</a>\n    </div>\n    <div class=\"c-grid__col is-1-2\">\n        <a class=\"c-btn c-btn--block c-btn--flat "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.filterState : depth0)) != null ? stack1.antepost : stack1)) != null ? stack1.special : stack1),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\" role=\"button\" data-type=\"special\">"
    + alias1(__default(require("../../../brands/racebets/js/components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_event_filter_specials",{"name":"translate","hash":{},"data":data}))
    + "</a>\n    </div>\n</div>\n";
},"useData":true});