'use strict';

import _                     from 'underscore';
import $                     from 'jquery';
import app                   from 'components/core/application';
import Backbone              from 'backbone';
import template              from 'components/sortable-view/sortable.tpl.hbs';
import * as SortableMethods  from 'components/sortable-view/sortable.methods';
import BackboneCloseView     from 'components/core/mixins/Backbone.CloseView';

/**
 * Sortable view
 *
 * @name SortableView
 * @constructor
 * @requires Underscore
 * @requires jQuery
 * @requires Backbone
 * @requires template
 * @requires SortableCollection
 * @requires BackboneCloseView
 *
 * @description
 *        Serves as a wrapper for a collection of item view.
 *        Internally there is a collection initialized with models and and sorting logic.
 *
 *         {
 *           th: [
 *                {title: '#', width:'10%', sortable: true, active: true, sort_attribute: 'programNumber'},
 *                {title: app.polyglot.t('label_runner_p'), width:'50%'},
 *                {title: app.polyglot.t('label_bet_type_PLC'), width:'20%'},
 *                {title: app.polyglot.t('label_bet_type_WIN'), width:'20%'}
 *            ],
 *            view: RaceItem,       - view to be rendered for each model (item view)
 *            models: data.runners, - models to be set on the collection
 *            data: data            - additional data to be passed into each item view
 *         }
 *
 *         'sortable'       - makes column sortable
 *         'active'         - active sortable column on view initialization
 *         'sort_attribute' - and attribute to sort on when view initialized
 *
 * @returns {Function} - Constructor function for Sortable View
 */

const SortableView = Backbone.View.extend(
    _.extend({}, BackboneCloseView, SortableMethods, {

        template: template

    })
);

export default SortableView;
