'use strict';
import $                            from 'jquery';
import _                            from 'underscore';
import Backbone                     from 'backbone';
import BackboneCloseView            from 'components/core/mixins/Backbone.CloseView';
import template                     from 'modules/home/country-list.tpl.hbs';
import itemView                     from 'modules/home/event-list-item';
import user                         from 'components/user/user';

/**
 * Country list
 *
 * @name CountryList
 * @constructor
 *
 * @description
 *  A collection view, renders children views in an accordion with a flag and countryname in the header
 *
 * @returns {Function} - Constructor function for CountryList
 */

var CountryList = Backbone.View.extend(
    _.extend({}, BackboneCloseView, {
        template: template,

        events: {
            'click .c-accordion.isToggleable .c-accordion__head': 'toggleCollapse'
        },

        initialize: function (opt) {
            this.DOM = {};
            this.data = opt.data;
            this.opt = opt;
            this.parentView = opt.parentView;
            this.childViews = [];

            this.tab = this.parentView.parentView.activeTab; // current, antepost or calendar

            //this.closed is true if the user closed the country list in the current session
            //this.open is true if the user opened the country list in the current session
            this.closed = user.countryListSettings(this.tab).isClosed(this.data.country);
            this.open = user.countryListSettings(this.tab).isOpen(this.data.country);

            //if there are any events shown by default (this.data.data.shown) this.closed is always true;
            if (!_.isUndefined(this.data.data.shown) && this.data.data.shown.length > -1) {
                this.closed = !this.open;
            }

            this.data.data.closed = this.closed;

            this.listenTo(this, 'view:appended', this.renderItems);
        },

        render: function () {
            this.data.isVirtual = this.opt.isVirtual;

            this.$el.html(this.template(this.data));
            this.$el.attr('data-country-list', 'true');
            this.DOM.accordion = this.$('[data-accordion]');
            this.DOM.eventsList = this.$('[data-events]');
            this.DOM.togglableEventsList = this.$('[data-expandable-events]');
            this.DOM.hiddenCount = this.$('[data-hidden-count]');

            return this;
        },

        toggleCollapse: function () {
            if (this.closed) {

                if (_.isUndefined(this.DOM.togglableEventsList.data('height'))) {
                    this.DOM.togglableEventsList.css({'visibility': 'hidden', 'position': 'absolute', 'height': 'auto'});
                    var height = this.DOM.togglableEventsList.outerHeight();
                    this.DOM.togglableEventsList.data('height', height);
                    this.DOM.togglableEventsList.css({'visibility': 'visible', 'position': 'static', 'height': '0'});
                    //without this timeout the css animation isn't happening
                    window.setTimeout(() => {
                        this.DOM.togglableEventsList.css('height', height);
                    }, 0);
                } else {
                    this.DOM.togglableEventsList.css('height', this.DOM.togglableEventsList.data('height'));
                }

                this.closed = false;
                this.open = true;
                this.DOM.accordion.removeClass('isCollapsed');

                user.countryListSettings(this.tab).add('open', this.data.country);
                user.countryListSettings(this.tab).remove('closed', this.data.country);
            } else {
                // if the list is open by default the animation is not happening, since it starts from height auto, so we set it before.
                if (this.data.data.showArrow && _.isUndefined(this.DOM.togglableEventsList.data('height'))) {
                    this.DOM.togglableEventsList.css('height', this.DOM.togglableEventsList.outerHeight());
                }

                window.setTimeout(() => {
                    this.DOM.togglableEventsList.css('height', 0);
                }, 10);
                this.DOM.accordion.addClass('isCollapsed');

                this.closed = true;
                this.open = false;

                user.countryListSettings(this.tab).remove('open', this.data.country);
                user.countryListSettings(this.tab).add('closed', this.data.country);
            }
        },

        renderItems: function () {
            this.closeSubViews();

            if ((this.data.data.hiddenCount === 0 || _.isUndefined(this.data.data.hiddenCount)) && this.data.data.hidden.length === 0) {
                this.DOM.hiddenCount.html('');
                this.DOM.accordion.addClass('isCollapsed');
            } else {
                this.DOM.hiddenCount.html('+' + this.data.data.hiddenCount);
            }

            let shownListFragment = document.createDocumentFragment();
            _.each(this.data.data.shown, (event) => {
                let view = new itemView({
                    event: event,
                    parentView: this,
                    timeFormat: this.opt.timeFormat,
                    isVirtual: this.opt.isVirtual,
                });
                shownListFragment.appendChild(view.render().el);
                this.childViews.push(view);
            });

            this.DOM.eventsList.html(shownListFragment);

            let togglableEventsListFragment = document.createDocumentFragment();
            _.each(this.data.data.hidden, (event) => {
                let view = new itemView({
                    event: event,
                    parentView: this,
                    timeFormat: this.opt.timeFormat
                });
                togglableEventsListFragment.appendChild(view.render().el);
                this.childViews.push(view);
            });

            this.DOM.togglableEventsList.html(togglableEventsListFragment);

            // if a country list is updated and is open, we need to set the el height again
            if (this.open) {
                this.DOM.togglableEventsList.css('height', 'auto');
                window.setTimeout(() => {
                    // on old phones the animation is so slow that the outerHeight() could be equal to 0 or 1px so we leave it auto.
                    let outerHeight = this.DOM.togglableEventsList.outerHeight();
                    if (outerHeight > 10) {
                        this.DOM.togglableEventsList.css('height', this.DOM.togglableEventsList.outerHeight());
                    }
                    this.DOM.togglableEventsList.removeData('height');
                }, 200);
            } else {
                this.DOM.togglableEventsList.removeData('height');
            }
        },

        closeSubViews: function () {
            _.each(this.childViews, function (childView) {
                childView.close();
            });
            this.childViews = null;
            this.childViews = [];
        },

        onClose: function () {
            this.DOM.togglableEventsList.off();
            this.closeSubViews();
        }
    })
);

export default CountryList;
