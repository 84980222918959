var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
    return " value=\""
    + this.escapeExpression(this.lambda((depth0 != null ? depth0.valueLabel : depth0), depth0))
    + "\" ";
},"3":function(depth0,helpers,partials,data) {
    var alias1=this.lambda, alias2=this.escapeExpression;

  return "            <div class=\"table-cell c-amountInput__plus\" data-plus=\""
    + alias2(alias1((depth0 != null ? depth0.value : depth0), depth0))
    + "\">\n                + "
    + alias2(alias1((depth0 != null ? depth0.label : depth0), depth0))
    + "\n            </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.escapeExpression;

  return "<div class=\"table-row c-amountInput__wrapper\">\n    <div class=\"table-cell\">\n        <div class=\"c-amountInput\">\n            <input type=\"text\" id=\"unitStake\" data-amount name=\"amount\" "
    + ((stack1 = __default(require("../helpers/handlebar-helpers/compare.js")).call(depth0,(depth0 != null ? depth0.value : depth0),">",0,{"name":"compare","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " class=\"c-form__inputText c-form__inputText--border-gray c-amountInput__input\">\n            <div data-validation_amount class=\"c-form__validationMessage\"></div>\n            <span class=\"c-amountInput__currency\" data-currency>"
    + alias1(this.lambda((depth0 != null ? depth0.symbol : depth0), depth0))
    + "</span>\n            <span class=\"c-amountInput__clear\" data-clear>x</span>\n        </div>\n    </div>\n    <div class=\"table-cell\">\n        <button type=\"button\" class=\"c-btn c-btn--light\" data-stake-back><i class=\"rb-icon rb-icon--back2presets\"></i></button>\n    </div>\n</div>\n<div class=\"c-amountInput__hint\">"
    + alias1(__default(require("../helpers/handlebar-helpers/translateInterpolate.js")).call(depth0,"label_amount_converted",{"name":"translateInterpolate","hash":{"currency":(depth0 != null ? depth0.user_currency : depth0)},"data":data}))
    + ": <span data-converted-preset-amount></span></div>\n<div class=\"l-scrollH c-amountInput__plusWrapper\">\n    <div class=\"table-row c-amountInput__plusTable\">\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.increasePresets : depth0),{"name":"each","hash":{},"fn":this.program(3, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n</div>\n\n\n";
},"useData":true});