'use strict';

import _ from 'underscore';
import Backbone from 'backbone';
import app from 'components/core/application';
import BackboneSingleton from 'components/core/mixins/Backbone.Singleton';
import model from 'modules/race/race-item.model';
import transformRunners from 'modules/race/transform.runners';
import webSocketManager from 'components/helpers/webSocketManager';
import maybe from 'components/helpers/maybe';
import helpers from 'components/helpers/helpers';
import raceHelpers from 'modules/race/race.helpers';

var RunnersCollection = Backbone.Collection.extend({

    data: {
        id: null,
        runners: []
    },

    url: '/ajax/races/details/id/:race_id',

    model: model,

    initialize: function (models, options) {
        this.options = options;
        if(options.data) this.data = options.data;
        if(options.timestamp) this.timestamp = options.timestamp;
        this.on('error', this.fallBack, this);

        //trigger models' init
        if (models) this.setupWebsockets(this.options.data);

        //todo: check what response is returned when ajax host fails and then use one error cb function with checking
        //if another ajax request is required or we need to show an error info
        this.on('error', this.error, this);
    },

    error: function(collection, response, options) {
        var responseText = helpers.parseJSON(response.responseText);

        if(maybe.of(responseText).mapDotProp('error.message').join()) {
            app.trigger('dialog:error', app.interpolate('msg_unknown_error_with_errorcode', {errorCode: responseText.error.message}));
        } else {
            app.trigger('dialog:error', app.polyglot.t('msg_generic_error'));
        }
    },

    /**
     * @method fallBack
     * @description
     *  If ajaxHost is not available, use fallback host
     */
    fallBack: function (collection, response, options) {
        this.fetch({ajaxHostFailure: true});
    },

    generateSocketChannelName: function(race_id) {
        return `node_raceCard_${race_id}`;
    },

    setRaceId: function(race_id) {
        this.url = `/ajax/races/details/id/${race_id}`;

        return this;
    },

    parse: function (response) {
        if (response.type === 'error') {
            return [];
        }

        this.data = transformRunners.mapRunners(response);
        this.setupWebsockets(response);
        return transformRunners.parseRunners(this.data);
    },

    setupWebsockets: function (data) {
        this.data.timestamp = data.timestamp;
        if(this.data.socketChannel) this.stopListening(`update:channel:${this.data.socketChannel}`);
        this.data.socketChannel = this.generateSocketChannelName(data.race.idRace);
        this.listenTo(webSocketManager, `update:channel:${this.data.socketChannel}`, this.onUpdate);
    },

    //todo: move to presenter
    onUpdate: function (response) {
        let data = response.data;

        let fixedOddsStatus = maybe.of(data).mapDotProp('race.fixedOddsStatus').join();
        let fixedOddsStatusChanged = !_.isUndefined(fixedOddsStatus) && fixedOddsStatus !== this.data.race.fixedOddsStatus;

        if (data.betTypes || fixedOddsStatusChanged) {
            _.extend(this.data.betTypes, data.betTypes || {});
            if (fixedOddsStatusChanged) this.data.race.fixedOddsStatus = fixedOddsStatus;
            this.data.availableOdds = raceHelpers.availableOdds(this.data.betTypes, this.data.race.fixedOddsStatus);
            this.trigger('race:betTypes:updated');
        }

        if (data.event) {
            _.extend(this.data.event, data.event);
            this.trigger('race:event:updated');
        }

        if (data.race) {
            _.extend(this.data.race, data.race);
            this.trigger('race:race:updated');
        }

        if (data.siblingRaces) {
            _.each(data.siblingRaces.races, (updated) => {
                let i = _.findIndex(this.data.siblingRaces.races, (old) => {
                    return old.idRace === updated.idRace;
                });

                if (i > -1) {
                    this.data.siblingRaces.races[i].raceStatus = updated.raceStatus;
                }
            });

            this.trigger('race:siblings:updated');
        }

        if (data.runners) {
            // find runners to update
            _.each(data.runners, (runner) => {
                let runnerToUpdate = this.get(runner.idRunner);

                if(runnerToUpdate) {
                    // Check if horse is abroad and show a flag
                    runnerToUpdate.attributes.isAbroad = transformRunners.checkHorseAbroad(runner);

                    runnerToUpdate.attributes.odds = runner.odds;
                    runnerToUpdate.attributes.reserve = runner.reserve;
                    runnerToUpdate.attributes.scratched = runner.scratched;

                    if (_.isUndefined(runnerToUpdate.attributes.jockey) && runner.jockey) runnerToUpdate.attributes.jockey = {};

                    if (runner.jockey){
                        runnerToUpdate.attributes.jockey.firstName = runner.jockey.firstName ? runner.jockey.firstName : null;
                        runnerToUpdate.attributes.jockey.firstNameAbbr = runner.jockey.firstNameAbbr ? runner.jockey.firstNameAbbr : null;
                        runnerToUpdate.attributes.jockey.lastName = runner.jockey.lastName ? runner.jockey.lastName : null;
                    }
                    runnerToUpdate.setupData();
                    runnerToUpdate.trigger('updated');
                }
            });
        }
    }

}, BackboneSingleton);

export default RunnersCollection;
