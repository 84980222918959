import { ResizeObserver } from '@juggle/resize-observer';
import _ from 'underscore';

// fires callbacks when body resizes
// currently only pass the new height as parameter

let callbacks = [];

let running = false;

let resize = (height) => {
    if (!running) {
        running = true;
        runCallbacks(height);
    }
};

let runCallbacks = _.throttle((height) => {
    callbacks.forEach((fn) => {
        fn(height);
    });

    running = false;
}, 300);

let addCallback = (fn) => {
    if (fn) {
        callbacks.push(fn);
    }
};

let add = (fn) => {
    if (!callbacks.length) {
        let observer = new ResizeObserver((entries, observer) => {
            for (const entry of entries) {
                const {left, top, width, height} = entry.contentRect;
                resize(height);
            }
        });

        observer.observe(document.body);
    }
    addCallback(fn);
};

export default add;
