var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "                    <li class=\"c-special__el\">\n                        <div class=\"c-special__name\">\n                            "
    + alias2(alias1((depth0 != null ? depth0.horseName : depth0), depth0))
    + "\n                        </div>\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.isWinner : depth0),{"name":"if","hash":{},"fn":this.program(2, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                            <div class=\"c-special__position\">\n                                <div class=\"c-post c-post--special\">\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.finalPosition : depth0),{"name":"if","hash":{},"fn":this.program(4, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                                </div>\n                            </div>\n\n                        <div class=\"c-special__win\">\n                            <button class=\"c-btn "
    + ((stack1 = __default(require("../../components/helpers/handlebar-helpers/compare.js")).call(depth0,(depths[1] != null ? depths[1].raceStatus : depths[1]),"===","OPN",{"name":"compare","hash":{},"fn":this.program(6, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../components/helpers/handlebar-helpers/compare.js")).call(depth0,(depths[1] != null ? depths[1].raceStatus : depths[1]),"!==","OPN",{"name":"compare","hash":{},"fn":this.program(8, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\" data-idRunner=\""
    + alias2(alias1((depth0 != null ? depth0.idRunner : depth0), depth0))
    + "\" data-horseName=\""
    + alias2(alias1((depth0 != null ? depth0.horseName : depth0), depth0))
    + "\" data-betType=\"WIN\" data-odds=\""
    + alias2(alias1((depth0 != null ? depth0.winOdds : depth0), depth0))
    + "\" "
    + ((stack1 = __default(require("../../components/helpers/handlebar-helpers/compare.js")).call(depth0,(depths[1] != null ? depths[1].raceStatus : depths[1]),"!==","OPN",{"name":"compare","hash":{},"fn":this.program(10, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias2(alias1((depth0 != null ? depth0.oddsLabel : depth0), depth0))
    + "</button>\n                        </div>\n                    </li>\n";
},"2":function(depth0,helpers,partials,data) {
    return "                            <div class=\"c-special__position c-special__position--winner\">\n                                "
    + this.escapeExpression(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_winner",{"name":"translate","hash":{},"data":data}))
    + "\n                            </div>\n";
},"4":function(depth0,helpers,partials,data) {
    return "                                        "
    + this.escapeExpression(this.lambda((depth0 != null ? depth0.finalPosition : depth0), depth0))
    + "\n                                        <i class=\"rb-icon rb-icon--circle-thin\"></i>\n";
},"6":function(depth0,helpers,partials,data) {
    return "c-btn--odds";
},"8":function(depth0,helpers,partials,data) {
    return "c-btn--default c-btn--disabled";
},"10":function(depth0,helpers,partials,data) {
    return "disabled";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=this.lambda;

  return "<div class=\"c-special\">\n    <div class=\"c-box\">\n        <h3 class=\"c-box__title\">"
    + this.escapeExpression(alias1((depth0 != null ? depth0.raceTitle : depth0), depth0))
    + "</h3>\n        <div class=\"c-box__content\">\n            <p class=\"c-special__desc\">"
    + ((stack1 = alias1((depth0 != null ? depth0.conditions : depth0), depth0)) != null ? stack1 : "")
    + "</p>\n            <ul class=\"c-special__list\">\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.runners : depth0),{"name":"each","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            </ul>\n        </div>\n    </div>\n</div>\n";
},"useData":true,"useDepths":true});