import _                       from 'underscore';
import $                       from 'jquery';
import ViewScroll              from 'components/core/mixins/Backbone.ViewScroll';
import app                     from 'components/core/application';
import template                from 'components/betting/betslip.tpl.hbs';
import user                    from 'components/user/user';
import maybe                   from 'components/helpers/maybe';
import BetItemsCollection      from 'components/betting/bet-items.collection';
import BetItemView             from 'components/betting/bet-item';
import BetItemMultiplesView    from 'components/betting/bet-item-multiples';
import auth                    from 'components/auth/auth';
import betslipHelpers          from 'components/betting/betslip.helpers';
import BetPlacedDialogView     from 'components/betting/bet_placed';
import BetErrorDialogView      from 'components/betting/bet_placing_error';
import UpdateMessageView       from 'components/betting/update_message';
import WebSocketManager        from 'components/helpers/webSocketManager';
import BackboneCloseView       from 'components/core/mixins/Backbone.CloseView';
import multiplesValidator      from 'components/betting/multiples.validator';

var BetslipMethods = {

        className: 'l-scrollV',

        template: template,

        events: {
            'click [data-wrap]': 'stopPropagation',
            'click [data-close]': 'closeDialog',
            'click [data-tax-info]': 'toggleTaxInfo',
            'click [data-accept-all]': 'acceptAll',
            'click [data-clear-all]': 'clearAll',
            'click [data-place-bet]': 'submit',
            'click [data-bonus-warning]': 'toggleBonusWarningMessage'

        },

        betslip: true,

        initialize: function(opt) {
            this.DOM = {};
            this.options = opt;
            this.timeOuts = [];
            this.updateTimeOut = 1000;

            //holds 'race.taxFees' value from /ajax/races/details/id/:eventId/version/detailed/ Could also be passed around in a service but seems like it is needed only here
            this.taxFees = {};

            this.initCollection();
        },

        render: function () {
            let data = {
                show_tax: (user.data && user.data.country && user.data.country.toUpperCase() === 'DE') ? true : false,
                isUkCustomer: user.isUkCustomer(),
                hasOnlyBonus: user.data.balance === user.data.bonusBalance
            };
            this.$el.html(this.template(data));

            this.DOM = {
                wrap: this.$('[data-wrap]'),
                bet_list: this.$('[data-bet-list]'),
                multiples_list: this.$('[data-multiples-list]'),
                tax_info_container: this.$('[data-tax-info-container]'),
                tax_value: this.$('[data-tax-value]'),
                winnings: this.$('[data-winnings]'),
                total_stake: this.$('[data-total-stake]'),
                total_stake_without_tax: this.$('[data-total-stake-without_tax]'),
                total_bet_count: this.$('[data-total-bet-count]'),
                update_messages: this.$('[data-update-messages]'),
                add_unit_msg: this.$('[data-add-unit-msg]'),
                max_races_amount: this.$('[data-max-race-reached]'),
                data_betslip_invalid: this.$('[data-betslip-invalid]'),
                submit_btn: this.$('[data-place-bet]'),
                bonusWarning: this.$('[data-bonus-warning]'),
                bonusWarningMessage: this.$('[data-bonus-warning-message]')
            };

            this.t = {
                not_available: app.polyglot.t('label_not_available')
            };

            this.renderBetsList();
            this.renderMultiplesList();
            this.checkUpdateStatus();
            this.checkMaxRacesCount();

            app.overlay.showBetslip();

            window.setTimeout(() => this.handleScrollToInputs(), 0);
            return this;
        },

        initCollection: function() {
            this.collection = new BetItemsCollection();
            this.listenTo(this.collection, 'sync', this.dataFetched);
            this.listenTo(this.collection, 'update:bet reset', this.dataBetChanged);
            this.listenTo(this.collection, 'update:multiples reset', this.dataMultiplesChanged);
            this.listenTo(this.collection, 'error', this.error);

            this.listenTo(this.collection, 'model:bet:changed', this.modelBetChanged);
            this.listenTo(this.collection, 'model:multiples:changed', this.modelMultiplesChanged);

            this.listenTo(this.collection, 'betslip:purchase:success', this.betslipPurchaseSuccess);
            this.listenTo(this.collection, 'betslip:purchase:error', this.betslipPurchaseError);

            this.listenTo(this.collection, 'model:destroy', this.modelDestroyed);
            this.listenTo(this.collection, 'model:isSelected', this.modelIsSelected);

            this.listenTo(this.collection, 'betslip:addUnit', this.manageAddUnitMessage);

            this.listenTo(this.collection, 'betslip:validity', this.manageBetslipValidity);
            this.listenTo(this.collection, 'update:totalStake', this.toggleBonusWarning);

            this.collection.fetch({silent: true});
        },

        checkUpdateStatus: function() {
            if(maybe.of(this).mapDotProp('DOM.submit_btn').join()) {
                var messages = betslipHelpers.getFullUpdateStatus(this.collection.data.bets.toJSON());
                if (messages.length > 0) {
                    this.$el.addClass('m-betslip--updated');
                    if (this.DOM) this.DOM.submit_btn.attr('disabled', true);
                    this.manageUpdateMessages(messages);
                } else {
                    this.$el.removeClass('m-betslip--updated');
                    if (this.DOM) this.DOM.submit_btn.removeAttr('disabled');
                    this.closeMessageView();
                }
            }
        },

        isInUpdateStatus: function() {
            let messages = betslipHelpers.getFullUpdateStatus(this.collection.data.bets.toJSON());
            return messages.length > 0;
        },

        manageUpdateMessages: function(messages) {
            this.closeMessageView();
            if(messages.length > 0) {
                this.message_view = new UpdateMessageView();
                if(maybe.of(this).mapDotProp('DOM.update_messages').join()) this.DOM.update_messages.html(this.message_view.render(messages).$el);
            }
        },

        dataFetched: function(data) {
            this.triggerEvent('betslip:change', data);
            this.joinRunnersChannels(this.collection.getBetIds());
        },

        save: function() {
            this.collection.saveAll();
        },

        dataBetChanged: function(data) {
            if(this.isEmpty()) app.overlay.hideBetslip();

            this.saveWithTimeOut('change');

            //create list if view has been rendered
            if(maybe.of(this).mapDotProp('DOM.bet_list').join()) {
                this.renderBetsList();
                this.checkMaxRacesCount();
            }

            this.collection.addMultiples();
            this.checkUpdateStatus();
            this.updateTaxInfo();

            this.triggerEvent('betslip:change', data);
        },

        dataMultiplesChanged: function(data) {
            this.saveWithTimeOut('change');

            //create list if view has been rendered
            if(maybe.of(this).mapDotProp('DOM.multiples_list').join()) {
                this.renderMultiplesList();
                this.checkMaxRacesCount();
            }

            this.triggerEvent('betslip:change', data);
        },

        modelBetChanged: function(data) {
            this.updateTotalStake();
            this.updateTotalBetCount();
            this.updateTotalWinnings();
            this.updateTaxInfo();
            this.manageAddUnitMessage();

            this.saveWithTimeOut('change');
            this.checkUpdateStatus();

            this.triggerEvent('betslip:change', data);
        },

        modelMultiplesChanged: function(data) {
            this.updateTotalStake();
            this.updateTotalBetCount();
            this.updateTotalWinnings();
            this.updateTaxInfo();
            this.manageAddUnitMessage();
            this.updateMinMaxStake();

            this.saveWithTimeOut('change');
            this.checkUpdateStatus();
        },

        saveWithTimeOut: function(timeoutID) {
            //do not save betslip state if user needs to accept changes
            if(this.isInUpdateStatus()) return;

            if(this.timeOuts[timeoutID]) clearTimeout(this.timeOuts[timeoutID]);

            this.timeOuts[timeoutID] = setTimeout(()=> {
                //update may be triggered bu adding, modifying, removing actions
                if(this.getTotalBetsCount() > 0) {
                    this.save();
                } else {
                    //betslip may have fully cleaned as a result of an update (e.g. all races finished)
                    //this.collection.removeAll();
                }
            }, this.updateTimeOut);
        },

        modelDestroyed: function(model) {
            this.triggerEvent('betslip:change', model.idRunner);
            this.leaveRunnersChannels([model.idRunner]);
        },

        modelIsSelected: function(model) {
            this.triggerEvent('model:isSelected', model);
        },

        triggerEvent: function(event, data) {
            var messages = betslipHelpers.getFullUpdateStatus(this.collection.data.bets.toJSON());
            this.trigger(event, {mc: data, bet_count: this.getTotalBetsCountNonMultiples(), update_state: messages.length > 0});
        },

        renderBetsList: function () {
            this.DOM.bet_list.append(this.composeBetsList());
            this.collection.validateMarketsForMultiples();
            this.updateTotalStake();
            this.updateTotalBetCount();
            this.updateTotalWinnings();
            this.updateTaxInfo();
        },

        renderMultiplesList: function () {
            this.DOM.multiples_list.append(this.composeMultiplesList());
            this.updateTotalStake();
            this.updateTotalBetCount();
            this.updateTotalWinnings();
            this.updateTaxInfo();
            this.updateMinMaxStake();
        },

        composeBetsList: function () {
            //clean up all child views because later on the childViews array is reset
            this.closeChildBetsViews();
            var listFragment = document.createDocumentFragment();
            var active_bets = this.getActiveBetCount();
            this.childBetViews = [];

            let bets_number = this.collection.data.bets.length;
            this.collection.data.bets.forEach((model, i) => {
                model.set({element_index: i, show_divider: i < (bets_number - 1)}, {silent: true});
                //show checkbox to add bet to multiples if bet count is 2 or more
                var view = new BetItemView({
                    model: model
                });
                listFragment.appendChild(view.render().el);
                view.delegateEvents();
                this.childBetViews.push(view);
            });

            return listFragment; //$(listFragment).clone(true);
        },

        composeMultiplesList: function () {
            //clean up all child views because later on the childViews array is reset
            this.closeChildMultiplesViews();
            var listFragment = document.createDocumentFragment();
            this.childMultiplesViews = [];

            let bets_number = this.collection.data.multiples.length;
            this.collection.data.multiples.forEach((model, i) => {
                model.set({element_index: i, show_divider: i < (bets_number - 1)}, {silent: true});
                var view = new BetItemMultiplesView({
                    model: model
                });
                listFragment.appendChild(view.render().el);
                view.delegateEvents();
                this.childMultiplesViews.push(view);
            });

            return listFragment;
        },

        error: function(collection, response) {
            if (response && response.responseText) {
                let responseObj = $.parseJSON(response.responseText);
                if (maybe.of(responseObj).mapDotProp('error.message').join() === 'BETSLIP_NOT_FOUND') {
                    this.trigger('betslip:change', {bet_count: this.getTotalBetsCountNonMultiples()});
                } else if(maybe.of(responseObj).mapDotProp('error.message').join() === 'INCLUDED_BETS_SHOULD_BE_FROM_SAME_MARKET') {
                    app.trigger('dialog:error:secondary', app.polyglot.t('msg_same_market_only'));
                } else {
                    app.trigger('dialog:error:secondary', app.polyglot.t('msg_generic_error'));
                }
            } else {
                app.trigger('dialog:error:secondary', app.polyglot.t('msg_generic_error'));
            }
        },

        getBetsCount: function() {
            return this.collection.data.bets.length;
        },

        getActiveBetCount: function() {
            return _.filter(this.collection.data.bets.toJSON(), function(bet) {
                return _.isUndefined(bet.status) || bet.status.update === false;
            });
        },

        getBets: function(json=false) {
            return json ? this.collection.data.bets.toJSON() : this.collection.data.bets.models;
        },

        getMultiplesCount: function() {
            return this.collection.data.multiples.length;
        },

        getTotalBetsCount: function() {
            return this.collection.data.bets.length + this.collection.data.multiples.length;
        },

        getTotalBetsCountNonMultiples: function() {
            return this.collection.data.bets.length;
        },

        getBetIds: function() {
            return this.collection.getBetIds();
        },

        isEmpty: function() {
            return this.getBetsCount() === 0 && this.getMultiplesCount() === 0;
        },

        isOpen: function() {
            return app.overlay.component && app.overlay.component.betslip && app.overlay.component.visible
        },

        show: function() {
            return this.getTotalBetsCount() > 0;
        },

        addBet: function(bet) {
            let all_bets = this.getBets(true);
            let bet_mapped = betslipHelpers.mapModelValues(bet, this.taxFees, betslipHelpers.getLastUnit(all_bets), betslipHelpers.isEachWay(all_bets, bet), betslipHelpers.allowedMultiples(bet), betslipHelpers.isInMultiples(bet, betslipHelpers.getMarkets(all_bets)));
            this.analyticsTagBetClick(bet_mapped);

            //remove related H2h if available
            if(bet_mapped.isHeadToHead && all_bets.length > 0) this.removeRelatedH2hBets(bet_mapped, all_bets);

            if (this.collection.raceLimitNotReached(bet_mapped)) {
                //show checkbox to add bet to multiples if bet count is 2 or more
                bet.showAddToMultiples = this.getBetsCount() > 1;

                this.collection.data.bets.add(bet_mapped);
                this.joinRunnersChannels([bet_mapped.idRunner]);
            }
        },

        removeRelatedH2hBets: function(bet_mapped, all_bets) {
            let relatedH2hBets = betslipHelpers.getRelatedH2hBets(bet_mapped, all_bets); //.map(function(value) {return value.relatedIdRunner});
            _.each(relatedH2hBets, bet => {
                this.removeBet(bet.idRunner);
            })
        },

        removeBet:function(idRunner) {
            this.collection.removeBetByRunnerId(idRunner);
        },

        hasBet: function(idRunner) {
            return this.collection.findBetByRunnerId(idRunner) ?  true : false;
        },

        //showContextMenu: function(e) {
        //    e.stopPropagation();
        //    console.log('show context menu');
        //},

        closeDialog: function(e) {
            e.stopPropagation();
            app.overlay.hideBetslip();
        },

        stopPropagation: function (e) {
            e.stopPropagation();
        },

        submit: function(e) {
            e.stopPropagation();
            auth.ifLoggedIn(function () {
                this.DOM.submit_btn.attr('disabled', true);
                this.collection.purchase();
            }, this)();
        },

        acceptAll: function(e) {
            e.preventDefault();
            this.collection.data.bets.acceptAll();
            this.submit(e);
        },

        clearAll: function(e) {
            e.stopPropagation();
            this.collection.removeAll();
        },

        toggleTaxInfo: function(e) {
            e.stopPropagation();
            this.DOM.tax_info_container.toggle();
        },

        updateTotalStake: function() {
            if(maybe.of(this).mapDotProp('DOM.total_stake').join()) {
                this.DOM.total_stake.html(betslipHelpers.calculateTotalStake(this.collection.getAllBets(true), true, true));
                this.DOM.total_stake_without_tax.html(betslipHelpers.calculateTotalStake(this.collection.getAllBets(true), true, false));
            }
            this.collection.trigger('update:totalStake');
        },

        updateTotalBetCount: function() {
            if(maybe.of(this).mapDotProp('DOM.total_bet_count').join()) this.DOM.total_bet_count.html(betslipHelpers.calculateTotalbetCount(this.collection.getAllBets(true), true));
        },

        updateTotalWinnings: function() {
            if(maybe.of(this).mapDotProp('DOM.winnings').join()) {
                var winnings = betslipHelpers.calculateWinnings(this.collection.getAllBets(true));
                this.DOM.winnings.html(this.collection.fixedBetsOnly() ? winnings : this.t.not_available);
            }
        },

        updateTaxInfo: function() {
            if(maybe.of(this).mapDotProp('DOM.tax_value').join()) this.DOM.tax_value.html(betslipHelpers.calculateTotalTaxAmount(this.collection.data.bets.toJSON(), true));
        },

        checkMaxRacesCount: function() {
            if(maybe.of(this).mapDotProp('DOM.max_races_amount').join()) {
                var race_count = this.collection.getRaceCount();
                this.DOM.max_races_amount.toggle(race_count === this.collection.maxRaceCount);
            }
        },

        betslipPurchaseSuccess: function(data, textStatus, jqXHR) {
            // this.DOM.submit_btn.removeAttr('disabled');
            var publicIds = [];
            if (jqXHR && jqXHR.responseText) {
                let responseObj = $.parseJSON(jqXHR.responseText);
                var bets = responseObj.bets && _.isArray(responseObj.bets) ? responseObj.bets : null;

                _.each(bets, function (bet) {
                    publicIds.push(bet.publicId);
                });
            }

            this.analyticsTagBetConfirmed(bets);

            let contentView = new BetPlacedDialogView({publicId: publicIds.join(', ')});

            contentView.once('successDialog:close', this.successDialogClosed, this);

            app.overlaySecondary.dialog({
                contentView: contentView,
                size: 'sliding',
                secondary: true, //tell dialog to use app.layOutSecondary
                closeOnOutsideClick: true
            });

            app.overlaySecondary.component.addOverlayClassName('c-overlay--showHeader');
            app.overlaySecondary.component.render();
            app.trigger('user:balance:update');
            app.trigger('bet:placed:successful');
        },

        successDialogClosed: function(data={}) {
            if (data.cleanBetslip) {
                this.collection.removeAll();
            }
            if (data.cleanUnits) {
                this.collection.resetUnits();
                //this.collection.data.multiples.reset(betslipHelpers.checkOddsForMultiples(this.collection.data.bets.toJSON(), this.collection.data.multiples.toJSON()));
            }
        },

        betslipPurchaseError: function(response) {
            if (response && response[0] && response[0].responseText) {
                const responseObj = $.parseJSON(response[0].responseText);
                const message = maybe.of(responseObj).mapDotProp('error.message').join();
                const data = maybe.of(responseObj).mapDotProp('error.data').join();
                const errors = betslipHelpers.getErrorMessage(data);

                if (message === 'BETSLIP_NOT_PURCHASED' && _.isArray(errors) && errors.length > 0) {
                    this.processErrors(errors);
                } else {

                    this.errorDialogView = new BetErrorDialogView(errors);
                    app.overlaySecondary.dialog({
                        contentView: this.errorDialogView,
                        secondary: true, //tell dialog to use app.layOutSecondary
                        closeBtn: false,
                        closeOnOutsideClick: true
                    });

                    app.overlaySecondary.component.render();
                    app.overlaySecondary.component.delegateEvents();

                    this.errorDialogView.on('cancel', function () {
                        app.overlaySecondary.closeDialog();
                        app.overlay.hideBetslip();
                    }, this);

                    this.errorDialogView.on('resubmit', function () {
                        app.overlaySecondary.dialog.removeOverlayClassName('c-overlay--showHeader');
                        app.overlaySecondary.closeDialog();
                        this.collection.purchase();
                    }, this);

                    this.options.closeOnOutsideClick = true;

                    this.DOM.submit_btn.removeAttr('disabled');
                }
            }
        },

        processErrors: function(errors) {
            //error type flag
            let unitStakeErrorOnly = true;
            //get bets with units
            if(this.childBetViews || this.childMultiplesViews) {
                const betsWithStake = _.filter(_.union(this.childBetViews, this.childMultiplesViews), function (bet) {
                    //multiples
                    if(bet.model.attributes.isMultiple) {
                        return maybe.of(bet).mapDotProp('model.attributes.stake.unit').join();
                    }
                    //bets
                    const parts = maybe.of(bet).mapDotProp('model.attributes.parts').join();
                    return parts && parts[0] && parts[0].stake;
                });

                _.each(errors, (error) => {
                    let view = betsWithStake[error.betNum];
                    if (view) {
                        if(view.model.attributes.isMultiple) {
                            //add RMS ID to multiples
                            if (error.idRms) {
                                view.model.attributes.stake.idRms = error.idRms;
                            }
                            //check if 'errorRunners' prop exists and has at least one value.
                            //it means that odds changed for bet(s) that are part of a multiple
                            if(error.errorRunners) {
                                _.each(error.errorRunners, val => {
                                    //find view by idRunner and overwrite 'view'
                                    view = _.find(this.childBetViews, function(view) {
                                        //check for 'idRunner'
                                        let attributes = maybe.of(view).mapDotProp('model.attributes').orElse(false).join();
                                        return attributes && attributes.idRunner ? attributes.idRunner === val.idRunner : false;
                                    });

                                    //add RMS ID to bet
                                    this.addRmsId(error, view);
                                    unitStakeErrorOnly = this.isNewUnitStakeError(error.errorCode);
                                    //trigger events for each view
                                    this.triggerViewError(error, view);
                                });
                            } else {
                                unitStakeErrorOnly = this.isNewUnitStakeError(error.errorCode);
                                this.triggerViewError(error, view);
                            }
                        } else {
                            unitStakeErrorOnly = this.isNewUnitStakeError(error.errorCode);
                            //add RMS ID to bet
                            this.addRmsId(error, view);
                            this.triggerViewError(error, view);
                        }
                    }
                });

                this.manageNewUnitMessage(errors.length > 0 && unitStakeErrorOnly);
            }
        },

        isNewUnitStakeError: function(errorCode) {
            return [111, 112].indexOf(errorCode) < 0;
        },

        addRmsId: function(error, view) {
            var parts = maybe.of(view).mapDotProp('model.attributes.parts').orElse([]).join();
            _.each(parts, function(part) {
                if(part.stake) part.stake.idRms = error.idRms;
            });
        },

        triggerViewError: function(error, view) {
            if(this.isNewUnitStakeError(error.errorCode)) {
                view.model.trigger('invalid', {}, [{name: 'unit', message: error.message}]);
            } else {
                view.model.oddsChanged(error);
            }
        },

        manageAddUnitMessage: function(data={}) {
            if(maybe.of(this).mapDotProp('DOM.add_unit_msg').join()) {
                if (_.isUndefined(data.total_bets_count)) {
                    this.DOM.add_unit_msg.hide();
                } else if (data.total_bets_count < 1) {
                    this.DOM.add_unit_msg.html(app.polyglot.t('msg_error_add_unit')).show();
                }
            }
        },

        updateMinMaxStake: function () {
            const stakeLimits = multiplesValidator.getStakeLimits(this.collection.data.bets.toJSON());
            this.collection.data.multiples.forEach((model, i) => {
                const attrStake = model.get('stake');
                model.set({stake: {...attrStake, ...stakeLimits}}, {silent: true});
            });
        },

        manageNewUnitMessage: function(show) {
            if (maybe.of(this).mapDotProp('DOM.add_unit_msg').join()) {
                this.DOM.add_unit_msg.html(app.polyglot.t('msg_error_new_unit')).toggle(show);
            }
        },

        manageBetslipValidity: function(valid) {
            var messages = betslipHelpers.getFullUpdateStatus(this.collection.data.bets.toJSON());
            if(this.DOM && this.DOM.data_betslip_invalid && messages.length < 1) {
                this.DOM.data_betslip_invalid.toggle(!valid);

                if(valid) {
                    if(this.DOM) this.DOM.submit_btn.removeAttr('disabled');
                } else {
                    if(this.DOM) this.DOM.submit_btn.attr('disabled', true);
                }
            }
        },

        joinRunnersChannels(ids) {
            WebSocketManager.connect();
            _.each(ids, (id) => {
                WebSocketManager.joinChannel({
                    channel: 'node_runner_' + id,
                    timestamp:  this.collection.data.stamp
                })
            });
        },

        leaveRunnersChannels(ids) {
            _.each(ids, (id) => {
                WebSocketManager.leaveChannel('node_runner_' + id)
            });
        },

        closeChildBetsViews: function () {
            _.each(this.childBetViews, function (view) {
                view.close();
            });
        },

        closeChildMultiplesViews: function () {
            _.each(this.childMultiplesViews, function (view) {
                view.close();
            });
        },

        closeMessageView: function() {
            if(this.message_view) this.message_view.close()
        },

        onClose: function() {
            //this.leaveRunnersChannels(this.collection.getBetIds());
            this.closeChildBetsViews();
            this.closeChildMultiplesViews();
            this.closeMessageView();
        },

        analyticsTagBetConfirmed: function (publicIds) {
            const bets = this.collection.data.bets.toJSON();

            bets.forEach(function (bet, index) {
                const betPart = bet.parts[0];

                if (publicIds[index] && betPart.stake) {
                    const CouponID = publicIds[index].publicId;
                    const betTypeMarket = (bet.isEachWay) ? 'WP' : betPart.market;
                    let totalStake = betPart.stake.amount * betPart.stake.unit;
                    if (bet.isEachWay) {
                        totalStake = totalStake * 2;
                    }

                    app.trigger('bet:placed:confirmed', {
                        'BetCategory': bet.category,
                        'BetType': betTypeMarket,
                        'TotalStake': totalStake,
                        'BetMarket': bet.country,
                        CouponID
                    });
                }
            });
        },

        analyticsTagBetClick: function (bets) {
            if (_.isArray(bets)) {
                bets.forEach(bets.parts, function (bet) {
                    app.trigger('bet:placed:click', {
                        'BetCategory': bets.category,
                        'BetType': bet.market
                    })
                });
            } else {
                app.trigger('bet:placed:click', {
                    'BetCategory': bets.category,
                    'BetType': bets.parts[0].market
                })
            }
        },

        toggleBonusWarningMessage: function(e) {
            e.preventDefault();
            betslipHelpers.toggleBonusWarningMessage($(e.target), $(this.DOM.bonusWarningMessage));
        },

        toggleBonusWarning: function() {
            const bet = betslipHelpers.calculateTotalStake(this.collection.getAllBets(true), false, true);
            betslipHelpers.toggleBonusWarning({ balance: user.data.balance, bonusBalance: user.data.bonusBalance}, bet, this.DOM.bonusWarning);
        }
    };

export default _.extend({}, BetslipMethods, ViewScroll, BackboneCloseView);
