'use strict';
import $                           from 'jquery';
import _                           from 'underscore';
import helpers                     from 'components/helpers/helpers';
import Backbone                    from 'backbone';
import BackboneCloseView           from 'components/core/mixins/Backbone.CloseView';
import template                    from 'components/input/custom-amount.tpl.hbs';
import user                        from 'components/user/user';

var CustomAmountInput = Backbone.View.extend(
    _.extend({}, BackboneCloseView, {
        template: template,

        events: {
            'keyup [data-amount]': 'amountInput',
            'keydown [data-amount]': 'triggerKeydown',
            'keypress [data-amount]': 'triggerKeypress',
            'click [data-clear]': 'clearAmountInput',
            'click [data-stake-back]': 'triggerBack',
            'click [data-plus]': 'addAmount'
        },

        initialize: function (opts) {
            this.currency = opts.currency;
            this.convert_to_user_currency = opts.convert_to_user_currency;
            this.DOM = {};
            this.presetAmounts = [
                0.5,
                1,
                1.5,
                2,
                3,
                5,
                10,
                20,
                30,
                50,
                100,
                200
            ];
            this.data = {
                increasePresets: this.formatPresets(this.presetAmounts),
                user_currency: user.data.currency.toUpperCase(),
                symbol: helpers.getCurrencySymbols(user.data.currency.toUpperCase())

            }
            this.parentView = opts.parentView;
            this.value = 0; // default value
        },

        render: function () {
            this.$el.html(this.template(this.data));
            this.DOM.amountInput = this.$('[data-amount]');
            this.DOM.inputValidation = this.$('[data-validation_amount]');
            this.DOM.converted_preset_amount = this.$('[data-converted-preset-amount]');
            this.$('[data-currency]').html(helpers.currencySymbols[this.currency]);

            return this;
        },

        update: function({currency, convert_to_user_currency}) {
            this.currency = currency;
            this.convert_to_user_currency = convert_to_user_currency;
            this.data.increasePresets = this.formatPresets(this.presetAmounts);
            this.value = 0;
            this.render();
        },

        checkValidation: function () {
            // remove previous validation issues
            if (this.DOM.inputValidation.html() !== '') {
                this.DOM.inputValidation.html('').hide();
                this.DOM.amountInput.removeClass('c-form__input--invalid');
            }
        },

        formatPresets: function(presetAmounts) {
            return _.map(presetAmounts, (value) => {
                let decimals = helpers.isInt(value) ? 0 : 2;
                return {
                    value: value,
                    label: helpers.formatMoney(value, decimals, this.currency, true)
                }
            });
        },

        amountInput: function (e) {
            this.checkValidation();
            var value = this.DOM.amountInput.val();
            this.value = parseFloat(value);
            this.trigger('keyup', value);
            this.showConvertedValue();
        },

        clearAmountInput: function () {
            this.resetUnitStake();
            this.trigger('new:amount', this.value);
            this.showConvertedValue();
        },

        resetUnitStake: function() {
            this.value = 0;
            this.DOM.amountInput.val('');
        },

        triggerBack: function (e) {
            this.trigger('back', e);
        },

        triggerKeydown: function (e) {
            this.trigger('keydown', e);
        },

        triggerKeypress: function (e) {
            this.trigger('keypress', e);
        },

        addAmount: function (e) {
            this.checkValidation();
            let value = parseFloat($(e.currentTarget).attr('data-plus'));
            this.value = this.value + value;
            this.DOM.amountInput.val(this.value);
            this.trigger('new:amount', this.value);

            this.showConvertedValue();
        },

        showConvertedValue: function() {
            if(this.convert_to_user_currency) {
                if(this.value) {
                    this.DOM.converted_preset_amount.html(Math.round(helpers.exchange(this.value, 'USD', user.data.currency) * 100) / 100).parent().fadeIn('fast');
                } else {
                    this.DOM.converted_preset_amount.html('').parent().hide();
                }
            }
        }
    })
);

export default CustomAmountInput;
