var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
    var stack1;

  return "        <ul class=\"c-dataList c-dataList--currentEvents rowsBordered\">\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.data : depth0),{"name":"each","hash":{},"fn":this.program(2, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "        </ul>\n";
},"2":function(depth0,helpers,partials,data) {
    var alias1=this.lambda, alias2=this.escapeExpression;

  return "                <li class=\"c-dataList__row\" data-index=\""
    + alias2(alias1((data && data.index), depth0))
    + "\">\n                    <div class=\"c-dataList__cell m-stream__flagCell\">\n                        <i class=\"c-flag isCountry"
    + alias2(alias1((depth0 != null ? depth0.country : depth0), depth0))
    + " m-stream__flag\"></i>\n                    </div>\n                    <div class=\"c-dataList__cell\">\n                        <div class=\"c-dataList__truncateText m-stream__details\">\n                            <div>"
    + alias2(alias1((depth0 != null ? depth0.tracks : depth0), depth0))
    + "</div>\n                        </div>\n                    </div>\n                    <div class=\"c-dataList__cell c-dataList__cell--light\">\n                        <div class=\"c-dataList__el--time\">"
    + alias2(alias1((depth0 != null ? depth0.startTimeFormatted : depth0), depth0))
    + "</div>\n                    </div>\n                </li>\n";
},"4":function(depth0,helpers,partials,data) {
    var alias1=this.escapeExpression;

  return "        <div class=\"c-emptyState\" data-empty-state=\"\">\n            <div class=\"c-emptyState--noStreams\"></div>\n            <div class=\"c-emptyState__title\">"
    + alias1(__default(require("../../../brands/racebets/js/components/helpers/handlebar-helpers/translate.js")).call(depth0,"msg_no_streams",{"name":"translate","hash":{},"data":data}))
    + "</div>\n            <a class=\"c-btn c-btn--action\" href=\"/\">"
    + alias1(__default(require("../../../brands/racebets/js/components/helpers/handlebar-helpers/translate.js")).call(depth0,"btn_goto_home_page",{"name":"translate","hash":{},"data":data}))
    + "</a>\n        </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"m-stream\">\n"
    + ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.showEmpty : depth0),{"name":"unless","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n\n";
},"useData":true});