import _                    from 'underscore';
import Backbone             from 'backbone';
import BackboneCloseView    from 'components/core/mixins/Backbone.CloseView';
import template             from 'components/auth/new-terms-content.tpl.hbs';

export const TermsContentView = Backbone.View.extend(
    _.extend({}, BackboneCloseView, {

        el: '[data-terms-content]',

        template: template,

        initialize: function () {
            this.data = {};
        },

        render: function () {
            this.$el.html(this.template(this.data));
            return this;
        },

        clearData: function() {
            this.data = {};
            return this;
        }
    })
);
