'use strict';

import _                             from 'underscore';
import { captureMessage, withScope } from '@sentry/browser';
import user                          from 'components/user/user';
import helpers                       from 'components/helpers/helpers';
import combi_calc                    from 'components/helpers/combinations';
import moment                        from 'moment-timezone';
import app                           from 'components/core/application';
import maybe                         from 'components/helpers/maybe';

const betslipHelpers = {
    mapModelValues: function (bet, taxFees, lastBetStake=null, isEachWay=false, allowedMultiples=[], isInMultiples=false) {
        var data = _.pick(bet, 'idRace', 'idFreebet', 'idRunner', 'relatedIdRunner', 'relatedIdRace', 'h2hOpponents', 'h2hOpponentsNames', 'h2hOpponentsArray', 'programNumber', 'name', 'title', 'raceNumber', 'postTime', 'placeOddsFactor', 'defaultUnitStake', 'silkUrl', 'postPosition');
        var partsItem = {};
        data.idFreebet = data.idFreebet || 0;
        data.stamp = moment().unix();

        data.postTime = moment.unix(data.postTime).tz(user.timeZone()).format('LT');

        //remap properties to be in line with API prop naming
        data.oddsPlc = maybe.of(bet).mapDotProp('odds.FXP').orElse(bet.fixedOddsPlace).join(); //bet.fixedOddsPlace for H2H
        data.oddsWin = maybe.of(bet).mapDotProp('odds.FXW').orElse(bet.fixedOddsWin).join(); //bet.fixedOddsWin for H2H
        data.oddsPrc = maybe.of(bet).mapDotProp('odds.PRC').orElse(0).join();

        data.uid = null;
        data.stable = bet.stable;
        data.country = bet.country;
        data.selectedOdds = bet.selectedOdds;

        if(data.selectedOdds) data.selectedOdds.odds_f = data.selectedOdds.odds ? helpers.formatOdds(data.selectedOdds.odds, user.data.oddsFormat) : null;

        data.isHeadToHead = bet.isHeadToHead;
        data.isSpecialBet = bet.isSpecialBet;

        //used to mark buttons as selected
        data.isSelected = true;

        if (bet.betCategory) data.category = bet.betCategory;

        if (bet.betType) partsItem.market = bet.betType;

        //pass toteCurrency as model attribute to be used when user changes stake
        if(data.category === 'TOT' && bet.toteCurrency) data.toteCurrency = bet.toteCurrency;

        partsItem.odds_f = helpers.formatOdds(partsItem.market === 'WIN' || partsItem.market === 'WP' ? data.oddsWin: data.oddsPlc, user.data.oddsFormat);

        //use stake same as was used for the last added bet
        if(!bet.unitStake && lastBetStake) bet.unitStake = lastBetStake;

        if (bet.unitStake && parseFloat(bet.unitStake) > 0) {
            partsItem.stake = {
                amount: 1,
                unit: bet.unitStake, //bet.defaultUnitStake;  //do not set defaultUnitStake if user did not enter unit value
                unit_f: helpers.formatMoney(bet.unitStake, bet.unitStake % 1 > 0 ? 2 : 0),
                currency: data.category === 'TOT' ? bet.toteCurrency : user.data.currency
            };

            if(helpers.whatDecimalSeparator() === '.') partsItem.stake.unit_f = partsItem.stake.unit_f.replace(/\,/g, '.');
        }

        //check if select with options for BOK, TOK and FXD should be shown
        data.odds_select = {
            options: this.getCategoryOptions(bet, data)
        };
        data.odds_select.show_options = maybe.of(data).mapDotProp('odds_select.options').orElse([]).join().length > 1;

        data.parts = [partsItem];

        //handle win/place bets
        if (partsItem.market === 'WP') {
            partsItem.market = 'WIN';

            let partsItem2 = helpers.deepCopy(partsItem);
            partsItem2.market = 'PLC';
            partsItem2.odds = data.oddsPlc;
            partsItem2.odds_f = helpers.formatOdds(data.oddsPlc, user.data.oddsFormat);

            data.parts = [partsItem, partsItem2];
        } else {
            //add second part for FXD or BOK that is marked as E/W. For betType E/W it is added in the right above
            if((data.category === 'FXD' || isEachWay) && data.parts.length === 1 && partsItem.market === 'WIN') {
                let partsItem2 = isEachWay ? helpers.deepCopy(partsItem) : {};
                partsItem2.market = 'PLC';
                partsItem2.odds = data.oddsPlc;
                data.parts.push(partsItem2);
            }
        }

        data.allowedMultiples = allowedMultiples;
        data.inMultiples = data.activeMultiplesChBx = allowedMultiples.indexOf(bet.betCategory) > -1 ? isInMultiples : false;

        data.winnings = this.calculateWinnings(data);
        data.show_odds = this.showOdds(data);
        if (data.show_odds) partsItem.odds = data.show_odds;

        data.isEachWay = isEachWay;
        data.showEachWay = data.isEachWay || this.showEachWay(data, bet.event, {[partsItem.market]: partsItem.market}, this.getBetTypes(bet));
        data.spEvent = (bet.event && (bet.event.spEvent === true || parseInt(bet.event.spEvent, 10) === 1)) ? true : false;

        //calculate tax for Germans
        //if bet has unit, it will be placed as a separate bet event though it is included in multiples
        data.taxRate = (_.contains(['BOK'], data.category)) ? taxFees : bet.taxRateV2;

        data.tax_data = this.getTax(data);

        //required to display silk for bets in betslip
        data.event = _.pick(bet.event, 'isAntePost', 'saddleColours', 'raceType', 'usSaddleColours', 'rule4');

        data.raceNumberLabel = helpers.getTitle(bet.race ? bet.race.raceTitle : bet.raceTitle, data.raceNumber);

        data.eachWayLabel = this.composeEachWayLabel(bet, false);

        return data;
    },

    composeEachWayLabel: function(fixedModelAttrs, isH2H, category=null) {
        let eachWayLabel = null;
        //H2H has no E/W
        if (!isH2H && fixedModelAttrs.betType === 'WIN' && _.contains(fixedModelAttrs.betTypesV2[category || fixedModelAttrs.betCategory], 'WP')) {
            if(fixedModelAttrs.spEvent) {
                eachWayLabel = '1/' + fixedModelAttrs.placeOddsFactor + ' - ' + _.range(1, fixedModelAttrs.placesNum + 1).join(',');
            }
        }

        return eachWayLabel;
    },

    getBetTypes: function(bet) {
        var available_types = {};
        _.each(bet.betTypesV2, function(value, key) {
            available_types[key] = {};
            _.each(value, function(category) {
                available_types[key][category] = category;
            });
        });

        return available_types;
    },

    //allow adding to multiples only if all bets in betslip are of same market and current bet has same market too
    //0 markets means it is the first bet so can be checked for multiples. 1 market means that all bets are of the same market. Check if current bet has the same market too and mark as for multiples.
    //also add to Multiples even if there are more markets but there are also 2 or more bets for each of them and they are marked for multiples
    isInMultiples: function(bet, markets) {
        //var winningMarket = Object.keys(markets).reduce(function(a, b){ return markets[a] > markets[b] ? a : b });
        return (_.size(markets) === 0 || markets[bet.betType]) ? true : false;
    },

    //allow only multiples if it is enabled for the bet category
    //multiplesFxd and multiplesBok can be either true/false or 0/1
    allowedMultiples: function (bet) {
        let multiples = [];
        if(bet.multiplesFxd) multiples.push('FXD');
        if(bet.multiplesBok) multiples.push('BOK');
        return multiples;
    },

    /**
     * @method includeInMultiples
     *  @descriptions
     *  Check that all bets included in multiples have the same market
     */
    getMarkets: function(all_bets) {
        var markets = {};
        _.each(all_bets, function(bet) {
            if(bet.parts && bet.parts[0] && bet.inMultiples) markets[bet.parts[0].market] = markets[bet.parts[0].market] + 1 || 1;
        });

       return markets;
    },

    getMarketsForMultiples: function(all_bets) {
        var markets = {};
        _.each(all_bets, function(bet) {
            if(bet.parts && bet.parts[0] && bet.inMultiples) markets[bet.parts[0].market] = bet.inMultiples;
        });

        return markets;
    },

    isEachWay: function(all_bets, new_bet) {
        var betType = null;
        if(new_bet.betType) {
            betType = new_bet.betType === 'WP' ? 'WIN' : new_bet.betType; //reset betType to WIN to stay inline with logic - WP bets are WIN bets.
        } else {
            var parts = maybe.of(new_bet).mapDotProp('attributes.parts').orElse(null).join();
            betType = parts && parts[0] ? parts[0].market : null;
        }

        //if no e/w available, return false;
        if(!this.showEachWay({}, {}, {[betType]: betType}, this.getBetTypes(new_bet))) return false;

        //this is the case when there are no bets in betlsip and we are about to add the first one with eachWay flag.
        var isEachWay = (all_bets.length === 0 && new_bet.betType === 'WP') ? true : false;

        _.each(all_bets, function (bet) {
            if (bet.inMultiples && bet.showEachWay) isEachWay = bet.isEachWay;
        });

        return isEachWay;
    },

    getCategoryOptions(bet, data) {
        var odds = this.mapOdds(bet);
        var options = [];

        //newly created bet has no parts and market is taken form the bet.betType
        //saved bets have their market in parts
        var market = (bet && bet.betType) ?  bet.betType : (data.parts && data.parts[0] && data.parts[0].market) ? data.parts[0].market : null;

        if (maybe.of(bet).mapDotProp('betTypes.normal.BOK.WIN').join()) {
            options.push({
                key: 'BOK',
                asterisk: true,
                value: odds.oddsPrice !== 0 ? helpers.formatOdds(odds.oddsPrice, user.data.oddsFormat) : null,
                label: app.polyglot.t('label_starting_price_short'),
                category: 'BOK',
                active: 'BOK' === data.category
            });
        }

        // NOTE: TOT is not added to betslip !!!
        //if (maybe.of(bet).mapDotProp('betTypes.normal.TOT.WIN').join()) {
        //    options.push({
        //        key: 'TOT',
        //        asterisk: true,
        //        value: odds.oddsPrice !== 0 ? helpers.formatOdds(odds.oddsPrice, user.data.oddsFormat) : null,
        //        title: app.polyglot.t('label_bet_category_TOT'),
        //        category: 'TOT',
        //        active: 'TOT' === data.category
        //    });
        //}

        //show fixed odds option for WIN market only
        if (maybe.of(bet).mapDotProp('betTypes.normal.FXD.WIN').join() && market === 'WIN') {
            options.push({
                key: 'FXD',
                asterisk: false,
                value: odds.oddsWin !== 0 ? helpers.formatOdds(odds.oddsWin, user.data.oddsFormat) : null,
                oddsPlc: data.oddsPlc,
                oddsWin: data.oddsWin,
                label: app.polyglot.t('label_price_short'),
                category: 'FXD',
                active: 'FXD' === data.category
            });
        }

        return options.length > 0 ? options : null;
    },

    mapOdds: function(bet) {
        return {
            oddsPlc: parseFloat(bet.oddsPlc || maybe.of(bet).mapDotProp('odds.FXP').orElse(bet.fixedOddsPlace).join()),//bet.fixedOddsPlace for H2H
            oddsPrice: parseFloat(bet.oddsPrice || maybe.of(bet).mapDotProp('odds.PRC').orElse(0).join()),
            oddsWin: parseFloat(bet.oddsWin || maybe.of(bet).mapDotProp('odds.FXW').orElse(bet.fixedOddsWin).join())//bet.fixedOddsWin for H2H
        };
    },

    calculateWinnings: function (data) {
        if (!_.isArray(data)) data = [data];
        var total = 0;
        var isCategoryFixed = false;

        _.each(data, function (value) {
            isCategoryFixed = value.category === 'FXD';
            //calculate for FXD bet category only and when unit available
            if (isCategoryFixed) {
                if (value.isMultiple) {
                    var result;
                    var unit = maybe.of(value).mapDotProp('stake.unit').join();
                    var market = value.market = value.isEachWay ? 'WP' : 'WIN';

                    if (unit) {
                        if (market === 'WIN') result = unit * value.odds;
                        if (market === 'WP') result = (unit * value.odds) * 2;
                        total = total + result;
                    }

                } else {
                    _.each(value.parts, function (part) {
                        var result;
                        var unit = maybe.of(part).mapDotProp('stake.unit').join();
                        var market = part.market;

                        if (unit) {
                            if (market === 'WIN') result = unit * value.oddsWin;

                            if (market === 'PLC') result = unit * value.oddsPlc;

                            if (market === 'WP') {
                                let ret = unit * value.oddsWin;
                                if (value.spEvent) {
                                    ret += unit * ((value.oddsWin - 1) / value.placeOddsFactor + 1);
                                } else {
                                    ret += unit * value.oddsPlc;
                                }

                                result = ret;
                            }

                            total = total + result;
                        }
                    });
                }
            }
        });

        return isCategoryFixed ? helpers.formatMoney(total, 2, user.data.currency, true, true) : null;
    },

    showOdds: function (data, response = null) {
        if (data.category !== 'FXD') return null;

        if (response) {
            var runner = _.find(response.details.runners, function (runner) {
                return parseInt(runner.id, 10) === parseInt(data.idRunner, 10);
            });

            //check if WIN market available
            var win = _.find(data.parts, function(part) {
                return part.market === 'WIN';
            });
            return win ? runner.oddsWin : runner.oddsPlc;
        } else {
            //check if WIN market available
            var win = _.find(data.parts, function(part) {
                return part.market === 'WIN';
            });
            return win ? data.oddsWin : data.oddsPlc;
        }
    },

    calculateTotalStake: function (data, formatted=false, with_tax=false) {
        if (!_.isArray(data)) data = [data];
        var result;
        var total_units = 0;

        _.each(data, function (value) {
            _.each(value.parts, function (part) {
                if (part.stake && part.stake.unit) {
                    total_units += parseFloat(part.stake.unit);
                }
            });

            //multiples - they don't have parts
            //unit needs to be multiplied by 'amount' value which stands for number of bets
            //no need to multiplied by 2 in case of eachWay because value.stake.amount is already multiplied when eachWay activated
            if(value.stake && value.stake.unit) {
                total_units += parseFloat(value.stake.unit) * parseInt(value.stake.amount, 10);
            }
        });

        if(with_tax) total_units += this.calculateTotalTaxAmount(data);

        result = (total_units * 100) / 100; //Math.round((total_units * 100) / 100);

        return formatted ? helpers.formatMoney(result, result % 1 > 0 ? 2 : 0, user.data.currency, true, true) : result;
    },

    calculateTotalbetCount: function(data) {
        if (!_.isArray(data)) data = [data];
        var total_bets = 0;

        var grouped_stables = _.groupBy(data, 'stable');
        _.each(grouped_stables, function(stable, key) {
            //if it is a stable horse, get country and if it is 'US' - leave the zero index entry only
            if(key !== 'false' && stable[0] && stable[0].country === 'US') stable.splice(1);
            _.each(stable, function (value) {
                _.each(value.parts, function (part) {
                    if (part.stake && part.stake.unit) {
                        total_bets += 1;
                    }
                });

                //multiples - they don't have parts
                //unit needs to be multiplied by 'amount' value which stands for number of bets
                //all multiplied by 2 in case of eachWay
                if(value.stake && value.stake.unit) {
                    total_bets += parseInt(value.stake.amount, 10);
                }
            });
        });

        return total_bets;
    },

    calculateTax: function (totalStake, bet, taxRate) {
        var taxFee;

        var categoryTaxRate;

        categoryTaxRate = taxRate[bet.category];

        // freebets
        if (bet.idFreebet) {
            categoryTaxRate = 0;
        }

        var res = {
            taxAmount: 0, // tax amount which is due to the authorities (right now just 5% German tax is considered)
            taxRate: 0, // tax fee set for this race/bet category
            taxSubventionAmount: 0, // subvention of RaceBets for the tax amount
            taxSubventionRate: 0, // subvention rate
            totalCost: totalStake, // total cost of the betslip
            totalStake: totalStake
        };

        if (user.data.taxFees && user.data.taxFees.taxable) {
            // tax amount
            res.taxRate = 5;
            res.taxAmount = Math.floor(totalStake * res.taxRate) / 100;

            var userDeduction = user.data.taxFees.deductions[bet.category] ? user.data.taxFees.deductions[bet.category] : 0;
            taxFee = Math.floor(totalStake * categoryTaxRate / 100 * (100 - userDeduction)) / 100;

            res.taxSubventionAmount = res.taxAmount - taxFee;
            if (res.taxSubventionAmount > 0) {
                res.taxSubventionRate = (res.taxRate - (categoryTaxRate * (100 - userDeduction) / 100));
            }

            res.totalCost = taxFee ? totalStake + taxFee : totalStake;
        }

        return res;
    },

    calculateTotalTaxAmount: function(data, formatted=false) {
        var total = 0;
        _.each(data, function(bet) {
            var amount = maybe.of(bet).mapDotProp('tax_data.taxAmount').join();
            total = amount ? total + amount : total;
        });

        total = (total * 100) / 100; //Math.round((total_units * 100) / 100);

        return formatted ? helpers.formatMoney(total, total % 1 > 0 ? 2 : 0, user.data.currency, true, true) : total;
    },

    mapAdditionalData: function (bet, response) {
        var part_markets = {};
        var available_types = {};
        var race;
        var event;

        race = this.getRace(bet.idRace, response);

        if (race) {
            if (race.time) bet.postTime = moment.unix(race.time).tz(user.timeZone()).format('LT');

            //find event title
            if (race.idEvent) {
                event = this.getEvent(race.idEvent, response);

                if(event) {
                    if (event.title) bet.title = event.title;
                    if (event.toteCurrency) bet.toteCurrency = event.toteCurrency;
                    bet.country = event.country;

                    //needed to show silk
                    bet.event = {
                        isAntePost: event.isAntePost,
                        rule4: event.rule4,
                        raceType: race.raceType,
                        usSaddleColours: _.contains(['US', 'CA'], event.country),
                        saddleColours: ''
                    }
                }

                var typesAndTax = this.getAvailableBetTypes(race.betCategories);
                available_types = typesAndTax.available_types;

                bet.taxRate = typesAndTax.taxRate;

                //format odds and stake
                _.each(bet.parts, (part, index) => {
                    //is used in some functions that operate on initial data structure (where parts are not yet present)
                    if(index === 0) bet.betType = part.market;
                    part_markets[part.market] = part.market;
                    if(part.odds) part.odds_f = helpers.formatOdds(part.odds, user.data.oddsFormat);
                    if(part.stake && part.stake.unit) {
                        part.stake.unit_f = helpers.formatMoney(part.stake.unit, part.stake.unit % 1 > 0 ? 2 : 0);
                        if(helpers.whatDecimalSeparator() === '.') part.stake.unit_f = part.stake.unit_f.replace(/\,/g, '.');

                        //check if currency is same as user currency. On BetSafe the default currency is GB, but when user logges in, he may have a different currency and currency for all bets in betslip should be set to user currency
                        if(bet.category !== 'TOT') {
                            var user_currency = maybe.of(user).mapDotProp('data.currency').orElse(null).join();
                            if (user_currency) part.stake.currency = user_currency;
                        }
                    }
                });

                //calculate tax for Germans
                bet.tax_data = this.getTax(bet);

                //whether to show eachWay checkbox checked
                //bet.isEachWay = bet.parts.length > 1;

                //show/hide each way
                bet.showEachWay = this.showEachWay(bet, event, part_markets, available_types);
                bet.spEvent = (event && (event.spEvent === true || parseInt(event.spEvent, 10) === 1)) ? true : false;
                bet.placeOddsFactor = race.placeOddsFactor;
                bet.placesNum = race.placesNum;
                bet.eachWayLabel = this.composeEachWayLabel(this.getBetForEWTerms(bet, race.betCategories), false, bet.category);
                //todo: backend should return additional data for label to be built
                //if(bet.showEachWay) bet.spEachWayLabel = this.buildSpEachWayLabel(event);
            }
        }

        //find runner name
        if (bet.idRunner) {
            var runner = _.find(response.details.runners, function (runner) {
                return parseInt(runner.id, 10) === parseInt(bet.idRunner, 10);
            });

            if(runner) {
                if (runner.name) bet.name = runner.name;
                if (runner.oddsWin) bet.oddsWin = runner.oddsWin;
                if (runner.oddsPlc) bet.oddsPlc = runner.oddsPlc;
                if (runner.oddsPrice) bet.oddsPrice = runner.oddsPrice;
                if (runner.postPosition) bet.postPosition = runner.postPosition;
                if (runner.silk) bet.silkUrl = helpers.generateSilkUrl(runner.silk);

                //for h2h/h3h races
                bet.relatedIdRace = maybe.of(race).mapDotProp('relatedRace.id').orElse(null).join();
                if(runner.idRelated) bet.relatedIdRunner = runner.idRelated;
                if(runner.opponents) bet.h2hOpponents = runner.opponents;

                //get related runner name for h2h
                if(runner.opponents && _.isArray(runner.opponents)) {
                    bet.h2hOpponentsNames = runner.opponents.map(function(value) {
                        return value.name;
                    }).join(' vs ');

                    bet.h2hOpponentsArray = runner.opponents.map(function(value) {
                        return value.relatedIdRunner;
                    });
                }

                bet.stable = runner.stable;
            }
        }

        //check if select with options for BOK, TOK and FXD should be shown
        bet.odds_select = {
            options: this.getCategoryOptions(_.extend(helpers.deepCopy(runner), race), bet)
        };
        //bet.odds_select.na = bet.odds_select.options.length < 1;
        //bet.odds_select.single = bet.odds_select.options.length === 1;
        bet.odds_select.show_options = bet.odds_select.options.length > 1;

        bet.winnings = this.calculateWinnings(bet);
        bet.show_odds = this.showOdds(bet, response);

        bet.status = this.getUpdateStatus(bet, event, race, runner);

        bet.allowedMultiples = this.allowedMultiples({multiplesBok: event.accumulationBets, multiplesFxd: event.multiplesFxd});

        //calculate tax for german customers only and for no multiples
        //if(user.isGerman() && !bet.inMultiples) bet.tax_data = this.calculateTax(this.calculateTotalStake(bet), bet, taxRate);
        return bet;
    },

    //Check if addToMultiples checkbox should be active
    checkMultiplesChBx: function(bets) {
        _.each(bets, bet => {
            bet.activeMultiplesChBx = bet.allowedMultiples.indexOf(bet.category) > -1 ? this.isInMultiples(bet, this.getMarkets(bets)) : false;
        })
    },

    getBetForEWTerms: function(bet, betCategories) {
        let betTypesV2 = {};
        _.each(betCategories, function(item) {
            betTypesV2[item.category] = item.markets
        });

        bet.betTypesV2 = betTypesV2;
        return helpers.deepCopy(bet);
    },

    //if bet has unit, it will be placed as a separate bet event though it is included in multiples
    getTax: function(bet) {
        var unit = (bet.parts &&  bet.parts[0] && bet.parts[0].stake && bet.parts[0].stake.unit) ? bet.parts[0].stake.unit : 0;

        if(bet.isEachWay) unit = unit * 2;

        if(user.isGerman() && unit > 0) {
            if(bet.taxRate[bet.category]) return this.calculateTax(unit, bet, bet.taxRate[bet.category]);
        }

        return 0;
    },

    allAreEachWay: function(bets) {
        var each_way = _.where(bets, {showEachWay: true});
        return _.isArray(each_way) ? each_way.length === bets.length : false;
    },

    /**
     * @method getUpdateStatus
     * @description
     *  Check update status and compose status messages
     *  //todo: add all possible status checks here
     *
     * @param bet
     * @param event
     * @param race
     * @param response
     * @returns {{}}
     */
    getUpdateStatus: function(bet, event, race, runner) {
        var status = {
            update: false,
            message: '',
            accept: false,
            remove: false
        };

        race.status = race.status || race.raceStatus;

        var scratched = runner.scratched === '1' || runner.scratched === true;

        if (runner && runner.newOdds) {
            status.update = true;
            status.accept = true;
            status.remove = true;
            status.message =  runner.new_odds_value ? app.polyglot.t('msg_fixed_odds_changed') + ' ' + app.interpolate('msg_new_win_fixed_odds', {odds: runner.new_odds_value}) : app.polyglot.t('odds_changed_betslip_error');
        }

        if(['FNL', 'TMP'].indexOf(race.status) > -1 || scratched) {
            status.update = true;
            status.message = app.polyglot.t(scratched ? 'label_scratched' : 'msg_race_fnl');
            status.remove = true;
        }

        if(race.status === 'STR') {
            status.update = true;
            status.message = app.polyglot.t('msg_race_started');
            status.remove = true;
        }

        if(race.status === 'CNC') {
            status.update = true;
            status.message = app.polyglot.t('label_race_canceled');
            status.remove = true;
        }

        return status;
    },

    getFullUpdateStatus: function(bets) {
        var full_status = [];
        _.each(bets, function(bet) {
            if(bet.status && bet.status.update) full_status.push({title: bet.title, message: bet.status.message});
        });

        return full_status;
    },

    getRace: function(idRace, response) {
        return _.find(response.details.races, function (race) {
            return parseInt(race.id, 10) === parseInt(idRace, 10);
        });
    },

    getEvent: function(idEvent, response) {
        return _.find(response.details.events, function (event) {
            return parseInt(event.id, 10) === parseInt(idEvent, 10);
        });
    },

    getAvailableBetTypes: function(betCategories) {
        var data = {
            available_types: {},
            taxRate: {}
        };
        _.each(betCategories, function(value) {
            //Assume that tax is the same for every betCategories. Even though it is an array, the zero index value will be used for tax calculation
            data.available_types[value.category] = {};
            data.taxRate[value.category] = value.taxRate;
            _.each(value.markets, function(market) {
                data.available_types[value.category][market] = market;
            });
        });

        return data;
    },

    showEachWay: function(bet, event, part_markets, available_types) {
        var FXD_WIN = maybe.of(available_types).mapDotProp('FXD.WIN').join();
        var BOK_WIN = maybe.of(available_types).mapDotProp('BOK.WIN').join();
        var FXD_WP = maybe.of(available_types).mapDotProp('FXD.WP').join();
        var BOK_WP = maybe.of(available_types).mapDotProp('BOK.WP').join();
        return (part_markets.WIN && (FXD_WIN || BOK_WIN) && (FXD_WP || BOK_WP)) ? true : false
    },

    buildSpEachWayLabel: function(event) {
        var spEvent = (event && (event.spEvent === true || parseInt(event.spEvent, 10) === 1)) ? true : false;
        var eachWayOdds;
        var eachWayOddsLabel;
        var eachWayLabel;

        if(spEvent) {
            eachWayOdds = ((parseFloat(event.fixedOddsWin) - 1) / parseFloat(event.placeOddsFactor)) + 1;
            eachWayOddsLabel = helpers.formatOdds(this.data.eachWayOdds, user.data.oddsFormat);
            eachWayLabel = '1/' + event.placeOddsFactor + ' - ' + _.range(1, event.placesNum + 1).join(',');
        }

        return eachWayLabel;
    },

    /**
     * - label_system_2X - Doubles
     * - label_system_3C - Trixie
     * - label_system_3F - Patent
     * - label_system_3X - Trebles
     * - label_system_4C - Yankee
     * - label_system_4F - Lucky 15
     * - label_system_4X - 4 Folds
     * - label_system_5X - 5 Folds
     * - label_system_6X - 6 Folds
     * - label_system_7X - 7 Folds
     * - label_system_8X - 8 Folds
     * - label_system_5C - Canadian
     * - label_system_6C - Heinz
     * - label_system_5F - Lucky 31
     * - label_system_6F - Lucky 63
     * - label_system_7C - Super Heinz
     * - label_system_8C - Goliath
     */

    oddsCalculationRules:  {
        label_system_2X: { //Doubles
            alg: {
                1: 'doubleBets'
            }
        },
        label_system_3C: { //Trixie
            alg: {
                1: 'doubleBets', //3 double bets
                2: 'trebleBets'  //1 treble bet
            }
        },
        label_system_3F: { //Patent
            alg: {
                1: 'singleBets', //3 single bets
                2: 'doubleBets', //3 double bets
                3: 'trebleBets'  //1 treble bet
            }
        },
        label_system_3X: { //Trebles
            alg: {
                1: 'trebleBets' //1 treble bet
            }
        },
        label_system_4C: { //Yankee
            alg: {
                1: 'doubleBets', //3 double bets
                2: 'trebleBets', //4 treble bets
                3: 'fold_4' //1 fourfold accumulator bet
            }
        },
        label_system_4F: { //Lucky 15
            alg: {
                1: 'singleBets', //4 single bets
                2: 'doubleBets', //6 double bets
                3: 'trebleBets', //4 treble bets
                4: 'fold_4' //1 fourfold accumulator bet
            }
        },
        label_system_4X: { //4 Folds
            alg: {
                1: 'fold_4' //1 fourfold accumulator bet
            }
        },
        label_system_5X: { //5 Folds
            alg: {
                1: 'fold_5' //1 fourfold accumulator bet
            }
        },
        label_system_6X: { //6 Folds
            alg: {
                1: 'fold_6' //1 fourfold accumulator bet
            }
        },
        label_system_7X: { //7 Folds
            alg: {
                1: 'fold_7' //1 fourfold accumulator bet
            }
        },
        label_system_8X: { //8 Folds
            alg: {
                1: 'fold_8' //1 fourfold accumulator bet
            }
        },
        label_system_5C: { //Canadian
            alg: {
                1: 'doubleBets', //10 double bets
                2: 'trebleBets', //10 treble bets
                3: 'fold_4', //5 fourfold accumulator bets
                4: 'fold_5' //1 fivefold accumulator bet
            }
        },
        label_system_5F: { //Lucky 31
            alg: {
                1: 'singleBets', //5 single bets
                2: 'doubleBets', //10 double bets
                3: 'trebleBets', //10 treble bets
                4: 'fold_4', //5 fourfold accumulator bets
                5: 'fold_5' //1 fivefold accumulator bet
            }
        },
        label_system_6C: { //Heinz
            alg: {
                1: 'doubleBets', //15 double bets
                2: 'trebleBets', //20 treble bets
                3: 'fold_4', //15 fourfold accumulator bets
                4: 'fold_5', //6 fivefold accumulator bet
                6: 'fold_6' //1 sixfold accumulator bet
            }
        },
        label_system_6F: { //Lucky 63
            alg: {
                1: 'singleBets', //6 single bets
                2: 'doubleBets', //15 double bets
                3: 'trebleBets', //20 treble bets
                4: 'fold_4', //15 fourfold accumulator bets
                5: 'fold_5', //6 fivefold accumulator bet
                6: 'fold_6' //1 sixfold accumulator bet
            }
        },
        label_system_7C: { //Super Heinz
            alg: {
                1: 'doubleBets', //21 double bets
                2: 'trebleBets', //35 treble bets
                3: 'fold_4', //35 fourfold accumulator bets
                4: 'fold_5', //21 fivefold accumulator bet
                6: 'fold_6', //7 sixfold accumulator bet
                7: 'fold_7' //1 sevenfold accumulator bet
            }
        },
        label_system_8C: { //Goliath
            alg: {
                1: 'doubleBets', //28 double bets
                2: 'trebleBets', //56 treble bets
                3: 'fold_4', //70 fourfold accumulator bets
                4: 'fold_5', //56 fivefold accumulator bet
                6: 'fold_6', //28 sixfold accumulator bet
                7: 'fold_7', //8 sevenfold accumulator bet
                8: 'fold_8' //1 eightfold accumulator bet
            }
        }
    },

    /**
     * @method singleBets
     * @description
     *  Add odds for all bets
     *
     * @param total
     */
    singleBets: function(bets) {
        var total = 0;
        _.each(bets, function (bet) {
            //access values in parts under 0 index to avoid adding same values twice in case of each way
            if(bet.parts && bet.parts[0]) total += bet.parts[0].odds;
        });

        return total;
    },

    /**
     * @method doubleBets
     * @description
     *  Multiply odds for each double (combination of 2) and then add them
     *
     * @param total
     */
    doubleBets: function(bets) {
        var combinations = combi_calc.k_combinations(bets, 2);
        var total = 0;

        _.each(combinations, function (doubles) {
            //access values in parts under 0 index to avoid adding same values twice in case of each way
            total += doubles[0].parts[0].odds * doubles[1].parts[0].odds;
        });

        return total;
    },

    /**
     * @method trebleBets
     * @description
     *  1. Get all possible combinations
     *  2. Multiple odds for combinations and add them to total
     *
     * @param total
     */
    trebleBets: function(bets) {
        var combinations = combi_calc.k_combinations(bets, 3);
        var total = 0;

        _.each(combinations, function (trebles) {
            //access values in parts under 0 index to avoid adding same values twice in case of each way
            total += trebles[0].parts[0].odds * trebles[1].parts[0].odds * trebles[2].parts[0].odds;
        });

        return total;
    },

    fold_4: function(bets) {
        return this.fold(bets, 4);
    },

    fold_5: function(bets) {
        return this.fold(bets, 5);
    },

    fold_6: function(bets) {
        return this.fold(bets, 6);
    },

    fold_7: function(bets) {
        return this.fold(bets, 7);
    },

    fold_8: function(bets) {
        return this.fold(bets, 8);
    },

    fold: function(bets, n) {
        var combinations = combi_calc.k_combinations(bets, n);
        var total = 0;

        _.each(combinations, function (trebles) {
            var set_total = 0;
            _.each(trebles, function(bet) {
                //access values in parts under 0 index to avoid adding same values twice in case of each way
                set_total = set_total === 0 ? bet.parts[0].odds : set_total * bet.parts[0].odds;

            });
            total += set_total;
        });

        return total;
    },

    calculateOdds: function(multiple, bets) {
        var rules = this.oddsCalculationRules[multiple.id];
        var total = 0;

        if(rules) {
            _.each(rules.alg, (function_name) => {
                total += helpers.executeFunctionByName(function_name, this, bets);
            });
        }

        return total;
    },

    checkOddsForMultiples: function(bets, multiples) {
        if(multiples.length === 0) return;

        var fixed_in_multiples = _.filter(bets, function(bet) {
            return bet.inMultiples && bet.category === 'FXD';
        });

        _.each(multiples, (multiple) => {
            var odds = this.calculateOdds(multiple, fixed_in_multiples);
            if(odds) multiple.category = 'FXD';
            multiple.odds = odds || null;
            multiple.odds_f = odds ? helpers.formatOdds(odds, 'base1') : null;
        });

        return multiples;
    },

    getLastUnit: function(bets) {
        var unit = null;
        var lastBet = _.last(bets);
        if(lastBet && lastBet.parts && lastBet.parts[0] && lastBet.parts[0].stake) {
            unit = lastBet.parts[0].stake.unit ? lastBet.parts[0].stake.unit : null;
        }
        return unit;
    },

    getBetTypeWithStakes: function(category, market, betTypes) {
        var type_stake = null;

        if (category === 'TOT') {
            type_stake = helpers.isPickBetType(market) ? betTypes.pick[category][market] : betTypes.normal[category][market];
        } else {
            const minUnitStake = helpers.getMinStakeBOKFXD(user.data.currencySettings, category, market);
            type_stake = [minUnitStake, minUnitStake];
        }

        return type_stake;
    },

    getToteStepSize: function (country) {
        var toteStepSizes = { 'DE': 0.50, 'MT': 1.00 };
        return (toteStepSizes[country]) ? toteStepSizes[country] : 0.01;

    },

    /**
     * @method checkUnitStake
     * @description
     *      Check if unitStake is valid for a given betType
     */
    checkUnitStake: function (category, market, betTypes, currency, country, unitStake, numBets=1) {
        var errors = [];
        var stake_restrictions = this.getBetTypeWithStakes(category, market, betTypes);

        var minUnitStake = stake_restrictions[0];
        var minTotalStake = stake_restrictions[1];


        // adjust different min stake for scandinavia
        if (category === 'BOK' && market === 'TRI' && _.contains(['DK', 'FI', 'NO', 'SE'], country)) {
            minUnitStake = user.data.currencySettings.minStakeBOKTRISE;
        }

        var minToteStepSize = this.getToteStepSize(country);

        // min unit stake
        if (!unitStake || unitStake < minUnitStake) {
            errors.push({name: 'unitStake', message: app.interpolate('error_min_stake', {amount: helpers.formatMoney(minUnitStake, 2, currency, true)})});
        }

        // min total stake for some tote bets
        //Since we do not add 'TOT' bets to betslip, we operate on one bet only. numBets defaults to 1.
        if (unitStake * numBets < minTotalStake) {
            errors.push({
                name: 'unitStake',
                message: app.interpolate('error_min_total_stake', {amount: helpers.formatMoney(minTotalStake, 2, currency, true)})
            });
        }

        // max stake for bookie bets
        if (category !== 'TOT') {
            var maxUnitStake = helpers.getMaxStakeBOKFXD(user.data.currencySettings, category, market);
            if (unitStake > maxUnitStake) {
                errors.push({name: 'unitStake', message: app.interpolate('error_max_stake', {amount: helpers.formatMoney(maxUnitStake, 2, currency, true)})});
            }
        }

        //todo: check if this is applicable for new betslip and quick bet
        // step size for US tote bets; unit stake under 1 USD has to be a multiplication of min stake
        //if (category === 'TOT' && country === 'US' && unitStake < 1 && (unitStake * 100) % (minUnitStake * 100) !== 0) {
        //    errors.push({name: 'betUnitStake', message: app.interpolate('error_step_size_unit_stake_invalid', {amount: helpers.formatMoney(minUnitStake, 2, currency, true)})});
        //}

        // step size for tote bets
        if (category === 'TOT' && (unitStake * 100) % (minToteStepSize * 100) !== 0) {
            errors.push({name: 'unitStake', message: app.interpolate('msg_step_size_invalid', {amount: helpers.formatMoney(minToteStepSize, 2, currency, true)})});
        }

        return errors;
    },

    getErrorMessage: function (attrs) {
        if (attrs.hasOwnProperty('errorCode')) {
            this.analyticsTagBetFailed(attrs.errorCode);
        }

        var toteError = function () {
            return {message: app.interpolate('msg_tote_error', {errorCode: attrs.errorCode})};
        };

        var toteBlocked = function () {
            return { message: app.interpolate('msg_tote_blocked_in_country', { ipCountry: app.polyglot.t(`countries.${user.data.ipCountry}`) })};
        };

        var insufficientFunds = function () {
            return {
                message: app.polyglot.t('msg_insufficient_funds'),
                triggerEvent: 'dialog:showdeposit',
                deposit: true
            };
        };

        var unitStake = function () {
            return {message: app.interpolate('error_min_stake', {amount: helpers.formatMoney(user.data.currencySettings.minStakeFXD, 2, user.data.currency, true)})};
        };

        var raceClosed = function () {
            return {message: app.polyglot.t('msg_race_closed')};
        };

        var raceStarted = function () {
            return {message: app.polyglot.t('msg_race_started')};
        };

        var limitReached = function () {
            return {message: app.polyglot.t('msg_limit_reached')};
        };

        var limitBetTypeReached = function() {
            return {message: app.polyglot.t('error_bet_type_limit_reached')};
        };
        

        var oddsDoNotMatch = function () {
            var first = app.polyglot.t('msg_fixed_odds_changed');
            var fixed = '';
            var place = '';
            var last = app.polyglot.t('msg_accept_new_fixed_odds');

            if (attrs.fixedOddsWin && attrs.fixedOddsWin > 0) {
                fixed = app.interpolate('msg_new_win_fixed_odds', {odds: helpers.formatOdds(parseFloat(attrs.fixedOddsWin), user.data.oddsFormat)}) + ' ';
            }
            if (attrs.fixedOddsPlace && attrs.fixedOddsPlace > 0) {
                place = app.interpolate('msg_new_place_fixed_odds', {odds: helpers.formatOdds(parseFloat(attrs.fixedOddsPlace), user.data.oddsFormat)}) + ' ';
            }

            return {message: first + ' ' + fixed + place + last, callback: true};
        };

        var restriction = function () {
            if (attrs.restrictionAmount && attrs.periodOfTime) {
                var message = app.interpolate((attrs.restrictionType === 'STK') ? 'msg_stake_limit' : 'msg_loss_limit', {
                    limitAmount: helpers.formatMoney(attrs.restrictionAmount, 2, attrs.currency),
                    period: attrs.periodOfTime
                });
                return {message: message};
            }

            return {message: app.polyglot.t('msg_error_stake_or_loss_limit_reached')};
        };

        var externalExclusion = function () {
            return {message: app.polyglot.t('msg_account_excluded_external')};
        };

        var externalExclusionServiceUnavailable = function () {
            return {message: app.polyglot.t('msg_account_excluded_external_service_unavailable')};
        };

        var fixedOddsOffline = function () {
            return {message: app.polyglot.t('msg_fixed_odds_offline')};
        };

        var toteErrorCombination = function () {
            return {message: app.polyglot.t('msg_tote_error_combination')}
        };

        var freeBetInvalid = function () {
            return {message: app.polyglot.t('error_free_bet_invalid')};
        };

        var freeBetOnePerRace = function () {
            return {message: app.polyglot.t('error_free_bet_one_per_race')};
        };

        var noEnhancedPlacesMarkets = function () {
            return {message: app.polyglot.t('error_no_enhanced_places_markets')};
        };

        var toteErrorNoTestAccounts = function () {
            return {message: app.polyglot.t('msg_tote_no_test_accounts')};
        };

        var poolBetClosed = function () {
            return {message: app.polyglot.t('msg_pool_bet_closed')};
        };

        var totBlockedCustomerCountry = function () {
            return {message: app.polyglot.t('msg_tote_blocked_customer_country')};
        };

        var scratchedHorse = function () {
            return {message: app.polyglot.t('msg_scratched_horse')};
        };

        var invalidStakeTote = function () {
            return {message: app.polyglot.t('msg_step_size_invalid_tote')};
        };

        var userNotVerified = function () {
            return {message: app.polyglot.t('error_account_not_verified')};
        };

        var betResponseRejected = function () {
            return {message: app.polyglot.t('error_bet_response_rejected')};
        };

        var newUnitStake = function () {
            return {
                message: app.interpolate('msg_new_unit_stake', {newUnitStake: helpers.formatMoney(attrs.newUnitStake, 2, attrs.currency)}),
                callback: true,
                cancelBtn: true
            }
        };

        var acceptWinOnly = function () {
            return {
                message: app.polyglot.t('error_accept_win_only'),
                callback: true,
                cancelBtn: true
              };
        };

        var cannotAcceptFXD = function () {
            return {
                message: app.polyglot.t('error_cannot_accept_FXD'),
                callback: true,
                cancelBtn: true
              };
        };

        var notAllowedFromUserCountry = function () {
            return {message: app.interpolate('msg_bet_not_accepted_in_users_country', {country: app.polyglot.t(`countries.${user.data.ipCountry}`)})};
        };

        var errorDepositRequired = function () {
            return {
                message: app.polyglot.t('msg_error_deposit_required'),
                triggerEvent: 'dialog:showdeposit',
                deposit: true
            };
        };

        var stakeExceedsWithdrawableBalance = function () {
            return {message: app.interpolate('msg_error_stake_exceeds_withdrawable_balance', {minOdds: helpers.formatOdds(1.5, user.data.oddsFormat)})};
        };

        var limitedAccessExpired = function () {
            return {message: app.polyglot.t('msg_limited_access_expired')};
        };

        var sessionInvalid = function () {
            return {
              message: app.polyglot.t('msg_session_expired'),
              triggerEvent: 'user:login:required'
            };
        }

        var defaultErrorMessage = function () {
            if (attrs && attrs.errorCode == 102) {
                let userLogData = {
                    currency: user.data.currency,
                    country: user.data.country,
                    ipCountry: user.data.ipCountry
                };

                let betLogData = {
                    betCategory: attrs.betCategory,
                    betslipType: attrs.betslipType,
                    betCurrency: attrs.currency,
                    betIdRace: attrs.idRace,
                    betRaceCountry: attrs.country
                };

                withScope(scope => {
                    scope.setExtra('user', userLogData);
                    scope.setExtra('bet', betLogData);

                    captureMessage('Error 102 - currency mismatch', 'error');
                });
            }
            return {message: app.interpolate('msg_unknown_error_with_errorcode', {errorCode: attrs ? attrs.errorCode : 'n/a'})};
        };

        var noBonusOnTOT = function () {
            return {
                message: app.polyglot.t('msg_error_bonus_not_allowed_on_tote'),
                triggerEvent: 'dialog:showdeposit',
                deposit: true
            };
        };

        var lugasOtherProvider = function () { 
              return {message: app.polyglot.t('error_lugas_other_provider')};
        }

        var lugasCoolingOff = function () { 
              return {message: app.polyglot.t('error_lugas_cooling_off')};
        }

        var codeMap = {
            62:  toteError,// general tote error
            113: toteError,// can not connect with easygate server
            114: toteError,// wrong easygate response
            115: toteError,// easygate returned error
            97:  toteBlocked,
            124: toteBlocked,// not allowed to play TOT from your country (ATG restrictions)
            101: sessionInvalid,
            103: insufficientFunds,// not enough money
            105: raceClosed,// xtc race closed
            106: raceStarted,// race not open
            107: limitReached,// stakes limit for this race is reached
            108: limitReached,// runner stake limit is reached
            109: limitReached,// runner payout limit is reached
            110: unitStake, // stake must be grater than x
            111: oddsDoNotMatch,// fixed odds win do not match
            112: oddsDoNotMatch,// fixed odds place do not match
            116: restriction,// customer restrictions error
            117: externalExclusion,// customer is self-excluded via external database (i.e. Oasis)
            118: fixedOddsOffline,// fixed odds are not active in this race (events_races.fixedOddsStatus != ON)
            119: externalExclusionServiceUnavailable,// self-exclusion can not be verified against external database (i.e. Oasis unavailable)
            120: toteErrorCombination,
            126: notAllowedFromUserCountry, // not allowed to bet on this event from user's country
            132: errorDepositRequired, // deposit required to use bonuses
            133: stakeExceedsWithdrawableBalance,// stake exceeds withdrawable balance
            135: freeBetInvalid,// invalid free bet
            137: freeBetInvalid,// invalid free bet - cannot be used on that race/horse
            138: freeBetInvalid,// invalid free bet - only one runner per free bet
            136: freeBetOnePerRace,// only one free bet per race
            140: newUnitStake,// maximum unit stake before price change
            142: noEnhancedPlacesMarkets,// no bonus customer excluded from enhanced place markets WP betting
            144: restriction,// customer restrictions error
            145: restriction,// customer restrictions error
            147: toteErrorNoTestAccounts, // test accounts not allowed to play tote in prod
            150: poolBetClosed, // pool bet closed
            151: scratchedHorse, // xtc horse was scratched while user selecting his bets
            152: invalidStakeTote, // xtc stake not divisible by min unit stake
            153: totBlockedCustomerCountry, // TOT blocked by customer country
            210: userNotVerified,// account not verified
            301: betResponseRejected,// rms bet not accepted
            310: betResponseRejected,// rms bet not accepted (auto decision)
            330: betResponseRejected,// Customer is not allowed to bet (noBetting property)
            335: notAllowedFromUserCountry, // not allowed to bet from user's country
            302: newUnitStake,// rms new unit stake
            304: newUnitStake,// rms new unit stake - when the same amount is resent with the same idRms
            306: acceptWinOnly,
            207: limitedAccessExpired,
            309: cannotAcceptFXD, // FXD cannot be accepted, offer SP instead
            345: limitBetTypeReached,
            714: noBonusOnTOT, // techsson - not allowed to use bonus on TOT 
            804: lugasOtherProvider, // Lugas - PARALLEL_GAMING_WITH_OTHER_PROVIDER
            805: lugasCoolingOff // Lugas - PARALLEL_GAMING_COOLING_OFF
        };

        var checkMessage = function(attrs) {
            var messages = [];

            if(attrs && attrs.errors) {
                _.each(attrs.errors, function(value) {
                    //for now we process only 'newUnitStake' error
                    if(value.newUnitStake) {
                        value.message = app.interpolate('msg_new_unit_stake', {newUnitStake: helpers.formatMoney(value.newUnitStake, 2, user.data.currency)});
                        messages.push(value);
                    } else if(value.message) {
                        messages.push(value);
                    }
                });
            }

            return messages.length > 0 ? messages : defaultErrorMessage();
        };

        if(attrs && attrs.errorCode) {
            return codeMap[attrs.errorCode] ? codeMap[attrs.errorCode]() : defaultErrorMessage();
        } else {
            return checkMessage(attrs);
        }
    },

    analyticsTagBetFailed: function (code) {
        app.trigger('bet:placed:failed', code);
    },

    noH2HinBetslip: function(fixedModel, allBets=[]) {
        const attrs = maybe.of(fixedModel).mapDotProp('attributes').join();

        // do not allow H2H in betslip if H2H multiples are not enabled
        if (!attrs.multiplesFxd && attrs.isHeadToHead === true) {
            return app.polyglot.t('msg_error_acc_bet_special_mix');
        }

        // head-to-head bets are only allowed as accumulator in betslip
        if (this.hasRelatedRace(allBets, attrs.idRace)
            || this.hasRace(allBets, attrs.relatedIdRace || '')
            || this.hasRelatedRunner(allBets, attrs.idRace, attrs.h2hOpponents || [])
            || this.hasRunner(allBets, attrs.relatedIdRunner || '')) {
            return app.polyglot.t('msg_error_acc_bet_special_same_leg');
        }

        return false;
    },

    // check if a head-to-head bet is already in betslip with the same parent race as the bet
    hasRelatedRace: function (allBets, idRace) {
        const id = idRace.toString();
        return allBets.some((bet) => {
            const relatedId = (bet.relatedIdRace || '').toString();
            return relatedId === id;
        });
    },

    // check if a bet is already in betslip with the same race as the parent of the head-to-head bet
    hasRace: function (allBets, idRace) {
        const id = idRace.toString();
        return allBets.some((bet) => {
            return bet.idRace.toString() === id;
        });
    },

    // check if opponent(s) who are part of another head-to-head are in the betslip
    hasRelatedRunner: function (allBets=[], idRace='', relatedRunners=[]) {
        const relatedIdRunners = relatedRunners.map((runner) => {
            return runner.relatedIdRunner;
        });
        return allBets.some((bet) => {
            // if the runner is from the same H2H race we want to replace the bet in the service
            // instead of showing and error, hence we check idRace differs
            return bet.idRace !== idRace && relatedIdRunners.includes(bet.relatedIdRunner);
        });
    },

    // check if runner is part of another head-to-head which is already in the betslip
    hasRunner: function (allBets=[], relatedIdRunner='') {
        return allBets.some((bet) => {
            return bet.relatedIdRunner === relatedIdRunner;
        });
    },

    getRelatedH2hBets: function(bet_mapped, all_bets) {
        return _.filter(all_bets, function(item) {
            //runners must belong to the same race. Otherwise they are not related.
            return item.idRace === bet_mapped.idRace && _.contains(bet_mapped.h2hOpponentsArray, item.relatedIdRunner);
        })
    },

    toggleBonusWarning: function(data, totalStake, container = []) {
        if (!container.length) return;

        const totalBalance = +data.balance;
        const bonusBalance = +data.bonusBalance;
        const netBalance = totalBalance - bonusBalance;

        if ((totalBalance >= totalStake) && (totalBalance === bonusBalance || netBalance < totalStake)) {
            container.fadeIn(500);
        } else {
            container.hide();
        }
    },

    toggleBonusWarningMessage: function(target, container) {
        if (target.is('a')) {
            location.href = target.attr("href");
            return;
        }

        container.slideToggle();
    },
};

export default betslipHelpers;
