'use strict';
import _                        from 'underscore';
import $                        from 'jquery';
import app                      from 'components/core/application';
import BaseRouter               from 'components/core/BaseRouter';
import UserModel                from 'components/user/user.model';
import depositData              from 'components/user/deposit.mixin';
import settings                 from 'components/settings/settings-service';
import userSettingsManager      from 'components/user/user.settings-manager';
import TimeZoneManager          from 'components/helpers/time_zone_manager';
import userFilters              from 'components/user/filter';
import helpers                  from 'components/helpers/helpers';

/**
 * Use objects
 * @module User
 *
 * @requires Underscore
 * @requires app
 * @requires UserModel
 * @requires settings
 * @requires userSettingsManager
 *
 * @description
 *      Manages user. E.g. Session management, updating user data, etc.
 *
 * @returns {Object}
 */
var user = {

    data: {},

    isLoggedIn: false,

    loggedInNode: false,

    init: function() {
        app.on('user:balance:update', this.updateBalance, this);
        app.on('user:login:success', this.joinBalanceChannel, this);
        return this;
    },

    getUserData: function () {
        var dfd = $.Deferred();

        var cb = function (model, response) {
            this.data = _.extend(this.data, response.data, this.readQueryStringUserSettings());

            // assure that currency from iframe URL is uppercase
            this.data.currency =  this.data.currency.toUpperCase();
            // to keep track of orginal language like PE, CL or BR to connect to correct websocket
            this.data.orgLanguage = this.data.language;

            //modify loggedIn property only if response contains this property or otherwise leave it as it is.
            //For some requests response.data is null. E.g. uk_fund_protection.
            if(this.data.loggedIn) {
                this.isLoggedIn = this.data.loggedIn;
            }

            // Portuguese language overrides for Brasilian
            if (['br'].indexOf(this.data.language) > -1) {
                this.data.language = 'pt';
            }

            //if 'loggedIn' property exists, fire an event
            if(this.data.hasOwnProperty('loggedIn')) {
                app.trigger('user:auth:status', {loggedIn: this.data.loggedIn, changed: this.data.loggedIn !== this.isLoggedIn});
            }

            this.data.countryOrder = userSettingsManager.getCountryOrder(this.data.language);

            //set user country on the canvas $el, so we can target it from css (づ￣ ³￣)づ
            if(this.data.language) {
                app.DOM.appCanvas.attr('data-lang', this.data.language);
            }

            if(this.data.ipCountry) {
                app.DOM.appCanvas.attr('data-country', this.data.ipCountry.toLowerCase());
            }

            this.joinBalanceChannel();

            this.filter = Object.create(userFilters);
            this.filter
                .getFromCookie()
                .createFilterCookie();

            // hide dogs
            if (!helpers.isGreyhoundAvailable()) {
                this.filter.filterState.type = _.omit(this.filter.filterState.type, 'D');
                this.filter.saveToCookie();
            } else if (!_.has(this.filter.filterState.type, 'D')) {
                // add dogs back if users move to another country
                this.filter.filterState.type.D = true;
                this.filter.saveToCookie();
            }

            dfd.resolve(this.data);
        };

        if (Object.keys(this.data).length < 1) {
            this.model = UserModel.getInstance();
            this.model.on('sync', cb, this);
            this.model.fetch();
        } else {
            dfd.resolve(this.data);
        }

        return dfd.promise();
    },

    readQueryStringUserSettings: function () {
        let locationQueryStringMatch = window.location.href.match(/\?(.*)/);
        if (locationQueryStringMatch && locationQueryStringMatch.length > 1) { // if there is a querystring, extract the values
            let querystring = helpers.parseQueryString(locationQueryStringMatch[1]);

            return querystring;
        }

        return {};
    },

    timeZone: function () {
        if (this.isLoggedIn && this.data.timezone) {
            return this.data.timezone;
        } else {
            //try to detect timezone
            let tz = new TimeZoneManager().getCurrentTimeZone().name;
            return (tz) ? tz : settings.defaultTimeZone;
        }
    },

    countryOrder: function () {
        if (_.isEmpty(this.data.countryOrder)) {
            return settings.defaultCountryOrder;
        } else {
            return this.data.countryOrder;
        }
    },

    manageData: function (response) {
        this.data = response.data;
    },

    getOddsFormat: function() {
        if (this.data.oddsFormat) {
            return this.data.oddsFormat;
        } else {
            return settings.oddsFormat;
        }
    },

    getMetricSystem: function() {
        if (this.data.unitSystem) {
            return this.data.unitSystem;
        } else if (this.data.country && _.indexOf(['GB', 'IE'], this.data.country) > -1) {
            return 'imperial';
        } else {
            return 'metric';
        }
    },

    getTLD: function() {
        return this.data.websiteTLD.toLowerCase();
    },

    isGerman: function() {
        return this.data.country ? this.data.country.toUpperCase() === 'DE' : false;
    },

    isUkCustomer: function () {
        return this.data.country ? this.data.country.toUpperCase() === 'GB' : false;
    },

    load: function () {
        if (this.model) {
            this.model.fetch();
        }
    },

    ipCountry: function() {
        return this.data.ipCountry;
    },

    joinBalanceChannel: function() {
        if (this.isLoggedIn) {
            import('components/user/user-push-service')
                .then((module) => {
                    const UserPushService = module.default;

                    UserPushService
                        .init()
                        .joinBalanceChannel();
                });
        }
    },

    countryListSettings: function (tab = 'defaultList') {
        var countryList = window.sessionStorage.getItem(tab);

        var save = () => {
            try {
                window.sessionStorage.setItem(tab, JSON.stringify(countryList));
            } catch (e) {
                console.log('Cannot save username in session storage: ' + e);
            }
        };

        var add = (state, country) => {
            if (_.isUndefined(state) || ['open', 'closed'].indexOf(state) < 0) {
                throw "state parameter undefined or wrong"
            }

            if (_.isUndefined(country)) {
                throw "state parameter undefined"
            }

            if (countryList[state].indexOf(country) < 0) {
                countryList[state].push(country);
            }
            save();
        };

        var remove = (state, country) => {
            if (_.isUndefined(state)  || ['open', 'closed'].indexOf(state) < 0) {
                throw "state parameter undefined or wrong"
            }

            if (_.isUndefined(country)) {
                throw "state parameter undefined"
            }

            if (countryList[state].indexOf(country) > -1) {
                countryList[state] = _.without(countryList[state], country);
                save();
            }
        };

        var get = () => {
            return countryList;
        };

        var isOpen = (country) => {
            return countryList.open.indexOf(country) > -1
        };

        var isClosed = (country) => {
            return countryList.closed.indexOf(country) > -1
        };

        if (_.isUndefined(countryList) || _.isNull(countryList)) {

            countryList = {
                closed: [],
                open: []
            };
            save();
        } else {
            countryList = JSON.parse(countryList);
        }

        return {
            save: save,
            add: add,
            remove: remove,
            get: get,
            isClosed: isClosed,
            isOpen: isOpen
        }
    }
};

_.extend(user, depositData);

export default user;
