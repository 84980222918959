var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
    return "                <input id=\"odds\" type=\"text\" class=\"m-betslip__input m-betslip__input--NA\" value=\""
    + this.escapeExpression(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_sp",{"name":"translate","hash":{},"data":data}))
    + "\" disabled>\n";
},"3":function(depth0,helpers,partials,data) {
    return "                <input id=\"odds\" type=\"text\" class=\"m-betslip__input m-betslip__input--NA\" value=\""
    + this.escapeExpression(this.lambda((depth0 != null ? depth0.odds_f : depth0), depth0))
    + "\" disabled>\n";
},"5":function(depth0,helpers,partials,data) {
    return "checked";
},"7":function(depth0,helpers,partials,data) {
    return "disabled";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.escapeExpression, alias2=this.lambda;

  return "<div class=\"m-betslip__section\">\n    <div class=\"table-row\">\n        <div class=\"table-cell\">\n            <div class=\"truncate-text\">\n                <div class=\"m-betslip__runnerName\">\n                    "
    + alias1(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,(depth0 != null ? depth0.label : depth0),{"name":"translate","hash":{},"data":data}))
    + "\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n\n<div class=\"m-betslip__section\">\n    <div class=\"c-grid c-grid--noGutter m-betslip__inputWrapper\">\n        <div class=\"c-grid__col is-1-2 left-el\">\n            <label for=\"odds\" class=\"m-betslip__inputLabel\">"
    + alias1(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_odds",{"name":"translate","hash":{},"data":data}))
    + "</label>\n"
    + ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.odds_f : depth0),{"name":"unless","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.odds_f : depth0),{"name":"if","hash":{},"fn":this.program(3, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n        <div class=\"c-grid__col is-1-3 right-el\">\n            <label for=\"unit\" class=\"m-betslip__inputLabel\">"
    + alias1(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_amount",{"name":"translate","hash":{},"data":data}))
    + "</label>\n            <input id=\"unit\" type=\"text\" value=\""
    + alias1(alias2(((stack1 = (depth0 != null ? depth0.stake : depth0)) != null ? stack1.unit_f : stack1), depth0))
    + "\" class=\"m-betslip__input\" data-id=\"unit\" data-unit>\n        </div>\n        <div class=\"c-grid__col is-1-5 right-el m-betslip__multiplesCheckbox\">\n            <label for=\"wp-c\" class=\"m-betslip__inputLabel\">"
    + alias1(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_bet_type_WP_short",{"name":"translate","hash":{},"data":data}))
    + "</label>\n            <input type=\"checkbox\" data-checkbox-each-way id=\"each_way_multiples_"
    + alias1(alias2((depth0 != null ? depth0.cid : depth0), depth0))
    + "\" name=\"each_way_multiples_\" data-idRunner=\"\" class=\"c-checkbox\" "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.isEachWay : depth0),{"name":"if","hash":{},"fn":this.program(5, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.showEachWay : depth0),{"name":"unless","hash":{},"fn":this.program(7, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">\n            <label for=\"each_way_multiples_"
    + alias1(alias2((depth0 != null ? depth0.cid : depth0), depth0))
    + "\" class=\"c-checkbox__label c-checkbox__label--race\"></label>\n        </div>\n    </div>\n    <div data-validation_unit class=\"c-form__validationMessage\"></div>\n</div>\n";
},"useData":true});