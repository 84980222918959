'use strict';
import moment           from 'moment-timezone';
import user             from 'components/user/user';
import settings         from 'components/settings/settings-service';
import appSettings      from 'components/app.settings';
import app              from 'components/core/application';

export default {
    fullTime: function (next_race, first_start, is_ante_post) {
        var timezone = user.timeZone();

        //convert event time based on user/defaut time zone
        first_start = moment.unix(first_start).tz(timezone);
        next_race = moment.unix(next_race).tz(timezone);
        var now = moment();

        //if antepost, send back date only
        if (is_ante_post) {
            var date = first_start.format('L');
            // regexp extract month and day from dd/mm/yyyy or dd.mm.yyyy
            // if there are no matches falls back to full date
            date = date.match(/(\d{2}.\d{2})/) === null ? date : date.match(/(\d{2}.\d{2})/)[0];
            return {type: 'original', value: date, textValue: date};
        }

        //if the event date is today
        if (first_start.isSame(now, 'day')) {
            //get difference in seconds
            var nextRaceDiffTime = next_race.diff(now, 'seconds');
            var firstStartDiffTime = first_start.diff(now, 'seconds');

            //if difference is 0 or negative - event is due
            if (nextRaceDiffTime <= 0) {
                return {type: 'diff', value: 'Due', textValue: app.polyglot.t('label_due'), fullTime: first_start.format('LT'), due: 'Due'};
            }

            //difference is more than 0
            if (nextRaceDiffTime > 0) {
                //if equals or is less than X seconds, return minutes before start (default: 30 m)
                if (firstStartDiffTime <= appSettings.eventsCountDownStartTime * 60) {//convert to seconds
                    let minutes = parseInt(nextRaceDiffTime / 60, 10);
                    let textValue = minutes + this.getMinutesLabel();
                    if (minutes > 60) {
                        let hours =  parseInt(nextRaceDiffTime / 60 / 60, 10);
                        let minutesDiff = minutes % 60;
                        if (minutesDiff !== 0) {
                            textValue = hours + ':' + (minutes % 60) + ' h'
                        } else {
                            textValue = hours + ' h'
                        }

                    }
                    return {
                        type: 'diff',
                        value: nextRaceDiffTime,
                        textValue: textValue,
                        minutes: minutes,
                        fullTime: next_race.format('LT'),
                        due: 'Due'
                    };
                } else {
                    return {
                        type: 'original',
                        value: next_race.format('LT'),
                        textValue: next_race.format('LT'),
                        fullTime: next_race.format('LT'),
                        due: 'Due'
                    };
                }
            }
        }
        if (first_start.isBefore(now, 'day')) {
            var time = first_start.format('LT');
            return {type: 'diff', value: time, textValue: time};
        }
        if (first_start.isAfter(now, 'day')) {
            var time = first_start.format('LT');
            return {type: 'original', value: time, textValue: time};
        }
    },

    countDownTime: function(next_race) {
        var timezone = user.timeZone();
        //convert event time based on user/default time zone
        next_race = moment.unix(next_race).tz(timezone);
        var now = moment();

        //get difference in seconds
        var diffTime = next_race.diff(now, 'seconds');

        //if difference is 0 or negative - event is due
        if (diffTime <= 0) {
            return {value: 'Due', textValue: 'Due', due: true};
        } else {
            //difference is more than 0
            if (diffTime <= appSettings.eventsCountDownStartTime * 60) {//convert to seconds
                return {value: parseInt(diffTime / 60, 10), textValue: parseInt(diffTime / 60, 10) + this.getMinutesLabel()};
            }
        }
    },

    getMinutesLabel: function() {
        return ' ' + app.polyglot.t('time_letter_minutes');
    }
};
