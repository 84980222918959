import _                           from 'underscore';
import Backbone                    from 'backbone';
import maybe                       from 'components/helpers/maybe';
import helpers                     from 'components/helpers/helpers';
import betslipHelpers              from 'components/betting/betslip.helpers';
import multiplesValidator          from 'components/betting/multiples.validator';
import moment                      from 'moment-timezone';
import Storage                     from 'components/helpers/serviceFactory';
import BetItemInnerCollection      from 'components/betting/bet-item-inner.collection';

const BetItemsCollection = Backbone.Collection.extend({

    url: '/rest/v2/betting/betslip',

    initialize: function() {
        this.maxRaceCount = 8;
        this.data = {
            bets: new BetItemInnerCollection(),
            multiples: new BetItemInnerCollection(),
            stamp: moment().unix()
        };

        this.data.bets.on('update reset', this.triggerUpdateEvent, this);
        this.data.multiples.on('update reset', this.triggerMultiplesUpdateEvent, this);

        this.data.bets.on('model:changed', this.triggerModelChangedEvent, this);
        this.data.multiples.on('model:changed', this.triggerModelMultiplesChangedEvent, this);

        this.data.bets.on('model:destroy', this.triggerModelDestroyEvent, this);

        this.data.bets.on('multiples:add', this.addMultiples, this);

        this.data.bets.on('model:isSelected', this.modelIsSelected, this);
    },

    triggerUpdateEvent: function(data) {
        this.trigger('update:bet', data);
    },

    triggerMultiplesUpdateEvent: function(data) {
        this.trigger('update:multiples', data);
    },

    triggerModelChangedEvent: function(model={}) {
        //perform check only if parts change.
        if(model.property === 'parts') this.resetMultiples(betslipHelpers.checkOddsForMultiples(this.data.bets.toJSON(), this.data.multiples.toJSON()));

        this.trigger('model:bet:changed', model);
    },

    triggerModelMultiplesChangedEvent: function(model) {
        this.trigger('model:multiples:changed', model);
    },

    triggerModelDestroyEvent: function(data) {
        //if no more models left - trigger DELETE endpoint
        if(this.data.bets.length === 0) this.removeAll();
        this.trigger('update:bet', data);
        this.trigger('model:destroy', {idRunner: data.idRunner});
    },

    addMultiples: function() {
        var allAreEachWay = betslipHelpers.allAreEachWay(this.data.bets.toJSON());
        if (this.data.bets.length === 0) return;
        if (this.validateMarketsForMultiples()) {
            this.resetMultiples(betslipHelpers.checkOddsForMultiples(this.data.bets.toJSON(), multiplesValidator.remapUnits(multiplesValidator.getSystem({bets: this.data.bets.toJSON()}, allAreEachWay), this.data.multiples.toJSON(), allAreEachWay)));
        }
    },

    /**
     * @method
     * @description
     *  //check if all bets have same market and mark betslip as invalid because only bets from same market can be combined in multiples
     * @returns {boolean}
     */
    validateMarketsForMultiples: function() {
        var size = _.size(betslipHelpers.getMarketsForMultiples(this.data.bets.toJSON()));
        var same_market = size === 0 || size === 1;
        //delay to list multiples first and then show message if needed;
        setTimeout(()=> {
            this.trigger('betslip:validity', same_market);
        }, 0);

        return same_market;
    },

    resetMultiples: function(data={}, external=false) {
        //todo: reset only if previous state is different from current one
        if(external && _.size(data) === 0) data = betslipHelpers.checkOddsForMultiples(this.data.bets.toJSON(), this.data.multiples.toJSON());
        this.data.multiples.reset(_.size(data) === 0 ? null : data);
    },

    modelIsSelected: function(model) {
        this.trigger('model:isSelected', model);
    },

    parse: function(response) {
        var bets = [];
        var multiples = [];

        _.each(response && response.bets, function(bet) {
            bets.push(betslipHelpers.mapAdditionalData(bet, response));
        });

        betslipHelpers.checkMultiplesChBx(bets);
        //response, betslipHelpers.isInMultiples(bet, betslipHelpers.getMarkets(all_bets)))

        this.data.bets.reset(bets, {silent: true});

        var allAreEachWay = betslipHelpers.allAreEachWay(this.data.bets.toJSON());

        //1) regenerate multiples. This may differ from saved result because bets included in multiples may have finished, horses scratched, ect.
        var regenerated_multiples = multiplesValidator.getSystem({bets: bets}, allAreEachWay);

        if(_.size(response.multiples) > 0) {
            //2) update multiples if newly generated multiples exists
            _.each(response.multiples, multiple => {
                multiples.push(multiplesValidator.mapAdditionalData(multiple, response, this.data.bets.fixedBetsOnly(), bets));
            });

            //3 find existing multiples by unique label and if exists, replace regenerated item with it
            _.each(regenerated_multiples, r_multiple => {
                var m_exists = _.findWhere(multiples, {label: r_multiple.label});
                if (m_exists) r_multiple = _.extend(r_multiple, m_exists);
            });
        }

        this.data.multiples.reset(betslipHelpers.checkOddsForMultiples(this.data.bets.toJSON(), regenerated_multiples), {silent: true});

        if(response.stamp) this.data.stamp = response.stamp;

        return response;
    },

    getAllBets: function(json=false) {
        if(json) return  _.union(this.data.bets.toJSON(), this.data.multiples.toJSON());
        return _.union(this.data.bets.models, this.data.multiples.models);
    },

    fixedBetsOnly: function() {
        return this.data.bets.length === this.data.bets.fixedBetsOnly().length; //this.data.multiples.length === 0 &&
    },

    //getTotalUnits: function() {
    //    return this.data.multiples.getTotalUnits() + this.data.bets.getTotalUnits();
    //},

    resetBetslip: function() {
        this.data.multiples.reset(null, {silent: true});
        this.data.bets.reset(null);
    },

    removeAll: function() {
        this.resetBetslip();
        return Backbone.$.ajax({
            url: this.url,
            method: 'DELETE'
        });
    },

    removeBetByRunnerId: function(idRunner) {
        var betToRemove = null;
        this.data.bets.forEach(function(bet) {
            if(bet && bet.attributes && parseInt(bet.attributes.idRunner, 10) === parseInt(idRunner, 10)) betToRemove = bet.destroy();
        });
    },

    findBetByRunnerId: function(idRunner) {
        let res = null
        this.data.bets.forEach(function(bet) {
            if(bet && bet.attributes && parseInt(bet.attributes.idRunner, 10) === parseInt(idRunner, 10)) {
                res = bet
            }
        });
        return res;
    },

    resetUnits: function() {
        var bets = this.getAllBets();
        _.each(bets, function(bet) {
            bet.resetUnit();
        });

        //todo: test
        this.triggerUpdateEvent();
        this.triggerMultiplesUpdateEvent();
    },

    getBetIds: function() {
        var all_bets = _.union(this.data.bets.toJSON(), this.data.multiples.toJSON());
        var ids = [];
        _.each(all_bets, function(bet) {
            ids.push(parseInt(bet.idRunner, 10));
        });

        return ids;
    },

    prepareData: function(purchase=false) {
        var new_data = helpers.deepCopy(this.data);
        var picked = {
            bets: [],
            multiples: []
        };

        _.each(new_data.bets, function(bet) {
            bet.parts = _.map(bet.parts, function(part) {
                return _.pick(part, 'market', 'stake', 'odds', 'idRms');
            });
            
            if(bet.tax_data.taxAmount) {
                bet.taxAmount = bet.tax_data.taxAmount
            }
            
            picked.bets.push(_.pick(bet, 'idRace', 'idFreebet', 'idRunner', 'programNumber', 'stamp', 'category', 'uid', 'inMultiples', 'details', 'parts', 'odds', 'isEachWay', 'taxAmount'));
        });

        _.each(new_data.multiples, function(multiple) {
            if (multiple.stake && multiple.stake.unit) picked.multiples.push(_.pick(multiple, 'stamp', 'system', 'baseBetsCount', 'uid', 'stake', 'isEachWay'));
        });

        return picked;
    },

    //do not save state if there is at lest one scratched runner to avoid 500 error
    hasScratched: function() {
        var has_scratched = false;
        for(let i = 0, l = this.data.length; i < l; i++) {
            if(this.data[i].scratched) has_scratched = true;
            break;
        }

        return has_scratched;
    },

    saveAll: function() {
        //if(this.hasScratched) return;

        return Backbone.$.ajax({
            data: JSON.stringify(this.prepareData()),
            dataType: 'json',
            url: this.url,
            method: 'PUT',
            success: function() {},
            error: (jqXHR, textStatus, errorThrown) => {
                this.trigger('error', null, jqXHR, textStatus, errorThrown);
            }
        });
    },

    validateBetSlip: function() {
        var bets = this.getAllBets();
        var status = true;

        //can't save empty state or purchase empty betslip
        if(bets.length < 1) return false;

        for(let i = 0, l = bets.length; i < l; i++) {
            if(bets[i].isValid() === false) {
                status = false;
                break;
            }}

        return status;
    },

    getRaceCount:function(bet=null) {
        var bets = this.data.bets.toJSON();
        if(bet) {
            bets = helpers.deepCopy(bets);
            bets.push(bet);
        }
        var races = _.groupBy(bets, 'idRace');
        return _.size(races);
    },

    raceLimitNotReached: function(bet) {
        return this.getRaceCount(bet) < (this.maxRaceCount + 1);
    },

    betslipValid: function(purchase=false) {
        var bets_valid = this.validateBetSlip();
        var total_bets_count = betslipHelpers.calculateTotalbetCount(this.getAllBets(true), true);

        if(purchase && total_bets_count < 1) this.trigger('betslip:addUnit', {total_bets_count: total_bets_count});

        return bets_valid && total_bets_count > 0;
    },

    removeIdRms: function() {
        _.each(this.data.bets.models, function(bet) {
            var parts = maybe.of(bet).mapDotProp('attributes.parts').orElse([]).join();
            _.each(parts, function(part) {
                if(part.stake && part.stake.idRms) delete part.stake.idRms;
            });
        });

        _.each(this.data.multiples.models, function(multiple) {
            if(multiple.attributes.stake.idRms) delete multiple.attributes.stake.idRms;
        });
    },

    purchase: function() {
        if(this.betslipValid(true)) {
            var betslip = this.prepareData(true);
            //store bet data
            Storage.getByNameOrCreate('Bet').set({data: betslip});

            return Backbone.$.ajax({
                data: JSON.stringify({betslip: betslip}),
                dataType: 'json',
                url: this.url,
                method: 'POST',
                success: (data, textStatus, jqXHR) => {
                    this.removeIdRms();
                    this.trigger('betslip:purchase:success', data, textStatus, jqXHR);
                },
                error: (...args) => {
                    this.trigger('betslip:purchase:error', args);
                }
            });
        }
    }

});

export default BetItemsCollection;
