'use strict';

import _                    from 'underscore';
import $                    from 'jquery';
import Backbone             from 'backbone';
import template             from 'modules/h2h/event-h2h.tpl.hbs';
import H2HCollection        from 'modules/h2h/event-h2h.collection';
import bettingService       from 'components/betting/betting.service';
import BackboneCloseView    from 'components/core/mixins/Backbone.CloseView';
import app                  from 'components/core/application';

/**
 * H2H events view Controller
 * @name EventH2HView
 * @constructor
 *
 * @requires Underscore
 * @requires jQuery
 * @requires Backbone
 * @requires template
 * @requires H2HCollection
 * @requires bettingService
 *
 * @description
 *     List H2H events for a given event
 *
 * @returns Function - Constructor function for NextH2HView
 */
var eventH2H = Backbone.View.extend(
    _.extend({}, {

        className: 'm-h2h c-accordion c-accordion--dark c-countryList l-scrollTarget--noTitle',

        template: template,

        events: {
            'click [data-idRunner]': 'showDialog'
        },

        initialize: function(id) {
            this.collection = new H2HCollection(null, {id: id});
            this.listenTo(this.collection, 'sync', () => {
                if(this.collection.length < 1) {
                    app.router.navigate('/404', {trigger: true});
                } else {
                    app.subHeader.title(this.collection.data.event.title + ' | ' + app.polyglot.t('label_h2h'));
                    this.render();
                }
            });
            this.collection.fetch();
        },

        render: function() {
            this.$el.html(this.template({data: this.collection.toJSON()}));
            return this;
        },

        /**
         * @method showDialog
         * @description
         *      Initialize betting dialog view with initial betModel attributes
         *
         * @param e
         */
        showDialog: e => {
            var link = $(e.target);
            var betType = link.attr('data-betType');
            var idRunner = parseInt(link.attr('data-idRunner'));

            if(idRunner.length < 1) return;

            bettingService.showDialog({attributes: {idRunner: idRunner, betType: betType, isH2H: true}});
        }
    }, BackboneCloseView)
);

export default eventH2H;
