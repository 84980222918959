'use strict';
import _                from 'underscore';
import app              from 'components/core/application';
import $                from 'jquery';
import viewTemplate     from 'components/sub_header/sub_header.tpl.hbs';
import user             from 'components/user/user';


export const template = viewTemplate;

export const el = app.DOM.appSubHeader;

export const viewEvents = {
    'click #back_btn': 'navigateBack',
    'click [data-r-menu]:not(".hide-visually")': 'showRightMenu',
    'click [data-open]': 'showExtras'
};

export function initialize (opt) {
    this.DOM = {};
    this.defaultRouteRules = {};
    this.options = {
        title: '',
        rightMenu: false,
        backArrow: false,
        betCount: 0
    };

    this.subExtraHeight = 0;

    _.extend(this.options, opt || {});
    if (this.options.defaultRoutes) {
        this.defaultRoutes().set(this.options.defaultRoutes);
    }

    app.router.on('history:page:last', this.hideArrow, this);
    app.router.on('history:page:next', this.showArrow, this);
    app.router.on('route', this.clearTitle, this);
    app.on('canvas:click', this.closeSubExtra, this);

    this.isOpen = false;
    this.extraView = null;

    //call custom init functions in local app folders
    if (typeof this.viewInitialize === 'function') {
        this.viewInitialize();
    }

    this.render();
}

export function render() {
    this.$el.html(this.template({
        data: this.options,
        trustlyEnabled: _.contains(user.data.trustlyEnabledCountries, user.data.ipCountry),
        hasBonus: user.data.pendingBonuses > 0 || user.data.freebets > 0,
        isLoggedIn: user.isLoggedIn
    }));

    this.DOM.arrow = this.$el.find('#back_btn');
    this.DOM.title = this.$('[data-title]');
    this.DOM.rMenu = this.$('[data-r-menu]');
    this.DOM.subExtra = this.$('[data-extra]');
    this.DOM.betslipButton = this.$('[data-betslip-button]');
    this.DOM.betslipBetsCount = this.$('[data-betslip-count]');
    this.DOM.bonus = this.$('[data-bonus]');

    setTimeout(()=> {
        this.checkHistory();
    }, 0);

    return this;
}

export function resetTemplate() {
    this.template = template;
    this.DOM = {};
    this.render();

    // close extra view if present
    if (this.extraView) {
        this.extraView.close();
        this.extraView = null;
    }

    this.isOpen = false;
    app.DOM.appHeader.removeAttr('style');

    this.checkHistory();

    //call custom update functions in local app folders
    if (typeof this.viewComponentUpdate === 'function') {
        this.viewComponentUpdate();
    }
}

export function setTemplateWithTitle() {
    this.template = template;
    this.render();
}

/**
 * Switch the subHeader template view
 * If extraView is passed, it will be rendered in the tag tagged data-extra
 * @param template - Handlebar template
 * @param data - Obj
 * @param extraView - Backbone View
 */
export function switchTemplate(template, data, extraView) {
    this.template = template;
    _.extend(this.options, data || {});

    this.render();

    // render the extraView
    if (extraView) {
        this.extraView = extraView;
        this.DOM.extra = this.$('[data-extra]');
        this.DOM.extra.html(this.extraView.render().$el);
    }

    this.checkHistory();

    if (this.rightMenuActive) {
        this.rightMenu(true) }

    // save new template height
    // used to adjust scrolling in the views
    this.subExtraHeight = parseInt(this.DOM.subExtra.outerHeight());
}

/**
 * @method defaultRoutes - Define default routes
 *  @description
 *      {
     *          key:{
     *              to: 'deposit/options',
     *              routes: []
     *          }
     *      }
 *
 *      'key' - route. E.g, 'deposit';
 *      'to' {String} - route to redirect when back arrow clicked. E.g, 'deposit/options';
 *      'routes' {Array} - list of nested routes. E.g. ['deposit/card', 'deposit/skrill']. Optional
 *
 *      The above rule means, that if user navigates to 'deposit/card/*' or 'deposit/skrill/*' - a back arrow will be shown for this two routes pointing to 'deposit/options'
 *      If array is empty, back arrow will be shown for all route containing '/deposit' base route except on 'deposit/options'
 *
 * @returns {{get: Function, set: Function}}
 */
export function defaultRoutes() {
    var _getRoutes = (route=null)=> {
        if (route) {
            //first check if there is a deep link before getting the base route
            //route may can also be set up for deep links. E.g. settings/language - arrow has to be visible for this route only, but not for base route 'settings'
            if (this.defaultRouteRules[route] && this.defaultRouteRules[route].routes.length === 0) {
                return this.defaultRouteRules[route].to;
            }

            //get base route
            let baseRoute = route.split('/')[0];
            let to = (this.defaultRouteRules[baseRoute] && this.defaultRouteRules[baseRoute].to) ? this.defaultRouteRules[baseRoute].to : '';

            //find rules for the base route in case if current route is different from the 'to' route
            if (this.defaultRouteRules[baseRoute] && [to, `${to}/success`, `${to}/success`].indexOf(route) === -1) {
                //send back 'to' if routes array is empty
                if (this.defaultRouteRules[baseRoute].routes.length === 0) {
                    return this.defaultRouteRules[baseRoute].to;
                } else {
                    //check if current route matches preset routes
                    return (this.defaultRouteRules[baseRoute].routes.indexOf(route) > -1) ? this.defaultRouteRules[baseRoute].to : null
                }
            }
        }

        return null;
    };

    var _setRoutes = routes => {
        this.defaultRouteRules = routes;
    };

    var _setOneRoute = route => {
        let key = Object.keys(route);
        this.defaultRouteRules[key[0]] = route[key[0]];
    };

    return {
        get: _getRoutes,
        set: _setRoutes,
        setOne: _setOneRoute
    }
}

/**
 * @method addOneTimeBackRoute
 * @description
 *  Add route to router's "toRoute" property to change back button behaviour.
 * @param to
 */
export function addOneTimeBackRoute(to) {
    let router = app.router;
    router.toRoute = to;
}

/**
 * @method checkDefaultRules - check default routes and set them if exist
 */
export function hasDefaultRules(router) {
    if (!router || router.visitedRoutes.length < 1) {
        return;
    }

    router.toRoute = this.defaultRoutes().get(router.visitedRoutes[0]);
    if (router.toRoute) {
        //clear visited routes
        router.visitedRoutes = [];
        return true;
    }

    return false;
}

/**
 * @method checkHistory - Manipulate arrow visibility
 *  @description
 *      If history contains at least 2 routes - show back arrow.
 *      Else, check if there are default routes. For certain modules there may be a default route to go back to.
 *      E.g. for deposit section for a number of nested routes there will be a back button.
 */
export function checkHistory() {
    let router = app.router;
    if (router.visitedRoutes.length > 1 || this.hasDefaultRules(router)) {
        this.showArrow();
    }
}

export function navigateBack() {
    app.trigger('navigate:back');
}

export function showRightMenu(e) {
    e.preventDefault();

    const rMenu = this.DOM.rMenu[0].dataset.rMenu;

    switch (rMenu) {
        case 'livestream':
            this.trigger('livestream:show');
            break;
        case 'bonusMenu':
            this.trigger('bonusContextMenu:show');
            break;
        default:
            this.trigger('menu:right:show');
    }
}

export function hide() {
    this.$el.addClass('isHidden')
}

export function show() {
    this.$el.removeClass('isHidden')
}

export function title(title) {
    setTimeout(() => {
        this.options.title = title;
        this.DOM.title.html(title);
    });
}

export function getTitle() {
    return this.options.title
}

export function rightMenu(show) {
    this.rightMenuActive = show;
    this.DOM.rMenu.toggleClass('hide-visually', !show);
}

export function showArrow() {
    this.options.backArrow = true;
    if (this.DOM.arrow && this.DOM.arrow.hasClass('hide-visually')) {
        this.DOM.arrow.toggleClass('show-visually-fade-in hide-visually');
    }
}

export function hideArrow() {
    this.options.backArrow = false;
    if (this.DOM.arrow && this.DOM.arrow.hasClass('show-visually-fade-in')) {
        this.DOM.arrow.toggleClass('show-visually-fade-in hide-visually');
    }
}

export function toggleArrow() {
    if (this.DOM.arrow) {
        this.DOM.arrow.toggleClass('show-visually-fade-in hide-visually');
    }
}

export function showExtras() {
    if (!this.isOpen) {
        app.DOM.appHeader.height(app.DOM.appHeader.height() + this.DOM.subExtra.outerHeight());
        this.isOpen = true;
        var self = this;
        window.setTimeout(function () {
            self.trigger('subheader:extra:open');
        }, 500);
    } else {
        app.DOM.appHeader.removeAttr('style');
        this.isOpen = false;
        this.trigger('subheader:extra:close');
        // notify extra view too
        if (this.extraView) {
            this.extraView.trigger('subheader:extra:close');
        }
    }
}

export function clearTitle() {
    this.options.title = '';
    this.DOM.title.html('');
}

export function closeSubExtra(e) {
    if ($(e.target).parents('.c-bar').length < 1 && this.isOpen) {
        this.showExtras();
    }
}
