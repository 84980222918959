'use strict';

import _                from 'underscore';
import $                from 'jquery';
import app              from 'components/core/application';
import Backbone         from 'backbone';
import ViewScroll       from 'components/core/mixins/Backbone.ViewScroll';
import template         from 'components/dialog/dialog.tpl.hbs';
import helpers          from 'components/helpers/helpers';

var Dialog = Backbone.View.extend(
    _.extend({}, ViewScroll, {

    className: 'l-scrollWrapper',

    template: template,

    events: {
        'click [data-el]': 'stopPropagation',
        'click a[data-dialog-closeBtn]': 'closeBtnFn'
    },

    /**
     * @methods stopPropagation
     *
     * @description
     *  Used to prevent click event from propagating to overlay object.
     *  Click outside the dialog area is in fact a click on overlay object which triggers an event to closed the dialog
     *
     *  To disable this behaviour use 'data-skip-stopPropagation' attribute on elements (e.g. links) inside the dialog.
     *
     * @param e
     */
    stopPropagation: function (e) {
        if($(e.target).attr('data-skipStoppropagation') === undefined) e.stopPropagation();
    },

    initialize: function (opt) {
        this.DOM = {};
        this.options = {};
        _.extend(this.options, opt || {});

        this.dataAttrBody = 'data-dialog-body';
        this.dataAttrTitle = 'data-dialog-title';
        this.dataAttrCloseBtn = 'data-dialog-closeBtn';

        //TODO I believe we can remove this logic about sizes
        this.sizeClassLookUp = {
            sliding: 'c-dialog--sliding'
        };

        if(this.options.secondary) {
            this.overlay = app.overlaySecondary;
        } else {
            this.overlay = app.overlay;
        }

        if (this.options.template) {
            this.template = this.options.template;

            if (this.options.autoRender) {
                this.render();
            }
        }
    },

    title: function (title) {
        if (title) {
            this.options.title = title;
        }

        if (this.DOM.title) {
            this.DOM.title.html(title);
        }
    },

    closeBtn: function (closeBtn) {
        if (closeBtn) {
            this.options.closeBtn = closeBtn;
        }

        if (this.DOM.closeBtn) {
            this.DOM.closeBtn.html(closeBtn);
        }
    },

    render: function () {
        this.$el.html(this.template({
            title: this.options.title,
            closeBtn: this.options.closeBtn,
            closeBtnIcon: this.options.closeBtnIcon
        }));

        this.DOM.el = this.$('[data-el]');

        if (this.options.size) {
            this.DOM.el.addClass(this.sizeClassLookUp[this.options.size]);
        }

        this.DOM.body = this.$('[' + this.dataAttrBody + ']');
        this.DOM.title = this.$('[' + this.dataAttrTitle + ']');
        this.DOM.closeBtn = this.$('[' + this.dataAttrCloseBtn + ']');

        if(this.options.whiteBG) {
            this.DOM.body.addClass('c-dialog__body--lightBackGround');
        }

        if (this.options.contentView) {
            this.DOM.body.html(this.options.contentView.render().$el);
        }

        if (this.options.actions) {
            //set up events dynamic
            this.delegateEvents(_.extend(this.events, this.prepareEvents()));
        }

        this.overlay.showComponent(this);
        app.overlay.trigger('content:render');

        window.setTimeout(() => {
            if (typeof this.options.size === 'undefined' &&
                window.screen.height > this.DOM.el.outerHeight()) {
                // is auto, check if is smaller then viewport and make it centered with css
                this.DOM.el.addClass('c-dialog--centered');
            }
        }, 0);

        return this;
    },

    prepareEvents: function () {
        var self = this;
        var events = {};
        var actions = _.flatten(this.options.actions);
        for (var i = 0, l = actions.length; i < l; i++) {
            var el = actions[i];
            var fn = 'func' + helpers.randomName();
            events[el.event + ' ' + el.selector] = fn;
            //register callback for event
            self[fn] = el.cb;
        }

        return events;
    },

    /**
    * Useful when changing only the contentView of a Dialog
    * before changing the contentView set a new size to the already visible dialog
    * e.g.
    * app.overlay.component.changeSize('large');
    * app.overlay.component.renderContentView(newContentDialogView);
    * @param size
    */
    changeSize: function (size) {
        if (this.sizeClassLookUp[size]) {
            this.DOM.el.removeClass(this.sizeClassLookUp[this.options.size]);
            this.DOM.el.addClass(this.sizeClassLookUp[size]);
            this.options.size = size;
        }
    },

    /**
     *
     * @param newContentView
     * @param storePrev - do not close the previous view, rather store it in array
     */
    renderContentView: function(newContentView, storePrev) {
        if(!newContentView) {
            throw new Error('New content view is required');
        }

        if(this.options.contentView) {
            if (storePrev) {
                if(!this.options.storedContentViews) {
                    this.options.storedContentViews = [];
                }
                this.options.storedContentViews.push(this.options.contentView);
            } else {
                this.options.contentView.close();
            }
        }

        this.options.contentView = newContentView;
        this.DOM.body.html(this.options.contentView.render().$el);
        this.options.contentView.delegateEvents();
        app.overlay.trigger('content:render');

        window.setTimeout(() => this.handleScrollToInputs(), 0);
    },

    addOverlayClassName: function (className) {
        this.overlay.$el.addClass(className);
    },

    removeOverlayClassName: function (className) {
        this.overlay.$el.removeClass(className);
    },

    close: function () {
        if (this.options.contentView && typeof this.options.contentView.close === 'function') {
            this.options.contentView.close();
        }

        this.trigger('dialog:close');

        this.remove();
        this.undelegateEvents();
    },

    closeBtnFn: function () {
        this.trigger('dialog:close:btn');
    }
}));

export default Dialog;

