import app                          from 'components/core/application';
import helpers                      from 'components/helpers/helpers';
import Dialog                       from 'components/dialog/dialog';
import SessionManager               from 'modules/sessions/session-manager';
import ActiveSessionsModel          from 'modules/sessions/active-sessions.model';
import ActionSheet                  from 'components/action_sheet/action_sheet';
import QuickBetDialog               from 'components/betting/quick-bet-dialog';
import QuickBetMultiDialog          from 'components/betting/quick-bet-multi-dialog';
import SnackBarConsent              from 'components/snack-bar/snack-bar-consent';
import { SNACK_BAR_VIEW_EVENTS }    from 'components/snack-bar/snack-bar';
import { NewTermsView }             from 'components/auth/new-terms';

export const viewEvents = {
    'click': 'closeComponent'
};

export function render() {
    this.$el.empty().append(this.component.$el);
    this.component.delegateEvents();
    this.showOverlay();
    this.trigger('overlay:render');
    return this;
};

export function checkForComponent() {
    if(this.component && !this.component.betslip) this.component.close();
};

/**
 * Creates new instance of ActionSheet with options (if provided)
 *
 * @description
 *  Closes previous component (any type) of exists. Only one component should be instantiated and shown at a time.
 *
 * @param options
 * @returns {AppOverlay}
 */
export function actionSheet(options) {
    this.checkForComponent();
    this.component = new ActionSheet(options);
    return this;
};

/**
 * Creates new instance of Dialog with options (if provided)
 *
 * @description
 *  Closes previous component (any type) of exists. Only one component should be instantiated and shown at a time.
 *
 * @param options
 * @returns {AppOverlay}
 */
export function dialog(options) {
    this.checkForComponent();
    this.component = new Dialog(options);
    this.listenTo(this.component, 'dialog:close:btn', function() {
        this.closeDialog();
    });
    return this;
};

export function quickbet(opt) {
    this.checkForComponent();
    this.$el.addClass('c-overlay--showHeader');
    this.component = new QuickBetDialog(opt);
    this.listenTo(this.component, 'close:btn', function () {
        this.closeDialog();
        this.$el.removeClass('c-overlay--showHeader');
    });

    return this;
};

export function betslip() {
    this.checkForComponent();
    this.$el.addClass('c-overlay--showHeader');
    this.component = app.betslip;
    this.component.visible = true;
    this.trigger('betslip:visible');
    this.listenTo(this.component, 'close:btn', function () {
        this.hideBetslip();
        this.$el.removeClass('c-overlay--showHeader');
    });

    return this;
};

export function quickbetMulti(opt) {
    this.checkForComponent();
    this.$el.addClass('c-overlay--showHeader');
    this.component = new QuickBetMultiDialog(opt);
    this.listenTo(this.component, 'close:btn', function () {
        this.closeDialog();
    });

    return this;
};

export function sessionManager(list, url) {
    var model = new ActiveSessionsModel();
    model.data.url = url;
    this.checkForComponent();

    this.component = new SessionManager({
        list: list,
        model: model
    });

    return this;
};

export function termsOverlay(options={}) {
    this.checkForComponent();
    this.component = new NewTermsView(options);

    return this;
};

export function showComponent(c) {
    this.component = c;
    this.render();
};

export function showBetslip() {
    //render element if it has not been rendered yet for betslip only.
    if(!this.component.betslip || this.$el.html().length === 0) {
        this.$el.empty().append(this.component.$el);
        this.component.delegateEvents();
    }

    this.showOverlay();
    this.trigger('overlay:render');

    if (helpers.device.isIOS) {
        this.component.$el.css({'overflow': 'hidden'});
        this.component.DOM.wrap.one('transitionend webkitTransitionEnd', () => {
            this.component.$el.css({'overflow': 'auto'});
        });
    }

    return this;
};

export function showOverlay() {
    var self = this;

    this.$el.one('transitionEnd webkitTransitionEnd', function() {
        app.trigger('overlay:visible');
    });

    // DOM i/o rest time in order to the animation could work, mostly when
    // user lands to a specific url, that triggers the overlay:show (e.g.: /login)
    // and already too many things happening in the same time.
    setTimeout(function () {
        self.$el.addClass('isActive');
        if(self.component.options.animation) {
            self.component.$el.addClass(self.component.options.animation);
        }
    }, 200);
};

export function hideOverlay() {
    this.$el
        .removeClass('isActive')
        .one('transitionEnd webkitTransitionEnd', function() {
            app.trigger('overlay:hidden');
        });
};

/**
 * Close the active dialog
 *
 * @description
 *  Closes the active component.
 *  Pass the url parameter if the function should trigger a navigate event to a custom url
 *
 * @param url
 * @returns {AppOverlay}
 */
export function closeDialog(url, closeOnRouteChange) {
    var self = this;
    var delay = 0;
    var component = this.component;

    if (component && (component.options.closeOnOutsideClick || closeOnRouteChange || component.options.closeBtn || url)) {

        if (url) {
            app.trigger('navigate', url);
        } else if (component.options.returnUrl) {
            app.trigger('navigate', component.options.returnUrl);
        }

        if (component.options.animation) {
            delay = 200;
            component.$el.removeClass(component.options.animation);
        }

        setTimeout(function () {
            //close all stored views.
            if(component.options.storedContentViews && component.options.storedContentViews.length > 0) {
                for(var i = 0, l = component.options.storedContentViews.length; i < l; i++) {
                    component.options.storedContentViews[i].close();
                }
            }

            //all views in dialogs have their close method called by default.
            //In case of betslip (it is initiated once at app load and lives through user session) we need to simply close the dialos and keep the components/view
            if(close) {
                self.stopListening();
                if (component.close && typeof(component.close) === 'function') component.close();
                component = null;
            }

            self.hideOverlay();
        }, delay);
    }
};

export function hideBetslip() {
    this.hideOverlay();
    this.trigger('betslip:hidden');
    if (this.component && this.component.betslip) {
        this.component.visible = false;
        if (this.component.onClose && typeof(this.component.onClose) === 'function') this.component.onClose();
    }
};

export function onRouteChange(current, prev, restrictions) {
    if(this.component) {
        if(this.component.betslip && this.component.visible) {
            this.hideBetslip();
        } else if(this.component.options && this.component.options.closeOnRouteChange) {
            this.closeDialog(null, true);
        }
    }
};

export function closeComponent(e) {
    if(e) e.stopPropagation();

    if(this.component && this.component.betslip) {
        this.hideBetslip();
    } else {
        this.closeDialog();
    }
}

export function showConsentSnackBar(e) {
    if(this.snackBarComponent) this.closeConsentSnackBar();
    this.snackBarComponent = new SnackBarConsent;

    this.listenToOnce(this.snackBarComponent, SNACK_BAR_VIEW_EVENTS.CLOSE, function() {
        this.closeConsentSnackBar();
    });

    app.DOM.snackBarAttachedAfter.after(
        this.snackBarComponent.render().$el
    );
}

export function closeConsentSnackBar(e) {
    if(this.snackBarComponent) {
        this.snackBarComponent.close();
        this.snackBarComponent = null;
    }
}
