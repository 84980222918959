import $                            from 'jquery';
import Backbone                     from 'backbone';
import _                            from 'underscore';
import template                     from 'components/media/media.tpl.hbs';
import BackboneCloseView            from 'components/core/mixins/Backbone.CloseView';
import user                         from 'components/user/user';
import moment                       from 'moment-timezone';
import helpers                      from 'components/helpers/helpers';
import maybe                        from 'components/helpers/maybe';
import app                          from 'components/core/application';

var MediaView = Backbone.View.extend(
    _.extend({}, BackboneCloseView, {

        el: app.DOM.appStream,

        template: template,

        initialize: function (data, idResource) {
            this.data = {};
            this.videojs = null;
            this.webRTC = helpers.supportsWebRTC();
            _.extend(this.data, data);
            this.data.idResource = idResource || this.data.idResource;

            if (this.data.provider === 'SISTV' && this.webRTC) {
                this.checkData();
                return;
            }

            // load video.js files
            const videoJs = import(
                /* webpackChunkName: "videojs" */
                'video.js'
            );
            const videoJsStyles = import(
                /* webpackChunkName: "videojs" */
                'video.js/dist/video-js.css'
            );

            Promise.all([videoJs, videoJsStyles]).then(
                (imports)=> {
                    this.videojs = imports[0].default
                    this.checkData();
                }
            );
        },

        checkData: function () {


            // archive video ATR/SISTV
            if (['ATR', 'SISTV'].indexOf(this.data.provider) > -1) {
                this.getStreamUrl(() => {
                    this.createPlayer();
                    return;
                });
                return;
            }

            if (this.data.streamType === 'LIV') {
                if (this.data.provider === 'PFG') {
                    this.getStreamUrl(() => {
                        this.handlePFG();
                        return;
                    });
                    return;
                }

                if (this.data.provider === 'TV1') {
                    this.createPlayer();
                    return;
                }

                if (this.data.idChannel == 18) {
                    this.createPlayer();
                    return;
                }

                //Hexaglobe, or EasyGate
                if (['HEX', 'EGT'].indexOf(this.data.provider) > -1) {
                    this.getStreamUrl(() => {
                         this.createPlayer();
                         return;
                    });
                    return;
                }
            }

            // archive
            if (['MP4','MSC'].indexOf(this.data.streamType) > -1) {
                if (['ATR', 'PFG', 'EGT'].indexOf(this.data.provider) > -1) {
                    this.getStreamUrl(() => {
                        this.createPlayer();
                        return;
                    });
                    return;
                }

                if (['TV1', 'HEX'].indexOf(this.data.provider) > -1) {
                    this.createPlayer();
                    return;
                }
            }

            app.trigger('dialog:error', app.polyglot.t('msg_stream_error_generic'));
        },

        handlePFG: function () {
            var mediaTime = moment.unix(this.data.startTime);

            if (mediaTime.isAfter(moment().tz(user.timeZone()))) {
                app.trigger('dialog:warning', app.polyglot.t('msg_error_stream_not_started'));
                return;
            }
            this.createPlayer()

        },

        getStreamUrl: function (callback) {
            let data = {
                idChannel: parseInt(this.data.idChannel, 10),
                streamType: this.data.streamType,
                mobile: true
            }

            // ATR provider requires idRace.
            if (['ATR', 'SISTV', 'EGT', 'PFG'].indexOf(this.data.provider) > -1) data.idRace = this.data.idRace;

            import(
                /* webpackChunkName: "media" */
                'components/media/token.model'
            ).then(({default: TokenModel}) => {
                this.token = new TokenModel(data);
                this.token.on('sync', (response) => {
                    if(response && response.get('type') !== 'error') callback();
                })
                this.token.save();
            })
        },
        getType: function(streamType) {
            switch (streamType) {
                case 'LIV':
                    return 'application/x-mpegURL';
                case 'MP4':
                    return 'video/mp4';
                case 'MP3':
                    return 'audio/mp3';
                default:
                    return 'application/x-mpegURL';
            }
        },

        getMediaType: function(provider, streamType) {
            if(provider === 'ATR') return 'application/x-mpegURL'
            return this.getType(streamType);
        },
        createPlayer: function () {
            this.data.mediaType = this.getMediaType(this.data.provider, this.data.streamType);
            if(['ATR', 'EGT', 'SISTV'].indexOf(this.data.provider) > -1) {
                this.data.video = true;
                this.data.url = {http: this.token.get('stream_url')};

                if ((this.data.provider === 'EGT' && this.data.streamType === 'LIV') || (this.data.provider === 'SISTV' && this.webRTC)) {
                    this.data.video = false;
                    this.data.audio = false;
                    this.data.iframe = true;
                    this.data.url.iframe = this.token.get('stream_player');
                }
            } else if (this.data.provider === 'PFG') {
                if (this.data.streamType === 'LIV') {
                    this.data.script = true;
                } else {
                    this.data.video = true;
                    this.data.url = {};
                    this.data.url.http = this.token.get('archive_url');
                }
            } else if (this.data.provider === 'YOUTUBE') {
                this.data.video = true;
                this.data.url = this.token.get('stream_player');
            } else {
                // if is not egt we build the streaming url
                var url = this.constructStreamUrl();
                this.data.url = url;
                this.data.iOS = helpers.device.isIOS;
            }
            // on iOS7 we redirect the browser to the streaming url,
            // once the user clicks done he is redirected to racebets again.
            // from iOS8 this doesn't happens unless the streaming starts from the current page.
            // Android devices below 4.5.0 don't go full screen with videojs
            if (
                (helpers.device.isIOS && helpers.device.iOSVersion < 8) ||
                (helpers.device.isAndroid && window.innerHeight < 501) ||
                (helpers.device.isAndroid && parseFloat(helpers.device.androidVersion) < 4.5)) {
                window.location = this.data.url.http;
                return;
            }

            this.render();
        },

        renderPFGScript: function () {
            this.$el.addClass('isScript');
            this.$el.removeClass('isVideo isAudio isIframe');

            var parent = this.$el[0]
            while (parent.hasChildNodes()) {
                parent.firstChild.remove()
            }

            var script = document.createElement('script');
            var closeButton = document.createElement('span');
            closeButton.textContent = 'X';
            closeButton.classList.add('m-media-closeStream');
            script.src = '//player.performgroup.com/csb.js';

            script.dataset.uuid = this.token.get('fixture_id');
            script.dataset.outletkey = this.token.get('outlet_key');
            script.dataset.apptype = 'csb';
            script.dataset.env = 'betting';
            script.dataset.streamuuid = this.token.get('asset_id');
            script.dataset.streamuser = this.token.get('username');
            script.dataset.oauthtoken =  this.token.get('auth_token');
            script.dataset.width = $(window).width();
            script.dataset.rmg = 'false';
            script.dataset.headerdisabled = 'true';
            script.dataset.footerdisabled = 'true';

            parent.appendChild(script);
            parent.appendChild(closeButton);

            $(closeButton).on('click', function() {
                while (parent.hasChildNodes()) {
                    parent.firstChild.remove()
                }
            })
        },

        constructStreamUrl: function () {
            var resource = _.find(this.data.resources, (resource) => {
                return resource.idResource === this.data.idResource;
            });

            this.data.video = true;

            if (resource && resource.technology === 'MP3') {
                this.data.audio = true;
                this.data.video = false;
            }

            // Hotfix MNT-2383
            if (this.data.idChannel == 18) {
                return {
                    rtmp: 'http://wowzaprodhd50-lh.akamaihd.net/z/5e08aadd_1@147070/manifest.f4m',
                    http: 'http://wowzaprodhd50-lh.akamaihd.net/i/5e08aadd_1@147070/master.m3u8'
                };
            }

            // Hexaglobe
            if (this.data.provider === 'HEX' && this.data.streamType === 'LIV') {
                return {
                    http: this.token.get('stream_url')
                }
            }

            // EGT
            if (this.data.provider === 'EGT' && this.data.streamType !== 'LIV') {
                return {
                    http: this.token.get('stream_url')
                }
            }

            var path = '';
            if (this.data.provider === 'TV1' && this.data.streamType === 'LIV') {
                path = this.data.folder + resource.file;
            } else if (this.data.streamType === 'LIV') {
                path = resource.file;
            } else {
                let streamPath = this.data.archiveLink.split('/');
                let raceDate = moment(streamPath[3].substr(0,4)+'-'+streamPath[3].substr(4,2)+'-'+streamPath[3].substr(6), 'YYYY-MM-DD');

                if (this.data.provider === 'HEX' || raceDate.isBefore(moment().subtract(2, 'days'))) {
                    path = 'https://vod.racebets.com/' + streamPath[2] + '/' + streamPath[3].substr(0,4) + '/' + streamPath[3].substr(4,2) + '/' + streamPath[3].substr(6) + '/' + streamPath[4]  + '/' + streamPath[5];

                    return {
                        http: path
                    };
                }

                path = this.data.archiveLink;
            }

            var token = '7RqTJHOTi9927014';

            var rtmpUrl = 'rtmp://xapp990314496c27014.s.' + (this.data.streamType === 'LIV' ? 'l' : 'o') + '.f.lb.core-cdn.net/' + token + '/27014rci/flv:' + path;
            var httpUrl = 'http://xapp990314496c27014.f.' + (this.data.streamType === 'LIV' ? 'l' : 'o') + '.z.lb.core-cdn.net/' + (this.data.streamType === 'LIV' ? '10049xrci' : '10062xrciod') + '/_definst_/27014rci/' + path + '/playlist.m3u8?key=' + token;

            return {
                rtmp: rtmpUrl,
                http: httpUrl
            };
        },

        render: function () {
            if (app.player) {
                app.player.dispose();
                this.$el.empty();
            }

            this.$el.html(this.template(this.data));

            if (this.data.video) {
                this.$el.addClass('isVideo');
                this.$el.removeClass('isAudio isScript');
            }

            if (this.data.audio) {
                this.$el.addClass('isAudio');
                this.$el.removeClass('isVideo isScript');
            }

            if (this.data.iframe) {
                this.$el.addClass('isIframe');
                this.$el.removeClass('isVideo isAudio isScript');

                return this;
            }

            if (this.data.script) {
                this.renderPFGScript();
                return this;
            }
            // video.js
            app.player = this.videojs('media-player');
            var self = this;
            var Button = this.videojs.getComponent('Button');
            var exitButtonClass = new Button(app.player, {
                clickHandler: function() {
                    app.player.dispose();
                    self.$el.empty();
                    app.player = null;
                }
            });

            var exitButtonClassInstance = app.player.controlBar.addChild(exitButtonClass);
            exitButtonClassInstance.controlText('close');
            exitButtonClassInstance.addClass('vjs-close-control');

            if (!app.mediaHasOrientationListener) { // prevents more events listener to be attached
                window.addEventListener("orientationchange", () => {
                    app.mediaHasOrientationListener = true;

                    let angle;
                    if (!_.isUndefined(window.screen.orientation)) { //https://www.w3.org/TR/screen-orientation/#screenorientation-interface
                        angle = window.screen.orientation.angle;
                    } else if (!_.isUndefined(window.orientation)) { //Deprecated but still in use
                        angle = window.orientation
                    } else {
                        angle = 0;
                    }

                    if (angle !== 0) {
                        if (app.player) {
                            app.player.requestFullscreen();
                        }
                        if (!this.isFullscreen) {
                            this.$el.addClass('fullscreen');
                            this.isFullscreen = true;
                        }
                    } else {
                        if (this.isFullscreen) {
                            this.$el.removeClass('fullscreen');
                            this.isFullscreen = false;
                        }
                    }
                });
            }

            var self = this;
            this.$('video').on('webkitendfullscreen', function () {
                app.player.dispose();
                self.$el.empty();
                app.player = null;
            });

            this.$('audio').on('pause', function () {
                app.player.dispose();
                self.$el.empty();
                app.player = null;
            });

            return this;
        }
    })
);

export default MediaView;
