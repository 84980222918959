import app                                  from 'components/core/application';
import viewManager                          from 'components/core/ViewManager';

const BonusRouter = {
    routes: {
        'bonus': 'bonusRedirect',
        'bonus/overview': 'bonusOverview',
        'bonus/claim': 'bonusClaim',
        'bonus/progress(/:deep)': 'bonusProgress',//deep stands for deep link
        'bonus/phone': 'bonusPhone',
        'bonus/verification': 'bonusPhoneVerification',
    },
    controllers: {
        bonusRedirect: function () {
            app.router.navigateTo(`bonus/overview${window.location.search}`);
        },
        bonusOverview: function () {
            import(
                /* webpackChunkName: "bonus" */
                'modules/bonus/bonus-overview'
            ).then(({default: BonusOverviewView}) => {
                app.subHeader.title(app.polyglot.t('h_pending_bonuses'));
                viewManager.removePrevView().show(new BonusOverviewView())
            });
        },

        bonusClaim: function () {
            import(
                /* webpackChunkName: "bonus" */
                'modules/bonus/claim'
            ).then(({default: BonusClaimView}) => {
                app.subHeader.title(app.polyglot.t('h_claim_bonus'));
                viewManager.removePrevView().show(new BonusClaimView());
            });
        },

        bonusProgress: function (internal=null) {
            import(
                /* webpackChunkName: "bonus" */
                'modules/bonus/progress'
            ).then(({default: BonusProgressView}) => {
                app.subHeader.title(app.polyglot.t('label_bonus'));
                viewManager.removePrevView().show(new BonusProgressView({internal}));
            });
        },

        bonusPhone: function () {
            import(
                /* webpackChunkName: "bonus" */
                'modules/bonus/phone'
            ).then(({default: BonusPhoneView}) => {
                app.subHeader.title(app.polyglot.t('label_bonus'));
                viewManager.removePrevView().show(new BonusPhoneView());
            });
        },

        bonusPhoneVerification: function () {
            import(
                /* webpackChunkName: "bonus" */
                'modules/bonus/verification'
            ).then(({default: BonusPhoneVerificationView}) => {
                app.subHeader.title(app.polyglot.t('label_bonus'));
                viewManager.removePrevView().show(new BonusPhoneVerificationView());
            });
        },
    }
}

export default BonusRouter;
