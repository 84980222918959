var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
    var helper;

  return " id=\""
    + this.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" ";
},"3":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "                <div class=\"c-selectStyled__optionsTitle\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.selected : depth0)) != null ? stack1.label : stack1), depth0))
    + "</div>\n                <div class=\"c-selectStyled__optionsValue\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.selected : depth0)) != null ? stack1.value : stack1), depth0))
    + "</div>\n";
},"5":function(depth0,helpers,partials,data) {
    var stack1;

  return "                <div class=\"c-selectStyled__optionsCategory c-selectStyled__optionsCategory--centered\">"
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.selected : depth0)) != null ? stack1.label : stack1), depth0))
    + "</div>\n";
},"7":function(depth0,helpers,partials,data) {
    var stack1, helper;

  return "                <li data-option-key=\""
    + this.escapeExpression(((helper = (helper = helpers.key || (depth0 != null ? depth0.key : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"key","hash":{},"data":data}) : helper)))
    + "\" class=\"c-selectStyled__optionsItem\">\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.value : depth0),{"name":"if","hash":{},"fn":this.program(8, data, 0),"inverse":this.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + "                </li>\n";
},"8":function(depth0,helpers,partials,data) {
    var helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "                        <div class=\"c-selectStyled__optionsTitle\">"
    + alias3(((helper = (helper = helpers.label || (depth0 != null ? depth0.label : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"label","hash":{},"data":data}) : helper)))
    + "</div>\n                        <div class=\"c-selectStyled__optionsValue\">"
    + alias3(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"value","hash":{},"data":data}) : helper)))
    + "</div>\n";
},"10":function(depth0,helpers,partials,data) {
    var helper;

  return "                        <div class=\"c-selectStyled__optionsCategory c-selectStyled__optionsCategory--centered\">"
    + this.escapeExpression(((helper = (helper = helpers.label || (depth0 != null ? depth0.label : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"label","hash":{},"data":data}) : helper)))
    + "</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<div "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.id : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " class=\"c-selectStyled__wrapper\">\n    <div class=\"c-selectStyled__selected\" data-toggle>\n        <div class=\"c-selectStyled__selectedValue\">\n"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.selected : depth0)) != null ? stack1.value : stack1),{"name":"if","hash":{},"fn":this.program(3, data, 0),"inverse":this.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "        </div>\n        <i class=\"rb-icon tick\" data-arrow></i>\n\n        <ul class=\"c-selectStyled__options\">\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.options : depth0),{"name":"each","hash":{},"fn":this.program(7, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "        </ul>\n    </div>\n</div>\n";
},"useData":true});