'use strict';

/**
 * Backbone.ParentTabbedViewInstance mixin
 * Applicable to uninstantinated object
 *
 * @description
 *      View instance manager
 *
 * @type {Object}
 */

export default {
    /**
     * @method ensureSameParentView
     *
     * @description
     *     Return existing view instance or create a new one
     */
    ensureSameParentView: function (opt) {
        if (!this._instance || this.cleanUp) {
            this._instance = new this(opt);
        } else {
            //there is no need to reinsert the view if it is no a new one. Is used by viewManager to process tabbed view
            this._instance.options = opt;
            this._instance.options.parentView = this._instance;
            this._instance.isInserted = true;
        }

        this._instance.activeTab = opt.activeTab;
        return this._instance;
    }
};
