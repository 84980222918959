var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
    return "<span class=\"rb-icon rb-icon--info-2\" data-tax-info></span>";
},"3":function(depth0,helpers,partials,data) {
    var stack1;

  return "                <div class=\"m-bonus-warning m-bonus-warning--betslip "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.hasOnlyBonus : depth0),{"name":"if","hash":{},"fn":this.program(4, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " m-quick__section\" data-bonus-warning>\n                    <div class=\"c-alert c-alert--info\">\n                        <div class=\"c-alert__title\"><span class=\"rb-icon rb-icon--info-2\"></span> "
    + this.escapeExpression(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"msg_bonus_money_info",{"name":"translate","hash":{},"data":data}))
    + "</div>\n                        <div class=\"c-alert__body\" data-bonus-warning-message>\n                                "
    + ((stack1 = __default(require("../helpers/handlebar-helpers/translateInterpolate.js")).call(depth0,"txt_bonus_money_info",{"name":"translateInterpolate","hash":{"url":"https://support.racebets.com/en.html?category=promotions-and-bonuses"},"data":data})) != null ? stack1 : "")
    + "\n                        </div>\n                    </div>\n                </div>\n";
},"4":function(depth0,helpers,partials,data) {
    return " m-bonus-warning--visible";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.escapeExpression;

  return "<div class=\"m-betslip\" data-wrap><!--add this class to show update states - m-betslip--updated  -->\n    <div class=\"m-betslip__header\">\n        <div class=\"c-grid c-grid--noGutter\">\n            <div class=\"c-grid__col is-1-3 text-left m-betslip__iconContainer\">\n                <span class=\"rb-icon rb-icon--trash\"></span>\n                <div class=\"m-betslip__iconBackground m-betslip__iconBackground--removeAll\" data-clear-all></div>\n            </div>\n            <div class=\"c-grid__col is-1-3 text-center\">\n                <span class=\"m-betslip__title\">"
    + alias1(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_betslip",{"name":"translate","hash":{},"data":data}))
    + "</span>\n            </div>\n            <div class=\"c-grid__col is-1-3 text-right m-betslip__iconContainer\">\n                <span class=\"rb-icon rb-icon--cancel\"></span>\n                <div class=\"m-betslip__iconBackground m-betslip__iconBackground--close\" data-close></div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"m-betslip__updateMessage\">"
    + alias1(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"msg_betslip_updated",{"name":"translate","hash":{},"data":data}))
    + "</div>\n\n    <div data-bet-list></div>\n    <div data-multiples-list></div>\n\n    <div class=\"m-betslip__sectionBottom\">\n        <div class=\"m-betslip__waves\"></div>\n\n        <div class=\"m-betslip__errorMessage\" data-add-unit-msg></div>\n        <div class=\"m-betslip__errorMessage\" data-max-race-reached>"
    + alias1(__default(require("../helpers/handlebar-helpers/translateInterpolate.js")).call(depth0,"msg_error_max_bet_number_reached",{"name":"translateInterpolate","hash":{"number":8},"data":data}))
    + "</div>\n        <div class=\"m-betslip__errorMessage line-height-inherit\" data-betslip-invalid>"
    + alias1(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"error_betslip_invalid",{"name":"translate","hash":{},"data":data}))
    + " "
    + alias1(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"msg_same_market_only",{"name":"translate","hash":{},"data":data}))
    + "</div>\n\n        <div class=\"m-betslip__bottomInner\">\n\n            <div class=\"m-betslip__bottomAction\">\n                <div class=\"mb1\" data-update-messages></div>\n                <div class=\"table-row\">\n                    <div class=\"table-cell\">\n                        <a href=\"\" data-accept-all role=\"button\" class=\"c-btn c-btn--block c-btn--betslipActionBatch mt1\">"
    + alias1(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_accept_all",{"name":"translate","hash":{},"data":data}))
    + "</a>\n                    </div>\n                </div>\n            </div>\n\n            <div class=\"table-row m-betslip__totalAmountRow\">\n                <div class=\"table-cell m-betslip__primaryInfo\">\n                    "
    + alias1(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_total_cost",{"name":"translate","hash":{},"data":data}))
    + "\n                </div>\n                <div class=\"table-cell m-betslip__primaryInfo text-right\">\n                    "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.show_tax : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n                    <span data-total-stake></span>\n                </div>\n            </div>\n\n            <div class=\"m-betslip__taxInfo\" data-info-container data-tax-info-container>\n                <div class=\"table-row m-betslip__infRow\">\n                    <div class=\"table-cell m-betslip__secondaryInfo\">\n                        "
    + alias1(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_total_stake",{"name":"translate","hash":{},"data":data}))
    + "\n                    </div>\n                    <div class=\"table-cell m-betslip__secondaryInfo text-right\" data-total-stake-without_tax></div>\n                </div>\n\n                <div class=\"table-row m-betslip__infRow\">\n                    <div class=\"table-cell m-betslip__secondaryInfo\">\n                        "
    + alias1(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_tax_RWLG",{"name":"translate","hash":{},"data":data}))
    + "\n                    </div>\n                    <div class=\"table-cell m-betslip__secondaryInfo text-right\" data-tax-value></div>\n                </div>\n            </div>\n\n            <div class=\"table-row\">\n                <div class=\"table-cell m-betslip__secondaryInfo\">\n                    "
    + alias1(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_num_bets",{"name":"translate","hash":{},"data":data}))
    + "\n                </div>\n                <div class=\"table-cell m-betslip__secondaryInfo text-right\" data-total-bet-count></div>\n            </div>\n\n            <div class=\"table-row\">\n                <div class=\"table-cell m-betslip__secondaryInfo\">\n                    "
    + alias1(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_potential_winnings",{"name":"translate","hash":{},"data":data}))
    + "\n                </div>\n                <div class=\"table-cell m-betslip__secondaryInfo text-right\" data-winnings>"
    + alias1(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_not_available",{"name":"translate","hash":{},"data":data}))
    + "</div>\n            </div>\n\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.isUkCustomer : depth0),{"name":"if","hash":{},"fn":this.program(3, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n            <input data-place-bet type=\"button\" class=\"c-btn c-btn--action mt2\" value=\""
    + alias1(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_place_bet",{"name":"translate","hash":{},"data":data}))
    + "\">\n        </div>\n    </div>\n</div>\n";
},"useData":true});