'use strict';
import $                            from 'jquery';
import _                            from 'underscore';
import app                          from 'components/core/application';
import Backbone                     from 'backbone';
import BackboneCloseView            from 'components/core/mixins/Backbone.CloseView';
import template                     from 'modules/mybets/mybets-settled.tpl.hbs';
import MyBetsSettledCollection      from 'modules/mybets/mybets-settled.collection';
import pagination                   from 'components/helpers/pagination';
import moment                       from 'moment-timezone';
import helpers                      from 'components/helpers/helpers';

/**
 * Settled Bets view
 *
 * @name SettledBets
 * @constructor
 *
 * @description
 * 		SettledBets view, display all settled bets.
 *
 * @returns {Function} - Constructor function for SettledBets
 */

var SettledBets = Backbone.View.extend(
    _.extend({}, BackboneCloseView, {

        className: 'm-mybets',

        template: template,

        events: function () {
            var events = {};
            if (helpers.device.isAndroid) {
                // Android triggers change when user is done selecting a new date
                events['change [data-from]'] = 'changeDate';
                events['change [data-to]'] = 'changeDate';
            } else {
                // iOS triggers change all the time
                events['blur [data-from]'] = 'changeDate';
                events['blur [data-to]'] = 'changeDate';
            }

            events['click [data-page]'] = 'goToPage';
            events['click [data-id]'] = 'goToDetail';
            events['click [data-reset]'] = 'resetDate';
            events['click [data-idrace]'] = 'goToRace';


            return events;
        },

        initialize: function (opt) {
            this.options = opt;
            this.DOM = {};
            this.data = {
                loaded: false
            };
            this.collection = new MyBetsSettledCollection(opt.from, opt.to, opt.page);
            this.collection.fetch();

            this.listenTo(this.collection, 'mybets:error', this.handleError);
            this.listenTo(this.collection, 'sync', this.show);
        },

        render: function () {
            this.$el.html(this.template(this.data));
            this.DOM.from = this.$('[data-from]');
            this.DOM.to = this.$('[data-to]');

            return this;
        },

        show: function () {
            this.data = {
                loaded: true,
                today: moment().format('YYYY-MM-DD'),
                from: this.collection.from.trim(),
                to: this.collection.to.trim(),
                results: this.collection.toJSON(),
                pagination: pagination.create(this.collection.page, this.collection.pages)
            };
            this.render();
        },

        goToPage: function (e) {
            var page = this.collection.page;
            if (e.target.hasAttribute('data-next')) {
                page++;
            } else if (e.target.hasAttribute('data-prev')) {
                page--;
            } else if (e.target.hasAttribute('data-first')) {
                page = 1;
            } else if (e.target.hasAttribute('data-last')) {
                page = this.collection.pages;
            } else {
                page = $(e.target).attr('data-page');
            }

            if (page !== this.collection.page) {
                this.collection.changePage(page);
            }
        },

        goToDetail: function (e) {
            var id = $(e.currentTarget).attr('data-id');
            app.router.navigate('betslip/id/' + id, {trigger: true});
        },

        goToRace: function (e) {
            let idRace = $(e.currentTarget).attr('data-idrace');
            app.router.navigate(`/race/details/id/${idRace}`, { trigger: true });
        },

        // test that 'from' input value isn't after 'to' input value
        checkRange: function () {
            if (moment(this.DOM.from.val()).isAfter(this.DOM.to.val())) {
                this.DOM.from.val(this.DOM.to.val());
            }
        },

        changeDate: function (e) {
            this.checkRange();

            var val = $(e.target).val(),
                type = $(e.target).attr('data-from') ? 'from' : 'to';

            this.collection.changeDate({
                val: val,
                type: type
            });
        },

        handleError: function (error) {
            if (error === 'DATE_RANGE_LIMIT') {
                app.trigger('dialog:error', app.interpolate('msg_error_time_range_exceeded', {days: 31}));
            }
            if (error === 'FROM_DATE_LIMIT') {
                app.trigger('dialog:error', app.interpolate('msg_error_date_from_exceeded', {days: 180}));
            }
        },

        resetDate: function () {
            this.collection.resetDate();
        }
    })
);

export default SettledBets;
