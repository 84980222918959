import app                                          from 'components/core/application';
import user                                         from 'components/user/user';
import template                                     from 'components/snack-bar/snack-bar-consent.tpl.hbs';
import {
    ROUTE_COOKIE_SETTINGS,
    ROUTE_MARKETING_SETTINGS,
    ROUTE_CONSENT_REQUIRED
}                                                   from 'components/app.settings';
import { setCookie, triggerCookieAcceptedEvent }    from 'modules/cookie-settings/marketing-cookie-settings.service';
import { SNACK_BAR_VIEW_EVENTS, SnackBar }          from 'components/snack-bar/snack-bar';
import helpers from 'components/helpers/helpers';

/**
 * SnackBarConsent view
 *
 * @name SnackBarConsent
 * @constructor
 *
 * @requires app
 * @requires user
 * @requires SnackBar
 * @requires template
 * @requires appSettings
 *
 * @description
 *     SnackBar view Controller.
 *
 * @returns {Function} - Constructor function for SnackBarConsent
 */

const SnackBarConsent = SnackBar.extend({

    template,

    events: {
        'click [data-accept]': 'accept',
    },

    initialize() {
        this.settingsUrl = user.isLoggedIn ? (user.consentRequired() ? ROUTE_CONSENT_REQUIRED : ROUTE_MARKETING_SETTINGS) : ROUTE_COOKIE_SETTINGS;
        this.data = {
            content: app.interpolate('txt_cookie_consent', {policyLink: '/info/cookie-policy', settingsLink: `/${this.settingsUrl}`})
        }
    },

    accept(e) {
        e.preventDefault();

        setCookie({ marketing: 1 });
        this.trigger(SNACK_BAR_VIEW_EVENTS.CLOSE);
        triggerCookieAcceptedEvent(true);

        // User gesture required for Safari
        setTimeout(function(){
            app.trigger('xtremepush:injected');
         }, 3000);
    },

});

export default SnackBarConsent;
