'use strict';

import $                            from 'jquery';
import _                            from 'underscore';
import Backbone                     from 'backbone';
import Hammer                       from 'hammerjs';
import app                          from 'components/core/application';
import maybe                        from 'components/helpers/maybe';
import BackboneCloseView            from 'components/core/mixins/Backbone.CloseView';
import template                     from 'modules/race/runner-form.tpl.hbs';
import RunnerFormModel              from 'modules/race/runner-form.model';
import RaceModel                    from 'components/model/race.model';
import auth                         from 'components/auth/auth';
import MediaView                    from 'components/media/media';

/**
 * Event view
 *
 * @name RunnerFormView
 * @constructor
 *
 * @requires jQuery
 * @requires Underscore
 * @requires BackboneCloseView
 * @requires template
 *
 * @description
 * 		Runner form view.
 *
 * @returns {Function} - Constructor function for RunnerFormView
 */

var RunnerFormView = Backbone.View.extend(
    _.extend({}, BackboneCloseView, {

        template: template,

        events: {
            'click [data-video]': 'loadVideo',
            'click [data-race-link]': 'goToRace',
            'click [data-id]': 'goToForm',
            'click [data-stop-event]': 'stopPropagation'
        },

        className: 'c-rform__el',

        initialize: function (opt) {
            this.DOM = {};
            this.data = {};
            this.parentView = opt.parentView;

            _.extend(this.data, opt.data);

            this.data.loaded = false;
            this.isLoading = false;

            this.model = new RunnerFormModel(null, {
                idRace: this.data.idRace,
                idSubject: this.data.idSubject
            });

            // defaults for the raceModel
            this.raceModel = {
                attributes: {}
            };

            this.listenTo(this.model, 'sync', () => {
                this.error = false;
                // using the race model to format the race data
                this.raceModel = new RaceModel(this.model.data.race);
                this.render();
                this.parentView.trigger('toggle:tab:loader');
                this.isLoading = false;
            });

            this.listenTo(this.model, 'error', () => {
                this.error = true;
                this.render();
                this.parentView.trigger('toggle:tab:loader');
                this.isLoading = false;
            });
        },

        load: function () {
            this.parentView.trigger('toggle:tab:loader');
            this.model.fetch();
        },

        loadVideo: function () {
            auth.ifLoggedIn(function () {
                let stream = {
                    type: 'VID',
                    streamType: 'MP4',
                    provider: 'TV1',
                    archiveLink: this.raceModel.attributes.archiveLink
                };
                app.mediaView = null;
                app.mediaView = new MediaView(stream);
            }, this)()
        },

        goToRace: function () {
            app.router.navigate('/race/details/id/' + this.model.attributes.ref.current, {trigger: true});
        },

        goToForm: function (e) {
            e.stopPropagation();
            this.DOM.slide.addClass('isFadingOut');
            this.DOM.slideLoader.addClass('isActive');
            let raceId = $(e.currentTarget).attr('data-id');
            this.model.opt.idRaceForm = raceId;
            this.model.fetch();
        },

        stopPropagation: function (e) {
            e.stopPropagation();
        },

        getRefRacesLabel: function (races=[], idRace) {
            if (idRace) {
                let num = races.indexOf(idRace)+1;
                return app.interpolate('label_race_number_of_races_count', {number: num, count: races.length});
            }
            return null;
        },

        render: function () {
            let refRaces = maybe.of(this.model.attributes).mapDotProp('ref.races').join();
            let currentRef = maybe.of(this.model.attributes).mapDotProp('ref.current').join();

            this.$el.html(this.template({
                form: this.model.attributes,
                show_ref: refRaces && refRaces.length > 0,
                ref_races_label: this.getRefRacesLabel(refRaces, currentRef),
                race: this.raceModel.attributes,
                runnerIdSubject: this.data.idSubject,
                error: this.error
            }));

            this.DOM.slide = this.$('[data-slide]');
            this.DOM.wrap = this.$('[data-wrap]');
            this.DOM.slideLoader = this.$('[data-slide-loader]');

            if (_.isUndefined(this.hammertime)) {
                this.hammertime = new Hammer(this.el);
                this.hammertime.get('swipe').set({ direction: Hammer.DIRECTION_HORIZONTAL });
                this.hammertime.on('swipeleft', (ev) => {
                    var raceId = this.model.attributes.ref.previous;
                    if (raceId !== null && !this.isLoading) {
                        this.DOM.slide.addClass('isSwipingLeft');
                        this.DOM.slideLoader.addClass('isActive');
                        this.isLoading = true;
                        this.model.opt.idRaceForm = raceId;
                        this.model.fetch();
                    }
                });
                this.hammertime.on('swiperight', (ev) => {
                    var raceId = this.model.attributes.ref.next;
                    if (raceId !== null && !this.isLoading) {
                        this.DOM.slide.addClass('isSwipingRight');
                        this.DOM.slideLoader.addClass('isActive');
                        this.isLoading = true;
                        this.model.opt.idRaceForm = raceId;
                        this.model.fetch();
                    }
                });
            }

            this.trigger('form:render');
            return this;
        }
    })
);

export default RunnerFormView;


