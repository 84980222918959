'use strict';

import _                     from 'underscore';
import $                     from 'jquery';
import Backbone              from 'backbone';
import template              from 'components/betting/bet-multiples-item.tpl.hbs';
import helpers               from 'components/helpers/helpers';
import BackboneCloseView     from 'components/core/mixins/Backbone.CloseView';

/**
 * BetItem item view Controller
 * @name BetItem
 * @constructor
 *
 * @requires Underscore
 * @requires jQuery
 * @requires Backbone
 * @requires template
 * @requires helpers
 * @requires BackboneCloseView
 *
 * @description
 *     BetItemMultiple View Controller
 *
 * @returns Function - Constructor function for BetItemMultiple view
 */

var BetItemMultiple = {

        className: 'm-betslip__bet m-betslip__bet--multiples p-0 pb1-5 c-form',//m-betslip__bet--updated

        template: template,

        events: {
            'keyup [data-unit]': 'amountChanged',
            'click [data-checkbox-each-way]': 'eachWay',
            'keyup input': 'manageInputErrors'
        },

        initialize: function (opts) {
            this.model = opts.model || undefined;
            this.DOM = {};

            this.listenTo(this.model, 'change:parts', this.onPartsChange);
            this.listenTo(this.model, 'invalid', this.onInvalid);
        },

        render: function () {
            this.$el.html(this.template(this.model.toJSON()));

            this.onRender();
            return this;
        },

        onRender: function () {
            this.DOM = {
                odds_select: this.$('[data-odds-select]'),
                winnings: this.$('[data-winnings]')
            };
        },

        onInvalid: function(model, errors) {
            if(!this.DOM.vmc) this.DOM.vmc = {};

            for(var i = 0, l = errors.length; i < l; i++) {
                var field = errors[i];
                //cache validation message container object if has not been cached yet
                if(!this.DOM.vmc[field.name]) this.DOM.vmc[field.name] = this.$('[data-validation_' + field.name + ']');
                if(!this.DOM[field.name]) this.DOM[field.name] = this.$('[data-' + field.name + ']');

                this.DOM.vmc[field.name].html(field.message).show();
                this.DOM[field.name].addClass('c-form__input--invalid');
            }
        },

        manageInputErrors: function(e) {
            var id = $(e.target).data('id');

            if(this.DOM[id]) this.DOM[id].removeClass('c-form__input--invalid');
            if(this.DOM.vmc && this.DOM.vmc[id]) this.DOM.vmc[id].hide();
        },

        amountChanged: function (e) {
            var unit = $(e.target).val();
            unit = unit && _.isNumber(unit) ? parseFloat(unit.replace(',', '.')) : unit;

            var _stake = helpers.deepCopy(this.model.attributes.stake);

            if(unit > 0) {
                _stake.unit = unit;
            } else {
                delete _stake.unit;
            }

            this.model.attributes.user_entered_unit = true;
            //we need to use 'set' helper to trigger 'change' event on a model
            this.model.unset('stake', {silent: true});
            this.model.set({stake: _stake});
        },

        eachWay: function (e) {
            e.stopPropagation();
            var checked = $(e.target).is(':checked');
            var _stake = helpers.deepCopy(this.model.attributes.stake);

            // this.model.set({isEachWay: checked}, {silent: true});

            //multiply amount if eachWay
            if(checked) {
                _stake.amount *= 2;
            } else {
                _stake.amount /= 2;
            }

            //we need to use 'set' helper to trigger 'change' event on a model
            this.model.unset('stake', {silent: true});
            this.model.set({isEachWay: checked, stake: _stake});
        }
    }

export default _.extend({}, BetItemMultiple, BackboneCloseView);
