'use strict';

import app from 'components/core/application';

/**
 * View manager - Singleton
 * @name viewManager
 * @type {Object}
 *
 * @description
 *     Cleans up after previous view and shows the next one on route change
 *
 * @returns {Object}
 */
// @TODO: operate on history to determine transition direction
export default {
    tag: app.DOM.appMain,
    scrollTag: app.DOM.appScroll,

    /**
     * @function removePrevView
     * @description
     *     This method needs to be called before the new view is initiated.
     *     Calls close() on previous view
     *     Close() is not called if a view's and prevView's sid equals.
     *     It is assumed that if it is the same view, it is either a parent or a tabbed view
     *     and only one instance of it is required.
     *
     * @param {Function} view - View constructor
     * @param {Function} subView - subView constructor
     */
    removePrevView: function() {
        if(this.prevView) {
            this.prevView.close();
            this.prevView = null;
        }
        return this;
    },

    /**
     * @function show
     * @description
     *     Show view on route change
     *
     * @param {Function} view - View constructor
     */
    show: function(view) {
        app.trigger('menu:changeSelected');
        this.prevView = view;
        this.tag.empty().append(view.render().$el);
        this.scrollTag.scrollTop(0);
    },

    /**
     * @function showWithSubView
     * @description
     *     Show subView on route change
     *
     * @param {Function} view - View constructor
     */
    showWithSubView: function(view) {
        //save the view if it is a tabbed view and has not yet been saved to prevView
        //Since view extend from base view in a way of shallow copy,
        //previous view may not be closed due to referencing the newly created view, because some values
        //may be inherited by reference. This is why for non tabbed views removePreviousView is called before
        //the new view is initiated.
        //Todo: rewrite tabbed view to maybe contain router or process routes for nested views in a different way
        if(view.isTabbedView) {
            if(this.prevView && view.cid !== this.prevView.cid) {
                this.prevView.close();
            }
            this.prevView = view;
        }

        if(view.isInserted) {
            view.manageSubView();
        } else {
            this.tag.empty().append(view.render().$el);
        }

        this.scrollTag.scrollTop(0);
    }
};
