'use strict';

import Backbone                     from 'backbone';
import moment                       from 'moment-timezone';
import user                         from 'components/user/user';
import helpers                      from 'components/helpers/helpers';

var SpecialEventItemModel = Backbone.Model.extend({
    idAttribute: 'idRace',

    parse: function(data) {
        data.runners.forEach(function(runner){
            runner.oddsLabel = helpers.formatOdds(runner.winOdds, user.getOddsFormat(), true);
        });
        return data;
    },

    getTime: function () {
        var time = this.attributes.postTime ? this.attributes.postTime : this.attributes.raceTime;

        return moment.unix(time).tz(user.timeZone()).format('H:mm');
    },
});

export default SpecialEventItemModel;
