import _                         from 'underscore';
import $                         from 'jquery';
import ViewScroll                from 'components/core/mixins/Backbone.ViewScroll';
import app                       from 'components/core/application';
import BetModel                  from 'components/betting/bet.model';
import betslipHelpers            from 'components/betting/betslip.helpers';
import helpers                   from 'components/helpers/helpers';
import user                      from 'components/user/user';
import BackboneCloseView         from 'components/core/mixins/Backbone.CloseView';
import cSelect                   from 'components/select/Backbone.select';
import CustomAmountView          from 'components/input/custom-amount';
import UnitStakesView            from 'components/betting/unit-stakes';
import freeSelectTemplate        from 'components/betting/select.tpl.hbs';
import BetPlacedDialogView       from 'components/betting/bet_placed';
import BetErrorDialogView        from 'components/betting/bet_placing_error';
import maybe                     from 'components/helpers/maybe';
import auth                      from 'components/auth/auth';

let QuickBet = {

        events: {
            'click [data-wrap]': 'stopPropagation',
            'click [data-close]': 'closeDialog',
            'click [data-each-way-option]': 'eachWayHandler',
            'click [data-category]': 'changeCategory',
            'click [data-bet]': 'quickbet',
            'click [data-betslip]': 'addToBetslip',
            'click [data-bonus-warning]': 'toggleBonusWarningMessage'
        },

        className: 'l-scrollV',

        /**
         * @method initialize
         * @description
         *      Initialize view with options. Setup listeners on model
         *
         * @param opt
         */
        initialize: function(opt={}) {
            this.options = opt;
            this.DOM = {};
            this.buttons = [];

            this.mapData();
            this.setUpBetModel();
            this.setUpOdds();
            this.setupFreeBets();
            this.detectCurrency();
            this.createPresetAmounts();
            this.setUpCustomAmountView();
            this.setupUnitStakesView();
            this.prepareStakeData();
            this.listenToRaceUpdate();
            this.composeEachWayLabel();

            if(this.afterInitialize && typeof this.afterInitialize == 'function') this.afterInitialize.apply(this, arguments);
        },

        detectCurrency: function() {
            this.convert_to_user_currency = this.betModel.attributes.betCategory === 'TOT' && this.betModel.attributes.currency !== user.data.currency;
        },

        mapData: function() {
            this.data = {
                runnerModel: this.options.runnerModel ? this.options.runnerModel : null,
                fixedModel: this.options.fixedModel,
                freebetModel: this.options.freebetModel
            };
        },

        setUpBetModel: function() {
            // assign race taxfees to the fixed bet model
            this.betModel = new BetModel(_.extend({}, this.data.fixedModel.attributes, {betTypes: this.data.runnerModel.attributes.betTypes}));
            this.listenTo(this.betModel, 'invalid', this.showValidationErrors);
            this.listenTo(this.betModel, 'sync', this.processSuccessResponse);

            if (this.data.runnerModel) {
                this.betModel.data = {
                    runnerModel: this.data.runnerModel.attributes,
                    raceModel: this.data.runnerModel.attributes.race
                };
            }

            this.betModel.set('betCategory', this.options.attributes.betCategory); //coming from the button configuration
            this.betModel.set('betType', this.options.attributes.betType); //coming from the button configuration

            //set currency
            this.currency = (this.betModel.get('betCategory') === 'TOT' && this.data.runnerModel) ? this.data.runnerModel.collection.data.event.toteCurrency : user.data.currency;
            this.betModel.set({currency: this.currency}, {silent: true});

            //set bet stake
            //no pre-selection
            this.listenTo(this.betModel, 'change:unitStake', function () {
                this.prepareStakeData();
                this.updateStakeDataDom();
                this.updateCustomAmountLabel();
                this.manageWinnings();
                this.toggleBonusWarning();
            });
        },

        setUpOdds: function() {
            let runnerModelAttrs = maybe.of(this.data).mapDotProp('runnerModel.attributes').orElse(null).join();
            //let fixedModelAttrs = this.data.fixedModel.attributes;

            //var raceEnabled = runnerModelAttrs.race && runnerModelAttrs.race.raceStatus === 'OPN';

            if (runnerModelAttrs) {
                const isSelected = runnerModelAttrs.isSelected && maybe.of(runnerModelAttrs).mapDotProp('race.raceStatus').join() === 'OPN';
                _.each(maybe.of(runnerModelAttrs).mapDotProp('betting.buttons').orElse([]).join(), (button) => {
                    var adjustedCategory = (button.category === 'FXW') ? 'FXD' : button.category; //maps 'FXW' to 'FXD'
                    if (maybe.of(runnerModelAttrs).mapDotProp(`betTypes.normal.${adjustedCategory}.${button.betType}`).join()) {
                        var enabled = button.enabled && (!isSelected || runnerModelAttrs.selectedCategory === adjustedCategory);
                        var btn = this.generateOptions(button, isSelected, enabled, adjustedCategory, runnerModelAttrs);
                        this.buttons.push(btn);
                    }
                })

                this.betModel.attributes.selectedOdds = _.findWhere(this.buttons, {active: true, odds_f: !null});
            }
        },

        createPresetAmounts: function() {
            const stake_restrictions = betslipHelpers.getBetTypeWithStakes(this.betModel.get('betCategory'), this.betModel.get('betType'), this.data.runnerModel.attributes.betTypes);
            const minUnitStake = stake_restrictions[0] || 0;

            this.data.personalUnitStakes = _.map(this.data.fixedModel.attributes.personalUnitStakes, (stake, i) => {
                //set first preset value to be same as minTotalStake if minTotalStake is a higher number
                if(minUnitStake && parseFloat(stake) < minUnitStake) stake = parseFloat(minUnitStake);
                return {
                    label: helpers.formatMoney(stake, stake % 1 > 0 ? 2 : 0, this.currency, true),
                    value: stake,
                    active: false //i === 0 //no pre-selection
                }
            });

            this.data.personalUnitStakes.push({
                label: app.polyglot.t('label_custom_amount'),
                value: 'custom'
            });
        },

        setupUnitStakesView: function() {
            this.unitStakesView = new UnitStakesView({ personalUnitStakes: this.data.personalUnitStakes });
            this.listenTo(this.unitStakesView, 'select:unitStake', this.stakeBtn);
        },

        listenToRaceUpdate: function() {
            this.listenTo(this.data.runnerModel.collection, 'race:race:updated', () => {
                var status = maybe.of(this.data).mapDotProp('runnerModel.collection.data.race.raceStatus').join();
                this.DOM.submitBtn.attr('disabled', status === 'STR');
                this.DOM.betslip_btn.attr('disabled', status === 'STR');

                if(status === 'STR') {
                    this.errorDialogView = new BetErrorDialogView({message: app.polyglot.t('msg_race_started')});

                    app.overlaySecondary.dialog({
                        contentView: this.errorDialogView,
                        secondary: true,
                        closeOnOutsideClick: true
                    });

                    this.errorDialogView.on('dialog:close:btn', function () {
                        app.overlaySecondary.closeDialog();
                        app.overlay.closeDialog();
                    }, this);

                    app.overlaySecondary.component
                        .render()
                        .delegateEvents();
                }
            });
        },

        getLabel: function(category) {
            var label;

            switch(category) {
                case 'FXD':
                    label = app.polyglot.t('label_bet_category_FXD');
                    break;
                case 'BOK':
                    label = app.polyglot.t('label_starting_price_short');
                    break;
                case 'TOT':
                    label = app.polyglot.t('label_bet_category_TOT');
                    break;
                default:
                    label = '';
                    break;
            }

            return label;
        },

        generateOptions: function(button, isSelected, enabled, adjustedCategory, runnerModelAttrs) {
            return {
                enabled: enabled,
                label: this.getLabel(adjustedCategory),
                odds: _.isNumber(parseFloat(button.value)) ? button.value : null,
                odds_f: parseFloat(button.value) > 0 ? button.value : null,
                toteCurrency: maybe.of(this).mapDotProp('attributes.raceCollection.data.event.toteCurrency').join(),
                betCategory: adjustedCategory,
                betType: this.options.attributes.betType,
                model: this.model,
                active: adjustedCategory === this.options.attributes.betCategory
            }
        },

        composeEachWayLabel: function(category=null) {
            let fixedModelAttrs = this.data.fixedModel.attributes;
            this.data.showEachWay = false;
            //H2H has no E/W
            if (!this.options.attributes.isH2H && this.options.attributes.betType === 'WIN' && _.contains(fixedModelAttrs.betTypesV2[category || this.options.attributes.betCategory], 'WP')) {
                this.data.showEachWay = true;
                this.data.eachWayTerms = null;
                if(fixedModelAttrs.spEvent) {
                    this.data.eachWayTerms = helpers.getEachWayTerms(this.data.runnerModel.attributes.race, this.data.runnerModel.attributes.event, user.data.country)
                }
            }
        },
        checkEachWay: function(category=null) {
            this.composeEachWayLabel(category);

            //show or hide checkbox
            this.DOM.data_each_way_wrapper.toggleClass('isHidden', !this.data.showEachWay);
            this.DOM.data_each_way.toggleClass('isHidden', !this.data.showEachWay);

            if(!this.data.showEachWay) this.DOM.eachWayCheckbox.prop('checked', false);
        },

        render: function () {
            if(this.beforeRender && typeof this.beforeRender == 'function') this.beforeRender.apply(this, arguments);
            this.$el.html(this.template(this.getViewData()));
            if(this.onTemplateRendered && typeof this.onTemplateRendered == 'function') this.onTemplateRendered.apply(this, arguments);

            this.DOM.eachWayCheckbox = this.$('[data-each-way-option]');
            this.DOM.userStakesBtn = this.$('[data-stake-btn]');
            this.DOM.customStake = this.$('[data-custom-stake]');
            this.DOM.unitStakeInput = this.$('[data-unitStake-input]');//todo: check if should be moved to rb
            this.DOM.total = this.$('[data-total]');
            this.DOM.tax_value = this.$('[data-tax-value]');
            this.DOM.totalStakeLabel = this.$('[data-stake-label]');
            this.DOM.subvention = this.$('[data-subvention]');
            this.DOM.subventionRate = this.$('[data-subvention-rate]');
            this.DOM.winning = this.$('[data-winning]');
            this.DOM.data_each_way_wrapper = this.$('[data-each-way-wrapper]');
            this.DOM.data_each_way = this.$('[data-each-way]');
            this.DOM.data_winnings = this.$('[data-winnings-wrapper]');
            this.DOM.submitBtn = this.$('[data-bet]');
            this.DOM.stake_required = this.$('[data-stake-required]');
            this.DOM.betslip_btn = this.$('[data-betslip]');
            this.DOM.converted_amount = this.$('[data-converted-amount]');
            this.DOM.bonusWarning = this.$('[data-bonus-warning]');
            this.DOM.bonusWarningMessage = this.$('[data-bonus-warning-message]');
            this.DOM.diffCurrencyWarning = this.$('[data-diff-currency-warning]');
            this.DOM.diffCurrencyMessage = this.$('[data-diff-currency-message]');

            this.DOM.customStake.html(this.customAmountView.render().$el);

            // todo: check if freebets are shown for betsafe
            if(this.data.showFreeBets) {
                this.DOM.freebetsSelectEl = this.$('[data-freebets-select]');
                this.DOM.freebetsSelectEl.html(this.freeBetsSelect.render().$el);
            }

            this.checkEachWay();
            this.manageWinnings();
            this.updateDiffCurrencyInfo();

            if(user.isGerman()) this.updateTax();

            app.overlay.showComponent(this);

            window.setTimeout(() => this.handleScrollToInputs(), 0);

            if(this.onRender && typeof this.onRender == 'function') this.onRender.apply(this, arguments);
            return this;
        },

        onTemplateRendered: function() {
            this.DOM.categoryBtn = this.$('[data-category]');
            this.DOM.userStakes = this.$('[data-user-stakes]');
            this.DOM.userStakes.html(this.unitStakesView.render().$el);
            this.DOM.userStakesCustomBtn = this.$('[data-stake-btn="custom"]');
        },
        /**
         * This function overwrites the eachWayTerms
         */
        getEachWayDialog: function(){
            if(this.data.fixedModel.attributes.spEvent){
                return `1/${this.data.fixedModel.attributes.placeOddsFactor} - ${_.range(1, this.data.fixedModel.attributes.placesNum + 1).join(',')}`
            }else{
                return null;
            }
        },

        getViewData: function() {
            let noH2HinBetslip = betslipHelpers.noH2HinBetslip(this.data.fixedModel, app.betslip.getBets(true));
            let multiplesFxd = maybe.of(this.data.fixedModel).mapDotProp('attributes.multiplesFxd').orElse(false).join();
            let data = {
                attr: this.data.runnerModel ? this.data.runnerModel.toJSON() : null,
                odds: this.options.attributes.odds || null,
                betModel: this.betModel.toJSON(),
                personalUnitStakes: this.data.personalUnitStakes,
                buttons: this.buttons,
                buttonsLength: this.buttons.length,
                showEachWay: this.data.showEachWay,
                user_currency: user.data.currency.toUpperCase(),
                isLoggedIn: user.isLoggedIn,
                winnings: this.data.winnings,
                showEachWayOrWinnings: !_.isUndefined(this.data.showEachWay) || !_.isUndefined(this.data.winningsLabel),
                eachWayLabel: helpers.getEachWayLabel(this.data.fixedModel.attributes.country, this.data.fixedModel.attributes.isAntePost, this.data.fixedModel.attributes.spEvent),
                eachWayTerms: this.options.attributes.isH2H || !this.data.fixedModel.attributes.spEvent ? null: helpers.getEachWayTerms(this.data.runnerModel.attributes.race, this.data.runnerModel.attributes.event, user.data.country),
                winningsLabel: this.data.winningsLabel,
                showFreeBets: this.data.showFreeBets,
                isGermanCustomer: user.isGerman(),
                isUkCustomer: user.isUkCustomer(),
                showDiffCurancyInfo: (this.convert_to_user_currency && ['suaposta', 'europebet'].indexOf(app.brand) > -1),
                disableBetslip: this.options.attributes.disableBetslip || this.betModel.get('betCategory') === 'TOT' || noH2HinBetslip,
                multiplesFxdEnabled: multiplesFxd,
                noSpecialMix: !app.betslip.isEmpty() && noH2HinBetslip ? noH2HinBetslip : false,
                convert_to_user_currency: this.convert_to_user_currency,
                hasOnlyBonus: user.data.balance === user.data.bonusBalance,
                showAddToBetslip: !(['WS', 'PS', 'WPS'].includes(this.options.attributes.betType))
            };
            return _.extend(this.viewData || {}, data);

        },

        stopPropagation: function (e) {
            e.stopPropagation();
        },

        // handles click on unit stakes presets
        stakeBtn: function (value='custom') {
            if (value === 'custom') {
                this.DOM.userStakes.hide();
                this.DOM.customStake.show();
                this.DOM.unitStakeInput.val(helpers.number(this.betModel.get('unitStake'), 2, '.', ''));
                this.DOM.converted_amount.html('').parent().hide();
            } else {
                this.resetFreeBet();
                this.betModel.set('unitStake', value);
                this.updateTax();
                this.updateDiffCurrencyInfo();

                if(this.convert_to_user_currency) {
                    this.DOM.converted_amount.html(Math.round(helpers.exchange(this.betModel.get('unitStake'), this.betModel.attributes.currency, user.data.currency) * 100) / 100).parent().fadeIn('fast');
                }

                this.updateCustomAmountLabel();
            }
            this.DOM.stake_required.hide();
            this.manageWinnings();
        },

        updateDiffCurrencyInfo: function() {
            if (this.convert_to_user_currency && ['suaposta', 'europebet'].indexOf(app.brand) > -1) {
                var parsedAmount =  parseFloat(this.betModel.get('unitStake'));
                if(parsedAmount === 0 || isNaN(parsedAmount)) {
                    this.DOM.diffCurrencyWarning.hide();
                } else {
                    this.DOM.diffCurrencyWarning.show();
                    this.DOM.diffCurrencyMessage.html(
                        app.interpolate('msg_diff_currency_info', {
                            originalCurrency: this.betModel.get('currency'),
                            chargedAmount: (Math.round(helpers.exchange(this.betModel.get('unitStake'), this.betModel.attributes.currency, user.data.currency) * 100) / 100) + ' ' + user.data.currency
                        })
                    );
                }
            }
        },

        showUserStake: function () {
            this.DOM.userStakes.show();
            this.DOM.customStake.hide();
            this.$('[data-stake-btn].isActive').removeClass('isActive');
            this.updateCustomAmountLabel();

            if(this.convert_to_user_currency) {
                this.DOM.converted_amount.html(Math.round(helpers.exchange(this.betModel.get('unitStake'), this.betModel.attributes.currency, user.data.currency) * 100) / 100).parent().fadeIn('fast');
            }
        },

        stakeInput: function (value) {
            this.resetFreeBet();
            this.betModel.set('unitStake', value);
            this.updateTax();
            this.updateDiffCurrencyInfo();
            this.manageWinnings();
        },

        updateCustomAmountLabel: function() {
            var unitStake = parseFloat(this.betModel.get('unitStake'));
            //check if unitStake is same as one of the preset values
            var exists = unitStake > 0 ? _.findWhere(this.data.personalUnitStakes, {value: unitStake}) : true;
            if(exists) {
                //Clear previously held values
                $(this.DOM.userStakesBtn).removeClass('isSelected');
                //find the button and mark as selected (if not selected)
                var toBeSelected = $(this.DOM.userStakesBtn).filter(function() {
                    return parseFloat($(this).data('stake-btn')) === unitStake;
                });

                if(toBeSelected && !toBeSelected.hasClass('isSelected')) toBeSelected.addClass('isSelected');
            }
            var label = exists ? app.polyglot.t('label_custom_amount') : helpers.formatMoney(unitStake, unitStake % 1 > 0 ? 2 : 0, this.currency, true, false);

            if(this.DOM.userStakesCustomBtn) {
                this.DOM.userStakesCustomBtn.toggleClass('isSelected', !exists).find('span.m-quick__btnStake').text(label);
            }
        },

        getTax: function() {
            var attributes = this.betModel.attributes;
            var taxRate = 0;
            var unitStake = attributes.unitStake;

            if (_.contains(['BOK', 'TOT'], attributes.betCategory)) {
                taxRate = attributes.taxRate;
            } else {
                taxRate = attributes.taxRateV2[attributes.betCategory] || 0;
            }

            unitStake = unitStake * attributes.numBets;

            var result = betslipHelpers.calculateTax(unitStake, attributes, taxRate);
            return result.taxAmount ? result.taxAmount : result;
        },

        updateTax: function() {
            var tax = this.getTax();
            if(this.betModel.get('betCategory') === 'TOT') tax = 0;
            this.DOM.tax_value.html(helpers.formatMoney(tax, tax % 1 > 0 ? 2 : 0, user.data.currency, true, true));
        },

        prepareStakeData: function () {
            var attr = this.betModel.attributes;
            this.data.stake = this.betModel.calculateTotalStake();
            this.data.unitStakeLabel = helpers.number(attr.unitStake, 2, '.', '');
            this.data.stake.totalCostLabel = helpers.formatMoney(this.data.stake.totalCost, 2, this.currency, true, true);
            this.data.stake.totalStakeLabel = helpers.formatMoney(this.data.stake.totalStake, 2, this.currency, true, true);

            if (this.data.stake.taxAmount) {
                this.data.stake.taxAmountDict = app.polyglot.t('label_tax_RWLG');
                this.data.stake.taxAmountLabel = helpers.formatMoney(this.data.stake.taxAmount, 2, this.currency, true, true);
            } else {
                this.data.stake.taxAmountDict = '';
                this.data.stake.taxAmountLabel = '';
            }

            if (this.data.stake.taxSubventionAmount) {
                this.data.stake.taxSubventionRateLabel = app.interpolate('label_tax_subvention', {amount: helpers.number(this.data.stake.taxSubventionRate, 1, undefined, undefined, true) + '%'});
                this.data.stake.taxSubventionAmountLabel = '-' + helpers.formatMoney(this.data.stake.taxSubventionAmount, 2, this.currency, true, true);
            } else {
                this.data.stake.taxSubventionRateLabel = '';
                this.data.stake.taxSubventionAmountLabel = '';
            }

            this.data.tax = user.data.taxFees ? user.data.taxFees.taxable : null;
        },

        manageWinnings: function(category=null) {
            this.data.winnings = 0;
            // calculate winnings only if the category is FXD
            if (category === 'FXD' || this.betModel.get('betCategory') === 'FXD') {
                this.data.winnings = this.betModel.calculateWinnings();
                this.data.winningsLabel = helpers.formatMoney(this.data.winnings, 2, this.currency, true, true);
            } else {
                this.data.winningsLabel = 'N/A';
            }

            this.DOM.winning.html(this.data.winningsLabel);
            this.DOM.data_each_way_wrapper.toggleClass('isHidden', parseFloat(this.data.winnings) === 0 && !this.data.showEachWay);
            // this.DOM.data_winnings.toggleClass('isHidden', parseFloat(this.data.winnings) === 0);
        },

        /**
         * @method eachWayHandler
         * @description
         *      Callback function for a checkbox change event handler
         *
         */
        eachWayHandler: function() {
            var attrs = this.betModel.attributes;
            this.betModel.set({betType: this.DOM.eachWayCheckbox.is(':checked') ? 'WP' : 'WIN'});

            if(attrs.isFree) {
                if (attrs.betType === 'WP') {
                    this.betModel.set({unitStake: parseFloat(attrs.freeBet.amount) / 2});
                } else {
                    this.betModel.set({unitStake: attrs.freeBet.amount});
                }
                this.$('[data-stake-btn].isSelected').removeClass('isSelected');
            }
            this.updateTax();
            this.manageWinnings();
        },

        updateStakeDataDom: function () {
            this.DOM.total.html(this.data.stake.totalCostLabel);
            this.DOM.totalStakeLabel.html(this.data.stake.totalStakeLabel);
            //this.DOM.tax.html(this.data.stake.taxAmountLabel);
            this.DOM.subventionRate.html(this.data.stake.taxSubventionRateLabel);
            this.DOM.subvention.html(this.data.stake.taxSubventionAmountLabel);
            this.DOM.winning.html(this.data.winningsLabel);
            // resets the tax container height
            // this.resetTaxDataHeight()
        },

        changeCategory: function (e) {
            const $button = $(e.currentTarget);
            const category = $button.attr('data-category');

            //return if no change in categroy
            if (this.betModel.get('betCategory') === category) return;

            //check if TOT was changed from/to
            const toggleTote = category === 'TOT' || this.betModel.get('betCategory') === 'TOT';

            //set new bet category
            this.betModel.set('betCategory', category);

            //if TOT is involved and tote currency differs from user's currency, rerender unit stakes and custom amount input
            if (toggleTote && user.data.currency !== this.data.runnerModel.collection.data.event.toteCurrency) this.currencyChanged();

            this.DOM.categoryBtn.filter('.isSelected').removeClass('isSelected');
            $button.addClass('isSelected');

            //enable "add to Betlip btn" if category os not "TOT"
            const noH2HinBetslip = betslipHelpers.noH2HinBetslip(this.data.fixedModel, app.betslip.getBets(true));
            const multiplesFxd = maybe.of(this.data.fixedModel).mapDotProp('attributes.multiplesFxd').orElse(false).join();

            this.DOM.betslip_btn.attr('disabled', this.betModel.get('freeBet') || this.betModel.get('betCategory') === 'TOT' || (noH2HinBetslip && multiplesFxd));
            this.updateTax();
            this.checkEachWay(category);
            this.manageWinnings(category);
        },

        currencyChanged: function () {
            this.currency = (this.betModel.get('betCategory') === 'TOT' && this.data.runnerModel) ? this.data.runnerModel.collection.data.event.toteCurrency : user.data.currency;
            this.betModel.set({currency: this.currency}, {silent: true});
            this.betModel.set({unitStake: 0});
            this.detectCurrency();
            this.createPresetAmounts();
            this.unitStakesView.update({ personalUnitStakes: this.data.personalUnitStakes });
            this.customAmountView.update({ currency: this.currency, convert_to_user_currency: this.convert_to_user_currency });
        },

        closeDialog: function () {
            app.overlay.closeDialog();
        },

        addToBetslip: function() {
            if(this.betModel.attributes.betCategory !== 'TOT') {
                //notice: the order of 'runnerModel' abd 'betModel' objects as they are passed to 'extend' has be swapped. 'betModel' used to be the first argument.
                //This was done after h2h were allowed to be added to betslip because 'runnerModel' has a different 'programNumber' for the same runner. In h2h runner has different 'programNumber'
                app.betslip.addBet(_.extend(this.data.runnerModel.attributes, this.betModel.attributes, maybe.of(this.data).mapDotProp('runnerModel.collection.data.event').orElse({}).join()));
                app.overlay.closeDialog();
            }
        },

        quickbet: function() {
            auth.ifLoggedIn(function (opt) {
                this.DOM.submitBtn.attr('disabled', true);
                this.options.closeOnOutsideClick = false;
                this.betModel.save();
            }, this)();
        },

        setupFreeBets: function () {
            if (this.data.freebetModel && this.data.freebetModel.length > 0) {
                this.betModel.attributes.freeBets = this.data.freebetModel;
                this.data.showFreeBets = true;
                let options = this.data.freebetModel.toJSON();

                // check if is for a single runner and is the one the user clicked on
                // and eventually remove it from the collection before getting the select options
                let idRunner = this.attributes.idRunner;
                let filteredOptions = _.filter(options, (freebet) => {
                    if (freebet.idRunner && freebet.idRunner !== idRunner) {
                        return false;
                    }

                    return true;
                });
                // if there is no freebets left hide the select
                if (filteredOptions.length < 1) {
                    this.data.showFreeBets = false;
                    return;
                }

                filteredOptions.unshift({idFreebet: 'no', title: app.polyglot.t('label_use_no_free_bet')});

                this.initFreeBetSelect(filteredOptions);
            }
        },

        initFreeBetSelect: function(filteredOptions) {
            this.freeBetsSelect = new cSelect({
                template: freeSelectTemplate,
                options: filteredOptions,
                event: 'freebet:selected',
                selected: filteredOptions[0],
                rerenderElAttribute: 'data-option'
            }).on('freebet:selected', this.processFreeBetSelect, this);
        },

        setUpCustomAmountView: function() {
            this.customAmountView = new CustomAmountView({parentView: this, currency: this.currency, convert_to_user_currency: this.convert_to_user_currency});
            this.listenTo(this.customAmountView, 'keyup', this.stakeInput);
            this.listenTo(this.customAmountView, 'new:amount', this.stakeInput);
            this.listenTo(this.customAmountView, 'back', this.showUserStake);

            if (this.betModel.attributes.isHeadToHead) {
                this.betModel.attributes.h2hOpponent = this.betModel.attributes.h2hOpponents[0];
            }
        },

        /**
         * @method processFreeBetSelect
         * @description
         *      Process freeBet selection/deselection
         *
         * @param val {String|Object}
         */
        processFreeBetSelect: function(val) {
            var attrs = this.betModel.attributes;
            if(val.idFreebet === 'no') {
                attrs.isFree = false;
                this.betModel.set('unitStake', 0);
                this.betModel.unset('freeBet');
                this.betModel.unset('idFreebet');
                this.DOM.freebetsSelectEl.removeClass('isActive');

                const noH2HinBetslip = betslipHelpers.noH2HinBetslip(this.data.fixedModel, app.betslip.getBets(true));
                const multiplesFxd = maybe.of(this.data.fixedModel).mapDotProp('attributes.multiplesFxd').orElse(false).join();
                this.DOM.betslip_btn.attr('disabled', this.betModel.get('betCategory') === 'TOT' || (noH2HinBetslip && multiplesFxd));

                if(this.DOM.customStake.length > 0) {
                    this.DOM.customStake.find('[data-amount]').val('');
                }
            } else {
                var unitStake;
                attrs.freeBet = attrs.freeBets.get(val.idFreebet).attributes;
                unitStake = attrs.freeBet.amount;
                attrs.idFreebet = attrs.freeBet.idFreebet;
                attrs.isFree = true;

                // disable add to betslip button if freebet is selected
                this.DOM.betslip_btn.attr({ disabled: true });

                if (attrs.betType === 'WP') {
                    unitStake = parseFloat(unitStake) / 2;
                }

                this.betModel.set('unitStake', unitStake);

                if(this.DOM.customStake.length > 0) {
                    this.DOM.customStake.find('[data-amount]').val(unitStake);
                }

                // deselect stake buttons if any
                this.$('[data-stake-btn].isSelected').removeClass('isSelected');
                this.DOM.freebetsSelectEl.addClass('isActive');
            }
        },

        resetFreeBet: function () {
            if (this.data.showFreeBets && this.freeBetsSelect.getVal() !== 'no') {
                this.freeBetsSelect.$('select').val('no').trigger('change');
                this.DOM.freebetsSelectEl.removeClass('isActive');
            }
        },

        /**
         * @method processSuccessResponse
         * @description
         *      Success callback function for model.save()
         *
         * @param model
         * @param response
         */
        processSuccessResponse: function(model, response) {
            this.options.closeOnOutsideClick = true;
            this.DOM.submitBtn.removeAttr('disabled');

            if(response.type === 'success') {
                let contentView = new BetPlacedDialogView({publicId: response.publicId});
                this.listenTo(contentView, 'successDialog:close', this.resetUnitStake);

                app.overlaySecondary.dialog({
                    contentView: contentView,
                    size: 'sliding',
                    secondary: true, //tell dialog to use app.layOutSecondary
                    closeOnOutsideClick: true
                });

                app.overlaySecondary.component.addOverlayClassName('c-overlay--showHeader');
                app.overlaySecondary.component.render();
                //app.overlaySecondary.component.delegateEvents();
                app.trigger('user:balance:update');
                app.trigger('bet:placed:successful');

                if(this.betModel.get('isFree')) {
                    app.trigger('bet:freeBet:used');
                }
            } else {
                this.promptUserActionOnError();
            }
        },

        resetUnitStake: function(data) {
            if(data.cleanUnits) {
                this.$('[data-stake-btn].isSelected').removeClass('isSelected');
                this.betModel.set('unitStake', 0);

                //reset for custom amount
                this.customAmountView.resetUnitStake();
            }
        },

        /**
         * @method promptUserActionOnError
         * @description
         *      Prompt for user action in case of an error response
         * @param model
         * @param response
         */
        promptUserActionOnError: function() {
            this.DOM.submitBtn.removeAttr('disabled'); 

            this.errorDialogView = new BetErrorDialogView(_.extend({}, betslipHelpers.getErrorMessage(this.betModel.attributes), {
                primaryBtnClass: 'action',
                cancelBtn: this.betModel.get('errorCode') === 111
            }));

            app.overlaySecondary.dialog({
                contentView: this.errorDialogView,
                secondary: true, //tell dialog to use app.layOutSecondary
                closeOnOutsideClick: true
            });

            app.overlaySecondary.component.render();
            app.overlaySecondary.component.delegateEvents();

            //update odds on button
            this.updateOddsForActiveButton();

            this.errorDialogView.on('cancel', function () {
                app.overlaySecondary.closeDialog();
                app.overlay.closeDialog();
            }, this);

            this.errorDialogView.on('resubmit', function () {
                app.overlaySecondary.closeDialog();
                if(this.betModel.get('newUnitStake')) {
                    this.betModel.set({unitStake: this.betModel.get('newUnitStake')}, {silent: true})
                }
                this.betModel.stripExtraAttributes();
                this.quickbet();
            }, this);

            this.options.closeOnOutsideClick = true;
        },

        //update odds for active button if they change
        updateOddsForActiveButton: function() {
            let active_btn = _.findWhere(this.buttons, {active: true});
            if (!active_btn) return;//TODO: fix this.buttons for special events' quick bet dialog instead of returning
            let attrs = this.betModel.attributes;
            let new_odds = attrs.betType === 'WIN' ? attrs.fixedOddsWin : attrs.fixedOddsPlace;
            this.$(`[data-category=${active_btn.category}]`).find('[data-odds-value]').html(new_odds);
        },

        /**
         * @method showValidationErrors
         * @description
         *      Shows validations errors when fired on model 'invalid' event
         *
         * @param model
         * @param response
         */
        showValidationErrors: function(model, errors) {
            this.DOM.stake_required.html(errors[0].message).show();
            this.DOM.submitBtn.removeAttr('disabled');
            this.options.closeOnOutsideClick = true;
        },

        toggleBonusWarningMessage: function(e) {
            e.preventDefault();
            betslipHelpers.toggleBonusWarningMessage($(e.target), $(this.DOM.bonusWarningMessage));
        },

        toggleBonusWarning: function() {
            const bet = +this.betModel.attributes.unitStake;
            betslipHelpers.toggleBonusWarning({ balance: user.data.balance, bonusBalance: user.data.bonusBalance }, bet, this.DOM.bonusWarning);
        },

        onClose: function() {
            if(this.oddsSelect) this.oddsSelect.close();
            if(this.customAmountView) this.customAmountView.close();
            if(this.freeBetsSelect) this.freeBetsSelect.close();
        }
    };

export default _.extend({}, QuickBet, ViewScroll, BackboneCloseView);
