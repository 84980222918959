'use strict';
import $                            from 'jquery';
import _                            from 'underscore';
import app                          from 'components/core/application';
import Backbone                     from 'backbone';
import template                     from 'modules/event/event.tpl.hbs';
import BackboneClose                from 'components/core/mixins/Backbone.CloseView';
import EventCollection              from 'modules/event/event.collection';
import EventViewItem                from 'modules/event/event-item.js';
import SpecialEventViewItem         from 'modules/event/special-event-item.js';
import webSocketManager             from 'components/helpers/webSocketManager';

/**
 * Event view
 *
 * @name EventView
 * @constructor
 *
 * @requires jQuery
 * @requires Underscore
 * @requires BackboneCloseView
 * @requires template
 *
 * @description
 * 		Event view, display all races.
 *
 * @returns {Function} - Constructor function for EventView
 */

var EventView = Backbone.View.extend(
    _.extend({}, BackboneClose, {

        template: template,

        initialize: function (opt) {
            this.DOM = {};
            this.data = {};
            this.childViews = [];
            this.options = {};

            _.extend(this.options, opt);

            // empty page title while waiting for event name
            app.subHeader.title('');
            this.collection = new EventCollection();
            this.collection.data.eventId = this.options.id;
            this.collection.joinChannel();
            this.listenTo(this.collection, 'sync', this.showEvent);
            this.collection.fetch({reset: true});
        },

        render: function () {
            var self = this;
            this.$el.html(this.template(this.data));
            this.DOM.eventList = this.$('[data-event-list]');
            var listFragment = document.createDocumentFragment();
            this.collection.forEach(function (event) {
                var view;

                // set different views for special bets
                if (self.collection.data.event.isSpecialBets) {
                    self.DOM.eventList.addClass('c-dataList');
                    view = new SpecialEventViewItem({model: event});
                } else {
                    self.DOM.eventList.addClass('c-dataList m-event__list');
                    view = new EventViewItem({model: event});
                }

                listFragment.appendChild(view.render().el);
                self.childViews.push(view);
            });
            this.DOM.eventList.append(listFragment);

            return this;
        },

        showEvent: function () {
            _.extend(this.data, this.collection.data);

            // set page title with event name
            app.subHeader.title(this.data.event.title);
            this.render();

            this.socketChannel = 'node_eventCard_' + this.data.eventId;

            webSocketManager
                .connect()
                .joinChannel({
                    channel: this.socketChannel,
                    timestamp: this.collection.data.timestamp
                });
        },

        onClose: function () {
            _.each(this.childViews, function(view) {
                view.close();
            });

            webSocketManager.leaveChannel(this.socketChannel);
        }
    })
);

export default EventView;
