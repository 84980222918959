
const WEBSOCKETS_HOST = process.env.MOB_APP_WEBSOCKETS_HOST;
const WEBSOCKETS_PORT = process.env.MOB_APP_WEBSOCKETS_PORT;

export const DICTIONARY_HASHMAP = process.env.MOB_APP_I18N_HASHMAP;

/**
 * RaceBets server environment
 */
export const ENV_RB = process.env.MOB_APP_RB_ENV;

/**
 * Test environment
 */
export const ENV_DEBUG_MODE = process.env.MOB_APP_DEBUG_MODE === 'true';

/**
 * Current brand built
 */
export const ENV_BRAND = process.env.MOB_APP_BRAND;

/**
 * Current target build.
 */
export const ENV_TARGET = process.env.MOB_APP_TARGET;

/**
 * Current client build.
 */
export const ENV_CLIENT = process.env.MOB_APP_CLIENT;

/**
 * GDPR enables consent and marketing cookies
 */
export const SETTINGS_GDPR_ENABLED = process.env.MOB_APP_GDPR_ENABLED === 'true';

/**
 *  ThreatMetrix enabled
 */
export const SETTINGS_THREAT_METRIX_ENABLED = process.env.MOB_APP_THREAT_METRIX_ENABLED === 'true'

/**
 * Consent cookie settings route for guest user
 */
export const ROUTE_COOKIE_SETTINGS = 'cookie-settings';

/**
 * Marketing cookie settings route for authorized user
 */
export const ROUTE_MARKETING_SETTINGS = 'account/marketing-settings';

/**
 * URL where existing customer is forced on first login
 */
export const ROUTE_CONSENT_REQUIRED = 'account/consent-required';

/**
 * URL where new customer is forced
 */
export const ROUTE_REGISTRATION_CONSENT_REQUIRED = 'account/consent-required/registration';

/**
 * List of routes where snack bar is not visible and consent page is not forced on customers
 */
export const ROUTE_SNACK_BAR_EXCLUSIONS = [
    ROUTE_MARKETING_SETTINGS,
    ROUTE_CONSENT_REQUIRED,
    ROUTE_REGISTRATION_CONSENT_REQUIRED,
    'info/group-companies',
    'info/cookie-policy',
    'info/privacy-policy',
    'register',
    'register/email',
    'register/email/success',
    'register/email/error',
    'register/email/verified',
    'register/consent/success',
    'register/success'
];

/**
 * Save card option is 'checked' by default
 */
export const DEPOSIT_SAVE_CARD_DEFAULT = true;

/**
 *  ScoutGaming DFS enabled
 */
export const FANTASY_ENABLED = process.env.MOB_APP_FANTASY_ENABLED === 'true';

/**
 *  Advent Calendar - enabled
 */
 export const ADVENT_CALENDAR_ENABLED = process.env.MOB_APP_ADVENT_CALENDAR_ENABLED === 'true';

 /**
 *  Advent Calendar - show from date
 */
  export const ADVENT_CALENDAR_SHOW_FROM = process.env.MOB_APP_ADVENT_CALENDAR_SHOW_FROM;

 /**
 *  Advent Calendar - show until date
 */
  export const ADVENT_CALENDAR_SHOW_UNTIL = process.env.MOB_APP_ADVENT_CALENDAR_SHOW_UNTIL;

   /**
 *  Advent Calendar - whitelisted TLDs
 */
    export const ADVENT_CALENDAR_WHITELISTED_TLD = process.env.MOB_APP_ADVENT_CALENDAR_WHITELISTED_TLD;

/**
 *  XtremePush enabled
 */
 export const XTREMEPUSH_ENABLED = process.env.MOB_APP_XTREMEPUSH_ENABLED === 'true';
 export const XTREMEPUSH_DE_ENABLED = process.env.MOB_APP_XTREMEPUSH_DE_ENABLED === 'true';
 export const XTREMEPUSH_OPT_IN_DELAY = process.env.MOB_APP_XTREMEPUSH_OPT_IN_DELAY;

 /**
  * Marketing cookies
  */

export const RB_CONSENT = process.env.MOB_APP_RB_CONSENT === 'true';
export const MARKETING_COOKIE_DEFAULT_VALUE = process.env.MOB_APP_MARKETING_COOKIE_DEFAULT_VALUE;
export const TARGETING_COOKIE_DEFAULT_VALUE = process.env.MOB_APP_TARGETING_COOKIE_DEFAULT_VALUE;
export const FUNCTIONAL_COOKIE_DEFAULT_VALUE = process.env.MOB_APP_FUNCTIONAL_COOKIE_DEFAULT_VALUE;
export const PERFORMANCE_COOKIE_DEFAULT_VALUE = process.env.MOB_APP_PERFORMANCE_COOKIE_DEFAULT_VALUE;
export const PERFORMANCE_COOKIE_DEFAULT_VALUE_OVERRIDE = process.env.MOB_APP_PERFORMANCE_COOKIE_DEFAULT_VALUE_OVERRIDE;
export const TARGETING_COOKIE_NAME_MAP = process.env.MOB_APP_TARGETING_COOKIE_NAME_MAP;
export const FUNCTIONAL_COOKIE_NAME_MAP = process.env.MOB_APP_FUNCTIONAL_COOKIE_NAME_MAP;
export const PERFORMANCE_COOKIE_NAME_MAP = process.env.MOB_APP_PERFORMANCE_COOKIE_NAME_MAP;
export const DOMAIN_SCRIPT_COM = process.env.MOB_APP_DOMAIN_SCRIPT_COM;
export const DOMAIN_SCRIPT_DE = process.env.MOB_APP_DOMAIN_SCRIPT_DE;

/**
 * App settings objects
 * @module App Settings
 *
 * @description
 *      General app settings
 *
 * @returns {Object}
 */

const appSettings = {
    webSocketsHost: WEBSOCKETS_HOST,

    webSocketsPort: WEBSOCKETS_PORT,

    //default language
    defaultLanguage: 'en',

    //home page - event listing
    eventsCountDownStartTime: 30,

    //race card
    raceCountDownStartTime: 30,

    //settings
    settingsCookieName: 'rbset',

    //consent cookie name
    consentCookie: 'CONSENT',

    //cooke expiration time in years for a visitor/guest (no logged in user)
    cookieExpiryDateInYearsVisitor: 2,

    //cooke expiration time in years for a registering user
    cookieExpiryDateInYearsLoggedIn: 5,
};

export default appSettings;
