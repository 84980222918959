var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.data : depth0),{"name":"each","hash":{},"fn":this.program(2, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"2":function(depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "        <div class=\"c-dataList__row\" data-href=\""
    + alias3(((helper = (helper = helpers.idRace || (depth0 != null ? depth0.idRace : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"idRace","hash":{},"data":data}) : helper)))
    + "\">\n            <div class=\"c-dataList__cell\"></div>\n            <div class=\"c-dataList__cell pl1\">\n                <div class=\"c-dataList__el--truncatedText\">\n                    <div>\n                        <i class=\"c-flag c-flag--alignMiddle isCountry"
    + alias3(((helper = (helper = helpers.country || (depth0 != null ? depth0.country : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"country","hash":{},"data":data}) : helper)))
    + "\"></i>\n                        <span>"
    + alias3(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"title","hash":{},"data":data}) : helper)))
    + " | "
    + alias3(((helper = (helper = helpers.raceTitleLabel || (depth0 != null ? depth0.raceTitleLabel : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"raceTitleLabel","hash":{},"data":data}) : helper)))
    + "</span>\n                    </div>\n                </div>\n                <div class=\"c-dataList__el--truncatedText m-event__subContent\">\n                    <span>"
    + alias3(((helper = (helper = helpers.categoryLabel || (depth0 != null ? depth0.categoryLabel : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"categoryLabel","hash":{},"data":data}) : helper)))
    + " "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.categoryLabel : depth0),{"name":"if","hash":{},"fn":this.program(3, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " "
    + alias3(((helper = (helper = helpers.distanceLabel || (depth0 != null ? depth0.distanceLabel : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"distanceLabel","hash":{},"data":data}) : helper)))
    + " | "
    + alias3(((helper = (helper = helpers.raceTypeLabel || (depth0 != null ? depth0.raceTypeLabel : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"raceTypeLabel","hash":{},"data":data}) : helper)))
    + " | "
    + alias3(((helper = (helper = helpers.purseLabel || (depth0 != null ? depth0.purseLabel : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"purseLabel","hash":{},"data":data}) : helper)))
    + "</span>\n                </div>\n            </div>\n            <div class=\"c-dataList__cell c-dataList__cell--light\">\n                <div class=\"c-dataList__el--time text-center\">\n                    <strong>"
    + alias3(((helper = (helper = helpers.postTimeLabel || (depth0 != null ? depth0.postTimeLabel : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"postTimeLabel","hash":{},"data":data}) : helper)))
    + "</strong><br>\n"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.countDownTime : depth0)) != null ? stack1.textValue : stack1),{"name":"if","hash":{},"fn":this.program(5, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n                </div>\n            </div>\n        </div>\n";
},"3":function(depth0,helpers,partials,data) {
    return "|";
},"5":function(depth0,helpers,partials,data) {
    var stack1, helper, alias1=this.escapeExpression;

  return "                        <span data-countdown=\""
    + alias1(((helper = (helper = helpers.idRace || (depth0 != null ? depth0.idRace : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"idRace","hash":{},"data":data}) : helper)))
    + "\">"
    + alias1(this.lambda(((stack1 = (depth0 != null ? depth0.countDownTime : depth0)) != null ? stack1.textValue : stack1), depth0))
    + "</span>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.data : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"useData":true});