'use strict';
import Backbone         from 'backbone';
import user             from 'components/user/user';
import app              from 'components/core/application';
import BaseModel        from 'components/core/BaseModel';

/**
 * Login model
 * @module Auth
 *
 * @requires BaseModel
 *
 * @description
 *      Performs user form validation and login request
 *
 * @returns {Function} - Login model constructor function
 */
export default BaseModel.extend({
    url: '/rest/v1/users/me/login',

    defaults: {
        username: '',
        password: ''
    },

    save: function(attrs, options) {
        options = options || {};
        options.success = function (model, data) {
            if (data.type === 'error') {
                app.trigger('user:login:error', data);
            }
            else {
                app.trigger('user:login:success', data);
            }
        };

        options.error = function (model, error) {
            app.trigger('user:login:error', error);
        };

        attrs.tmSessionId = user.getTMSessionID();

        Backbone.Model.prototype.save.call(this, attrs, options);
    },

    validate: function (attrs) {
        var errors = [];
        if (!attrs.username) {
            errors.push({name: 'username', message: app.polyglot.t('msg_error_required_field')});
        }
        if (!attrs.password) {
            errors.push({name: 'password', message: app.polyglot.t('msg_error_required_field')});
        }

        return (errors.length > 0) ? errors : false;
    }
});
