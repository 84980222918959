'use strict';

/**
 * Backbone.CloseView mixin
 *
 * @description
 *      Methods to manage view and subview clean up
 *
 * @type {Object}
 */

export default {
    /**
     * @method close
     *
     * @description
     *     Removes html and cleans up DOM events.
     *     Unbinds events directly triggered by `this.trigger(…)` from within current view,
     *     in order to have the view raise an event.
     */
    close: function() {
        this.closeSubViews();
        this.closeTabsViews();

        if(this.destroyScroller && typeof(this.destroyScroller) === 'function') {
            this.destroyScroller();
        }

        this.onClose();
        this.remove();
        this.cleanUpBackboneRefs();

        if(this.constructor._instance) {
            this.constructor._instance = null;
        }

        if(this._instance) {
            this._instance = null;
        }
    },

    cleanUpBackboneRefs: function () {
        this.off();
        this.undelegateEvents();
        this.stopListening();
    },

    /**
     * @method closeSubViews
     *
     * @description
     *     Useful for view with subviews.
     */
    closeSubViews: function() {
        if(this.subViews) {
            for(var view in this.subViews) {
                if(this.subViews.hasOwnProperty(view)) {
                    this.subViews[view].close();
                }
            }

            this.subViews = null;
        }
    },

    /**
     * @method closeTabsViews
     *
     * @description
     *     Useful for view with tabs
     */
    closeTabsViews: function() {

    },

    /**
     * @method onClose
     *
     * @description
     *     Override to add functionality right after the view is removed.
     */
    onClose: function() {}
};
