'use strict';

import _                from 'underscore';
import user             from 'components/user/user';
import moment           from 'moment-timezone';
import RaceModel        from 'components/model/race.model';
import raceHelpers      from 'modules/race/race.helpers';

const transformRunners = {

    foreignHorses: ['DE', 'GB', 'FR', 'IT', 'IE'],

    mapRunners: function (data = {}) {
        return {
            betTypes: data.betTypes,
            event: data.event,
            race: data.race,
            runners: data.runners.data,
            result: data.result,
            siblingRaces: data.siblingRaces,
            timestamp: data.timestamp
        }
    },

    parseRunners: function (data) {

        let race = new RaceModel(data.race);

        //prepare data for views
        data.isBog = (data.race.bestOddsGuaranteed || (user.data.bestOddsGuaranteed && data.event.spEvent ? true : false));
        data.availableOdds = raceHelpers.availableOdds(data.betTypes, data.race.fixedOddsStatus);
        data.siblingRaces.races = _.map(data.siblingRaces.races, (value) => {
            value.postTimeLabel = this.formatTime(value.postTime || value.raceTime, value.isAntePost);
            value.active = parseInt(value.idRace, 10) === parseInt(data.race.idRace, 10);
            return value;
        });

        if (data.event.isAntePost) return this.setupAntePostCollection(data);

        //handle h2h
        data.race.head2head = _.map(data.race.head2head, (h2hEvent, index) => {
            h2hEvent.id = index + 1;
            return h2hEvent;
        });

        var h2hMap = {};
        //check what type of h2h labels we should show
        _.each(data.race.head2head, function(h2h, index) {
            _.each(h2h.runners, function(idRunner) {
                h2hMap[idRunner] = h2hMap[idRunner] || [];
                h2hMap[idRunner].push(index + 1);
            });
        });

        data.runners = _.map(data.runners, runner => {
            //handle h2h
            if (data.race.head2head) {
                let h2hEvent = _.find(data.race.head2head, (h2hEvent) => {
                    return _.contains(h2hEvent.runners, runner.idRunner + '')
                });

                if (h2hEvent) runner.h2hEvent = h2hEvent.id;
                //how many h2h labels need to be shown
                runner.h2hLabels = h2hMap[runner.idRunner] || [];
            }

            runner.idRace = data.race.idRace;
            runner.postTimeLabel = race.attributes.postTimeLabel;
            runner.raceTitleLabel = race.attributes.raceTitleLabel;

            // Check if horse is abroad and show a flag
            runner.isAbroad = this.checkHorseAbroad(runner, data.event.country);

            return runner;
        });

        return data.runners;
    },

    setupAntePostCollection: function (data) {
        // turn data into an array of runners
        var runners = _.map(data.runners, (val) => {
            val.idRace = data.race.idRace;
            return val
        });

        // sort by odds and move not yet defined runners at the end
        var lastRunnersRegEx = /nachgenannt|andere|supplemented/i;
        runners = _.sortBy(runners, function (runner) {
            if (lastRunnersRegEx.test(runner.name)) {
                return 1000;
            }
            return runner.odds.FXW;
        });

        return runners;
    },

    checkHorseAbroad: function (runner, country) {
        if (runner.homeland) {
            let homeland = runner.homeland.toString().toUpperCase();

            if (homeland !== country.toUpperCase()) {
                return homeland;
            }

            return null;
        }
    },

    formatTime: function (time, isAntePost = false) {
        if (isAntePost) {
            var date = moment.unix(time).tz(user.timeZone()).format('L');
            // regexp extract month and day from dd/mm/yyyy or dd.mm.yyyy
            // if there are no matches falls back to full date
            return date.match(/(\d{2}.\d{2})/) === null ? date : date.match(/(\d{2}.\d{2})/)[0];
        }

        return moment.unix(time).tz(user.timeZone()).format('H:mm');
    }
};

export default transformRunners;
