var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=this.escapeExpression, alias2=this.lambda;

  return "    <div class=\"table-row c-date-filter m-mybets__dateFilter\">\n        <div class=\"table-cell m-mybets__dateFilterField\">\n            <div>\n                <label for=\"dateFrom\" class=\"c-form__inputLabel m-mybets__dateFilterLabel\">"
    + alias1(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_date_from",{"name":"translate","hash":{},"data":data}))
    + "</label>\n                <input class=\"c-subheader__date c-form__inputText c-form__inputText--noShadow c-form__inputText--border-gray\" type=\"date\" name=\"from\" min=\"1990-01-01\" max=\"2040-12-31\" value=\""
    + alias1(alias2((depth0 != null ? depth0.from : depth0), depth0))
    + "\" data-from=\"true\" id=\"dateFrom\">\n            </div>\n        </div>\n        <div class=\"table-cell m-mybets__dateFilterField\">\n            <div>\n                <div><label for=\"dateTo\" class=\"c-form__inputLabel m-mybets__dateFilterLabel\">"
    + alias1(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_date_to",{"name":"translate","hash":{},"data":data}))
    + "</label></div>\n                <input class=\"c-subheader__date c-form__inputText c-form__inputText--noShadow c-form__inputText--border-gray\" type=\"date\" name=\"to\" min=\"1990-01-01\" max=\"2040-12-31\" value=\""
    + alias1(alias2((depth0 != null ? depth0.to : depth0), depth0))
    + "\" data-to id=\"dateTo\">\n            </div>\n        </div>\n        <div class=\"table-cell m-mybets__dateFilterReset\">\n            <a role=\"button\" data-reset class=\"rb-icon rb-icon--cancel m-mybets__cancelIcon\"></a>\n        </div>\n    </div>\n\n\n"
    + ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.results : depth0),{"name":"unless","hash":{},"fn":this.program(2, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.results : depth0),{"name":"each","hash":{},"fn":this.program(4, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.results : depth0),{"name":"if","hash":{},"fn":this.program(35, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"2":function(depth0,helpers,partials,data) {
    var alias1=this.escapeExpression;

  return "        <div class=\"c-emptyState\">\n            <div class=\"c-emptyState--noBets\"></div>\n            <div class=\"c-emptyState__title\">"
    + alias1(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"msg_no_results_found",{"name":"translate","hash":{},"data":data}))
    + "</div>\n            <a class=\"c-form__link c-form__link--primary f-size-15\" href=\"/upcoming\">"
    + alias1(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"btn_goto_next_races",{"name":"translate","hash":{},"data":data}))
    + "</a>\n        </div>\n";
},"4":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = __default(require("../../components/helpers/handlebar-helpers/compare.js")).call(depth0,(depth0 != null ? depth0.type : depth0),"===","ACC",{"name":"compare","hash":{},"fn":this.program(5, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("../../components/helpers/handlebar-helpers/compare.js")).call(depth0,(depth0 != null ? depth0.type : depth0),"===","SPC",{"name":"compare","hash":{},"fn":this.program(13, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("../../components/helpers/handlebar-helpers/compare.js")).call(depth0,(depth0 != null ? depth0.type : depth0),"===","STD",{"name":"compare","hash":{},"fn":this.program(22, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n";
},"5":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "            <div>\n                <div class=\"m-mybets__raceHead\" data-idrace=\""
    + alias2(alias1((depths[1] != null ? depths[1].idRace : depths[1]), depth0))
    + "\">\n                    <div class=\"m-mybets__raceTitle\">\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.country : depth0),{"name":"if","hash":{},"fn":this.program(6, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n                        "
    + alias2(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_accumulated_bet",{"name":"translate","hash":{},"data":data}))
    + "\n                    </div>\n                    <div class=\"m-mybets__raceTime\">\n                        "
    + alias2(alias1((depth0 != null ? depth0.time : depth0), depth0))
    + "\n                    </div>\n                </div>\n                <div class=\"m-mybets__bet\" data-id=\""
    + alias2(alias1((depth0 != null ? depth0.publicId : depth0), depth0))
    + "\" >\n                    <div class=\"m-mybets__betData\">\n                        <div class=\"m-mybets__mark\">\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.legs : depth0),{"name":"each","hash":{},"fn":this.program(8, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                        </div>\n                        <div class=\"m-mybets__stake\">"
    + alias2(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_stake",{"name":"translate","hash":{},"data":data}))
    + ": "
    + alias2(alias1((depth0 != null ? depth0.stake : depth0), depth0))
    + "</div>\n                    </div>\n                    <div class=\"m-mybets__status is"
    + alias2(alias1((depth0 != null ? depth0.status : depth0), depth0))
    + "\">\n                        "
    + alias2(alias1((depth0 != null ? depth0.statusLabel : depth0), depth0))
    + "\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.winnings : depth0),{"name":"if","hash":{},"fn":this.program(11, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n            </div>\n";
},"6":function(depth0,helpers,partials,data) {
    return "                            <span class=\"c-flag isCountry"
    + this.escapeExpression(this.lambda((depth0 != null ? depth0.country : depth0), depth0))
    + "\"></span>\n";
},"8":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "                                <div>\n                                    "
    + alias2(alias1((depth0 != null ? depth0.event : depth0), depth0))
    + " | "
    + alias2(alias1((depth0 != null ? depth0.dateFormatted : depth0), depth0))
    + " | "
    + alias2(alias1((depth0 != null ? depth0.raceNumberLabel : depth0), depth0))
    + ":\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.marks : depth0),{"name":"each","hash":{},"fn":this.program(9, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                                </div>\n";
},"9":function(depth0,helpers,partials,data) {
    return "                                        "
    + this.escapeExpression(this.lambda(depth0, depth0))
    + "&nbsp;\n";
},"11":function(depth0,helpers,partials,data) {
    return "                            <div class=\"m-mybets__win\">"
    + this.escapeExpression(this.lambda((depth0 != null ? depth0.winningsFormatted : depth0), depth0))
    + "</div>\n";
},"13":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "            <div>\n                <div class=\"m-mybets__raceHead\" data-idrace=\""
    + alias2(alias1((depths[1] != null ? depths[1].idRace : depths[1]), depth0))
    + "\">\n                    <div class=\"m-mybets__raceTitle\">\n                        <span class=\"c-flag isCountry"
    + alias2(alias1((depth0 != null ? depth0.country : depth0), depth0))
    + "\"></span>\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.title : depth0),{"name":"if","hash":{},"fn":this.program(14, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                    </div>\n                    <div class=\"m-mybets__raceTime\">\n                        "
    + alias2(alias1((depth0 != null ? depth0.time : depth0), depth0))
    + "\n                    </div>\n                </div>\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.bets : depth0),{"name":"each","hash":{},"fn":this.program(16, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            </div>\n";
},"14":function(depth0,helpers,partials,data) {
    return "                            "
    + this.escapeExpression(this.lambda((depth0 != null ? depth0.title : depth0), depth0))
    + "\n";
},"16":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "                    <div class=\"m-mybets__bet\" data-id=\""
    + alias2(alias1((depth0 != null ? depth0.publicId : depth0), depth0))
    + "\">\n                        <div class=\"m-mybets__betData\">\n                            <div class=\"m-mybets__mark\">\n                                <div>"
    + alias2(alias1((depth0 != null ? depth0.bet : depth0), depth0))
    + "</div>\n"
    + ((stack1 = __default(require("../../components/helpers/handlebar-helpers/compare.js")).call(depth0,(depth0 != null ? depth0.category : depth0),"===","FXD",{"name":"compare","hash":{},"fn":this.program(17, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                            </div>\n                            <div class=\"m-mybets__typeWrap\">\n                                <div class=\"m-mybets__type\">"
    + alias2(alias1((depth0 != null ? depth0.betTypeLabel : depth0), depth0))
    + "</div>\n                            </div>\n                            <div class=\"m-mybets__stake\">"
    + alias2(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_stake",{"name":"translate","hash":{},"data":data}))
    + ": "
    + alias2(alias1((depth0 != null ? depth0.stake : depth0), depth0))
    + "</div>\n                        </div>\n                        <div class=\"m-mybets__status is"
    + alias2(alias1((depth0 != null ? depth0.status : depth0), depth0))
    + "\">\n                            "
    + alias2(alias1((depth0 != null ? depth0.statusLabel : depth0), depth0))
    + "\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.winnings : depth0),{"name":"if","hash":{},"fn":this.program(20, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                        </div>\n                    </div>\n";
},"17":function(depth0,helpers,partials,data) {
    var stack1;

  return "                                    <div>"
    + this.escapeExpression(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_odds",{"name":"translate","hash":{},"data":data}))
    + ": "
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.fixedOdds : depth0),{"name":"each","hash":{},"fn":this.program(18, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"18":function(depth0,helpers,partials,data) {
    return " "
    + this.escapeExpression(this.lambda(depth0, depth0))
    + "&nbsp; ";
},"20":function(depth0,helpers,partials,data) {
    return "                                <div class=\"m-mybets__win\">"
    + this.escapeExpression(this.lambda((depth0 != null ? depth0.winningsFormatted : depth0), depth0))
    + "</div>\n";
},"22":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "            <div>\n                <div class=\"m-mybets__raceHead\" data-idrace=\""
    + alias2(alias1((depths[1] != null ? depths[1].idRace : depths[1]), depth0))
    + "\">\n                    <div class=\"m-mybets__raceTitle\">\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.country : depth0),{"name":"if","hash":{},"fn":this.program(6, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.title : depth0),{"name":"if","hash":{},"fn":this.program(14, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.country : depth0),{"name":"if","hash":{},"fn":this.program(23, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                    </div>\n                    <div class=\"m-mybets__raceTime\">\n                        "
    + alias2(alias1((depth0 != null ? depth0.time : depth0), depth0))
    + "\n                    </div>\n                </div>\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.bets : depth0),{"name":"each","hash":{},"fn":this.program(26, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            </div>\n";
},"23":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0,(depths[1] != null ? depths[1].antePost : depths[1]),{"name":"unless","hash":{},"fn":this.program(24, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"24":function(depth0,helpers,partials,data) {
    return "                                | "
    + this.escapeExpression(this.lambda((depth0 != null ? depth0.raceNumberLabel : depth0), depth0))
    + "\n";
},"26":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "                    <div class=\"m-mybets__bet\" data-id=\""
    + alias2(alias1((depth0 != null ? depth0.publicId : depth0), depth0))
    + "\">\n                        <div class=\"m-mybets__betData\">\n                            <div class=\"m-mybets__mark\">\n"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.mark : depth0)) != null ? stack1.name : stack1),{"name":"if","hash":{},"fn":this.program(27, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.marks : depth0),{"name":"if","hash":{},"fn":this.program(29, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.unless.call(depth0,(depths[1] != null ? depths[1].antePost : depths[1]),{"name":"unless","hash":{},"fn":this.program(32, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../components/helpers/handlebar-helpers/compare.js")).call(depth0,(depth0 != null ? depth0.category : depth0),"===","FXD",{"name":"compare","hash":{},"fn":this.program(17, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                            </div>\n                            <div class=\"m-mybets__typeWrap\">\n                                <div class=\"m-mybets__type\">"
    + alias2(alias1((depth0 != null ? depth0.betTypeLabel : depth0), depth0))
    + "</div>\n                            </div>\n                            <div class=\"m-mybets__stake\">"
    + alias2(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_stake",{"name":"translate","hash":{},"data":data}))
    + ": "
    + alias2(alias1((depth0 != null ? depth0.stake : depth0), depth0))
    + "</div>\n                        </div>\n                        <div class=\"m-mybets__status is"
    + alias2(alias1((depth0 != null ? depth0.status : depth0), depth0))
    + "\">\n                            "
    + alias2(alias1((depth0 != null ? depth0.statusLabel : depth0), depth0))
    + "\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.winnings : depth0),{"name":"if","hash":{},"fn":this.program(20, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                        </div>\n                    </div>\n";
},"27":function(depth0,helpers,partials,data) {
    var stack1;

  return "                                    "
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.mark : depth0)) != null ? stack1.name : stack1), depth0))
    + "\n";
},"29":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.marks : depth0),{"name":"each","hash":{},"fn":this.program(30, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"30":function(depth0,helpers,partials,data) {
    var alias1=this.lambda, alias2=this.escapeExpression;

  return "                                        <div>"
    + alias2(alias1((depth0 != null ? depth0.marksIndex : depth0), depth0))
    + ". "
    + alias2(alias1((depth0 != null ? depth0.marksLabel : depth0), depth0))
    + "</div>\n";
},"32":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.mark : depth0)) != null ? stack1.programNumber : stack1),{"name":"if","hash":{},"fn":this.program(33, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"33":function(depth0,helpers,partials,data) {
    var stack1;

  return "                                        <span class=\"m-mybets__programNumber\">"
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.mark : depth0)) != null ? stack1.programNumber : stack1), depth0))
    + "</span>\n";
},"35":function(depth0,helpers,partials,data) {
    var stack1;

  return "        <div class=\"c-pagination\">\n            <button class=\"c-btn "
    + ((stack1 = helpers.unless.call(depth0,((stack1 = (depth0 != null ? depth0.pagination : depth0)) != null ? stack1.prev : stack1),{"name":"unless","hash":{},"fn":this.program(36, data, 0),"inverse":this.program(38, data, 0),"data":data})) != null ? stack1 : "")
    + " c-pagination__btn\" data-page data-first>&laquo;</button>\n            <button class=\"c-btn c-btn--default "
    + ((stack1 = helpers.unless.call(depth0,((stack1 = (depth0 != null ? depth0.pagination : depth0)) != null ? stack1.prev : stack1),{"name":"unless","hash":{},"fn":this.program(36, data, 0),"inverse":this.program(38, data, 0),"data":data})) != null ? stack1 : "")
    + " c-pagination__btn\" data-page data-prev>&lsaquo;</button>\n"
    + ((stack1 = helpers.each.call(depth0,((stack1 = (depth0 != null ? depth0.pagination : depth0)) != null ? stack1.pages : stack1),{"name":"each","hash":{},"fn":this.program(40, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            <button class=\"c-btn c-btn--default "
    + ((stack1 = helpers.unless.call(depth0,((stack1 = (depth0 != null ? depth0.pagination : depth0)) != null ? stack1.next : stack1),{"name":"unless","hash":{},"fn":this.program(36, data, 0),"inverse":this.program(38, data, 0),"data":data})) != null ? stack1 : "")
    + " c-pagination__btn\" data-page data-next>&rsaquo;</button>\n            <button class=\"c-btn c-btn--default "
    + ((stack1 = helpers.unless.call(depth0,((stack1 = (depth0 != null ? depth0.pagination : depth0)) != null ? stack1.next : stack1),{"name":"unless","hash":{},"fn":this.program(36, data, 0),"inverse":this.program(38, data, 0),"data":data})) != null ? stack1 : "")
    + " c-pagination__btn\" data-page data-last>&raquo;</button>\n        </div>\n";
},"36":function(depth0,helpers,partials,data) {
    return "c-btn--disabled ";
},"38":function(depth0,helpers,partials,data) {
    return "c-btn--default ";
},"40":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "                <button class=\"c-btn c-btn--default "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.active : depth0),{"name":"if","hash":{},"fn":this.program(41, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " c-pagination__btn\" data-page=\""
    + alias2(alias1((depth0 != null ? depth0.number : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? depth0.number : depth0), depth0))
    + "</button>\n";
},"41":function(depth0,helpers,partials,data) {
    return "isCurrent";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.loaded : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"useData":true,"useDepths":true});