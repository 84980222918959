'use strict';

import _                           from 'underscore';
import $                           from 'jquery';
import app                         from 'components/core/application';
import Backbone                    from 'backbone';
import template                    from 'modules/race/race-bottom-bet.tpl.hbs';
import BackboneCloseView           from 'components/core/mixins/Backbone.CloseView';
import helpers                     from 'components/helpers/helpers'

/**
 * Race bottom bet view
 *
 * @name RaceBottomBet
 * @constructor
 *
 * @requires Underscore
 * @requires jQuery
 * @requires Backbone
 * @requires template
 * @requires BackboneCloseView
 *
 * @description
 * 		RaceNumbers view
 *
 * @returns {Function} - Constructor function for RaceBottomBet
 */

const RaceBottomBet = Backbone.View.extend(
    _.extend({}, BackboneCloseView, {

        className: 'm-race__bottom',

        template: template,

        events: {
            'click [data-quickbet]': 'quickbet'
        },

        initialize: function (opt) {
            var default_error = [
                {
                    message: !helpers.isPickBetType(opt.betType) ? app.polyglot.t('error_bet_select_runners') : app.polyglot.t('error_bet_select_runner_per_race'),
                    name: 'betStatus',
                    type: 'message'
                }
            ];

            this.opt = {
                bets: opt.bets,
                show: opt.show ? opt.show : false,
                betType: opt.betType,
                error: opt.errors ? opt.errors[0] : default_error[0]
            };

            if (this.opt.bets === 0 && this.opt.errors && this.opt.errors.length < 2) {
                this.opt.show = false;
            }
        },

        render: function () {
            this.$el.html(this.template({show: this.opt.show, bets: this.opt.bets, error: this.opt.error}));
            return this;
        },

        update: function (opt) {
            this.initialize(opt);
            return this;
        },

        quickbet: function () {
            app.trigger('betting:exotic:bet');
        }
    })
);

export default RaceBottomBet;
