var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
    var alias1=this.lambda, alias2=this.escapeExpression;

  return "                    <li class=\"m-session__el\">\n                        <div class=\"table-row\">\n                            <div class=\"table-cell truncated-text m-session__info\" data-info>\n                                <div>"
    + alias2(alias1((depth0 != null ? depth0.channel : depth0), depth0))
    + " "
    + alias2(alias1((depth0 != null ? depth0.browser : depth0), depth0))
    + " "
    + alias2(alias1((depth0 != null ? depth0.os : depth0), depth0))
    + " <br> "
    + alias2(alias1((depth0 != null ? depth0.time : depth0), depth0))
    + "</div>\n                            </div>\n                            <div class=\"table-cell m-session__checkbox\">\n                                <input type=\"checkbox\" name=\""
    + alias2(alias1((depth0 != null ? depth0.idSession : depth0), depth0))
    + "\" id=\""
    + alias2(alias1((depth0 != null ? depth0.idSession : depth0), depth0))
    + "\" class=\"c-checkbox\">\n                                <label for=\""
    + alias2(alias1((depth0 != null ? depth0.idSession : depth0), depth0))
    + "\" class=\"c-checkbox__label c-checkbox__label--light\"></label>\n                            </div>\n                        </div>\n                    </li>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.escapeExpression;

  return "<h3 class=\"m-session__title\" data-title>\n    "
    + alias1(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"msg_login_multiple_active_sessions",{"name":"translate","hash":{},"data":data}))
    + "\n</h3>\n<div class=\"m-session__body\">\n    <form>\n        <div class=\"m-session__scroll\" data-scroll>\n            <ul class=\"m-session__list\" data-list>\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.sessions : depth0),{"name":"each","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            </ul>\n        </div>\n        <div class=\"m-session__toggle\" data-toggle>\n            <input type=\"checkbox\" class=\"c-checkbox\" name=\"hideSessionDialog\" id=\"hideSessionsDialog\">\n            <label for=\"hideSessionsDialog\" class=\"c-checkbox__label\">"
    + alias1(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_hide_dialog",{"name":"translate","hash":{},"data":data}))
    + "</label>\n        </div>\n        <div data-controls>\n            <button type=\"submit\" data-submit class=\"c-btn c-btn--primary mb1\">"
    + alias1(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_close",{"name":"translate","hash":{},"data":data}))
    + "</button>\n        </div>\n    </form>\n</div>\n";
},"useData":true});