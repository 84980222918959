'use strict';

import app                   from 'components/core/application';
import RaceView              from 'modules/race/race';
import RunnersCollection     from 'modules/race/runners.collection';
import viewManager           from 'components/core/ViewManager';
import helpers               from 'components/helpers/helpers';
import maybe                 from 'components/helpers/maybe';

/**
 * raceController controller
 *
 * @name raceController
 * @constructor
 *
 * @requires RaceView
 * @requires EventCollection
 * @requires app
 * @requires viewManager
 * @requires helpers
 * @requires maybe
 *
 * @description
 * 		Loads data, initiates and renders the view
 *
 * @returns {function}
 */
const raceController = function(race_id=null, bet_type=null) {
    load();

    /**
     * @method initView
     * @description
     *  Initiates view instance
     */
    function initView(collection) {
        //show WIN market view when race is not open
        const raceNotOpen = maybe.of(collection).mapDotProp('data.race.raceStatus').join() !== 'OPN';
        //select default market from available bet types
        const betType = helpers.getDefaultMarket(collection.data.betTypes);
        app.subHeader.title(maybe.of(collection).mapDotProp('data.event.title').join());
        viewManager.removePrevView().show(new RaceView({
            race_id: race_id,
            bet_type: raceNotOpen ? 'WIN' : (bet_type ? bet_type.toUpperCase() : betType),
            collection: collection
        }));
    }

    /**
     * @method load
     * @description
     *  Load data before the view is initiated and rendered
     */
    function load() {
        RunnersCollection.getInstance(null, {join_channel: true}).setRaceId(race_id).fetch({
            success: success
        });
    }

    function success(collection, response, options) {
        if (response.errorMsg && response.errorMsg === 'RACE_NOT_FOUND') {
            return app.router.navigateTo('404');
        }
        initView(collection);
    }
};

export default raceController;
