import _                    from 'underscore';
import $                    from 'jquery';
import {
    withScope,
    captureException }      from '@sentry/browser';
import maybe                from 'components/helpers/maybe';
import app                  from 'components/core/application';
import user                 from 'components/user/user';
import serviceFactory       from 'components/helpers/serviceFactory';
import moment               from "moment-timezone";
import appSettings, {
    DICTIONARY_HASHMAP,
    ENV_BRAND,
    XTREMEPUSH_ENABLED,
    XTREMEPUSH_DE_ENABLED,
    ADVENT_CALENDAR_ENABLED,
    ADVENT_CALENDAR_SHOW_FROM,
    ADVENT_CALENDAR_SHOW_UNTIL,
    ADVENT_CALENDAR_WHITELISTED_TLD
} from 'components/app.settings';

var Helpers = {
    device: function () {
        /**
         * Windows Phone 8.1 fakes user agent string to look like Android and iPhone.
         *
         * @type boolean
         */
        var isWindowsPhone = navigator.userAgent.indexOf("Windows Phone") >= 0;

        /**
         * Android requires exceptions.
         *
         * @type boolean
         */
        var isAndroid = navigator.userAgent.indexOf('Android') > 0 && !isWindowsPhone;

        var userAgent = navigator.userAgent.toLowerCase();
        var check = userAgent.match(/android[^\d]*([0-9\.]*)/);
        var androidVersion = check ? check[1] : false;

        /**
         * iOS requires exceptions.
         *
         * @type boolean
         */
        var isIOS = /iP(ad|hone|od)/.test(navigator.userAgent) && !isWindowsPhone;

        var iOSVersion = navigator.userAgent.match(/OS (\d+)/);

        return {
            isWindowsPhone: isWindowsPhone,
            isAndroid: isAndroid,
            androidVersion: androidVersion,
            isIOS: isIOS,
            iOSVersion: (iOSVersion !== null && iOSVersion.length) > 1 ? parseInt(iOSVersion[1]) : null
        }
    }(),

    loadScript: function (url, callback) {
        // Adding the script tag to the head as suggested before
        var head = document.getElementsByTagName('head')[0];
        var script = document.createElement('script');
        script.src = url;

        // Then bind the event to the callback function.
        // There are several events for cross browser compatibility.
        script.onreadystatechange = callback;
        script.onload = callback;

        // Fire the loading
        head.appendChild(script);
    },

    preloadImages: function (images, cb) {
        const promises = [];
        images.forEach(function (url, i) {
            (function (url, promise) {
                const img = new Image();
                img.onload = function () {
                    promise.resolve();
                };
                img.src = url;
            })(images[i], promises[i] = $.Deferred());
        });
        $.when.apply($, promises).done(function () {
            cb();
        });
    },

    sortByKey: function (array, key) {

        function compare(a, b) {
            if (a[key] < b[key]) {
                return -1;
            }

            if (a[key] > b[key]) {
                return 1;
            }

            return 0;
        }

        return array.sort(compare);

    },

    /**
     * Used in models to format amounts before sending to server
     * @param val
     * @returns {*}
     */
    formatAmount: function (val) {
        if (!val) {
            return null;
        }
        return val.toString().replace(/\,/g, '.');
    },

    currencySymbols: {
        'USD': 'US$',
        'GBP': '£',
        'AED': 'AED',
        'HKD': 'HK$',
        'SEK': 'kr',
        'ZAR': 'R',
        'COP': 'CO$',
        'CZK': 'Kč',
        'AUD': 'AU$',
        'SGD': 'SG$',
        'JPY': '¥',
        'MXN': 'MX$',
        'NOK': 'kr',
        'CHF': 'CHF',
        'DKK': 'kr',
        'MOP': 'MOP$',
        'TRY': '₺',
        'INR': '₹',
        'CLP': '$',
        'ARS': '$',
        'UYU': '$U',
        'PEN': 'S/',
        'PLN': 'zł',
        'CAN': 'C$',
        'NZD': 'NZ$',
        'BRL': 'R$',
        'HUF': 'Ft',
        'EUR': '€',
        'SAR': 'SAR',
        'GEL': '₾'
    },

    getCurrencySymbols: function (currency) {
        return this.currencySymbols[currency] ? this.currencySymbols[currency] : '';
    },

    formatMoney: function (amount, decimals, currency, symbol, toAccountCurrency) {
        if (toAccountCurrency) {
            amount = this.exchange(amount, currency, user.data.currency);
            currency = user.data.currency;
        }

        decimals = (decimals === undefined) ? 2 : decimals;
        let formatted = this.number(amount, decimals);

        // currencies which should show the sign before the amount (i.e. $10.00)
        let precedingCurrencies = ['GBP', 'USD', 'INR', 'TRY', 'BRL'];

        if (currency === undefined) {
            return formatted;
        }

        if (_.contains(precedingCurrencies, currency)) {
            return (symbol ? this.getCurrencySymbols(currency) : currency + ' ') + formatted;
        } else {
            return formatted + ' ' + (symbol ? this.getCurrencySymbols(currency) : currency);
        }
    },

    exchange: function (amount, baseCurrency, targetCurrency, withoutRate) {
        if (baseCurrency === targetCurrency) {
            return amount;
        }

        const exchangeRates = user.data.exchangeRates;

        try {
            if (withoutRate) {
                return parseFloat(amount) * exchangeRates[baseCurrency][targetCurrency];
            }
            // ... and return result with 1% more expensive exchange rate
            return parseFloat(amount) * (exchangeRates[baseCurrency][targetCurrency] * 1.01);
        } catch (e) {
            withScope(scope => {
                scope.setExtra('base_currency', baseCurrency);
                scope.setExtra('target_currency', targetCurrency);
                captureException(e);
            });
        }
    },

    number: function (number, decimals, decPoint, thousandsSep, decBeautify) {
        let hasComma = /,/.test(number);
        if (hasComma) {
            number = number.replace(',', '.');
        }
        number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
        var n = !_.isFinite(+number) ? 0 : +number,
            prec = !_.isFinite(+decimals) ? 0 : Math.abs(decimals),
            sep = (typeof thousandsSep === 'undefined') ? app.polyglot.t('thousands_separator') : thousandsSep,
            dec = (typeof decPoint === 'undefined') ? app.polyglot.t('decimal_separator') : decPoint,
            s,
            toFixedFix = function (n, prec) {
                var k = Math.pow(10, prec);
                return '' + Math.round(n * k) / k;
            };

        // Fix for IE parseFloat(0.55).toFixed(0) = 0;
        s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');

        if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
        }

        if ((s[1] || '').length < prec) {
            s[1] = s[1] || '';
            s[1] += new Array(prec - s[1].length + 1).join('0');
        }

        var res = s.join(dec);

        // remove empty decimal places
        if (decBeautify !== undefined) {
            res = res.toString().replace(dec + new Array(decimals + 1).join('0'), '');
        }

        if (hasComma) {
            res = res.replace('.', ',');
        }

        return res;
    },

    percentage: function (a, b) {
        return Math.round(a * 100 / b)
    },

    isInt: function (value) {
        if (isNaN(value)) {
            return false;
        }
        var x = parseFloat(value);
        return (x | 0) === x;
    },

    randomName: function () {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (var i = 0; i < 5; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return text;
    },

    isLeadingZeroCountries: function (countryCode) {
        const leadingZeroCountries = ['IT', 'ES', 'PT', 'LU', 'CZ'];
        return leadingZeroCountries.indexOf(countryCode) > -1;
    },

    dialCodes: {
        GB: 44,
        IM: 44,
        JE: 44,
        GG: 44,
        US: 1,
        DZ: 213,
        AD: 376,
        AO: 244,
        AI: 1264,
        AG: 1268,
        AR: 54,
        AM: 374,
        AW: 297,
        AU: 61,
        AT: 43,
        AZ: 994,
        BS: 1242,
        BH: 973,
        BD: 880,
        BB: 1246,
        BY: 375,
        BE: 32,
        BZ: 501,
        BJ: 229,
        BM: 1441,
        BT: 975,
        BO: 591,
        BA: 387,
        BW: 267,
        BR: 55,
        BN: 673,
        BG: 359,
        BF: 226,
        BI: 257,
        KH: 855,
        CM: 237,
        CA: 1,
        CV: 238,
        KY: 1345,
        CF: 236,
        CL: 56,
        CN: 86,
        CO: 57,
        KM: 269,
        CG: 242,
        CK: 682,
        CR: 506,
        HR: 385,
        CU: 53,
        CY: 357,
        CZ: 42,
        DK: 45,
        DJ: 253,
        DM: 1809,
        DO: 1809,
        EC: 593,
        EG: 20,
        SV: 503,
        GQ: 240,
        ER: 291,
        EE: 372,
        ET: 251,
        FK: 500,
        FO: 298,
        FJ: 679,
        FI: 358,
        FR: 33,
        GF: 594,
        PF: 689,
        GA: 241,
        GM: 220,
        GE: 7880,
        DE: 49,
        GH: 233,
        GI: 350,
        GR: 30,
        GL: 299,
        GD: 1473,
        GP: 590,
        GU: 671,
        GT: 502,
        GN: 224,
        GW: 245,
        GY: 592,
        HT: 509,
        HN: 504,
        HK: 852,
        HU: 36,
        IS: 354,
        IN: 91,
        ID: 62,
        IR: 98,
        IQ: 964,
        IE: 353,
        IL: 972,
        IT: 39,
        JM: 1876,
        JP: 81,
        JO: 962,
        KZ: 7,
        KE: 254,
        KI: 686,
        KP: 850,
        KR: 82,
        KW: 965,
        KG: 996,
        LA: 856,
        LV: 371,
        LB: 961,
        LS: 266,
        LR: 231,
        LY: 218,
        LI: 417,
        LT: 370,
        LU: 352,
        MU: 230,
        MO: 853,
        MK: 389,
        MG: 261,
        MW: 265,
        MY: 60,
        MV: 960,
        ML: 223,
        MT: 356,
        MH: 692,
        MQ: 596,
        MR: 222,
        YT: 269,
        MX: 52,
        FM: 691,
        MD: 373,
        MC: 377,
        MN: 976,
        MS: 1664,
        MA: 212,
        MZ: 258,
        NA: 264,
        NR: 674,
        NP: 977,
        NL: 31,
        NC: 687,
        NZ: 64,
        NI: 505,
        NE: 227,
        NG: 234,
        NU: 683,
        NF: 672,
        NO: 47,
        OM: 968,
        PK: 91,
        PW: 680,
        PA: 507,
        PG: 675,
        PY: 595,
        PE: 51,
        PH: 63,
        PL: 48,
        PT: 351,
        PR: 1787,
        QA: 974,
        RE: 262,
        RO: 40,
        RU: 7,
        RW: 250,
        SM: 378,
        ST: 239,
        SA: 966,
        SN: 221,
        RS: 381,
        SC: 248,
        SL: 232,
        SG: 65,
        SK: 421,
        SI: 386,
        SB: 677,
        SO: 252,
        ZA: 27,
        ES: 34,
        LK: 94,
        SH: 290,
        KN: 1869,
        SD: 249,
        SR: 597,
        SZ: 268,
        SE: 46,
        CH: 41,
        SY: 963,
        TW: 886,
        TJ: 7,
        TH: 66,
        TG: 228,
        TO: 676,
        TT: 1868,
        TN: 216,
        TR: 90,
        TM: 993,
        TC: 1649,
        TV: 688,
        UG: 256,
        UA: 380,
        AE: 971,
        UY: 598,
        UZ: 7,
        VU: 678,
        VA: 379,
        VE: 58,
        VN: 84,
        VG: 84,
        VI: 84,
        WF: 681,
        YE: 967,
        ZM: 260,
        ZW: 263
    },

    weight: function (sourceKg, outputFormat) {
        if (outputFormat == 'imperial') {
            var basePounds = Math.round(parseFloat(sourceKg) / 0.453592370); // convert to pounds
            var stones = Math.floor(basePounds / 14); // convert to stones
            var pounds = Math.round(basePounds % 14);

            return stones + '-' + pounds;
        } else {
            sourceKg = this.number(sourceKg, 1);
            return app.interpolate('label_weight_x_kg', {weight: sourceKg});
        }
    },

    distance: function (sourceMeters, outputFormat) {
        if (outputFormat == 'imperial') {
            let distance = '';
            const yards = Math.round(sourceMeters / 0.9144);
            const furlong = Math.floor(yards / 220);
            const miles = Math.floor(furlong / 8);

            if (miles > 0) {
                distance += miles + app.polyglot.t('label_miles_short');
            }

            if ((furlong % 8) > 0) {
                distance += Math.floor((furlong % 8)) + app.polyglot.t('label_furlong_short');
            }

            if ((yards % 220) > 0) {
                distance += Math.floor((yards % 220)) + app.polyglot.t('label_yards_short');
            }

            return distance;
        }

        // meters as default
        return app.interpolate('label_meter_short', { distance: this.number(sourceMeters) });
    },


    /**
     * "constant" to contain the fractional equivalents of decimal odds
     */
    ODDS_FRACTIONAL: {
        1.01: '1/100',
        1.02: '1/50',
        1.03: '1/33',
        1.04: '1/25',
        1.05: '1/20',
        1.06: '1/16',
        1.07: '1/14',
        1.08: '1/12',
        1.09: '1/11',
        1.10: '1/10',
        1.11: '1/9',
        1.12: '1/8',
        1.13: '2/15',
        1.14: '1/7',
        1.15: '2/13',
        1.16: '1/6',
        1.18: '2/11',
        1.20: '1/5',
        1.22: '2/9',
        1.25: '1/4',
        1.26: '20/75',
        1.28: '2/7',
        1.30: '3/10',
        1.33: '1/3',
        1.35: '7/20',
        1.36: '4/11',
        1.40: '2/5',
        1.42: '40/95',
        1.44: '4/9',
        1.45: '9/20',
        1.47: '40/85',
        1.50: '1/2',
        1.53: '8/15',
        1.55: '11/20',
        1.57: '4/7',
        1.60: '3/5',
        1.61: '8/13',
        1.62: '5/8',
        1.65: '13/20',
        1.66: '4/6',
        1.70: '7/10',
        1.72: '8/11',
        1.75: '15/20',
        1.80: '4/5',
        1.83: '5/6',
        1.85: '17/20',
        1.90: '10/11',
        1.95: '20/21',
        2.00: '1/1',
        2.05: '21/20',
        2.10: '11/10',
        2.20: '6/5',
        2.25: '5/4',
        2.30: '13/10',
        2.37: '11/8',
        2.40: '7/5',
        2.50: '6/4',
        2.60: '8/5',
        2.62: '13/8',
        2.70: '17/10',
        2.75: '7/4',
        2.80: '9/5',
        2.87: '15/8',
        2.90: '19/10',
        3.00: '2/1',
        3.10: '21/10',
        3.12: '85/40',
        3.20: '11/5',
        3.25: '9/4',
        3.30: '23/10',
        3.37: '95/40',
        3.40: '12/5',
        3.50: '5/2',
        3.60: '13/5',
        3.75: '11/4',
        3.80: '14/5',
        4.00: '3/1',
        4.20: '16/5',
        4.30: '33/10',
        4.33: '10/3',
        4.40: '17/5',
        4.50: '7/2',
        4.60: '18/5',
        4.75: '75/20',
        4.80: '19/5',
        5.00: '4/1',
        5.50: '9/2',
        6.00: '5/1',
        6.50: '11/2',
        7.00: '6/1',
        7.50: '13/2',
        8.00: '7/1',
        8.50: '15/2',
        9.00: '8/1',
        9.50: '17/2'
    },

    formatOdds: function (odds, format, noStrPad) {
        if(_.isString(odds)) return '';

        // get odds format
        format = format || user.getOddsFormat();

        if (format == 'base1') {
            odds = this.number(odds, 2, '.', '');
        } else if (format == 'base10') {
            odds = Math.floor(odds * 10);
        } else if (format == 'fractional') {
            if (this.ODDS_FRACTIONAL[odds]) {
                odds = this.ODDS_FRACTIONAL[odds];
            } else if (odds == 99.90 || odds == 99.99) {
                odds = '99/1';
            } else {
                odds = (odds - 1).toFixed(2) * 1;

                if(_.isNaN(odds)) return '';

                var denum = 1;
                while (odds % 1 != 0) {
                    denum *= 10;
                    odds = (odds * 10).toFixed(2) * 1;
                }

                odds = odds + '/' + denum;
            }
        }

        // remove padding zeros if set
        if (format === 'base10' || (noStrPad && format === 'base1')) {
            odds = odds * 1;
        }

        return odds;
    },

    BET_TYPES_WITH_HIDDEN_ROWS: ['QNP', 'QRP', 'SF4', 'TRC'],

    BET_TYPES_WINPLACE: ['WIN', 'PLC'],

    BET_TYPES_SHOW: ['SHW', 'WS', 'PS', 'WPS'],

    BET_TYPES_EACHWAY: ['WP'],

    BET_TYPES_ITA_TRITA: ['ITA', 'TRT'],

    BET_TYPES_EXOTIC: ['EXA', 'QNL', 'TRI', 'SFC', 'SWG', 'TRO', 'TOF', 'QRP', 'QNP', 'SF4', 'TRC', 'M4', 'M5', 'M6', 'M7', 'PK5'],

    BET_TYPES_PICK_WIN: ['P02', 'P03', 'P04', 'P05', 'P06', 'P07', 'P08', 'P09', 'P10'],

    BET_TYPES_PICK_PLC: ['PP2', 'PP3', 'PP4', 'PP5', 'PP6', 'PP7', 'PP8', 'PP9'],

    allowedBetTypes: function () {
        return [
            ...this.BET_TYPES_WINPLACE,
            ...this.BET_TYPES_SHOW,
            ...this.BET_TYPES_EACHWAY,
            ...this.BET_TYPES_ITA_TRITA,
            ...this.BET_TYPES_EXOTIC,
            ...this.getBetTypesPick()
        ];
    },

    marketsDisplayOrder: function (country) {
        return [
            ...this.BET_TYPES_WINPLACE,
            ...(country === 'US' ? this.BET_TYPES_EACHWAY : []),
            ...this.BET_TYPES_SHOW,
            ...this.BET_TYPES_EXOTIC,
            ...this.BET_TYPES_ITA_TRITA,
            ...this.getBetTypesPick()
        ];
    },

    getDefaultMarket: function (betTypes={}, selectedMarket='WIN') {
        const possibleMarkets = this.marketsDisplayOrder();

        function flattenBetTypeObj(betTypeTopCategory) {
            return Object.values(betTypeTopCategory).reduce((prev, item) => {
                prev = prev.concat(Object.keys(item));
                return prev;
            }, []);
        }

        const normalBetTypesAvailable = flattenBetTypeObj(maybe.of(betTypes).mapProp('normal').orElse({}).join());
        const pickBetTypesAvailable = flattenBetTypeObj(maybe.of(betTypes).mapProp('pick').orElse({}).join());
        const betTypesFlat = [...normalBetTypesAvailable, ...pickBetTypesAvailable];

        for (let i = 0, n = possibleMarkets.length; i < n; i++) {
            if (betTypesFlat.indexOf(possibleMarkets[i]) > -1) {
                selectedMarket = possibleMarkets[i];
                return selectedMarket;
            }
        }
        return selectedMarket;
    },

    getBetTypesPick: function () {
        return this.BET_TYPES_PICK_WIN.reduce((arr, val, i) => arr.concat(val, this.BET_TYPES_PICK_PLC[i]), []);
    },

    isPMUBetType: function (betType) {
        return ['TOF', 'QRP', 'QNP', 'SF4', 'TRC', 'M4', 'M5', 'M6', 'M7', 'PK5'].indexOf(betType) > -1;
    },

    isPickBetType: function (betType) {
        return /^(P|V|PP)(\d+)$/.test(betType);
    },

    isPickPlace: function(betType) {
        return /^PP/.test(betType);
    },

    getPickNumRaces: function(pickType) {
        return this.isPickPlace(pickType)
            ? parseInt(pickType.substr(2), 10)
            : /^V(64|65|75)$/.test(pickType)
                ? parseInt(pickType.substr(1, 1), 10)
                : parseInt(pickType.substr(1), 10);
    },

    getMinStakeBOKFXD: function(currencySettings, betCategory, betType) {
        return currencySettings['minStake' + betCategory + betType] !== undefined
            ? currencySettings['minStake' + betCategory + betType]
            : currencySettings['minStake' + betCategory];
    },

    getMaxStakeBOKFXD: function(currencySettings, betCategory, betType) {
        return currencySettings['maxStake' + betCategory + betType] !== undefined
            ? currencySettings['maxStake' + betCategory + betType]
            : currencySettings['maxStake' + betCategory];
    },

    /**
     * Return bet category for pick bets. It is not possible to have the same pick bet type offered
     * in both or TOT and BOK at the same time
     *
     * @param {object}    betTypesPick    Bet types pick in race collection
     * @param {string}    pickType        Pick type
     * @returns {string}                  Bet category
     */
    getPickCategory: function(betTypesPick={}, pickType='') {
        return _.findKey(betTypesPick, (betTypeObj) => betTypeObj.hasOwnProperty(pickType));
    },

    /**
     * Format a betType name
     *
     * @param string betType     betType abbreviation from DB
     * @param string betCategory BOK/TOT/FXD
     * @param bool   wps         Win/Place/Show
     * @param string country     country of the event
     */
    betTypeName: function (betType, betCategory, wps, country) {
        if (this.isPickBetType(betType)) {
            if (['DE', 'SE', 'NO', 'DK', 'FI'].indexOf(country) > -1) {
                return 'V' + this.getPickNumRaces(betType);
            }
            return app.interpolate(this.isPickPlace(betType) ? 'label_bet_type_ppx' : 'label_bet_type_PXX', {numRaces: this.getPickNumRaces(betType)});
        }

        if (wps && (betType === 'WIN' || betType === 'PLC' || betType === 'WP')) {
            return app.polyglot.t('label_bet_type_' + betType + 'US');
        }

        var language = user.data.language;
        if (betCategory === 'BOK' && (betType === 'EXA' || betType === 'TRI') && language === 'en') {
            return app.polyglot.t('label_bet_type_' + betType + 'BOKUK');
        }

        return app.polyglot.t('label_bet_type_' + betType);
    },

    getOS: function (uaString) {
        if (/Windows/.test(uaString)) {
            return 'Windows';
        } else if (
            /Mac OS X/.test(uaString) ||
            /AppleTV[0-9],[0-9]/.test(uaString)) {
            return 'Apple OS X';
        } else if (/Linux/.test(uaString)) {
            return 'Linux';
        } else if (
            /ov\(Android ([0-9\.]+)/.test(uaString) ||
            /Android/.test(uaString)) {
            return 'Android';
        } else if (
            /iPhone/.test(uaString) ||
            /like iPhone/.test(uaString) ||
            /iPod/.test(uaString) ||
            /iPad/.test(uaString) ||
            /\(iOS;/.test(uaString)) {
            return 'iOS';
        } else {
            return '';
        }
    },

    getBrowser: function (uaString) {
        if (/Firefox/.test(uaString)) {
            return 'Firefox';
        } else if (/(?:Chrome|CrMo|CriOS)\/([0-9.]*)/.test(uaString)) {
            return 'Chrome';
        } else if (
            /MSIE/.test(uaString) ||
            /\(IE ([0-9.]*)/.test(uaString) ||
            /Browser\/IE([0-9.]*)/.test(uaString) ||
            /Xbox\)$/.test(uaString) ||
            /Xbox One\)$/.test(uaString) ||
            /Edge\/([0-9.]*)/.test(uaString)) {
            return 'Explorer';
        } else if (/Safari/.test(uaString)) {
            return 'Safari';
        } else {
            return '';
        }
    },

    /**
     * Streaming media helpers
     */

    /**
     * Returns the result of a country filter
     */
    isCountryAllowed: function (userCountry, countries, filterType) {
        if (filterType === null) {
            return true;
        }

        var res = _.indexOf(countries, userCountry);
        return ((filterType == 'ALW' && res != -1) || (filterType == 'DIS' && res == -1));
    },

    capitalizeFirstLetter: function (string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },

    isValidEmail: function (email) {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    },

    /**
     * @method isValidPassword
     * @description
     *  Password must contain at least one number, one uppercase letter, one lowercase letter, one special character and min. 8 characters long
     *
     * old regex: /^\w*((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/
     * @param password
     * @returns {boolean|*}
     */
    isValidPassword: function (password) {
        let re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).{8,}$/;
        return re.test(password);
    },

    containsString: function (str1, str2) {
        return (str1 && str2 && (str2.indexOf(str1) >= 0 || str2 === str1)) ? true : false;
    },

    /**
     *
     * @returns {*}
     */
    getDecimalSeparator: function () {
        let n = 1.1;
        let fallBack = '.';
        try {
            let sep = /^1(.+)1$/.exec(n.toLocaleString())[1];
            return (sep) ? sep : fallBack;
        } catch (e) {
            return fallBack;
        }
    },

    /**
     * JS Implementation of MurmurHash3 (r136) (as of May 20, 2011)
     *
     * @author <a href="mailto:gary.court@gmail.com">Gary Court</a>
     * @see http://github.com/garycourt/murmurhash-js
     * @author <a href="mailto:aappleby@gmail.com">Austin Appleby</a>
     * @see http://sites.google.com/site/murmurhash/
     *
     * @param {string} key ASCII only
     * @param {number} seed Positive integer only
     * @return {number} 32-bit positive integer hash
     */
    murmurhash3_32_gc: function (key, seed) {
        var remainder, bytes, h1, h1b, c1, c1b, c2, c2b, k1, i;

        remainder = key.length & 3; // key.length % 4
        bytes = key.length - remainder;
        h1 = seed;
        c1 = 0xcc9e2d51;
        c2 = 0x1b873593;
        i = 0;

        while (i < bytes) {
            k1 =
                ((key.charCodeAt(i) & 0xff)) |
                ((key.charCodeAt(++i) & 0xff) << 8) |
                ((key.charCodeAt(++i) & 0xff) << 16) |
                ((key.charCodeAt(++i) & 0xff) << 24);
            ++i;

            k1 = ((((k1 & 0xffff) * c1) + ((((k1 >>> 16) * c1) & 0xffff) << 16))) & 0xffffffff;
            k1 = (k1 << 15) | (k1 >>> 17);
            k1 = ((((k1 & 0xffff) * c2) + ((((k1 >>> 16) * c2) & 0xffff) << 16))) & 0xffffffff;

            h1 ^= k1;
            h1 = (h1 << 13) | (h1 >>> 19);
            h1b = ((((h1 & 0xffff) * 5) + ((((h1 >>> 16) * 5) & 0xffff) << 16))) & 0xffffffff;
            h1 = (((h1b & 0xffff) + 0x6b64) + ((((h1b >>> 16) + 0xe654) & 0xffff) << 16));
        }

        k1 = 0;

        switch (remainder) {
            case 3:
                k1 ^= (key.charCodeAt(i + 2) & 0xff) << 16;
            case 2:
                k1 ^= (key.charCodeAt(i + 1) & 0xff) << 8;
            case 1:
                k1 ^= (key.charCodeAt(i) & 0xff);

                k1 = (((k1 & 0xffff) * c1) + ((((k1 >>> 16) * c1) & 0xffff) << 16)) & 0xffffffff;
                k1 = (k1 << 15) | (k1 >>> 17);
                k1 = (((k1 & 0xffff) * c2) + ((((k1 >>> 16) * c2) & 0xffff) << 16)) & 0xffffffff;
                h1 ^= k1;
        }

        h1 ^= key.length;

        h1 ^= h1 >>> 16;
        h1 = (((h1 & 0xffff) * 0x85ebca6b) + ((((h1 >>> 16) * 0x85ebca6b) & 0xffff) << 16)) & 0xffffffff;
        h1 ^= h1 >>> 13;
        h1 = ((((h1 & 0xffff) * 0xc2b2ae35) + ((((h1 >>> 16) * 0xc2b2ae35) & 0xffff) << 16))) & 0xffffffff;
        h1 ^= h1 >>> 16;

        return h1 >>> 0;
    },

    /**
     * @name common.service:Settings#getHostname
     * @public
     * @methodOf common.service:Settings
     *
     * @description
     *     Get second level domain with subdomains. Optionally replace current TLD.
     *
     * @param {String} replaceTld Top level domain to replace
     *
     * @returns {String} Current or new hostname
     */
    getHostname: function (replaceTld) {
        let host = window.location.hostname;

        if (replaceTld) {
            host = host.split('.');
            host[host.length - 1] = replaceTld;
            host = host.join('.');
        }

        return host;
    },

    getCookieDomain: function () {
        // if IP, just return IP
        if (document.location.hostname.match(/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/)) {
            return document.location.hostname;
        } else {
            return '.' + document.location.hostname;
        }
    },

    getTLDCookieDomain: function () {
        var parts = location.hostname.split('.');
        return parts[0] && parts[0] === 'localhost' ? parts[0] : '.' + (parts[parts.length - 2].match(/^co(m?)$/) ? parts.slice(parts.length - 3) : parts.slice(parts.length - 2)).join('.');
    },

    /**
     * @method getPageVisibilityProp
     *
     * @description
     *  Detects when a webpage is visible or in focus
     *
     *  @url: https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
     */
    getPageVisibilityProp: function () {
        var hidden, visibilityChange;
        if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
            hidden = "hidden";
            visibilityChange = "visibilitychange";
        } else if (typeof document.mozHidden !== "undefined") {
            hidden = "mozHidden";
            visibilityChange = "mozvisibilitychange";
        } else if (typeof document.msHidden !== "undefined") {
            hidden = "msHidden";
            visibilityChange = "msvisibilitychange";
        } else if (typeof document.webkitHidden !== "undefined") {
            hidden = "webkitHidden";
            visibilityChange = "webkitvisibilitychange";
        }

        return {hidden: hidden, visibilityChange: visibilityChange};
    },

    /**
     * @method depositButtonText
     * @description
     *  Deposit button text with html to truncate long labels
     *
     * @param value
     * @returns {html}
     */
    getDepositButtonText: function (value) {
        return `<div class="truncate-text"><span>${value}</span></div>`
    },

    /**
     * Display funding source (credit/debit card)
     * @param {string} provider
     * @param {string} cardType
     */
    displayFundingSource(provider, cardType) {
        return ['pagarme', 'braspag'].includes(provider) && ['visa', 'mastercard', 'elo'].includes(cardType);
    },

    /**
     * @property ibanCodeLengths
     *
     * @description
     *      IBAN code length by countries
     *
     */
    ibanCodeLengths: {
        AD: 24,
        AE: 23,
        AT: 20,
        AZ: 28,
        BA: 20,
        BE: 16,
        BG: 22,
        BH: 22,
        BR: 29,
        CH: 21,
        CR: 21,
        CY: 28,
        CZ: 24,
        DE: 22,
        DK: 18,
        DO: 28,
        EE: 20,
        ES: 24,
        FI: 18,
        FO: 18,
        FR: 27,
        GB: 22,
        GI: 23,
        GL: 18,
        GR: 27,
        GT: 28,
        HR: 21,
        HU: 28,
        IE: 22,
        IL: 23,
        IS: 26,
        IT: 27,
        JO: 30,
        KW: 30,
        KZ: 20,
        LB: 28,
        LI: 21,
        LT: 20,
        LU: 20,
        LV: 21,
        MC: 27,
        MD: 24,
        ME: 22,
        MK: 19,
        MR: 27,
        MT: 31,
        MU: 30,
        NL: 18,
        NO: 15,
        PK: 24,
        PL: 28,
        PS: 29,
        PT: 25,
        QA: 29,
        RO: 24,
        RS: 22,
        SA: 24,
        SE: 24,
        SI: 19,
        SK: 24,
        SM: 27,
        TN: 24,
        TR: 26
    },

    /**
     * @method isValidIBAN
     * @description
     *      Checks if IBAN code is valid
     *
     * @returns {Boolean}
     */

    isValidIBAN: function (ibanValue) {
        let iban = String(ibanValue).toUpperCase().replace(/[^A-Z0-9]/g, ''),
            code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/),
            digits;

        if (!code || iban.length !== this.ibanCodeLengths[code[1]]) {
            return false;
        }

        digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, this.convertCharsToInt);

        return this.mod97(digits) === 1;
    },

    /**
     * @method convertCharsToInt
     *
     * @description
     *      Returns char code of a letter
     *
     * @param letter
     * @returns {number}
     */
    convertCharsToInt: function (letter) {
        return letter.charCodeAt(0) - 55;
    },

    /**
     * @method mod97
     *
     * @description
     *      IBAN modulo function using 9 digit chunks and 97 as divisor
     *
     * @param {String} value IBAN converted digits
     *
     * @returns {Number}
     *
     */
    mod97: function (value) {
        let checksum = value.slice(0, 2),
            fragment;

        for (let offset = 2; offset < value.length; offset += 7) {
            fragment = String(checksum) + value.substring(offset, offset + 7);
            checksum = parseInt(fragment, 10) % 97;
        }

        return checksum;
    },

    /**
     * @method bankCodeMinLength
     * @description
     *     Minimum length of bank code, based on user's country of residence
     * @param country
     * @returns {number}
     */
    bankCodeMinLength: function (country) {
        return country.toString().toUpperCase() === 'DE' ? 8 : 1;
    },

    /**
     * @method bankCodeMaxLength
     *
     * @description
     *     Maximum length of bank code, based on user's country of residence
     * @param country
     * @returns {number}
     */
    bankCodeMaxLength: function (country) {
        return country.toString().toUpperCase() === 'DE' ? 8 : 99;
    },

    /**
     * @method parseJSON
     *
     * @description
     *  Parse JSON response
     *
     * @returns {object|null}
     */
    parseJSON: function (res) {
        var response = null;
        if (res) {
            try {
                response = JSON.parse(res);
            } catch (e) {
                response = e;
            }
        }

        return response;
    },

    deepCopy: function (data) {
        return this.parseJSON(JSON.stringify(data))
    },

    executeFunctionByName: function(functionName, context /*, args */) {
        var args = [].slice.call(arguments).splice(2);
        var namespaces = functionName.split(".");
        var func = namespaces.pop();
        for (var i = 0; i < namespaces.length; i++) {
            context = context[namespaces[i]];
        }
        return context[func].apply(context, args);
    },

    whatDecimalSeparator: function() {
        var n = 1.1;
        n = n.toLocaleString().substring(1, 2);
        return n;
    },

    /**
     * @method getViewPortSize
     * @description
     *  Get viewport size
     *
     * @returns {{width: number, height: number}}
     */
    getViewPortSize: function() {
        return {
            width: document.documentElement.clientWidth,
            height: document.documentElement.clientHeight
        }
    },

    /**
     * @method isInViewPort
     * @description
     *  Check if given DOM element is fully visible in the viewPort
     *
     * @param viewPort
     * @param rect
     *
     * @returns {boolean}
     */
    isInViewPort: function(viewPort, rect) {
        return (rect.top >= 0) && (rect.left >= 0) && (rect.bottom <= viewPort.height) && (rect.right <= viewPort.width);
    },

    /**
     * @method parseQueryString
     * @description
     *  Given a querystring e.g. language=de&currency=eur, returns an object e.g. {language: de, currency: eur}
     *
     * @param string queryString
     * @returns {language: de, currency: eur}
     */
    parseQueryString: function (queryString) {
        let params = {};
        if (queryString) {
            queryString = queryString.replace(/^\?/, '');
            _.each(_.map(decodeURI(queryString).split(/&/g), (el, i) => {
                let aux = el.split('=');
                let o = {};
                if (aux.length >= 1) {
                    let val = undefined;
                    if (aux.length == 2) {
                        val = aux[1];
                        if (val === 'false') val = false;
                        if (val === 'true') val = true;
                    }
                    o[aux[0]] = val;
                }
                return o;
            }), (o) => {
                _.extend(params, o)
            })
        }

        return params;
    },

    /**
     * @method getEachWayLabel
     *
     * @description
     *      Get label for WP betType option
     *
     * @param {string} country
     * @param {boolean} isAntePost
     * @param {boolean} spEvent
     * @returns {string} Label "Each Way" or "E/W"
     */
    getEachWayLabel: function(country, isAntePost, spEvent) {
        const eachWay = (_.contains(['GB', 'IE', 'AE'], country) && isAntePost) || spEvent;
        return eachWay ? app.polyglot.t('label_bet_type_WP') : app.polyglot.t('label_bet_type_WP_no_sp');
    },

    /**
     * Get Each Way terms (Place Terms)
     * @param {object} race
     * @param {object} event
     * @param {string} userCountry
     * @returns {string} Country and track based Each Way terms
     */
    getEachWayTerms: function({raceType, postTime, placesNum, placeOddsFactor}, {idTrack}, userCountry) {
        if (_.isUndefined(placesNum)) return false;

        if (userCountry === 'DE') {
            if (raceType === 'T' && idTrack === 10 && postTime > 1356994800) {
                return `2 ${app.polyglot.t('label_places')}`;
            }
            return `${placesNum} ${app.polyglot.t('label_places')} @ 1/${placeOddsFactor} ${app.polyglot.t('label_odds')}`;
        }

        return `${placesNum} ${app.polyglot.t('label_places')} @ 1/${placeOddsFactor} ${app.polyglot.t('label_odds')} `;
    },

    getCountries: function() {
        return serviceFactory.country_list ? serviceFactory.country_list.get('data') : [];
    },

    getCountriesBasedOnDialCodes: function () {
        let arr = [];
        for (var key in this.dialCodes) {
            if (this.dialCodes.hasOwnProperty(key)) {
                arr.push({key: key, code: this.dialCodes[key]});
            }
        }
        return arr;
    },

    currencyPresetAmount: {
        'BGN': 100,
        'CAD': 100,
        'EUR': 50,
        'GBP': 50,
        'NOK': 200,
        'PLN': 100,
        'SEK': 200,
        'USD': 50
    },

    generateSilkUrl: function (attributes) {
        var silkUrl = null;
        if (attributes.silkExtension !== '' && !_.isNull(attributes.silkExtension)) {
            // hardcoded domain name is used for b2b
            silkUrl = '/cache/img/silks/' + attributes.silkId.toString() + '_w62.' + attributes.silkExtension;
        }
        return silkUrl;
    },

    getTitle: function (raceTitle, raceNumber) {
        return raceTitle !== '' ? raceTitle : app.interpolate('label_race_number', {'number': raceNumber});
    },

    /**
     * nParts - represents number of elements separated by '/'
     * e.g. for the url /some/test/url with 'nParts' === 1 the result would be 'some'.
     * Leading and trailing '/' are removed
     */
    getPathName: function(nParts=1) {
        return location.pathname.replace(/^\/|\/$/g, '').split('/').splice(0, nParts).join('/');
    },

    /**
     * Contruct dictionary url
     * Invalidates cache by appending dictionary hash to the request
     */
    getDictionaryURL: function() {
        let dictLang = user.data.language;
        let dictVersion = '';

        try {
            const dictManifest = JSON.parse(DICTIONARY_HASHMAP);
            dictVersion = dictManifest[dictLang]['mobile'];
        } catch(e) {
            dictLang = appSettings.defaultLanguage;
            dictVersion = '';
        }

        return `/i18n/${dictLang}/i18n_mobile.json?${dictVersion}`;
    },

    /**
     * @method closeToExpire
     * @private
     * @description
     *      Check if expires date is expired or close to be expired to prevent
     *      expiration during long API calls (connection and/or kyc check)
     */
    closeToExpire(expires, threshold = 0) {
        var now = new Date().getTime();
        if (expires && (expires * 1000 - now < threshold)) {
            return true;
        }
        return false;
    },

    /**
     * @method formatCPFNumber
     * @private
     * @description
     *      format CPF number to 111.111.111-11
     */
    formatCPFNumber: function (cpfNotFormatted) {
        let formatted = cpfNotFormatted.split('');
        let counter = 0;

        const mask = [
            {length: 3, separator: '.'},
            {length: 3, separator: '.'},
            {length: 3, separator: '-'}
        ];

        for (var i = 0; i < mask.length; i++) {
            counter += mask[i].length;

            if (formatted.length > counter) {
                formatted.splice(counter, 0, mask[i].separator);
                counter++;
            } else {
                break;
            }
        }

        return formatted.join('');
    },

    supportsWebRTC() {
        if (window.RTCPeerConnection && typeof window.RTCPeerConnection === 'function') {
            return true;
        }
        return false;
    },

    isVirtualAvailable() {
        return user.data.isVirtualAvailable;
    },

    isGreyhoundAvailable() {
        return user.data.areGreyhoundsAvailable;
    },

    isMillionGameAvailable() {
        if (user.data.noBonus !== '1' && ['racebets', 'suaposta'].includes(ENV_BRAND)) {
            return true;
        }

        return false;
    },

    /**
     * ADVENT_CALENDAR_ENABLED is set in mobilev2/dist/[brand]/conf/settings.ini
     */
    isAdventCalendarAvailable() {
        const user_TLD = user.data.websiteTLD.toUpperCase();
        const matching_TLD = ADVENT_CALENDAR_WHITELISTED_TLD.includes(user_TLD);

        if(!ADVENT_CALENDAR_ENABLED || !ADVENT_CALENDAR_SHOW_FROM || !ADVENT_CALENDAR_SHOW_UNTIL || !matching_TLD) {
            return false;
        }

        let now = moment();

        let dateFrom = moment(`${ADVENT_CALENDAR_SHOW_FROM} 00:00:00`, 'DD/MM/YYYY hh:mm:ss')
        let dateUntil = moment(`${ADVENT_CALENDAR_SHOW_UNTIL} 23:59:59`, 'DD/MM/YYYY hh:mm:ss')

        // This is an inclusive workaround
        let show = now.isBetween(dateFrom, dateUntil) || now.isSame(dateFrom) || now.isSame(dateUntil);

        return show;
    },

    /**
     * We need to enable XtremePush related logic for .com and .de tld independently.
     * XtremePush can be:
     * 1. (ROW) - Enabled for ROW (globals.xtremePush) and disabled for Germany (globals.xtremePushDE).
     * 2. (DE) - Disabled for ROW and enabled for Germany.
     * 3. (ROW + DE) - Enabled both for ROW and Germany.
     * 4. Disabled for both ROW and Germany.
     */
    getXtremePushEnabledState() {
        var isGermanTLD = user.data.websiteTLD.toLowerCase() === 'de';
        var isEnabledForUser = user.isLoggedIn && user.data.accountVerification.verified;

        var deIsEnabled = isGermanTLD && XTREMEPUSH_DE_ENABLED && isEnabledForUser;
        var rowIsEnabled = !isGermanTLD && XTREMEPUSH_ENABLED && isEnabledForUser;

        return (deIsEnabled || rowIsEnabled) ? true : false
    },

    /**
     * Detect specific routes for showing the XtremePush opt-in dialog
     */
    showXtremePushOptIn() {
        // Show dialog on the event and race card only
        var isEventCard = window.location.href.indexOf('/event') > -1;
        var isRaceCard = window.location.href.indexOf('/race') > -1;
        var routeMatches = isEventCard || isRaceCard;

        return routeMatches;
    },

    /**
     * Returns depostit limit remainder or Infinity
     */
    getDepositLimit(input) {
        if (input[0] && input[0].limits && Array.isArray(input[0].limits)) {
            const limit = input[0].limits.find((limit) => {
                return limit.limit_type === 'DEP' && limit.status === 'ACT';
            })

            if (limit && typeof limit.remainder === 'number') {
                return limit.remainder
            }
        }
    }
};

export default Helpers;
