'use strict';

import _                            from 'underscore';
import Backbone                     from 'backbone';
import app                          from 'components/core/application';
import user                         from 'components/user/user';
import helpers                      from 'components/helpers/helpers';
import moment                       from 'moment-timezone';

var RunnerStatisticModel = Backbone.Model.extend({

    url: function () {
        return '/rest/v2/cards/runner_statistics/' + this.opt.idSubject;
    },

    initialize: function (attr, opt) {
        this.opt = opt;
        this.data = {};
    },

    parse: function (response) {
        response.loaded = true;

        if(response.raceRecord) {
            if(response.raceRecord.annual) {
                _.each(response.raceRecord.annual, (record) => {
                    _.each(record.results, (value, key) => {
                        if(key.toString() === '1') {
                            record.results.one = value;
                        }
                        if(key.toString() === '2') {
                            record.results.two = value;
                        }
                        if(key.toString() === '3') {
                            record.results.three = value;
                        }

                        if(key.toString() === 'earnings') {
                            record.results.earnings = helpers.formatMoney(value, 2, user.data.currency, true);
                        }
                    });
                });
            }

            if(response.raceRecord.total) {
                response.raceRecord.total.earnings = helpers.formatMoney(response.raceRecord.total.earnings, 2, user.data.currency, true);
                let results = response.raceRecord.total.results;
                _.each(results, (value, key) => {
                    if(key.toString() === '1') {
                        results.one = value;
                    }
                    if(key.toString() === '2') {
                        results.two = value;
                    }
                    if(key.toString() === '3') {
                        results.three = value;
                    }
                });
            }
        }

        if(response.lastPerformance) {
            _.each(response.lastPerformance, (value) => {
                value.date = moment.unix(value.date).format('DD/MM/YYYY');
                value.odds = helpers.formatOdds(value.odds, user.data.oddsFormat);
            });
        }

        return response;
    }
});

export default RunnerStatisticModel;
