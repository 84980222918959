var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return ((stack1 = __default(require("../helpers/handlebar-helpers/compare.js")).call(depth0,(data && data.index),"===",0,{"name":"compare","hash":{},"fn":this.program(2, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = __default(require("../helpers/handlebar-helpers/compare.js")).call(depth0,(data && data.index),"===",3,{"name":"compare","hash":{},"fn":this.program(4, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "        <div class=\"c-grid__col c-grid__col--noPadding is-1-3\">\n            <button class=\"c-btn c-btn--block c-btn--light m-quick__btn "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.active : depth0),{"name":"if","hash":{},"fn":this.program(6, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\" data-stake-btn=\""
    + alias2(alias1((depth0 != null ? depth0.value : depth0), depth0))
    + "\">\n            <span class=\"truncate-text\">\n                <span class=\"m-quick__btnStake\">\n                    "
    + alias2(alias1((depth0 != null ? depth0.label : depth0), depth0))
    + "\n                </span>\n            </span>\n            </button>\n        </div>\n"
    + ((stack1 = __default(require("../helpers/handlebar-helpers/compare.js")).call(depth0,(data && data.index),"===",2,{"name":"compare","hash":{},"fn":this.program(8, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = __default(require("../helpers/handlebar-helpers/compare.js")).call(depth0,(data && data.index),"===",5,{"name":"compare","hash":{},"fn":this.program(8, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"2":function(depth0,helpers,partials,data) {
    return "        <div class=\"c-grid mb1\">\n";
},"4":function(depth0,helpers,partials,data) {
    return "        <div class=\"c-grid\">\n";
},"6":function(depth0,helpers,partials,data) {
    return "isSelected";
},"8":function(depth0,helpers,partials,data) {
    return "        </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<div>\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.personalUnitStakes : depth0),{"name":"each","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});