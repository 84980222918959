var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.escapeExpression;

  return "                        <div class=\"m-quick__detail\">\n                            "
    + alias1(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_head_to_head_vs",{"name":"translate","hash":{},"data":data}))
    + " "
    + alias1(this.lambda(((stack1 = (depth0 != null ? depth0.betModel : depth0)) != null ? stack1.h2hOpponentsNames : stack1), depth0))
    + "\n                        </div>\n";
},"3":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "                        <div class=\"m-quick__detail\">\n                            "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.attr : depth0)) != null ? stack1.postTimeLabel : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.attr : depth0)) != null ? stack1.raceTitleLabel : stack1), depth0))
    + "\n                        </div>\n";
},"5":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "            <div class=\"m-quick__label mb1\">"
    + this.escapeExpression(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_odds",{"name":"translate","hash":{},"data":data}))
    + "</div>\n            <div class=\"c-grid\">\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.buttons : depth0),{"name":"each","hash":{},"fn":this.program(6, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            </div>\n";
},"6":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "                    <div class=\"c-grid__col c-grid__col--noPadding is-1-"
    + alias2(alias1((depths[1] != null ? depths[1].buttonsLength : depths[1]), depth0))
    + " v-align-t\">\n                        <button class=\"c-btn "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.enabled : depth0),{"name":"if","hash":{},"fn":this.program(7, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.active : depth0),{"name":"if","hash":{},"fn":this.program(9, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\" data-category=\""
    + alias2(alias1((depth0 != null ? depth0.betCategory : depth0), depth0))
    + "\" "
    + ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.enabled : depth0),{"name":"unless","hash":{},"fn":this.program(11, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">\n                            "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.asterisk : depth0),{"name":"if","hash":{},"fn":this.program(13, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.odds_f : depth0),{"name":"if","hash":{},"fn":this.program(15, data, 0, blockParams, depths),"inverse":this.program(17, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "                        </button>\n                    </div>\n";
},"7":function(depth0,helpers,partials,data) {
    return "c-btn--block c-btn--light m-quick__btn";
},"9":function(depth0,helpers,partials,data) {
    return "isSelected";
},"11":function(depth0,helpers,partials,data) {
    return "disabled";
},"13":function(depth0,helpers,partials,data) {
    return "<div class=\"c-btn__extra hasAsterisk\"></div>";
},"15":function(depth0,helpers,partials,data) {
    var alias1=this.lambda, alias2=this.escapeExpression;

  return "                                <div class=\"m-quick__btnOdds\" data-odds-value>"
    + alias2(alias1((depth0 != null ? depth0.odds_f : depth0), depth0))
    + "</div>\n                                <div class=\"m-quick__btnLabel\">"
    + alias2(alias1((depth0 != null ? depth0.label : depth0), depth0))
    + "</div>\n";
},"17":function(depth0,helpers,partials,data) {
    return "                                <div class=\"m-quick__btnText\">"
    + this.escapeExpression(this.lambda((depth0 != null ? depth0.label : depth0), depth0))
    + "</div>\n";
},"19":function(depth0,helpers,partials,data) {
    return "        <div class=\"m-quick__section m-quick__freebet\">\n            <div data-freebets-select class=\"c-select c-form__select\"></div>\n        </div>\n";
},"21":function(depth0,helpers,partials,data) {
    return "            <div class=\"table-row\">\n                <div class=\"m-betslip__taxInfo m-betslip__taxInfo--visible\" data-tax-info>\n                    <div class=\"table-row m-betslip__infRow\">\n                        <div class=\"table-cell m-betslip__secondaryInfo\">\n                            "
    + this.escapeExpression(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_tax_RWLG",{"name":"translate","hash":{},"data":data}))
    + "\n                        </div>\n                        <div class=\"table-cell m-betslip__secondaryInfo text-right\" data-tax-value>0.5</div>\n                    </div>\n                </div>\n            </div>\n";
},"23":function(depth0,helpers,partials,data) {
    return "mt1";
},"25":function(depth0,helpers,partials,data) {
    return "("
    + this.escapeExpression(this.lambda((depth0 != null ? depth0.eachWayTerms : depth0), depth0))
    + ")";
},"27":function(depth0,helpers,partials,data) {
    var stack1;

  return "        <div class=\"m-bonus-warning "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.hasOnlyBonus : depth0),{"name":"if","hash":{},"fn":this.program(28, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " m-quick__section\" data-bonus-warning>\n            <div class=\"c-alert c-alert--info\">\n                <div class=\"c-alert__title\"><span class=\"rb-icon rb-icon--info-2\"></span> "
    + this.escapeExpression(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"msg_bonus_money_info",{"name":"translate","hash":{},"data":data}))
    + "</div>\n                <div class=\"c-alert__body\" data-bonus-warning-message>\n                        "
    + ((stack1 = __default(require("../helpers/handlebar-helpers/translateInterpolate.js")).call(depth0,"txt_bonus_money_info",{"name":"translateInterpolate","hash":{"url":"https://support.racebets.com/en.html?category=promotions-and-bonuses"},"data":data})) != null ? stack1 : "")
    + "\n                </div>\n            </div>\n        </div>\n";
},"28":function(depth0,helpers,partials,data) {
    return " m-bonus-warning--visible";
},"30":function(depth0,helpers,partials,data) {
    return "        <div class=\"m-bonus-warning m-quick__section\" data-diff-currency-warning>\n            <div class=\"c-alert c-alert--info\">\n                <div class=\"c-alert__body\">\n                    <span class=\"rb-icon rb-icon--info-2\"></span>\n                    <span data-diff-currency-message></span>\n                </div>\n            </div>\n        </div>\n";
},"32":function(depth0,helpers,partials,data) {
    var stack1;

  return "            <button type=\"button\" class=\"c-btn c-btn--light c-btn--block c-btn--large\" data-betslip "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.disableBetslip : depth0),{"name":"if","hash":{},"fn":this.program(11, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + this.escapeExpression(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_add_to_betslip",{"name":"translate","hash":{},"data":data}))
    + "</button>\n";
},"34":function(depth0,helpers,partials,data) {
    return "            <div class=\"c-alert c-alert--info mt1\">\n                <div class=\"c-alert__body\">\n                    "
    + this.escapeExpression(this.lambda((depth0 != null ? depth0.noSpecialMix : depth0), depth0))
    + "\n                </div>\n            </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "<div class=\"m-quick\" data-wrap>\n    <div class=\"m-quick__section\">\n        <div class=\"table-row mb2\">\n            <div class=\"table-cell\">\n                <div class=\"truncate-text\">\n                    <div class=\"m-quick__title\">\n                        "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.attr : depth0)) != null ? stack1.name : stack1), depth0))
    + " <span class=\"m-quick__market\">("
    + alias2(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,__default(require("../helpers/handlebar-helpers/concat.js")).call(depth0,"label_bet_type_",((stack1 = (depth0 != null ? depth0.betModel : depth0)) != null ? stack1.betType : stack1),{"name":"concat","hash":{},"data":data}),{"name":"translate","hash":{},"data":data}))
    + ")</span>\n                    </div>\n                </div>\n                <div class=\"truncate-text\">\n"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.betModel : depth0)) != null ? stack1.isHeadToHead : stack1),{"name":"if","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.program(3, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "                </div>\n            </div>\n            <div class=\"table-cell v-align-t\">\n                <span data-close class=\"rb-icon rb-icon--cancel m-quick__cancel\"></span>\n            </div>\n        </div>\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.buttons : depth0),{"name":"if","hash":{},"fn":this.program(5, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n\n    <div class=\"m-quick__section\">\n        <div class=\"m-quick__label mb1\">"
    + alias2(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_amount",{"name":"translate","hash":{},"data":data}))
    + "</div>\n        <div data-user-stakes></div>\n        <div class=\"c-amountInput__hint c-amountInput__hint--preset\">"
    + alias2(__default(require("../helpers/handlebar-helpers/translateInterpolate.js")).call(depth0,"label_amount_converted",{"name":"translateInterpolate","hash":{"currency":(depth0 != null ? depth0.user_currency : depth0)},"data":data}))
    + ": <span data-converted-amount></span></div>\n    </div>\n\n    <div class=\"m-quick__custom\" data-custom-stake></div>\n\n    <div data-stake-required class=\"m-quick__errorMessage\"> "
    + alias2(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"msg_error_stake_required",{"name":"translate","hash":{},"data":data}))
    + "</div>\n\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.showFreeBets : depth0),{"name":"if","hash":{},"fn":this.program(19, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n    <div class=\"m-quick__section\">\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.isGermanCustomer : depth0),{"name":"if","hash":{},"fn":this.program(21, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "        <div data-each-way-wrapper class=\"table-row "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.isGermanCustomer : depth0),{"name":"if","hash":{},"fn":this.program(23, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " isHidden\">\n            <div data-each-way class=\"table-cell isHidden\">\n                <input type=\"checkbox\" id=\"each-way-option\" name=\"each-way-option\" class=\"c-checkbox\" data-each-way-option>\n                <label for=\"each-way-option\" class=\"c-checkbox__label m-quick__checkboxLabel\">\n                    "
    + alias2(alias1((depth0 != null ? depth0.eachWayLabel : depth0), depth0))
    + " "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.eachWayTerms : depth0),{"name":"if","hash":{},"fn":this.program(25, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n                </label>\n            </div>\n            <div data-winnings-wrapper class=\"table-cell isHidden\">\n                <div class=\"m-quick__label\">\n                    "
    + alias2(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_potential_winnings",{"name":"translate","hash":{},"data":data}))
    + " <span data-winning>"
    + alias2(alias1((depth0 != null ? depth0.winningsLabel : depth0), depth0))
    + "</span>\n                </div>\n            </div>\n        </div>\n    </div>\n\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.isUkCustomer : depth0),{"name":"if","hash":{},"fn":this.program(27, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.showDiffCurancyInfo : depth0),{"name":"if","hash":{},"fn":this.program(30, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n    <div class=\"m-quick__section mb2\">\n        <button type=\"button\" class=\"c-btn c-btn--action c-btn--block c-btn--large mb1\" data-bet>"
    + alias2(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_bet",{"name":"translate","hash":{},"data":data}))
    + "</button>\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.showAddToBetslip : depth0),{"name":"if","hash":{},"fn":this.program(32, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.noSpecialMix : depth0),{"name":"if","hash":{},"fn":this.program(34, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"useData":true,"useDepths":true});