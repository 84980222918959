var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
    var helper;

  return " "
    + this.escapeExpression(((helper = (helper = helpers.customCssClass || (depth0 != null ? depth0.customCssClass : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"customCssClass","hash":{},"data":data}) : helper)))
    + " ";
},"3":function(depth0,helpers,partials,data) {
    return "disabled";
},"5":function(depth0,helpers,partials,data) {
    return " data-bet ";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "<button type=\"button\" data-idRunner=\""
    + alias3(((helper = (helper = helpers.idRunner || (depth0 != null ? depth0.idRunner : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"idRunner","hash":{},"data":data}) : helper)))
    + "\" data-idRace=\""
    + alias3(((helper = (helper = helpers.idRace || (depth0 != null ? depth0.idRace : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"idRace","hash":{},"data":data}) : helper)))
    + "\" data-betType=\"WIN\" class=\"c-btn c-btn--block c-btn--primary c-btn--narrow "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.customCssClass : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\"\n    "
    + ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.enabled : depth0),{"name":"unless","hash":{},"fn":this.program(3, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.enabled : depth0),{"name":"if","hash":{},"fn":this.program(5, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">\n    <div class=\"c-btn__extra "
    + alias3(((helper = (helper = helpers.extraCssClass || (depth0 != null ? depth0.extraCssClass : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"extraCssClass","hash":{},"data":data}) : helper)))
    + "\" data-extra></div>\n    "
    + alias3(((helper = (helper = helpers.oddsFormatted || (depth0 != null ? depth0.oddsFormatted : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"oddsFormatted","hash":{},"data":data}) : helper)))
    + "\n</button>\n";
},"useData":true});