'use strict';

import _                           from 'underscore';
import $                           from 'jquery';
import app                         from 'components/core/application';
import Backbone                    from 'backbone';
import maybe                       from 'components/helpers/maybe';
import template                    from 'modules/race/race-h2h.tpl.hbs';
import H2HCollection               from 'modules/h2h/event-h2h.collection';
import H2HModel                    from 'modules/h2h/h2h.model';
import H2hRaceItemView             from 'modules/race/race-h2h-item';
import BackboneCloseView           from 'components/core/mixins/Backbone.CloseView';
import EmptyStateView              from 'components/view/empty_state';
import bettingService              from 'components/betting/betting.service';
import webSocketManager            from 'components/helpers/webSocketManager';

/**
 * Race h2h bet view
 *
 * @name RaceH2HView
 * @constructor
 *
 * @requires Underscore
 * @requires jQuery
 * @requires Backbone
 * @requires template
 * @requires BackboneCloseView
 *
 * @description
 * 		RaceNumbers view
 *
 * @returns {Function} - Constructor function for RaceH2HView
 */

const RaceH2HView = Backbone.View.extend(
    _.extend({}, BackboneCloseView, {

        className: 'm-race__h2h',

        template: template,

        initialize: function (opt) {
            this.collection = opt.collection;
            let h2h = maybe.of(this).mapDotProp('collection.data.race.head2head').orElse([]).join();
            this.setUpEmptyState();
            if(_.isArray(h2h) && h2h.length > 0) {
                if(this.idEvent === parseInt(h2h[0].idEvent, 10)) return;

                this.idRace = maybe.of(this.collection).mapDotProp('data.race.idRace').join();
                this.data = [];
                this.idEvent = parseInt(h2h[0].idEvent, 10);

                this.H2Hcollection = new H2HCollection(null, {id: this.idEvent});
                this.listenTo(this.H2Hcollection, 'sync', this.processH2H);
                this.H2Hcollection.fetch();
            }
        },

        setUpEmptyState: function() {
            this.empty_state_options = {
                icon: 'noHighlights',
                title: app.polyglot.t('msg_no_results_found'),
                link: {
                    clickable: false,
                    text: app.polyglot.t('btn_goto_next_races'),
                    url: '/upcoming'
                }
            };
        },

        listenToSocketUpdates: function() {
            var idRace = maybe.of(this.collection).mapDotProp('data.race.idRace').orElse(null).join();
            if(idRace) {
                this.data.socketChannel = `node_raceCard_${idRace}`;
                this.listenTo(webSocketManager, `update:channel:${this.data.socketChannel}`, this.update);
            }
        },

        update: function() {
            if(this.H2Hcollection) this.H2Hcollection.fetch();
        },

        processH2H: function() {
            this.DOM.container.append(this.composeH2HList());
        },

        composeH2HList: function() {
            var listFragment = document.createDocumentFragment();

            this.closeChildViews();
            this.views = [];

            this.H2Hcollection.forEach(model => {
                model.event = this.collection.data.event;
                model.betTypes = this.collection.data.betTypes;
                model.collection = this.collection;

                if(parseInt(model.get('relatedIdRace'), 10) === parseInt(this.idRace, 10)) {
                    var view = new H2hRaceItemView({model: model});
                    listFragment.appendChild(view.render().el);
                    this.views.push(view);
                }
            });

            return listFragment;
        },

        render: function () {
            this.$el.html(this.template({
                data: this.runnersCollection ? this.runnersCollection.toJSON() : []
            }));

            this.DOM = {
                container: this.$('[data-container]'),
                empty_state: this.$('[data-empty-state]')
            }

            if(!this.H2Hcollection) this.manageEmptyState();

            return this;
        },

        manageEmptyState: function() {
            if(this.empty_state) {
                this.empty_state.close();
                this.empty_state = null;
            }

            if(!this.H2Hcollection || this.H2Hcollection.length < 1) {
                if(!this.empty_state) this.empty_state = new EmptyStateView(this.empty_state_options);
                this.DOM.empty_state.html(this.empty_state.render().$el);
                this.empty_state.delegateEvents();
            }

        },

        closeChildViews: function() {
            _.each(this.views, view => {
                view.close();
            })
        },

        onClose: function() {
            this.closeChildViews();
        }
    })
);

export default RaceH2HView;
