'use strict';
import webSocketManager     from 'components/helpers/webSocketManager';
import user                 from 'components/user/user';
import _                    from 'underscore';

export default {
    channels: {
        current: 'node_sidebarEvents',
        calendar: 'node_sidebarEvents_'
    },

    host: '//' + location.host,

    currentChannelConnected: false,
    calendarChannelConnected: false,

    /**
     * Hold the name for the current connected data channel
     * Is used to leave the data channel before connecting to a new one
     */
    currentCalendarChannelName: null,

    init: function (data = {}) {

        this.homeCollection = data.collection;

        //determine channel name
        var channelName = this.channels[data.activeTab] || this.channels.current;//default channel is 'node_sidebarEvents'
        //for calendar channel name we have to add timestamp (beginning of the day). Defaults to 'today'.
        if(data.activeTab === 'calendar') {
            channelName += this.getStartOfDay();
            this.currentCalendarChannelName = channelName;
        }

        webSocketManager
            .connect()
            .joinChannel({
                channel: channelName,
                timestamp: this.homeCollection.data.lastTimestamp
            });

        this[data.activeTab + 'ChannelConnected'] = true;
    },

    getStartOfDay: function (date) {
        if (date === undefined) {
            date = new Date();
        }
        //@todo: test if this is the same: moment(date).startOf('day')
        return Math.floor(new Date(date).setHours(0, 0, 0, 0) / 1000);
    },

    /**
     * @method manageCurrentChannel
     *
     * @description
     *  Manage current tab channel connection
     *
     */
    manageCurrentChannel: function () {
        if(!webSocketManager.connected) {
            return;
        }

        //if there is already a connection to 'node_sidebarEvents' channel, there is no need to connect again
        if (this.currentChannelConnected) {
            return;
        }

        //leave existing data channel
        if(this.calendarChannelConnected) {
            webSocketManager.leaveChannel(this.currentCalendarChannelName);
            this.calendarChannelConnected = false;
        }

        if (this.homeCollection && this.homeCollection.data) {
            webSocketManager.joinChannel({
                channel: this.channels.current,
                timestamp: this.homeCollection.data.lastTimestamp
            });
        }

        this.currentChannelConnected = true;
    },

    /**
     * @method manageCalendarChannel
     *
     * @description
     *  Manage calendar tab channel connection
     *
     * @param data
     */
    manageCalendarChannel: function (data = {}) {
        if(!webSocketManager.connected) {
            return;
        }

        //if there is a connection to 'node_sidebarEvents' channel - leave it
        if(this.currentChannelConnected) {
            webSocketManager.leaveChannel(this.channels.current);
            this.currentChannelConnected = false;
        }

        //leave existing data channel before connection to a new one.
        if(this.calendarChannelConnected) {
            webSocketManager.leaveChannel(this.currentCalendarChannelName);
        }

        this.currentCalendarChannelName = this.channels.calendar + this.getStartOfDay(data.date);

        webSocketManager.joinChannel({
            channel: this.currentCalendarChannelName,
            timestamp: data.lastTimestamp
        });

        this.calendarChannelConnected = true;
    }
};
