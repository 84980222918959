var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"l-scrollWrapper\">\n    <div data-el class=\"c-dialog l-scrollV\">\n        <header class=\"c-dialog__header\">\n            <h2 class=\"c-dialog__title\">"
    + ((stack1 = __default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_new_terms",{"name":"translate","hash":{},"data":data})) != null ? stack1 : "")
    + "</h2>\n        </header>\n        <div class=\"c-dialog__body c-newTerms__content isVisible\">\n            <div data-terms-content></div>\n        </div>\n    </div>\n\n    <div class=\"c-overlay__actions\">\n        <button type=\"submit\" class=\"c-btn c-btn--block c-btn--action mt1\" data-id-accept>"
    + this.escapeExpression(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_accept",{"name":"translate","hash":{},"data":data}))
    + "</button>\n        <a role=\"button\" class=\"c-btn c-btn--block c-btn--link mt1\" data-id-toggle-terms>"
    + ((stack1 = __default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_view_full_terms_conditions",{"name":"translate","hash":{},"data":data})) != null ? stack1 : "")
    + "</a>\n    </div>\n</div>\n";
},"useData":true});