'use strict';
import Backbone  from 'backbone';
import app  from 'components/core/application';

/**
 * Base model
 * @module BaseModel
 * @constructor
 *
 * @requires Backbone
 * @requires app
 *
 * @description
 *      Common functionality for a model
 *
 * @returns {Function} - Constructor function for BaseModel
 */
export default Backbone.Model.extend({

    /**
     * @method initialize
     *
     * @description
     *      Trigger an event via app event bus when model changes
     */
    //initialize: function(){}
});
