'use strict';

import _                           from 'underscore';
import $                           from 'jquery';
import Backbone                    from 'backbone';
import BackboneCloseView           from 'components/core/mixins/Backbone.CloseView';
import template                    from 'modules/race/race-item-tooltip.tpl.hbs';
import maybe                       from 'components/helpers/maybe';
import RunnerFormView              from 'modules/race/runner-form';
import RunnerStatisticView         from 'modules/race/runner-statistic';

/**
 * Runner Tooltip view
 *
 * @name RaceItemTooltipView
 * @constructor
 *
 * @requires jQuery
 * @requires Underscore
 * @requires BackboneCloseView
 * @requires template
 *
 * @description
 * 		Runner item tooltip view.
 *
 * @returns {Function} - Constructor function for RaceItemTooltipView
 */

var RaceItemTooltipView = Backbone.View.extend(
    _.extend({}, BackboneCloseView, {

        events: {
            'click [data-tab-link]': 'handleTabs'
        },

        template: template,

        initialize: function (opt) {
            this.DOM = {};
            this.data = {};

            this.listenTo(this, 'hidden', this.hidden);
            this.listenTo(this, 'visible', this.visible);

            this.runnerFormView = new RunnerFormView({
                data: {
                    idRace: this.model.attributes.idRace,
                    idSubject: this.model.attributes.idSubject
                },
                parentView: this
            });

            this.runnerStatisticView = new RunnerStatisticView({
                data: {
                    idRace: this.model.attributes.idRace,
                    idSubject: this.model.attributes.idSubject//todo: for testing only
                },
                parentView: this
            });

            this.data.formTabLoaded = false;
            this.data.statisticTabLoaded = false;

            // Set up default open tab
            if (!this.model.attributes.tooltip_active_tab) this.model.attributes.tooltip_active_tab = 'runner';

            // fire a callback when the form tab is activated
            this.listenTo(this, 'tab:active:form', this.handleForm);
            this.listenTo(this, 'tab:active:statistic', this.handleStatistic);
            this.listenTo(this.runnerFormView, 'form:render', () => {
                this.trigger('tab:change'); // triggers height adjustment
            });
            this.listenTo(this.runnerStatisticView, 'statistic:render', () => {
                this.trigger('tab:change'); // triggers height adjustment
            });
        },

        render: function () {
            this.$el.html(this.template(this.model.toJSON()));

            this.DOM.img = this.$('[data-img]');
            this.DOM.detail = this.$('[data-detail]');
            this.DOM.tabLinks = this.$('[data-tab-link]');
            this.DOM.tabs = this.$('[data-tab]');

            if (this.data.formTabLoaded) {
                this.DOM.tabs.filter('[data-tab="form"]').html(this.runnerFormView.$el);
                this.runnerFormView.delegateEvents();
            }

            if (this.data.statisticTabLoaded) {
                this.DOM.tabs.filter('[data-tab="statistic"]').html(this.runnerStatisticView.$el);
                this.runnerStatisticView.delegateEvents();
            }

            return this;
        },

        hidden: function () {

        },

        visible: function (race_update=false) {
            this.handleImages(this.model.attributes.tooltip_active_tab, race_update);

            switch (this.model.attributes.tooltip_active_tab) {
                case 'statistic':
                    this.handleStatistic();
                    break;
                case 'form':
                    this.handleForm();
                    break;
            }
        },

        handleImages: function (tab, race_update) {
            let $img = this.DOM.img.filter(`[data-img="${tab}"]`);
            if ($img.length && !$img.data('loaded') && !_.isEmpty(this.model.attributes.photos[tab])) {
                let pixelRatio = _.isUndefined(window.devicePixelRatio) ? 1 : window.devicePixelRatio;

                $img.one('load', () => {
                    this.DOM.tabs.filter(`[data-tab="${tab}"]`).addClass('isLoaded');
                });

                if (race_update) this.DOM.tabs.filter(`[data-tab="${tab}"]`).addClass('isLoaded');

                if (tab === 'runner') {
                    let width = parseInt((this.DOM.detail.width() / 2) - 10, 10);
                    width = Math.min(width, 200); // max width 200

                    $img.attr('data-src', this.model.attributes.photos[tab].replace('w150', 'w' + parseInt(width * pixelRatio, 10)));
                    $img.attr('width', width);
                    $img.attr('height', parseInt((width / 1.49), 10));

                } else {
                    let width = parseInt(($('[data-detail]').width() / 3) - 10, 10);
                    width = Math.min(width, 100); // max width 100

                    $img.attr('data-src', this.model.attributes.photos[tab].replace('w75', 'w' + parseInt(width * pixelRatio, 10)));
                    $img.attr('width', width);
                    $img.attr('height', parseInt((width), 10));
                }

                $img.attr('src', $img.attr('data-src'));
                $img.data('loaded', true);
            }
        },

        handleTabs: function (e) {
            e.stopPropagation();
            this.DOM.tabLinks.filter(`[data-tab-link="${this.model.attributes.tooltip_active_tab}"]`).removeClass('isActive');
            this.DOM.tabs.filter(`[data-tab="${this.model.attributes.tooltip_active_tab}"]`).removeClass('isActive');
            var tab = $(e.currentTarget).attr('data-tab-link');
            this.model.attributes.tooltip_active_tab = tab;
            this.DOM.tabLinks.filter(`[data-tab-link="${tab}"]`).addClass('isActive');
            this.DOM.tabs.filter(`[data-tab="${tab}"]`).addClass('isActive');
            this.handleImages(this.model.attributes.tooltip_active_tab);
            this.trigger('tab:active:' + tab);
            this.trigger('tab:change');
        },

        handleForm: function (race_update=false) {
            if (!this.data.formTabLoaded || race_update) {
                this.runnerFormView.load();
                this.DOM.tabs.filter('[data-tab="form"]').html(this.runnerFormView.render().$el);
                this.data.formTabLoaded = true;
            }
        },

        handleStatistic: function (race_update=false) {
            if (!this.data.statisticTabLoaded || race_update) {
                this.runnerStatisticView.load();
                this.DOM.tabs.filter('[data-tab="statistic"]').html(this.runnerStatisticView.render().$el);
                this.data.statisticTabLoaded = true;
            }
        }
    })
);

export default RaceItemTooltipView;
