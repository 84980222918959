var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
    return "isToggleable";
},"3":function(depth0,helpers,partials,data) {
    return "isCollapsed";
},"5":function(depth0,helpers,partials,data) {
    return "allCollapsed";
},"7":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.escapeExpression;

  return "        <div class=\"c-accordion__head\">\n            <h3 class=\"c-accordion__title\">\n                <i class=\"c-flag c-flag isCountry"
    + alias1(this.lambda((depth0 != null ? depth0.country : depth0), depth0))
    + "\"></i> "
    + alias1(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,__default(require("../../components/helpers/handlebar-helpers/concat.js")).call(depth0,"countries.",(depth0 != null ? depth0.country : depth0),{"name":"concat","hash":{},"data":data}),{"name":"translate","hash":{},"data":data}))
    + "\n            </h3>\n            <div class=\"c-accordion__controller\">\n"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.showArrow : stack1),{"name":"if","hash":{},"fn":this.program(8, data, 0),"inverse":this.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + "                <span class=\"c-arrow pointUp\"></span>\n            </div>\n        </div>\n";
},"8":function(depth0,helpers,partials,data) {
    return "                    <span class=\"c-arrow pointDown\"></span>\n";
},"10":function(depth0,helpers,partials,data) {
    var stack1;

  return "                    <span class=\"c-badge c-badge--clean\" data-hidden-count>"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.hiddenCount : stack1),{"name":"if","hash":{},"fn":this.program(11, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</span>\n";
},"11":function(depth0,helpers,partials,data) {
    var stack1;

  return "+"
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.hiddenCount : stack1), depth0));
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.lambda, alias2=helpers.blockHelperMissing;

  return "<div data-accordion class=\"c-list__item c-accordion c-accordion--dark "
    + ((stack1 = alias2.call(depth0,alias1(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.hiddenCount : stack1), depth0),{"name":"data.hiddenCount","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.showArrow : stack1),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.closed : stack1),{"name":"if","hash":{},"fn":this.program(3, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = alias2.call(depth0,alias1(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.shown : stack1), depth0),{"name":"data.shown","hash":{},"fn":this.noop,"inverse":this.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.isVirtual : depth0),{"name":"unless","hash":{},"fn":this.program(7, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "    <div class=\"c-accordion__body\">\n        <ul class=\"c-accordion__content c-accordion__content--withTransition c-dataList c-dataList--currentEvents rowsBordered\" data-events></ul>\n        <ul class=\"c-accordion__content c-accordion__content--withTransition c-dataList c-dataList--upComingEvents rowsBordered\" data-expandable-events></ul>\n    </div>\n</div>\n";
},"useData":true});