var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
    return "isEmpty";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.escapeExpression;

  return "<div class=\"c-mybetsBar mt5\">\n    <div class=\"table-row table-row--fixed c-mybetsBar__bg\">\n        <div class=\"table-cell c-mybetsBar__iconCell\">\n            <div class=\"c-myActions c-myActions__mybets c-mybetsBar__icon\" data-open-bets>\n                <div class=\"c-myActions__icon rb-icon rb-icon--list\">\n                    <div class=\"c-mybetsBar__count\" style=\"display: none\"></div>\n                </div>\n                <div class=\"c-myActions__label\">\n                    "
    + alias1(__default(require("../../../brands/racebets/js/components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_open_bets",{"name":"translate","hash":{},"data":data}))
    + "\n                </div>\n            </div>\n        </div>\n        <div class=\"table-cell c-mybetsBar__iconCell\" data-betslip-cell>\n            <div class=\"c-myActions c-myActions__betslip c-mybetsBar__icon "
    + ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.betCount : depth0),{"name":"unless","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\" data-betslip-button>\n                <div class=\"c-myActions__icon\" data-betslip-icon>\n                    <div class=\"c-myActions__count c-mybetsBar__count\" data-betslip-count>"
    + alias1(this.lambda((depth0 != null ? depth0.betCount : depth0), depth0))
    + "</div>\n                </div>\n                <div class=\"c-myActions__label\">\n                    "
    + alias1(__default(require("../../../brands/racebets/js/components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_betslip",{"name":"translate","hash":{},"data":data}))
    + "\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});