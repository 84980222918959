'use strict';
/**
 * maybe Monad
 *
 * @description
 *  Custom implementations of maybe monad in JavaScript
 *  Very useful when we need to retrieve deeply nested properties and want to avoid
 *  very long checks.
 *
 *  @example
 *  var user = {
 *      account: {
 *          name: 'James Bond',
 *          email: 'test@gmail.com,
 *          address: {
 *              country: 'USA',
 *              city: 'Unknown'
 *          },
 *          phone: {
 *              cell: '1234567',
 *              home: '1122334455'
 *          }
 *      }
 *  }
 *
 *  To access the 'country' property we need to check if it exists first
 *  if(user && user.account && user.account.address && user.account.address.country) {
 *      var address = user.account.address.country;
 *  }
 *
 *  or we can use the maybe Monad
 *  var country = maybe.of(user).mapDotProp('account.address.country').join();
 *  // {__value: {name: 'James Bond', email: 'test@gmail.com, address: {country: 'USA', city: 'Unknown'},  phone: { cell: '1234567', home: '1122334455' }}}
 */

const maybe = (function() {

    /**
     * @method - Constructor function
     * @constructor
     *
     * @param val {Object|null}
     */
    var maybe = function(val) {
        this.__value = val;
    };

    /**
     * @method map - Private method
     * @private
     *
     * @returns {boolean}
     */
    maybe.prototype._isNothing = function() {
        return (this.__value === null || this.__value === undefined);
    };

    /**
     * @method map - Public method
     *
     * @description
     *  Check for value with given property and return maybe or null/undefined
     *
     * @example
     *  var account = maybe.of(user).map(_.property('account'));
     *  // {__value: {name: 'James Bond', email: 'test@gmail.com, address: {country: 'USA', city: 'Unknown'},  phone: { cell: '1234567', home: '1122334455' }}}
     *
     * @param f - {function} mapping value by property. E.g. Ramdajs's 'prop' or Underscore's '_.property'
     *
     * @returns {function|null|undefined}
     */
    maybe.prototype.map = function(f) {
        if(this._isNothing()) return maybe.of(null);
        return maybe.of(f(this.__value));
    };

    /**
     * @method mapProp - Public method
     *
     * @description
     *  Check for value with given property and return maybe or null/undefined
     *
     * @example
     *  var account = maybe.of(user).map('account');
     *  // {__value: USA"}
     *
     * @param key - {string} property
     *
     * @returns {function|null|undefined}
     */
    maybe.prototype.mapProp = function(key) {
        if(this._isNothing()) return maybe.of(null);
        return maybe.of(this.__value[key]);
    };

    /**
     * @method mapDotProp - Public method
     *
     * @description
     *  Check for value with given properties separated by dots and return maybe or null/undefined
     *
     * @example
     *  var country = maybe.of(user).mapDotProp('account.address.country');
     *  // {__value: USA"}
     *
     * @param keys - {string} properties separated by dots
     *
     * @returns {function|null|undefined}
     */
    maybe.prototype.mapDotProp = function(dotKey) {
        var keys = dotKey.split('.'),
            result = result || null;

        if(this._isNothing()) return maybe.of(null);

        for(var i = 0, l = keys.length; i < l; i++) {
            result = result ? result.mapProp(keys[i]) : this.mapProp(keys[i]);
            //return string value
            if(i === (l - 1)) result = result.join();
        }

        return maybe.of(result);
    };

    /**
     * @method join - Public method
     *
     * @description
     *  Return string value
     *
     * @example
     *  var country = maybe.of(user).mapDotProp('account.address.country').join();
     *  // USA instead of {__value: USA"}
     *
     * @returns {String}
     */
    maybe.prototype.join = function() {
        return this.__value;
    };

    /**
     * @method orElse - Public method
     * @description
     *  Return default value
     *
     * @param default_value
     * @returns {*}
     */
    maybe.prototype.orElse = function(default_value) {
        if(this._isNothing()) return maybe.of(default_value);
        return this;
    };

    /**
     * @method of - Static method
     *
     * @description
     *  Return string value
     *
     * @example
     *  var country = maybe.of(user).mapDotProp('account.address.country').join();
     *  // USA instead of {__value: USA"}
     *
     * @param val {Object
     * }
     * @returns {maybe}
     */

    maybe.of = function(val) {
        return new maybe(val)
    };

    return {
        of: maybe.of
    }
})();

export default maybe;


