'use strict';

import _                         from 'underscore';
import $                         from 'jquery';
import Backbone                  from 'backbone';
import app                       from 'components/core/application';
import user                      from 'components/user/user';
import maybe                     from 'components/helpers/maybe';
import quickBetDialogMethods     from 'components/betting/quick-bet-dialog.methods';
import template                  from 'components/betting/quick-bet-dialog.tpl.hbs';

/**
 * QuickBet view Controller
 * @name QuickBet
 * @constructor
 *
 * @requires Underscore
 * @requires Backbone
 * @requires quickBetDialogMethods
 *
 * @description
 *     QuickBet View Controller
 *     for quick betting and adding bets to betslip
 *
 * @returns Function - Constructor function for QuickBet view
 */
var QuickBet = Backbone.View.extend(
    _.extend({}, quickBetDialogMethods, {

        template: template

    }));

export default QuickBet;
