'use strict';

import _            from 'underscore';
import Backbone     from 'backbone';

var FixedDetailsModel = Backbone.Model.extend({

    url: '/ajax/races/bettingDialog/id/:idRunner',

    //by default it is NOT a freeBet
    defaults: {
        isFree: false
    },

    initialize: function() {
        var atrs = this.attributes;
        if(_.isUndefined(atrs.idRunner)) {
            throw new Error('idRunner attribute is required');
        }
        if(_.isUndefined(atrs.isFree) || !_.isBoolean(atrs.isFree)) {
            throw new Error('isFree attribute should have boolean value');
        }

        this.url = this.url.replace(/:idRunner/, this.attributes.idRunner);
    },

    parse: function(response) {
        //todo: cover with test
        response.betslipType = response.betslipType || 'STD';
        response.betCategory = response.betCategory || 'FXD';
        response.unitStake = response.unitStake || 0;

        var marks = this.attributes.betType === 'ITA' ? `/${response.programNumber}///` : this.attributes.betType === 'TRT' ? `//${response.programNumber}//` : `${response.programNumber}////`;
        response.marks = response.marks || marks;

        //get related runner name for h2h
        if(response.h2hOpponents && _.isArray(response.h2hOpponents)) {
            response.h2hOpponentsNames = response.h2hOpponents.map(function(value) {
                return value.name;
            }).join(' vs ');

            response.h2hOpponentsArray = response.h2hOpponents.map(function(value) {
                return value.relatedIdRunner;
            });            
        }

        return response;
    }
});

export default FixedDetailsModel;
