'use strict';

import _                    from 'underscore';
import $                    from 'jquery';
import Backbone             from 'backbone';
import app                  from 'components/core/application';
import template             from 'modules/h2h/next_h2h.tpl.hbs';
import H2HCollection        from 'modules/h2h/next_h2h.collection';
import bettingService       from 'components/betting/betting.service';
import EmptyStateView       from 'components/view/empty_state';
import BackboneCloseView    from 'components/core/mixins/Backbone.CloseView';
import h2hPushService       from 'modules/h2h/h2hPushService.js';

/**
 * H2H events view Controller
 * @name NextH2HView
 * @constructor
 *
 * @requires Underscore
 * @requires jQuery
 * @requires Backbone
 * @requires template
 * @requires H2HCollection
 * @requires bettingService
 * @requires EmptyStateView
 * @requires BackboneCloseView
 *
 * @description
 *     List H2H events
 *
 * @returns Function - Constructor function for NextH2HView
 */
var NextH2HView = Backbone.View.extend(
    _.extend({}, BackboneCloseView, {

        template: template,

        events: {
          'click [data-idRunner]': 'showDialog'
        },

        initialize: function() {
            this.DOM = {};
            this.options = {};
            this.empty_state_options = {
                icon: 'noHighlights',
                title: app.polyglot.t('msg_no_results_found'),
                link: {
                    clickable: false,
                    text: app.polyglot.t('btn_goto_next_races'),
                    url: '/upcoming'
                }
            };

            this.collection = new H2HCollection();
            this.listenTo(this.collection, 'sync', this.render, this);
            this.collection.fetch();

            h2hPushService.init();
        },

        render: function(collection) {
            this.$el.html(this.template({data: this.collection.toJSON()}));
            if(collection) {
                this.DOM.empty_state = this.$('[data-empty-state]');
                this.manageEmptyState(this.collection.length > 0);

                if(this.collection.length > 0) {
                    //this.initScrollerAndScrollTo(this.$('[data-h2h-' + this.options.id + ']'));
                }
            }
            return this;
        },

        /**
         * @method showDialog
         * @description
         *      Initialize betting dialog view with initial betModel attributes
         *
         * @param e
         */
        showDialog: function(e) {
            var link = $(e.target);
            var betType = link.attr('data-betType');
            var idRunner = parseInt(link.attr('data-idRunner'));
            let idRace = parseInt(link.attr('data-idRace'));

            if(idRunner.length < 1) return;

            let raceData = this.collection.findWhere({'idRace': idRace});
            let runnerData = _.findWhere(raceData.attributes.topRunners, {'idRunner': idRunner});

            bettingService.showQuickbet({
                attributes: {
                    idRunner: idRunner,
                    betType: betType,
                    betCategory: 'FXD',
                    isH2H: true
                },
                runnerModel: {
                    attributes: runnerData,
                    // this is to get the quick bet dialog work
                    toJSON: () => {
                        return runnerData
                    }
                }
            });
        },

        manageEmptyState: function(remove=false) {
            if (remove) {
                if(this.empty_state) {
                    this.empty_state.close();
                    this.empty_state = null;
                }
            } else {
                if(!this.empty_state) {
                    this.empty_state = new EmptyStateView(this.empty_state_options);
                }
                this.DOM.empty_state.html(this.empty_state.render().$el);
                this.empty_state.delegateEvents();
            }
        },

        onClose: function () {
            if(this.empty_state) {
                this.empty_state.close();
            }

            this.collection.stopListening();
        }
    })
);

export default NextH2HView;
