var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
    return this.escapeExpression(this.lambda((depth0 != null ? depth0.betType : depth0), depth0));
},"3":function(depth0,helpers,partials,data) {
    return this.escapeExpression(this.lambda((depth0 != null ? depth0.race_type : depth0), depth0));
},"5":function(depth0,helpers,partials,data) {
    var alias1=this.lambda, alias2=this.escapeExpression;

  return "                    <div class=\"m-quick__selectedRunners\">"
    + alias2(alias1((depth0 != null ? depth0.time : depth0), depth0))
    + " "
    + alias2(alias1((depth0 != null ? depth0.race : depth0), depth0))
    + " : "
    + alias2(alias1((depth0 != null ? depth0.runners : depth0), depth0))
    + "</div>\n";
},"7":function(depth0,helpers,partials,data) {
    var stack1;

  return "                    <div class=\"m-quick__selectedRunners\">\n                        "
    + this.escapeExpression(this.lambda((depth0 != null ? depth0.key : depth0), depth0))
    + ":\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.col : depth0),{"name":"each","hash":{},"fn":this.program(8, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                    </div>\n";
},"8":function(depth0,helpers,partials,data) {
    return "                            <span>"
    + this.escapeExpression(this.lambda(depth0, depth0))
    + "</span>\n";
},"10":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "            <!--<div class=\"m-quick__label mb1\">"
    + this.escapeExpression(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_odds",{"name":"translate","hash":{},"data":data}))
    + "</div>-->\n            <div class=\"c-grid\">\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.betCategoryOptions : depth0),{"name":"each","hash":{},"fn":this.program(11, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            </div>\n";
},"11":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "                    <div class=\"c-grid__col c-grid__col--noPadding is-1-"
    + alias2(alias1((depths[1] != null ? depths[1].buttonsLength : depths[1]), depth0))
    + " v-align-t\">\n                        <button class=\"c-btn c-btn--block c-btn--light m-quick__btn "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.active : depth0),{"name":"if","hash":{},"fn":this.program(12, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\" data-category=\""
    + alias2(alias1((depth0 != null ? depth0.key : depth0), depth0))
    + "\">\n                            <div class=\"m-quick__btnText\">"
    + alias2(alias1((depth0 != null ? depth0.title : depth0), depth0))
    + "</div>\n                        </button>\n                    </div>\n";
},"12":function(depth0,helpers,partials,data) {
    return "isSelected";
},"14":function(depth0,helpers,partials,data) {
    return "<span>/<span data-converted-amount>"
    + this.escapeExpression(this.lambda((depth0 != null ? depth0.total_stake_converted : depth0), depth0))
    + "</span></span>";
},"16":function(depth0,helpers,partials,data) {
    return "            <div class=\"table-row mt1\">\n                <div class=\"m-betslip__taxInfo m-betslip__taxInfo--visible\" data-tax-info>\n                    <div class=\"table-row m-betslip__infRow\">\n                        <div class=\"table-cell m-betslip__secondaryInfo\">\n                            "
    + this.escapeExpression(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_tax_RWLG",{"name":"translate","hash":{},"data":data}))
    + "\n                        </div>\n                        <div class=\"table-cell m-betslip__secondaryInfo text-right\" data-tax-value>0.5</div>\n                    </div>\n                </div>\n            </div>\n";
},"18":function(depth0,helpers,partials,data) {
    var stack1;

  return "        <div class=\"m-bonus-warning "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.hasOnlyBonus : depth0),{"name":"if","hash":{},"fn":this.program(19, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " m-quick__section\" data-bonus-warning>\n            <div class=\"c-alert c-alert--info\">\n                <div class=\"c-alert__title\"><span class=\"rb-icon rb-icon--info-2\"></span> "
    + this.escapeExpression(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"msg_bonus_money_info",{"name":"translate","hash":{},"data":data}))
    + "</div>\n                <div class=\"c-alert__body\" data-bonus-warning-message>\n                        "
    + ((stack1 = __default(require("../helpers/handlebar-helpers/translateInterpolate.js")).call(depth0,"txt_bonus_money_info",{"name":"translateInterpolate","hash":{"url":"http://support.racebets.com/customer/portal/topics/355779-bonuses/articles"},"data":data})) != null ? stack1 : "")
    + "\n                </div>\n            </div>\n        </div>\n";
},"19":function(depth0,helpers,partials,data) {
    return " m-bonus-warning--visible";
},"21":function(depth0,helpers,partials,data) {
    return "        <div class=\"m-bonus-warning m-quick__section\" data-diff-currency-warning>\n            <div class=\"c-alert c-alert--info\">\n                <div class=\"c-alert__body\">\n                    <span class=\"rb-icon rb-icon--info-2\"></span>\n                    <span data-diff-currency-message></span>\n                </div>\n            </div>\n        </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "<div class=\"m-quick\" data-wrap>\n    <div class=\"m-quick__section\">\n        <div class=\"table-row\">\n            <div class=\"table-cell\">\n                <div class=\"m-quick__raceTitle\">\n                    "
    + alias2(alias1((depth0 != null ? depth0.race_title : depth0), depth0))
    + "\n                    <span class=\"m-quick__raceType\">("
    + ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.race_type : depth0),{"name":"unless","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.race_type : depth0),{"name":"if","hash":{},"fn":this.program(3, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ")</span>\n                </div>\n\n"
    + ((stack1 = helpers.blockHelperMissing.call(depth0,alias1((depth0 != null ? depth0.selected_races : depth0), depth0),{"name":"selected_races","hash":{},"fn":this.program(5, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.cols : depth0),{"name":"each","hash":{},"fn":this.program(7, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            </div>\n\n            <div class=\"table-cell v-align-t\">\n                <span data-close class=\"rb-icon rb-icon--cancel m-quick__cancel\"></span>\n            </div>\n        </div>\n\n        <!--categories-->\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.betCategoryOptions : depth0),{"name":"if","hash":{},"fn":this.program(10, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n\n    <div class=\"m-quick__section\">\n        <div class=\"m-betslip__inputWrapper\">\n            <label for=\"amount\" class=\"m-betslip__inputLabel\">"
    + alias2(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_amount",{"name":"translate","hash":{},"data":data}))
    + "</label>\n            <div class=\"c-amountInput\">\n                <input id=\"amount\" data-id=\"unitStake\" type=\"text\" data-amount class=\"c-form__inputText c-form__inputText--border-gray c-amountInput__input\" value=\""
    + alias2(alias1((depth0 != null ? depth0.stake : depth0), depth0))
    + "\">\n                <div data-validation_unitStake class=\"c-form__validationMessage\"></div>\n                <span class=\"c-amountInput__currency\" data-currency=\"\">"
    + alias2(alias1((depth0 != null ? depth0.currency_symbol : depth0), depth0))
    + "</span>\n            </div>\n        </div>\n\n        <div class=\"table-row mt1\">\n            <div class=\"m-betslip__taxInfo m-betslip__taxInfo--visible\">\n                <div class=\"table-row m-betslip__infRow\">\n                    <div class=\"table-cell m-betslip__secondaryInfo\">\n                        "
    + alias2(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_total_stake",{"name":"translate","hash":{},"data":data}))
    + "\n                    </div>\n                    <div class=\"table-cell m-betslip__secondaryInfo text-right\"><span data-total-stake-value>"
    + alias2(alias1((depth0 != null ? depth0.total_stake : depth0), depth0))
    + "</span>"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.convert_to_user_currency : depth0),{"name":"if","hash":{},"fn":this.program(14, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n                </div>\n            </div>\n        </div>\n\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.isGermanCustomer : depth0),{"name":"if","hash":{},"fn":this.program(16, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.isUkCustomer : depth0),{"name":"if","hash":{},"fn":this.program(18, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.showDiffCurancyInfo : depth0),{"name":"if","hash":{},"fn":this.program(21, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n    <div class=\"m-quick__section mb2\">\n        <button class=\"c-btn c-btn--primary c-btn--block c-btn--large mb1\" data-bet>"
    + alias2(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_bet",{"name":"translate","hash":{},"data":data}))
    + "</button>\n    </div>\n</div>\n";
},"useData":true,"useDepths":true});