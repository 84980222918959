var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
    return "<span class=\"c-badge c-badge--eventState\">"
    + this.escapeExpression(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_new",{"name":"translate","hash":{},"data":data}))
    + "</span>";
},"3":function(depth0,helpers,partials,data) {
    return "<span class=\"c-badge c-badge--betType\">"
    + this.escapeExpression(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_priced_up_short",{"name":"translate","hash":{},"data":data}))
    + "</span>";
},"5":function(depth0,helpers,partials,data) {
    return "<a data-special class=\"c-badge c-badge--eventType\">"
    + this.escapeExpression(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_special_bets_short",{"name":"translate","hash":{},"data":data}))
    + "</a>";
},"7":function(depth0,helpers,partials,data) {
    return "<a data-h2h class=\"c-badge c-badge--eventType\">"
    + this.escapeExpression(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_h2h_short_2",{"name":"translate","hash":{},"data":data}))
    + "</a>";
},"9":function(depth0,helpers,partials,data) {
    var stack1;

  return "            <div class=\"c-dataList__el--time\" "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.id_next_race : stack1),{"name":"if","hash":{},"fn":this.program(10, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.firstStartFormatted : stack1), depth0))
    + "</div>\n";
},"10":function(depth0,helpers,partials,data) {
    return " data-next ";
},"12":function(depth0,helpers,partials,data) {
    var stack1;

  return "            <div class=\"c-dataList__el--time\" "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.countDown : stack1),{"name":"if","hash":{},"fn":this.program(13, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.id_next_race : stack1),{"name":"if","hash":{},"fn":this.program(10, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + this.escapeExpression(this.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.time : stack1)) != null ? stack1.textValue : stack1), depth0))
    + "</div>\n";
},"13":function(depth0,helpers,partials,data) {
    return " data-countdown";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression, alias3=helpers.blockHelperMissing;

  return "<li data-id=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.eventId : stack1), depth0))
    + "\" class=\"c-dataList__row\">\n    <div class=\"c-dataList__cell\">\n        <span class=\"c-raceType isType"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.type : stack1), depth0))
    + "\"></span>\n    </div>\n    <div class=\"c-dataList__cell\">\n        <div class=\"c-dataList__truncateText\">\n            <div>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.title : stack1), depth0))
    + "</div>\n        </div>\n    </div>\n    <div class=\"c-dataList__cell c-dataList__cell--badges\">\n        "
    + ((stack1 = alias3.call(depth0,alias1(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.is_new : stack1), depth0),{"name":"model.is_new","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = alias3.call(depth0,alias1(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.is_priced_up : stack1), depth0),{"name":"model.is_priced_up","hash":{},"fn":this.program(3, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = alias3.call(depth0,alias1(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.id_event_specials : stack1), depth0),{"name":"model.id_event_specials","hash":{},"fn":this.program(5, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = alias3.call(depth0,alias1(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.id_event_h2h : stack1), depth0),{"name":"model.id_event_h2h","hash":{},"fn":this.program(7, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n    </div>\n    <div class=\"c-dataList__cell c-dataList__cell--light\">\n"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.calendarTime : stack1),{"name":"if","hash":{},"fn":this.program(9, data, 0),"inverse":this.program(12, data, 0),"data":data})) != null ? stack1 : "")
    + "    </div>\n</li>\n";
},"useData":true});