'use strict';
import _                 from 'underscore';
import $                 from 'jquery';
import app               from 'components/core/application';
import Backbone          from 'backbone';
import template          from 'modules/home/antepost.tpl.hbs';
import BackboneSubView   from 'components/core/mixins/Backbone.SubView';
import BackboneCloseView  from 'components/core/mixins/Backbone.CloseView';
import HomeSubViewsMixin  from 'modules/home/home.subviews.mixin';
import countDown         from 'components/helpers/countDown';
import pushService       from 'modules/home/homePushService';
import user              from 'components/user/user';
import countryList       from 'modules/home/country-list';
import helpers           from 'components/helpers/helpers';


/**
 * Antepost view Controller
 * @name AntepostView
 * @constructor
 *
 * @requires Underscore
 * @requires jQuery
 * @requires app
 * @requires BackboneSubView
 * @requires HomeCollection
 *
 * @description
 *     Antepost View Controller
 *
 * @returns Function - Constructor function for Antepost view
 */
export default Backbone.View.extend(
    _.extend({}, BackboneSubView, BackboneCloseView, HomeSubViewsMixin, {

        className: 'l-scrollWithAdditionalControl',

        template: template,

        events: {
            'click [data-type]': 'setAntepostType'
        },

        initialize: function (opt) {
            this.DOM = {};
            this.data = {};
            this.childViews = [];

            this.current_empty_state = {
                icon: 'default',
                    title: app.polyglot.t('msg_no_events'),
                    link: {
                    clickable: true,
                        text: app.polyglot.t('h_reset_filters')
                }
            };

            this.parentView = opt.parentView;

            this.listenTo(this.parentView.subViews.filtersView, 'filter:selected', () => {
                this.data.previousEvents = this.data.events;
                this.data.events = this.setupData();
                this.selectiveRender();
            });

            this.collection = this.parentView.collection;
            this.loaded = this.parentView.loaded; // this value is updated in the home.js view on collection sync event
            this.data.events = this.setupData();

            this.listenTo(this.collection, 'update', () => {
                this.data.previousEvents = this.data.events;
                this.data.events = this.setupData();
                this.selectiveRender();
            });

            pushService.manageCurrentChannel();
            this.collection.setSocketChannelListener(pushService.channels.current);
        },

        setAntepostType: function (e) {
            var type = $(e.target).attr('data-type');
            var filterState = this.parentView.subViews.filtersView.filter.filterState;
            if (filterState.antepost.special !== (type === "special")) {
                filterState.antepost.special = type === "special";
                this.parentView.subViews.filtersView.filter.saveToCookie();
                this.data.events = this.setupData();
                this.render();
            }
        },

        setupData: function () {
            // apply race type filters
            var filterState = this.parentView.subViews.filtersView.filter.filterState;
            var collection = new Backbone.Collection(this.collection.applyRaceTypeFilter(filterState.type));
            // used in the view to enable the right filter button
            this.data.filterState = filterState;

            var data = collection.toJSON();

            // filter general/special antepost events
            // filter only antepost tab events
            var antepostEvents = _.filter(data, function (event) {
                return event.is_ante_post && event.is_special === filterState.antepost.special
            });
            // sort by first_time
            var sortedByTime = _.sortBy(antepostEvents, function (event) {
                return event.first_start;
            });
            // group by country
            var groupByCountry = _.groupBy(sortedByTime, function (event) {
                return event.country;
            });
            // group the events for the country-list view logic
            var splittedEvents = _.mapObject(groupByCountry, function (country) {
                let result = {
                    hidden: country
                };
                result.showArrow = true;
                return result
            });

            var sortedCountryArray = this.sortByCountry(splittedEvents);
            return this.hashObjects(sortedCountryArray);
        },

        render: function () {
            this.closeSubViews();
            this.$el.html(this.template(this.data));
            this.DOM.list = this.$('[data-list]');

            if(_.size(this.data.events) > 0) {
                var groupFragment = document.createDocumentFragment();
                _.each(this.data.events, (country) => {
                    // a group is a set of models of the same country
                    let groupView = new countryList({
                        'data': country,
                        'parentView': this
                    });

                    groupFragment.appendChild(groupView.render().el);
                    this.childViews.push(groupView);
                });

                this.DOM.list.append(groupFragment);
                _.each(this.childViews, function (childView) {
                    childView.trigger('view:appended');
                });
            } else {
                this.manageEmptyState();
            }

            return this;
        },

        closeSubViews: function () {
            _.each(this.childViews, function (childView) {
                childView.close();
            });
            this.childViews = null;
            this.childViews = [];
        },

        onClose: function () {
            this.closeSubViews();

            if(this.empty_state) {
                this.empty_state.close();
            }
        }

    })
);
