var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
    return "        <a class=\"c-dialog__closeButton rb-icon rb-icon--cancel\" data-dialog-closeBtn role=\"button\"></a>\n";
},"3":function(depth0,helpers,partials,data) {
    return "        <header class=\"c-dialog__header\">\n            <h2 class=\"c-dialog__title\" data-dialog-title>"
    + this.escapeExpression(this.lambda((depth0 != null ? depth0.title : depth0), depth0))
    + "</h2>\n        </header>\n";
},"5":function(depth0,helpers,partials,data) {
    return "            <div class=\"c-dialog__body\">\n                <a class=\"c-btn c-btn--block c-btn--primary\" data-dialog-closeBtn role=\"button\">"
    + this.escapeExpression(this.lambda((depth0 != null ? depth0.closeBtn : depth0), depth0))
    + "</a>\n            </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"l-scrollWrapper\">\n    <div data-el class=\"c-dialog l-scrollV\">\n"
    + ((stack1 = helpers["if"].call(depth0,__default(require("../helpers/handlebar-helpers/exists.js")).call(depth0,(depth0 != null ? depth0.closeBtnIcon : depth0),{"name":"exists","hash":{},"data":data}),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(depth0,__default(require("../helpers/handlebar-helpers/exists.js")).call(depth0,(depth0 != null ? depth0.title : depth0),{"name":"exists","hash":{},"data":data}),{"name":"if","hash":{},"fn":this.program(3, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "        <div class=\"c-dialog__body\" data-dialog-body>Loading ...</div>\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.closeBtn : depth0),{"name":"if","hash":{},"fn":this.program(5, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"useData":true});