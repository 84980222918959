import $                        from 'jquery';
import app                      from 'components/core/application';
import user                     from 'components/user/user';
import LoadingManager           from 'components/core/LoadingManager';
import { onMeLoaded }           from 'components/core/application.init.callback';
import { BonusContext }         from 'modules/bonus/bonus.context'; BonusContext();

export function initialize() {
    app.brand = 'europebet';
    app.domain = location.host.replace('m-racing.', '');
    app.loadingManager = LoadingManager;

    // TODO: move this listener in the loading screen, which should be a backbone view or at least extend backbone.event mixin
    app.listenTo(app, 'app:scroll', (scroll) => {
        app.loadingManager.DOM.container.css({'top': scroll + ((app.iframeHeight ? app.iframeHeight : 400) / 2)});
    });

    $.when(user.init().getUserData()).then(onMeLoaded);
}
