var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
    var stack1;

  return "        <video id=\"media-player\" class=\"video-js vjs-default-skin\" controls>\n"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.url : depth0)) != null ? stack1.http : stack1),{"name":"if","hash":{},"fn":this.program(2, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.url : depth0)) != null ? stack1.rtmp : stack1),{"name":"if","hash":{},"fn":this.program(4, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "        </video>\n";
},"2":function(depth0,helpers,partials,data) {
    var stack1, helper, alias1=this.escapeExpression;

  return "                <source src=\""
    + alias1(this.lambda(((stack1 = (depth0 != null ? depth0.url : depth0)) != null ? stack1.http : stack1), depth0))
    + "\" type='"
    + alias1(((helper = (helper = helpers.mediaType || (depth0 != null ? depth0.mediaType : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"mediaType","hash":{},"data":data}) : helper)))
    + "'>\n";
},"4":function(depth0,helpers,partials,data) {
    var stack1;

  return "                <source src=\""
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.url : depth0)) != null ? stack1.rtmp : stack1), depth0))
    + "\" type='rtmp/mp4'>\n";
},"6":function(depth0,helpers,partials,data) {
    var stack1;

  return "        <audio id=\"media-player\" class=\"video-js vjs-default-skin\" controls height=\"30\">\n"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.url : depth0)) != null ? stack1.http : stack1),{"name":"if","hash":{},"fn":this.program(2, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.url : depth0)) != null ? stack1.rtmp : stack1),{"name":"if","hash":{},"fn":this.program(4, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "        </audio>\n";
},"8":function(depth0,helpers,partials,data) {
    var stack1;

  return "        <iframe id=\"media-player\" class=\"iframe\" src=\""
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.url : depth0)) != null ? stack1.iframe : stack1), depth0))
    + "\" allowfullscreen></iframe>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<div data-media class=\"m-media\">\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.video : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.audio : depth0),{"name":"if","hash":{},"fn":this.program(6, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.iframe : depth0),{"name":"if","hash":{},"fn":this.program(8, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});