import $                        from 'jquery';
import _                        from 'underscore';
import Backbone                 from 'backbone';
import app                      from 'components/core/application';
import Router                   from '../../router';
import { defaultRoutesList }    from '../../routes.default';
import ErrorHandler             from 'components/core/ErrorHandler';
import AjaxPrefilter            from 'components/core/AjaxPrefilter';
import Canvas                   from 'components/core/app-canvas';
import Footer                   from 'components/core/app-footer';
import {
    beforeRouteChange,
    afterRouteChange
}                               from 'components/core/router.hooks';
import Overlay                  from 'components/core/app-overlay';
import OverlaySecondary         from 'components/core/app-overlaySecondary';
import user                     from 'components/user/user';
import SubHeader                from 'components/sub_header/SubHeader';
import helpers                  from 'components/helpers/helpers';
import BetslipDialog            from 'components/betting/betslip';
import tagManager               from 'utils/tag-manager';
import PostMessages             from 'utils/post_messages';
import { globalLinkHandler }    from 'utils/link-handler';

export const onDataLoaded = function () {
    // this flag is set to false after the first route load
    app.firstroute = true;

    var errorHandler = new ErrorHandler();
    let ajaxPrefilter = new AjaxPrefilter();

    app.footer = new Footer();

    // initiate layout views and view managers
    app.betslip = new BetslipDialog({
        closeOnOutsideClick: true
    });

    // initiate router
    app.router = Router.getInstance();
    app.subHeader = new SubHeader(defaultRoutesList);
    app.overlay = Overlay.getInstance();
    app.overlaySecondary = OverlaySecondary.getInstance();

    // add global route change callbacks
    app.router.on('before:route:change', beforeRouteChange);
    app.router.on('after:route:change', afterRouteChange);

    // setup Google Tag manager
    tagManager.init();
    // initiate PostMessages listeners and events
    PostMessages.init();

    app.canvas = new Canvas();

    Backbone.history.start({pushState: true});

    // intercept all clicks on links
    $(document).on('click tap', 'a[role!="button"]', globalLinkHandler);

    if (helpers.device.isAndroid) {
        app.DOM.appCanvas.attr('data-device', "android");
    }

    if (helpers.device.isIOS) {
        app.DOM.appCanvas.attr('data-device', "ios");
    }

    /**
     * Listen to user auth status changes
     * @description
     *  Fired when /me request is successfully resolved
     */
    app.on('user:auth:status', (data) => {
        if (data.loggedIn === false && data.changed) {
            location.reload(true);
        }
    });

    /**
     * Listen to page visibility change (user switches to a different tab or minimizes the browser window)
     */
    let state = helpers.getPageVisibilityProp();
    let handleVisibilityChange = function () {
        if (!document[state.hidden]) {
            user.load();
        }
    };

    document.addEventListener(state.visibilityChange, handleVisibilityChange, false);
    app.trigger('app:bootstrapped');
};
