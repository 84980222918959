'use strict';

import Backbone  from 'backbone';

var FreeBetModel = Backbone.Model.extend({

    idAttribute: 'idFreebet'
});

export default FreeBetModel;
