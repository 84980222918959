'use strict';
import app  from 'components/core/application';

var countDown = function() {
    var int;

    var _init = function(duration, eventName) {

        var timer = duration,
            minutes,
            seconds;

        int = setInterval(function () {
            if (--timer < 0) {
                _destroy();
            }

            minutes = parseInt(timer / 60, 10);
            seconds = parseInt(timer % 60, 10);

            minutes = minutes < 10 ? '0' + minutes : minutes;
            //seconds = seconds < 10 ? '0' + seconds : seconds;
            //minutes + ':' + seconds

            app.trigger(eventName, minutes);
        }, 60000);// 1000 = 1 sec, 60000 = 1 min
    };

    var _destroy = function() {

        clearInterval(int);
    };

    return {
        init: _init,
        destroy: _destroy
    };
};

export default countDown;
