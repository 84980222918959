import _                            from 'underscore';
import Backbone                     from 'backbone';
import app                          from 'components/core/application';
import helpers                      from 'components/helpers/helpers';
import moment                       from 'moment-timezone';
import user                         from 'components/user/user';
import eventTimeManager             from 'components/helpers/eventTimeManager';
import raceHelpers                  from 'modules/race/race.helpers';

var RaceModel = Backbone.Model.extend({

    idAttribute: "idRace",

    initialize: function () {
        this.attributes.raceTitleLabel = helpers.getTitle(this.attributes.raceTitle, this.attributes.raceNumber);
        this.attributes.postTimeLabel = this.getTime();
        this.attributes.postTimeDateLabel = this.getDate();
        this.attributes.countDownTime = this.getCountDownTime();
        this.attributes.purseLabel = raceHelpers.getPurse(this.attributes);
        this.attributes.distanceLabel = raceHelpers.getDistance(this.attributes);
        
        const category = raceHelpers.getCategory(this.attributes);
        const raceClass = raceHelpers.getClass(this.attributes.categoryLetter, this.attributes.country);
        this.attributes.categoryLabel = category !== null ? `${category} ${raceClass}` : null;
        
        this.attributes.raceTypeLabel = this.getRaceType();
        this.attributes.favourites = this.getFavourites();
        this.attributes.isGreyhound = raceHelpers.isGreyhound(this.attributes);

        if (this.attributes.results && this.attributes.results[0] && this.attributes.results[0].winOdds) {
            var oddsFormat = user.getOddsFormat();
            this.attributes.results[0].winOddsFormatted = helpers.formatOdds(this.attributes.results[0].winOdds, oddsFormat);
        }
    },

    getTime: function () {
        var time = this.attributes.postTime ? this.attributes.postTime : this.attributes.raceTime;

        if (this.attributes.isAntePost) {
            var date = moment.unix(time).tz(user.timeZone()).format('L');
            // regexp extract month and day from dd/mm/yyyy or dd.mm.yyyy
            // if there are no matches falls back to full date
            date = date.match(/(\d{2}.\d{2})/) === null ? date : date.match(/(\d{2}.\d{2})/)[0];
            return date;
        }
        return moment.unix(time).tz(user.timeZone()).format('H:mm');
    },

    getDate: function () {
        var time = this.attributes.postTime ? this.attributes.postTime : this.attributes.raceTime;
        return moment.unix(time).tz(user.timeZone()).format('L');
    },

    getCountDownTime: function () {
        var time = this.attributes.postTime ? this.attributes.postTime : this.attributes.raceTime;


        return eventTimeManager.countDownTime(time);
    },

    getRaceType: function () {
        if (_.isUndefined(this.attributes.raceType)) {
            return ''
        }
        return app.polyglot.t('label_race_type_' + this.attributes.raceType.toLowerCase());
    },

    getAgeCondition: function () {
        if (_.isUndefined(this.attributes.ageFrom) || _.isUndefined(this.attributes.ageTo)) {
            return null
        }

        var ageFrom = this.attributes.ageFrom + ' ';
        var ageTo = this.attributes.ageTo + ' ';

        if (ageFrom != 0 && ageTo != 0 && ageFrom != ageTo) {
            return app.interpolate('label_condition_age_x_to_y', {
                ageFrom: ageFrom,
                ageTo: ageTo
            });
        } else if (ageFrom != 0 && ageTo != 0 && ageFrom == ageTo) {
            return app.interpolate('label_condition_age_x', {age: ageFrom});
        } else if (ageFrom != 0) {
            return app.interpolate('label_condition_age_x_plus', {age: ageFrom});
        } else if (ageTo != 0) {
            return app.interpolate('label_condition_age_up_to_x', {age: ageTo});
        }
    },

    getFavourites: function () {
        return _.map(this.attributes.favourites, (favourite) => {
            favourite.winOdds = helpers.formatOdds(favourite.winOdds, user.data.oddsFormat);
            return favourite;
        });
    }

});

export default RaceModel;
