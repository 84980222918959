'use strict';
import _                                from 'underscore';
import app                              from 'components/core/application';
import BaseRouter                       from 'components/core/BaseRouter';
import viewManager                      from 'components/core/ViewManager';

//auth
import auth                             from 'components/auth/auth';

//settings
import SettingsView                     from 'modules/settings/settings';
import ModifySettingsView               from 'modules/settings/modify_settings';

//event
import EventView                        from 'modules/event/event';

//h2h
import nextH2HView                      from 'modules/h2h/next_h2h';
import eventH2hView                     from 'modules/h2h/event-h2h';

//my bets
import myBetsView                       from 'modules/mybets/mybets';

//streams
import liveStreamsView                  from 'modules/streams/streams';

//next races
import nextRacesView                    from 'modules/next-races/next-races';

//import routes
import homeRouter                       from 'modules/home/home.routes';
import raceRouter                       from 'modules/race/race.routes';
import extrasRouter                     from 'modules/extras/extras.routes';
import bonusRouter                      from 'modules/bonus/bonus.routes';

import webSocketManager                 from 'components/helpers/webSocketManager';


/**
 * Router
 * @module Router
 * @constructor
 *
 * @requires BaseRouter
 *
 * @description
 *  this.visitedRoutes contains all previously visited routes and is available in callback functions
 *
 * @returns {Function} - Constructor function for Router
 */

var Router = BaseRouter.extend(_.extend({},
    homeRouter.controllers,
    raceRouter.controllers,
    extrasRouter.controllers,
    bonusRouter.controllers,
    {

    initialize: function () {
        app.on('navigate:back', this.navigateBack, this);
        app.on('navigate', (url, trigger, channel) => {
            if (channel) {
                webSocketManager.leaveChannel(channel);
            }

            this.navigateTo(url, trigger);
        }, this);
    },

    routes: _.extend({},
        homeRouter.routes,
        raceRouter.routes,
        extrasRouter.routes,
        bonusRouter.routes,
        {

        //settings
        'settings': 'settings',
        'settings/:type': 'modifySettings',

        //event old
        'event/details/id/:id(/)': 'eventView',

        //h2h
        'next-h2h': 'nextH2H',
        'h2h(/:id)': 'eventH2H',

        //my bets
        'bets': 'myBets',
        'bets/:tab': 'myBets',
        'bets/:tab/page/:page': 'myBets',
        'bets/:tab/page/:page/from/:from/to/:to': 'myBets',

        //betslip detail view from mybets
        'betslip/id/:id': 'betslip',

        //streams
        'livestreams': 'livestreams',

        //next-races
        'upcoming': 'nextRaces',

        //404 page
        '404': 'page404',

        '*default': 'page404'
    }),


    /**
     * @property routeRestrictions - List of route restrictions rules
     * @type {Object}
     *
     * @description
     *      Define routes restrictions key using regular expressions or as in router.routes
     *      Values needs a corresponding filter function in router.restrictions
     *      Possible values example: ['auth', 'guest']
     */

    routeRestrictions: {
        'bonus*any': 'auth',
        'mybets*any': 'auth',
        'betslip*any': 'auth'
    },

    restrictions: {
        auth: function (fragment, args, next) {
            if (!auth.isUserLoggedIn()) {
                app.router.navigate('login', {trigger: true});
                return false;
            }
            next();
        },

        guest: function (fragment, args, next) {
            if (auth.isUserLoggedIn()) {
                app.router.navigate('/', {trigger: true});
                return false;
            }
            next();
        }
    },

    /**
     * @property routTransformationMap - routes are mapped to transformed values. Usually these are same routes but without "success" or "error" params
     *
     * @description
     *   When navigating back by clicking "Back" button in SubHeader urls will be transformed in order to avoid showing dialogs for statuses "success/error"
     *   Dialogs are shown based on "success/error" url params and we avoid showing them when user navigates through browser history.
     *
     *   !!!Note: this does not modify browser history however and if browser "Back" buttons is clicked, the status dialog will be shown whenever user navigates to a route with "success/error" params
     */
    routeTransformationMap: { },

    //404
    page404: function () {
        app.trigger('app:pagenotfound');
    },

    //auth
    login: function () {
        auth.manageLogin();
    },

    //settings
    settings: function () {
        app.subHeader.title(app.polyglot.t('label_settings'));
        viewManager.removePrevView().show(new SettingsView());
    },

    modifySettings: function (type) {
        if (type === 'language') {
            app.subHeader.title(app.polyglot.t('label_language'));
        } else if (type === 'odds_format') {
            app.subHeader.title(app.polyglot.t('label_odds_format'));
        } else {
            app.subHeader.title(app.polyglot.t('label_settings'));
        }
        viewManager.removePrevView().show(new ModifySettingsView({type: type}));
    },

    //event
    eventView: function (id) {
        viewManager.removePrevView().show(new EventView({id: id}));
    },

    nextH2H: function () {
        app.subHeader.title(app.polyglot.t('h_next_h2hs'));
        viewManager.removePrevView().show(new nextH2HView());
    },

    eventH2H: function (id) {
        app.subHeader.title('');
        viewManager.removePrevView().show(new eventH2hView(id));
    },

    myBets: function (tab, from, to, page) {
        app.subHeader.title(app.polyglot.t('label_sidemenu_my_bets'));
        viewManager.showWithSubView(myBetsView.ensureSameParentView({activeTab: tab, page: page, from: from, to: to}));
    },

    livestreams: function () {
        app.subHeader.title(app.polyglot.t('label_livestream'));
        viewManager.removePrevView().show(new liveStreamsView());
    },

    nextRaces: function () {
        app.subHeader.title(app.polyglot.t('label_menu_next_races'));
        viewManager.removePrevView().show(new nextRacesView());
    }
}));

export default Router;
