'use strict';
import _                    from 'underscore';
import $                    from 'jquery';
import app                  from 'components/core/application';
import Backbone             from 'backbone';
import template             from 'modules/home/calendar.tpl.hbs';
import BackboneSubView      from 'components/core/mixins/Backbone.SubView';
import BackboneCloseView    from 'components/core/mixins/Backbone.CloseView';
import HomeSubViewsMixin    from 'modules/home/home.subviews.mixin';
import moment               from 'moment-timezone';
import user                 from 'components/user/user';
import helpers              from 'components/helpers/helpers';
import countryList          from 'modules/home/country-list';
import pushService          from 'modules/home/homePushService';
import HomeCollection       from 'modules/home/home.collection';

/**
 * Calendar view Controller
 * @name CalendarView
 * @constructor
 *
 * @requires Underscore
 * @requires BaseView
 * @requires BackboneSubView
 *
 * @description
 *     Calendar View Controller
 *
 * @returns Function - Constructor function for Calendar view
 */
export default Backbone.View.extend(
    _.extend({}, BackboneSubView, BackboneCloseView, HomeSubViewsMixin, {

        template: template,

        className: 'app__subView l-scrollWithAdditionalControl',

        initialize: function (opt) {
            this.data = {
                dateInput: null,
                todayDate: moment().format('YYYY-MM-DD'), // Set date of today,
                todayDay: moment().format('D'),
                date: null,
                dayOfWeek: moment().format('dddd'),
                timeFormat: 'calendar' // passed down to the subviews, shows full time instead of countdowns on the event items
            };

            this.current_empty_state = {
                icon: 'noEvents',
                title: app.polyglot.t('msg_no_events'),
                link: {
                    clickable: true,
                    text: app.polyglot.t('h_reset_filters')
                }
            };

            this.data.date = this.data.todayDate;

            this.DOM = {};

            this.parentView = opt.parentView;

            this.listenTo(this.parentView.subViews.filtersView, 'filter:selected', () => {
                this.data.previousEvents = this.data.events;
                this.data.events = this.setupData();
                this.selectiveRender();
            });

            this.collection = new HomeCollection();
            this.collection.loadCalendarByDate(this.data.date);

            this.listenTo(this.collection, 'sync', (collection) => {
                this.data.events = this.setupData();
                this.render(collection);
                //reconnect to a chanel with a selected timestamp
                pushService.manageCalendarChannel({
                    date: this.data.date,
                    lastTimestamp: this.collection.data.lastTimestamp
                });
                //collection has to listen to the new channel for updates
                this.collection.setSocketChannelListener(pushService.currentCalendarChannelName);
            });

            this.listenTo(this.collection, 'update', () => {
                this.data.previousEvents = this.data.events;
                this.data.events = this.setupData();
                this.selectiveRender();
            });
        },

        events: function () {
            var events = {
                'click .c-dateSelect__control--prev': 'prevDay',
                'click .c-dateSelect__control--next': 'nextDay',
                'click [data-today]': 'selectToday'
            };

            if (helpers.device.isAndroid) {
                events['change [data-date-input]'] = 'getDate';
            } else {
                events['blur [data-date-input]'] = 'getDate';
            }

            return events;
        },

        setupData: function () {
            var filterState = this.parentView.subViews.filtersView.filter.filterState.type;
            var collection = new Backbone.Collection(this.collection.applyRaceTypeFilter(filterState));

            var data = collection.toJSON();
            // filter only current tab events
            var currentEvents = _.filter(data, function (event) {
                return !event.is_ante_post && !event.is_virtual;
            });
            // sort by first_time
            var sortedByTime = _.sortBy(currentEvents, function (event) {
                return event.first_start;
            });
            // group by country
            var groupByCountry = _.groupBy(sortedByTime, function (event) {
                return event.country;
            });
            // split current events and upcoming events
            var splittedEvents = _.mapObject(groupByCountry, function (country) {
                let result = {
                    hidden: country
                };
                result.showArrow = true;
                return result
            });

            var sortedCountryArray = this.sortByCountry(splittedEvents);
            return this.hashObjects(sortedCountryArray);
        },

        render: function (collection) {
            this.closeSubViews();
            this.$el.html(this.template(this.data));
            this.DOM.list = this.$('[data-list]');
            this.DOM.todayIcon = this.$('[data-today]');
            this.DOM.dateInput = this.$('[data-date-input]');
            this.DOM.dayOfWeek = this.$('[data-day-week]');

            if (_.size(this.data.events) > 0) {
                var groupFragment = document.createDocumentFragment();
                _.each(this.data.events, (country) => {
                    // a group is a set of models of the same country
                    let groupView = new countryList({
                        'data': country,
                        'parentView': this,
                        'timeFormat': this.data.timeFormat
                    });

                    groupFragment.appendChild(groupView.render().el);
                    this.childViews.push(groupView);
                });

                this.DOM.list.append(groupFragment);
                _.each(this.childViews, function (childView) {
                    childView.trigger('view:appended');
                });
            } else {
                if(collection) {
                    this.manageEmptyState();
                }
            }

            this.toggleTodayIcon();

            return this;
        },

        getDate: function(e) {
            var date = $(e.target).val();

            this.data.date = date;
            this.data.dayOfWeek = moment(date).format('dddd');
            this.toggleTodayIcon();

            this.collection.loadCalendarByDate(date);

            // if this.collection.data.lastTimestamp is undefined, it means the collection was cloned from the parent view and fetch wasn't called,
            // the lastTimestamp is set in the collection parse method.
            if (_.isUndefined(this.collection.data.lastTimestamp)) {
                this.collection.data.lastTimestamp = this.parentView.collection.data.lastTimestamp;
            }

            //reconnect to a chanel with a selected timestamp
            pushService.manageCalendarChannel({
                date: date,
                lastTimestamp: this.collection.data.lastTimestamp
            });
            //collection has to listen to the new channel for updates
            this.collection.setSocketChannelListener(pushService.currentCalendarChannelName);
        },

        selectToday: function (e) {
            this.DOM.dateInput
                .val(this.data.todayDate)
                .trigger('change')
                .trigger('blur');

            this.DOM.dayOfWeek.html(moment(this.data.todayDate, 'YYYY-MM-DD').format('dddd'))
        },

        prevDay: function (e) {
            var selectedDate = moment(this.data.date);
            var dayBefore = selectedDate.subtract(1, 'days').format('YYYY-MM-DD');

            this.DOM.dateInput
                .val(dayBefore);

            if (helpers.device.isAndroid) {
                this.DOM.dateInput.trigger('change');
            } else {
                this.DOM.dateInput.trigger('blur');
            }

            this.DOM.dayOfWeek.html(moment(dayBefore, 'YYYY-MM-DD').format('dddd'));
        },

        nextDay: function (e) {
            var selectedDate = moment(this.data.date);
            var dayAfter = selectedDate.add(1, 'days').format('YYYY-MM-DD');

            this.DOM.dateInput
                .val(dayAfter);

            if (helpers.device.isAndroid) {
                this.DOM.dateInput.trigger('change');
            } else {
                this.DOM.dateInput.trigger('blur');
            }

            this.DOM.dayOfWeek.html(moment(dayAfter, 'YYYY-MM-DD').format('dddd'));
        },

        toggleTodayIcon: function () {
            if (this.data.todayDate === this.data.date) {
                this.DOM.todayIcon.hide();
            } else {
                this.DOM.todayIcon.show();
            }
        },

        closeSubViews: function () {
            _.each(this.childViews, function (childView) {
                childView.close();
            });
            this.childViews = null;
            this.childViews = [];
        },

        onClose: function () {
            this.closeSubViews();
            this.collection = null;

            if(this.empty_state) {
                this.empty_state.close();
            }
        }

    })
);
