'use strict';

import _                            from 'underscore';
import Backbone                     from 'backbone';
import BackboneCloseView            from 'components/core/mixins/Backbone.CloseView';
import template                     from 'modules/race/runner-statistic.tpl.hbs';
import RunnerStatisticModel         from 'modules/race/runner-statistic.model';
import RaceModel                    from 'components/model/race.model';

/**
 * Event view
 *
 * @name RunnerStatisticView
 * @constructor
 *
 * @requires jQuery
 * @requires Underscore
 * @requires BackboneCloseView
 * @requires template
 *
 * @description
 * 		Runner statistic view.
 *
 * @returns {Function} - Constructor function for RunnerStatisticView
 */

var RunnerStatisticView = Backbone.View.extend(
    _.extend({}, BackboneCloseView, {

        template: template,

        initialize: function (opt) {
            this.DOM = {};
            this.data = {};
            this.parentView = opt.parentView;

            _.extend(this.data, opt.data);

            this.data.loaded = false;
            this.isLoading = false;

            this.model = new RunnerStatisticModel(null, {
                idRace: this.data.idRace,
                idSubject: this.data.idSubject
            });

            // defaults for the raceModel
            this.raceModel = {
                attributes: {}
            };

            this.listenTo(this.model, 'sync', () => {
                this.error = false;
                // using the race model to format the race data
                this.raceModel = new RaceModel(this.model.data.race);
                this.render();
                this.parentView.trigger('toggle:tab:loader');
                this.isLoading = false;
            });

            this.listenTo(this.model, 'error', () => {
                this.error = true;
                this.render();
                this.parentView.trigger('toggle:tab:loader');
                this.isLoading = false;
            });
        },

        load: function () {
            this.parentView.trigger('toggle:tab:loader');
            this.model.fetch();
        },

        render: function () {
            this.$el.html(this.template({
                statistic: this.model.attributes,
                race: this.raceModel.attributes,
                runnerIdSubject: this.data.idSubject,
                error: this.error
            }));
            this.trigger('statistic:render');
            return this;
        }
    })
);

export default RunnerStatisticView;
