'use strict';

/**
 * Backbone.Singleton mixin
 * Applicable to uninstantinated object
 *
 * @description
 *      Allows to ensure that a Backbone model or a collections is instantiated only once (Singleton)
 *      E.g. _.extend( SomeModel, BackboneSingleton );
 *
 * @type {Object}
 */

export default {
    /**
     * @method getInstance
     *
     * @description
     *     Return existing instance or create a new one
     */
    getInstance: function (model, opt) {
        if (this._instance === undefined) {
            this._instance = new this(model, opt);
        }
        return this._instance;
    }
};
