'use strict';

import _                        from 'underscore';
import Backbone                 from 'backbone';
import BackboneSingleton        from 'components/core/mixins/Backbone.Singleton';
import * as OverlayMethods      from 'components/core/app-overlay.methods';
import app                      from 'components/core/application';

/**
 * Overlay
 *
 * @module OverlaySecondary
 * @singleton
 *
 * @description
 *  Manage ui components (e.g. dialog. modal, etc).
 *  Primary goal is to properly clean up each and every ui component to avoid memory leaks
 *
 * @requires Backbone
 * @requires app
 * @requires BackboneSingleton
 * @requires Dialog
 *
 * @description
 *     Singleton overlay entity
 *
 * @returns {Function} - Overlay
 */

var AppOverlaySecondary = Backbone.View.extend(
    _.extend({}, OverlayMethods, {
        
        el: '#overlay-backdrop-secondary',

        events: OverlayMethods.viewEvents,

        initialize: function() {
            app.router.on('before:route:change', this.onRouteChange, this);
            app.on('dialog:close', this.closeDialog, this);
            app.on('dialog:secondary:close', this.closeDialog, this);
            app.on('app:scroll', (offset) => {
                this.$el.css({'top': offset});
            });
            app.on('app:height:change', (height) => {
                this.$el.css({'height': height});
            });
        },
    })
);

AppOverlaySecondary.getInstance = BackboneSingleton.getInstance;

export default AppOverlaySecondary;

