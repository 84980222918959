'use strict';
import $                            from 'jquery';
import _                            from 'underscore';
import Backbone                     from 'backbone';
import app                          from 'components/core/application';
import BackboneCloseView            from 'components/core/mixins/Backbone.CloseView';
import template                     from 'modules/race/race-item-multiple.tpl.hbs';
import RaceItemTooltip              from 'modules/race/race-item-tooltip';
import helpers                      from 'components/helpers/helpers';

/**
 * RaceItemCheckBox view
 *
 * @name RaceItemCheckboxView
 * @constructor
 *
 * @requires jQuery
 * @requires Underscore
 * @requires BackboneCloseView
 * @requires template
 *
 * @description
 * 		RaceItemCheckBox view.
 *
 * @returns {Function} - Constructor function for RaceItemCheckBox
 */

var RaceItemView = Backbone.View.extend(
    _.extend({}, BackboneCloseView, {
        tagName: 'li',

        className: 'c-dataList__row m-race__listItem',

        template: template,

        events: {
            'click [data-col]': 'checkboxClicked',
            'stable:checked [data-col]': 'checkboxClicked',
            'click [data-expand]': 'toggleTooltip',
            'click [data-tooltip]': 'toggleTooltip'
        },

        initialize: function (opt) {
            this.DOM = {};
            this.data = opt.data;
            this.model = opt.model ? opt.model : undefined;
            this.th = opt.th ? opt.th : undefined;
            this.options = opt.options;
            this.betType = this.options.betType;

            //reset checkbox selections
            if (!this.options.raceUpdate) {
                this.model.attributes.checkbox_data = {};
            }

            // add collection data to the model attributes
            _.extend(this.model.attributes, opt.data);
            this.model.setupBettingData(this.betType);

            this.buttonViews = {};

            // tooltip view
            this.model.set({idRace: this.data.race.idRace}, {silent: true});
            this.tooltipView = new RaceItemTooltip({model: this.model});
            this.tooltipView.open = false;

            this.listenTo(this.tooltipView, 'tab:change', this.adjustHeight);

            this.listenTo(app.betslip, 'combo:clicked', this.processCombo);

            this.listenTo(app, 'bet:placed:clean:selections', this.cleanSelections);

            // for some bet types don't allow to click multiple checkboxes for one runner
            if (helpers.BET_TYPES_WITH_HIDDEN_ROWS.includes(this.options.betType)) {
                this.on('betting:siblings:checkbox:clicked', (data) => {
                    const programnumber = this.$('[data-programnumber]').first().data().programnumber;
                    const thisRow = this.$('[data-programnumber='+ programnumber +']');
                    const inThisRowChecked = thisRow.is(':checked');

                    if(programnumber !== data.programnumber) {
                        if (data.isChecked) {
                            if(data.col !== 'C') {
                                thisRow.filter('[data-col="'+ data.col +'"]').prop('checked', false);
                                thisRow.filter('[data-col="'+ data.col +'"]').prop('disabled', true);
                            }
                        } else {
                            thisRow.filter((index, item) => {
                                const itemData = $(item).data();
                                return !inThisRowChecked && !data.colsChecked.includes(itemData.col) && !itemData.hasOwnProperty('scratched');

                            }).prop('disabled', false);
                        }
                    } else {
                        if (!data.isChecked) {
                            thisRow.filter((index, item) => {
                                const itemData = $(item).data();
                                if ((itemData.col === 'C')) {
                                    return true;
                                }
                                return !data.colsChecked.includes(itemData.col);
                            }).prop('disabled', false);
                        }
                    }
                }, this);
            }
        },

        processCombo: function() {
            if(!this.DOM.col_c) this.DOM.col_c = this.$('[data-col="C"]');
            this.DOM.col_c.click();
        },

        render: function () {
            this.$el.html(this.template({
                model: this.model.toJSON(),
                th: this.th
            }));

            //if the is the minimal version, without the runner information add an extra css class
            if (this.th.data.minimal) {
                this.$el.addClass('isMinimal')
            }

            if (this.model.attributes.event.isAntePost) {
                this.$el.addClass('isAntePost');
            }

            this.DOM.tooltip = this.$('[data-tooltip]');
            this.DOM.tooltip.html(this.tooltipView.render().$el);
            this.tooltipView.delegateEvents();

            return this;
        },

        cleanSelections: function () {
            this.$('input[type="checkbox"]').prop('checked', false);
            this.model.attributes.checkbox_data = null;

            //reset all selected runners
            _.each(this.data.race_ids, function(race) {
                race.count = 0;
                race.runners = [];
            });
        },

        checkboxClicked: function(e) {
            var $target = $(e.currentTarget);
            let data = $target.data();
            const siblingClick = {
                programnumber: data.programnumber,
                isChecked: $target.is(':checked'),
                col: data.col
            };

            data.isChecked = $target.is(':checked');
            this.checkSiblings($target);
            if (e.type === 'stable:checked') {
                data.stableChecked = true;
            } else {
                data.stableChecked = false;
            }

            this.model.attributes.checkbox_data = data;

            app.trigger('betting:checkbox:clicked', {data: data, model: this.model, race_ids: this.data.race_ids, race: this.data.race, siblingClick: siblingClick});
        },

        checkSiblings($checkbox) {
            let data = $checkbox.data();
            data.isChecked = $checkbox.is(':checked');
            let property = 'itemCheckbox' + data.programnumber;

            if(!this.DOM[property]) {
                this.DOM[property] = this.$('[data-programnumber='+ data.programnumber +']');
            }
            if (helpers.BET_TYPES_WITH_HIDDEN_ROWS.includes(this.options.betType)) {
                if(data.isChecked) {
                    this.DOM[property].not('[data-col="'+ data.col +'"]').prop('checked', false);
                    this.DOM[property].not('[data-col="'+ data.col +'"]').prop('disabled', true);
                }
            } else {
                if(data.isChecked) {
                    if(data.col === 'C') {
                        this.DOM[property].not('[data-col="C"]').prop('checked', false);
                    } else {
                        this.DOM[property].filter('[data-col="C"]').prop('checked', false);
                    }
                }
            }


        },

        toggleTooltip: function () {
            // stores original height
            if (_.isUndefined(this.originalHeight)) {
                this.originalHeight = this.$el.height();
            }

            if (this.tooltipView.open) {
                this.tooltipView.trigger('hidden');
                this.$el.height(this.originalHeight).removeClass('isTooltipOpen');
                this.tooltipView.open = false;
            } else {
                this.tooltipView.trigger('visible');
                this.$el.height(this.originalHeight + this.$el.find('[data-tooltip]').outerHeight(true)).addClass('isTooltipOpen');
                this.tooltipView.open = true;
            }
        },

        adjustHeight: function () {
            this.$el.height(this.originalHeight + this.$el.find('[data-tooltip]').outerHeight(true));
        }
    })
);

export default RaceItemView;
