'use strict';
import _                    from 'underscore';
import $                    from 'jquery';
import Backbone             from 'backbone';
import app                  from 'components/core/application';
import user                 from 'components/user/user';
import auth                 from 'components/auth/auth';
import docCookies           from 'components/helpers/docCookies';
import webSocketManager     from 'components/helpers/webSocketManager';

var UserPushService = {

    channels: {
        defaults: 'user_private_',
        balance: 'user_private_'
    },

    host: '//' + location.host,

    webSocketsHost: window.location.hostname,
    webSocketsPort: '20228',

    balanceChannelConnected: false,

    init: function () {
        if(user.data.webSocketsPort) {
            this.webSocketsPort = user.data.webSocketsPort;
        }

        if(user.data.webSocketsHost) {
            this.webSocketsHost = user.data.webSocketsHost;
        }

        return this;
    },

    /**
     * @method joinBalanceChannel
     *
     * @description
     *  Notifies node.js server about user login action and connects to user private channel
     *
     */
    joinBalanceChannel: function() {
        let sockie = $.parseJSON(docCookies.getItem('sockie'));

        if(sockie && sockie.userId) {
            this.channels.balance = this.channels.defaults + sockie.userId;
        }

        if (!this.updateChannelListener) {
            webSocketManager.on(`update:channel:${this.channels.balance}`, this.onUpdate, this);
            this.updateChannelListener = true;
        }

        //listen to 'node:loggedIn' and joint ot user private channel
        if (!this.nodeLoggedInListener) {
            webSocketManager.on('node:loggedIn', () => {
                webSocketManager
                    .joinChannel({
                        channel: this.channels.balance
                    });
            });
            this.nodeLoggedInListener = true;
        }

        //notify node.js about user login action
        webSocketManager
            .connect({
                url: this.webSocketsHost,
                port: this.webSocketsPort
            })
            .notifyNodeServerAboutLogin(sockie);
    },

    /**
     * @method onUpdate
     *
     * @description
     *  Callback function to update user profile info
     *
     @param response
     */
    onUpdate: function(response = {}) {
        if(response.data && response.data.message) {
            let data = response.data.message;

            if(data === 'logout') {
                typeof auth.logOut === 'function' && auth.logOut();
            } else {
                this.trigger('user:data:update', data);

                //update user.data.pendingBonuses
                if(data.bonus) {
                    user.data.pendingBonuses = parseInt(data.bonus.pending, 10)
                }

                //update user.data.freebets
                user.data.freebets = (data.freebets && _.isArray(data.freebets)) ? data.freebets.length : 0;

                //todo: check if used somewhere and remove
                app.trigger('user:data:update', response.data.message);
            }
        }
    }
};

/**
 * Inherit backbone events so that the application can serve as a mediator - Event(Message) Bus
 */
_.extend(UserPushService, Backbone.Events);

export default UserPushService;
