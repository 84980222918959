var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
    return "            <a class=\"c-btn c-btn--block c-btn--action mt1\" data-dialog-deposit role=\"button\">"
    + this.escapeExpression(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"h_deposit_now",{"name":"translate","hash":{},"data":data}))
    + "</a>\n";
},"3":function(depth0,helpers,partials,data) {
    var alias1=this.escapeExpression;

  return "            <a class=\"c-btn c-btn--block c-btn--"
    + alias1(this.lambda((depth0 != null ? depth0.primaryBtnClass : depth0), depth0))
    + "\" data-dialog-resubmit role=\"button\">"
    + alias1(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_ok",{"name":"translate","hash":{},"data":data}))
    + "</a>\n";
},"5":function(depth0,helpers,partials,data) {
    return "            <a class=\"c-btn c-btn--block c-btn--default mt1\" data-dialog-cancel role=\"button\">"
    + this.escapeExpression(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_cancel",{"name":"translate","hash":{},"data":data}))
    + "</a>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"pb0\">\n    <div class=\"c-dialog__message text-center\">\n        <div class=\"mb1\"><div class=\"rb-icon rb-icon--error c-dialog__header-icon\"></div></div>\n        "
    + ((stack1 = this.lambda((depth0 != null ? depth0.message : depth0), depth0)) != null ? stack1 : "")
    + "\n    </div>\n\n    <div class=\"c-dialog__body\">\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.deposit : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.cancelBtn : depth0),{"name":"if","hash":{},"fn":this.program(5, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"useData":true});