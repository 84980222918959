import _ from 'underscore';
import { ENV_DEBUG_MODE } from 'components/app.settings';

var _messenger = {};
var eventOrigin = '*';
var targetOrigin = '*';
var validOrigin = false;
var innerIframe = null;


const crossFrame = {

    init: function (filterList) {
        window.addEventListener('message', (event) => {
            eventOrigin = event.origin || event.originalEvent.origin;

            if (ENV_DEBUG_MODE) {
                console.groupCollapsed(`postMessage RECEIVED @ ${window.location.host}`);
                console.log(`%c origin`, 'color: #9E9E9E; font-weight: bold', eventOrigin);
                console.log(`%c data`, 'color: #03A9F4; font-weight: bold', event.data);
                console.groupEnd();
            }

            if (validOrigin || ENV_DEBUG_MODE) {
                this.publish(event);
                return;
            }

            if (_.some(filterList, (item) => eventOrigin.includes(item))) {
                validOrigin = true;
                this.publish(event);
            }
        }, false);
    },

    addIframe: function(iframe){
        innerIframe = iframe;
    },

    removeIframe: function() {
        innerIframe = null;
    },

    addMessageListener: function (messageId, cb) {
        if (!_messenger[messageId]) {
            _messenger[messageId] = [];
        }

        if (!_.find(_messenger[messageId], cb)) {
            _messenger[messageId].push(cb);
        }
    },

    removeMessageListener: function (messageId, cb) {
        if (_messenger[messageId]) {
            var index = _.indexOf(_messenger[messageId], cb);

            if (index >= 0) {
                _messenger[messageId].splice(index, 1);

                if (!_messenger[messageId].length) {
                    delete _messenger[messageId];
                }
            }
        }
    },

    removeAll: function (messageId) {
        if (_messenger[messageId]) {
            delete _messenger[messageId];
        } else {
            _messenger = {};
        }
    },

    publish: function (event) {
        let ref = _messenger[event.data.messageId];

        if (ref) {
            _.map(ref, function (cb) {
                cb(event.data);
            });
        }
    },

    send: function (messageId, payload) {
        let messageObject = {};

        if (!messageId) {
            throw new Error('Message ID has to be defined');
        } else {
            messageObject.messageId = messageId;
        }

        if(!_.isUndefined(payload)) {
            messageObject.payload = payload;
        }
        if (innerIframe) {
            innerIframe[0].contentWindow.postMessage(messageObject, targetOrigin);
        } else if (window.self !== window.top) {
            window.parent.postMessage(messageObject, targetOrigin);
        }
    }
};

export default crossFrame;
