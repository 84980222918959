'use strict';

import _                            from 'underscore';
import $                            from 'jquery';
import Backbone                     from 'backbone';
import template                     from 'modules/settings/settings.tpl.hbs';
import user                         from 'components/user/user';
import app                          from 'components/core/application';
import cookies                      from 'components/helpers/docCookies';
import helpers                      from 'components/helpers/helpers';
import BackboneCloseView            from 'components/core/mixins/Backbone.CloseView';

/**
 * User settings view
 *
 * @name UserSettings
 * @constructor
 *
 * @requires underscore
 * @requires Backbone
 * @requires template
 * @requires user
 * @requires app
 * @requires BackboneCloseView;
 *
 * @description
 *     User settings view Controller.
 *
 * @returns {Function} - Constructor function for UserSettings
 */

const settingsView = Backbone.View.extend(
    _.extend({}, BackboneCloseView, {

        className: 'c-itemList',

        template: template,

        events: {
            'click [data-href]': 'goToPage'
        },

        initialize: function () {
            let tld = user.getTLD();
            let rev_tld = (tld === 'com' ? 'de' : 'com?redirectSource=de');
            this.urls = {
                desktop: {
                    prod: `https://${location.hostname.replace('m.', 'www.')}?nomobile`,
                    test:  `//${location.hostname.replace('m', 'www')}?nomobile`
                }
            };

            this.odds_format = {
                base1: '2.50',
                base10: '25',
                fractional: '6/4'
            };

            this.menuItems = [
                {
                    title: app.polyglot.t('label_odds_format'),
                    value: this.odds_format[user.data.oddsFormat],
                    url: '/settings/odds_format',
                    dataAttr: ''
                },
                {
                    title: app.polyglot.t('label_unit_system'),
                    value: app.polyglot.t('label_' + user.getMetricSystem()),
                    url: '/settings/unit_system',
                    dataAttr: ''
                },
                {
                    title: app.interpolate('label_go_to_racebets', {redirectTarget: (tld === 'com' ? 'de' : 'com')}),
                    value: '',
                    url: `https://${helpers.getHostname(rev_tld)}`,
                    dataAttr: 'data-external data-action=tld'
                },
                {
                    title: app.polyglot.t('label_menu_desktop_version'),
                    value: '',
                    url: this.getAppUrl('desktop'),
                    dataAttr: 'data-external'
                }
            ];

            // remove button 'Go to racebets com / de for other brands then racebets
            if (app.brand !== 'racebets') {
                this.menuItems.splice(2,1);
            }
        },

        render: function() {
            this.$el.html(this.template({menuItems: this.menuItems}));
            return this;
        },

        getAppUrl: function(type) {
            let env = (user.data && user.data.isTest) ? 'test' : 'prod';
            return this.urls[type][env];
        },

        goToPage: function (e) {
            var $el = $(e.currentTarget);
            var url = $el.attr('data-href');

            //check if the clicked link has any action related
            if (e.currentTarget.hasAttribute('data-action')) {
                let action = $el.attr('data-action');
                if(action === 'tld') {
                    if(cookies.hasItem('redirectSource')) {
                        cookies.removeItem('redirectSource', '/', helpers.getCookieDomain());
                    } else if(cookies.hasItem('rbTldAccepted')) {
                        cookies.removeItem('rbTldAccepted', '/', helpers.getCookieDomain());
                    }
                }
            }

            // if is an external link, follow it
            if (e.currentTarget.hasAttribute('data-external')) {
                location.href = url;
            } else if (url) {
                app.router.navigate(url, {trigger: true});
            }
        }
    })
);
export default settingsView;
