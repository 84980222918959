import $                            from 'jquery';
import Backbone                     from 'backbone';
import _                            from 'underscore';
import template                     from 'components/media/audio-preview.tpl.hbs';
import BackboneCloseView            from 'components/core/mixins/Backbone.CloseView';
import user                         from 'components/user/user';
import auth                         from 'components/auth/auth';
// import progressTracking             from 'components/helpers/audio-preview';

var MediaView = Backbone.View.extend(
    _.extend({}, BackboneCloseView, {

        template: template,

        initialize: function (options) {
            this.data = {};
            this.videojs = null;
            _.extend(this.data, options);
            this.el = this.data.el;
            this.data.lang = user.data.language;
            this.data.isLoggedIn = user.isLoggedIn;
            this.data.audioPreviewElemID = 'audio-preview-player';

            // load video.js files
            const videoJs = import(
                /* webpackChunkName: "videojs" */
                'video.js'
            );
            const videoJsStyles = import(
                /* webpackChunkName: "videojs" */
                'video.js/dist/video-js.css'
            );
            const videoJsEventTracking = import(
                /* webpackChunkName: "videojs" */
                'videojs-event-tracking/dist/videojs-event-tracking.min.js'
            );

            Promise.all([videoJs, videoJsStyles, videoJsEventTracking]).then(
                (imports)=> {
                    this.videojs = imports[0].default;
                    this.videojs.registerPlugin('eventTracking',imports[2].default);
                    this.render();
                }
            )
        },

        render: function () {
            if (this.videojs.getPlayer(this.data.audioPreviewElemID)) {
                this.videojs(this.data.audioPreviewElemID).dispose();
                this.$el.empty();
            }

            this.$el.html(this.template(this.data));

            // video.js
            this.videojs(this.data.audioPreviewElemID, {
                controlBar: {
                    pictureInPictureToggle: false,
                    fullscreenToggle: false,
                    autoplay: false
                },
                preload: user.isLoggedIn ? 'auto' : 'none',
                plugins: { eventTracking: true }
            }, function() {
                this.bigPlayButton.off(['click', 'touchstart']);
                this.bigPlayButton.on('touchstart', function(event){
                    auth.manageLogin();
                });
            });

            // Listen to player events
            this.videojs(this.data.audioPreviewElemID).on('play', function() {
                dataLayer.push({
                    'event': 'audio',
                    'Action': 'Play'
                });
            });

            this.videojs(this.data.audioPreviewElemID).on('pause', function() {
                dataLayer.push({
                    'event': 'audio',
                    'Action': 'Pause'
                });
            });

            this.videojs(this.data.audioPreviewElemID).on('ended', function() {
                dataLayer.push({
                    'event': 'audio',
                    'Action': 'Ended'
                });
            });

            this.videojs(this.data.audioPreviewElemID).on('tracking:seek', function(e, data) {
                dataLayer.push({
                    'event': 'audio',
                    'Action': 'Seek'
                });
            })

            this.videojs(this.data.audioPreviewElemID).on('tracking:first-quarter', function(e, data) {
                dataLayer.push({
                    'event': 'audio',
                    'Action': 'Progress 25%'
                });
            })

            this.videojs(this.data.audioPreviewElemID).on('tracking:second-quarter', function(e, data) {
                dataLayer.push({
                    'event': 'audio',
                    'Action': 'Progress 50%'
                });
            })

            this.videojs(this.data.audioPreviewElemID).on('tracking:third-quarter', function(e, data) {
                dataLayer.push({
                    'event': 'audio',
                    'Action': 'Progress 75%'
                });
            })

            this.videojs(this.data.audioPreviewElemID).on('tracking:fourth-quarter', function(e, data) {
                dataLayer.push({
                    'event': 'audio',
                    'Action': 'Progress 100%'
                });
            })
        },
        close: function () {
            if (this.videojs.getPlayer(this.data.audioPreviewElemID)) {
                this.videojs(this.data.audioPreviewElemID).dispose();
            }
        }
    })
);

export default MediaView;
