var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "    <div class=\"c-grid "
    + ((stack1 = __default(require("../../components/helpers/handlebar-helpers/compare.js")).call(depth0,((stack1 = (depths[1] != null ? depths[1].data : depths[1])) != null ? stack1.length : stack1),">",1,{"name":"compare","hash":{},"fn":this.program(2, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = helpers.each.call(depth0,depth0,{"name":"each","hash":{},"fn":this.program(4, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n";
},"2":function(depth0,helpers,partials,data) {
    return "m-h2h--h3h";
},"4":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return ((stack1 = __default(require("../../components/helpers/handlebar-helpers/compare.js")).call(depth0,(depths[1] != null ? depths[1].length : depths[1]),"===",1,{"name":"compare","hash":{},"fn":this.program(5, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            \n            <div class=\"c-grid__col is-1-2 text-center p-rel\">\n                <span class=\"c-silk c-silk--large inline-block mt2\" "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.silkUrl : depth0),{"name":"if","hash":{},"fn":this.program(7, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "></span>\n                <div class=\"truncate-text\"><div class=\"m-h2h__runner\">"
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + "</div></div>\n                <div class=\"m-h2h__details\">"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.ageFormatted : depth0),{"name":"if","hash":{},"fn":this.program(9, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " "
    + alias2(alias1((depth0 != null ? depth0.genderFormatted : depth0), depth0))
    + " "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.ppString : depth0),{"name":"if","hash":{},"fn":this.program(11, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n                <div class=\"m-h2h__details\">\n"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.jockey : depth0)) != null ? stack1.firstNameAbbr : stack1),{"name":"if","hash":{},"fn":this.program(13, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.trainer : depth0)) != null ? stack1.firstNameAbbr : stack1),{"name":"if","hash":{},"fn":this.program(15, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                </div>\n                <div class=\"m-h2h__details\">\n"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.jockey : depth0)) != null ? stack1.weight : stack1),{"name":"if","hash":{},"fn":this.program(17, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.postPosition : depth0),{"name":"if","hash":{},"fn":this.program(19, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                </div>\n                <div class=\"m-h2h__btn\" data-id=\""
    + alias2(alias1((depth0 != null ? depth0.betButtonId : depth0), depth0))
    + "\">\n                </div>\n"
    + ((stack1 = __default(require("../../components/helpers/handlebar-helpers/compare.js")).call(depth0,(data && data.index),"===",0,{"name":"compare","hash":{},"fn":this.program(21, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            </div>\n\n"
    + ((stack1 = __default(require("../../components/helpers/handlebar-helpers/compare.js")).call(depth0,(depths[1] != null ? depths[1].length : depths[1]),"===",1,{"name":"compare","hash":{},"fn":this.program(5, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"5":function(depth0,helpers,partials,data) {
    return "                <div class=\"c-grid__col is-1-4 text-center\"></div>\n";
},"7":function(depth0,helpers,partials,data) {
    return "style=\"background-image:url('"
    + this.escapeExpression(this.lambda((depth0 != null ? depth0.silkUrl : depth0), depth0))
    + "')\"";
},"9":function(depth0,helpers,partials,data) {
    return this.escapeExpression(this.lambda((depth0 != null ? depth0.ageFormatted : depth0), depth0));
},"11":function(depth0,helpers,partials,data) {
    return "("
    + this.escapeExpression(this.lambda((depth0 != null ? depth0.ppString : depth0), depth0))
    + ")";
},"13":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.escapeExpression, alias2=this.lambda;

  return "                        "
    + alias1(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_abbr_jockey",{"name":"translate","hash":{},"data":data}))
    + ": "
    + alias1(alias2(((stack1 = (depth0 != null ? depth0.jockey : depth0)) != null ? stack1.firstNameAbbr : stack1), depth0))
    + " "
    + alias1(alias2(((stack1 = (depth0 != null ? depth0.jockey : depth0)) != null ? stack1.lastName : stack1), depth0))
    + "\n";
},"15":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.escapeExpression, alias2=this.lambda;

  return "                        | "
    + alias1(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_abbr_trainer",{"name":"translate","hash":{},"data":data}))
    + ": "
    + alias1(alias2(((stack1 = (depth0 != null ? depth0.trainer : depth0)) != null ? stack1.firstNameAbbr : stack1), depth0))
    + " "
    + alias1(alias2(((stack1 = (depth0 != null ? depth0.trainer : depth0)) != null ? stack1.lastName : stack1), depth0))
    + "\n";
},"17":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.escapeExpression;

  return "                        "
    + alias1(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_abbr_weight",{"name":"translate","hash":{},"data":data}))
    + ": "
    + alias1(this.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.jockey : depth0)) != null ? stack1.weight : stack1)) != null ? stack1.weightFormatted : stack1), depth0))
    + "\n";
},"19":function(depth0,helpers,partials,data) {
    var alias1=this.escapeExpression;

  return "                        "
    + alias1(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_abbr_box",{"name":"translate","hash":{},"data":data}))
    + ": "
    + alias1(this.lambda((depth0 != null ? depth0.postPosition : depth0), depth0))
    + "\n";
},"21":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = __default(require("../../components/helpers/handlebar-helpers/compare.js")).call(depth0,(depths[2] != null ? depths[2].length : depths[2]),"===",2,{"name":"compare","hash":{},"fn":this.program(22, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"22":function(depth0,helpers,partials,data) {
    return "                        <div class=\"m-h2h__vs\"><span>vs</span></div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.data : depth0),{"name":"each","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"useData":true,"useDepths":true});