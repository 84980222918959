'use strict';

import _                from 'underscore';
import Backbone         from 'backbone';
import template         from 'components/betting/bet_placed.tpl.hbs';
import app              from 'components/core/application';

/**
 * BetPlaced view Controller
 * @name BetPlacedView
 * @constructor
 *
 * @requires Backbone
 * @requires template
 *
 * @description
 *     BetPlaced View Controller
 *
 * @returns Function - Constructor function for BetPlaced view
 */
var BetPlacedView = Backbone.View.extend(
    _.extend({}, {

        template: template,

        events: {
            'click [data-dialog-closeBtn]': 'closeAll',
            'click [data-dialog-retain]': 'closeSuccess'
        },

        /**
         * @method initialize
         * @description
         *      Initialize view with options/data
         *
         * @param data
         */
        initialize: function(data) {
            this.data = {};
            if(!data || !data.publicId) {
                throw new Error('publicId is required');
            }
            this.data = data;
        },

        /**
         * @method render
         * @description
         *      Render view template and populate with data (if passed)
         *
         * @returns {BetPlacedView}
         */
        render: function() {
            this.$el.html(this.template(this.data));
            return this;
        },

        /**
         * @method closeBtnFn
         * @description
         *      Callback function for a click event handler
         *
         * @param e
         */
        closeAll: function() {
            if (!app.betslip.isOpen()) {
                app.trigger('dialog:close');
            }
            app.trigger('dialog:secondary:close');
            app.trigger('bet:placed:clean:selections');

            this.trigger('successDialog:close', {cleanBetslip: true});
        },

        closeSuccess: function () {
            app.trigger('dialog:secondary:close');
            this.trigger('successDialog:close', {cleanUnits: true});
        },

        /**
         * @method close
         * @description
         *      Calls remove and undelegateEvents methods on a view to clean up html and events;
         */
        close: function() {
            this.remove();
            this.undelegateEvents();
        }
    })
);

export default BetPlacedView;
