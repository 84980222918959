var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
    return "        <li data-tab class=\"c-tabs__item\">\n            <a class=\"c-tabs__link\" href=\"/home/virtuals\">\n                "
    + this.escapeExpression(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_virtuals",{"name":"translate","hash":{},"data":data}))
    + "\n            </a>\n        </li>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.escapeExpression;

  return "<div data-filter class=\"c-filter c-filter--floating\"></div>\n\n<ul class=\"c-tabs\">\n    <li data-tab class=\"c-tabs__item\">\n        <a class=\"c-tabs__link\" href=\"/home/current\">"
    + alias1(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_current",{"name":"translate","hash":{},"data":data}))
    + "</a>\n    </li>\n    <li data-tab class=\"c-tabs__item\">\n        <a class=\"c-tabs__link\" href=\"/home/antepost\">"
    + alias1(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_ante_post",{"name":"translate","hash":{},"data":data}))
    + "</a>\n    </li>\n"
    + ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.hideVirtuals : depth0),{"name":"unless","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "    <li data-tab class=\"c-tabs__item\">\n        <a class=\"c-tabs__link\" href=\"/home/calendar\">"
    + alias1(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_calendar",{"name":"translate","hash":{},"data":data}))
    + "</a>\n    </li>\n    <li class=\"c-tabs__item c-tabs__item--placeholder\">&nbsp;</li>\n</ul>\n\n<div id=\"app-subview\" class=\"app__subView\"></div>\n";
},"useData":true});