
import $                        from 'jquery';
import _                        from 'underscore';
import moment                   from 'moment-timezone';
import { configureScope }       from '@sentry/browser';
import Polyglot                 from 'node-polyglot';
import app                      from 'components/core/application';
import user                     from 'components/user/user';
import appSettings, {
    ENV_CLIENT,
    ENV_RB
}                               from 'components/app.settings';
import cookies                  from 'components/helpers/docCookies';
import maybe                    from 'components/helpers/maybe';
import helpers                  from 'components/helpers/helpers';
import i18n                     from 'components/helpers/i18n';
import serviceFactory           from 'components/helpers/serviceFactory';
import userSettingsManager      from 'components/user/user.settings-manager';

export function onMeLoaded(response) {
    if (user.data.loggedIn) {
        configureScope((scope) => {
            scope.setUser({ id: user.data.idAccount });
        });
    }

    if (ENV_CLIENT === 'iframe' && ENV_RB === 'production') {
        // set redirect debug cookie to prevent iframe url redirection to host app
        const getParams = helpers.parseQueryString(window.location.search);
        if (getParams.redirect === false) {
            cookies.setItem('rbredirect', false, moment().add(1, 'year').toDate());
        }

        // redirect iframe url to host app (unless redirect cookie is set)

        if (window.self === window.top && !cookies.getItem('rbredirect')) {
            userSettingsManager.readFromCookies()
            var lang = userSettingsManager.getGeneral().language || (app.brand === 'europebet' ? 'ka' : 'en');

            if(app.brand === 'europebet') {
                window.location.href = 'https://mobile.' + app.domain + '/' + lang + '/games/sportsbook/horse-racing'; //+ window.location.pathname;
            } else if (['betssonarbacity', 'betssonarbaprovince'].indexOf(app.brandChild) > -1) {
                window.location.href = 'https://' + app.domain + '/horse-racing' + window.location.pathname;
            } else {
                window.location.href = 'https://m.' + app.domain + '/' + lang + '/horse-racing' + window.location.pathname;
            }
            return;
        }
    }

    $.when(
        $.getJSON(helpers.getDictionaryURL())
    ).done(function (mobile) {
        app.polyglot = new Polyglot({phrases: mobile});

        //store countries
        //convert into an array of objects and store in service
        serviceFactory
            .create('country_list')
            .set({data: _.sortBy(_.map(maybe.of(mobile).mapProp('countries').orElse([]).join(), function(value, key) { return { key : key, title : value } }), 'title')});

        // patch interpolation delimiters in translations to support polyglot interpolation
        app.interpolate = function (key, options) {
            var value = app.polyglot.phrases[key];
            if (typeof value === "string") {
                var obj = {};
                obj[key] = value.replace(/{{\s*/g, '%{').replace(/\s*}}/g, '}');

                app.polyglot.extend(obj);
                return app.polyglot.t(key, options);
            }
            return key;
        };

        // load moment locale, returns a promise
        let country = user.data.country ? user.data.country : user.data.ipCountry;
        i18n.requireLanguage(user.data.language, country)
            .then(() => {
                i18n.setLanguage(user.data.language, country)
            });

        app.trigger('initial:data:loaded');
    });
}
