'use strict';

import _                from 'underscore';
import Backbone         from 'backbone';
import user             from 'components/user/user';
import helpers          from 'components/helpers/helpers';
import app              from 'components/core/application';
import FreeBetModel     from 'components/betting/free_bet.model';

var FreeBetsModel = Backbone.Collection.extend({

    url: '/ajax/freebets/freeBetsForRace?isMobile=1&idRace=:idRace',

    model: FreeBetModel,

    options: {},

    initialize: function(models, opts) {
        _.extend(this.options, opts);

        if(!this.options.idRace) throw new Error('idRace is required');

        //todo: check if programNumber is always returned in FixedBet request
        if(!this.options.betXprogramNumber) this.options.betXprogramNumber = '';

        this.url = this.url.replace(/:idRace/, this.options.idRace);
    },

    parse: function(response) {
        if(response.length > 0) {
            for(var i = 0, l = response.length; i < l; i++) {
                var name;
                var item = response[i];
                item.singleRunner = (item.programNumbers[0] === "0") ? false : true;
                item.runnerName = '';

                if (item.singleRunner) {
                    if (parseInt(item.programNumbers[0], 10) === parseInt(this.options.betXprogramNumber, 10)) {
                        item.runnerName = this.options.betXname;
                        item.idRunner = this.options.idRunner;
                    } else {
                        if (this.options.raceCollection) {
                            var runner = this.options.raceCollection.findWhere({'programNumber': parseInt(item.programNumbers[0], 10)});
                            if (runner) { // might break here, qa found a bug once, but can't reproduce it.
                                item.runnerName = runner.get('name');
                                item.idRunner = runner.get('idRunner');
                            }
                        }
                    }
                    name = (item.runnerName.length > 9) ? (item.runnerName.substring(0,9) + '...') : item.runnerName;
                    item.title = helpers.formatMoney(item.amount, 2, user.data.currency, true) + ' ' + name;
                } else {
                    item.title = helpers.formatMoney(item.amount, 2, user.data.currency, true) + ' ' + app.polyglot.t('label_select_all_runners');
                }
            }
        }

        response = _.filter(response, (item) => {
            const isAntePost = item.antepost === '1';
            // do not allow freebets for special events
            if (!this.options.raceCollection) return false;
            return this.options.raceCollection.data.event.isAntePost === isAntePost;
        });

        this.reset(response);

        return response;
    }
});

export default FreeBetsModel;
