var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
    var stack1;

  return "        <div class=\"c-runnerDetails__infoTabsHeader\">\n            <a role=\"button\" class=\"c-runnerDetails__infoTabLink "
    + ((stack1 = __default(require("../../components/helpers/handlebar-helpers/compare.js")).call(depth0,(depth0 != null ? depth0.tooltip_active_tab : depth0),"===","runner",{"name":"compare","hash":{},"fn":this.program(2, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\" data-tab-link=\"runner\">\n                "
    + ((stack1 = __default(require("../../components/helpers/handlebar-helpers/compare.js")).call(depth0,(depth0 != null ? depth0.raceType : depth0),"===","D",{"name":"compare","hash":{},"fn":this.program(4, data, 0),"inverse":this.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + "\n            </a>\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.hasPastPerformance : depth0),{"name":"if","hash":{},"fn":this.program(8, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            <a role=\"button\" class=\"c-runnerDetails__infoTabLink "
    + ((stack1 = __default(require("../../components/helpers/handlebar-helpers/compare.js")).call(depth0,(depth0 != null ? depth0.tooltip_active_tab : depth0),"===","statistic",{"name":"compare","hash":{},"fn":this.program(2, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\" data-tab-link=\"statistic\">"
    + this.escapeExpression(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_statistic",{"name":"translate","hash":{},"data":data}))
    + "</a>\n"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.jockey : depth0)) != null ? stack1.stats : stack1),{"name":"if","hash":{},"fn":this.program(10, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.trainer : depth0)) != null ? stack1.stats : stack1),{"name":"if","hash":{},"fn":this.program(12, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n        </div>\n";
},"2":function(depth0,helpers,partials,data) {
    return "isActive";
},"4":function(depth0,helpers,partials,data) {
    return this.escapeExpression(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_greyhound",{"name":"translate","hash":{},"data":data}));
},"6":function(depth0,helpers,partials,data) {
    return this.escapeExpression(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_horse",{"name":"translate","hash":{},"data":data}));
},"8":function(depth0,helpers,partials,data) {
    var stack1;

  return "                <a role=\"button\" class=\"c-runnerDetails__infoTabLink "
    + ((stack1 = __default(require("../../components/helpers/handlebar-helpers/compare.js")).call(depth0,(depth0 != null ? depth0.tooltip_active_tab : depth0),"===","form",{"name":"compare","hash":{},"fn":this.program(2, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\" data-tab-link=\"form\">"
    + this.escapeExpression(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_form",{"name":"translate","hash":{},"data":data}))
    + "</a>\n";
},"10":function(depth0,helpers,partials,data) {
    var stack1;

  return "                <a role=\"button\" class=\"c-runnerDetails__infoTabLink "
    + ((stack1 = __default(require("../../components/helpers/handlebar-helpers/compare.js")).call(depth0,(depth0 != null ? depth0.tooltip_active_tab : depth0),"===","jockey",{"name":"compare","hash":{},"fn":this.program(2, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\" data-tab-link=\"jockey\">"
    + this.escapeExpression(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_jockey",{"name":"translate","hash":{},"data":data}))
    + "</a>\n";
},"12":function(depth0,helpers,partials,data) {
    var stack1;

  return "<a role=\"button\" class=\"c-runnerDetails__infoTabLink "
    + ((stack1 = __default(require("../../components/helpers/handlebar-helpers/compare.js")).call(depth0,(depth0 != null ? depth0.tooltip_active_tab : depth0),"===","trainer",{"name":"compare","hash":{},"fn":this.program(2, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\" data-tab-link=\"trainer\">"
    + this.escapeExpression(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_trainer",{"name":"translate","hash":{},"data":data}))
    + "</a>";
},"14":function(depth0,helpers,partials,data) {
    return "        <div class=\"c-grid c-grid--noGutter\">\n        <div class=\"c-grid__col is-1-2 c-runnerDetails__horseDetailCol\">\n";
},"16":function(depth0,helpers,partials,data) {
    var alias1=this.escapeExpression;

  return "            <p class=\"c-runnerDetails__info\"><strong>"
    + alias1(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_owner",{"name":"translate","hash":{},"data":data}))
    + ":</strong> "
    + alias1(this.lambda((depth0 != null ? depth0.owner : depth0), depth0))
    + "</p>\n";
},"18":function(depth0,helpers,partials,data) {
    var alias1=this.escapeExpression;

  return "            <p class=\"c-runnerDetails__info\"><strong>"
    + alias1(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_breeder",{"name":"translate","hash":{},"data":data}))
    + ":</strong> "
    + alias1(this.lambda((depth0 != null ? depth0.breeder : depth0), depth0))
    + "</p>\n";
},"20":function(depth0,helpers,partials,data) {
    var alias1=this.escapeExpression;

  return "            <p class=\"c-runnerDetails__info\"><strong>"
    + alias1(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_sire",{"name":"translate","hash":{},"data":data}))
    + ":</strong> "
    + alias1(this.lambda((depth0 != null ? depth0.sire : depth0), depth0))
    + "</p>\n";
},"22":function(depth0,helpers,partials,data) {
    var alias1=this.escapeExpression;

  return "            <p class=\"c-runnerDetails__info\"><strong>"
    + alias1(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_dam",{"name":"translate","hash":{},"data":data}))
    + ":</strong> "
    + alias1(this.lambda((depth0 != null ? depth0.dam : depth0), depth0))
    + "</p>\n";
},"24":function(depth0,helpers,partials,data) {
    var alias1=this.escapeExpression;

  return "            <p class=\"c-runnerDetails__info\"><strong>"
    + alias1(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_dam_sire",{"name":"translate","hash":{},"data":data}))
    + ":</strong> "
    + alias1(this.lambda((depth0 != null ? depth0.damSire : depth0), depth0))
    + "</p>\n";
},"26":function(depth0,helpers,partials,data) {
    var alias1=this.escapeExpression;

  return "            <p class=\"c-runnerDetails__info\"><strong>"
    + alias1(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_purse",{"name":"translate","hash":{},"data":data}))
    + ":</strong> "
    + alias1(this.lambda((depth0 != null ? depth0.purse : depth0), depth0))
    + "</p>\n";
},"28":function(depth0,helpers,partials,data) {
    var alias1=this.escapeExpression;

  return "            <p class=\"c-runnerDetails__info\"><strong>"
    + alias1(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_rating",{"name":"translate","hash":{},"data":data}))
    + ":</strong> "
    + alias1(this.lambda((depth0 != null ? depth0.rating : depth0), depth0))
    + "</p>\n";
},"30":function(depth0,helpers,partials,data) {
    var alias1=this.escapeExpression;

  return "            <p class=\"c-runnerDetails__info\"><strong>"
    + alias1(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_homeland",{"name":"translate","hash":{},"data":data}))
    + ":</strong> "
    + alias1(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,__default(require("../../components/helpers/handlebar-helpers/concat.js")).call(depth0,"countries.",(depth0 != null ? depth0.isAbroad : depth0),{"name":"concat","hash":{},"data":data}),{"name":"translate","hash":{},"data":data}))
    + "</p>\n";
},"32":function(depth0,helpers,partials,data) {
    var stack1;

  return "        </div>\n            <div class=\"c-grid__col is-1-2 c-runnerDetails__horseDetailCol text-right\">\n                <img class=\"c-runnerDetails__runnerImg\" data-img=\"runner\" data-src=\""
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.photos : depth0)) != null ? stack1.runner : stack1), depth0))
    + "\" src=\"\">\n            </div>\n        </div>\n";
},"34":function(depth0,helpers,partials,data) {
    var stack1;

  return "            <hr class=\"c-runnerDetails__hr\">\n            <p class=\"c-runnerDetails__info text-justify\">"
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.featuredHorse : depth0)) != null ? stack1.comment : stack1), depth0))
    + "</p>\n";
},"36":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0,((stack1 = (depth0 != null ? depth0.featuredHorse : depth0)) != null ? stack1.comment : stack1),{"name":"unless","hash":{},"fn":this.program(37, data, 0),"inverse":this.program(39, data, 0),"data":data})) != null ? stack1 : "")
    + "            <p class=\"c-runnerDetails__info text-justify\">"
    + this.escapeExpression(this.lambda((depth0 != null ? depth0.pinSticker : depth0), depth0))
    + "</p>\n";
},"37":function(depth0,helpers,partials,data) {
    return "                <hr class=\"c-runnerDetails__hr\">\n";
},"39":function(depth0,helpers,partials,data) {
    return "                <br>\n";
},"41":function(depth0,helpers,partials,data) {
    var stack1;

  return "        <div class=\"c-runnerDetails__infoTab c-runnerDetails__infoTab--form "
    + ((stack1 = __default(require("../../components/helpers/handlebar-helpers/compare.js")).call(depth0,(depth0 != null ? depth0.tooltip_active_tab : depth0),"===","form",{"name":"compare","hash":{},"fn":this.program(2, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\" data-tab=\"form\"></div>\n";
},"43":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "        <div class=\"c-runnerDetails__infoTab "
    + ((stack1 = __default(require("../../components/helpers/handlebar-helpers/compare.js")).call(depth0,(depths[1] != null ? depths[1].tooltip_active_tab : depths[1]),"===","jockey",{"name":"compare","hash":{},"fn":this.program(2, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\" data-tab=\"jockey\">\n"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.photos : depth0)) != null ? stack1.jockey : stack1),{"name":"if","hash":{},"fn":this.program(44, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            <p class=\"c-runnerDetails__infoHeader\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.jockey : depth0)) != null ? stack1.stats : stack1)) != null ? stack1.label : stack1), depth0))
    + "<i class=\"c-flag c-runnerDetails__infoCountry isCountry"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.jockey : depth0)) != null ? stack1.stats : stack1)) != null ? stack1.country : stack1), depth0))
    + "\"></i></p>\n            <p class=\"c-runnerDetails__info\"><span class=\"c-runnerDetails__infoLabel\">"
    + alias2(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_starts",{"name":"translate","hash":{},"data":data}))
    + ":</span> "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.jockey : depth0)) != null ? stack1.stats : stack1)) != null ? stack1.numRaces : stack1), depth0))
    + "</p>\n            <p class=\"c-runnerDetails__info\"><span class=\"c-runnerDetails__infoLabel\">"
    + alias2(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_wins",{"name":"translate","hash":{},"data":data}))
    + ":</span> "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.jockey : depth0)) != null ? stack1.stats : stack1)) != null ? stack1.numWin : stack1), depth0))
    + " "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.jockey : depth0)) != null ? stack1.stats : stack1)) != null ? stack1.numWinPercentage : stack1),{"name":"if","hash":{},"fn":this.program(46, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</p>\n            <p class=\"c-runnerDetails__info\"><span class=\"c-runnerDetails__infoLabel\">"
    + alias2(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_places",{"name":"translate","hash":{},"data":data}))
    + ":</span> "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.jockey : depth0)) != null ? stack1.stats : stack1)) != null ? stack1.numPlace : stack1), depth0))
    + " "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.jockey : depth0)) != null ? stack1.stats : stack1)) != null ? stack1.numPlacePercentage : stack1),{"name":"if","hash":{},"fn":this.program(48, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</p>\n            <p class=\"c-runnerDetails__info\"><span class=\"c-runnerDetails__infoLabel\">"
    + alias2(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_winnings",{"name":"translate","hash":{},"data":data}))
    + ":</span> "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.jockey : depth0)) != null ? stack1.stats : stack1)) != null ? stack1.earnings : stack1), depth0))
    + "</p>\n"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.photos : depth0)) != null ? stack1.jockey : stack1),{"name":"if","hash":{},"fn":this.program(50, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n";
},"44":function(depth0,helpers,partials,data) {
    return "            <div class=\"c-grid c-grid--noGutter\">\n            <div class=\"c-grid__col is-2-3 c-runnerDetails__horseDetailCol\">\n";
},"46":function(depth0,helpers,partials,data) {
    var stack1;

  return "("
    + this.escapeExpression(this.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.jockey : depth0)) != null ? stack1.stats : stack1)) != null ? stack1.numWinPercentage : stack1), depth0))
    + ")";
},"48":function(depth0,helpers,partials,data) {
    var stack1;

  return "("
    + this.escapeExpression(this.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.jockey : depth0)) != null ? stack1.stats : stack1)) != null ? stack1.numPlacePercentage : stack1), depth0))
    + ")";
},"50":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "            </div>\n                <div class=\"c-grid__col is-1-3 c-runnerDetails__horseDetailCol text-right\">\n                    <img class=\"c-runnerDetails__runnerImg\" data-img=\"jockey\" data-src=\""
    + this.escapeExpression(this.lambda(((stack1 = (depths[1] != null ? depths[1].photos : depths[1])) != null ? stack1.jockey : stack1), depth0))
    + "\" src=\"\">\n                </div>\n            </div>\n";
},"52":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "        <div class=\"c-runnerDetails__infoTab "
    + ((stack1 = __default(require("../../components/helpers/handlebar-helpers/compare.js")).call(depth0,(depths[1] != null ? depths[1].tooltip_active_tab : depths[1]),"===","trainer",{"name":"compare","hash":{},"fn":this.program(2, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\" data-tab=\"trainer\">\n"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.photos : depth0)) != null ? stack1.trainer : stack1),{"name":"if","hash":{},"fn":this.program(44, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            <p class=\"c-runnerDetails__infoHeader\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.trainer : depth0)) != null ? stack1.stats : stack1)) != null ? stack1.label : stack1), depth0))
    + "<i class=\"c-flag c-runnerDetails__infoCountry isCountry"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.trainer : depth0)) != null ? stack1.stats : stack1)) != null ? stack1.country : stack1), depth0))
    + "\"></i></p>\n            <p class=\"c-runnerDetails__info\"><span class=\"c-runnerDetails__infoLabel\">"
    + alias2(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_starts",{"name":"translate","hash":{},"data":data}))
    + ":</span> "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.trainer : depth0)) != null ? stack1.stats : stack1)) != null ? stack1.numRaces : stack1), depth0))
    + "</p>\n            <p class=\"c-runnerDetails__info\"><span class=\"c-runnerDetails__infoLabel\">"
    + alias2(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_wins",{"name":"translate","hash":{},"data":data}))
    + ":</span> "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.trainer : depth0)) != null ? stack1.stats : stack1)) != null ? stack1.numWin : stack1), depth0))
    + " "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.trainer : depth0)) != null ? stack1.stats : stack1)) != null ? stack1.numWinPercentage : stack1),{"name":"if","hash":{},"fn":this.program(53, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</p>\n            <p class=\"c-runnerDetails__info\"><span class=\"c-runnerDetails__infoLabel\">"
    + alias2(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_places",{"name":"translate","hash":{},"data":data}))
    + ":</span> "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.trainer : depth0)) != null ? stack1.stats : stack1)) != null ? stack1.numPlace : stack1), depth0))
    + " "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.trainer : depth0)) != null ? stack1.stats : stack1)) != null ? stack1.numPlacePercentage : stack1),{"name":"if","hash":{},"fn":this.program(55, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</p>\n            <p class=\"c-runnerDetails__info\"><span class=\"c-runnerDetails__infoLabel\">"
    + alias2(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_winnings",{"name":"translate","hash":{},"data":data}))
    + ":</span> "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.trainer : depth0)) != null ? stack1.stats : stack1)) != null ? stack1.earnings : stack1), depth0))
    + "</p>\n"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.photos : depth0)) != null ? stack1.trainer : stack1),{"name":"if","hash":{},"fn":this.program(57, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n";
},"53":function(depth0,helpers,partials,data) {
    var stack1;

  return "("
    + this.escapeExpression(this.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.trainer : depth0)) != null ? stack1.stats : stack1)) != null ? stack1.numWinPercentage : stack1), depth0))
    + ")";
},"55":function(depth0,helpers,partials,data) {
    var stack1;

  return "("
    + this.escapeExpression(this.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.trainer : depth0)) != null ? stack1.stats : stack1)) != null ? stack1.numPlacePercentage : stack1), depth0))
    + ")";
},"57":function(depth0,helpers,partials,data) {
    var stack1;

  return "            </div>\n                <div class=\"c-grid__col is-1-3 c-runnerDetails__horseDetailCol text-right\">\n                    <img class=\"c-runnerDetails__runnerImg\" data-img=\"trainer\" data-src=\""
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.photos : depth0)) != null ? stack1.trainer : stack1), depth0))
    + "\" src=\"\">\n                </div>\n            </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "<div class=\"c-runnerDetails\" data-detail>\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.tabHeader : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "    <div class=\"c-runnerDetails__infoTab "
    + ((stack1 = __default(require("../../components/helpers/handlebar-helpers/compare.js")).call(depth0,(depth0 != null ? depth0.tooltip_active_tab : depth0),"===","runner",{"name":"compare","hash":{},"fn":this.program(2, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\" data-tab=\"runner\">\n"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.photos : depth0)) != null ? stack1.runner : stack1),{"name":"if","hash":{},"fn":this.program(14, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.owner : depth0),{"name":"if","hash":{},"fn":this.program(16, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.breeder : depth0),{"name":"if","hash":{},"fn":this.program(18, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.sire : depth0),{"name":"if","hash":{},"fn":this.program(20, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.dam : depth0),{"name":"if","hash":{},"fn":this.program(22, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.damSire : depth0),{"name":"if","hash":{},"fn":this.program(24, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.purse : depth0),{"name":"if","hash":{},"fn":this.program(26, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.rating : depth0),{"name":"if","hash":{},"fn":this.program(28, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.isAbroad : depth0),{"name":"if","hash":{},"fn":this.program(30, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.photos : depth0)) != null ? stack1.runner : stack1),{"name":"if","hash":{},"fn":this.program(32, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.featuredHorse : depth0)) != null ? stack1.comment : stack1),{"name":"if","hash":{},"fn":this.program(34, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.pinSticker : depth0),{"name":"if","hash":{},"fn":this.program(36, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.hasPastPerformance : depth0),{"name":"if","hash":{},"fn":this.program(41, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "    <div class=\"c-runnerDetails__infoTab c-runnerDetails__infoTab--statistic "
    + ((stack1 = __default(require("../../components/helpers/handlebar-helpers/compare.js")).call(depth0,(depth0 != null ? depth0.tooltip_active_tab : depth0),"===","statistic",{"name":"compare","hash":{},"fn":this.program(2, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\" data-tab=\"statistic\"></div>\n"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.jockey : depth0)) != null ? stack1.stats : stack1),{"name":"if","hash":{},"fn":this.program(43, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.trainer : depth0)) != null ? stack1.stats : stack1),{"name":"if","hash":{},"fn":this.program(52, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true,"useDepths":true});