'use strict';

import maybe from 'components/helpers/maybe';
import BaseModel from 'components/core/BaseModel';
import BackboneSingleton    from 'components/core//mixins/Backbone.Singleton';

/**
 * Use model
 * @module User
 *
 * @requires BaseModel
 * @requires BackboneSingleton
 *
 * @description
 *      @todo Add description
 *
 * @returns {Function} - Constructor function or existing exemplar for User
 */
var UserModel = BaseModel.extend({
    url: '/rest/v1/users/me',

    parse: function(response) {
        var prom = maybe.of(response).mapDotProp('data.promotion').orElse({}).join();
        prom.mailings = !!+prom.mailings;
        prom.newsletter = !!+prom.newsletter;
        prom.phone = !!+prom.phone;
        prom.sms = !!+prom.sms;

        return response;
    }

}, BackboneSingleton);

export default UserModel;
