'use strict';

export default {

    defaultTimeZone: 'Europe/Berlin',

    defaultCountryOrder: ['AE', 'FR', 'GB', 'IE', 'ZA', 'HK', 'DE', 'ES', 'IT', 'SE', 'NO', 'DK', 'FI', 'MT',
        'BR', 'AU', 'NZ', 'CZ', 'HU', 'SG', 'MO', 'MU', 'AT', 'CH', 'CL', 'ZW', 'TR', 'AR', 'JP', 'BE', 'SK', 'NL',
        'PR', 'IN', 'US', 'CA', 'MX', 'CO'
    ],

    oddsFormats: [{code: 'base1', value: '2.50'}, {code: 'base2', value: '25'}, {code: 'base3', value: '6/4'}],

    //todo: this values have to be changed on country selected. Not necessarily from logged in user settings
    oddsFormat: 'base1', // overwritten by country specific rules

    weightFormat: 'kg', // overwritten by country specific rules

    unitSystem: 'metric', // overwritten by country specific rules,

    streamFormat: 'high',

    popupStreams: false,

    hideDogs: false,

    closeCountries: true,

    betslip: {
        confirmation: false,
        preselection: true,
        stakes: {
            SEK: {
                "0": '5.00',
                "1": '10.00',
                "2": '15.00',
                "3": '20.00',
                "4": '30.00',
                "5": '50.00',
                "6": '100.00',
                "7": '200.00',
                "8": '300.00',
                "9": '500.00',
                "10": '1000.00',
                "11": '2000.00'
            },
            NOK: {
                "0": '5.00',
                "1": '10.00',
                "2": '15.00',
                "3": '20.00',
                "4": '30.00',
                "5": '50.00',
                "6": '100.00',
                "7": '200.00',
                "8": '300.00',
                "9": '500.00',
                "10": '1000.00',
                "11": '2000.00'
            },
            DKK: {
                "0": '5.00',
                "1": '10.00',
                "2": '15.00',
                "3": '20.00',
                "4": '30.00',
                "5": '50.00',
                "6": '100.00',
                "7": '200.00',
                "8": '300.00',
                "9": '500.00',
                "10": '1000.00',
                "11": '2000.00'
            },
            GBP: {
                "0": '0.50',
                "1": '1.00',
                "2": '1.50',
                "3": '2.00',
                "4": '3.00',
                "5": '5.00',
                "6": '10.00',
                "7": '20.00',
                "8": '30.00',
                "9": '50.00',
                "10": '100.00',
                "11": '200.00'
            },
            EUR: {
                "0": '0.50',
                "1": '1.00',
                "2": '1.50',
                "3": '2.00',
                "4": '3.00',
                "5": '5.00',
                "6": '10.00',
                "7": '20.00',
                "8": '30.00',
                "9": '50.00',
                "10": '100.00',
                "11": '200.00'
            },
            USD: {
                "0": '0.50',
                "1": '1.00',
                "2": '1.50',
                "3": '2.00',
                "4": '3.00',
                "5": '5.00',
                "6": '10.00',
                "7": '20.00',
                "8": '30.00',
                "9": '50.00',
                "10": '100.00',
                "11": '200.00'
            },
            PEN: {
                "0": '2.00',
                "1": '4.00',
                "2": '6.00',
                "3": '8.00',
                "4": '10.00',
                "5": '15.00',
                "6": '20.00',
                "7": '40.00',
                "8": '60.00',
                "9": '100.00',
                "10": '200.00',
                "11": '400.00'
            },
            GEL: {
                "0": '0.50',
                "1": '1.00',
                "2": '2.00',
                "3": '3.00',
                "4": '5.00',
                "5": '7.00',
                "6": '10.00',
                "7": '15.00',
                "8": '20.00',
                "9": '25.00',
                "10": '50.00',
                "11": '100.00'
            },
            CLP: {
                "0": '500',
                "1": '1000',
                "2": '2000',
                "3": '3000',
                "4": '5000',
                "5": '7500',
                "6": '10000',
                "7": '20000',
                "8": '30000',
                "9": '50000',
                "10": '75000',
                "11": '100000'
            },
            ARS: {
                "0": '100.00',
                "1": '150.00',
                "2": '200.00',
                "3": '300.00',
                "4": '500.00',
                "5": '1000.00',
                "6": '2000.00',
                "7": '3000.00',
                "8": '5000.00',
                "9": '7500.00',
                "10": '10000.00',
                "11": '20000.00'
            },
            MXN: {
                "0": '10.00',
                "1": '20.00',
                "2": '30.00',
                "3": '40.00',
                "4": '60.00',
                "5": '100.00',
                "6": '200.00',
                "7": '300.00',
                "8": '500.00',
                "9": '1000.00',
                "10": '2000.00',
                "11": '4000.00'
            },
            COP: {
                "0": '2500.00',
                "1": '5000.00',
                "2": '7500.00',
                "3": '10000.00',
                "4": '15000.00',
                "5": '25000.00',
                "6": '50000.00',
                "7": '75000.00',
                "8": '125000.00',
                "9": '250000.00',
                "10": '500000.00',
                "11": '1000000.00'
            }
        }
    }
};
