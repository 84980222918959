var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
    var helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "        <span class=\"c-badge-small c-bonus-label__item\" data-promo=\""
    + alias3(((helper = (helper = helpers.idPromotion || (depth0 != null ? depth0.idPromotion : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"idPromotion","hash":{},"data":data}) : helper)))
    + "\">\n            <i class=\"rb-icon rb-icon--info\"></i>\n            "
    + alias3(((helper = (helper = helpers.labelTranslation || (depth0 != null ? depth0.labelTranslation : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"labelTranslation","hash":{},"data":data}) : helper)))
    + "\n        </span>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"c-bonus-label\">\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.racePromotions : depth0),{"name":"each","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});