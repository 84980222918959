'use strict';

/**
 * Service factory to create, store and pass data around the project
 *
 * @returns {{set: Function, get: Function}}
 * @constructor
 */

var Storage = function () {
    var data = {};

    var _set = function (value) {
        if ((typeof value !== 'object') && (value === null)) {
            throw new Error('Value should be type of object');
        }

        for (var key in value) {
            if (value.hasOwnProperty(key)) {
                data[key] = value[key];
            }
        }
    };

    var _get = function (key) {
        return data[key];
    };

    return {
        set: _set,
        get: _get
    };
};

var serviceFactory = {
    create: function (service) {
        if (typeof service !== 'string' || service === '') {
            throw new Error('Service name should be a string');
        }

        this[service] = new Storage();
        if (!this.storage) {
            this.storage = new Storage();
        }

        this.storage.set(service, this[service]);
        return this[service];
    },

    getByNameOrCreate: function (service) {
        if (typeof service !== 'string' || service === '') {
            throw new Error('Service name should be a string');
        }

        return this[service] || this.create(service);
    }
};


export default serviceFactory;
