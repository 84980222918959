'use strict';

import _                    from 'underscore';
import Backbone             from 'backbone';
import moment               from 'moment-timezone';
import app                  from 'components/core/application';
import helpers              from 'components/helpers/helpers';

var MyBetsSettledCollection = Backbone.Collection.extend({

    url: function () {
        return '/ajax/mbets/filter' + this.routerUrl.substr(12) + '/status/NOTOPN';
    },

    initialize: function (page, from, to) {
        this.routerUrl = 'bets/settled/page/:page/from/:from/to/:to';

        this.defaultFromDate = moment().subtract('31', 'days').format('YYYY-MM-DD');
        this.defaultToDate = moment().format('YYYY-MM-DD');

        if (moment(from, 'YYYY-MM-DD').isValid()) {
            this.from = from
        } else {
            this.from = this.defaultFromDate;
        }

        if (moment(to, 'YYYY-MM-DD').isValid()) {
            this.to = to
        } else {
            this.to = this.defaultToDate;
        }

        var re = /(\d+)/,
            testPage = re.exec(page);
        if (testPage && testPage[0]) {
            this.page = testPage[0];
        } else {
            this.page = 1;
        }

        this.routerUrl = this.routerUrl.replace(/:from/, this.from).replace(/:to/, this.to).replace(/:page/, this.page);
    },

    setPage: function (n) {
        this.page = n;
        this.routerUrl = this.routerUrl.replace(/page\/\d*/, 'page/' + this.page);
    },

    changePage: function (n) {
        if (n <= this.pages && n > 0) {
            this.setPage(n);
            app.router.navigate(this.routerUrl, {trigger: true});
        }
    },

    resetDate: function () {
        this.routerUrl = this.routerUrl.replace(/from\/(.*?)\//, 'from/' + this.defaultFromDate + '/').replace(/to\/.*/, 'to/' + this.defaultToDate);
        this.setPage(1);
        app.router.navigate(this.routerUrl, {trigger: true});
    },

    changeDate: function (data) {
        if (data.type === 'from' && this.from !== data.val) {
            this.routerUrl = this.routerUrl.replace(/from\/(.*?)\//, 'from/' + data.val + '/');
            this.from = data.val;
            this.setPage(1);
            app.router.navigate(this.routerUrl, {trigger: true});
        }
        if (data.type === 'to'  && this.to !== data.val) {
            this.routerUrl = this.routerUrl.replace(/to\/.*/, 'to/' + data.val);
            this.to = data.val;
            this.setPage(1);
            app.router.navigate(this.routerUrl, {trigger: true});
        }
    },

    parse: function(response) {
        if (response.type === 'error') {
            this.trigger('mybets:error', response.errorMsg);
            // returns empty collection
            return []
        }

        this.pages = response.pagesNum;

        _.each(response.results, function(result, i, list) {

            result.antePost = result.antePost === "1";

            result.time = moment.unix(result.postTime).calendar(null, {
                sameDay: '[' + app.polyglot.t('label_today') + ']',
                nextDay: '[' + app.polyglot.t('label_tomorrow') + ']',
                nextWeek: 'L',
                lastDay: '[' + app.polyglot.t('label_yesterday') + ']',
                lastWeek: 'L'
            });

            // for ACC bet type
            if (result.type === 'ACC') {
                result.statusLabel = app.polyglot.t('label_bet_status_' + result.status.toLowerCase());

                if (result.winnings) {
                    result.winningsFormatted = helpers.formatMoney(result.winnings, 2, result.currency);
                }

                result.stake = helpers.formatMoney(result.totalStake, 2, result.currency);

                _.each(result.legs, function (leg, i, list) {
                    // e.g. extract DD.MM from DD.MM.YYYY
                    leg.dateFormatted = moment.unix(leg.postTime).format('L');
                    leg.dateFormatted.match(/(\d{2}.\d{2})/).length === null ? leg.dateFormatted : leg.dateFormatted.match(/(\d{2}.\d{2})/)[0];
                    leg.raceNumberLabel = app.interpolate('label_race_number_short', {number: leg.raceNumber});
                });
            }

            // for SPC bet type
            if (result.type === 'SPC') {
                result.raceNumberLabel = app.interpolate('label_race_number_short', {number: result.raceNumber});

                _.each(result.bets, function (bet, i, list) {
                    bet.betTypeLabel = helpers.betTypeName(bet.betType, bet.category, bet.currency == 'USD', result.country);
                    bet.stake = helpers.formatMoney(bet.totalStake, 2, bet.currency);
                    bet.statusLabel = app.polyglot.t('label_bet_status_' + bet.status.toLowerCase());

                    if (bet.category === 'FXD') {
                        bet.fixedOdds = _.map(bet.fixedOdds, function (odd) {
                            return helpers.formatOdds(odd);
                        });
                    }

                    if (bet.winnings) {
                        bet.winningsFormatted = helpers.formatMoney(bet.winnings, 2, bet.currency);
                    }
                });
            }

            // for STD bet type
            if (result.type === 'STD') {

                result.raceNumberLabel = app.interpolate('label_race_number_short', {number: result.raceNumber});

                _.each(result.bets, function (bet, i, list) {

                    bet.betTypeLabel = helpers.betTypeName(bet.betType, bet.category, bet.currency == 'USD', result.country);
                    bet.stake = helpers.formatMoney(bet.totalStake, 2, bet.currency);
                    bet.statusLabel = app.polyglot.t('label_bet_status_' + bet.status.toLowerCase());

                    if (bet.category === 'FXD') {
                        bet.fixedOdds = _.map(bet.fixedOdds, function (odd) {
                            return helpers.formatOdds(odd);
                        });
                    }

                    if (bet.winnings) {
                        bet.winningsFormatted = helpers.formatMoney(bet.winnings, 2, bet.currency);
                    }

                    if (bet.marks) {
                        _.each(bet.marks, function (mark, i, list) {
                            mark.marksIndex = i;
                            mark.marksLabel = mark.join(', ');
                        });
                    }
                });
            }
        });
        return response.results;
    }

});

export default MyBetsSettledCollection;
