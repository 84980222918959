var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.escapeExpression;

  return "<div class=\"c-seo\" data-seo-text>\n    <div class=\"c-seo__inner\">\n        "
    + ((stack1 = this.lambda((depth0 != null ? depth0.text : depth0), depth0)) != null ? stack1 : "")
    + "\n    </div>\n\n    <div class=\"c-seo__show\" data-show>\n        <span class=\"c-seo__show__more\">"
    + alias1(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_show_more",{"name":"translate","hash":{},"data":data}))
    + "</span>\n        <span class=\"c-seo__show__less\">"
    + alias1(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_show_less",{"name":"translate","hash":{},"data":data}))
    + "</span>\n        <i class=\"rb-icon\" data-arrow=\"\"></i>\n    </div>\n</div>";
},"useData":true});