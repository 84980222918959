'use strict';
import _                        from 'underscore';
import Backbone                 from 'backbone';
import auth                     from 'components/auth/auth';
import app                      from 'components/core/application';
import * as SubHeaderMethods    from 'components/sub_header/sub_header.methods';
import BettingService           from 'components/betting/betting.service';
import userPushService          from 'components/user/user-push-service';

/**
 * SubHeader view Controller
 * @name SubHeaderView
 * @constructor
 *
 * @requires Underscore
 * @requires BaseView
 * @requires BackboneSubView
 *
 * @description
 *     SubHeader View Controller
 *
 * @returns Function - Constructor function for SubHeader view
 */
export default Backbone.View.extend(
    _.extend({}, SubHeaderMethods, {

        events: {
            ...SubHeaderMethods.viewEvents,
            'click [data-betslip-button]': 'openBetslip',
            'click [data-open-bets]': 'openBets'
        },

        navigateBack: _.throttle(SubHeaderMethods.navigateBack, 500),

        /**
         * This function will be called in the shared initialize method
         */
        viewInitialize: function () {
            this.listenTo(app, 'app:bootstrapped', () => {
                this.listenTo(app.betslip, 'betslip:change', this.updateBetslipIcon);
            });
            userPushService.on('user:data:update', this.updateUserPushedData, this);
        },

        viewComponentUpdate: function () {
            this.DOM.betslipBetsCount.text(this.options.betCount);
        },

        updateUserPushedData: function (data) {
            if (data.freebets) {
                let freeBets = (data.freebets && _.isArray(data.freebets)) ? data.freebets.length : 0;
                this.DOM.bonus.toggleClass('hasBonus', freeBets > 0);
            }
        },

        updateBetslipIcon: function ({bet_count}) {
            if (typeof bet_count !== 'undefined') {
                this.options.betCount = bet_count;
            }
            this.DOM.betslipButton.toggleClass('isEmpty', !this.options.betCount)
            this.DOM.betslipBetsCount.text(this.options.betCount);
        },

        openBetslip: function () {
            BettingService.toggleBetslip();
        },

        openBets: auth.ifLoggedIn(() => {
            app.router.navigateTo('/bets');
        }),

    }, Backbone.Events));
