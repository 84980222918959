import raceController from 'modules/race/race.controller';

const raceRouter = {
    routes: {
        'race/details/id/:id(/)(:bet_type)(/)': 'raceController'
        //should should be a separate controllers
        //'race/details/id/:id/external/:id(/)': raceController
    },
    controllers: {
        raceController,
    }
};

export default raceRouter;
