'use strict';

import _                        from 'underscore';
import Backbone                 from 'backbone';
import BetItemMultipleMethods   from 'components/betting/bet-item-multiples.methods';

/**
 * BetItemMultiple item view Controller
 * @name BetItem
 * @constructor
 *
 * @requires Underscore
 * @requires Backbone
 * @requires BetItemMultipleMethods
 *
 * @description
 *     BetItemMultiple View Controller
 *
 * @returns Function - Constructor function for BetItemMultiple view
 */
var BetItemMultiple = Backbone.View.extend(
    _.extend({}, BetItemMultipleMethods, { }));

export default BetItemMultiple;
