import _                            from 'underscore';
import app                          from 'components/core/application';
import Backbone                     from 'backbone';
import BackboneCloseView            from 'components/core/mixins/Backbone.CloseView';
import template                     from 'modules/event/event-item.tpl.hbs';

/**
 * Event view
 *
 * @name EventView
 * @constructor
 *
 * @requires jQuery
 * @requires Underscore
 * @requires BackboneCloseView
 * @requires template
 *
 * @description
 * 		Event item view.
 *
 * @returns {Function} - Constructor function for EventViewItem
 */

var EventViewItem = Backbone.View.extend(
    _.extend({}, BackboneCloseView, {
        tagName: 'li',

        template: template,

        events: {
            'click [data-link]': 'goToRace'
        },

        initialize: function () {
            this.listenTo(this.model, 'updated', this.render);
        },

        render: function () {
            this.$el.html(this.template(this.model.toJSON()));
            return this;
        },

        goToRace: function () {
            app.trigger('navigate', '/race/details/id/' + this.model.attributes.idRace);
        }
    })
);

export default EventViewItem;
