'use strict';
import _                    from 'underscore';
import helpers              from 'components/helpers/helpers';
import user                 from 'components/user/user';
import app                  from 'components/core/application';
import countryList          from 'modules/home/country-list';
import EmptyStateView       from 'components/view/empty_state';

/**
 * Subview mixin for the home tabs
 *
 * @type {Object}
 */

export default {

    selectiveRender: function () {
        let previousEvents = {};
        let events = {};

        if(_.size(this.data.events) < 1) {
            this.manageEmptyState();

            //if it is a calendar tab, it will have a selectToday() method
            if(!app._events['select:today'] && this.selectToday && typeof this.selectToday === 'function') {
                app.once('select:today', this.selectToday, this);
            }
        } else {
            this.manageEmptyState(true);
        }

        _.each(this.data.previousEvents, function (value) {
            previousEvents[value.country] = value;
        });

        _.each(this.data.events, function (value) {
            events[value.country] = value;
        });

        _.each(previousEvents, (previousEvent, country) => {
            // if is not in the new events, the group has to be removed
            if (_.isUndefined(events[country])) {
                // console.log('remove country list ' + country);

                let viewToRemove = _.find(this.childViews, (view) => {
                    return view.data.country === country;
                });

                viewToRemove.$el.remove();
                viewToRemove.close();

                this.childViews = _.filter(this.childViews, (view) => {
                    return view.cid !== viewToRemove.cid;
                });

                return;
            }
            if (previousEvent.data.shownHash !== events[country].data.shownHash || previousEvent.data.hiddenHash !== events[country].data.hiddenHash) {
                // console.log('country events changed ' + country);

                let viewToUpdate = _.find(this.childViews, (view) => {
                    return view.data.country === country;
                });

                viewToUpdate.data = events[country];
                viewToUpdate.renderItems();
            }
        });

        _.each(events, (event, country) => {
            // if is not in the old events, the group has to be added
            if (_.isUndefined(previousEvents[country])) {
                // console.log('add country list ' + country);

                let groupView = new countryList({
                    'data': event,
                    'parentView': this,
                    'timeFormat': this.data.timeFormat
                });

                let index,
                    before;

                _.each(this.data.events, (event, i) => {
                    if (event.country === country) {
                        index = parseInt(i, 10);
                    }
                });

                before = this.DOM.list.find('[data-country-list]:eq(' + index + ')');

                if (before.length) {
                    this.DOM.list.find('[data-country-list]:eq(' + index + ')').before(groupView.render().el);
                } else {
                    index--;
                    if (index === -1) {
                        // there is nothing left in the list
                        this.DOM.list.append(groupView.render().el);
                    } else {
                        this.DOM.list.find('[data-country-list]:eq(' + index + ')').after(groupView.render().el);
                    }
                }

                groupView.trigger('view:appended');
                this.childViews.push(groupView);
            }
        });
    },

    // hashes will be used to diff objects and understand which view to update
    hashObjects: function (object) {
        var hashedObject = _.extend({}, object);
        _.each(hashedObject, function(country) {
            country.data.shownHash = "";
            _.each(country.data.shown, function(event) {
                event.hash = helpers.murmurhash3_32_gc(_.values(event).toString(), event.eventId).toString();
                country.data.shownHash += event.hash;
            });
            country.data.hiddenHash = "";
            _.each(country.data.hidden, function(event) {
                event.hash = helpers.murmurhash3_32_gc(_.values(event).toString(), event.eventId).toString();
                country.data.hiddenHash += event.hash;
            });
        });
        return hashedObject;
    },

    /**
     * @method sortByCountry - Sort by country
     *
     * @description
     *      Sort by country according to country order
     *
     * @param {Object}object
     * @returns {{Array}}
     */
    sortByCountry: function(object) {
        var sortedArray = [];
        var userCountryOrder = user.countryOrder();

        _.each(userCountryOrder, function (countryCode) {
            if (_.isUndefined(object[countryCode])) {
                return;
            }

            var ev = {country: countryCode, data: object[countryCode]};
            sortedArray.push(ev);
        });

        // removes countryOrder countries
        object = _.omit(object, userCountryOrder);

        var keys = _.keys(object);
        _.each(keys, function (countryCode) {
            var ev = {country: countryCode, data: object[countryCode]};
            sortedArray.push(ev);
        });

        return sortedArray;
    },

    manageEmptyState: function(remove=false, element, replace) {
        let el = element || this.DOM.list;
        if (remove) {
            if(this.empty_state) {
                this.empty_state.close();
                this.empty_state = null;
            }
        } else {
            if(!this.empty_state) {
                this.empty_state = new EmptyStateView(this.current_empty_state);
            }
            if(replace) {
                el.html(this.empty_state.render().$el);
            } else {
                el.append(this.empty_state.render().$el);
            }
            el.append(this.empty_state.render().$el);
            this.empty_state.delegateEvents();
        }
    }
};
