'use strict';

import _                     from 'underscore';
import $                     from 'jquery';
import app                   from 'components/core/application';
import Backbone              from 'backbone';
import raceHelpers           from 'modules/race/race.helpers';
import SortableCollection    from 'components/sortable-view/sortable.collection';
import BackboneCloseView     from 'components/core/mixins/Backbone.CloseView';

export const tagName = 'div';

export const className = 'c-sortable__wrap';

export const supplementHorseNames = [
        'EIN NACHGENANNTES PFERD',
        'EINE NACHGENANNTE STUTE',
        'EIN NACHGENANNTER HENGST',
        'EIN ANDERER'
    ];

export const events = {
    'click [data-sort]': 'sort',
    'click [data-combo]': 'combo'
};

/**
 * @method initialize
 * @description
 *  On initialization there is a collection and item view set
 *
 * @param opt
 */
export function initialize(options) {
    this.options = this.setOptions(options);
}

export function initCollection() {
    if(this.collection) return;
    //we could pass in models on initialize stage but we do not do it because we want to avail of 'reset' event
    //which is triggered when a collection of models is set on the model
    this.collection = new SortableCollection({}, {sort_order: this.options.sort_order, sort_attribute: this.options.sort_attribute});

    this.listenTo(this.collection, 'sort', this.renderList);
    this.listenTo(this.collection, 'reset', function () {
        let pferdArr = [];
        const supHorseNames = this.supplementHorseNames;

        this.collection.models.forEach(function (item) {
            const pferdName = item.attributes.name.toUpperCase();
            const isSupplementHorse = _.contains(supHorseNames, pferdName);
            if (isSupplementHorse) {
                pferdArr.push(item);
            }
        });
        if(pferdArr.length) {
            this.collection.models = _.difference(this.collection.models, pferdArr).concat(pferdArr);
        }
        this.colsChecked = [];

        this.renderList();

    });

    this.listenTo(app, 'betting:checkbox:clicked', (data) => {
        if(data.siblingClick.isChecked) {
            this.colsChecked.push(data.siblingClick.col);
        } else {
            this.colsChecked.splice(this.colsChecked.indexOf(data.siblingClick.col), 1);
        }
        this.childViews.forEach((view) => {
            view.trigger('betting:siblings:checkbox:clicked', {programnumber: data.siblingClick.programnumber, col: data.siblingClick.col, colsChecked: this.colsChecked, isChecked: data.siblingClick.isChecked});
        });
        
    });

    this.populateCollection(this.options.models);
}

export function populateCollection(models) {
    this.collection.reset(models);
}

export function setOptions(options) {
    if (!options && !this.options) throw new Error('Options required');
    if (!options.th && !this.options) throw new Error('Table header options are required');
    if (!options.models && !this.options) throw new Error('Models are required');
    if (!options.view && !this.options) throw new Error('View required');

    //array of models can be passed in on initialization stage or set by calling 'update' method
    if (options.data) this.data = options.data;

    //default sorting order is 'ASC'
    options.sort_order = options.sort_order || 'asc';

    //default sorting attribute
    options.sort_attribute = options.sort_attribute || this.getSortAttribute(options.th, options.data);

    //item view to render for each model
    this.view = options.view;

    return options;
}

export function orderTH(th) {
    var order = raceHelpers.headerItemsOrder;
    var ordered = [];
    _.each(order, function(key) {
        if(th.hasOwnProperty(key)) ordered.push(th[key]);
    });

    return ordered;
}

export function getSortAttribute(th) {
    let active = _.find(th, function (value) {
        return value.active;
    });

    //sort attribute specifies which is the default field to be used for sorting
    if (!active || !active.sort_attribute) throw new Error('Sort attribute required');

    return active.sort_attribute;
}

export function render() {
    this.$el.html(this.template(this.setTemplateOptions()));

    this.DOM = {
        'runners_list': this.$('[data-runners-list]')
    };

    //init collection when main view is rendered so that 'this.DOM.runners_list' is available when 'composeList' method is invoked on collection 'reset' event
    this.initCollection();

    return this;
}

export function setTemplateOptions() {
    return {
        th: this.orderTH(this.options.th),
        background: this.options.th.background,
        sort_order: this.options.sort_order,
        sort_attribute: this.options.sort_attribute,
        runners: this.options.models,
        hasSilks: this.data.race.hasSilks
    };
}

export function renderList() {
    this.checkHeader();
    this.DOM.runners_list.append(this.composeList());
    app.trigger('race:race:rendered');
}

export function composeList() {
    //clean up all child views because later on the childViews array is reset
    this.closeChildViews();
    var listFragment = document.createDocumentFragment();
    this.childViews = [];

    let models = this.collection.models;
    if(this.options.limit_runners) models = _.first(models, this.options.limit_runners);

    models.forEach((model, i) => {
        var view = new this.view({
            th: this.options.th,
            model: model,
            columns: this.options.columns || [],
            data: this.data,
            th_width: this.options.th,
            options: _.extend({}, this.options.viewOptions, {betType: this.options.betType}, {raceUpdate: this.options.raceUpdate}) || {}
        });
        listFragment.appendChild(view.render().el);
        this.childViews.push(view);
    });

    return listFragment;
}

//todo: check if th changed and rerender parent view;
export function checkHeader() {
    if(this.rerender_th) {
        this.rerender_th = false;
        this.render();
    }
}

/**
 * @method update
 * @description
 *  Update sortable view
 *
 * @param models {Array}    - array of models to be set on collection
 * @param options {Object}  - options including additional 'data' to be passed into the item view
 *
 * @returns {SortableView}
 */
export function update(models, options=null) {
    //store previous th value
    if(options && options.th) this.rerender_th = _.difference(options.th, this.options.th).length > 0;
    if(options) this.options = this.setOptions(options);

    this.populateCollection(models);
}

export function sort(e) {
    e.preventDefault();
    let el = $(e.target);
    let sort_prop = el.attr('data-sort');
    let sort_order = this.getSortOrder(el.attr('class'));
    this.options.sort_order = sort_order;
    this.options.sort_attribute = sort_prop;
    this.manageThActiveState(sort_prop);
    this.rerender_th = true;
    this.collection.sortByAttribute(sort_prop, sort_order);
}

export function combo(e) {
    e.preventDefault();
    app.betslip.trigger('combo:clicked');
}

export function getSortOrder(classes) {
    return classes.indexOf('asc') > -1 && classes.indexOf('c-sortable--isActive') > -1 ? 'desc' : 'asc';
}

export function manageThActiveState(sort_prop) {
    this.options.th = _.mapObject(this.options.th, (val, key) => {
        if(_.isObject(val)) val.active = val.sort_attribute === sort_prop ? true : false;
        return val;
    });
}

export function closeChildViews() {
    _.each(this.childViews, function (view) {
        view.close();
    });
    this.childViews = [];
}

export function onClose() {
    //clean up to remove references
    this.options = null;
    this.view = null;
    this.data = null;
    this.closeChildViews();
}
