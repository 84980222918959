import app                  from 'components/core/application';
import viewManager          from 'components/core/ViewManager';
import HomeView              from 'modules/home/home';

const homeRouter = {
    routes: {
        '': 'home',
        'home': 'home',
        'home/:tab': 'home',
        'horse-racing': 'home',
        'horse-racing/:tab': 'home',
    },

    controllers: {
        home: function (route) {
            app.subHeader.title(app.polyglot.t('label_menu_home'));
            viewManager.showWithSubView(HomeView.ensureSameParentView({activeTab: route}));
        }
    }
}

export default homeRouter;
