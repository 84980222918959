var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
    return "        <div class=\"m-race__virtual\">\n            <div class=\"c-badge-small c-badge--virtual\">"
    + this.escapeExpression(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_virtual_race",{"name":"translate","hash":{},"data":data}))
    + "</div>\n        </div>\n";
},"3":function(depth0,helpers,partials,data) {
    var stack1;

  return "        <div class=\"m-race__result c-result\">\n            <div class=\"c-result__head\">\n                <div class=\"c-result__table\">\n                    <div class=\"c-result__th\">\n                        <div class=\"c-result__td text-left\" style=\"width: 100%\">\n                            "
    + this.escapeExpression(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_runner_p",{"name":"translate","hash":{},"data":data}))
    + "\n                        </div>\n"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.race : depth0)) != null ? stack1.results : stack1)) != null ? stack1.header : stack1)) != null ? stack1.win : stack1),{"name":"if","hash":{},"fn":this.program(4, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(depth0,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.race : depth0)) != null ? stack1.results : stack1)) != null ? stack1.header : stack1)) != null ? stack1.place : stack1),{"name":"if","hash":{},"fn":this.program(6, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(depth0,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.race : depth0)) != null ? stack1.results : stack1)) != null ? stack1.header : stack1)) != null ? stack1.show : stack1),{"name":"if","hash":{},"fn":this.program(8, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n            </div>\n            <div class=\"c-result__list\">\n                <div class=\"c-result__table\">\n"
    + ((stack1 = helpers.each.call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.race : depth0)) != null ? stack1.results : stack1)) != null ? stack1.race : stack1),{"name":"each","hash":{},"fn":this.program(10, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                </div>\n            </div>\n"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.race : depth0)) != null ? stack1.results : stack1)) != null ? stack1.other : stack1),{"name":"if","hash":{},"fn":this.program(19, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n";
},"4":function(depth0,helpers,partials,data) {
    return "                            <div class=\"c-result__td text-right\" style=\"width: 20%\">\n                                "
    + this.escapeExpression(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_bet_type_WIN",{"name":"translate","hash":{},"data":data}))
    + "\n                            </div>\n";
},"6":function(depth0,helpers,partials,data) {
    return "                            <div class=\"c-result__td text-right\" style=\"width: 20%\">\n                                "
    + this.escapeExpression(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_bet_type_PLC",{"name":"translate","hash":{},"data":data}))
    + "\n                            </div>\n";
},"8":function(depth0,helpers,partials,data) {
    return "                            <div class=\"c-result__td text-right\" style=\"width: 20%\">\n                                "
    + this.escapeExpression(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_bet_type_SHW",{"name":"translate","hash":{},"data":data}))
    + "\n                            </div>\n";
},"10":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "                    <div class=\"c-result__tr c-result__positionResult\">\n                        <div class=\"c-result__td\" style=\"width: 100%\">\n                            <div class=\"truncate-text\">\n                                <div>"
    + alias2(alias1((depth0 != null ? depth0.position : depth0), depth0))
    + ". "
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + " <span class=\"c-result__programNumber\">"
    + alias2(alias1((depth0 != null ? depth0.programNumber : depth0), depth0))
    + "</span></div>\n                            </div>\n                        </div>\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.win : depth0),{"name":"if","hash":{},"fn":this.program(11, data, 0),"inverse":this.program(13, data, 0),"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.place : depth0),{"name":"if","hash":{},"fn":this.program(15, data, 0),"inverse":this.program(13, data, 0),"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.show : depth0),{"name":"if","hash":{},"fn":this.program(17, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                    </div>\n";
},"11":function(depth0,helpers,partials,data) {
    return "                        <div class=\"c-result__td text-right\" style=\"width: 20%\">\n                            <div class=\"c-badge-small c-result__odds\">"
    + this.escapeExpression(this.lambda((depth0 != null ? depth0.win : depth0), depth0))
    + "</div>\n                        </div>\n";
},"13":function(depth0,helpers,partials,data) {
    return "                        <div class=\"c-result__td text-right\" style=\"width: 20%\">\n\n                        </div>\n";
},"15":function(depth0,helpers,partials,data) {
    return "                        <div class=\"c-result__td text-right\" style=\"width: 20%\">\n                            <div class=\"c-badge-small c-result__odds\">"
    + this.escapeExpression(this.lambda((depth0 != null ? depth0.place : depth0), depth0))
    + "</div>\n                        </div>\n";
},"17":function(depth0,helpers,partials,data) {
    return "                        <div class=\"c-result__td text-right\" style=\"width: 20%\">\n                            <div class=\"c-badge-small c-result__odds\">"
    + this.escapeExpression(this.lambda((depth0 != null ? depth0.show : depth0), depth0))
    + "</div>\n                        </div>\n";
},"19":function(depth0,helpers,partials,data) {
    var stack1;

  return "                <div class=\"c-result__list\">\n                    <div class=\"c-result__table\">\n"
    + ((stack1 = helpers.each.call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.race : depth0)) != null ? stack1.results : stack1)) != null ? stack1.other : stack1),{"name":"each","hash":{},"fn":this.program(20, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n";
},"20":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "                            <div class=\"c-result__tr c-result__positionResult\">\n                                <div class=\"c-result__td\" style=\"width: 100%\">\n                                    <div>"
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + " "
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.position : depth0),{"name":"each","hash":{},"fn":this.program(21, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n                                </div>\n                                <div class=\"c-result__td text-right\" style=\"width: 20%\">\n                                    <div class=\"c-badge-small c-result__odds\">"
    + alias2(alias1((depth0 != null ? depth0.win : depth0), depth0))
    + "</div>\n                                </div>\n                            </div>\n";
},"21":function(depth0,helpers,partials,data) {
    return "<span class=\"c-result__programNumber\">"
    + this.escapeExpression(this.lambda(depth0, depth0))
    + " </span> ";
},"23":function(depth0,helpers,partials,data) {
    var stack1;

  return "        <div class=\"m-race__preview\" data-preview>\n"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.race : depth0)) != null ? stack1.previewProvider : stack1),{"name":"if","hash":{},"fn":this.program(24, data, 0),"inverse":this.program(29, data, 0),"data":data})) != null ? stack1 : "")
    + "            <div data-audio-preview></div>\n        </div>\n";
},"24":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "                <div class=\"m-race__previewHeader\" "
    + ((stack1 = __default(require("../../components/helpers/handlebar-helpers/compare.js")).call(depth0,((stack1 = (depth0 != null ? depth0.race : depth0)) != null ? stack1.previewProvider : stack1),"===","GON",{"name":"compare","hash":{},"fn":this.program(25, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">\n                    <i class=\"m-race__previewLogo--"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.race : depth0)) != null ? stack1.previewProvider : stack1), depth0))
    + "\"></i>\n                    <span class=\"m-race__previewTitle m-race__previewTitle--"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.race : depth0)) != null ? stack1.previewProvider : stack1), depth0))
    + "\">"
    + alias2(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_race_preview_gb",{"name":"translate","hash":{},"data":data}))
    + "</span>\n                </div>\n"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.race : depth0)) != null ? stack1.previewHeading : stack1),{"name":"if","hash":{},"fn":this.program(27, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                <div>"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? depth0.race : depth0)) != null ? stack1.preview : stack1), depth0)) != null ? stack1 : "")
    + "</div>\n";
},"25":function(depth0,helpers,partials,data) {
    return " data-gon ";
},"27":function(depth0,helpers,partials,data) {
    var stack1;

  return "                    <div class=\"m-race__title\" data-preview-title>"
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.race : depth0)) != null ? stack1.previewHeading : stack1), depth0))
    + "</div>\n";
},"29":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.race : depth0)) != null ? stack1.previewHeading : stack1),{"name":"if","hash":{},"fn":this.program(27, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                "
    + ((stack1 = this.lambda(((stack1 = (depth0 != null ? depth0.race : depth0)) != null ? stack1.preview : stack1), depth0)) != null ? stack1 : "")
    + "\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "    <div class=\"m-race\">\n    <div data-banner></div>\n    <div data-race-numbers></div>\n    <div data-title class=\"m-race__raceTitle\"></div>\n    <div data-promotions></div>\n"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.race : depth0)) != null ? stack1.isVirtual : stack1),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.race : depth0)) != null ? stack1.results : stack1),{"name":"if","hash":{},"fn":this.program(3, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n    <div data-bet-types></div>\n    <div data-runners class=\"c-sortable m-race__runnersList\"></div>\n\n"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.race : depth0)) != null ? stack1.preview : stack1),{"name":"if","hash":{},"fn":this.program(23, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n    <div data-bottom-bet></div>\n    <div data-promotions-terms></div>\n</div>\n";
},"useData":true});