'use strict';

import _                            from 'underscore';
import Backbone                     from 'backbone';
import helpers                      from 'components/helpers/helpers';
import user                         from 'components/user/user';
import eventTimeManager             from 'components/helpers/eventTimeManager';
import moment                       from 'moment-timezone';

var EventModel = Backbone.Model.extend({

    idAttribute: 'eventId',

    initialize: function () {
        this.setupData();
    },

    setupData: function () {
        this.attributes.firstStartFormatted = moment.unix(this.attributes.first_start).tz(user.timeZone()).format('LT');
        this.attributes.time = eventTimeManager.fullTime(this.attributes.next_race, this.attributes.first_start, this.attributes.is_ante_post);
        this.attributes.hide = this.attributes.time.type === 'original' && !this.attributes.is_ante_post;
        this.attributes.countDown = this.attributes.time.type === 'diff';
        this.attributes.type = this.attributes.race_types[0];
        this.attributes.id_event_h2h = this.attributes.id_event_h2h > 0 ? this.attributes.id_event_h2h : null;

        this.attributes.id_event_specials = this.attributes.id_event_specials > 0 ? this.attributes.id_event_specials : null;

        if(this.attributes.is_ante_post) {
            // avoids showing the badge for anteposts events
            this.attributes.is_priced_up = null;
        }
    },

    updateEventTime: function () {
        this.set('time', eventTimeManager.fullTime(this.attributes.next_race, this.attributes.first_start, this.attributes.is_ante_post));
    }
});

export default EventModel;
