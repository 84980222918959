'use strict';
import $            from 'jquery';
import BezierEasing from 'bezier-easing';
import helpers      from 'components/helpers/helpers';
import app          from 'components/core/application';
import requestFrame from 'request-frame';

/**
 * Backbone View mixin
 *
 * @description
 *      Adds scrolling functionality to the content
 *
 * @type {Object}
 */

export default {
    scrollEasing: BezierEasing(0.0, 0.0, 0.2, 1), // https://material.io/guidelines/motion/duration-easing.html#duration-easing-natural-easing-curves deceleration curve

    scrollTo: function (el) {
        if (el.length > 0) {
            let request = requestFrame('request');
            let originalScrollTop = this.el.scrollTop;
            let scrollHeight = this.el.scrollHeight;
            let scrollTopTarget = this.getElementY(el[0]) - this.offsetTop;
            let scrollDelta = scrollTopTarget - originalScrollTop;

            // handle the case where scroll target is at the end of the scrolling view
            // atm not needed if this runs only on android as keyboard is showing up
            // if ((scrollDelta + originalScrollTop) > (scrollHeight - window.screen.height)) {
            //     scrollDelta = scrollHeight - originalScrollTop - window.screen.height;
            // }

            // animation vars
            let percentage = 0;
            let ended = false;
            let startingTime;
            //let lastTime;
            let totalElapsedTime;
            //let elapsedSinceLastLoop;
            let animationTime = 225;

            let scroll = (currentTime) => {
                // requestAnimationFrame doesn't have a timestamp argument in old browsers
                if (typeof currentTime === 'undefined') {
                    currentTime = window.performance.now ?
                    (performance.now() + performance.timing.navigationStart) :
                    Date.now();
                }

                if (!startingTime) {
                    startingTime = currentTime;
                }
                // if (!lastTime) {
                //     lastTime = currentTime;
                // }
                totalElapsedTime = currentTime - startingTime;
                //elapsedSinceLastLoop = currentTime - lastTime;
                //lastTime = currentTime;

                //console.log('Since start: ', totalElapsedTime);
                //console.log('Since last loop: ', elapsedSinceLastLoop);

                if (percentage < 101 && !ended) {
                    request((currentTime) => scroll(currentTime));
                    percentage = (totalElapsedTime / animationTime) * 100;
                    percentage = percentage < 101 ? percentage : 100; // if goes above set it to 100 and finish the animation
                    if (percentage === 100) ended = true;
                    //console.log('Percentage: ', percentage);
                    app.DOM.appScroll.scrollTop(this.scrollEasing(percentage / 100) * scrollDelta + originalScrollTop);
                }
            }

            request((currentTime) => scroll(currentTime));
        }
    },

    handleScrollToInputs: function () {
        // get view distance from top fo the viewport
        this.offsetTop = this.getElementY(this.el);

        //listen to all inputs withing scroller el - Android only
        this.androidInputs = $('[data-device="android"] input');
        if(this.androidInputs.length > 0) {
            this.androidInputs.on('focus', (e)=> {
                //Virtual keyboard is visible
                this.virtualKeyboardVisible = true;
                window.setTimeout(() => {
                    let parentElement = $(e.target).closest('.c-form__section');
                    if (parentElement.length > 0) {
                        this.scrollTo(parentElement);
                        return;
                    }

                    parentElement = $(e.target).closest('.m-betslip__section');
                    if (parentElement.length > 0) {
                        this.scrollTo(parentElement);
                        return;
                    }


                    // last resort
                    parentElement = $(e.target).parent();
                    if (parentElement.length > 0) {
                        this.scrollTo(parentElement);
                        return;
                    }
                }, 300);
            });

            //Hack around android's native prev/next buttons
            this.androidInputs.on('blur', (e)=> {
                //Virtual keyboard is hidden
                this.virtualKeyboardVisible = false;

                //wait 350 mls to make sure that no other inputs were focused in the meantime.
                //E.g. user could have used prev/next buttons where the next input immediately gets focused
                setTimeout(() => {
                    if (!this.virtualKeyboardVisible) {
                        scrollActions();
                    }
                }, 350);
            });
        }

        /**
         * Listen to select "blur" and "change" events to reset header back to its original position because opening select's options list
         * on a mobile device causes header to scroll up beyond the viewport and it never gets back by itself.
         *
         * @type {*|jQuery|HTMLElement}
         */
        this.header = this.header || $('#app-header');
        this.child = this.child || this.$el.children().children();
        this.selectsArr = this.selectsArr || this.$('select');

        this.selectsArr.on('blur', (e)=> {
            scrollActions($(e.target).closest('.c-form__section'));
        });

        this.selectsArr.on('change', (e)=> {
            scrollActions();
        });

        /**
         * @method scrollActions
         *
         * @description
         *  Resets header to its original position (0px, 0px) using WEB API
         *
         *  @url https://developer.mozilla.org/en/docs/Web/API/Element/scrollIntoView
         */
        var scrollActions = (element=null) => {
            let scrollToElement = element;

            setTimeout(() => {
                if (!this.isInViewport(this.header[0])) {
                    setTimeout(()=> {
                        this.header[0].scrollIntoView();

                        if(scrollToElement) {
                            this.scrollTo(scrollToElement);
                        }
                    }, 0);
                }
            }, 0);
        };
    },

    // Determine if an element is in the visible viewport
    isInViewport: function (element) {
        var rect = element.getBoundingClientRect();
        var html = document.documentElement;
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || html.clientHeight) &&
            rect.right <= (window.innerWidth || html.clientWidth)
        );
    },

    getElementY: function (element) {
        let y = 0;
        if (element.offsetParent) {
            do {
                y += element.offsetTop;
            } while (element = element.offsetParent);
            return y;
        }
    }
};
