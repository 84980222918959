import _                from 'underscore';
import appSettings      from 'components/app.settings';
import SettingsModel    from 'modules/settings/settings.model';
import docCookies       from 'components/helpers/docCookies';

/**
 * Use settings objects
 * @module User
 *
 * @requires userSettings
 *
 * @description
 *      Manages user settings
 *
 * @returns {Object}
 */

const UserSettingsManager = {

    data: {},

    readFromCookies: function() {
        var settingsCookie = docCookies.getItem(appSettings.settingsCookieName);
        if(settingsCookie) {
            try {
                this.data = JSON.parse(settingsCookie);
            } catch (e) {
                this.data = {};
            }
        }
        return this;
    },

    save: function () {
        let cookieData = JSON.stringify(this.data);
        var model = new SettingsModel(this.data, {reload: false});
        model.save();
    },

    getGeneral: function() {
        return (this.data.dialog && this.data.dialog.general) ? this.data.dialog.general : {};
    },

    getUserCountryOrder: function () {
        let countryOrder = this.getCountryOrder(this.getGeneral().language);

        var userOrder = (this.data.dialog && this.data.dialog.countries && this.data.dialog.countries.order) ? this.data.dialog.countries.order : [];

        return _.union(userOrder, countryOrder);
    },

    getCountryOrder: function (language) {
        var defaultCountryOrder = ['AE', 'FR', 'GB', 'IE', 'ZA', 'HK', 'DE', 'ES', 'IT', 'SE', 'NO', 'DK', 'FI', 'MT',
        'BR', 'AU', 'NZ', 'CZ', 'HU', 'SG', 'MO', 'MU', 'AT', 'CH', 'CL', 'ZW', 'TR', 'AR', 'JP', 'BE', 'SK', 'NL',
        'PR', 'IN', 'US', 'CA'];

        var langOrder;

        // change order depending on language
        switch (language) {
            case 'de':
                langOrder = ['DE'];
                break;

            case 'en':
                langOrder = ['GB', 'IE', 'ZA'];
                break;

            case 'fr':
                langOrder = ['FR'];
                break;

            case 'es':
                langOrder = ['ES', 'AR', 'CL', 'MX', 'PE', 'PR', 'GB', 'IE', 'ZA'];
                break;

            case 'ag':
            case 'cl':
            case 'co':
            case 'mx':
            case 'pe':
                langOrder = ['AR', 'CL', 'MX', 'CO', 'PE', 'US', 'PR', 'CA', 'GB'];
                break;

            case 'it':
                langOrder = ['IT'];
                break;

            case 'sv':
                langOrder = ['SE', 'NO', 'FI'];
                break;

            case 'fi':
                langOrder = ['FI', 'SE', 'NO'];
                break;

            case 'no':
            case 'eu':
                langOrder = ['NO', 'EU', 'SE', 'FI'];
                break;

            case 'pl':
            case 'cs':
            case 'hu':
            case 'ru':
                langOrder = ['CZ', 'HU', 'SK'];
                break;

            case 'br':
            case 'pt':
                langOrder = ['US', 'BR', 'PE', 'CL', 'AR', 'UR'];
                break;

            case 'tr':
                langOrder = ['TR'];
                break;

            default:
                langOrder = [];
        }

        return _.union(langOrder, defaultCountryOrder);
    }
};

export default UserSettingsManager;
