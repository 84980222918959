'use strict';
import _                    from 'underscore';
import $                    from 'jquery';
import Backbone             from 'backbone';
import app                  from 'components/core/application';
import EventModel           from 'modules/home/event.model';
import user                 from 'components/user/user';
import helpers              from 'components/helpers/helpers';
import webSocketManager     from 'components/helpers/webSocketManager';

/**
 * Home model
 * @module Home
 *
 * @requires Underscore
 * @requires jQuery
 * @requires App
 * @requires BaseCollection
 * @requires BackboneSingleton
 * @requires User
 *
 * @description
 *      Loads event
 *
 * @returns {Function} - Constructor function or existing exemplar for Home
 */
var HomeCollection = Backbone.Collection.extend({

    url: '/rest/v1/events/sidebar',

    model: EventModel,

    initialize: function () {
        this.baseUrl = this.url;

        this.data = {};
        this.listenTo(webSocketManager, 'update:channel:node_sidebarEvents', this.onUpdatePushed);
        this.once('error', this.fallBack, this);
    },

    /**
     * @method fallBack
     * @description
     *  If ajaxHost is not available, use fallback host
     */
    fallBack: function (collection, response, options) {
        this.fetch({ajaxHostFailure: true});
    },

    checkTitleTranslation: function (event) {
        if (event.title_i18n && event.title_i18n[user.data.language]) {
            return event.title_i18n[user.data.language];
        }

        return event.title;
    },

    parse: function (response) {
        this.data.lastTimestamp = response.data.generated_at;
        // key is event id
        response.data.events = _.map(response.data.events, (event, key)=> {
            event.eventId = parseInt(key, 10);
            event.title = this.checkTitleTranslation(event);
            return event;
        });
        // hide greyhounds races form users from Peru
        if (app.brand === 'betsson' && user.ipCountry() === 'PE') {
            return _.filter(response.data.events, (event, key) => {
                return event.race_types.indexOf('D') === -1;
            });
        }
        return response.data.events;

    },

    onUpdatePushed: function (response) {
        var updatedEvents = response.data.events;

        _.each(updatedEvents, (updatedEvent, updatedEventId) => {
            let eventToUpdate = this.get(parseInt(updatedEventId, 10));
            if (eventToUpdate) {
                if (updatedEvent.is_offline || updatedEvent.is_over) {
                    // remove it
                    // we remove it from the collection and selective rendering will be triggered
                    this.remove(eventToUpdate.id);
                } else {
                    // update it
                    // we just rerender the item view
                    eventToUpdate.attributes = updatedEvent;
                    eventToUpdate.attributes.eventId = updatedEventId;
                    eventToUpdate.setupData();
                    eventToUpdate.trigger('updated');
                }
            } else {
                // if events is online ... add it
                if (!updatedEvent.is_offline){
                    updatedEvent.eventId = updatedEventId;
                    let eventToAdd = new EventModel(updatedEvent);
                    this.add(eventToAdd);
                }
            }
        });
    },

    applyRaceTypeFilter: function (filterState) {
        var activeFilters = _.keys(_.pick(filterState, function (value) {
            return value; }));

        return this.filter(function (event) {
            return _.contains(activeFilters, event.attributes.type)
        });
    },

    // calendar
    getCalendarUrl: function (date) {
        if (date) {
            return this.baseUrl + '/' + date + '/+2';
        }
        return this.baseUrl;
    },

    loadCalendarByDate: function (date) {
        this.calendarDate = date;
        this.url = this.getCalendarUrl(date);
        this.fetch();
    },

    setSocketChannelListener: function (channel) {
        this.stopListening();
        this.listenTo(webSocketManager, 'update:channel:' + channel, this.onUpdatePushed);
    }
});

export default HomeCollection;
