'use strict';

import _                 from 'underscore';
import $                 from 'jquery';
import app               from 'components/core/application';
import Backbone          from 'backbone';
import template          from 'modules/home/current.tpl.hbs';
import BackboneSubView   from 'components/core/mixins/Backbone.SubView';
import BackboneCloseView  from 'components/core/mixins/Backbone.CloseView';
import HomeSubViewsMixin  from 'modules/home/home.subviews.mixin';
import pushService       from 'modules/home/homePushService';
import user              from 'components/user/user';
import countryList       from 'modules/home/country-list';
import helpers           from 'components/helpers/helpers';
import appSettings       from 'components/app.settings';
import countDown         from 'components/helpers/countDown';


/**
 * Current view Controller
 * @name CurrentView
 * @constructor
 *
 * @requires Underscore
 * @requires jQuery
 * @requires app
 * @requires BackboneSubView
 * @requires HomeCollection
 *
 * @description
 *     Current View Controller
 *
 * @returns Function - Constructor function for Current view
 */
export default Backbone.View.extend(
    _.extend({}, BackboneSubView, BackboneCloseView, HomeSubViewsMixin, {

        template: template,

        initialize: function (opt) {
            this.DOM = {};
            this.data = {};
            this.childViews = [];

            this.parentView = opt.parentView;

            this.current_empty_state = {
                icon: 'default',
                title: app.polyglot.t('msg_no_events'),
                link: {
                    clickable: true,
                    text: app.polyglot.t('h_reset_filters')
                }
            };

            this.listenTo(this.parentView.subViews.filtersView, 'filter:selected', () => {
                this.data.previousEvents = this.data.events;
                this.data.events = this.setupData();
                this.selectiveRender();
            });

            this.collection = this.parentView.collection;
            this.loaded = this.parentView.loaded; // this value is updated in the home.js view on collection sync event
            this.data.events = this.setupData();

            this.listenTo(this.collection, 'update', () => {
                this.data.previousEvents = this.data.events;
                this.data.events = this.setupData();
                this.selectiveRender();
            });

            pushService.manageCurrentChannel();
            this.collection.setSocketChannelListener(pushService.channels.current);

            // start the countdown timer, which will trigger an event on the app. the event item views will listen to it to update the events countdowns
            this.countDown = new countDown();
            this.countDown.init(appSettings.eventsCountDownStartTime, 'current:timer');
        },

        setupData: function () {
            // apply race type filters
            var filterState = this.parentView.subViews.filtersView.filter.filterState.type;
            var collection = new Backbone.Collection(this.collection.applyRaceTypeFilter(filterState));

            var data = collection.toJSON();
            // filter only current tab events
            var currentEvents = _.filter(data, function (event) {
                return !event.is_ante_post && !event.is_virtual;
            });
            // sort by first_time
            var sortedByTime = _.sortBy(currentEvents, function (event) {
                return event.first_start;
            });
            // group by country
            var groupByCountry = _.groupBy(sortedByTime, function (event) {
                return event.country;
            });
            // split current events and upcoming events
            var splittedEvents = _.mapObject(groupByCountry, function (country) {
                let result = {
                    shown: _.filter(country, function (event) {
                        return !event.hide
                    }),
                    hidden: _.filter(country, function (event) {
                        return event.hide
                    })
                };
                result.hiddenCount = result.hidden.length;
                return result
            });

            var sortedCountryArray = this.sortByCountry(splittedEvents);
            return this.hashObjects(sortedCountryArray);
        },

        render: function () {
            this.closeSubViews();
            this.$el.html(this.template());
            this.DOM.list = this.$('[data-list]');

            if(_.size(this.data.events) > 0) {
                var groupFragment = document.createDocumentFragment();
                _.each(this.data.events, (country) => {
                    // a group is a set of models of the same country
                    let groupView = new countryList({
                        'data': country,
                        'parentView': this
                    });

                    groupFragment.appendChild(groupView.render().el);
                    this.childViews.push(groupView);
                });

                this.DOM.list.append(groupFragment);
                _.each(this.childViews, function (childView) {
                    childView.trigger('view:appended');
                });
            } else {
                this.manageEmptyState();
            }

            return this;
        },

        closeSubViews: function () {
            _.each(this.childViews, function (childView) {
               childView.close();
            });
            this.childViews = null;
            this.childViews = [];
        },

        onClose: function () {
            this.countDown.destroy();
            this.closeSubViews();

            if(this.empty_state) {
                this.empty_state.close();
            }
        }

    })
);
