'use strict';

import _                    from 'underscore';
import $                    from 'jquery';
import app                  from 'components/core/application';
import Backbone             from 'backbone';
import BackboneCloseView    from 'components/core/mixins/Backbone.CloseView';
import template             from 'modules/streams/quality-dialog.tpl.hbs';


var StreamsQualityDialog = Backbone.View.extend(
    _.extend({}, BackboneCloseView, {

        events: {
            'click [data-btn]' : 'buttonClick'
        },

        template: template,

        render: function () {
            this.$el.html(this.template(this.data));
            return this;
        },

        buttonClick: function (e) {
            this.trigger('button:click', $(e.currentTarget).attr('data-btn'));
            app.overlay.closeComponent();
        }
    })
);

export default StreamsQualityDialog;
