var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"m-race__bottomBtn\">\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.error : depth0),{"name":"if","hash":{},"fn":this.program(2, data, 0),"inverse":this.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"2":function(depth0,helpers,partials,data) {
    var stack1;

  return "        <button class=\"c-btn c-btn--primary\" disabled>"
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.error : depth0)) != null ? stack1.message : stack1), depth0))
    + "</button>\n";
},"4":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = __default(require("../../../brands/racebets/js/components/helpers/handlebar-helpers/compare.js")).call(depth0,(depth0 != null ? depth0.bets : depth0),"==","1",{"name":"compare","hash":{},"fn":this.program(5, data, 0),"inverse":this.program(7, data, 0),"data":data})) != null ? stack1 : "");
},"5":function(depth0,helpers,partials,data) {
    return "            <button class=\"c-btn c-btn--primary\" data-quickbet>"
    + this.escapeExpression(__default(require("../../../brands/racebets/js/components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_continue_with_1_bet",{"name":"translate","hash":{},"data":data}))
    + "</button>\n";
},"7":function(depth0,helpers,partials,data) {
    return "            <button class=\"c-btn c-btn--primary\" data-quickbet>"
    + this.escapeExpression(__default(require("../../../brands/racebets/js/components/helpers/handlebar-helpers/translateInterpolate.js")).call(depth0,"label_continue_with_n_bets",{"name":"translateInterpolate","hash":{"num":(depth0 != null ? depth0.bets : depth0)},"data":data}))
    + "</button>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.show : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"useData":true});