var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.statistic : depth0)) != null ? stack1.raceRecord : stack1)) != null ? stack1.annual : stack1),{"name":"if","hash":{},"fn":this.program(2, data, 0),"inverse":this.program(18, data, 0),"data":data})) != null ? stack1 : "");
},"2":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.escapeExpression, alias2=this.lambda, alias3=helpers.blockHelperMissing;

  return "    <table class=\"c-rform__table\">\n    <thead>\n    <tr class=\"c-rform__tr\">\n        <th>"
    + alias1(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_year",{"name":"translate","hash":{},"data":data}))
    + "</th>\n        <th>"
    + alias1(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_starts",{"name":"translate","hash":{},"data":data}))
    + "</th>\n        <th>"
    + alias1(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_betslip_col1",{"name":"translate","hash":{},"data":data}))
    + "</th>\n        <th>"
    + alias1(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_betslip_col2",{"name":"translate","hash":{},"data":data}))
    + "</th>\n        <th>"
    + alias1(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_betslip_col3",{"name":"translate","hash":{},"data":data}))
    + "</th>\n        <th class=\"text-right\">"
    + alias1(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_winnings",{"name":"translate","hash":{},"data":data}))
    + "</th>\n    </tr>\n    </thead>\n    <tbody>\n"
    + ((stack1 = alias3.call(depth0,alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.statistic : depth0)) != null ? stack1.raceRecord : stack1)) != null ? stack1.annual : stack1), depth0),{"name":"statistic.raceRecord.annual","hash":{},"fn":this.program(3, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = alias3.call(depth0,alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.statistic : depth0)) != null ? stack1.raceRecord : stack1)) != null ? stack1.total : stack1), depth0),{"name":"statistic.raceRecord.total","hash":{},"fn":this.program(12, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "    </tbody>\n</table>\n"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.statistic : depth0)) != null ? stack1.lastPerformance : stack1),{"name":"if","hash":{},"fn":this.program(14, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n<table class=\"c-rform__table\">\n    <tbody>\n"
    + ((stack1 = alias3.call(depth0,alias2(((stack1 = (depth0 != null ? depth0.statistic : depth0)) != null ? stack1.lastPerformance : stack1), depth0),{"name":"statistic.lastPerformance","hash":{},"fn":this.program(16, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "    </tbody>\n</table>\n";
},"3":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "        <tr class=\"c-rform__tr\">\n            <td class=\"c-rform__largeTd text-left\">"
    + alias2(alias1((depth0 != null ? depth0.year : depth0), depth0))
    + "</td>\n            <td class=\"text-center\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1.starts : stack1), depth0))
    + "</td>\n            <td class=\"text-center\">"
    + ((stack1 = helpers["if"].call(depth0,__default(require("../../components/helpers/handlebar-helpers/exists.js")).call(depth0,((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1.one : stack1),{"name":"exists","hash":{},"data":data}),{"name":"if","hash":{},"fn":this.program(4, data, 0),"inverse":this.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + "</td>\n            <td class=\"text-center\">"
    + ((stack1 = helpers["if"].call(depth0,__default(require("../../components/helpers/handlebar-helpers/exists.js")).call(depth0,((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1.two : stack1),{"name":"exists","hash":{},"data":data}),{"name":"if","hash":{},"fn":this.program(8, data, 0),"inverse":this.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + "</td>\n            <td class=\"text-center\">"
    + ((stack1 = helpers["if"].call(depth0,__default(require("../../components/helpers/handlebar-helpers/exists.js")).call(depth0,((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1.three : stack1),{"name":"exists","hash":{},"data":data}),{"name":"if","hash":{},"fn":this.program(10, data, 0),"inverse":this.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + "</td>\n            <td class=\"c-rform__largeTd text-right\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1.earnings : stack1), depth0))
    + "</td>\n        </tr>\n";
},"4":function(depth0,helpers,partials,data) {
    var stack1;

  return " "
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1.one : stack1), depth0))
    + " ";
},"6":function(depth0,helpers,partials,data) {
    return " - ";
},"8":function(depth0,helpers,partials,data) {
    var stack1;

  return " "
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1.two : stack1), depth0))
    + " ";
},"10":function(depth0,helpers,partials,data) {
    var stack1;

  return " "
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1.three : stack1), depth0))
    + " ";
},"12":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "        <tr class=\"c-rform__tr bold\">\n            <td class=\"c-rform__largeTd\"></td>\n            <td class=\"text-center\">"
    + alias2(alias1((depth0 != null ? depth0.starts : depth0), depth0))
    + "</td>\n            <td class=\"text-center\">"
    + ((stack1 = helpers["if"].call(depth0,__default(require("../../components/helpers/handlebar-helpers/exists.js")).call(depth0,((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1.one : stack1),{"name":"exists","hash":{},"data":data}),{"name":"if","hash":{},"fn":this.program(4, data, 0),"inverse":this.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + "</td>\n            <td class=\"text-center\">"
    + ((stack1 = helpers["if"].call(depth0,__default(require("../../components/helpers/handlebar-helpers/exists.js")).call(depth0,((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1.two : stack1),{"name":"exists","hash":{},"data":data}),{"name":"if","hash":{},"fn":this.program(8, data, 0),"inverse":this.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + "</td>\n            <td class=\"text-center\">"
    + ((stack1 = helpers["if"].call(depth0,__default(require("../../components/helpers/handlebar-helpers/exists.js")).call(depth0,((stack1 = (depth0 != null ? depth0.results : depth0)) != null ? stack1.three : stack1),{"name":"exists","hash":{},"data":data}),{"name":"if","hash":{},"fn":this.program(10, data, 0),"inverse":this.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + "</td>\n            <td class=\"c-rform__largeTd text-right\">"
    + alias2(alias1((depth0 != null ? depth0.earnings : depth0), depth0))
    + "</td>\n        </tr>\n";
},"14":function(depth0,helpers,partials,data) {
    return "    <hr class=\"c-rform__hr\">\n    <p class=\"c-runnerDetails__info bold\">"
    + this.escapeExpression(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_last_performances",{"name":"translate","hash":{},"data":data}))
    + "</p>\n    <hr class=\"c-rform__hr\">\n";
},"16":function(depth0,helpers,partials,data) {
    var alias1=this.lambda, alias2=this.escapeExpression;

  return "            <tr class=\"c-rform__tr\">\n                <td class=\"c-rform__td-17 text-left\"><span class=\"bold\">"
    + alias2(alias1((depth0 != null ? depth0.finalPosition : depth0), depth0))
    + "</span>/"
    + alias2(alias1((depth0 != null ? depth0.numRunners : depth0), depth0))
    + "</td>\n                <td class=\"text-center\">"
    + alias2(alias1((depth0 != null ? depth0.date : depth0), depth0))
    + "</td>\n                <td class=\"text-left\"><div class=\"truncate-text\"><div><i class=\"c-flag c-flag--small isCountry"
    + alias2(alias1((depth0 != null ? depth0.country : depth0), depth0))
    + "\"></i> "
    + alias2(alias1((depth0 != null ? depth0.title : depth0), depth0))
    + "</div></div></td>\n                <td class=\"c-rform__td-17 text-right\">"
    + alias2(alias1((depth0 != null ? depth0.odds : depth0), depth0))
    + "</td>\n            </tr>\n";
},"18":function(depth0,helpers,partials,data) {
    return "    <p class=\"c-runnerDetails__info text-center pt1 pb1\">"
    + this.escapeExpression(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"msg_no_statistics",{"name":"translate","hash":{},"data":data}))
    + "</p>\n";
},"20":function(depth0,helpers,partials,data) {
    return "    <div class=\"pt2 pb2\">\n        <div class=\"m-race__loader\">\n            <span data-loader-indicator class=\"c-loadingIndicator c-loadingIndicator--bouncer c-loadingIndicator--light isSmall isVisible\">\n                <span class=\"c-loading__elem1\"></span>\n                <span class=\"c-loading__elem2\"></span>\n                <span class=\"c-loading__elem3\"></span>\n            </span>\n        </div>\n    </div>\n";
},"22":function(depth0,helpers,partials,data) {
    return "    <p class=\"c-runnerDetails__info pt1 pb1\">"
    + this.escapeExpression(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"error_occurred",{"name":"translate","hash":{},"data":data}))
    + "</p>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.statistic : depth0)) != null ? stack1.loaded : stack1),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(20, data, 0),"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.error : depth0),{"name":"if","hash":{},"fn":this.program(22, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"useData":true});