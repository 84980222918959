'use strict';

import $            from 'jquery';
import _            from 'underscore';
import Backbone     from 'backbone';
import io           from 'socket.io-client/dist/socket.io.min.js';
import moment       from 'moment-timezone';
import user         from 'components/user/user';
import app          from 'components/core/application';
import appSettings  from 'components/app.settings';

var WebSocketManager = {
    /**
     * @property _options
     * @private
     *
     * @description
     *  Default settings
     *
     * @type {{url: string, port: string, protocol: string, secureProtocol: string, reconnectDelay: number, maxReconnectionAttempts: number, channel: null, timeStamp: *}}
     */
    _options: {
        url: appSettings.webSocketsHost === 'hostname' ? window.location.hostname : appSettings.webSocketsHost,
        port: appSettings.webSocketsPort,
        protocol: 'http://',
        secureProtocol: 'https://',
        reconnectDelay: 120000 + (1000 * Math.floor((Math.random() * 10) + 1)),
        maxReconnectionAttempts: 100,
        channel: null,
        timeStamp: moment().unix(),
        transports: ['websocket', 'polling']
    },

    /**
     * @property connected
     *
     * @description
     *  Socket connection status
     *
     * @type {boolean}
     */
    connected: false,

    /**
     * @property _connection
     * @private
     *
     * @description
     *  Holds reference to connected socket instance
     */
    _connection: null,

    /**
     * @property _channels
     * @private
     *
     * @description
     *  Stores channel names for reconnection purposes.
     *
     * @type {Array}
     */
    _channels: [],

    /**
     * @method connect
     *
     * @description
     *  Connect to socket
     *
     *  @param opts {Object}
     */
    connect: function (opts = {}) {
        _.extend(this._options, opts);

        if (!this._connection) {
            let _connectionUrl = `${this._options.secureProtocol}${this._options.url}:${this._options.port}`;
            if (!_connectionUrl) {
                throw new Error('Url is required');
            }

            _connectionUrl += `/${this.getNamespace()}`;

            this._connection = io(_connectionUrl, {
                transports: this._options.transports
            });
            this._connection.on('connect', () => {
                this.trigger('socket:connected');
                this.connected = true;
                this._registerListeners();
            });
        }
        return this;
    },

    /**
     * @method joinChannel
     *
     * @description
     *  Join channel and store chanel data for reconnection purposes
     *
     * @param channel {Sting}
     * @param timeStamp
     */
    joinChannel: function ({ channel, timestamp = null, idEvent = null }) {
        let _connectionData = {channel: channel, timestamp: timestamp};
        if (idEvent) {
            _connectionData.idEvent = idEvent;
        }
        //store connection properties to be able to reconnect
        this._channels.push(_connectionData);
        if (this._connection) this._connection.emit('join', _connectionData);
    },

    /**
     * @method _reConnect
     * @private
     *
     * @description
     *  Rejoin channels iterating over an array
     */
    _reConnect: function () {
        _.each(this._channels, (channel) => {
            if (this._connection) this._connection.emit('join', channel);
        });
    },

    /**
     * @method _registerListeners
     * @private
     *
     * @description
     *  Register listeners to Node sever events
     */
    _registerListeners: function () {
        if (!this._connection) return;

        this._connection.on('error', () => {
            this.connected = false;
            user.loggedInNode = false;

            setTimeout(() => {
                if (this._connection.socket) {
                    this._connection.socket.reconnect();
                }
            }, this._options.reconnectDelay);

        });

        this._connection.on('disconnect', () => {
            this.connected = false;
            user.loggedInNode = false;

            setTimeout(() => {
                if (this._connection.socket) {
                    this._connection.socket.reconnect();
                }
            }, this._options.reconnectDelay);

        });

        this._connection.on('reconnect', () => {
            this._reConnect();
        });

        this._connection.on('connect_error', () => {
            this._reConnect();
        });

        this._connection.on('dataUpdate', response => {
            // discard if have different brands
            if(response.data && response.data.orgBrand && response.data.orgBrand !== app.brand) {
                return false;
            }
            this.trigger(`update:channel:${response.channel}`, response);

            // Trigger an event on the app to avoid importing the webSocketManager.
            if(response.data === 'realityCheck') {
                app.trigger('realityCheck:warning')
            }
        });

        this._connection.on('session', (session = {}) => {
            switch (session.event) {
                case 'loggedIn':
                    user.loggedInNode = true;
                    this.trigger('node:loggedIn');
                    break;
                case 'loggedOut':
                    user.loggedInNode = false;
                    this.trigger('node:loggedOut');
                    break;
                case 'relogIn':
                    this.trigger('node:relogin');
                    user.loggedInNode = true;
                    break;
            }
        });
    },

    /**
     * @method notifyNodeServerAboutLogin
     *
     * @description
     *  Set user cookies on node.js server side
     *
     *  @param cookies
     */
    notifyNodeServerAboutLogin: function (cookies) {
        if (this._connection) this._connection.emit('login', cookies);
    },

    /**
     * @method leaveChannel
     *
     * @description
     *  Leave channel
     *
     *  @param channel
     */
    leaveChannel: function (channel) {
        if (this.connected && channel) {
            this._connection.emit('leave', channel);


            this._channels = _.reject(this._channels, function (item) {
                return item.channel === channel;
            });
        }
    },

    /**
     * @method getNamespace
     *
     * @description
     *  Returns namespace
     *
     */
    getNamespace: function  () {
        let namespace = app.brandChild;

        if (app.brand === 'betsson') {
            if (['cl', 'co', 'pe'].indexOf(user.data.orgLanguage) > -1) {
                namespace += user.data.orgLanguage;
            }
        }

        return namespace
    }
};

/**
 * Inherit backbone events so that the application can serve as a mediator - Event(Message) Bus
 */
_.extend(WebSocketManager, Backbone.Events);

export default WebSocketManager;
