'use strict';
import Backbone             from 'backbone';
import _                    from 'underscore';
import app                  from 'components/core/application';
import BackboneSingleton    from 'components/core/mixins/Backbone.Singleton';
import helpers              from 'components/helpers/helpers';

/**
 * Base router
 * @module BaseRouter
 * @constructor
 *
 * @requires Backbone
 * @requires app
 *
 * @description
 *      Add 'before:route:change' and 'after:route:change' events.
 *      Pass route related data to listeners. E.g. route restrictions, etc
 *
 * @returns {Function} - Constructor function for BaseRouter
 */

export default Backbone.Router.extend(
    _.extend({}, Backbone.Events, {
        /**
         * @property {Array} visitedRoutes
         *
         * @description
         *     Store all visited routes names
         *
         */
        visitedRoutes: [],

        /**
         * @property toRoute
         *
         */
        toRoute: null,

        storeRouteAsVisited: true,

        /**
         * @method execute
         *
         * @description
         *     Override Backbone native router's method.
         *     Override is done to trigger the following events:
         *     @event 'before:route:change' (e.g. check if a route requires authorization)
         *     @event 'after:route:change' (e.g. cleanup after previous view)
         *
         * @param {Function} callback
         * @param {?} args - ?
         * @param {?} name - ?
         */
        execute: function (callback, args, name) {
            var routeInfo = {
                prev: _.last(this.visitedRoutes),
                current: Backbone.history.getFragment(),
                name: name || '',
            };

            this.trigger('before:route:change', routeInfo);
            Backbone.Router.prototype.execute.apply(this, arguments);
            this.trigger('after:route:change', routeInfo);

            var fragment = Backbone.history.getFragment();
            //if fragment is empty (it is a home page), set its value to be a '/
            if (!fragment) {
                fragment = '/';
            }

            //do not store routes on user navigating back or if the previous one equals current
            if (this.storeRouteAsVisited && routeInfo.current !== routeInfo.prev) {
                this.visitedRoutes.push(fragment);

                if (this.visitedRoutes.length >= 2) {
                    //instruct to show the back button
                    this.trigger('history:page:next');
                }
            }
            this.storeRouteAsVisited = true;
        },

        /**
         * Overrides backbone route function
         * @param route
         * @param name
         * @param callback
         * @returns {exports|export default|module:BaseRouter}
         */
        route: function (route, name, callback) {
            if (!_.isRegExp(route)) {
                route = this._routeToRegExp(route);
            }
            if (_.isFunction(name)) {
                callback = name;
                name = '';
            }
            if (!callback) {
                callback = this[name];
            }
            var router = this;

            Backbone.history.route(route, function (fragment) {
                const args = router._extractParameters(route, fragment);
                const doRoute = function () {
                    if (router.execute(callback, args, name) !== false) {
                        router.trigger.apply(router, ['route:' + name].concat(args));
                        router.trigger('route', name, args);
                        Backbone.history.trigger('route', router, name, args);
                    }
                };

                if (!_.isEmpty(app.enforceRoute)) {
                    const rulesMatching = _.find(app.enforceRoute, (callback, filter) => {
                        filter = _.isRegExp(filter) ? filter : router._routeToRegExp(filter);
                        return !filter.test(fragment);
                    });
                    if (rulesMatching) {
                        router.restrictions[rulesMatching](fragment, args, doRoute);
                        return;
                    }
                }
                router.filterRules(router, router.routeRestrictions, fragment, args, doRoute);
            });
            return this;
        },

        /**
         * go through the restrictions and find those matching,
         * then calls _applyRule to run them.
         * @param router
         * @param restrictions
         * @param fragment
         * @param args
         * @param callback
         */
        filterRules: function (router, restrictions, fragment, args, callback) {
            var rulesMatching = _.filter(restrictions, function (callback, filter) {
                filter = _.isRegExp(filter) ? filter : router._routeToRegExp(filter);
                return filter.test(fragment);
            });
            this.applyRules(rulesMatching, router, fragment, args, callback);
        },

        /**
         * recursive function, runs the rules functions until there are no left
         * then calls the callback function
         * @param rules
         * @param router
         * @param fragment
         * @param args
         * @param callback
         */
        applyRules: function (rules, router, fragment, args, callback) {
            var self = this;
            // if there are no more rules to test go on with routing
            if (!rules.length) {
                callback.call(router);
                return;
            }

            var current = rules[0],
                tail = _.tail(rules),
                next = function () {
                    // call itself until no rules are left
                    self.applyRules(tail, router, fragment, args, callback);
                };

            if (_.isString(current)) {
                current = router.restrictions[current];
            }

            // rule is a function with 3 arguments
            // it will call next() and continue the recursive calls
            if (current.length === 3) {
                current.apply(router, [fragment, args, next]);
            } else {
                if (current.apply(router, [fragment, args]) !== false) {
                    next();
                }
            }
        },

        navigateBack: function () {
            //do not go back since it is the last route
            if (this.toRoute) {
                this.navigate(this.toRoute, {trigger: true});
                this.toRoute = null;
                this.trigger('history:page:last');
            } else if (this.visitedRoutes.length > 1) {
                //do not store this route in visitedRoutes array
                this.storeRouteAsVisited = false;
                //find route and remove
                var index = this.visitedRoutes.indexOf(Backbone.history.getFragment());
                if (index > 0) {
                    this.visitedRoutes.splice(index, 1);
                }

                //if the urls that we want to go back is in routeTransformationMap - redirect user to transformed route.
                //Transform value is the the same url but without "success" or "error" param in order to avoid showing status dialog
                let goTo = _.last(this.visitedRoutes);
                if (this.routeTransformationMap[goTo]) {
                    this.navigate(this.routeTransformationMap[goTo], {trigger: true});
                } else if (history.pushState) {
                    history.back();
                }

                if (this.visitedRoutes.length < 2) {
                    //instruct to hide the back button
                    this.trigger('history:page:last');
                }
            }
        },

        navigateTo: function (url, trigger) {
            trigger = trigger || true;
            if (url) {
                this.navigate(url, {trigger: trigger});
            }
        },

        parseQueryString: helpers.parseQueryString
    }),
    BackboneSingleton
);
