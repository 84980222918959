import _                    from 'underscore';
import $                    from 'jquery';
import app                  from 'components/core/application';
import user                 from 'components/user/user';
import template             from 'modules/home/filters.tpl.hbs';
import Backbone             from 'backbone';
import BackboneCloseView    from 'components/core/mixins/Backbone.CloseView';
import BackboneSubView      from 'components/core/mixins/Backbone.SubView';
import HomeCollection       from 'modules/home/home.collection';
import userFilters          from 'components/user/filter';

/**
 * Filter view Controller
 * @name FilterView
 * @constructor
 *
 * @requires Underscore
 * @requires jQuery
 * @requires app
 * @requires BaseView
 * @requires BackboneSubView
 *
 * @description
 *     Filter View Controller
 *
 */
export default Backbone.View.extend(
    _.extend({}, BackboneSubView, BackboneCloseView, {

        className: 'c-filter__wrapper',

        template: template,

        events: {
            'touchstart [data-type]': 'filterFn',
            'click .c-filter__toggle': 'toggleFilter'
        },

        initialize: function () {
            this.data = {};
            this.filter = Object.create(userFilters);

            // check for user preferences stored in cookies
            this.filter
                .getFromCookie()
                .createFilterCookie();

            // set default state
            this.data.filterState = this.filter.filterState;

            app.on('filters:reset', this.reset, this);
        },

        render: function () {
            // Hide dog races for users from Peru
            if (user.ipCountry() === 'PE') {
                delete this.data.filterState.type['D'];
            }
            this.$el.html(this.template(this.data));
            return this;
        },

        toggleFilter: function (e) {
            e.preventDefault();
            $(e.currentTarget).parents('.c-filter').toggleClass('isOpen');
        },

        filterFn: function (e) {
            var $el = $(e.currentTarget);
            var type = $el.attr('data-type');
            var value = !$el.hasClass('isActive');

            $el.toggleClass('isActive');

            this.data.filterState.type[type] = value;

            this.filter.filterState = this.data.filterState;
            this.filter.saveToCookie();

            // notify view
            this.trigger('filter:selected', this.data.filterState);
        },

        reset: function () {
            _.each(this.data.filterState.type, (val, key)=> {
                this.data.filterState.type[key] = true;
                this.filter.filterState.type[key] = true;
            });

            this.filter.saveToCookie();

            this.$('[data-type]').addClass('isActive');

            // notify view
            this.trigger('filter:selected', this.data.filterState);
        }
    })
);
