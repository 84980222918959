'use strict';

import Backbone             from 'backbone';
import RaceModel            from 'components/model/race.model';

var NextRacesCollection = Backbone.Collection.extend({

    model: RaceModel,

    url: '/ajax/events/upcoming/limit/5'
});

export default NextRacesCollection;
