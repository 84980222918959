'use strict';

import $                    from 'jquery';
import _                    from 'underscore';
import app                  from 'components/core/application';
import Backbone             from 'backbone';
import template             from 'components/mybets-bar/mybets-bar.tpl.hbs';
import BettingService       from 'components/betting/betting.service';
import auth                 from 'components/auth/auth';
import RaceBottomBet        from 'modules/race/race-bottom-bet';
import helpers              from 'components/helpers/helpers';

/**
 * Footer view
 *
 * @name Footer
 * @constructor
 *
 * @requires Underscore
 * @requires jQuery
 * @requires Backbone
 * @requires template
 * @requires BackboneCloseView
 *
 * @description
 * 		Footer view
 *
 * @returns {Function} - Constructor function for MyBetsBar
 */

const MyBetsBar = Backbone.View.extend({
    template: template,

    el: app.DOM.appFooter,

    events: {
        'click [data-betslip-button]': 'openBetslip',
        'click [data-open-bets]': 'openBets'
    },

    initialize: function () {
        this.DOM = {};

        this.data = {
            betCount: 0
        };

        this.listenTo(app, 'app:bootstrapped', () => {
            this.listenTo(app.betslip, 'betslip:change', this.updateBetslipIcon);
            this.listenTo(app.overlay, 'betslip:visible', this.betslipVisible);
            this.listenTo(app.overlay, 'betslip:hidden', this.betslipHidden);
        });

        this.render();
    },

    render: function () {
        this.$el.html(this.template(this.data));
        this.DOM.betslipButton = this.$el.find('[data-betslip-button]')
        this.DOM.betslipBetsCount = this.$('[data-betslip-count]');
        this.DOM.betslipCell = this.$('[data-betslip-cell]');
        this.DOM.bottomBet = this.$('[data-bottom-bet]');

        return this;
    },

    openBetslip: function () {
        BettingService.toggleBetslip();
    },

    openBets: auth.ifLoggedIn(() => {
        app.router.navigateTo('/bets');
    }),

    updateBetslipIcon: function (data) {
        if (data && data.hasOwnProperty('bet_count')) {
            this.data.betCount = data.bet_count;
        }
        this.DOM.betslipButton.toggleClass('isEmpty', !this.data.betCount);
        this.DOM.betslipBetsCount.text(this.data.betCount);
    },

    betslipVisible: function () {
        this.DOM.betslipCell.addClass('isActive');
    },

    betslipHidden: function () {
        this.DOM.betslipCell.removeClass('isActive');
    }
});

export default MyBetsBar;
