'use strict';

import _                    from 'underscore';
import Backbone             from 'backbone';
import moment               from 'moment-timezone';
import app                  from 'components/core/application';
import H2HModel             from 'modules/h2h/h2h.model';
import helpers              from 'components/helpers/helpers';
import user                 from 'components/user/user';
import webSocketManager     from 'components/helpers/webSocketManager';

/**
 * NextH2H Collection
 * @name NextH2HCollection
 * @constructor
 *
 * @requires Underscore
 * @requires Backbone
 * @requires app
 * @requires user
 * @requires helpers
 *
 * @description
 *     NextH2H Collection
 *
 * @returns Function - Constructor function for NextH2H Collection
 */
var NextH2HCollection = Backbone.Collection.extend({

    url: '/rest/v2/races/upcoming_h2h',

    model: H2HModel,

    initialize: function()  {
        this.idEventParentMap = {};
        this.listenTo(webSocketManager, 'update:channel:node_sidebarEvents', this.onUpdatePushed);
    },

    onUpdatePushed: function(response = {}) {
        if(response.data && response.data.events) {
            let events = response.data.events;
            for(let key in events) {
                if(events.hasOwnProperty(key) && events[key].id_event_h2h === 0) {
                    this.fetch();
                }
            }
        }
    },

    /**
     * @method parse
     * @description
     *      Format time and odds
     *
     * @param response
     */
    parse: function(response) {
        var races = _.map(response, (race)=> {
            race.raceTime = moment.unix(race.raceTime).tz(user.timeZone()).format('LT');
            this.idEventParentMap[race.idEventParent] = race.idEventParent;
            _.map(race.topRunners, function (runner) {
                runner.fixedOddsWin = helpers.formatOdds(runner.fixedOddsWin, user.data.oddsFormat, true);
                runner.ageFormatted = (runner.age && runner.age > 0) ? app.interpolate('label_year_old_abbr', {'age': runner.age}) : null;
                runner.genderFormatted = runner.gender ? app.polyglot.t('label_gender_short_' + runner.gender) : null;
                runner.silkUrl = null;
                if (!_.isEmpty(runner.silkExtension) && !_.isEmpty(runner.silkId)) {
                    runner.silkUrl = '//www.racebets.com/cache/img/silks/' + runner.silkId + '_w62.' + runner.silkExtension;
                }

                return runner;
            });

            return race;
        });

        return races;
    }

});

export default NextH2HCollection;
