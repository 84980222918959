'use strict';

import _                    from 'underscore';
import Backbone             from 'backbone';
import app                  from 'components/core/application';
import template             from 'components/view/empty_state.tpl.hbs';
import BackboneCloseView    from 'components/core/mixins/Backbone.CloseView';

var EmptyStateView =  Backbone.View.extend(
    _.extend({}, BackboneCloseView, {

        events: {
            'click [data-link-click]': 'processClick'
        },

        initialize: function(opts) {
            this.options = opts;

            if(!this.options) {
                throw new Error('Options required!');
            }

            this.template = this.options.template || template;
        },

        render: function() {
            this.$el.html(this.template(this.options));
            return this;
        },

        processClick: function(e) {
            e.preventDefault();
            if (_.isFunction(this.options.link.action)) {
                this.options.link.action(e);
            }
            app.trigger('filters:reset select:today');
        }
    })
);

export default EmptyStateView;
