'use strict';
import $                            from 'jquery';
import _                            from 'underscore';
import app                          from 'components/core/application';
import Backbone                     from 'backbone';
import BackboneCloseView            from 'components/core/mixins/Backbone.CloseView';
import template                     from 'modules/streams/streams.tpl.hbs';
import StreamsCollection            from 'modules/streams/streams.collection';
import StreamsQualityDialog         from 'modules/streams/quality-dialog';
import MediaView                    from 'components/media/media';
import moment                       from 'moment-timezone';
import user                         from 'components/user/user';
import auth                         from 'components/auth/auth';

/**
 * Streams View
 *
 * @name ContentView
 * @constructor
 *
 * @requires jQuery
 * @requires Underscore
 * @requires template
 *
 * @description
 *  List current/upcoming streams
 *
 * @returns {Function} - Constructor function for StreamsView
 */

var StreamsView = Backbone.View.extend(
    _.extend({}, BackboneCloseView, {

        className: 'm-stream',

        template: template,

        events: {
            'click [data-index]': 'openStream'
        },

        initialize: function () {
            this.data = {};
            this.collection = new StreamsCollection();
            this.listenTo(this.collection, 'sync', this.render);
            this.collection.fetch();
        },

        render: function () {
            this.$el.html(this.template({ data: this.collection.toJSON(), showEmpty: this.collection.data.showEmpty }));
            return this;
        },

        openStream: function (e) {
            auth.ifLoggedIn(function () {
                var i = $(e.currentTarget).attr('data-index');
                var stream = this.collection.toJSON()[i];
                stream.streamType = 'LIV';

                var streamTime = moment.unix(stream.startTime);
                if (streamTime.isBefore(moment().tz(user.timeZone()))) {
                    // if more than 1 resources ask user which quality
                    if (stream.resources.length > 1) {
                        var dialogView = new StreamsQualityDialog();
                        app.overlay.dialog({
                            contentView: dialogView,
                            closeOnOutsideClick: true,
                            title: app.polyglot.t('label_livestream_quality')
                        });
                        app.overlay.component.render();
                        this.listenTo(dialogView, 'button:click', function (id) {
                            stream.idResource = stream.resources[id].idResource;
                            app.mediaView = null;
                            app.mediaView = new MediaView(stream);
                        })
                    } else {
                        stream['idResource'] = stream.resources[0].idResource;
                        app.mediaView = null;
                        app.mediaView = new MediaView(stream);
                    }
                } else {
                    app.trigger('dialog:warning', app.interpolate('msg_media_start_time', {time: streamTime.format('LT')}));
                }
            }, this)();
        }
    })
);

export default StreamsView;
