import _                        from 'underscore';
import Backbone                 from 'backbone';
import BackboneSingleton        from 'components/core/mixins/Backbone.Singleton';
import * as OverlayMethods      from 'components/core/app-overlay.methods';
import app                      from 'components/core/application';
import QuickBetDialog           from 'components/betting/quick-bet-dialog';
import QuickBetMultiDialog      from 'components/betting/quick-bet-multi-dialog';

/**
 * Overlay
 *
 * @module Overlay
 * @singleton
 *
 * @description
 *  Manage ui components (e.g. dialog. modal, etc).
 *  Primary goal is to properly clean up each and every ui component to avoid memory leaks
 *
 * @requires Backbone
 * @requires app
 * @requires BackboneSingleton
 * @requires ActionSheet
 * @requires Dialog
 *
 * @description
 *     Singleton overlay entity
 *
 * @returns {Function} - Overlay
 */
var AppOverlay = Backbone.View.extend(
    _.extend({}, OverlayMethods, {

        el: '#overlay-backdrop',

        events: OverlayMethods.viewEvents,

        initialize: function() {
            app.router.on('before:route:change', this.onRouteChange, this);
            app.on('dialog:close', this.closeDialog, this);
            app.on('app:scroll', (offset) => {
                this.$el.css({'top': offset});
            });
            app.on('app:height:change', (height) => {
                this.$el.css({'height': height});
            });
        },

        quickbet: function (opt) {
            this.checkForComponent();
            this.component = new QuickBetDialog(opt);
            this.listenTo(this.component, 'close:btn', function () {
                this.closeDialog();
            });

            return this;
        },

        betslip: function () {
            this.checkForComponent();
            this.component = app.betslip;
            this.component.visible = true;
            this.trigger('betslip:visible');
            this.listenTo(this.component, 'close:btn', function () {
                this.hideBetslip();
            });

            return this;
        },

        quickbetMulti: function (opt) {
            this.checkForComponent();
            this.component = new QuickBetMultiDialog(opt);
            this.listenTo(this.component, 'close:btn', function () {
                this.closeDialog();
            });

            return this;
        }
    })
);

AppOverlay.getInstance = BackboneSingleton.getInstance;

export default AppOverlay;
