var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "        <div class=\"c-rform__slideWrap\">\n            <div class=\"c-rform__slide\" data-slide>\n                <div class=\"table-row c-rform__nav\">\n                    <div class=\"table-cell c-rform__cell\">\n                        <p class=\"c-runnerDetails__info\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.race : depth0)) != null ? stack1.postTimeDateLabel : stack1), depth0))
    + " | "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.race : depth0)) != null ? stack1.event : stack1), depth0))
    + " "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.race : depth0)) != null ? stack1.categoryLabel : stack1),{"name":"if","hash":{},"fn":this.program(2, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</p>\n                        <p class=\"c-runnerDetails__info\">"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.race : depth0)) != null ? stack1.distanceLabel : stack1),{"name":"if","hash":{},"fn":this.program(4, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.race : depth0)) != null ? stack1.purseLabel : stack1),{"name":"if","hash":{},"fn":this.program(6, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.race : depth0)) != null ? stack1.trackSurface : stack1),{"name":"if","hash":{},"fn":this.program(8, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.race : depth0)) != null ? stack1.trackConditions : stack1),{"name":"if","hash":{},"fn":this.program(10, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</p>\n                    </div>\n                    <div class=\"table-cell c-rform__cell c-rform__iconCell\" data-race-link>\n                        <div class=\"c-rform__icon\">\n                            <i class=\"rb-icon rb-icon--dotted-list\"></i>\n                        </div>\n                    </div>\n"
    + ((stack1 = __default(require("../../components/helpers/handlebar-helpers/compare.js")).call(depth0,((stack1 = (depth0 != null ? depth0.race : depth0)) != null ? stack1.archiveVideo : stack1),"===",true,{"name":"compare","hash":{},"fn":this.program(12, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                </div>\n                <table class=\"c-rform__table\">\n                    <thead>\n                    <tr class=\"c-rform__tr\">\n                        <th>#</th>\n                        <th>"
    + alias2(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_horse",{"name":"translate","hash":{},"data":data}))
    + "</th>\n                        <th class=\"text-center\">"
    + alias2(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_age_short",{"name":"translate","hash":{},"data":data}))
    + "</th>\n"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.form : depth0)) != null ? stack1.result : stack1)) != null ? stack1.showWeight : stack1),{"name":"if","hash":{},"fn":this.program(14, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.unless.call(depth0,((stack1 = (depths[1] != null ? depths[1].race : depths[1])) != null ? stack1.isGreyhound : stack1),{"name":"unless","hash":{},"fn":this.program(16, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                        <th class=\"text-right\">"
    + alias2(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_odds",{"name":"translate","hash":{},"data":data}))
    + "</th>\n                    </tr>\n                    </thead>\n                    <tbody>\n"
    + ((stack1 = helpers.blockHelperMissing.call(depth0,alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.form : depth0)) != null ? stack1.result : stack1)) != null ? stack1.positions : stack1), depth0),{"name":"form.result.positions","hash":{},"fn":this.program(18, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                    </tbody>\n                </table>\n"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.form : depth0)) != null ? stack1.result : stack1)) != null ? stack1.distances : stack1),{"name":"if","hash":{},"fn":this.program(31, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            </div>\n        </div>\n        <div class=\"c-rform__slideLoader\" data-slide-loader>\n            <div class=\"m-race__loader\">\n                <span data-loader-indicator class=\"c-loadingIndicator c-loadingIndicator--bouncer c-loadingIndicator--light isSmall isVisible\">\n                    <span class=\"c-loading__elem1\"></span>\n                    <span class=\"c-loading__elem2\"></span>\n                    <span class=\"c-loading__elem3\"></span>\n                </span>\n            </div>\n        </div>\n"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.form : depth0)) != null ? stack1.ref : stack1)) != null ? stack1.races : stack1),{"name":"if","hash":{},"fn":this.program(33, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"2":function(depth0,helpers,partials,data) {
    var stack1;

  return "| "
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.race : depth0)) != null ? stack1.categoryLabel : stack1), depth0));
},"4":function(depth0,helpers,partials,data) {
    var stack1;

  return this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.race : depth0)) != null ? stack1.distanceLabel : stack1), depth0))
    + " |";
},"6":function(depth0,helpers,partials,data) {
    var stack1;

  return this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.race : depth0)) != null ? stack1.purseLabel : stack1), depth0))
    + " |";
},"8":function(depth0,helpers,partials,data) {
    var stack1;

  return this.escapeExpression(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,__default(require("../../components/helpers/handlebar-helpers/concat.js")).call(depth0,"label_track_surface_",((stack1 = (depth0 != null ? depth0.race : depth0)) != null ? stack1.trackSurface : stack1),{"name":"concat","hash":{},"data":data}),{"name":"translate","hash":{},"data":data}));
},"10":function(depth0,helpers,partials,data) {
    var stack1;

  return " | "
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.race : depth0)) != null ? stack1.trackConditions : stack1), depth0));
},"12":function(depth0,helpers,partials,data) {
    return "                    <div class=\"table-cell c-rform__cell c-rform__iconCell\" data-video>\n                        <div class=\"c-rform__icon\">\n                            <i class=\"rb-icon rb-icon--cam\"></i>\n                        </div>\n                    </div>\n";
},"14":function(depth0,helpers,partials,data) {
    return "                        <th class=\"text-center\">"
    + this.escapeExpression(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_abbr_weight",{"name":"translate","hash":{},"data":data}))
    + "</th>\n";
},"16":function(depth0,helpers,partials,data) {
    return "                            <th>"
    + this.escapeExpression(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_jockey",{"name":"translate","hash":{},"data":data}))
    + "</th>\n";
},"18":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "                        <tr class=\"c-rform__tr "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.isRunner : depth0),{"name":"if","hash":{},"fn":this.program(19, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\">\n                            <td>"
    + alias2(alias1((depth0 != null ? depth0.position : depth0), depth0))
    + "</td>\n                            <td class=\"c-rform"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depths[1] != null ? depths[1].race : depths[1])) != null ? stack1.isGreyhound : stack1),{"name":"if","hash":{},"fn":this.program(21, data, 0, blockParams, depths),"inverse":this.program(23, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.isOpponent : depth0),{"name":"if","hash":{},"fn":this.program(25, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\"><div class=\"truncate-text\"><div>"
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + "</div></div></td>\n                            <td class=\"text-center\">"
    + alias2(alias1((depth0 != null ? depth0.age : depth0), depth0))
    + "</td>\n"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = ((stack1 = (depths[1] != null ? depths[1].form : depths[1])) != null ? stack1.result : stack1)) != null ? stack1.showWeight : stack1),{"name":"if","hash":{},"fn":this.program(27, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.unless.call(depth0,((stack1 = (depths[1] != null ? depths[1].race : depths[1])) != null ? stack1.isGreyhound : stack1),{"name":"unless","hash":{},"fn":this.program(29, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                            <td class=\"text-right\">"
    + alias2(alias1((depth0 != null ? depth0.oddsLabel : depth0), depth0))
    + "</td>\n                        </tr>\n";
},"19":function(depth0,helpers,partials,data) {
    return "isRunner";
},"21":function(depth0,helpers,partials,data) {
    return "__xLargeTd";
},"23":function(depth0,helpers,partials,data) {
    return "__largeTd";
},"25":function(depth0,helpers,partials,data) {
    return "c-rform__opponent";
},"27":function(depth0,helpers,partials,data) {
    var stack1;

  return "                                <td class=\"text-center c-rform__weightTd\">"
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.jockey : depth0)) != null ? stack1.weightFormatted : stack1), depth0))
    + "</td>\n";
},"29":function(depth0,helpers,partials,data) {
    var stack1;

  return "                                <td><div class=\"truncate-text\"><div>"
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.jockey : depth0)) != null ? stack1.nameLabel : stack1), depth0))
    + "</div></div></td>\n";
},"31":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.escapeExpression, alias2=this.lambda;

  return "                    <hr class=\"c-rform__hr\">\n                    <p class=\"c-runnerDetails__info c-rform__distance\">"
    + alias1(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_final_distances",{"name":"translate","hash":{},"data":data}))
    + " "
    + alias1(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.form : depth0)) != null ? stack1.result : stack1)) != null ? stack1.distancesLabels : stack1), depth0))
    + " ("
    + alias1(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.form : depth0)) != null ? stack1.result : stack1)) != null ? stack1.numRunners : stack1), depth0))
    + ")</p>\n";
},"33":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "        <div class=\"c-rform__dots\" data-stop-event>\n"
    + ((stack1 = helpers.each.call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.form : depth0)) != null ? stack1.ref : stack1)) != null ? stack1.races : stack1),{"name":"each","hash":{},"fn":this.program(34, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n";
},"34":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "                <div class=\"c-rform__dotWrap\" data-id=\""
    + this.escapeExpression(this.lambda(depth0, depth0))
    + "\">\n                    <span class=\"c-rform__dot "
    + ((stack1 = __default(require("../../components/helpers/handlebar-helpers/compare.js")).call(depth0,((stack1 = ((stack1 = (depths[1] != null ? depths[1].form : depths[1])) != null ? stack1.ref : stack1)) != null ? stack1.current : stack1),"===",depth0,{"name":"compare","hash":{},"fn":this.program(35, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\"></span>\n                </div>\n";
},"35":function(depth0,helpers,partials,data) {
    return "isActive";
},"37":function(depth0,helpers,partials,data) {
    return "        <div class=\"pt2 pb2\">\n            <div class=\"m-race__loader\">\n                <span data-loader-indicator class=\"c-loadingIndicator c-loadingIndicator--bouncer c-loadingIndicator--light isSmall isVisible\">\n                    <span class=\"c-loading__elem1\"></span>\n                    <span class=\"c-loading__elem2\"></span>\n                    <span class=\"c-loading__elem3\"></span>\n                </span>\n            </div>\n        </div>\n";
},"39":function(depth0,helpers,partials,data) {
    return "        <p class=\"c-runnerDetails__info pt1 pb1\">"
    + this.escapeExpression(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"error_occurred",{"name":"translate","hash":{},"data":data}))
    + "</p>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "<div class=\"c-rform__wrap\" data-wrap>\n"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.form : depth0)) != null ? stack1.loaded : stack1),{"name":"if","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.program(37, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.error : depth0),{"name":"if","hash":{},"fn":this.program(39, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true,"useDepths":true});