'use strict';

import _ from 'underscore';
import Backbone from 'backbone';
import moment from 'moment-timezone';
import app from 'components/core/application';
import H2HModel from 'modules/h2h/h2h.model';
import RaceH2hCollection from 'modules/h2h/race-h2h.collection';
import helpers from 'components/helpers/helpers';
import user from 'components/user/user';

/**
 * EventH2h Collection
 * @name EventH2hCollection
 * @constructor
 *
 * @requires Underscore
 * @requires Backbone
 * @requires app
 * @requires helpers
 *
 * @description
 *     EventH2h Collection
 *
 * @returns Function - Constructor function for EventH2h Collection
 */
var EventH2HCollection = Backbone.Collection.extend({

    url: function () {
        return '/ajax/events/details/id/' + this.id;
    },

    model: H2HModel,

    initialize: function(model, options) {
        this.data = {};
        this.id = options.id;
    },

    /**
     * @method parse
     * @description
     *      Format time and odds
     *
     * @param response
     */
    parse: function(response) {
        this.data.event = response.event;

        var races = _.map(response.races, (race, i) => {

            race.titleLabel = this.data.event.title + ' ' + app.interpolate('label_race_number', {'number': race.relatedRaceNumber});
            race.raceNumber = race.relatedRaceNumber;
            race.raceTime = moment.unix(race.postTime).tz(user.timeZone()).format('LT');

            race.runners = _.map(race.runners, function (runner) {
                runner.raceTitle = race.raceTitle;
                runner.oddsFormatted = helpers.formatOdds(runner.winOdds, user.data.oddsFormat, true);
                runner.enabled = race.raceStatus === 'OPN';
                runner.silkUrl = null;
                if (!_.isEmpty(runner.silkExtension) && !_.isEmpty(runner.silkId)) {
                    runner.silkUrl = '//www.racebets.com/cache/img/silks/' + runner.silkId + '_w62.' + runner.silkExtension;
                }

                runner.idRace = race.idRace;
                runner.name = runner.horseName || null;
                runner.programNumber = runner.programNumber || i+1;
                runner.ageFormatted = (runner.age && runner.age > 0) ? app.interpolate('label_year_old_abbr', {'age': runner.age}) : null;
                runner.genderFormatted = runner.gender ? app.polyglot.t('label_gender_short_' + runner.gender) : null;

                if(runner.jockey) {
                    var split = runner.jockey.split(' ');
                    runner.jockey = {
                        firstNameAbbr: split[0] ? split[0].charAt(0) + '.' : '',
                        lastName: split[1] ? split[1] : '',
                        weight: {
                            weightFormatted: helpers.weight(runner.weight, user.getMetricSystem())
                        }
                    };
                }

                if(runner.trainer) {
                    var split = runner.trainer.split(' ');
                    runner.trainer = {
                        firstNameAbbr: split[0] ? split[0].charAt(0) + '.' : '',
                        lastName: split[1] ? split[1] : ''
                    };
                }

                return runner;
            });

            //convert runners into a backbone collection
            race.runners_collection = new RaceH2hCollection(race.runners);

            return race;
        });

        return races;
    }

});

export default EventH2HCollection;
