'use strict';

import Backbone             from 'backbone';
import app                  from 'components/core/application';
import $                    from 'jquery';
import auth                 from 'components/auth/auth';
import user                 from 'components/user/user';
import userSettingsManager  from 'components/user/user.settings-manager';

/**
 * Canvas Constructor
 * @module Canvas
 * @constructor
 *
 * @requires Backbone
 * @requires app
 * @requires auth
 * @requires user
 *
 * @description
 *     Handling eventbinding for application wise interactions.
 *
 * @returns {Function} - Constructor function for canvas
 */
export default Backbone.View.extend({

    el: app.DOM.appCanvas,

    initialize: function () {
        /**
         * @this - Canvas constructor
         */
        app.on('app:mainmenu:toggle', this.toggleMainMenu, this);
        app.on('app:mainmenu:close', this.closeMainMenu, this);
        app.on('app:sidemenu:close', this.closeSideMenu, this);
        app.on('app:menus:close', this.closeMenus, this);
        app.on('user:logout:success', this.closeSideMenu, this);

        //hide login/register buttons for logged in user
        if (user.isLoggedIn) {
            this.loggedInState();
        }
    },

    events: {
        'click [data-logout]': 'logOut',
        'click': 'canvasClick',
        'click [data-overlay]': 'closeMenus'
    },

    /**
     * @method toggleMainMenu
     *
     * @description
     *     Toggling main menu CSS class to open or close the panel.
     */
    toggleMainMenu: function () {
        if (app.menu) {
            this.$el.toggleClass('mainMenuIsOpen');
        }
    },

    /**
     * @method toggleSideMenu
     *
     * @description
     *     Toggling side menu CSS class to open or close the panel.
     */
    toggleSideMenu: function () {
        if (app.sideMenu) {
            if (auth.isUserLoggedIn()) {
                app.trigger('app:sidemenu:stateChanged',{isMenuOpen: this.$el.hasClass('sideMenuIsOpen')});
                this.$el.toggleClass('sideMenuIsOpen');
            } else {
                auth.manageLogin();
            }
        }
    },

    /**
     * @method closeMainMenu
     *
     * @description
     *     Removing CSS class from the canvas to close the main menu panel.
     */
    closeMainMenu: function () {
        this.$el.removeClass('mainMenuIsOpen');
    },

    /**
     * @method closeSideMenu
     *
     * @description
     *     Removing CSS class from the canvas to close the main menu panel.
     */
    closeSideMenu: function () {
        this.$el.removeClass('sideMenuIsOpen');
    },

    /**
     * @method closeMenus
     *
     * @description
     *     Removing CSS class from the canvas to close the main or the side menu panel.
     */
    closeMenus: function() {
        if (this.$el.hasClass('mainMenuIsOpen')) {
            app.trigger('app:mainmenu:close');
        }

        if (this.$el.hasClass('sideMenuIsOpen')) {
            app.trigger('app:sidemenu:close');
        }
    },

    /**
     * @method logOut
     *
     * @description
     *     Logs user out
     */
    logOut: function () {
        auth.logOut();
    },

    loggedInState: function () {
        app.DOM.appHeader.removeClass('app-header--withSubheader');

        //checks if balance should be hidden
        if (userSettingsManager.data.dialog && userSettingsManager.data.dialog.general.hideBalance) {
            app.trigger('app:sidemenu:hidebalance');
        }
    },

    /**
     * @method handleOffmenuInteraction
     *
     * @description
     *     Event handler for offmenu (either main or side) interaction, when menu is open.
     *     // @todo: swipe close menu
     */
    handleOffmenuInteraction: function (e) {
        e.preventDefault();
        e.stopPropagation();
    },

    canvasClick: function (e) {
        app.trigger('canvas:click', e);
    }
});
