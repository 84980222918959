'use strict';
import $                                    from 'jquery';
import _                                    from 'underscore';
import app                                  from 'components/core/application';
import Backbone                             from 'backbone';
import BackboneParentTabbedView             from 'components/core/mixins/Backbone.ParentTabbedView';
import BackboneParentTabbedViewInstance     from 'components/core/mixins/Backbone.ParentTabbedViewInstance';
import BackboneCloseView                    from 'components/core/mixins/Backbone.CloseView';
import template                             from 'modules/mybets/mybets.tpl.hbs';
import OpenView                             from 'modules/mybets/mybets-open';
import SettledView                          from 'modules/mybets/mybets-settled';


/**
 * My Bets view
 *
 * @name MyBets
 * @constructor
 *
 * @requires jQuery
 * @requires Underscore
 * @requires BackboneCloseView
 * @requires template
 *
 * @description
 * 		My Bets view, display all races.
 *
 * @returns {Function} - Constructor function for MyBets
 */

var MyBets = Backbone.View.extend(
    _.extend({}, BackboneCloseView, BackboneParentTabbedView, {
        template: template,

        className: 'l-tabbedView',

        initialize: function (opt) {
            this.options = opt;
            this.initializeTabbedView();

            this.DOM = {};
            this.subViews = {};
            this.defaultActiveTab = 'open';
            //Set subviews
            this.tabViews = {
                'open': OpenView,
                'settled': SettledView
            };
        },

        render: function () {
            this.$el.html(this.template());

            //Cache parent <li> elements for tabs links
            this.tabLinkParentList = this.$el.find('.c-tabs__item');
            //Cache subViewTag tag
            this.subViewTag = this.$(app.SELECTORS.appSubView);

            this.manageSubView();
            return this;
        }

    }), BackboneParentTabbedViewInstance
);

export default MyBets;
