/**
 * Register handlebar string concat method
 *
 * @Example
 * syntax in Handlebars template: {{ concat 'string_prefix' @handlebars_key '_string_postfix' }}
 *
 */
export default function () {
    var arr = Array.prototype.slice.call(arguments);
    arr.pop();
    return arr.join('');
};
