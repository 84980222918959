var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
    return "isLoggedOut";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "<div class=\"isAudio isAudioPreview "
    + ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0.isLoggedIn : depth0),{"name":"unless","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\">\n    <div class=\"m-media\">\n        <audio id=\""
    + alias3(((helper = (helper = helpers.audioPreviewElemID || (depth0 != null ? depth0.audioPreviewElemID : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"audioPreviewElemID","hash":{},"data":data}) : helper)))
    + "\" class=\"video-js vjs-default-skin\" controls height=\"30\">\n            <source src=\"/rest/v2/audiopreview/"
    + alias3(((helper = (helper = helpers.idRace || (depth0 != null ? depth0.idRace : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"idRace","hash":{},"data":data}) : helper)))
    + "/"
    + alias3(((helper = (helper = helpers.lang || (depth0 != null ? depth0.lang : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"lang","hash":{},"data":data}) : helper)))
    + "\" type='audio/mpeg'>\n        </audio>\n    </div>\n</div>\n";
},"useData":true});