import $                         from 'jquery';
import _                         from 'underscore';
import app                       from 'components/core/application';
import user                      from 'components/user/user';
import helpers                   from 'components/helpers/helpers';
import { ENV_BRAND }             from 'components/app.settings';
import { getCookieDefaultState } from 'modules/cookie-settings/marketing-cookie-settings.service';

var depositOpened = false;

function getGUID() {
    return user.data.loggedIn ? user.data.idAccount : '00000000-0000-0000-0000-000000000000';
}

/**
 * Create lobby object by path
 */
function getLobby(path = '', name = '') {
    // Prepare 2 levels of path without search params
    const [level1, level2 = ''] = path.split('/', 2).reduce((levels, level) => {
        const [l] = level.split('?');
        levels.push(l);
        return levels;
    }, []);

    // Default lobby object
    const lobby = {
        Lobby: false,
        LobbyName: 'GENERIC'
    };

    // 404 page has no URL hence we identify by controller
    if (name === 'page404') {
        lobby.LobbyName = 'PAGE_NOT_FOUND';
        return lobby;
    }

    // HOME when home or fantasy controller is active
    if ((name === 'home' && ENV_BRAND !== 'suaposta') || name === 'fantasy') {
        lobby.LobbyName = 'HOME';

        // Lobby is every product AND the home page but NOT including subpages of those
        if (['', 'home', 'horse-racing'].includes(level1) && level2 === '') {
            lobby.Lobby = true;
        }
        return lobby;
    }

    // if (window.location.pathname === '/fantasy/betssonfc/futebol/lobby/fantasy/admin_created/all/all/waiting') {
    //     lobby.Lobby = true;
    // }

    // Identify the rest of lobby names
    switch (level1) {
        case 'event':
        case 'race':
        case 'fantasy':
        case 'extras':
        case 'upcoming':
        case 'next-h2h':
        case 'livestreams':
        case 'home':
        case 'horse-racing':
            lobby.LobbyName = 'HORSE_RACING';
            break;
        case 'login':
            lobby.LobbyName = 'LOGIN';
            break;
        case 'bonus':
            lobby.LobbyName = 'BONUSES';
            break;
        case 'deposit':
            lobby.LobbyName = 'DEPOSIT';
            break;
        case 'offers':
            lobby.LobbyName = 'PROMOTIONS';
            break;
        case 'withdrawal':
            lobby.LobbyName = 'WITHDRAW';
            break;
        case 'cookie-settings':
            lobby.LobbyName = 'ADMIN';
            break;
        case 'register':
            lobby.LobbyName = getSecondLevelLobbyName(level2) || 'REGISTER';
            break;
        case 'transactions':
        case 'bets':
        case 'account':
            lobby.LobbyName = getSecondLevelLobbyName(level2) || 'MY_ACCOUNT';
            break;
        case 'info':
            lobby.LobbyName = getSecondLevelLobbyName(level2) || 'INFO';
            break;
        default:
            lobby.LobbyName = 'GENERIC';
    }

    return lobby;
}

/**
 * Identify lobby name based on second level path
 */
function getSecondLevelLobbyName(l = '') {
    switch (l) {
        case 'terms-conditions':
            return 'TERMS_AND_CONDITIONS';
        case 'cookie-policy':
            return 'COOKIE_POLICY';
        case 'privacy-policy':
            return 'PRIVACY_POLICY';
        case 'responsible-gaming':
        case 'restrictions':
            return 'RESPONSIBLE_GAMING';
        case 'forgotten':
            return 'FORGOTTEN_PASSWORD';
        case 'password':
            return 'RESET_PASSWORD';
        case 'marketing-settings':
        case 'consent-required':
            return 'ADMIN';
        case 'verification':
        case 'kyc':
            return 'KYC_BAR';
        case 'email':
            return 'ACTIVATE_ACCOUNT';
        default:
            return null;
    }
}

function virtualPageView(route) {

    const lobby = getLobby(route.current, route.name);

    TagManager.push({
        ...lobby,
        'VirtualUrl': `/${route.current}`,//${location.search}`,
        'VirtualTitle': document.title, // TODO get subheader title
        'event': 'VirtualPageView',
    });

    // Payment Funnel: Open Deposit Page
    if (/deposit/.test(route.current)) {
        if (!depositOpened) {
            TagManager.push({
                'GUIDConfirm': getGUID(),
                'event': 'OpenDepPage'
            });
        }
        depositOpened = true;
    } else {
        depositOpened = false;
    }
}

function prepareAmount(amount) {
    let _amount = parseFloat(amount);
    if (user.data.currency !== 'EUR') {
        _amount = helpers.exchange(_amount, user.data.currency, 'EUR');
    }
    return _amount;
}

function depositSuccess(data) {
    TagManager.push({
        'Method': data.method,
        'Currency': 'Euros',
        'Amount': prepareAmount(data.amount),
        'PublicPaymentId': data.publicPaymentId,
        'event': data.ndc ? 'FirstDeposit' : 'ConfirmedDeposit'
    }, data.callback);

}

function loginError(resp) {
    let responseText = $.parseJSON(resp.responseText);
    TagManager.push({
        'Error': responseText.data.code,
        'event': 'LoginFailed'
    });
}

function forgottenDetails() {
    TagManager.push({
        'event': 'ForgottenPassword'
    });
}

var TagManager = {

    init: function () {
        if (!window.dataLayer) {
            throw new Error('Window.dataLayer is not defined!');
        }

        // Virtual Page View
        app.router.on('after:route:change', virtualPageView);

        // Payment Funnel: NDC and RDC
        app.on('deposit:successful', depositSuccess);

        // Registration Funnel: Open Registration Page
        app.on('registration:open', (noHash) => {
            if (noHash) {
                TagManager.push({
                    'event': 'OpenRegPage'
                });
            }
        });

        app.on('registration:fieldchange', (name) => {
            TagManager.push({
                'FieldName': name,
                'event': 'RegistrationFieldChange'
            });
        });

        // Registration Funnel: Account Details Submit
        app.on('registration:accountdetails:submit', () => {
            TagManager.push({
                'event': 'AccountDetailsSubmitted'
            });
        });

        // Registration Funnel: Account Details Failed
        app.on('registration:accountdetails:failed', (error) => {
            TagManager.push({
                'Error': error.message,
                'event': 'FailedAccountDetails'
            });
        });

        // Registration Funnel: Registration Submit
        app.on('registration:personaldetails:submit', () => {
            TagManager.push({
                'event': 'RegistrationSubmitted'
            });
        });

        // Registration Funnel: Registration Failed
        app.on('registration:personaldetails:failed', (error) => {
            TagManager.push({
                'Error': error.message,
                'event': 'FailedRegistration'
            });
        });

        // Registration Funnel: Registration Success
        // Login Funnel: first user login
        app.on('registration:success', ({GUID, Age, Sex, Location, PartnerID}) => {
            let eventData = {
                GUID,
                Age,
                Sex,
                Location,
                'event': 'ConfirmedRegistration'
            };
            if (PartnerID) eventData['PartnerID'] = PartnerID;
            TagManager.push(eventData);
        });

        // Registration Funnel: Registration Verified
        app.on('registration:verified', (idAccount, cb=null) => {
            TagManager.push({
                'GUID': idAccount || getGUID(),
                'event': 'VerifiedRegistration'
            }, cb);
        });

        // Registration Funnel: Mobile number verification submit
        app.on('registration:tempaccount:submit', () => {
            TagManager.push({
                'event': 'TemporaryAccountSubmitted'
            });
        });

        // Registration Funnel: Mobile number verification / Temporary account confirmed
        app.on('registration:tempaccount:verified', () => {
            TagManager.push({
                'GUID': getGUID(),
                'event': 'ConfirmedTemporaryAccount'
            });
        });

        // Registration Funnel: Mobile number verification / Temporary account failed
        // app.on('registration:tempaccount:failed', (error) => {
        //     TagManager.push({
        //         'Error': error.message,
        //         'event': 'FailedTemporaryAccount'
        //     });
        // });

        // Track home page visits
        // Deprecated, kept it for backwards compatibility until
        // updated tracking points (to HOME lobby) are confirmed by Niki Caruana
        app.on('home:initialize', () => {
            TagManager.push({
                'event': 'HomeVisits'
            });
        });

        // Login Funnel: first time login
        app.on('user:login:first', (idAccount, cb=null) => {
            TagManager.push({
                'GUID': idAccount || getGUID(),
                'event': 'FirstLogin'
            }, cb);
        });

        // Login Funnel: existing user login
        app.on('user:login:success', (resp) => {
            TagManager.push({
                'GUID': resp.data.idAccount,
                'event': 'LoggedInExisting'
            });
        });

        // Login Funnel: failed login
        app.on('user:login:error', loginError);

        // Login Funnel: login intention
        app.on('dialog:login', () => {
            TagManager.push({
                'event': 'LoginIntention'
            });
        });

        // Login Funnel: forgotten password or username
        app.on('forgottenDetails', forgottenDetails);
        app.on('forgottenPassword', forgottenDetails);
        app.on('forgottenUsername', forgottenDetails);

        // Login Funnel: password reset request
        app.on('user:password:request', () => {
            TagManager.push({
                'event': 'LoginPasswordFormSubmit'
            });
        });

        // Login Funnel: password reset success
        app.on('user:password:reset', () => {
            TagManager.push({
                'event': 'LoginPasswordChange'
            });
        });

        // Login Funnel: username request
        app.on('user:username:request', () => {
            TagManager.push({
                'event': 'LoginUsernameFormSubmit'
            });
        });

        // Bet Funnel: bet place click
        app.on('bet:placed:click', function ({BetCategory, BetType}) {
            TagManager.push({
                BetCategory,
                BetType,
                'event': 'PlaceBetClick'
            });
        });

        // Bet Funnel: bet place confirmed
        app.on('bet:placed:confirmed', function ({BetCategory, BetType, BetMarket, CouponID, TotalStake}) {
            TagManager.push({
                BetCategory,
                BetType,
                BetMarket,
                CouponID,
                'TotalStake': prepareAmount(TotalStake),
                'event': 'BetConfirmed'
            });
        });

        // Bet Funnel: bet place failed
        app.on('bet:placed:failed', function (Error) {
            TagManager.push({
                Error,
                'event': 'BetFailed'
            });
        });

        app.on('user:withdrawal:success', () => {
            TagManager.push({
                'Status': 'Successful',
                'event': 'Withdrawal'
            });
        });

        app.on('user:withdrawal:error', () => {
            TagManager.push({
                'Status': 'Failed',
                'event': 'Withdrawal'
            });
        });

        app.on('marketing:cookie:accepted', () => {
            TagManager.push({
                'event': 'MarketingConsent'
            });
        });

        app.on('marketing:gdpr:consent', (consents, accepted) => {
            let data = {
                'event': 'GDPRConsent',
                'gtm_is_cookie_consent_disabled': '0',
                'gdpr_performance_enabled': consents.performance.toString(),
                'gdpr_targeting_enabled': consents.targeting.toString(),
                'gdpr_functional_enabled': consents.functional.toString()
            };

            let defaultCookie = getCookieDefaultState();

            if(accepted.performance && accepted.performance !== parseInt(defaultCookie.performance)) {
                data.GDPRPerformanceAccepted = '1';
            }

            if(accepted.targeting && accepted.targeting !== parseInt(defaultCookie.targeting)) {
                data.GDPRTargetingAccepted = '1';
            }

            if(accepted.functional && accepted.functional !== parseInt(defaultCookie.functional)) {
                data.GDPRFunctionalAccepted = '1';
            }

            TagManager.push(data);
        });

        app.on('marketing:gdpr:consent:rejected', (consents) => {
            TagManager.push({
                'gtm_is_cookie_consent_disabled': '0',
                'gdpr_performance_enabled': consents.performance.toString(),
                'gdpr_targeting_enabled': consents.targeting.toString(),
                'gdpr_functional_enabled': consents.functional.toString()
            });
        });
    },

    push: function (e, cb) {
        if (_.isArray(e)) {
            for (let i = 0; i < e.length; i++) {
                dataLayer.push(e[i]);
            }
        } else {
            dataLayer.push(e);
        }
        if (cb && typeof cb === 'function') {
            cb();
        }
    }

};

export default TagManager;
