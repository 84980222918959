'use strict';
import _                   from 'underscore';
import $                   from 'jquery';
import Backbone            from 'backbone';
import helpers             from 'components/helpers/helpers';
import BackboneCloseView   from 'components/core/mixins/Backbone.CloseView';

/**
 * Backbone select styled component view Controller
 * @name cSelectStyled
 * @constructor
 *
 * @requires Backbone
 *
 * @description
 *     Responsive Backbone cSelectStyled component that allows images as options values
 *
 * @returns Function - Constructor function for Backbone cSelectStyled component view
 */

var cSelectStyled = Backbone.View.extend(
    _.extend({}, BackboneCloseView, {

        className: 'c-selectStyled',

        events: {
            'click [data-toggle]' : 'onToggle',
            'click [data-option-key]' : 'onSelected'
        },

        initialize: function(settings) {
            if(!settings.template) throw new Error('Template is required');

            if(settings.options && !_.isArray(settings.options)) throw new Error('Options should be an array');

            this.val = null;
            this.id = settings.id || null;
            this.template = settings.template;
            this.initial_options = settings.options || null;
            this.options = helpers.deepCopy(this.initial_options);
            this.selected = settings.selected || null;
            this.event = settings.event || null;
            this.dataAttributes = (settings.dataAttributes) ? settings.dataAttributes.join(' ') : null;
        },

        render: function() {
            this.setSelected();
            this.$el.html(this.template({
                options: this.options,
                selected: this.selected,
                id: this.id,
                dataAttributes: this.dataAttributes
            }));

            this.DOM = {
                arrow: this.$('[data-arrow]')
            };

            return this;
        },

        setSelected: function() {
            this.options = helpers.deepCopy(this.initial_options);
            if(this.selected) {
                this.options = this.options.filter(value => {
                    return value.key !== this.selected.key;
                });
            } else {
                this.selected = this.options[0];
                this.options.shift();
            }
        },

        onToggle: function(e) {
            e.preventDefault();
            e.stopPropagation();
            $(e.currentTarget).toggleClass('c-selectStyled--open');
        },

        onSelected: function(e) {
            e.preventDefault();
            this.selected = this.getSelected($(e.currentTarget).attr('data-option-key'));
            this.render();

            if(this.event) this.trigger(this.event, this.selected);
        },

        getSelected: function(key) {
            return this.initial_options.find(value => {
                return value.key === key;
            });
        }

    })
);

export default cSelectStyled;
