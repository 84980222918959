'use strict';

import _            from 'underscore';
import Backbone     from 'backbone';
import template     from 'components/action_sheet/action_sheet.tpl.hbs';
import helpers      from 'components/helpers/helpers';

/**
 * Action sheet view controller
 */
var ActionSheetView = Backbone.View.extend({

    className: 'c-actionSheet',

    template: template,

    options: {
        closeOnOutsideClick: true,
        closeOnRouteChange: true,
        animation: 'c-actionSheet--in'
    },

    events: {
        'click': 'stopPropagation'
    },

    stopPropagation: function(e) {
        e.stopPropagation();
    },

    initialize: function(opt) {
        _.extend(this.options, opt || {});

        if(!this.options.actions) {
            throw Error('At least one action should be specified');
        }

        //set up events dynamic
        this.delegateEvents(_.extend(this.events, this.prepareEvents()));
        this.render();
    },

    render: function() {
        this.$el.html(this.template(this.options));
        return this;
    },

    prepareEvents: function() {
        var self = this;
        var events = {};
        var actions = _.flatten(this.options.actions);
        for (var i = 0, l = actions.length; i < l; i++) {
            var el = actions[i];
            var fn = 'func' + helpers.randomName();
            events[el.event + ' ' + el.selector] = fn;
            //register callback for event
            if(typeof el.cb !== 'function') {
                throw Error('Callback should be a functions');
            }
            self[fn] = el.cb;
        }

        return events;
    },

    close: function() {
        this.remove();
        this.undelegateEvents();
    }
});

export default ActionSheetView;
