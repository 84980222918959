'use strict';

import _                    from 'underscore';
import Backbone             from 'backbone';
import template             from 'components/snack-bar/snack-bar.tpl.hbs';
import BackboneCloseView    from 'components/core/mixins/Backbone.CloseView';

/**
 * SnackBar view
 *
 * @name SnackBar
 * @constructor
 *
 * @requires underscore
 * @requires Backbone
 * @requires template
 * @requires BackboneCloseView
 *
 * @description
 *     SnackBar view Controller.
 *
 * @returns {Function} - Constructor function for SnackBar
 */

 const SNACK_BAR_VIEW_EVENTS = {
    CLOSE: 'snackBar:close'
}

const SnackBar = Backbone.View.extend(
    _.extend({}, BackboneCloseView, {

        className: 'c-snackBar',

        template,

        render(data=null) {
            const { content = 'Default content' } = data || this.data;
            this.$el.html(this.template({ content }));

            return this;
        }
    })
);

export { SNACK_BAR_VIEW_EVENTS, SnackBar };