'use strict';

import _                    from 'underscore';
import app                  from 'components/core/application';
import auth                 from 'components/auth/auth';
import FixedDetailsModel    from 'components/betting/fixed_details.model';
import FreeBetsCollection   from 'components/betting/free_bets.collection';
import helpers              from 'components/helpers/helpers';
import user                 from 'components/user/user';
import betslipHelpers       from 'components/betting/betslip.helpers'

/**
 * Betting service API module
 *
 * @type {{Object}}
 */
var bettingService = {

    showQuickbet: function (opt) {
        var fixedModel;
        var freebetsModel;
        var runnerModel = opt.runnerModel;
        var raceCollection = opt.attributes.raceCollection;

        if(app.betslip.isInUpdateStatus() && app.betslip.show()) {
            this.showBetSlip({
                attributes: {}
            });
            return;
        }

        var action = () => {
            // if (app.betslip.isEmpty() || opt.attributes.betCategory === 'TOT' || (opt.attributes.isH2H  || runnerModel.attributes.specialBetType === 'H2H') || runnerModel.attributes.isAntePost) {
            if (this.shouldShowDialog(opt.attributes.betCategory, opt.attributes.betType)
                    || (opt.attributes.isSpecialBet && !opt.attributes.isHeadToHead)
                    || betslipHelpers.noH2HinBetslip(fixedModel, app.betslip.getBets(true))
                    || runnerModel.attributes.isAntePost) {
                let timeout = helpers.device.isIOS ? 200 : 0;

                if (!app.betslip.isEmpty() && opt.buttonView) {
                    opt.buttonView.trigger('button:deselect');
                }

                setTimeout(() => {
                    app.overlay.quickbet({
                        closeOnOutsideClick: true,
                        attributes: opt.attributes,
                        fixedModel: fixedModel,
                        runnerModel: runnerModel,
                        freebetModel: freebetsModel
                    });
                    app.overlay.component.render();
                }, timeout);

            } else {
                //notice: the order of 'runnerModel' abd 'fixedModel' objects as they are passed to 'extend' has be swapped. 'fixedModel' used to be the first argument.
                //This was done after h2h were allowed to be added to betslip because 'runnerModel' has a different 'programNumber' for the same runner. In h2h runner has different 'programNumber'
                //But that leads to overwriting 'betTypes' structure. To avoid this we do the following
                fixedModel.attributes.betTypes = helpers.deepCopy(runnerModel.attributes.betTypes);
                app.betslip.addBet(_.extend(runnerModel.attributes, fixedModel.attributes, opt.attributes));
            }
        };

        let getFixedDetails = () => {
            fixedModel = new FixedDetailsModel({idRunner: opt.attributes.idRunner, betType: opt.attributes.betType});

            //for logged in user only
            // Free bets allowed for BOK/FXD WIN/WP bets only
            if (user.isLoggedIn && _.contains(['BOK', 'FXD'], opt.attributes.betCategory) && _.contains(['WIN', 'WP'], opt.attributes.betType)) {
                fixedModel.on('sync', getFreeBet, this);
            } else {
                fixedModel.on('sync', action, this);
            }

            // fixedModel.on('error', processError, this);
            fixedModel.fetch({betslip: !app.betslip.isEmpty() && (!opt.attributes.betCategory === 'TOT' || !opt.attributes.isHeadToHead)});
        };

        var getFreeBet = function () {
            freebetsModel = new FreeBetsCollection([], {
                idRace: fixedModel.attributes.idRace,
                idRunner: fixedModel.attributes.idRunner,
                betXprogramNumber: fixedModel.attributes.programNumber,
                betXname: fixedModel.attributes.name,
                raceCollection
            });
            freebetsModel.on('sync', action, this);
            // freebetsModel.on('error', processError, this);
            freebetsModel.fetch({betslip: !app.betslip.isEmpty() && (!opt.attributes.betCategory === 'TOT' || !opt.attributes.isHeadToHead)});
        };

        getFixedDetails();
    },

    shouldShowDialog: function(betCategory, betType) {
        return app.betslip.isEmpty() || betCategory === 'TOT' || ['WS', 'PS', 'WPS'].includes(betType);
    },

    showQuickbetMulti: auth.ifLoggedIn(function (opt) {
        if(app.betslip.isInUpdateStatus() && app.betslip.show()) {
            this.showBetSlip({
                attributes: {}
            });
            return;
        }

        let showDialog = () => {
            let timeout = helpers.device.isIOS ? 200 : 0;
            setTimeout(() => {
                app.overlay.quickbetMulti({
                    closeOnOutsideClick: true,
                    attributes: opt.attributes
                });
                app.overlay.component.render();
            }, timeout);
        };

        showDialog();
    }, this),

    showBetSlip: function (opt) {
        let timeout = helpers.device.isIOS ? 200 : 0;
        setTimeout(() => {
            app.overlay.betslip({closeOnRouteChange: true});
            app.overlay.component.render();
        }, timeout);
    },

    toggleBetslip: function (attributes = {}) {
        if(app.betslip.isOpen()) {
            app.overlay.hideBetslip();
        } else if (app.betslip.show()) {
            this.showBetSlip({
                attributes: attributes
            });
        }
    },
};

export default bettingService;
