'use strict';

import $              from 'jquery';
import _              from 'underscore';
import Backbone       from 'backbone';
import {
    captureMessage,
    withScope,
}                     from '@sentry/browser';
import app            from 'components/core/application';
import user           from 'components/user/user';
import moment         from 'moment-timezone';
import Storage        from 'components/helpers/serviceFactory';
import helpers        from 'components/helpers/helpers';

/**
 * AjaxPrefilter
 * @constructor
 *
 * @requires $
 * @requires _
 * @requires Backbone
 * @requires app
 * @requires User
 *
 * @description
 *  Overrides Ajax request options
 */
export default function () {

    /**
     * @property cachedUrls
     * @description
     *  Preset array of urls to be prefixed with ajax host if exists
     *
     * @type {string[]}
     */
    this.cachedUrls = [
        '/rest/v1/events/sidebar',
        '/ajax/media/streams',
        '/ajax/events/details/id',
        '/ajax/races/details/id'
    ];

    this.language = user.data.language.toLowerCase();

    /**
     * @method matchesPresetUrl
     * @description
     *  Checks if given url matches any of the preset urls
     *
     * @param url
     * @returns {String|Undefined} - returns url if it matches any of the preset urls or undefined if no match found.
     */
    this.matchesPresetUrl = function (url) {
        return _.find(this.cachedUrls, (item)=> {
            return url.indexOf(item) > -1;
        });
    };

    /**
     * @method getUrl
     * @description
     *  Modifies given url if it matches any of the preset urls and if ajaxHost provided
     *
     * @param url
     * @returns {String}
     */
    this.getUrl = function (options) {
        if (user.data.ajaxHost && this.matchesPresetUrl(options.url)) {
            options.crossDomain = true;
            options.timeout = 5000;
            return `${user.data.ajaxHost + options.url}?lang=${this.language}`;
        }

        options.crossDomain = false;
        //set this prop to 'false' since it may have been set to 'true' if previous request with ajaxHost failed.
        options.ajaxHostFailure = false;
        return options.url;
    };

    this.ajaxHostAvailable = function (options) {
        return !options.ajaxHostFailure;
    };

    /**
     * Measure time between ajax requests
     */
    this.controlRequestFrequency = function(options, jqXHR) {
        var new_time = moment();

        //check time difference for specific urls only
        if(['/ajax/mbetslip/send/', '/rest/v2/betting/betslip'].indexOf(options.url) > -1) {
            if(this._prev_time && this._prev_bet) {
                var currentBet = helpers.deepCopy(Storage.getByNameOrCreate('Bet').get('data'));
                var difference = new_time.diff(this._prev_time, 'seconds');

                //capture cases when time difference between two requests is less or equals to 3 sec.
                if(difference <= 3) {
                    //abort request if bets are identical
                    if(_.isEqual(this._prev_bet, currentBet)) {
                        jqXHR.abort();

                        withScope(scope => {
                            scope.setExtra("difference", difference);
                            scope.setExtra("url", options.url);

                            captureMessage('Betting request aborted. Identical bet data under 3 seconds.', 'error');
                        });
                    }
                } else {
                    this._prev_time = null;
                    this._prev_bet = currentBet;
                }
            } else {
                this._prev_time = new_time;
                this._prev_bet = helpers.deepCopy(Storage.getByNameOrCreate('Bet').get('data'));
            }
        }
    };

    /**
     * @method ajaxPrefilter
     * @description
     *  Override to intercept ajax call and make necessary changes to url or any other jqXHR options
     *
     *  @url http://api.jquery.com/jquery.ajaxprefilter
     *  @param args (options, originalOptions, jqXHR)
     */
    $.ajaxPrefilter((...args) => {
        let options = args[0];
        let jqXHR = args[2];

        //default timeout
        options.timeout = 0;

        if(options.url && typeof options.url === 'string' && options.url.indexOf('https://viacep.com.br/ws/') > -1) {
            options.headers = {};
        }

        if (this.ajaxHostAvailable(options)) {
            options.url = this.getUrl(options);
        }

        this.controlRequestFrequency(options, jqXHR);
    });
};
