var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
    var stack1;

  return "    <div class=\"c-dataList__cell "
    + ((stack1 = helpers.unless.call(depth0,((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.silkUrl : stack1),{"name":"unless","hash":{},"fn":this.program(2, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " text-center\" style=\"width:"
    + this.escapeExpression(this.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.th : depth0)) != null ? stack1.number : stack1)) != null ? stack1.width : stack1), depth0))
    + "\">\n"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.isGreyhound : stack1),{"name":"if","hash":{},"fn":this.program(4, data, 0),"inverse":this.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + "    </div>\n";
},"2":function(depth0,helpers,partials,data) {
    return "c-dataList__cell--alignTop";
},"4":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "            <div class=\"c-saddle isRaceTypeD--"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.event : stack1)) != null ? stack1.country : stack1), depth0))
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.programNumber : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.programNumber : stack1), depth0))
    + "</div>\n";
},"6":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.th : depth0)) != null ? stack1.data : stack1)) != null ? stack1.minimal : stack1),{"name":"if","hash":{},"fn":this.program(7, data, 0),"inverse":this.program(12, data, 0),"data":data})) != null ? stack1 : "");
},"7":function(depth0,helpers,partials,data) {
    var stack1;

  return "                <div class=\"m-race__runnerInfo\">\n"
    + ((stack1 = helpers.unless.call(depth0,((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.isAntePost : stack1),{"name":"unless","hash":{},"fn":this.program(8, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                </div>\n";
},"8":function(depth0,helpers,partials,data) {
    var stack1;

  return "                        <div class=\"c-number m-race__runnerNum "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.event : stack1)) != null ? stack1.saddleColours : stack1),{"name":"if","hash":{},"fn":this.program(9, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\">"
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.programNumber : stack1), depth0))
    + "</div>\n";
},"9":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "c-saddle is"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.event : stack1)) != null ? stack1.raceType : stack1), depth0))
    + ((stack1 = helpers["if"].call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.event : stack1)) != null ? stack1.usSaddleColours : stack1),{"name":"if","hash":{},"fn":this.program(10, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.programNumber : stack1), depth0));
},"10":function(depth0,helpers,partials,data) {
    return "-US";
},"12":function(depth0,helpers,partials,data) {
    var stack1;

  return "                <div class=\"m-race__runnerInfo\">\n"
    + ((stack1 = helpers.unless.call(depth0,((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.isAntePost : stack1),{"name":"unless","hash":{},"fn":this.program(8, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                    <div class=\"m-race__silk\" "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.silkUrl : stack1),{"name":"if","hash":{},"fn":this.program(13, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "></div>\n                </div>\n";
},"13":function(depth0,helpers,partials,data) {
    var stack1;

  return " style=\"background-image:url('"
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.silkUrl : stack1), depth0))
    + "')\" ";
},"15":function(depth0,helpers,partials,data) {
    return "v-align-t";
},"17":function(depth0,helpers,partials,data) {
    return "pl1";
},"19":function(depth0,helpers,partials,data) {
    var stack1;

  return "                    <div>\n                        "
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.name : stack1), depth0))
    + "\n                    </div>\n";
},"21":function(depth0,helpers,partials,data) {
    var stack1;

  return "                    <div>\n                        "
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.name : stack1), depth0))
    + "\n"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.isAbroad : stack1),{"name":"if","hash":{},"fn":this.program(22, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.featuredHorse : stack1)) != null ? stack1.isStar : stack1),{"name":"if","hash":{},"fn":this.program(24, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.h2hEvent : stack1),{"name":"if","hash":{},"fn":this.program(26, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                    </div>\n";
},"22":function(depth0,helpers,partials,data) {
    var stack1;

  return "                            <i class=\"c-flag isCountry"
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.isAbroad : stack1), depth0))
    + " m-abroad__countryFlag m-abroad__countryFlag--runner\"></i>\n";
},"24":function(depth0,helpers,partials,data) {
    return "                            <span class=\"rb-icon rb-icon--star m-race__runnerStar\"></span>\n";
},"26":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.escapeExpression;

  return "                            <span class=\"c-badge c-badge--orange m-race__h2hBadge m-race__h2hBadge-"
    + alias1(this.lambda(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.h2hEvent : stack1), depth0))
    + "\">"
    + alias1(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_h2h_short",{"name":"translate","hash":{},"data":data}))
    + "</span>\n";
},"28":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.event : stack1)) != null ? stack1.isAntePost : stack1),{"name":"unless","hash":{},"fn":this.program(29, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"29":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "                <div class=\"c-dataList__el--truncatedText\">\n                    <div class=\"m-race__subContent "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.th : depth0)) != null ? stack1.number : stack1),{"name":"if","hash":{},"fn":this.program(17, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\"><span>"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.ageFormatted : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.genderFormatted : stack1), depth0))
    + " "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.ppString : stack1),{"name":"if","hash":{},"fn":this.program(30, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</span></div>\n                </div>\n";
},"30":function(depth0,helpers,partials,data) {
    var stack1;

  return "("
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.ppString : stack1), depth0))
    + ")";
},"32":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.event : stack1)) != null ? stack1.isAntePost : stack1),{"name":"unless","hash":{},"fn":this.program(33, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"33":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.escapeExpression, alias2=this.lambda;

  return "        <div class=\"table-row\">\n            <div class=\"table-cell\" data-arrow>\n                <div class=\"c-grid--noGutter\">\n                    <div class=\"c-grid__col is-1-2 is-ls-1-4\">\n                        <div class=\"truncate-text m-race__subContent "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.th : depth0)) != null ? stack1.number : stack1),{"name":"if","hash":{},"fn":this.program(17, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\">\n                            <div>\n"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.reserve : stack1),{"name":"if","hash":{},"fn":this.program(34, data, 0),"inverse":this.program(36, data, 0),"data":data})) != null ? stack1 : "")
    + "                            </div>\n                        </div>\n                        <div class=\"truncate-text m-race__subContent "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.th : depth0)) != null ? stack1.number : stack1),{"name":"if","hash":{},"fn":this.program(17, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\">\n                            <div>"
    + alias1(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_abbr_trainer",{"name":"translate","hash":{},"data":data}))
    + ": "
    + alias1(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.trainer : stack1)) != null ? stack1.firstNameAbbr : stack1), depth0))
    + " "
    + alias1(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.trainer : stack1)) != null ? stack1.lastName : stack1), depth0))
    + "</div>\n                        </div>\n                    </div>\n                    <div class=\"c-grid__col is-1-2 is-ls-1-4\">\n"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.jockey : stack1)) != null ? stack1.weight : stack1)) != null ? stack1.weight : stack1),{"name":"if","hash":{},"fn":this.program(39, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.postPosition : stack1),{"name":"if","hash":{},"fn":this.program(41, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                    </div>\n                    <div class=\"c-grid__col is-ls-2-4 hidden-v\">\n"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.sire : stack1),{"name":"if","hash":{},"fn":this.program(43, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.dam : stack1),{"name":"if","hash":{},"fn":this.program(45, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n            </div>\n"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.finalPosition : stack1),{"name":"if","hash":{},"fn":this.program(48, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n";
},"34":function(depth0,helpers,partials,data) {
    return "                                    "
    + this.escapeExpression(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_reserve",{"name":"translate","hash":{},"data":data}))
    + "\n";
},"36":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.jockey : stack1)) != null ? stack1.firstNameAbbr : stack1),{"name":"if","hash":{},"fn":this.program(37, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"37":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.escapeExpression, alias2=this.lambda;

  return "                                        "
    + alias1(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_abbr_jockey",{"name":"translate","hash":{},"data":data}))
    + ": "
    + alias1(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.jockey : stack1)) != null ? stack1.firstNameAbbr : stack1), depth0))
    + " "
    + alias1(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.jockey : stack1)) != null ? stack1.lastName : stack1), depth0))
    + "\n";
},"39":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.escapeExpression;

  return "                            <div class=\"truncate-text m-race__subContent "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.th : depth0)) != null ? stack1.number : stack1),{"name":"if","hash":{},"fn":this.program(17, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\">\n                                <div>"
    + alias1(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_abbr_weight",{"name":"translate","hash":{},"data":data}))
    + ": "
    + alias1(this.lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.jockey : stack1)) != null ? stack1.weight : stack1)) != null ? stack1.weightFormatted : stack1), depth0))
    + "</div>\n                            </div>\n";
},"41":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.escapeExpression;

  return "                            <div class=\"truncate-text m-race__subContent "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.th : depth0)) != null ? stack1.number : stack1),{"name":"if","hash":{},"fn":this.program(17, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\">\n                                <div>"
    + alias1(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_abbr_box",{"name":"translate","hash":{},"data":data}))
    + ": "
    + alias1(this.lambda(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.postPosition : stack1), depth0))
    + "</div>\n                            </div>\n";
},"43":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.escapeExpression;

  return "                            <div class=\"truncate-text m-race__subContent "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.th : depth0)) != null ? stack1.number : stack1),{"name":"if","hash":{},"fn":this.program(17, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\">\n                                <div>"
    + alias1(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_sire_short",{"name":"translate","hash":{},"data":data}))
    + ": "
    + alias1(this.lambda(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.sire : stack1), depth0))
    + "</div>\n                            </div>\n";
},"45":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.escapeExpression;

  return "                            <div class=\"truncate-text m-race__subContent "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.th : depth0)) != null ? stack1.number : stack1),{"name":"if","hash":{},"fn":this.program(17, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\">\n                                <div>"
    + alias1(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_dam_short",{"name":"translate","hash":{},"data":data}))
    + ": "
    + alias1(this.lambda(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.dam : stack1), depth0))
    + " "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.damSire : stack1),{"name":"if","hash":{},"fn":this.program(46, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n                            </div>\n";
},"46":function(depth0,helpers,partials,data) {
    var stack1;

  return "("
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.damSire : stack1), depth0))
    + ")";
},"48":function(depth0,helpers,partials,data) {
    var stack1;

  return "                <div class=\"table-cell\">\n                    <div class=\"c-post\">\n                        "
    + this.escapeExpression(this.lambda(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.finalPosition : stack1), depth0))
    + "\n                        <i class=\"rb-icon rb-icon--circle-thin\"></i>\n                    </div>\n                </div>\n";
},"50":function(depth0,helpers,partials,data) {
    var stack1;

  return "    <div class=\"c-dataList__cell text-center\" style=\"width:"
    + this.escapeExpression(this.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.th : depth0)) != null ? stack1.odds : stack1)) != null ? stack1.width : stack1), depth0))
    + "\">\n        "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.betting : stack1)) != null ? stack1.oddsAsInfo : stack1),{"name":"if","hash":{},"fn":this.program(51, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n    </div>\n";
},"51":function(depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"c-badge-small m-race__oddsBadge\">"
    + this.escapeExpression(this.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.betting : stack1)) != null ? stack1.oddsAsInfo : stack1), depth0))
    + "</div>";
},"53":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "    <div class=\"c-dataList__cell text-center\" style=\"width:"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.th : depth0)) != null ? stack1.CHK1 : stack1)) != null ? stack1.width : stack1), depth0))
    + "\">\n        <input type=\"checkbox\" "
    + ((stack1 = helpers.unless.call(depth0,((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.isActive : stack1),{"name":"unless","hash":{},"fn":this.program(54, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " data-col=\"1\" data-idrunner=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.idRunner : stack1), depth0))
    + "\" data-programnumber=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.programNumber : stack1), depth0))
    + "\" id=\"col-1-"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.idRunner : stack1), depth0))
    + "\" class=\"c-checkbox\" "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.checkbox_data : stack1)) != null ? stack1.isChecked : stack1),{"name":"if","hash":{},"fn":this.program(56, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.scratched : depth0),{"name":"if","hash":{},"fn":this.program(59, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "><label for=\"col-1-"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.idRunner : stack1), depth0))
    + "\" class=\"c-checkbox__label c-checkbox__label--race\"></label>\n    </div>\n";
},"54":function(depth0,helpers,partials,data) {
    return "disabled data-scratched";
},"56":function(depth0,helpers,partials,data) {
    var stack1;

  return " "
    + ((stack1 = __default(require("../../components/helpers/handlebar-helpers/compare.js")).call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.checkbox_data : stack1)) != null ? stack1.col : stack1),"===",1,{"name":"compare","hash":{},"fn":this.program(57, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " ";
},"57":function(depth0,helpers,partials,data) {
    return "checked";
},"59":function(depth0,helpers,partials,data) {
    return "disabled";
},"61":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "    <div class=\"c-dataList__cell text-center\" style=\"width:"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.th : depth0)) != null ? stack1.CHK2 : stack1)) != null ? stack1.width : stack1), depth0))
    + "\">\n        <input type=\"checkbox\" "
    + ((stack1 = helpers.unless.call(depth0,((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.isActive : stack1),{"name":"unless","hash":{},"fn":this.program(54, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " data-col=\"2\" data-idrunner=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.idRunner : stack1), depth0))
    + "\" data-programnumber=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.programNumber : stack1), depth0))
    + "\" id=\"col-2-"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.idRunner : stack1), depth0))
    + "\" class=\"c-checkbox\" "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.checkbox_data : stack1)) != null ? stack1.isChecked : stack1),{"name":"if","hash":{},"fn":this.program(62, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "  "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.scratched : depth0),{"name":"if","hash":{},"fn":this.program(59, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "><label for=\"col-2-"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.idRunner : stack1), depth0))
    + "\" class=\"c-checkbox__label c-checkbox__label--race\"></label>\n    </div>\n";
},"62":function(depth0,helpers,partials,data) {
    var stack1;

  return " "
    + ((stack1 = __default(require("../../components/helpers/handlebar-helpers/compare.js")).call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.checkbox_data : stack1)) != null ? stack1.col : stack1),"===",2,{"name":"compare","hash":{},"fn":this.program(57, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " ";
},"64":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "    <div class=\"c-dataList__cell text-center\" style=\"width:"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.th : depth0)) != null ? stack1.CHK3 : stack1)) != null ? stack1.width : stack1), depth0))
    + "\">\n        <input type=\"checkbox\" "
    + ((stack1 = helpers.unless.call(depth0,((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.isActive : stack1),{"name":"unless","hash":{},"fn":this.program(54, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " data-col=\"3\" data-idrunner=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.idRunner : stack1), depth0))
    + "\" data-programnumber=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.programNumber : stack1), depth0))
    + "\" id=\"col-3-"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.idRunner : stack1), depth0))
    + "\" class=\"c-checkbox\" "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.checkbox_data : stack1)) != null ? stack1.isChecked : stack1),{"name":"if","hash":{},"fn":this.program(65, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "  "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.scratched : depth0),{"name":"if","hash":{},"fn":this.program(59, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "><label for=\"col-3-"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.idRunner : stack1), depth0))
    + "\" class=\"c-checkbox__label c-checkbox__label--race\"></label>\n    </div>\n";
},"65":function(depth0,helpers,partials,data) {
    var stack1;

  return " "
    + ((stack1 = __default(require("../../components/helpers/handlebar-helpers/compare.js")).call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.checkbox_data : stack1)) != null ? stack1.col : stack1),"===",3,{"name":"compare","hash":{},"fn":this.program(57, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " ";
},"67":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "    <div class=\"c-dataList__cell text-center\" style=\"width:"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.th : depth0)) != null ? stack1.CHK4 : stack1)) != null ? stack1.width : stack1), depth0))
    + "\">\n        <input type=\"checkbox\" "
    + ((stack1 = helpers.unless.call(depth0,((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.isActive : stack1),{"name":"unless","hash":{},"fn":this.program(54, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " data-col=\"4\" data-idrunner=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.idRunner : stack1), depth0))
    + "\" data-programnumber=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.programNumber : stack1), depth0))
    + "\" id=\"col-4-"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.idRunner : stack1), depth0))
    + "\" class=\"c-checkbox\" "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.checkbox_data : stack1)) != null ? stack1.isChecked : stack1),{"name":"if","hash":{},"fn":this.program(68, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "  "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.scratched : depth0),{"name":"if","hash":{},"fn":this.program(59, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "><label for=\"col-4-"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.idRunner : stack1), depth0))
    + "\" class=\"c-checkbox__label c-checkbox__label--race\"></label>\n    </div>\n";
},"68":function(depth0,helpers,partials,data) {
    var stack1;

  return " "
    + ((stack1 = __default(require("../../components/helpers/handlebar-helpers/compare.js")).call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.checkbox_data : stack1)) != null ? stack1.col : stack1),"===",4,{"name":"compare","hash":{},"fn":this.program(57, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " ";
},"70":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "    <div class=\"c-dataList__cell text-center\" style=\"width:"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.th : depth0)) != null ? stack1.CHK5 : stack1)) != null ? stack1.width : stack1), depth0))
    + "\">\n        <input type=\"checkbox\" "
    + ((stack1 = helpers.unless.call(depth0,((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.isActive : stack1),{"name":"unless","hash":{},"fn":this.program(54, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " data-col=\"5\" data-idrunner=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.idRunner : stack1), depth0))
    + "\" data-programnumber=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.programNumber : stack1), depth0))
    + "\" id=\"col-5-"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.idRunner : stack1), depth0))
    + "\" class=\"c-checkbox\" "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.checkbox_data : stack1)) != null ? stack1.isChecked : stack1),{"name":"if","hash":{},"fn":this.program(71, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "  "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.scratched : depth0),{"name":"if","hash":{},"fn":this.program(59, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "><label for=\"col-5-"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.idRunner : stack1), depth0))
    + "\" class=\"c-checkbox__label c-checkbox__label--race\"></label>\n    </div>\n";
},"71":function(depth0,helpers,partials,data) {
    var stack1;

  return " "
    + ((stack1 = __default(require("../../components/helpers/handlebar-helpers/compare.js")).call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.checkbox_data : stack1)) != null ? stack1.col : stack1),"===",5,{"name":"compare","hash":{},"fn":this.program(57, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " ";
},"73":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "    <div class=\"c-dataList__cell text-center\" style=\"width:"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.th : depth0)) != null ? stack1.COMBO : stack1)) != null ? stack1.width : stack1), depth0))
    + "\">\n        <input type=\"checkbox\" "
    + ((stack1 = helpers.unless.call(depth0,((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.isActive : stack1),{"name":"unless","hash":{},"fn":this.program(54, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " data-col=\"C\" data-idrunner=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.idRunner : stack1), depth0))
    + "\" data-programnumber=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.programNumber : stack1), depth0))
    + "\" id=\"col-c-"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.idRunner : stack1), depth0))
    + "\" class=\"c-checkbox\" "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.checkbox_data : stack1)) != null ? stack1.isChecked : stack1),{"name":"if","hash":{},"fn":this.program(74, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "  "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.scratched : depth0),{"name":"if","hash":{},"fn":this.program(59, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "><label for=\"col-c-"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.idRunner : stack1), depth0))
    + "\" class=\"c-checkbox__label c-checkbox__label--race\"></label>\n    </div>\n";
},"74":function(depth0,helpers,partials,data) {
    var stack1;

  return " "
    + ((stack1 = __default(require("../../components/helpers/handlebar-helpers/compare.js")).call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.model : depth0)) != null ? stack1.checkbox_data : stack1)) != null ? stack1.col : stack1),"===","C",{"name":"compare","hash":{},"fn":this.program(57, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " ";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.th : depth0)) != null ? stack1.number : stack1),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "<div class=\"c-dataList__cell "
    + ((stack1 = helpers.unless.call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.th : depth0)) != null ? stack1.data : stack1)) != null ? stack1.minimal : stack1),{"name":"unless","hash":{},"fn":this.program(15, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\" style=\"width:"
    + this.escapeExpression(this.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.th : depth0)) != null ? stack1.data : stack1)) != null ? stack1.width : stack1), depth0))
    + "\" data-expand>\n    <div class=\"table-row\">\n        <div class=\"table-cell\" data-arrow>\n            <div class=\"c-dataList__el--truncatedText m-race__runnerName "
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.th : depth0)) != null ? stack1.number : stack1),{"name":"if","hash":{},"fn":this.program(17, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.th : depth0)) != null ? stack1.data : stack1)) != null ? stack1.minimal : stack1),{"name":"if","hash":{},"fn":this.program(19, data, 0),"inverse":this.program(21, data, 0),"data":data})) != null ? stack1 : "")
    + "            </div>\n"
    + ((stack1 = helpers.unless.call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.th : depth0)) != null ? stack1.data : stack1)) != null ? stack1.minimal : stack1),{"name":"unless","hash":{},"fn":this.program(28, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n    </div>\n"
    + ((stack1 = helpers.unless.call(depth0,((stack1 = ((stack1 = (depth0 != null ? depth0.th : depth0)) != null ? stack1.data : stack1)) != null ? stack1.minimal : stack1),{"name":"unless","hash":{},"fn":this.program(32, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n\n"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.th : depth0)) != null ? stack1.odds : stack1),{"name":"if","hash":{},"fn":this.program(50, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.th : depth0)) != null ? stack1.CHK1 : stack1),{"name":"if","hash":{},"fn":this.program(53, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.th : depth0)) != null ? stack1.CHK2 : stack1),{"name":"if","hash":{},"fn":this.program(61, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.th : depth0)) != null ? stack1.CHK3 : stack1),{"name":"if","hash":{},"fn":this.program(64, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.th : depth0)) != null ? stack1.CHK4 : stack1),{"name":"if","hash":{},"fn":this.program(67, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.th : depth0)) != null ? stack1.CHK5 : stack1),{"name":"if","hash":{},"fn":this.program(70, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(depth0,((stack1 = (depth0 != null ? depth0.th : depth0)) != null ? stack1.COMBO : stack1),{"name":"if","hash":{},"fn":this.program(73, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "\n<div class=\"m-race__runnerDetails\" data-tooltip></div>\n";
},"useData":true});