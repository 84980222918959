import _         from 'underscore';
import app       from 'components/core/application';
import user      from 'components/user/user';
import helpers   from 'components/helpers/helpers';
import maybe     from 'components/helpers/maybe';
import moment    from 'moment-timezone';

const raceHelpers = {
    getTitle: function (race) {
        return race.raceTitle || app.interpolate('label_race_number', {'number': race.raceNumber});
    },

    getCategory: function (race) {
        if (race.raceTypeDetail && race.raceTypeDetail.toUpperCase() === 'GRH') return race.category;
        return race.category ? app.polyglot.t('label_race_category_' + race.category.toUpperCase()) : null;
    },

    getDistance: function (race) {
        return race.distance ? helpers.distance(race.distance, user.getMetricSystem()) : null;
    },

    getRule4: function (race, event) {
        return event.rule4 && (race.fixedOddsStatus === 'ON' || race.fixedOddsStatus === 'SPD') ? app.polyglot.t('label_rule_4_applies') : '';
    },

    getRule4Info: function ({ postTime, rule4Deductions = [] }) {

        const raceDate = moment.unix(postTime).tz(user.timeZone()).format('L');

        return rule4Deductions.reduce(function(total, item) {
            const startDate = moment.unix(item.dateStart).tz(user.timeZone()).format('L');
            const endDate = moment.unix(item.dateEnd).tz(user.timeZone()).format('L');
            const deductionString =  app.interpolate(!item.dateStart ? 'msg_rule4_runner_deduction_overnight' : 'msg_rule4_runner_deduction', {
                deduction: item.deduction,
                from: moment.unix(item.dateStart).tz(user.timeZone()).format(startDate != raceDate ? 'LLLL' : 'LTS'),
                till:  moment.unix(item.dateEnd).tz(user.timeZone()).format(endDate != raceDate ? 'LLLL' : 'LTS'),

            });

            total += `${deductionString} (${item.name}${item.odds ? ' | ' + item.odds : ''}) ${item.waived ? ' - ' + app.polyglot.t('label_waived') : ''} <br />` ;

            return total;
        }, '');
    },

    getPurse: function (race) {
        return race.purse ? helpers.formatMoney(race.purse, false, race.purseCurrency, false) : null;
    },

    getPurseDetails: function (race) {
        const result = race.purseDetails.map(function(item){
            return helpers.number(item);
        }).join(' - ');
        return result === '' ? result : app.polyglot.t('label_purse_details') + ': ' + result;
    },

    getPlaceTerms: function (race, event) {
        return event.spEvent && helpers.getEachWayTerms(race, event, event.country) !== false ?
            helpers.getEachWayTerms(race, event, event.country) :
            '';
    },

    getPlaceTermsInfo: function (event) {
        return event.spEvent ? `${app.polyglot.t('label_each_way_terms')}: ${app.polyglot.t('msg_tip_each_way_terms')}` : '';
    },

    getRaceTypeDetails: function(race) {
        if (race.raceTypeDetail && race.raceTypeDetail !== "GRH") {
            return app.polyglot.t(`label_race_type_detail_${race.raceTypeDetail}`);
        }
        return '';
    },

    getClass: function (categoryLetter = '', country = '') {
        if (categoryLetter === '') {
            return categoryLetter;
        }

        switch (country) {
            case 'FR':
                return `(${app.interpolate('label_race_category_letter_france', {letter: categoryLetter})})`;
            case 'GB':
            case 'IE':
            case 'AE':
                return `(Class ${categoryLetter})`;
            default:
                return `(${categoryLetter})`;
        }
    },

    getDefined: function(val) {
        return val !== null && val !== undefined && val !== '';
    },

    raceTypeDetail: function(race) {
        if (race.raceTypeDetail && race.raceTypeDetail !== "GRH") {
            return raceBetsJS.i18n.data["raceTypeDetail" + race.raceTypeDetail]
        }
        return ''
    },

    getTrackSurface: function(race) {
        return race.raceType ? app.polyglot.t('label_track_surface_' + race.trackSurface.toUpperCase()) : null;
    },

    getTrackSurfaceFormatted: function(race, label=true) {
        if(_.isUndefined(race.trackSurface) || race.trackSurface === '') {
            return null;
        }
        var track_surface = this.getTrackSurface(race);
        return track_surface ? (label ? `${app.polyglot.t('label_track_surface')}: ${track_surface}` : track_surface) : null;
    },

    getAgeCondition: function (race) {
        if (_.isUndefined(race.ageFrom) || _.isUndefined(race.ageTo)) {
            return null;
        }

        var ageFrom = parseInt(race.ageFrom, 10);
        var ageTo = parseInt(race.ageTo, 10);

        if (ageFrom !== 0 && ageTo !== 0 && ageFrom !== ageTo) {
            return app.interpolate('label_condition_age_x_to_y', {
                ageFrom: ageFrom,
                ageTo: ageTo
            });
        } else if (ageFrom !== 0 && ageTo !== 0 && ageFrom === ageTo) {
            return app.interpolate('label_condition_age_x', {age: ageFrom});
        } else if (ageFrom !== 0) {
            return app.interpolate('label_condition_age_x_plus', {age: ageFrom});
        } else if (ageTo !== 0) {
            return app.interpolate('label_condition_age_up_to_x', {age: ageTo});
        }
    },

    /**
     * @method getTrackConditionsFormatted
     * @description
     *  Get formatted track condition
     *
     * @param race - race data
     * @param country - event country
     * @param abbr
     * @returns {string}
     */
    getTrackConditionsFormatted: function (race, country, abbr=false, label=true) {
        var ret = '',
            trackSurface,
            trSrf;

        if (race.trackGoing && race.trackSurface && country) {
            this.trackGoing = parseFloat(race.trackGoing);
            if (race.trackGoing == 0.0) {
                ret = '';
            } else {
                trSrf = race.trackSurface !== 'TRF' ? 'DRT' : 'TRF';
                trackSurface = trSrf.charAt(0).toUpperCase() + trSrf.slice(1).toLowerCase();

                if (trSrf === 'TRF' && race.trackGoing === 2.5) {
                    if (abbr) {
                        ret = (app.polyglot.t('label_track_going_' + trackSurface + Math.ceil(race.trackGoing)).charAt(0).toUpperCase()
                        + app.polyglot.t('label_track_going_' + trackSurface + Math.floor(race.trackGoing)) || '' ).charAt(0).toUpperCase();
                    } else {
                        ret = app.polyglot.t('label_track_going_' + trackSurface + Math.ceil(race.trackGoing)) + ' '
                        + app.polyglot.t('label_connect_to') + ' '
                        + app.polyglot.t('label_track_going_' + trackSurface + Math.floor(race.trackGoing));
                    }
                } else if (trSrf === 'TRF' && race.trackGoing == 3.5 && country === 'IE' && user.data.language === 'en') {
                    if (abbr) {
                        ret = 'Y';
                    } else {
                        ret = 'yielding';
                    }
                } else if (trSrf === 'DRT' && race.trackGoing > 3) {
                    ret = app.polyglot.t('label_track_going_' + trackSurface + '3');
                } else if ((race.trackGoing % 1) > 0) {
                    if (abbr) {
                        ret = (app.polyglot.t('label_track_going_' + trackSurface + Math.floor(race.trackGoing)) || '').charAt(0).toUpperCase()
                        + (app.polyglot.t('label_track_going_' + trackSurface + Math.ceil(race.trackGoing)) || '').charAt(0).toUpperCase();
                    } else {
                        ret = (app.polyglot.t('label_track_going_' + trackSurface + Math.floor(race.trackGoing)) || '')
                        + ' ' + app.polyglot.t('label_connect_to') + ' ' + app.polyglot.t('label_track_going_' + trackSurface + Math.ceil(race.trackGoing));
                    }
                } else {
                    if (abbr) {
                        ret = (app.polyglot.t('label_track_going_' + trackSurface + Math.floor(race.trackGoing)) || '').charAt(0).toUpperCase();
                    } else {
                        ret = app.polyglot.t('label_track_going_' + trackSurface + Math.floor(race.trackGoing));
                    }
                }
            }
        }

        return ret === '' ? ret : (label ? `${app.polyglot.t('label_track_going')}: ${ret}` : ret);
    },

    getRect: function(el) {
        return el[0].getBoundingClientRect();
    },

    /**
     * @method keepInViewPort
     * @description
     *  Keep element in the viewport
     *
     * @param el
     * @param options - {cssProperty: 'scrollLeft', duration: 100}
     */
    keepInViewPort: function(el, rect_initial, options={}) {
        //defaults
        var cssProperty = options.cssProperty || 'scrollLeft';
        var duration = options.duration || 0;

        if (!el.length) return;

        var viewPort = helpers.getViewPortSize();
        var rect = el[0].getBoundingClientRect();

        var px_to_move;
        if(helpers.isInViewPort(viewPort, rect)) {
            if (options.withoutRender) {
                return;
            } else {
                px_to_move = rect_initial ? rect_initial.left > 0 ? rect.left - rect_initial.left : rect.left : 0;
            }
        } else {
            if (options.withoutRender) {
                if (rect.right >= viewPort.width) {
                    px_to_move = rect.left - rect.width;
                } else {
                    px_to_move = rect.right - rect.width;
                }
            } else {
                px_to_move = rect.right - viewPort.width;
                var right_margin = rect_initial ? viewPort.width - rect_initial.right : 0;
                if (right_margin > 0) px_to_move = Math.abs(px_to_move) + right_margin;
            }
        }

        el.parent().animate({[cssProperty]: px_to_move}, duration);
    },

    headerItemsOrder: ['number', 'data', 'odds', 'BTN1', 'BTN2', 'CHK1', 'CHK2', 'CHK3', 'CHK4', 'CHK5', 'COMBO'],

    showOddsAsInfo: function(betType, odds, label) {
        //Odds must be available and bet type must match. For single bets there should also be no odds displayed as button label (threfore there is a check for _.isNaN(parseFloat(label))
        return odds > 0 && (
            _.contains(['EXA', 'TRI', 'QNL', 'TRO', 'SWG'], betType) || (_.contains(['PLC', 'SHW', 'SFC', 'ITA', 'TRT', 'WS', 'PS', 'WPS', 'WP'], betType) && _.isNaN(parseFloat(label))) || (helpers.isPickBetType(betType) && !_.isNaN(parseFloat(label)))
        );
    },

    showFixedOnlyForCountries: function (event, race) {
        //do not show SP odds once fixed are available for select countries
        return ['GB', 'IE', 'AE'].indexOf(event.country) > -1 && race.fixedOddsStatus === 'ON';
    },

    labelIsNumber: function(label) {
        return !_.isNaN(parseFloat(label));
    },

    availableOdds: function (betTypes, fixedOddsStatus) {
        let availableOdds = [];
        //price
        if (betTypes.normal.TOT !== undefined || betTypes.normal.BOK !== undefined) {
            availableOdds.push('PRC');
        }
        if (fixedOddsStatus !== 'NAV' && betTypes.normal.FXD !== undefined) {
            // win
            if (betTypes.normal.FXD.WIN !== undefined || betTypes.normal.FXD.WP !== undefined) {
                availableOdds.push('FXW');
            }
            // place
            if (user.getOddsFormat() !== 'fractional' && (betTypes.normal.FXD.PLC !== undefined || betTypes.normal.FXD.WP !== undefined)) {
                availableOdds.push('FXP');
            }
        }

        return availableOdds;
    },

    isGreyhound: function (race) {
        return (race.raceType === 'D' || race.raceTypeDetail === 'GRH');
    }
};

export default raceHelpers;
