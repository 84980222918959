'use strict';

import _                 from 'underscore';
import $                 from 'jquery';
import user              from 'components/user/user';
import helpers           from 'components/helpers/helpers';
import moment            from 'moment-timezone';
import maybe             from 'components/helpers/maybe';

/**
 * label_system_2X - Doubles
 * label_system_3C - Trixie
 * label_system_3F - Patent
 * label_system_3X - Trebles
 * label_system_4C - Yankee
 * label_system_4F - Lucky 15
 * label_system_4X - 4 Folds
 * label_system_5X - 5 Folds
 * label_system_6X - 6 Folds
 * label_system_7X - 7 Folds
 * label_system_8X - 8 Folds
 * label_system_5C - Canadian
 * label_system_6C - Heinz
 * label_system_5F - Lucky 31
 * label_system_6F - Lucky 63
 * label_system_7C - Super Heinz
 * label_system_8C - Goliath
 * label_system_none - No System (not used)
 */

const baseBetsCount = {
    label_system_2X: 2,
    label_system_3C: 3,
    label_system_3F: 3,
    label_system_3X: 3,
    label_system_4C: 4,
    label_system_4F: 4,
    label_system_4X: 4,
    label_system_5C: 5,
    label_system_5X: 5,
    label_system_6X: 6,
    label_system_7X: 7,
    label_system_8X: 8,
    label_system_6C: 6,
    label_system_5F: 5,
    label_system_6F: 6,
    label_system_7C: 7,
    label_system_8C: 8
};

const multiplesValidator = {
    /**
     * @method getSystemLabel
     * @description
     *  'FOLD' -> 'X' e.g. label_system_2X, label_system_3X
     *  'COMB' -> 'C' e.g. label_system_4C, label_system_5C
     *  'FULL' -> 'F' e.g. label_system_3F, label_system_6F
     *
     * @param n - number of bets or baseBetsCount (returned by server)
     * @param system - 'FOLD', 'COMB' or 'FULL'
     *
     * return {String}
     */
    getSystemLabel: function(n, system) {
        if(!_.contains(['FOLD', 'COMB', 'FULL'], system)) throw new error("System value can be one of these: 'FOLD', 'COMB' or 'FULL'");

        if(system === 'FOLD') system = 'X';

        return `label_system_${n}${system.charAt(0).toUpperCase()}`
    },

    generateMultiplesModel: function (data, bets, allAreEachWay) {
        var model = {
            id: data.label,
            label: data.label,
            stamp: moment().unix(),
            system: data.system,
            value: data.value, //used internally
            baseBetsCount: data.baseBetsCount,
            isMultiple: true,
            showEachWay: allAreEachWay,
            cid: _.uniqueId('ch_'),
            stake: {
                currency: user.data.currency
            }
        };

        model.stake.amount = this.getAmount(model, bets);

        return model;
    },

    getBaseBets: function(bets) {
        function inMultiplesOnly(value) {
            var active = _.isUndefined(value.status) || (value.status && !value.status.update) ? true : false;
            return value.inMultiples === true && active;
        }
        return _.groupBy(bets.filter(inMultiplesOnly), 'idRace');
    },

    /**
     * @method getSystem
     *  @description
     *   Calculate possible multiple bets
     *
     * @param data
     * @returns {Array}
     */
    getSystem: function(data, allAreEachWay) {
        var numRaces = _.size(this.getBaseBets(data.bets));
        var multiples = [];

        if (numRaces > 1) multiples.push(this.generateMultiplesModel({system: 'FOLD', value: '', label: this.getSystemLabel(numRaces, 'FOLD'), baseBetsCount: baseBetsCount[this.getSystemLabel(numRaces, 'FOLD')]}, data.bets, allAreEachWay));

        if (numRaces > 2) {
            for (let i = 2; i < numRaces; i++) multiples.push(this.generateMultiplesModel({system: 'FOLD', value: i, label: this.getSystemLabel(i, 'FOLD'), baseBetsCount: baseBetsCount[this.getSystemLabel(i, 'FOLD')]}, data.bets, allAreEachWay));

            multiples.push(this.generateMultiplesModel({system: 'COMB', value: 'COMB', label: this.getSystemLabel(numRaces, 'COMB'), baseBetsCount: baseBetsCount[this.getSystemLabel(numRaces, 'COMB')]}, data.bets, allAreEachWay));

            if (numRaces < 7) multiples.push(this.generateMultiplesModel({system: 'FULL', value: 'FULL', label: this.getSystemLabel(numRaces, 'FULL'), baseBetsCount: baseBetsCount[this.getSystemLabel(numRaces, 'FULL')]}, data.bets, allAreEachWay));
        }

        return multiples;
    },

    remapUnits: function(newSystem, oldSystem, allAreEachWay) {
        _.each(newSystem, function(multiple) {
            var m = _.findWhere(oldSystem, {id: multiple.id}, {baseBetsCount: multiple.baseBetsCount}, {system: multiple.system});
            //it is important that we keep the newly generate stake.amount value. E.g. Double can have and amount of 1 with 2 bets and amount 3 with 3+ bets included into multiples
            if(m) {
                //do not overwrite amount if it's higher than the preset one. If it is higher, it was multiplied by 2 because of each way
                // Check if the there is isEachWay and Multiply the amount by 2, else just rewrite the amount.
                if( m.stake && m.stake.amount) {
                    if (m.isEachWay){
                        m.stake.amount = multiple.stake.amount * 2;
                    }else{
                        m.stake.amount = multiple.stake.amount;
                    }
                }
                multiple.stake = m.stake;
                m.isEachWay? multiple.stake.amount * 2 : multiple.stake;

                //copy each way flag
                multiple.isEachWay = allAreEachWay ? m.isEachWay : false;
                multiple.showEachWay = allAreEachWay;
            }
        });

        return newSystem;
    },

    mapAdditionalData: function (multiple, response, fixedBetsOnly, bets) {
        multiple.label = this.getSystemLabel(multiple.baseBetsCount, multiple.system);
        multiple.id = multiple.label; //note that this is used for proper odds calculation for multiples
        multiple.isMultiple = true;

        multiple.cid = _.uniqueId('ch_');//used for checkboxes

        //if(multiple.odds) multiple.odds_f = helpers.formatOdds(multiple.odds, user.data.oddsFormat);
        if(multiple.stake && multiple.stake.unit) {
            multiple.stake.unit_f = helpers.formatMoney(multiple.stake.unit, multiple.stake.unit % 1 > 0 ? 2 : 0);

            //check if currency is same as user currency. On BetSafe the default currency is GB, but when user logges in, he may have a different currency and currency for all bets in betslip should be set to user currency
            var user_currency = maybe.of(user).mapDotProp('data.currency').orElse(null).join();
            if(user_currency) multiple.stake.currency = user_currency;
        }

        if(helpers.whatDecimalSeparator() === '.') multiple.stake.unit_f = multiple.stake.unit_f.replace(/\,/g, '.');

        return multiple;
    },

    getRunnersPerRace: function(data) {
        var races = this.getBaseBets(data);
        var runners_n = [];

        _.each(races, function(race) {
            runners_n.push(race.length); //todo: remove coupled horses
        });

        return runners_n;
    },

    getAmount: function(multiple, bets) {
        var marks = this.getRunnersPerRace(bets);
        var numBets = 0;

        if (multiple.value === '') {
            // no system
            numBets = marks[0] || 0;
            for (let i = 1, l = marks.length; i < l; i++) {
                numBets *= marks[i];
            }
        } else if (multiple.value === 'COMB') {
            // combination system without single bets
            numBets = this.getNumBetsCombi(marks, _.size(this.getBaseBets(bets)));
        } else if (multiple.value === 'FULL') {
            // full combination
            numBets = this.getNumBetsCombi(marks, _.size(this.getBaseBets(bets)));
            for (let i = 0, l = marks.length; i < l; i++) {
                numBets += marks[i];
            }
        } else {
            numBets = this.getNumBetsSystem(marks, multiple.value);
        }

        // if (multiple.isEachWay) numBets *= 2;

        return numBets;
    },

    getNumBetsCombi: function(marks, numRaces) {
        var numBets = 0;
        for (let i = 2; i <= numRaces; i++) {
            numBets = numBets + this.getNumBetsSystem(marks, i);
        }
        return numBets;
    },

    getNumBetsSystem: function(marks, system) {
        if (system === 0) return 1;

        var numBets = 0;

        for (let i = 0; i < marks.length - (system - 1); i++) {
            for (let j = 0; j < marks[i]; j++) {
                var copy = $.extend([], marks);
                for (let k = 0; k < i + 1; k++) {
                    copy.shift();
                }
                numBets = numBets + this.getNumBetsSystem(copy, system - 1);
            }
        }

        return numBets;
    },

    getPickMarks: function(race_ids) {
        const marks = [];

        _.each(race_ids, function(race) {
            const minus = race.skipCount || 0;
            marks.push(race.count - minus);
        });

        return marks;
    },

    getPickBetCount: function(marks) {
        var bet_count = marks[0] || 0;
        if(marks.length < 2) return bet_count;

        for (let i = 1, l = marks.length; i < l; i++) {
            bet_count *= marks[i];
        }

        return bet_count;
    },

    getPickBetTypeCount: function(race_ids) {
        return this.getPickBetCount(this.getPickMarks(race_ids));
    },

    composeRunner: function(runner_data, bet) {
        var runner = {
            idRunner: runner_data.idRunner,
            name: runner_data.name,
            odds: maybe.of(runner_data).mapDotProp('betting.PRC.oddsValue').orElse(null).join(),
            programNumber: runner_data.programNumber.toString().replace(/[^\d.]/g, '')
        };

        if (runner_data.betCategory === 'FXD' && bet.betType === 'WIN') {
            runner.fixedOddsWin = runner_data.odds.FXW;
        } else if (runner_data.betCategory === 'FXD' && bet.betType === 'WP') {
            runner.fixedOddsWin = runner_data.odds.FXW;
            runner.fixedOddsPlace = runner_data.odds.FXP;
        } else if (bet.betCategory === 'FXD' && runner_data.betCategory === 'BOK') {
            runner.useSp = 1;
        }

        return runner;
    },

    getStakeLimits: function(bets) {
        const minStakes = [];
        const maxStakes = [];
        _.each(bets, function(bet) {
            if (bet.inMultiples) {
                _.each(bet.parts, function(part) {
                    minStakes.push(helpers.getMinStakeBOKFXD(user.data.currencySettings, bet.category, part.market));
                    maxStakes.push(helpers.getMaxStakeBOKFXD(user.data.currencySettings, bet.category, part.market));
                });
            }
        })
        return {
            minStake: _.max(minStakes),
            maxStake: _.min(maxStakes)
        }
    },
};

export default multiplesValidator;
