'use strict';

import _                            from 'underscore';
import Backbone                     from 'backbone';
import BackboneCloseView            from 'components/core/mixins/Backbone.CloseView';
import BettingService               from 'components/betting/betting.service';
import app                          from 'components/core/application';
import helpers                      from 'components/helpers/helpers';
import template                     from 'modules/race/race.tpl.hbs';
import * as RaceViewMethods         from 'modules/race/race.methods';
import contextMenu                  from 'modules/race/context-menu';
import mediaService                 from 'modules/race/media.service';

/**
 * Race view
 *
 * @name RaceView
 * @constructor
 *
 * @requires Underscore
 * @requires jQuery
 * @requires Backbone
 * @requires template
 * @requires maybe
 * @requires helpers
 * @requires RaceNumbersView
 * @requires RaceTitleView
 * @requires RunnersCollection
 * @requires BackboneCloseView
 *
 * @description
 *  RaceView view
 *
 * @returns {Function} - Constructor function for RaceView
 */

const RaceView = Backbone.View.extend(
    _.extend({}, BackboneCloseView, RaceViewMethods, {

        template: template,

        events: RaceViewMethods.viewEvents,

        /**
         * This function will be called in the shared initialize method
         * Used to have different a media service instance on the context menu as it has different logic regarding streams
         */
        viewInitialize: function () {
            this.contextMenu = contextMenu;
            this.contextMenu.mediaService = mediaService;
        },

        viewComponentUpdate: function (collection) {
            this.contextMenu.init(collection.data);
        }
    })
);

export default RaceView;
