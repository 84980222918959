import $                            from 'jquery';
import _                            from 'underscore';
import app                          from 'components/core/application';
import Backbone                     from 'backbone';
import BackboneCloseView            from 'components/core/mixins/Backbone.CloseView';
import user                         from 'components/user/user';
import { SETTINGS_GDPR_ENABLED }    from 'components/app.settings';
import helpers                      from 'components/helpers/helpers';

/**
 * Content View
 *
 * @name ContentView
 * @constructor
 *
 * @requires jQuery
 * @requires Underscore
 * @requires template
 *
 * @description
 * 		Common view used for simple content displaying, as used in 'contact us', 'terms and conditions', 'privacy', etc...
 * 		listen for clicks on element with data-href='url' attributes for user navigation
 *
 *
 * @returns {Function} - Constructor function for ContentView
 */

var ContentView = Backbone.View.extend(
    _.extend({}, BackboneCloseView, {

        events: {
            'click [data-href]': 'goToPage'
        },

        initialize: function (opt) {
            this.template = opt.template;

            this.data = {
                lang: user.data.language,
                tld: user.data.websiteTLD.toLowerCase(), //'contact us' template needs TLD (com/de) information to show proper license (Maltese or German)
                gdpr_enabled: SETTINGS_GDPR_ENABLED,
                showMillionGame: helpers.isMillionGameAvailable(),
                showAdventCalendar: helpers.isAdventCalendarAvailable(),
                supportLink: app.brand === 'racebets' ?
                    user.data.supportUrl + '/' + (user.data.language === 'de' ? user.data.language : 'en') + '.html?category=betting-rules'
                    : 'https://support.betsson.com.br/br.html?category=APOSTAS',
            };

            if (opt.loadHtmlUrl) {
                this.loadContent(opt.loadHtmlUrl);
            }
        },

        render: function () {
            this.$el.html(this.template(this.data));
            return this;
        },

        goToPage: function (e) {
            var $el = $(e.currentTarget);
            var url = $el.attr('data-href');

            // if is an external link, follow it
            if (e.currentTarget.hasAttribute('data-external')) {
                window.open(url);
                return;
            }
            else if (url) {
                app.router.navigate(url, {trigger: true});
            }
        },

        loadContent: function (url) {
            $.get(url, (res) => {
                this.data.content = res.data;
                this.render();
                setTimeout(() => this.$el.addClass('m-static'), 1000);
            });
        },

        onClose: function () {
            this.$el.removeClass('m-static');
        }
    })
);

export default ContentView;
