var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
    return this.escapeExpression(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_free_bet",{"name":"translate","hash":{},"data":data}));
},"3":function(depth0,helpers,partials,data) {
    return " data-id=\""
    + this.escapeExpression(this.lambda((depth0 != null ? depth0.id : depth0), depth0))
    + "\" ";
},"5":function(depth0,helpers,partials,data) {
    return " "
    + this.escapeExpression(this.lambda((depth0 != null ? depth0.dataAttributes : depth0), depth0))
    + " ";
},"7":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "            <option value=\""
    + alias2(alias1((depth0 != null ? depth0.idFreebet : depth0), depth0))
    + "\"\n"
    + ((stack1 = __default(require("../helpers/handlebar-helpers/compare.js")).call(depth0,((stack1 = (depths[1] != null ? depths[1].selected : depths[1])) != null ? stack1.idFreebet : stack1),"===",(depth0 != null ? depth0.idFreebet : depth0),{"name":"compare","hash":{},"fn":this.program(8, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + ">\n                "
    + alias2(alias1((depth0 != null ? depth0.title : depth0), depth0))
    + "\n"
    + ((stack1 = __default(require("../helpers/handlebar-helpers/compare.js")).call(depth0,(depth0 != null ? depth0.idFreebet : depth0),"!==","no",{"name":"compare","hash":{},"fn":this.program(10, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            </option>\n";
},"8":function(depth0,helpers,partials,data) {
    return "                    selected\n                ";
},"10":function(depth0,helpers,partials,data) {
    return "                    "
    + this.escapeExpression(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_free_bet",{"name":"translate","hash":{},"data":data}))
    + "\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=this.escapeExpression;

  return "<div class=\"c-select__wrapper c-select--border\">\n    <div class=\"c-select__selectedOption c-select__selectedOptions hasRibbon\" data-selected>\n        <div class=\"c-select__truncate truncate-text\">\n            <div>\n                <div class=\"c-select__ribbon\">\n                    "
    + alias1(__default(require("../helpers/handlebar-helpers/translate.js")).call(depth0,"label_free_bets",{"name":"translate","hash":{},"data":data}))
    + "\n                </div>\n                <span data-option>\n                    "
    + alias1(this.lambda(((stack1 = (depth0 != null ? depth0.selected : depth0)) != null ? stack1.title : stack1), depth0))
    + "\n                    \n                </span>\n                <span>"
    + ((stack1 = __default(require("../helpers/handlebar-helpers/compare.js")).call(depth0,((stack1 = (depth0 != null ? depth0.selected : depth0)) != null ? stack1.idFreebet : stack1),"!==","no",{"name":"compare","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</span>\n            </div>\n        </div>\n    </div>\n    <div class=\"c-select__arrow\">\n        <i class=\"c-arrow pointDown\"></i>\n    </div>\n    <select "
    + ((stack1 = helpers["if"].call(depth0,__default(require("../helpers/handlebar-helpers/exists.js")).call(depth0,(depth0 != null ? depth0.id : depth0),{"name":"exists","hash":{},"data":data}),{"name":"if","hash":{},"fn":this.program(3, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " name=\"select\" class=\"c-select__options\" "
    + ((stack1 = helpers["if"].call(depth0,__default(require("../helpers/handlebar-helpers/exists.js")).call(depth0,(depth0 != null ? depth0.dataAttributes : depth0),{"name":"exists","hash":{},"data":data}),{"name":"if","hash":{},"fn":this.program(5, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " data-select>\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.options : depth0),{"name":"each","hash":{},"fn":this.program(7, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "    </select>\n</div>\n";
},"useData":true,"useDepths":true});