import _                     from 'underscore';
import $                     from 'jquery';
import Backbone              from 'backbone';
import app                   from 'components/core/application';
import template              from 'components/betting/bet-item.tpl.hbs';
import helpers               from 'components/helpers/helpers';
import user                  from 'components/user/user';
import maybe                 from 'components/helpers/maybe';
import betslipHelpers        from 'components/betting/betslip.helpers';
import cSelectStyled         from 'components/select/Backbone.select-styled';
import oddsSelectTemplate    from 'components/betting/odds-select.tpl.hbs';
import BackboneCloseView     from 'components/core/mixins/Backbone.CloseView';

/**
 * BetItem item view Controller
 * @name BetItem
 * @constructor
 *
 * @requires Underscore
 * @requires jQuery
 * @requires Backbone
 * @requires app
 * @requires template
 * @requires helpers
 * @requires user
 * @requires maybe
 * @requires betslipHelpers
 * @requires cSelectStyled
 * @requires oddsSelectTemplate
 * @requires BackboneCloseView
 *
 * @description
 *     BetItem View Controller
 *     for quick betting and adding bets to BetItem
 *
 * @returns Function - Constructor function for BetItem view
 */
var BetItem = {

        className: 'm-betslip__bet c-form p-0 pb1-5',//m-betslip__bet--updated

        template: template,

        events: {
            'click [data-delete]': 'deleteBet',
            'click [data-accept]': 'accept',
            'click [data-checkbox-eachWay]': 'eachWay',
            'click [data-checkbox-multiples]': 'multiples',
            'keyup [data-unit]': 'amountChanged',
            'blur [data-unit]': 'amountChangedFinished',
            'keyup input': 'manageInputErrors'
        },

        initialize: function (opts) {
            this.model = opts.model || undefined;
            this.DOM = {};

            this.listenTo(this.model, 'change:parts', this.onPartsChange);
            this.listenTo(this.model, 'change:status', this.render);
            this.listenTo(this.model, 'invalid', this.onInvalid);

            if(this.model.attributes.odds_select.show_options) this.initOddsSelect();
        },

        checkUpdateStatus: function() {
            var status = maybe.of(this).mapDotProp('model.attributes.status').orElse(false).join();
            this.$el.toggleClass('m-betslip__bet--updated', status && status.update);
        },

        render: function () {
            this.checkUpdateStatus();
            this.$el.html(this.template(_.extend(this.model.toJSON(), {eachWayCountryBasedLabel: helpers.getEachWayLabel(this.model.get('country'), this.model.get('isAntePost'), this.model.get('spEvent'))})));

            this.onRender();
            return this;
        },

        onRender: function () {
            this.DOM = {
                odds_select: this.$('[data-odds-select]'),
                winnings: this.$('[data-winnings]')
            };

            if(this.oddsSelect) {
                this.DOM.odds_select.html(this.oddsSelect.render().$el);
                this.oddsSelect.delegateEvents();
            }
            this.updateWinnings();
        },

        onInvalid: function(model, errors) {
            if(!this.DOM.vmc) this.DOM.vmc = {};

            for(var i = 0, l = errors.length; i < l; i++) {
                var field = errors[i];

                if(field.name === 'odds') {
                    this.render();
                } else {
                    //cache validation message container object if has not been cached yet
                    if (!this.DOM.vmc[field.name]) this.DOM.vmc[field.name] = this.$('[data-validation_' + field.name + ']');
                    if (!this.DOM[field.name]) this.DOM[field.name] = this.$('[data-' + field.name + ']');

                    this.DOM.vmc[field.name].html(field.message).show();
                    this.DOM[field.name].addClass('c-form__input--invalid');
                }
            }
        },

        manageInputErrors: function(e) {
            var id = $(e.target).data('id');

            if(this.DOM[id]) this.DOM[id].removeClass('c-form__input--invalid');
            if(this.DOM.vmc && this.DOM.vmc[id]) this.DOM.vmc[id].hide();
        },

        onPartsChange: function(...args) {
            if(!args || !args[0] || !args[0].attributes || !args[0].attributes.user_entered_unit) {
                this.render();
                this.updateWinnings();
            }
        },

        initOddsSelect: function () {
            this.oddsSelect = new cSelectStyled({
                    template: oddsSelectTemplate,
                    options: this.model.attributes.odds_select.options,
                    event: 'odds:selected',
                    selected: _.find(this.model.attributes.odds_select.options, value => value.active)
                })
                .on('odds:selected', this.onOddsSelected, this);
        },

        onOddsSelected: function (data) {
            var odds = data.oddsWin;

            this.model.attributes.category = data.category;

            var odds_select = maybe.of(this.model).mapDotProp('attributes.odds_select.options').orElse([]).join();
            if(odds_select) {
                _.map(odds_select, function(option) {
                    option.active = data.category === option.category;
                })
            }

            var _parts = [];
            _.each(this.model.attributes.parts, (part, index) => {
                if(part.odds) {
                    if (odds > 0) {
                        part.odds = odds;
                    } else {
                        delete part.odds;
                        if(index > 0) delete part.stake;
                    }
                } else {
                    if(odds > 0) part.odds = odds;
                }
                _parts.push(part);
            });

            //add 'PLC' part for FXD category. In case of changing options, we need to restore second entry in parts
            var pl = _parts.length;
            if(pl > 0 && (this.model.attributes.category === 'FXD' && _parts[0].market === 'WIN') || this.model.attributes.isEachWay) {
                //remove second entry if exists to ensure that odds value is correct (it is set to same as WIN, but should be PLC)
                if(pl === 2) _parts.pop();
                var data = {market: 'PLC', odds: data.oddsPlc};
                //ad stake for eachWay only
                if(this.model.attributes.isEachWay) data.stake = _parts[0].stake;
                _parts.push(data);
            } else {
                //remove second part of parts if it is no a FXD category and not EachWay
                if(pl === 2 && !this.model.attributes.isEachWay && this.model.attributes.category !== 'FXD') _parts.pop();
            }

            //we need to use 'set' helper to trigger 'change' event on a model
            this.model.unset('parts', {silent: true});
            this.model.set({parts: _parts});

            this.updateWinnings();
        },

        deleteBet: function (e) {
            e.stopPropagation();
            this.model.destroy(); //will be removed form collection and collection will trigger change event
        },

        accept: function (e) {
            e.stopPropagation();
            this.model.attributes.status.update = false;
            this.model.attributes.status.remove = false;
            this.model.collection.trigger('model:update');
            this.render();
        },

        eachWay: function (e) {
            e.stopPropagation();
            var _parts = [];
            var _part_2;
            var is_checked = $(e.target).is(':checked');
            var parts = maybe.of(this).mapDotProp('model.attributes.parts').orElse([]).join();
            var part_1 = parts[0] || null;
            var part_2 = parts[1] || null;

            if(part_1) _parts.push(part_1);

            //add second entry for parts in case of EachWay
            if (part_1 && (is_checked || this.model.attributes.category === 'FXD')) {
                _part_2 = helpers.deepCopy(part_1);
                _part_2.market = _part_2.market === 'WIN' ? 'PLC' : 'WIN';
                _part_2.odds = this.model.attributes.oddsPlc || null;

                //if not EachWay and FXD bet - remove stake
                if(!is_checked && this.model.attributes.category === 'FXD') delete _part_2.stake;
                _parts.push(_part_2);
            } else {
                //remove second part only if it exists and it is not EachWay and not a FXD bet
                //if(_parts) _parts.pop();
            }

            //always modify this value on manipulations with each way
            //important: 'isEachWay' value needs to be set before 'parts' are set. Settings 'parts' triggers total stake and total bet count calculation which is incorrect if 'eachWay' has not been set before
            this.model.set({isEachWay: is_checked});

            this.model.unset('parts', {silent: true});
            this.model.set({parts: _parts});
        },

        multiples: function (e) {
            e.stopPropagation();
            var is_checked = $(e.target).is(':checked');
            this.model.set({inMultiples: is_checked});
        },

        amountChanged: function (e) {
            var unit = $(e.target).val();
            unit = unit ? parseFloat(unit.replace(',', '.')) : '';
            this.model.attributes.user_entered_unit = false;

            var _parts = [];
            var parts = maybe.of(this).mapDotProp('model.attributes.parts').orElse([]).join();
            var part_1 = parts[0] || null;
            var part_2 = parts[1] || null;

            //part_1 has to be added for all bets but stake.unit only when unit is available
            //if part_1 exists and it has stake and unit properties
            //unit is more than 0 - update value
            if(unit > 0) {
                if(part_1 && part_1.stake && part_1.stake.unit) {
                    //store previous unit
                    this.model.attributes.previous_unit = part_1.stake.unit;
                    part_1.stake.unit = unit;
                } else {
                    //add stake.unit property
                    this.model.attributes.previous_unit = null;
                    part_1.stake = {
                        amount: 1,
                        unit: unit,
                        currency: this.model.get('category') === 'TOT' ? this.model.get('toteCurrency') : user.data.currency
                    };
                }
            } else {
                //remove stake property since there is no unit
                delete part_1.stake;
            }

            _parts.push(part_1);

            //part_2 is available for eachWay or FXD bets only
            if(this.model.attributes.isEachWay || this.model.attributes.category === 'FXD') {
                if(unit > 0 && this.model.attributes.isEachWay) {
                    //update unit for each way only
                    if (part_2 && part_2.stake && part_2.stake.unit) {
                        //store previous unit
                        this.model.attributes.previous_unit = part_2.stake.unit;
                        part_2.stake.unit = unit;
                    } else {
                        //add stake.unit property
                        this.model.attributes.previous_unit = null;
                        part_2.stake = {
                            amount: 1,
                            unit: unit,
                            currency: this.model.get('category') === 'TOT' ? this.model.get('toteCurrency') : user.data.currency
                        };
                    }
                } else {
                    //remove stake.unit if unit is not available
                    delete part_2.stake;
                }

                _parts.push(part_2);
            }

            this.model.attributes.user_entered_unit = true;
            //we need to use 'set' helper to trigger 'change' event on a model
            this.model.unset('parts', {silent: true});
            this.model.set({parts: _parts});

            this.updateWinnings();
        },

        amountChangedFinished: function() {
            this.model.attributes.user_entered_unit = false;
        },

        updateWinnings: function () {
            var winnings = betslipHelpers.calculateWinnings(this.model.attributes);
            this.DOM.winnings.html(`${app.polyglot.t('label_potential_winnings')}: ` + winnings).toggle(winnings !== null);
        },

        onClose: function () {
            if (this.oddsSelect) this.oddsSelect.close();
        }

    }

export default _.extend({}, BetItem, BackboneCloseView);
