'use strict';

import _                            from 'underscore';
import Backbone                     from 'backbone';
import app                          from 'components/core/application';
import user                         from 'components/user/user';
import helpers                      from 'components/helpers/helpers';
import moment                       from 'moment-timezone';

var RunnerFormModel = Backbone.Model.extend({

    url: function () {
        let url = '/rest/v2/cards/runner_past_performance/' + this.opt.idSubject + '/' + this.opt.idRace;

        if (this.opt.idRaceForm) {
            url += '/' + this.opt.idRaceForm;
        }

        return url;
    },

    initialize: function (attr, opt) {
        this.opt = opt;
        this.data = {};
    },

    parse: function (response) {
        response.loaded = true;

        this.data.race = response.race;
        this.data.ref = response.ref;

        // patching some differences across api responses
        this.data.race.purseCurrency = this.data.race.purse.currency;
        this.data.race.purse = this.data.race.purse.amount;

        // flag to determine whenever to show or not the weight col
        response.result.showWeight = false;

        _.each(response.result.positions, (position) => {
            // formats jockey weight
            if (position.jockey.weight) {
                position.jockey.weightFormatted = helpers.weight(position.jockey.weight, user.getMetricSystem());
                response.result.showWeight = true;
            } else {
                position.jockey.weightFormatted = '-';
            }
            if (position.jockey.firstName) {
                position.jockey.nameLabel = position.jockey.firstName.substr(0, 1) + '. ';
            }
            if (position.jockey.lastName) {
                position.jockey.nameLabel += position.jockey.lastName;
            }
            if (position.odds) {
                position.oddsLabel = helpers.formatOdds(position.odds, user.data.oddsFormat);
            }
            // set a flag if is the race-item runner
            if (position.idSubject == this.opt.idSubject) {
                position.isRunner = true;
            }

        });

        if (response.result.distances.length > 0) {
            response.result.distancesLabelsArr = [];
            _.each(response.result.distances, (distance) => {
                if (_.contains(['DE', 'GB', 'IE'], this.data.race.country)) {
                    let value = parseFloat(distance);
                    let label = app.polyglot.t('label_distance_' + value + '_short');
                    let whole = Math.floor(value);
                    let fraction = value - whole;
                    if (label === 'label_distance_' + value + '_short' && value > 0) {
                        label = (whole > 0 ? whole : '') + app.polyglot.t('label_distance_' + fraction + '_fract');
                    }
                    if (label !== ((whole > 0 ? whole : '') + 'label_distance_' + fraction + '_fract')) {
                        response.result.distancesLabelsArr.push(label);
                    } else {
                        response.result.distancesLabelsArr.push(value);
                    }
                } else {
                    let value = parseFloat(distance);
                    response.result.distancesLabelsArr.push(value);
                }
            });
            response.result.distancesLabels = response.result.distancesLabelsArr.join(' - ');
        } else {
            response.result.distances = false;
        }
        return response;
    }
});

export default RunnerFormModel;
