'use strict';

import _                         from 'underscore';
import $                         from 'jquery';
import Backbone                  from 'backbone';
import quickBetMultiMethods      from 'components/betting/quick-bet-multi-dialog.methods';

/**
 * QuickBet view Controller
 * @name QuickBetMulti
 * @constructor
 *
 * @requires Underscore
 * @requires jQuery
 * @requires Backbone
 * @requires app
 * @requires quickBetMultiMethods
 *
 * @description
 *     QuickBetMulti View Controller
 *     for quick betting and adding bets to betslip
 *
 * @returns Function - Constructor function for QuickBetMulti view
 */
var QuickBetMulti = Backbone.View.extend(
    _.extend({}, quickBetMultiMethods, {

        afterInitialize: function() {
            this.events['click [data-category]'] = 'changeCategory';
        },

        onTemplateRendered: function() {
            this.DOM.categoryBtn = this.$('[data-category]');
        },

        changeCategory: function(e) {
            let $button = $(e.currentTarget);
            this.betModel.set({betCategory: $button.attr('data-category')}, {silent: true});
            this.DOM.categoryBtn.filter('.isSelected').removeClass('isSelected');
            $button.addClass('isSelected');
            this.updateTax();
        }
    })
);

export default QuickBetMulti;
