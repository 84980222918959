var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(depth0,helpers,partials,data) {
    return "|";
},"3":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = __default(require("../../components/helpers/handlebar-helpers/eachUpTo.js")).call(depth0,(depth0 != null ? depth0.results : depth0),3,{"name":"eachUpTo","hash":{},"fn":this.program(4, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"4":function(depth0,helpers,partials,data) {
    var stack1;

  return "                <div class=\"m-event__favourites\">\n                    <span>"
    + this.escapeExpression(this.lambda((depth0 != null ? depth0.horseName : depth0), depth0))
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.winOdds : depth0),{"name":"if","hash":{},"fn":this.program(5, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</span>\n                </div>\n";
},"5":function(depth0,helpers,partials,data) {
    return " ("
    + this.escapeExpression(this.lambda((depth0 != null ? depth0.winOdds : depth0), depth0))
    + ")";
},"7":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.favourites : depth0),{"name":"if","hash":{},"fn":this.program(8, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"8":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "                <div class=\"m-event__favourites\">\n                    <span>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.favourites : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.horseName : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.favourites : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.winOdds : stack1), depth0))
    + "</span>\n                </div>\n";
},"10":function(depth0,helpers,partials,data) {
    var stack1;

  return "            <div class=\"m-event__eventBonusLabel\">\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.promotions : depth0),{"name":"each","hash":{},"fn":this.program(11, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "            </div>\n";
},"11":function(depth0,helpers,partials,data) {
    return "                    <span class=\"c-badge-small c-badge--jackpot\">"
    + this.escapeExpression(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,(depth0 != null ? depth0.label : depth0),{"name":"translate","hash":{},"data":data}))
    + "</span>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.lambda, alias2=this.escapeExpression;

  return "<div data-link class=\"c-dataList__row m-event__listItem\">\n    <div class=\"c-dataList__cell m-event__infoCell\">\n        <div class=\"m-event__raceInfo\">\n            <div class=\"c-number c-number--large is"
    + alias2(alias1((depth0 != null ? depth0.raceStatus : depth0), depth0))
    + " m-event__number\">\n                "
    + alias2(alias1((depth0 != null ? depth0.raceNumber : depth0), depth0))
    + "\n            </div>\n            <div class=\"m-event__time\">"
    + alias2(alias1((depth0 != null ? depth0.postTimeLabel : depth0), depth0))
    + "</div>\n        </div>\n    </div>\n    <div class=\"c-dataList__cell\">\n        <div class=\"c-dataList__el--truncatedText m-event__title\">\n            <span>"
    + ((stack1 = alias1((depth0 != null ? depth0.raceTitleLabel : depth0), depth0)) != null ? stack1 : "")
    + "</span>\n        </div>\n        <div class=\"c-dataList__el--truncatedText m-event__subContent\">\n            <span>"
    + alias2(alias1((depth0 != null ? depth0.categoryLabel : depth0), depth0))
    + " "
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.categoryLabel : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + " "
    + alias2(alias1((depth0 != null ? depth0.distanceLabel : depth0), depth0))
    + " | "
    + alias2(alias1((depth0 != null ? depth0.raceTypeLabel : depth0), depth0))
    + " | "
    + alias2(alias1((depth0 != null ? depth0.purseLabel : depth0), depth0))
    + "</span>\n        </div>\n"
    + ((stack1 = __default(require("../../components/helpers/handlebar-helpers/compare.js")).call(depth0,(depth0 != null ? depth0.raceStatus : depth0),"===","FNL",{"name":"compare","hash":{},"fn":this.program(3, data, 0),"inverse":this.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.promotions : depth0),{"name":"if","hash":{},"fn":this.program(10, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"useData":true});