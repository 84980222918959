var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var alias1=this.escapeExpression;

  return "<ul class=\"c-tabs\">\n    <li class=\"c-tabs__item\">\n        <a class=\"c-tabs__link\" href=\"/bets/open\">"
    + alias1(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_mybets_open",{"name":"translate","hash":{},"data":data}))
    + "</a>\n    </li>\n    <li class=\"c-tabs__item\">\n        <a class=\"c-tabs__link\" href=\"/bets/settled\">"
    + alias1(__default(require("../../components/helpers/handlebar-helpers/translate.js")).call(depth0,"label_mybets_settled",{"name":"translate","hash":{},"data":data}))
    + "</a>\n    </li>\n</ul>\n\n<div id=\"app-subview\" class=\"app__subView\"></div>\n";
},"useData":true});