'use strict';

/**
 * Backbone.SubView mixin
 *
 * @description
 *      Subview.
 *
 * @type {Object}
 */

export default {
    /**
     * @property {Boolean} isSubView - Is a subview
     *
     * @description
     *     Marks view as subview
     *
     * @default [true]
     */
    isSubView: true,

    /**
     * @property {String} type
     *
     * @description
     *     type of view represented in text format
     *
     * @default [true]
     */
    type: 'subview',

    /**
     * @method onSubViewShown
     *
     * @description
     *     Fired when subView is shown with or without populated template
     */
    onSubViewShown: function() {},

    /**
     * @method onPopulatedSubViewShown
     *
     * @description
     *     Fired when subView is shown with populated template
     */
    onPopulatedSubViewShown: function() {}
};
