import $           from 'jquery';
import * as Sentry from '@sentry/browser';

//configure sentry
Sentry.init({
    dsn: process.env.MOB_APP_SENTRY_DSN,
    environment: process.env.MOB_APP_RB_ENV,
    release: process.env.MOB_APP_RELEASE_VERSION,
    ignoreErrors: [
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        'Can\'t find variable: ZiteReader',
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        // Facebook borked
        'fb_xd_fragment',
        'ChunkLoadError',
        'Gateway Timeout',
        'Blocked a frame with origin',
        'ibFindAllVideos'
    ],
    blacklistUrls: [
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i,  // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
    ]
});

//set brand tag
Sentry.configureScope(scope => scope.setTag('brand', process.env.MOB_APP_BRAND));

$(document).ajaxError(function(event, jqXHR, ajaxSettings, thrownError) {
    if ([0, 400, 401, 403, 404, 422].indexOf(parseInt(jqXHR.status, 10)) === -1) {

        Sentry.withScope(scope => {
            scope.setExtra("type", ajaxSettings.type);
            scope.setExtra("url", ajaxSettings.url);
            scope.setExtra("data", ajaxSettings.data);
            scope.setExtra("status", jqXHR.status);
            scope.setExtra("error", thrownError || jqXHR.statusText);
            scope.setExtra("response", jqXHR.responseText ? jqXHR.responseText.substring(0, 100) : jqXHR.responseText);

            Sentry.captureMessage(thrownError || jqXHR.statusText);
        });
    }
});
