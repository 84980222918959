'use strict';

import app  from 'components/core/application';

export default {

    depositData: {},

    check_uk_funds_protection: true,

    getLastDepositAmount: function() {
        return this.depositData.last_deposit_amount;
    },

    updateBalance: function() {
        this.model.fetch();
    },

    hasRestrictions: function() {
        var restrictions = [];
        if(this.depositData && this.depositData.restrictions) {
            //check if there is a restriction 'uk_funds_protection' and do not count it as a restriction because it is processed differently
            restrictions = this.depositData.restrictions.filter(function (value) {
                return value !== 'uk_funds_protection';
            });
        }

        return (restrictions.length > 0) ? restrictions : false;
    },

    /**
     * For UK user only
     * @returns {boolean}
     */
    informOnMoneyProtection: function() {
        var inform = false;
        if(this.check_uk_funds_protection && this.depositData && this.depositData.restrictions) {
           if(this.depositData.restrictions.indexOf('uk_funds_protection') > -1) {
               inform = true;
            }
        }
        return inform;
    },

    /**
     * For UK user only
     * @returns {boolean}
     */
    acceptFundProtectionInformation: function() {
        this.model.url = this.model.url + '/uk_funds_protection';
        this.model.save({}, {
            success: ()=> {
                //todo: Reset url to previous one. Create a separate model for uk_funds_protection
                this.model.url = '/rest/v1/users/me';

                //reset to false in order not to show the dialog again
                this.check_uk_funds_protection = false;
                app.trigger('dialog:close');
            },
            error: ()=> {
                console.log('error');
            }
        });
    }
};
