'use strict';
import $                            from 'jquery';
import _                            from 'underscore';
import app                          from 'components/core/application';
import Backbone                     from 'backbone';
import BackboneCloseView            from 'components/core/mixins/Backbone.CloseView';
import template                     from 'modules/mybets/mybets-open.tpl.hbs';
import MyBetsOpenCollection         from 'modules/mybets/mybets-open.collection.js';
import pagination                   from 'components/helpers/pagination';


/**
 * Open Bets view
 *
 * @name OpenBets
 * @constructor
 *
 * @description
 * 		OpenBets view, display all open bets.
 *
 * @returns {Function} - Constructor function for OpenBets
 */

var OpenBets = Backbone.View.extend(
    _.extend({}, BackboneCloseView, {

        className: 'm-mybets',

        template: template,

        events: {
            'click [data-page]': 'goToPage',
            'click [data-id]': 'goToDetail',
            'click [data-idrace]': 'goToRace'
        },

        initialize: function (opt) {
            this.options = opt;
            this.DOM = {};
            this.data = {
                loaded: false
            };
            this.collection = new MyBetsOpenCollection(opt.page);
            this.collection.fetch();

            this.listenTo(this.collection, 'sync', this.show);
        },

        render: function () {
            this.$el.html(this.template(this.data));

            return this;
        },

        show: function () {
            this.data = {
                loaded: true,
                results: this.collection.toJSON(),
                pagination: pagination.create(this.collection.page, this.collection.pages)
            };
            this.render();
        },

        goToPage: function (e) {
            var page = this.collection.page;
            if (e.target.hasAttribute('data-next')) {
                page++;
            } else if (e.target.hasAttribute('data-prev')) {
                page--;
            } else if (e.target.hasAttribute('data-first')) {
                page = 1;
            } else if (e.target.hasAttribute('data-last')) {
                page = this.collection.pages;
            } else {
                page = $(e.target).attr('data-page');
            }

            if (page !== this.collection.page) {
                this.collection.changePage(page);
            }
        },

        goToRace: function (e) {
            let idRace = $(e.currentTarget).attr('data-idrace');
            app.router.navigate(`/race/details/id/${idRace}`, { trigger: true });
        },

        goToDetail: function (e) {
            let id = $(e.currentTarget).attr('data-id');
            app.router.navigate('betslip/id/' + id.toLowerCase(), {trigger: true});
        }
    })
);

export default OpenBets;
