'use strict';

const betOptions = {

    betTopCategories: ['normal', 'pick'],

    betCategories: ['BOK', 'TOT'],

    freeBetCategories: ['BOK'],

    columns: {
        WIN: {cl: 'col-1', value: [1], cols: 1},
        PLC: {cl: 'col-1', value: [1], cols: 1},
        SHW: {cl: 'col-1', value: [1], cols: 1},
        WP: {cl: 'col-1', value: [1], cols: 1},
        WS: {cl: 'col-1', value: [1], cols: 1},
        PS: {cl: 'col-1', value: [1], cols: 1},
        WPS: {cl: 'col-1', value: [1], cols: 1},
        PICK: {cl: 'col-3', value: [1], cols: 1},
        ITA: {cl: 'col-2', value: [2], cols: 1},
        TRT: {cl: 'col-3', value: [3], cols: 1},
        QNL: {cl: 'col-1 col-C', value: [1, 'C'], cols: 1},
        SWG: {cl: 'col-1 col-C', value: [1, 'C'], cols: 1},
        EXA: {cl: 'col-1 col-2 col-C', value: [1, 2, 'C'], cols: 2},
        TRO: {cl: 'col-1 col-2 col-C', value: [1, 2, 'C'], cols: 2},
        TRI: {cl: 'col-1 col-2 col-3 col-C', value: [1, 2, 3, 'C'], cols: 3},
        SFC: {cl: 'col-1 col-2 col-3 col-4 col-C', value: [1, 2, 3, 4, 'C'], cols: 4},
        TOF: {cl: 'col-1 col-C', value: [1, 'C'], cols: 1},
        QRP: {cl: 'col-1 col-C', value: [1, 2, 3, 4, 'C'], cols: 4}, //'Quarte+',
        QNP: {cl: 'col-1 col-C', value: [1, 2, 3, 4, 5, 'C'], cols: 5}, // 'Quinte+',
        SF4: {cl: 'col-1 col-C', value: [1, 2, 3, 4, 'C'], cols: 4}, //'Super4',
        TRC: {cl: 'col-1 col-C', value: [1, 2, 3, 'C'], cols: 3},// Tierce
        M4:  {cl: 'col-1 col-C', value: [1, 'C'], cols: 1},  //'Multi4',
        M5:  {cl: 'col-1 col-C', value: [1, 'C'], cols: 1}, //Multi5
        M6:  {cl: 'col-1 col-C', value: [1, 'C'], cols: 1}, //'Multi6',
        M7:  {cl: 'col-1 col-C', value: [1, 'C'], cols: 1}, //'Multi7',
        PK5: {cl: 'col-1 col-C', value: [1, 'C'], cols: 1} // 'Pick5'
    }
};

export default betOptions;
